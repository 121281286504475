import React, {FunctionComponent, useEffect, useState} from "react";
import "./NetWorthDistribution.scss";
import {AssetWithValuation, BANK_ACCOUNT, OTHER, REAL_ESTATE,} from "../../../../../store/assets/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {GraphAssetsPieChart} from "../../../molecules/GraphAssetsPieChart/GraphAssetsPieChart";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SG_BLACK, SG_BLUE, SG_ORANGE, SG_PINK,} from "../../../../utils/graphs/colors";
import {getAssetCategory} from "../../../../../store/assets/utils";
import {useAssets} from "../../../hooks/useAssets";
import {SGProgress} from "sg-progress";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGSpace} from "sg-space";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

const NetWorthDistribution: FunctionComponent = () => {
   const { assets } = useAssets();
   const hasFetchedAssets = useSelector<State, boolean>((state) => state.assets.hasFetched);

   const [cashAmount, setCashAmount] = useState(0);
   const [savingsAmount, setSavingsAmount] = useState(0);
   const [realEstateInvestment, setRealEstateInvestment] = useState(0);
   const [otherAmount, setOtherAmount] = useState(0);

   useEffect(() => {
      let tempCash = 0;
      let tempSavings = 0;
      let tempRealEstateInvestment = 0;
      let tempOther = 0;

      if (assets) {
         assets.forEach((asset: AssetWithValuation) => {
            switch (getAssetCategory(asset.assetType).intlKey) {
               case "BANK_ACCOUNT":
                  tempCash += asset.assetValuation.totalValuation || 0;
                  break;
               case "TERM_ACCOUNT":
               case "SAVINGS_ACCOUNT":
               case "INVESTMENT_ACCOUNT":
                  tempSavings += asset.assetValuation.totalValuation || 0;
                  break;
               case "REAL_ESTATE":
                  tempRealEstateInvestment +=
                     asset.assetValuation.totalValuation || 0;
                  break;
               default:
                  tempOther += asset.assetValuation.totalValuation || 0;
            }
         });
      }
      const tempTotalWealth =
         tempCash + tempSavings + tempRealEstateInvestment + tempOther;

      setCashAmount(Math.round((tempCash / tempTotalWealth) * 100));
      setSavingsAmount(Math.round((tempSavings / tempTotalWealth) * 100));
      setRealEstateInvestment(
         Math.round((tempRealEstateInvestment / tempTotalWealth) * 100)
      );
      setOtherAmount(Math.round((tempOther / tempTotalWealth) * 100));
   }, [assets]);

   return (
      <SpinnerWrapper spinnerSize={SpinnerSize.LG} displayComponent={hasFetchedAssets} fatalErrorOnTimeout={false}>
         <SGGridRow align="center">
            {assets?.length > 0 && (
               <SGGridCol xs={12} md={6}>
                  <GraphAssetsPieChart/>
               </SGGridCol>)}
            {assets?.length > 0 && (
               <SGGridCol xs={12} md={6} align="center">
                  <SGSpace direction="vertical" align="center">
                     <SGProgress extra={<SGTextIntl intlId={`assetCategory.${BANK_ACCOUNT.intlKey}`}/>} percent={cashAmount}
                                 className={`netWorth-distributions__progress-bar--${SG_BLUE.label}`}/>

                     <SGProgress extra={<SGTextIntl intlId="assetCategory.savings"/>} percent={savingsAmount}
                                 className={`netWorth-distributions__progress-bar--${SG_ORANGE.label}`}/>

                     <SGProgress extra={<SGTextIntl intlId={`assetCategory.${REAL_ESTATE.intlKey}`}/>} percent={realEstateInvestment}
                                 className={`netWorth-distributions__progress-bar--${SG_PINK.label}`}/>

                     {otherAmount > 0 && (
                        <SGProgress extra={<SGTextIntl intlId={`assetCategory.${OTHER.intlKey}`}/>} percent={otherAmount}
                                    css={{color: SG_BLACK.hexCode}}
                                    className={`netWorth-distributions__progress-bar--${SG_BLACK.label}`}/>
                     )}
                  </SGSpace>
               </SGGridCol>)}
            {assets?.length === 0 && (
               <SGGridCol span={12}>
                  <SGAlertIntl type="warning" cypressName="netWorth-no-assets-error" title="netWorth.distribution.no.assets.message">
                     <SGTextIntl intlId="netWorth.distribution.no.assets.message2"/>
                  </SGAlertIntl>
               </SGGridCol>
            )}

         </SGGridRow>
      </SpinnerWrapper>
   );
};

export {NetWorthDistribution};
