import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters } from "store/simulationAssu/types";
import { WorkIncomeRaisesTypeSimulation, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { Loader } from "website/components/atoms/Loader/Loader";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useWorkIncomesHistory } from "website/components/hooks/useWorkIncomesHistory";
import { ReformeDepartsAnticipes } from "website/components/organisms/ReformeDepartsAnticipes/ReformeDepartsAnticipes";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { formatDateSimulation } from "website/utils/date/DateUtils";
import { externalURL } from "website/utils/externalUrl";
import { State } from "../../store/store";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useHistory } from "react-router";
import { DASHBOARD, LOGOUT } from "website/utils/privateRoutes";
import { SupervisionState, possedeDroitsActionUtilisateur } from "store/supervision/types";
import { SGButton } from "sg-button";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const ReformeDepartsAnticipesPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { trackPage } = useTracking();

    const supervisionState: SupervisionState = useSelector<State, SupervisionState>((state) => state.supervision);

    // En attente des chargements
    const [showLoader, setShowLoader] = useState(true);

    const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const { personalInformationsState } = usePersonalInformations();

    const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);
    const family = useFamily();
    const dashboardState = useDashboardState();

    useWorkIncomesHistory();

    useEffect(() => {
        if (dashboardState.dashboard?.ages &&
            dashboardState.dashboard.ages.filter((age) => new Date(age.creationDate) < new Date(window.env.REACT_APP_DEPART_ANTICIPE_DEBUT)).length === 0) {
            history.push(DASHBOARD);
        } else {
            // Cas du tracking via l'onboarding à cause des mentions légales affichées instantanément
            trackPage("", "mise-a-jour-donnees-departs-anticipes", PAGE_TYPE_GESTION);
        }
    }, []);

    /**
     * Paramètres récupérés d'ailleurs pour préalimentation ou appel d'API
     */
    useEffect(() => {
        // On n'écoute que les obligatoires (non saisissables)
        if (showLoader && workIncomesHistoryState.workIncomesHistory && hasFetchedFamily && personalInformationsState.hasFetched) {
            const simulateur45Request: Partial<RetirementSimulationParameters> = {
                // Anciennes données
                genre: "m",
                dateNaissance: formatDateSimulation(new Date(family.me.birthday)),
                montantAnnuelSalaire: workIncomesHistoryState.workIncomesHistory.currentYearlyAmount,
                evolSalaireFutur: WorkIncomeRaisesTypeSimulation[workIncomesHistoryState.workIncomesHistory.expectedYearlyIncrease ?? "NO_WORK_INCOME_RAISES"],
                evolSalairePasse: WorkIncomeRaisesTypeSimulation[workIncomesHistoryState.workIncomesHistory.pastYearlyIncrease ?? "NO_WORK_INCOME_RAISES"],
                memberId: family.me.id,
                idSimulSimple: "",
                simulationErrorCode: undefined,
                simulationErrorMessage: undefined,
            };
            dispatch(updateRetirementSimulation(simulateur45Request));

            setShowLoader(false);
        }
    }, [workIncomesHistoryState, hasFetchedFamily, personalInformationsState.hasFetched]);

    const handleLogout = () => {
        history.push(LOGOUT);
    };

    return showLoader ? (
        <SGBox margin={{ top: "xxl", bottom: "xxl" }}>
            <Loader title="loader.title" />
        </SGBox>
    ) : (
        <SGLayout tagName="div" background="light">
            <SGBox span={11} xl={9}>
                <>
                    <SGLayout tagName="div">
                        <SGContent disableautomargin>
                            {!possedeDroitsActionUtilisateur(supervisionState) &&
                                <SGBox margin={{ bottom: "lg" }}>
                                    <SGAlert
                                        description={<SGText size="m">Invitez le client à mettre à jour ses données suite au départ anticipé dans Mon&#160;Compagnon&#160;Retraite.</SGText>}
                                        icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                                        type="info"
                                        span={8}
                                        action={<SGButton size="small" onClick={handleLogout}>Retour</SGButton>}
                                        actionPlacement="top"
                                    />
                                </SGBox>
                            }
                            <SGBox margin={{ bottom: "lg" }}>
                                <SGAlert
                                    description="Pour actualiser votre simulation retraite, nous avons besoin de compléter votre profil."
                                    icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                                    type="info"
                                    span={6}
                                />
                            </SGBox>
                            <DetailTabProvider>
                                <ReformeDepartsAnticipes />
                            </DetailTabProvider>
                        </SGContent>
                    </SGLayout>
                    <SGContent disableautomargin>
                        <SGText strong>
                            <FormattedMessage id="reformeDepartsAnticipes.mentions.bloc1" />
                        </SGText>
                        <SGBlock>
                            <FormattedMessage id="reformeDepartsAnticipes.mentions.bloc2" />
                        </SGBlock>
                        <SGBlock disableautomargin>
                            <FormattedMessage
                                id="reformeDepartsAnticipes.mentions.bloc3"
                                values={{
                                    link: (texte: string) => (
                                        <SGLink href={externalURL.EXTERNAL_URL_CNIL} target="_blank">
                                            {texte}
                                        </SGLink>
                                    ),
                                }}
                            />
                        </SGBlock>
                    </SGContent>
                </>
            </SGBox>
        </SGLayout>
    );
};
