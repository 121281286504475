import React, {FunctionComponent, ReactElement} from "react";
import {ProjectType} from "../../../../store/projects/types";
import {SGIcon} from "sg-icon";
import {
   SGAvenirStyledBourse,
   SGAvenirStyledChantier,
   SGAvenirStyledDon,
   SGAvenirStyledDureeLongue,
   SGAvenirStyledEpargneBanquePrive,
   SGAvenirStyledFauteuil,
   SGAvenirStyledHabitation,
   SGAvenirStyledImmeuble,
   SGAvenirStyledMomentsDeVie,
   SGAvenirStyledMonde,
   SGAvenirStyledPortefeuillePlus,
   SGAvenirStyledUniversite,
   SGAvenirStyledVoyageInternational
} from "sg-icon-pack-base";

interface ProjectIconProps {
   projectType: ProjectType;
}

const getProjectImage = (projectType: ProjectType): ReactElement => {
   switch (projectType) {
      case ProjectType.REAL_ESTATE_INVESTMENT:
         return <SGAvenirStyledImmeuble/>;
      case ProjectType.REAL_ESTATE_PRIMARY:
      case ProjectType.REAL_ESTATE_SECONDARY:
         return <SGAvenirStyledHabitation/>;
      case ProjectType.CONSTRUCTION_WORK:
         return <SGAvenirStyledChantier/>;
      case ProjectType.RETIREMENT_PROJECT:
         return <SGAvenirStyledFauteuil/>;
      case ProjectType.GIVEN_DONATION:
         return <SGAvenirStyledDon/>;
      case ProjectType.EXCEPTIONAL_EXPENSE:
         return <SGAvenirStyledBourse/>;
      case ProjectType.WEDDING:
         return <SGAvenirStyledMomentsDeVie/>;
      case ProjectType.ADDITIONAL_INCOME:
         return <SGAvenirStyledDureeLongue/>;
      case ProjectType.RECEIVED_DONATION:
         return <SGAvenirStyledPortefeuillePlus/>;
      case ProjectType.CHILDREN_EDUCATION:
         return <SGAvenirStyledUniversite/>;
      case ProjectType.CHANGE_LIFE_PROJECT:
         return <SGAvenirStyledMonde/>;
      case ProjectType.TRAVEL:
         return <SGAvenirStyledVoyageInternational/>;
      case ProjectType.SABBATICAL_LEAVE:
         return <SGAvenirStyledDureeLongue/>
      case ProjectType.CAPITAL_GAIN:
      case ProjectType.TAX_EXEMPTION_PROJECT:
         return <SGAvenirStyledEpargneBanquePrive/>;
      default:
         return <SGAvenirStyledEpargneBanquePrive/>;
   }
};

const ProjectIcon: FunctionComponent<ProjectIconProps> = ({projectType}) => (
   <SGIcon component={getProjectImage(projectType)} size="xl"/>
);

export {ProjectIcon};
