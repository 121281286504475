import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGCarousel } from "sg-carousel";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import {
  TypeMedia,
  getIdTrackingType,
} from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useArticles } from "website/components/hooks/useArticles";
import { CarteArticleVertical } from "website/components/organisms/Articles/CarteArticleVertical";
import { ARTICLE, ARTICLES } from "website/utils/privateRoutes";

const AccesContenus: FunctionComponent = () => {
  const { articlesTries } = useArticles();
  const history = useHistory();
  const { trackClick } = useTracking();

  const handleOnClickLireArticle = (
    path: string,
    trackKey: string,
    typeMedia?: TypeMedia
  ) => {
    const idTrackingType: string = typeMedia
      ? `-${getIdTrackingType(typeMedia)}-`
      : "";
    trackClick(`${trackKey}${idTrackingType}${path}`);
    history.push(`${ARTICLE}${path}`);
  };

  const handleOnClick = () => {
    trackClick("clic-sur-voir-tous-les-articles");
    history.push(ARTICLES);
  };

  return (
    <SGSpace direction="vertical" size="md" disableautomargin>
      <SGBox key="box-1">
        <>
          <SGTitle level={2} visuallevel={3}>
            Nos conseils d’expert
          </SGTitle>
          <SGText>Des contenus pour en savoir plus sur la retraite</SGText>
        </>
      </SGBox>
      <SGCarousel
        key="carousel"
        slidesToScroll={1}
        settings={{
          infinite: true,
          slidesToShow: 2,
        }}
      >
        {articlesTries
          .filter((article) => article.favoriDashboard !== undefined)
          .sort((article1, article2) =>
            article1.favoriDashboard && article2.favoriDashboard
              ? article1.favoriDashboard - article2.favoriDashboard
              : 0
          )
          .map((article) => (
            <CarteArticleVertical
              key={article.id}
              media={article}
              handleOnClickLireArticle={handleOnClickLireArticle}
              trackKey="clic-sur-carrousel"
              afficherIconeTag={false}
            />
          ))}
      </SGCarousel>
      <SGBox key="box-2" textalign="center">
        <SGButton type="secondary" onClick={handleOnClick}>
          Voir tous les articles
        </SGButton>
      </SGBox>
    </SGSpace>
  );
};

export { AccesContenus };
