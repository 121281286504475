import { ReactComponent as ChevronDown } from "assets/images/chevron-down-solid.svg";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { DashboardAge, MONTHS } from "../../../../../store/dashboard/types";
import "./ChoixAge.scss";
import { SupervisionState, possedeDroitsActionUtilisateur } from "store/supervision/types";
import { useSelector } from "react-redux";
import { State } from "store/store";

interface ChoixAgeProps {
    setSelectedRetirementAge: (retirementAge: DashboardAge) => void;
    agesDepartRetraite: DashboardAge[];
    selectedRetirementAge?: DashboardAge;
}

const ChoixAge: FunctionComponent<ChoixAgeProps> = (props: ChoixAgeProps) => {
    const { setSelectedRetirementAge, agesDepartRetraite, selectedRetirementAge } = props;

    const [displayAgesChoices, setDisplayAgesChoices] = useState(false);
    const choixAgeContainer = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const supervisionState: SupervisionState = useSelector<
      State,
      SupervisionState
    >((state) => state.supervision);

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    // Permet de gérer les click en dehors de l'élément ChoixAge
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: { target: any }) => {
        if (choixAgeContainer.current !== null && !choixAgeContainer.current.contains(event.target)) {
            setDisplayAgesChoices(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const onRetirementAgeSelection = (ageDepartRetraite: DashboardAge) => {
        setDisplayAgesChoices(false);
        setSelectedRetirementAge(ageDepartRetraite);
    };

    return (
        <div ref={choixAgeContainer}>
            <SGBox margin={{ top: isPhone ? "sm" : "xs", bottom: isPhone ? "sm" : "xs" }}>
                    <SGGridRow align="middle">
                        <SGGridCol span={12}>
                            {agesDepartRetraite.length > 0 && (
                                <button
                                    onClick={() => setDisplayAgesChoices(!displayAgesChoices)}
                                    type="button"
                                    className="choix-age__button"
                                    tabIndex={isPhone ? undefined : -1}
                                >
                                    <SGGridRow align="middle">
                                        <SGGridCol flex="1 0 0%">
                                            <div className="choix-age__text">
                                                <SGTextIntl
                                                    intlId="dashboard.choixAge.mainText"
                                                    size="m"
                                                    transformations={{
                                                        retirementAgeYear: selectedRetirementAge?.retirementAgeYear,
                                                        retirementAgeMonth: selectedRetirementAge?.retirementAgeMonth ? (
                                                            <label>{` et ${selectedRetirementAge?.retirementAgeMonth} mois`}</label>
                                                        ) : (
                                                            ""
                                                        ),
                                                        retirementYear: selectedRetirementAge?.retirementYear,
                                                        retirementMonth:
                                                            MONTHS[selectedRetirementAge ? selectedRetirementAge.retirementMonth - 1 : 0]?.toLocaleLowerCase(),
                                                        b: (word: string) => (
                                                            <SGText strong className="choix-age__text-strong">
                                                                {word}
                                                            </SGText>
                                                        ),
                                                        red: (word: string) => <SGText color="error">{word}</SGText>,
                                                        year: (word: string) => <SGText>{word}</SGText>,
                                                    }}
                                                />
                                            </div>
                                            <SGMediaQuery minwidth="sm">
                                                <SGDivider disableautomargin />
                                            </SGMediaQuery>
                                            {selectedRetirementAge && (
                                                <SGBox>
                                                    <SGText
                                                        weight={isPhone ? "600" : undefined}
                                                        className="choix-age__retirement-age-type"
                                                        style={{ color: "#545454" }}
                                                        >{selectedRetirementAge.typesDepartTitre}</SGText>
                                                </SGBox>
                                            )}
                                        </SGGridCol>
                                        <SGGridCol flex="initial">
                                            <SGBox margin={{ right: "xs" }}>
                                                <SGButton
                                                    ariaLabel={intl.formatMessage({ id: "dashboard.choixAge.ouvrir" })}
                                                    disableautomargin
                                                    size="sm"
                                                    style={{ width: "40px", backgroundColor: "#E7E7E7" }}
                                                    icon={
                                                        <ChevronDown className={`choix-age__chevron ${displayAgesChoices ? "choix-age__chevron--rotate" : ""}`} />
                                                    }
                                                />
                                            </SGBox>
                                        </SGGridCol>
                                    </SGGridRow>
                                </button>
                            )}
                        </SGGridCol>
                        <SGGridCol
                            className={`choix-age__choices-container choix-age__choices-container-epargner ${
                                displayAgesChoices ? "choix-age__choices-container--show" : ""
                            }`}
                        >
                            {agesDepartRetraite.map((ageDepartRetraite: DashboardAge, index) => (
                                <React.Fragment key={ageDepartRetraite.type}>
                                    <button
                                        type="button"
                                        disabled={!possedeDroitsActionUtilisateur(supervisionState)}
                                        key={`${ageDepartRetraite.retirementAgeYear}${ageDepartRetraite.retirementAgeMonth}`}
                                        onClick={() => onRetirementAgeSelection(ageDepartRetraite)}
                                        className="choix-age__button choix-age__choices-button"
                                    >
                                        <SGGridRow align="middle" justify="space-between" gutter={[20]}>
                                            <SGGridCol sapn={12}>
                                                <SGMediaQuery minwidth={0} maxwidth="xs">
                                                    {selectedRetirementAge === ageDepartRetraite && <SGDivider type="vertical" width={1} />}
                                                </SGMediaQuery>
                                                <SGBox margin={{ left: "sm" }} inline textalign="left" style={{ fontFamily: "Source Sans Pro Regular", color: "#545454" }}>
                                                    <SGTextIntl
                                                        intlId="dashboard.choixAge.mainText"
                                                        size="m"
                                                        transformations={{
                                                            retirementAgeYear: ageDepartRetraite?.retirementAgeYear,
                                                            retirementAgeMonth: ageDepartRetraite?.retirementAgeMonth ? (
                                                                <SGText>{` et ${ageDepartRetraite?.retirementAgeMonth} mois`}</SGText>
                                                            ) : (
                                                                ""
                                                            ),
                                                            retirementYear: ageDepartRetraite?.retirementYear,
                                                            retirementMonth: MONTHS[ageDepartRetraite?.retirementMonth - 1]?.toLocaleLowerCase(),
                                                            b: (word: string) => (
                                                                <SGText strong className="choix-age__text-strong">
                                                                    {word}
                                                                </SGText>
                                                            ),
                                                            red: (word: string) => <SGText>{word}</SGText>,
                                                            year: (word: string) => <SGText>{word}</SGText>,
                                                        }}
                                                    />
                                                </SGBox>
                                            </SGGridCol>
                                            <SGMediaQuery minwidth="sm">
                                                <SGGridCol>
                                                    <SGText
                                                        className="choix-age__retirement-age-type"
                                                        style={{ color: "#545454" }}
                                                    >{ageDepartRetraite.typesDepartTitre}</SGText>
                                                </SGGridCol>
                                            </SGMediaQuery>
                                        </SGGridRow>
                                    </button>
                                    <SGMediaQuery minwidth={0} maxwidth="xs">
                                        {index !== agesDepartRetraite.length - 1 && <SGDivider disableautomargin />}
                                    </SGMediaQuery>
                                </React.Fragment>
                            ))}
                        </SGGridCol>
                    </SGGridRow>
            </SGBox>
        </div>
    );
};

export { ChoixAge };
