import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedRetour } from "sg-icon-pack-base";
import { SGText } from "sg-typo";
import { AccountState, isLoggedIn } from "store/account/types";
import { State } from "store/store";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { SGLogo } from "sg-logo";
import { ARTICLES, LOGOUT, MES_SERVICES, MON_PLAN, PARCOURS_REVENUS_COMPLEMENTAIRES, REFORME_DEPART_ANTICIPE } from "website/utils/privateRoutes";
import "./HeaderAssu.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const HeaderAssu: FunctionComponent = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { trackClick } = useTracking();

    const accountState = useSelector<State, AccountState>((state) => state.account);

    const handleLogout = () => {
        trackClick("clic-sur-retour-aux-comptes");
        history.push(LOGOUT);
    };

    return (
        <header className="headerAssu">
            <nav className="headerAssu__wrapper">
                <SGButton icon={<SGLogo baseline width={256} />} onClick={handleLogout} type="icon" />
                {accountState.account.tunnelCompleted && pathname !== REFORME_DEPART_ANTICIPE && (
                    <>
                        <NavLink
                            onClick={() => {
                                trackClick("clic-sur-mon-tableau-de-bord");
                            }}
                            className={`headerAssu__title ${
                                (window.location.href.includes("/parcours-ris") || window.location.href.includes(PARCOURS_REVENUS_COMPLEMENTAIRES)) &&
                                "headerAssu__title__isActive"
                            }`}
                            activeClassName="headerAssu__title__isActive"
                            to="/dashboard"
                        >
                            <SGTextIntl intlId="header.title.bilan" size="xs" />
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                trackClick("clic-sur-comprendre-ma-retraite");
                            }}
                            className="headerAssu__title"
                            to={ARTICLES}
                            activeClassName="headerAssu__title__isActive"
                        >
                            <FormattedMessage id="header.title.comprendre" />
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                trackClick("clic-sur-mon-plan");
                            }}
                            className="headerAssu__title"
                            to={MON_PLAN}
                            activeClassName="headerAssu__title__isActive"
                        >
                            <FormattedMessage id="header.title.plan" />
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                trackClick("clic-sur-mes-services");
                            }}
                            className="headerAssu__title"
                            to={MES_SERVICES}
                            activeClassName="headerAssu__title__isActive"
                        >
                            <FormattedMessage id="header.title.services" />
                        </NavLink>
                    </>
                )}
                {isLoggedIn(accountState) && (
                    <div className="headerAssu__wrapper__links">
                        <SGButton
                            type="link"
                            icon={<SGIcon size="s" component={<SGAvenirStrokedRetour />} currentcolor />}
                            underline={false}
                            onClick={handleLogout}
                        >
                            <SGText size="xs">
                                <FormattedMessage id="app.logout" />
                            </SGText>
                        </SGButton>
                    </div>
                )}
            </nav>
            {!isLoggedIn(accountState) && <div className="headerAssu__linear-gradient" />}
        </header>
    );
};

export { HeaderAssu };
