import React, {FunctionComponent, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {nextStepAction, previousStepAction} from "../../../../../store/tunnel/actions";
import {useFamily} from "../../../hooks/useFamily";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGSpace} from "sg-space";
import {Income, IncomeType} from "../../../../../store/incomes/types";
import {MonthlyYearly} from "../../../atoms/NumericInput/NumericInput";
import {useIntl} from "react-intl";
import {Member} from "../../../../../store/members/types";
import {createIncomeAction, updateIncomeAction} from "../../../../../store/incomes/actions";
import {IncomeSalary} from "../../../molecules/IncomeSalary/IncomeSalary";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGButtonGroup} from "sg-button";
import {useWorkOrRetirementIncome} from "../../../hooks/useWorkOrRetirementIncome";
import {calculateAgeByBirthdate, MAX_AGE_TO_RETIRE} from "../../../../../store/members/utils";
import {State} from "../../../../../store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface TunnelIncomesFormData {
   meAmount: number;
   meAmountPeriodicity: MonthlyYearly;
   meType?: IncomeType;
   partnerAmount: number;
   partnerAmountPeriodicity: MonthlyYearly;
   partnerType?: IncomeType;
}

const TunnelIncomes: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const displayComponent = useSelector<State, boolean>((state) => state.family.hasFetched && state.incomes.hasFetched);
   const family = useFamily();
   const myIncome = useWorkOrRetirementIncome(family.me);
   const partnerIncome = useWorkOrRetirementIncome(family.partner);
   const { trackPage } = useTracking();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   useEffect(() => {
      trackPage("funnel", "revenus-de-mon-foyer", "formulaire-ecran", "inscription", "3");
   }, []);

   const onSubmit = (data: TunnelIncomesFormData) => {
      if (data.meAmount && data.meAmount !== 0) {
         createIncomeForMember(family.me, data.meAmountPeriodicity === MonthlyYearly.MONTHLY ? data.meAmount : Math.trunc(data.meAmount / 12), data.meType, myIncome?.id);
      }
      if (data.partnerAmount && data.partnerAmount !== 0) {
         if (family.partner !== undefined) {
            createIncomeForMember(family.partner, data.partnerAmountPeriodicity === MonthlyYearly.MONTHLY ? data.partnerAmount : Math.trunc(data.partnerAmount / 12), data.partnerType, partnerIncome?.id);
         }
      }
      dispatch(nextStepAction());
   };

   const computeDefaultType = (member: Member): IncomeType => {
      if (!member.birthday) {
         return IncomeType.WORK_INCOME;
      }

      if (calculateAgeByBirthdate(member.birthday) > MAX_AGE_TO_RETIRE) {
         return IncomeType.RETIREMENT_INCOME;
      }

      return IncomeType.WORK_INCOME;
   };

   const createIncomeForMember = (member: Member, monthlyAmount: number | undefined, incomeType: IncomeType | undefined, id: number | undefined) => {
      const memberIncome: Partial<Income> = {
         name: intl.formatMessage({
            id: `form.income.name.${member.status}.${incomeType || computeDefaultType(member)}`,
         }),
         memberId: member.id,
         type: incomeType || computeDefaultType(member),
         monthlyAmount,
         id
      };

      if (id !== undefined) {
         dispatch(updateIncomeAction(memberIncome));
      } else {
         dispatch(createIncomeAction(memberIncome));
      }
   };

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="tunnel.incomes.title" cypressName="tunnel-incomes-title"/>
         <FormProvider {...methods}>
            <form>
               <SpinnerWrapper displayComponent={displayComponent} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
                  {displayComponent && <>
                     <SGSpace direction="vertical">
                        <IncomeSalary member={family.me} cypressName="tunnel-incomes-me"
                                      income={myIncome || {type: computeDefaultType(family.me)}}/>
                        {family.partner &&
                           <IncomeSalary member={family.partner} cypressName="tunnel-incomes-partner"
                                         income={partnerIncome || {type: computeDefaultType(family.partner)}}/>
                        }
                     </SGSpace>
                     <SGButtonGroup align="center" layout="column">
                        <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="tunnel-next"
                                      onClick={methods.handleSubmit(onSubmit)} trackingName="revenus-de-mon-foyer::clic-sur-confirmer"
                                      intlId="tunnel.buttons.next"/>
                        <SGButtonIntl type="link" cypressName="tunnel-previous"
                                      onClick={() => {
                                         dispatch(previousStepAction())
                                      }} trackingName="revenus-de-mon-foyer::clic-sur-retour"
                                      intlId="tunnel.buttons.previous"/>
                     </SGButtonGroup>
                  </>}
               </SpinnerWrapper>
            </form>
         </FormProvider>
      </SGSpace>
   );
};

export {TunnelIncomes};
