import {call, put, takeLeading} from "redux-saga/effects";
import {apiPost} from "../apiCaller";
import {onDefaultError, safe} from "../error/utils";
import {FETCH_INHERITANCE_SHARES, InheritanceSimulatorParameter, InheritanceSimulatorResult} from "./types";
import {PayloadAction} from "@reduxjs/toolkit";
import {inheritanceSharesFetched} from "./inheritanceSimulatorSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(FETCH_INHERITANCE_SHARES, safe(onDefaultError, handleCallInheritanceSimulator));
}

function* handleCallInheritanceSimulator(action: PayloadAction<InheritanceSimulatorParameter>) {
   const payload: AxiosResponse<InheritanceSimulatorResult> = yield call(apiPost, `wealth/api/inheritance/amount-fees`, action.payload);
   yield put(inheritanceSharesFetched(payload.data));
}
