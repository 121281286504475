import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {ProjectState} from "../../../store/projects/types";
import {getFeasibilitiesActions} from "../../../store/projects/actions";

const useProjectsFeasibilities = (forceFetch: boolean) => {
   const dispatch = useDispatch();
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   useEffect(() => {
      if (!projectState.hasFetchedFeasibilities || forceFetch) {
         dispatch(getFeasibilitiesActions());
      }
   }, []);

   return projectState.projectFeasibilities;
};

export {useProjectsFeasibilities};
