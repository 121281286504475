import React, {ChangeEvent, FunctionComponent, useEffect, useState} from "react";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {SGContent} from "sg-layout";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {SpinnerSize, SpinnerWrapper} from "../components/atoms/SpinnerWrapper/SpinnerWrapper";
import {ElyxirTabs} from "../components/molecules/ElyxirTabs/ElyxirTabs";
import graphLine from "../../assets/images/graph-line.png";
import graphBar from "../../assets/images/graph-bar.png";
import {GraphSimulation} from "../components/molecules/GraphSimulation/GraphSimulation";
import {GraphDetailedSimulation} from "../components/molecules/GraphDetailedSimulation/GraphDetailedSimulation";
import {SGAlertIntl} from "../components/molecules/SGAlertIntl/SGAlertIntl";
import {SGCard} from "sg-card";
import {GraphIncomesExpenses} from "../components/molecules/GraphIncomesExpenses/GraphIncomesExpenses";
import {SGBlock} from "sg-typo";
import {useProjects} from "../components/hooks/useProjects";
import {useLoans} from "../components/hooks/useLoans";
import {useAssets} from "../components/hooks/useAssets";
import {useSimulation} from "../components/hooks/useSimulation";
import {HighChartsAlert, SIMULATION_ALERTS} from "../../store/simulation/types";
import {getAlertsByAge} from "../../store/simulation/utils";
import {AssetWithValuation} from "../../store/assets/types";
import {Loan} from "../../store/loans/types";
import {SGSpace} from "sg-space";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledBillet, SGAvenirStyledChangementDevise, SGAvenirStyledFamille} from "sg-icon-pack-base";
import {redirectToRelativePath} from "../utils/routes/routing";
import {INHERITANCE_SIMULATOR, SURVIVING_PARTNER_SIMULATOR, TAX_SIMULATOR} from "../utils/privateRoutes";
import {SGGridCol, SGGridRow} from "sg-grid";
import {GraphDetailedIncomesExpenses} from "../components/molecules/GraphDetailedIncomesExpenses/GraphDetailedIncomesExpenses";
import {InfoModal} from "website/components/molecules/InfoModal/InfoModal";
import {SGCheckBox} from "sg-checkbox";
import {useMediaQuery} from "sg-media-query";
import {useSelector} from "react-redux";
import {State} from "../../store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const HIDE_PROJECTS_INFO_MODAL = "hideProjectsInfoModal";

const SimulationsPage: FunctionComponent = () => {
   const loans = useLoans([]);
   const { assets } = useAssets();
   const simulation = useSimulation(true);
   const projects = useProjects(true, false);
   const hasFetchedLoansAndAssets = useSelector<State, boolean>((state) => state.loans.hasFetched && state.assets.hasFetched);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);
   const { trackClick, trackPage } = useTracking();

   const [totalValuation, setTotalValuation] = useState<number>(0);
   const [simulationAlerts, setSimulationAlerts] = useState<HighChartsAlert[]>([]);

   const isAtLeastMediumScreen = useMediaQuery({
      minwidth: 'sm'
   });

   useEffect(() => {
      if (hasFetchedLoansAndAssets) {
         computeNetWorthValuation();
      }
   }, [assets, loans]);

   useEffect(() => {
      if (hasFetchedSimulation) {
         const alerts = getAlertsByAge(simulation.alerts);
         setSimulationAlerts(
            alerts
               .filter((x) => SIMULATION_ALERTS.indexOf(x.type) !== -1)
               .filter((alert: HighChartsAlert) => alert.type.startsWith("ALERT"))
         );
      }
   }, [simulation]);

   useEffect(() => {
      trackPage("simulation", "accueil", "gestion-ecran");
   }, []);

   const computeNetWorthValuation = () => {
      const assetsTotalValuation = assets.reduce((acc: number, item: AssetWithValuation) => acc + (item?.assetValuation?.totalValuation || 0), 0);
      const loansTotalPrincipal = loans.reduce((acc: number, item: Loan) => acc + (item && item.remainingPrincipal), 0);
      setTotalValuation(assetsTotalValuation - loansTotalPrincipal);
   };

   const displayGraphSimulation = (): boolean => totalValuation > 0 || projects.length > 0;

   return (
      <SGContent>
         <SGSpace direction="vertical" disableautomargin>
            <SGTitleIntl cypressName="simulators-title" intlId="simulator.title"/>
            <SGGridRow align="stretch" justify="center">
               <SGGridCol sm={4} xs={6}>
                  <SGCard clickable disableautomargin onClick={() => {
                     redirectToRelativePath(TAX_SIMULATOR);
                     trackClick("clic-sur-acceder-au-simulateur-impot");
                  }}
                          textalign="center" bordered size="xs"
                          data-cy="tax-simulator-button">
                     <SGSpace size="sm" direction="vertical">
                        <SGIcon component={<SGAvenirStyledBillet/>} size="xl"/>
                        <SGTextIntl intlId="tax.simulator.go-to-simulator" strong/>
                     </SGSpace>
                  </SGCard>
               </SGGridCol>
               <SGGridCol sm={4} xs={6}>
                  <SGCard clickable disableautomargin onClick={() => {
                     redirectToRelativePath(INHERITANCE_SIMULATOR);
                     trackClick("clic-sur-acceder-au-simulateur-fiscalite-de-la-succession");
                  }}
                          textalign="center" bordered size="xs"
                          data-cy="inheritance-calculator-button">
                     <SGSpace size="sm" direction="vertical">
                        <SGIcon component={<SGAvenirStyledChangementDevise/>} size="xl"/>
                        <SGTextIntl intlId="inheritance.simulator.inheritance-calculator.go-to-simulator" strong/>
                     </SGSpace>
                  </SGCard>
               </SGGridCol>
               <SGGridCol sm={4} xs={6}>
                  <SGCard clickable disableautomargin={isAtLeastMediumScreen} onClick={() => {
                     redirectToRelativePath(SURVIVING_PARTNER_SIMULATOR);
                     trackClick("clic-sur-acceder-au-simulateur-part-du-conjoint-survivant");
                  }}
                          textalign="center" bordered size="xs"
                          data-cy="surviving-partner-inheritance-calculator-button">
                     <SGSpace size="sm" direction="vertical">
                        <SGIcon component={<SGAvenirStyledFamille/>} size="xl"/>
                        <SGTextIntl intlId="inheritance.simulator.surviving-partner.go-to-simulator" strong/>
                     </SGSpace>
                  </SGCard>
               </SGGridCol>
            </SGGridRow>
            <SGTitleIntl intlId="projects.my-net-worth" cypressName="my-net-worth-title"/>
            <SGCard>
               <SpinnerWrapper displayComponent={hasFetchedLoansAndAssets} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
                  {displayGraphSimulation() && (
                     <>
                        <ElyxirTabs centered={false} tabsIcons={[graphLine, graphBar]} tabsCypressNames={[
                           "net-worth-line-graph",
                           "net-worth-bar-graph",
                        ]} tabsComponents={[
                           <SGContent key="net-worth-line-graph"><GraphSimulation alerts={simulationAlerts}/></SGContent>,
                           <SGContent key="net-worth-bar-graph"><GraphDetailedSimulation/></SGContent>,
                        ]} tabsNames={["line", "bar"]}/>
                     </>
                  )}
                  {!displayGraphSimulation() && (
                     <SGAlertIntl type="error" cypressName="notification-frame-simulation-missing-data" title="common.warning">
                        <SGTextIntl intlId="graph.ask-fill-up"/>
                     </SGAlertIntl>
                  )}
               </SpinnerWrapper>
            </SGCard>
            <SGTitleIntl intlId="projects.my-incomes-expenses" cypressName="my-incomes-expenses-title"/>
            <SGCard>
               <ElyxirTabs centered={false} tabsIcons={[graphBar, graphLine]} tabsCypressNames={[
                  "incomes-bar-graph",
                  "incomes-line-graph",
               ]} tabsComponents={[
                  <SGContent key="incomes-line-graph"> <GraphDetailedIncomesExpenses/>
                     <SGBlock component="p">
                        <SGTextIntl intlId="graphs.info-legend"/>
                     </SGBlock></SGContent>,
                  <SGContent key="incomes-line-graph"> <GraphIncomesExpenses/></SGContent>,
               ]} tabsNames={["line", "bar"]}/>
            </SGCard>
         </SGSpace>
         {localStorage.getItem(HIDE_PROJECTS_INFO_MODAL) !== "true" && (
            <InfoModal textId="projects.info-modal" cypressName="projects-info-modal" transformations={{br: <br/>}}
                       titleId="projects.info-modal.title"
                       embeddedContent={
                          <SGCheckBox data-cy="projects-info-modal-checkbox" defaultChecked={false}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) => localStorage.setItem(HIDE_PROJECTS_INFO_MODAL, `${event.target.checked}`)}>
                             <SGTextIntl intlId="common.stop-displaying"/>
                          </SGCheckBox>
                       }
            />
         )}
      </SGContent>
   );
}

export {SimulationsPage};
