import React, {FunctionComponent, useEffect,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {Tax,} from "../../../../../store/tax/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {ProjectType} from "../../../../../store/projects/types";
import {useTaxExemptedAssets} from "../../../hooks/useTaxExemptedAssets";
import {useTaxCredits} from "../../../hooks/useTaxCredits";
import {useExpenses} from "../../../hooks/useExpenses";
import {NameAndValuationCollapsableFrames} from "../../../molecules/NameAndValuationCollapsableFrames/NameAndValuationCollapsableFrames";
import {useTaxSituation} from "../../../hooks/useTaxSituation";
import {updateAccountAction} from "../../../../../store/account/actions";
import {useAccount} from "../../../hooks/useAccount";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SGGridCol, SGGridRow} from "sg-grid";
import {State} from "../../../../../store/store";

const ProfileTaxation: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const taxSituation = useTaxSituation(true);
   const taxExemptedAssets = useTaxExemptedAssets();
   const taxCredits = useTaxCredits();
   const expenses = useExpenses()
   const account = useAccount()
   const hasFetchedExpenses = useSelector<State, boolean>((state) => state.expenses.hasFetchedExpenses);
   const hasFetchedTaxCredits = useSelector<State, boolean>((state) => state.tax.hasFetchedTaxCredits);

   useEffect(() => {
      if (!account.visitedTaxSimulator) {
         dispatch(updateAccountAction({...account, visitedTaxSimulator: true}));
      }
   }, []);

   return (
      <>
         <SGTitleIntl intlId="profile.taxation.list.title" cypressName="profile-taxation-list-title"/>
         <SGGridRow>
            <SGGridCol xs={12} xl={6}>
               {/* Pensions alimentaires */}
               <SpinnerWrapper displayComponent={hasFetchedExpenses} spinnerSize={SpinnerSize.MD} fatalErrorOnTimeout={false}>
                  {hasFetchedExpenses && (
                     <NameAndValuationCollapsableFrames title="profile.tax.child.support.expense.title" emptyListText="profile.tax.no.child.support.expense"
                                                        cypressName="profile-taxation-child-expense"
                                                        childSupportExpenses={expenses.filter((expense) => expense.type === ProjectType.CHILD_SUPPORT_EXPENSE)}
                     />
                  )}
               </SpinnerWrapper>
               {/* Avantages fiscaux */}
               <SpinnerWrapper displayComponent={hasFetchedTaxCredits} spinnerSize={SpinnerSize.MD} fatalErrorOnTimeout={false}>
                  {hasFetchedTaxCredits && (
                     <NameAndValuationCollapsableFrames emptyListText="profile.tax.no.tax.credit" title="profile.tax.credit.title"
                                                        cypressName="profile-taxation-tax-credits" taxCredits={taxCredits}/>
                  )}
               </SpinnerWrapper>
            </SGGridCol>
            <SGGridCol xs={12} xl={6}>
               {/* Immobilier défiscalisé */}
               <SpinnerWrapper displayComponent={!!taxExemptedAssets} spinnerSize={SpinnerSize.MD} fatalErrorOnTimeout={false}>
                  {!!taxExemptedAssets && (
                     <NameAndValuationCollapsableFrames title="profile.tax.rent.tax.credit.title"
                                                        cypressName="profile-taxation-rent-tax-credits" rentTaxCredits={taxExemptedAssets}
                                                        emptyListText="profile.tax.no.rent.tax.credit.expense"
                                                        amountSubTitle={
                                                           taxExemptedAssets.length === 1
                                                              ? intl.formatMessage({id: "tax.credit.rent.amount.subtitle"}, {amount: taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.taxDeduction, 0)})
                                                              : undefined
                                                        }/>
                  )}
                  {!!taxExemptedAssets && taxExemptedAssets.length > 1 && (
                     <SGTextIntl intlId="tax.credit.total"
                                 transformations={{taxCreditTotal: taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.taxDeduction, 0)}}/>
                  )}
               </SpinnerWrapper>
            </SGGridCol>
         </SGGridRow>
      </>
   );
};

export {ProfileTaxation};
