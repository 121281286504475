import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStrokedModification,
} from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { DashboardAge, DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { MaxRisErrorModal } from "website/components/molecules/MaxRisErrorModal/MaxRisErrorModal";
import { PARAM_RESULTAT, QUERY_PARAM_ETAPE } from "website/pages/ParcoursRISPage";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { PARCOURS_RIS, SIMULATEUR_FLASH } from "website/utils/privateRoutes";
import "./PensionMobile.scss";

interface PensionProps {
  selectedRetirementAge?: DashboardAge;
}

export const PensionMobile: FunctionComponent<PensionProps> = (
  props: PensionProps
) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedRetirementAge } = props;
  const [montantPension, setMontantPension] = useState<number>();
  const [isParcoursRISDone, setIsParcoursRISDone] = useState(false);
  const [affichePopin, setAffichePopin] = useState<boolean>(false);
  const [accessibiliteRisClicked, setAccessibiliteRisClicked] =
    useState<boolean>(false);
  const { disponibiliteSimulateurFlash, disponibiliteParcoursRIS } =
    useFeatures();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const intl = useIntl();
  const { trackClick } = useTracking();

  useEffect(() => {
    setIsParcoursRISDone(dashboardState?.dashboard?.risDate !== undefined);
  }, [dashboardState]);

  useEffect(() => {
    if (
      selectedRetirementAge &&
      selectedRetirementAge.retirementMonthlyIncome
    ) {
      setMontantPension(
        roundDown10(selectedRetirementAge.retirementMonthlyIncome)
      );
    }
  }, [selectedRetirementAge]);

  const handleOnClickModifier = () => {
    trackClick(
      `clic-sur-modification-${isParcoursRISDone ? "ris" : "simulateur-flash"}`
    );
    if (isParcoursRISDone) {
      setAffichePopin(false);
      setAccessibiliteRisClicked(true);
    } else {
      history.push(SIMULATEUR_FLASH);
    }
  };

  const handleOnClickLinkRIS = () => {
    if (isParcoursRISDone) {
      // Action consulter
      trackClick("clic-sur-consulter-mon-analyse-ris");
      history.push(`${PARCOURS_RIS}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`);
    } else {
      // Action analyser
      trackClick("clic-sur-analyser-mon-ris");
      setAccessibiliteRisClicked(true);
    }
  };

  const redirectToParcoursRIS = () => {
      history.push(PARCOURS_RIS);
  };

  return (
    <SGCard
      bordered={false}
      coversize="sm"
      className={`pension-mobile__card ${
        isParcoursRISDone && "pension-mobile__card-color"
      }`}
      hoverable
      title={
        <>
          <SGBox padding={{ left: "sm", right: "sm", bottom: "md" }}>
            <SGGridRow justify="center" gutter={[0, 12]}>
              <SGGridCol className="espaces-insecables">
                <SGText titlerender>
                  {intl.formatMessage({ id: `dashboard.pension.title` })}
                </SGText>
                {((!isParcoursRISDone && disponibiliteSimulateurFlash) ||
                  (isParcoursRISDone && disponibiliteParcoursRIS)) && (
                  <BoutonSupervision type="icon" onClick={handleOnClickModifier}>
                    <SGIcon
                      size="s"
                      component={<SGAvenirStrokedModification />}
                      align="text-top"
                    />
                  </BoutonSupervision>
                )}
              </SGGridCol>
              {montantPension && (
                <SGGridCol textalign="center" span={12}>
                  <SGText
                    weight="700"
                    type="number"
                    style={{
                      color: "#9F2D62",
                      fontSize: "1.5rem",
                      lineHeight: "0.5",
                    }}
                  >
                    {intl.formatMessage(
                      {
                        id: "dashboard.pension.montant",
                      },
                      {
                        salary: montantPension?.toLocaleString("fr-FR"),
                        label: (word: string) => (
                          <SGText weight="600" size="s">
                            {word}
                          </SGText>
                        ),
                        linebreak: <br />,
                      }
                    )}
                  </SGText>
                </SGGridCol>
              )}
            </SGGridRow>
          </SGBox>
          <SGDivider key="divider" disableautomargin />
        </>
      }
    >
      <SGBox textalign="center" margin={{ left: "xs" }}>
        {disponibiliteParcoursRIS || isParcoursRISDone ? (
          <SGButton
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
            onClick={handleOnClickLinkRIS}
          >
            <FormattedMessage
              id={
                isParcoursRISDone
                  ? "dashboard.button.ris.consulter"
                  : "dashboard.button.ris.analyse"
              }
            />
          </SGButton>
        ) : (
          <SGText>
            {intl.formatMessage({ id: "dashboard.pension.ris.indisponible" })}
          </SGText>
        )}
      </SGBox>
      <MaxRisErrorModal
        accessibiliteRisClicked={accessibiliteRisClicked}
        setAccessibiliteRisClicked={setAccessibiliteRisClicked}
        redirectToParcoursRIS={redirectToParcoursRIS}
      />
    </SGCard>
  );
};
