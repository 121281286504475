import React, {FunctionComponent, ReactNode, useState} from "react";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {Project, ProjectFeasibility, ProjectPlanStatus, ProjectType,} from "../../../../store/projects/types";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {getMeteoMessageIds, getMeteoPictureAsString,} from "../../../../store/projects/utils";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {SITUATION} from "../../../utils/privateRoutes";
import {SGModal} from "sg-modal";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {
   SGAvenirStyledMeteoEnsoleille,
   SGAvenirStyledMeteoPartiellementEnsoleille,
   SGAvenirStyledMeteoPartiellementNuageux,
   SGAvenirStyledPrendreContact,
   SGAvenirStyledVirementInstantane
} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";
import {useProjects} from "../../hooks/useProjects";

interface ProjectMeteoProps {
   projectFeasibility: ProjectFeasibility
}

const ProjectMeteo: FunctionComponent<ProjectMeteoProps> = (props: ProjectMeteoProps) => {
   const {projectFeasibility} = props

   const [isShowLinkModalVisible, setShowLinkModalVisible] = useState<boolean>(false);

   const projects = useProjects(false, false);

   const hasFetchedProjectFeasibility = useSelector<State, boolean>((state) => (state.projects.hasFetchedFeasibilities || state.projects.hasFetchedFeasibilitiesError) && state.projects.hasFetchedProjects);

   const mustShowLinkForDataEditing = (): boolean => [ProjectPlanStatus.KO, ProjectPlanStatus.WARN].includes(projectFeasibility.plan.status);

   const getMeteoPicture = (projectFeasibility: ProjectFeasibility): ReactNode => {
      switch (getMeteoPictureAsString(projectFeasibility)) {
         case "Sun":
            return <SGIcon size="xxl" component={<SGAvenirStyledMeteoEnsoleille/>}/>;
         case "SunWithCloud2":
            return <SGIcon size="xxl" component={<SGAvenirStyledMeteoPartiellementEnsoleille/>}/>;
         case "SunWithCloud1":
            return <SGIcon size="xxl" component={<SGAvenirStyledMeteoPartiellementNuageux/>}/>;
         case "Thunder":
            return <SGIcon size="xxl" component={<SGAvenirStyledVirementInstantane/>}/>;
         default:
            return "";
      }
   };

   return (
      <>
         <SpinnerWrapper fatalErrorOnTimeout={false} displayComponent={hasFetchedProjectFeasibility}
                         spinnerSize={SpinnerSize.LG}>
            <SGSpace direction="vertical" disableautomargin data-cy="project-meteo">
               {getMeteoPicture(projectFeasibility)}
               <SGTextIntl strong
                           intlId={getMeteoMessageIds(projectFeasibility, projects.some((p: Project) => p.projectType === ProjectType.RETIREMENT_PROJECT))[0]}/>
               <SGTextIntl intlId={getMeteoMessageIds(projectFeasibility, projects.some((p: Project) => p.projectType === ProjectType.RETIREMENT_PROJECT))[1]}/>
               {mustShowLinkForDataEditing() && (
                  <SGButtonIntl type="link" onClick={() => setShowLinkModalVisible(true)} intlId="project.feasibility.edit.data"/>
               )}
            </SGSpace>
         </SpinnerWrapper>
         <SGModal visible={isShowLinkModalVisible} onCancel={() => setShowLinkModalVisible(false)}>
            <SGMessage
               icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
               title={<SGTitleIntl intlId="project.feasibility.modal.title" cypressName="project-meteo-modal-title"/>}>
               <SGSpace direction="vertical">
                  <SGTextIntl intlId="project.feasibility.modal.see.projects.link"/>
                  <SGButtonIntl type="link" onClick={() => redirectToRelativePath(SITUATION)} intlId="project.feasibility.modal.see.profile.link"/>
                  <SGTextIntl intlId="project.feasibility.modal.text.forgotten" italic/>
                  <SGTextIntl intlId="project.feasibility.modal.see.advisor"/>
               </SGSpace>
            </SGMessage>
         </SGModal>
      </>
   );
};

export {ProjectMeteo};
