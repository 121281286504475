import React, {FunctionComponent} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {ProjectState, ProjectType} from "../../../../../store/projects/types";
import {createProjectAction, getAdditionalIncomeObjectiveAction,} from "../../../../../store/projects/actions";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {State} from "../../../../../store/store";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {convertNumberToStringWithSeparators} from "../../../../utils/formatting/numberFormatter";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGCard} from "sg-card";

interface AdditionalIncomeProjectFormData {
   duration: string;
   horizon: string;
   amount: number;
}

const AdditionalIncomeProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const projectState: ProjectState = useSelector<State, ProjectState>(
      (state) => state.projects
   );

   const calculateObjective = () => {
      dispatch(getAdditionalIncomeObjectiveAction(methods.getValues("amount") ? methods.getValues("amount") : 0, methods.getValues("duration")));
   };

   const onSubmit = (data: AdditionalIncomeProjectFormData) => {
      dispatch(
         createProjectAction({
            amount: projectState.additionalIncomeObjective,
            projectType: ProjectType.ADDITIONAL_INCOME,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            duration: data.duration,
            projectName: "Revenu complémentaire",
            recurrentFees: data.amount,
         })
      );
   };

   const availableHorizonOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
   const availableDuringOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];

   return (
      <>
         <SGTitleIntl intlId="project.additional.income.title" cypressName="additional-income-project-title"/>
         <SGTextIntl intlId="project.additional.income.subtitle"/>
         <FormProvider {...methods}>
            <form>
               <SGCard data-cy="additional-income-project-frame">
                  <FormInputs colLength={10}>
                     <SelectInput name="horizon" cypressName="additional-income-select-horizon" defaultValue={5} label="project.additional.income.form.horizon">
                        {availableHorizonOptions.map(
                           (horizon: number) => (
                              <SGSelectOption key={horizon} value={horizon}>
                                 {horizon === 0
                                    ? intl.formatMessage({id: "common.now"})
                                    : horizon +
                                    intl.formatMessage({id: "common.years"})}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>
                     <NumericInput name="amount" suffix="common.euro.per.month" cypressName="additional-income-amount"
                                   min={200} max={15_000} onValueChange={() => calculateObjective()}
                                   label="project.additional.income.form.amount"
                                   placeholder={intl.formatMessage({id: "project.additional.income.form.amount.placeholder"})}/>
                     <SelectInput name="duration" cypressName="additional-income-select-duration" defaultValue={5} onValueChange={() => calculateObjective()}
                                  label="project.additional.income.form.duration">
                        {availableDuringOptions.map(
                           (horizon: number) => (
                              <SGSelectOption key={horizon} value={horizon}>
                                 {horizon + intl.formatMessage({id: "common.years"})}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>
                  </FormInputs>

                  {projectState.additionalIncomeObjective !== undefined &&
                     !!methods.getValues("amount") && (
                        <SGAlertIntl cypressName="project-additional-income-frame" type="warning" title="common.warning">
                           <SGTextIntl intlId="project.additional.income.info.frame" transformations={{
                              amount: convertNumberToStringWithSeparators(
                                 methods.getValues("amount")
                              ),
                              objective: convertNumberToStringWithSeparators(
                                 projectState.additionalIncomeObjective
                              ),
                           }}/>
                        </SGAlertIntl>
                     )}
               </SGCard>

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="additional-income-next"
                                onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                  <SGButtonIntl type="link" cypressName="additional-income-previous" onClick={() => redirectToRelativePath(NEW_PROJECTS)}
                                intlId="common.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>
      </>
   );
};

export {AdditionalIncomeProject};
