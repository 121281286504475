import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {DateInput, DatePattern} from "../../atoms/DateInput/DateInput";
import {CustodyType, EmploymentStatus, FamilyStatus, MaritalStatus, MatrimonialRegime, Member} from "../../../../store/members/types";
import {addMemberInStore, updateMemberInStore} from "../../../../store/members/membersSlice";
import {
   calculateAgeByBirthdate,
   getAmountOfMembersWithSameStatus,
   getAvailableCustodyTypes,
   getDefaultCustodyType,
   getDefaultMatrimonialRegime,
   getMatrimonialRegimes,
   isIsolatedParentStatus,
   MAX_AGE_TO_RETIRE,
   MIN_AGE_TO_RETIRE
} from "../../../../store/members/utils";
import {MemberDeleteModal} from "../MemberDeleteModal/MemberDeleteModal";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {getMaxDateForMyBirthdate, getMinDateForMyBirthdate, toddMMyyyyStringWithSlash, utcDate} from "../../../utils/date/DateUtils";
import {EditorContext} from "../../providers/EditorProvider";
import {SGButton, SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {useFamily} from "../../hooks/useFamily";
import {SGCard} from "sg-card";
import {SGAvenirStrokedCorbeille, SGAvenirStrokedModification} from "sg-icon-pack-base";
import {SGText, SGTitle} from "sg-typo";
import {SGIcon} from "sg-icon";
import {SGSpace} from "sg-space";
import {SG_RED} from "../../../utils/graphs/colors";
import {SGGridCol, SGGridRow} from "sg-grid";

interface MemberElementProps {
   member: Member;
   onCancel?: () => void;
   isCreating?: boolean;
   cypressName: string;
}

const MemberElement: FunctionComponent<MemberElementProps> = (props: MemberElementProps) => {
   const {member, onCancel, isCreating, cypressName} = props;

   const [infoIsolatedParentVisible, setInfoIsolatedParentVisible] = useState<boolean>(member.status === FamilyStatus.ME && isIsolatedParentStatus(member.maritalStatus));

   const family = useFamily();

   const intl = useIntl();
   const dispatch = useDispatch();
   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });
   const {handleSubmit} = methods;

   const age: number | null = !member.birthday ? null : calculateAgeByBirthdate(member.birthday);
   const [displayWorkerStatus, setDisplayWorkerStatus] = useState((age != null && age < MAX_AGE_TO_RETIRE) || isCreating || age === null);
   const [displayRetiredStatus, setDisplayRetiredStatus] = useState((age != null && age > MIN_AGE_TO_RETIRE) || isCreating || age === null);
   const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<EmploymentStatus>(EmploymentStatus.WORKER);
   const [displayMatrimonialRegime, setDisplayMatrimonialRegime] = useState(member.maritalStatus === MaritalStatus.MARRIED);

   const updateAvailableEmploymentStatuses = (age: number) => {
      setDisplayWorkerStatus(age < MAX_AGE_TO_RETIRE);
      setDisplayRetiredStatus(age > MIN_AGE_TO_RETIRE);
   };

   // On cache le statut "Célibataire" pour le conjoint
   const maritalStatuses: MaritalStatus[] = Object.values(MaritalStatus).filter((ms) => member.status !== FamilyStatus.PARTNER || ms !== MaritalStatus.SINGLE);
   // Si l'on est en train de créer un membre, on ne peut pas sélectionner "Moi même" ni sélectionner "Mon conjoint" si l'on en a déjà un
   const familyStatuses: FamilyStatus[] = isCreating
      ? Object.values(FamilyStatus).filter((fs) => fs !== FamilyStatus.ME && (!family.partner || fs !== FamilyStatus.PARTNER))
      : Object.values(FamilyStatus);

   const {isEditing, setEditing} = useContext(EditorContext);

   const [isEditingMember, setEditingMember] = useState(false);
   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
   // Permet de gérer l'affichage des champs en fonction du membre
   const [displayCoupleFields, setDisplayCoupleFields] = useState(false);
   const [displayChildrenFields, setDisplayChildrenFields] = useState(false);

   // Permet de mettre le composant en édition à l'ajout d'un nouveau membre
   useEffect(() => {
      setEditingMember(!!isCreating);
      setDisplayCoupleFields(member.status === FamilyStatus.ME || member.status === FamilyStatus.PARTNER);
      setDisplayMatrimonialRegime(member.maritalStatus === MaritalStatus.MARRIED);
      setDisplayChildrenFields(member.status === FamilyStatus.CHILD);
   }, [member]);

   useEffect(() => {
      setEditing(isEditingMember);
   }, [isEditingMember]);

   const onSelectFamilyType = (newFamilyStatus: string) => {
      member.status = newFamilyStatus;
      methods.setValue("familyStatus", newFamilyStatus, {
         shouldValidate: true,
      });

      const amountOfMembersWithSameStatus = getAmountOfMembersWithSameStatus(family, newFamilyStatus);
      const idFamilyStatus = `family.status.${newFamilyStatus}`;
      const defaultName = amountOfMembersWithSameStatus < 1 ? intl.formatMessage({id: idFamilyStatus})
         : `${intl.formatMessage({id: idFamilyStatus})} ${amountOfMembersWithSameStatus + 1}`;

      methods.setValue("name", defaultName, {shouldValidate: true});

      setDisplayCoupleFields(newFamilyStatus === FamilyStatus.ME || newFamilyStatus === FamilyStatus.PARTNER);
      setDisplayChildrenFields(newFamilyStatus === FamilyStatus.CHILD);
   };

   const onSelectMaritalStatus = (newMaritalStatus: string) => {
      methods.setValue("maritalStatus", newMaritalStatus, {shouldValidate: true});

      if (newMaritalStatus !== MaritalStatus.MARRIED) {
         setDisplayMatrimonialRegime(false);
         methods.setValue("matrimonialRegime", null);
      } else {
         setDisplayMatrimonialRegime(true);
      }
      setInfoIsolatedParentVisible(member.status === FamilyStatus.ME && isIsolatedParentStatus(newMaritalStatus));
   };

   const onSelectMatrimonialRegime = (newMatrimonialRegime: string) => {
      methods.setValue("matrimonialRegime", newMatrimonialRegime, {shouldValidate: true});
   };

   const onSelectEmploymentStatus = (newEmploymentStatus: string) => {
      setSelectedEmploymentStatus(newEmploymentStatus);
   };

   const onSelectBirthday = (date: Date) => {
      const age = calculateAgeByBirthdate(date);
      updateAvailableEmploymentStatuses(age);
      if (member.status === FamilyStatus.ME || member.status === FamilyStatus.PARTNER) {
         if (age >= MAX_AGE_TO_RETIRE) {
            setSelectedEmploymentStatus(EmploymentStatus.RETIRED);
         } else if (age <= MIN_AGE_TO_RETIRE) {
            setSelectedEmploymentStatus(EmploymentStatus.WORKER);
         }
      }
   };

   function onSubmit(data: Member & Required<Pick<Member, "birthday" | "name">>) {
      const currentMember = {...member, ...data, birthday: utcDate(data.birthday), employmentStatus: selectedEmploymentStatus};
      if (currentMember.status !== FamilyStatus.CHILD) {
         currentMember.custodyType = undefined;
      }
      if (currentMember.status !== FamilyStatus.PARTNER && currentMember.status !== FamilyStatus.ME) {
         currentMember.maritalStatus = undefined;
         currentMember.matrimonialRegime = undefined;
      }
      if (currentMember.maritalStatus !== MaritalStatus.MARRIED) {
         currentMember.matrimonialRegime = undefined;
      }

      if (isCreating) {
         dispatch(addMemberInStore(currentMember));
      } else {
         dispatch(updateMemberInStore({newMember: currentMember, oldMember: member}));
      }

      setEditingMember(false);
   }

   function getMemberSituation(): string {
      if (member.status === FamilyStatus.ME || member.status === FamilyStatus.PARTNER) {
         if (displayMatrimonialRegime) {
            return intl.formatMessage({
                  id: 'member.situation.text'
               },
               {
                  maritalStatus: intl.formatMessage({id: `marital.status.${member.maritalStatus}`}),
                  matrimonialRegime: intl.formatMessage({id: `matrimonial.regime.${member.matrimonialRegime}`}).toLowerCase()
               }
            );
         }

         return intl.formatMessage({
               id: `marital.status.${member.maritalStatus}`,
            }
         );
      }

      return '\xa0'; // Espace insécable pour l'alignement des SGCard de MemberElement
   }

   function getMemberStatus(): string {
      if (member.employmentStatus != null && (member.status === FamilyStatus.ME || member.status === FamilyStatus.PARTNER)) {
         return intl.formatMessage({id: `employment.status.${member.employmentStatus}`});
      }
      if (member.custodyType != null && member.status === FamilyStatus.CHILD) {
         return intl.formatMessage({id: `custody.type.${member.custodyType}`});
      }

      return '\xa0'; // Espace insécable pour l'alignement des SGCard de MemberElement
   }

   const renderMemberElement = () => (
      <SGGridRow>
         <SGGridCol span={9}>
            <SGSpace direction="vertical" size="sm" disableautomargin>
               <SGTitle level={3} data-cy={`${cypressName}-display-name`}>
                  {member.name}
               </SGTitle>
               <SGTextIntl size="m" strong intlId="member.birthdate" transformations={{
                  birthdate: member.birthday ? toddMMyyyyStringWithSlash(member.birthday) : intl.formatMessage({id: "member.birthdate.not.present"})
               }} cypressName={`${cypressName}-display-birthday`}/>
               <SGText strong data-cy={`${cypressName}-display-status`}>
                  {getMemberStatus()}
               </SGText>
               <SGText strong data-cy={`${cypressName}-display-situation`}>
                  {getMemberSituation()}
               </SGText>
            </SGSpace>
         </SGGridCol>
         <SGGridCol span={3} align="end" textalign="right">
            <SGButton type="icon" data-cy={`${cypressName}-edit-button`} disabled={isEditing} onClick={() => setEditingMember(true)}>
               <SGIcon component={<SGAvenirStrokedModification/>}/>
            </SGButton>
            <SGButton type="icon" data-cy={`${cypressName}-delete-button`} disabled={member.status === FamilyStatus.ME || isEditing}
                      onClick={() => setDeleteModalVisible(true)} disableautomargin>
               <SGIcon component={<SGAvenirStrokedCorbeille/>}/>
            </SGButton>
         </SGGridCol>
      </SGGridRow>
   );

   const renderMemberEdit = () => (
      <FormProvider {...methods}>
         <form>
            <FormInputs colLength={6}>
               <SelectInput disabled={!isCreating} onValueChange={onSelectFamilyType} cypressName={`${cypressName}-edit-status`} name="familyStatus"
                            required label="member.form.status" defaultValue={member.status}>
                  {familyStatuses.map(
                     (familyStatus: FamilyStatus) => (
                        <SGSelectOption key={familyStatus} data-cy={`${cypressName}-edit-status-${familyStatus}`} value={familyStatus}>
                           {intl.formatMessage({id: `family.status.${familyStatus}`})}
                        </SGSelectOption>
                     )
                  )}
               </SelectInput>

               <StringInput defaultValue={member.name} placeholder="member.form.placeholder.name" name="name" minLength={1} maxLength={140}
                            cypressName={`${cypressName}-edit-name`} label="member.form.name"/>

               <DateInput cypressName={`${cypressName}-edit-birthday-container`} onDateChange={(value: Date) => {
                  onSelectBirthday(value);
               }} label="member.form.birthdate" pattern={DatePattern.DAYS} name="birthday" placeholder="member.form.placeholder.birthdate"
                          min={displayCoupleFields ? getMinDateForMyBirthdate() : "1935-01-01"}
                          defaultValue={member.birthday} max={displayCoupleFields ? getMaxDateForMyBirthdate() : new Date()}/>

               {displayCoupleFields && (
                  <SelectInput onValueChange={onSelectMaritalStatus} name="maritalStatus" defaultValue={member.maritalStatus}
                               cypressName={`${cypressName}-edit-marital-status`} label="member.form.situation"
                               tooltipLabel={infoIsolatedParentVisible ? "member.isolated.parent.info" : ""}>
                     {maritalStatuses.map(
                        (maritalStatus: MaritalStatus) => (
                           <SGSelectOption key={maritalStatus} data-cy={`${cypressName}-edit-marital-status-${maritalStatus}`} value={maritalStatus}>
                              {intl.formatMessage({
                                 id: `marital.status.${maritalStatus}`,
                              })}
                           </SGSelectOption>
                        )
                     )}
                  </SelectInput>
               )}

               {displayChildrenFields && (
                  <SelectInput name="custodyType"
                               defaultValue={(member.custodyType && getAvailableCustodyTypes(family).includes(member.custodyType)) ? member.custodyType : getDefaultCustodyType(family.me)}
                               required cypressName={`${cypressName}-edit-custody-type`} label="member.form.custody.type">
                     {getAvailableCustodyTypes(family).map(
                        (custodyType: CustodyType) => (
                           <SGSelectOption key={custodyType} data-cy={`${cypressName}-edit-custody-type-${custodyType}`} value={custodyType}>
                              {intl.formatMessage({
                                 id: `custody.type.${custodyType}`,
                              })}
                           </SGSelectOption>
                        )
                     )}
                  </SelectInput>
               )}

               {displayCoupleFields && (
                  <SelectInput onValueChange={onSelectEmploymentStatus} required defaultValue={member.employmentStatus}
                               cypressName={`${cypressName}-edit-employment-status`} name="employmentStatus" label="member.form.employment.status"
                               tooltipLabel="profile.family.tooltip.employment.status">
                     {displayWorkerStatus && (
                        <SGSelectOption data-cy={`${cypressName}-edit-employment-status-${EmploymentStatus.WORKER}`} value={EmploymentStatus.WORKER}>
                           {intl.formatMessage({id: `employment.status.${EmploymentStatus.WORKER}`})}
                        </SGSelectOption>
                     )}
                     {displayRetiredStatus && (
                        <SGSelectOption data-cy={`${cypressName}-edit-employment-status-${EmploymentStatus.RETIRED}`} value={EmploymentStatus.RETIRED}>
                           {intl.formatMessage({id: `employment.status.${EmploymentStatus.RETIRED}`})}
                        </SGSelectOption>
                     )}
                  </SelectInput>
               )}
               {displayMatrimonialRegime && (
                  <SelectInput onValueChange={onSelectMatrimonialRegime} name="matrimonialRegime" required label="member.form.matrimonial.regime"
                               defaultValue={getDefaultMatrimonialRegime(member.matrimonialRegime)}
                               cypressName={`${cypressName}-edit-matrimonial-regime`}>
                     {getMatrimonialRegimes().map((matrimonialRegime: MatrimonialRegime) => (
                        <SGSelectOption key={matrimonialRegime} data-cy={`${cypressName}-edit-matrimonial-regime-${matrimonialRegime}`}
                                        value={matrimonialRegime}>
                           {intl.formatMessage({id: `matrimonial.regime.${matrimonialRegime}`})}
                        </SGSelectOption>
                     ))}
                  </SelectInput>
               )}
            </FormInputs>

            <SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName={`${cypressName}-edit-submit`}
                             onClick={handleSubmit(onSubmit)} intlId={isCreating ? "common.add" : "common.update"}/>
               <SGButtonIntl type="secondary" size="sm" cypressName={`${cypressName}-edit-cancel`}
                             onClick={() => (onCancel ? onCancel() : setEditingMember(false))} intlId="common.cancel"/>
            </SGButtonGroup>
         </form>
      </FormProvider>
   );

   return (
      <SGCard data-cy={`${cypressName}-display`} borderleft={`.3rem solid ${SG_RED.hexCode}`}>
         {isEditingMember && renderMemberEdit()}
         {!isEditingMember && renderMemberElement()}

         <MemberDeleteModal isVisible={isDeleteModalVisible} setVisible={setDeleteModalVisible} member={member}/>
      </SGCard>
   );
};

export {MemberElement};
