import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Media, TypeMedia } from "website/components/hooks/articles";
import { useArticles } from "website/components/hooks/useArticles";
import { CategorieVideo, useVideos } from "website/components/hooks/useVideos";
import { ArticlePage } from "./ArticlePage/ArticlePage";
import { PauseVideoProvider } from "website/components/providers/PauseVideoProvider";
import { VideoPage } from "./VideoPage/VideoPage";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface MediaParams {
  mediaPath: string;
}

export const MediaPage: FunctionComponent = () => {
  const { mediaPath } = useParams<MediaParams>();
  const [media, setMedia] = useState<Media>();
  const { articlesTries } = useArticles();
  const videos = useVideos(CategorieVideo.ARTICLES);
  const { trackPage } = useTracking();

  useEffect(() => {
    const allMedia = [...videos, ...articlesTries];
    setMedia(allMedia.find((m) => m.path === mediaPath));
  }, [videos, articlesTries, mediaPath]);

  useEffect(() => {
      trackPage("comprendre-ma-retraite",mediaPath, "fiche-article");
  }, [mediaPath]);

  return (
    <PauseVideoProvider>
      {media?.type !== TypeMedia.VIDEO && mediaPath === media?.path && (
        <ArticlePage media={media} />
      )}
      {media?.type === TypeMedia.VIDEO && mediaPath === media.path && (
        <VideoPage media={media} />
      )}
    </PauseVideoProvider>
  );
};
