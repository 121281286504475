import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGDrawer } from "sg-drawer";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStrokedOeil,
  SGAvenirStrokedTelechargement,
} from "sg-icon-pack-base";
import { SGProgress } from "sg-progress";
import { SGBox, SGSpace } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { SGText } from "sg-typo";
import { telechargerSyntheseRetraite } from "store/pdf/actions";
import { PdfState } from "store/pdf/types";
import { State } from "store/store";
import { Parcours } from "website/components/hooks/dashboard/useDashboardProgression";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { ProgressionPopHoverMob } from "website/components/organisms/Dashboard/Progression/ProgressionPopHoverMob";
import { MODIFIER_PROFIL } from "website/utils/privateRoutes";
import "./ProgressionMob.scss";

interface ProgressionProps {
  percent: number;
  parcoursDoneTab: Parcours[];
}

export const ProgressionMob: FunctionComponent<ProgressionProps> = (
  props: ProgressionProps
) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { trackClick, trackPopin, trackDownload, resetTracking } =
    useTracking();
  const [nextStep, setNextStep] = useState<Parcours>();

  const { percent, parcoursDoneTab } = props;
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const history = useHistory();

  const pdfState = useSelector<State, PdfState>((state) => state.pdf);
  const estSupervisionActive: boolean = useSelector<State, boolean>((state) => state.supervision.estActive);

  useEffect(() => {
    setNextStep(
      parcoursDoneTab.filter((parcours) => parcours.date === undefined)[0]
    );
  }, [parcoursDoneTab]);

  useEffect(() => {
    if (pdfState.erreur) {
      setShowDrawer(false);
    }
  }, [pdfState.erreur]);

  useEffect(() => {
    if (showDrawer) {
      trackPopin(false, "tableau-de-bord", "popin-progression");
    } else {
      resetTracking();
    }
  }, [showDrawer]);

  const handleOnClickTelechargerSynthese = () => {
    trackDownload("popin-progression::telechargement-synthese-retraite");
    dispatch(telechargerSyntheseRetraite());
  };

  const handleOnClickLinkProfil = () => {
    trackClick("clic-sur-modifier-mon-profil");
    history.push(MODIFIER_PROFIL);
  };

  const renderDrawer = () => (
    <SGDrawer
      visible={showDrawer}
      onClose={() => setShowDrawer(false)}
      placement="bottom"
      title={
        <SGText size="xxl" weight="700" titlerender>
          {intl.formatMessage(
            { id: "progression.popHoverMobile.title" },
            {
              blue: (word: string) => <SGText color="info">{word}</SGText>,
              pourcentage: percent,
              linebreak: <br />,
            }
          )}
        </SGText>
      }
    >
      <SGBox margin={{ top: "md", bottom: "xxl" }}>
        <SGSpace direction="vertical" disableautomargin>
          <ProgressionPopHoverMob key="progression" />
              <SGDivider key="divider" />
              <SGButton
                key="button"
                type="link"
                icon={
                  <SGIcon
                    component={
                      pdfState.enChargement ? (
                        <SGSpinner size="md" />
                      ) : (
                        <SGAvenirStrokedTelechargement />
                      )
                    }
                  />
                }
                onClick={handleOnClickTelechargerSynthese}
                disabled={pdfState.enChargement || estSupervisionActive}
              >
                {intl.formatMessage({ id: "dashboard.pdf.telecharger" })}
              </SGButton>
        </SGSpace>
      </SGBox>
    </SGDrawer>
  );

  return (
    <SGCard
      className="progression"
      disableautomargin
      title={
        <>
          <button
            onClick={() => {
              trackClick("clic-sur-progression");
              setShowDrawer(true);
            }}
            type="button"
            className="progression-mob__button"
          >
            <SGGridRow align="middle" span={12}>
              <SGGridCol flex="1 0 0%">
                <SGText size="m" weight="600">
                  {intl.formatMessage(
                    { id: "dashboard.progression.title.link" },
                    { pourcentage: percent.toLocaleString("fr-FR") }
                  )}
                </SGText>
                {nextStep !== undefined && (
                  <SGBox margin={{ bottom: "xxs" }}>
                    <SGText size="xs" weight="400" color="info">
                      {intl.formatMessage({
                        id: "dashboard.progression.prochaine.etape",
                      })}
                      {intl.formatMessage({
                        id: `progression.popHover.check.${nextStep.nom}`,
                      })}
                    </SGText>
                  </SGBox>
                )}
                <SGProgress
                  size="lg"
                  percent={percent}
                  showInfo={false}
                  disableautomargin
                  status="normal"
                />
              </SGGridCol>

              <SGGridCol flex="initial" align="center" className="progression__loupe">
                <SGButton
                  ariaLabel={intl.formatMessage({
                    id: "dashboard.progression.ouvrir",
                  })}
                  disableautomargin
                  type="icon"
                  icon={<SGIcon size="l" component={<SGAvenirStrokedOeil />} />}
                />
              </SGGridCol>
            </SGGridRow>
          </button>
          {renderDrawer()}
        </>
      }
      actions={[
        <SGBox
          margin={{
            top: "sm",
          }}
        >
          <SGButton
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
            onClick={handleOnClickLinkProfil}
          >
            {intl.formatMessage({
              id: `dashboard.progression.profile`,
            })}
          </SGButton>
        </SGBox>,
      ]}
      actionsdivider
      textalign="center"
    />
  );
};
