import { ReactComponent as ChevronDown } from "assets/images/chevron-down-solid.svg";
import { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGButton } from "sg-button";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLayout } from "sg-layout";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { Contenu } from "website/components/molecules/Article/ArticleModel";
import "./LegendImage.scss";

export interface LegendImageProps {
    legend: Contenu;
    setSelectedAnchor?: (legend: string) => void;
}

const LegendImage: FunctionComponent<LegendImageProps> = (props: LegendImageProps) => {
    const { legend, setSelectedAnchor } = props;
    const [openLegend, setOpenLegend] = useState<string>("")
    const [openDetails, setOpenDetails] = useState<boolean>(false)
    const onClickLink = (level : Contenu) => {
        const anchor = ("anchor" in level) ? level.anchor : ""
        const element = document.getElementById(anchor)
        element?.scrollIntoView();
        if (setSelectedAnchor) {
            setSelectedAnchor(anchor);
        }
    }

    const onClickChevron = (level : Contenu) => {
        setOpenDetails(openLegend === level.id ? !openDetails : true);
        setOpenLegend(level.id);
    }

    return (
        <SGLayout tagName="div" background="light">
            <SGBox padding={{right: "md", left: "md", top: "md", bottom: "md"}}>
                <SGGridRow gutter={[0,5]}>
                    <SGGridCol>
                        <SGBox margin={{ bottom: "xs" }}>
                            <SGText size="xl" weight="600">
                                {legend.h3}
                            </SGText>
                        </SGBox>
                    </SGGridCol>
                    <SGGridCol>
                        <SGGridRow gutter={[0,5]}>
                            { legend.children?.map((level : Contenu) => (
                                <>
                                <SGGridCol span={12}>
                                    <SGGridRow gutter={[0,2]}>
                                        <SGGridCol span={12} >
                                            <SGGridRow justify="middle">
                                                <SGGridCol span={1}>
                                                    <SGButton type="icon" onClick={() => onClickChevron(level)}>
                                                        <ChevronDown className={`legend-image__chevron ${(level.id === openLegend && openDetails) ? "legend-image__chevron--rotate" : ""}`} />
                                                    </SGButton>
                                                </SGGridCol>
                                                <SGGridCol flex="initial" align="center">
                                                    <div className={`legend-image__rectangle legend-image__rectangle_${level.color}`}/>
                                                </SGGridCol>
                                                <SGGridCol flex="1 0 0%">
                                                    <SGText weight="600" className="legend-image__level">
                                                            <FormattedMessage
                                                                id="level.h4"
                                                                defaultMessage={level.h4}
                                                                values={{
                                                                    link: (word: string) => <SGButton type="link" onClick={() => onClickLink(level)} >{word}</SGButton>,
                                                                }}
                                                            />
                                                    </SGText>
                                                </SGGridCol>
                                            </SGGridRow>
                                        </SGGridCol>

                                        {(level.id === openLegend && openDetails) && (
                                        <SGGridCol offset={1} >
                                            <SGText className="legend-image__level">
                                                <FormattedMessage
                                                    id="level.text"
                                                    defaultMessage={level.text}
                                                    values={{
                                                        i: (word: string) => <i>{word}</i>,
                                                    }}
                                                />
                                            </SGText>
                                        </SGGridCol>
                                        )}
                                    </SGGridRow>
                                </SGGridCol>
                                <SGGridCol span={12} >
                                    <SGBox margin={{top: "xs", bottom: "xs"}}>
                                        <SGDivider disableautomargin/>
                                    </SGBox>
                                </SGGridCol>
                            </>
                            ))}
                        </SGGridRow>
                    </SGGridCol>
                </SGGridRow>
            </SGBox>
        </SGLayout>
    );
};

export { LegendImage };
