import { ReactComponent as IconArticle } from "assets/images/icon-article.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard, SGCardMeta } from "sg-card";
import { SGCarousel } from "sg-carousel";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGText, SGTitle } from "sg-typo";
import { Article } from "website/components/hooks/articles";
import { useArticles } from "website/components/hooks/useArticles";
import "./HomeCaroussel.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface HomeCarousselProps {
    currentArticlePath?: string;
    blockTracking: string;
}

/**
 * Composant qui définit le caroussel dédié aux articles
 *
 * @param props articles dans le caroussel et path de l'article courant
 * @returns Un composant caroussel en fonction des articles en entrées
 */
export const HomeCaroussel: FunctionComponent<HomeCarousselProps> = (props: HomeCarousselProps) => {
    const intl = useIntl();
    const { currentArticlePath, blockTracking } = props;
    const { articlesTries } = useArticles();
    const history = useHistory();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const isTab = useMediaQuery({ minwidth: "sm", maxwidth: "md" });
    const { getLienArticle } = useArticles();
    const { trackClick } = useTracking();

    function handleOnClick(path: string) {
        if (path === "") {
            return;
        }

        trackClick(`${blockTracking}::clic-sur-lire-l-article-${path}`);
        history.push(getLienArticle(path));
    }

    function getSlideToShow(): number {
        if (isTab) {
            return 2;
        }

        return isPhone ? 1 : 3;
    }

    return (
        <SGGridRow>
            <SGGridCol span={12}>
                <SGCarousel
                    slidesToScroll={1}
                    carouselMargin="15rem"
                    carouselPadding="10rem 5rem"
                    settings={{
                        infinite: true,
                        slidesToShow: getSlideToShow(),
                    }}
                >
                    {articlesTries
                        .filter((article) => article.path !== currentArticlePath)
                        .slice(0, 10)
                        .map((article) => (
                            <SGCard
                                className="home-caroussel__card-text"
                                hoverable
                                coversize="md"
                                key={article.path}
                                actions={[
                                    <SGButton
                                        key="lien"
                                        type="primarylink"
                                        icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                        iconposition="right"
                                        onClick={() => handleOnClick(article.path)}
                                    >
                                        {intl.formatMessage({ id: `articles.bouton.lire.${article.type}` })}
                                    </SGButton>,
                                ]}
                                cover={
                                    <div className="home-caroussel__cover-container">
                                        <img alt="cover" src={article?.image} />
                                        {article.time !== 0 && (
                                            <div className="home-caroussel__cover-text-container">
                                                <IconArticle />
                                                <SGText className="home-caroussel__timer-text" weight="600">
                                                    {article.time} MIN
                                                </SGText>
                                            </div>
                                        )}
                                    </div>
                                }
                            >
                                <SGCardMeta
                                    title={<SGTitle level={4}>{intl.formatMessage({ id: `home.article.title.${article.id}` })}</SGTitle>}
                                    description={
                                        <SGText className="home-caroussel__article-description">
                                            {intl.formatMessage({ id: `home.article.description.${article.id}` })}
                                        </SGText>
                                    }
                                />
                            </SGCard>
                        ))}
                </SGCarousel>
            </SGGridCol>
        </SGGridRow>
    );
};
