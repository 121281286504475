import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/store";
import { setSupervision } from "store/supervision/supervisionSlice";
import { SupervisionState } from "store/supervision/types";
import { LoadingStatus } from "./tracking/types";
import { useAccount } from "./useAccount";
import { usePrivateData } from "./usePrivateData";

export const useAppInit = () => {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.LOADING
  );
  const { idStat } = usePrivateData();

  const dispatch = useDispatch();
  const account = useAccount();

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);
  const hasFetchedAccount = useSelector<State, boolean>(
    (state) => state.account.hasFetched
  );

  useEffect(() => {
    if (hasFetchedAccount) {
      dispatch(setSupervision(account.supervision ?? false));
    }
  }, [hasFetchedAccount]);

  useEffect(() => {
    if (
      idStat !== undefined &&
      supervisionState.hasFetched &&
      !window.tc_vars
    ) {
      const loadTrackingScript = () => {
        window.tc_vars = {
          env_work: window.env.REACT_APP_COMMANDERS_ENV,
          env_channel: "website",
          env_market: "particuliers",
          env_is_private: true,
          user_id: idStat,
          user_is_logged: true,
          user_is_identified: true,
          user_is_supervisor: supervisionState.estActive,
          page_noload: true,
        };

        try {
          let bddfTmsScript: HTMLScriptElement | null = document.querySelector(
            `script[src="${window.env.REACT_APP_COMMANDERS_TAG_URL}"]`
          );

          if (!bddfTmsScript) {
            bddfTmsScript = document.createElement("script");
            bddfTmsScript.src = window.env.REACT_APP_COMMANDERS_TAG_URL;
            bddfTmsScript.async = true;
            bddfTmsScript.onload = () => {
              setLoadingStatus(LoadingStatus.DONE);
            };
            bddfTmsScript.onerror = () => {
              setLoadingStatus(LoadingStatus.ERROR);
            };
            document.body.appendChild(bddfTmsScript);
          }
        } catch (e) {
          setLoadingStatus(LoadingStatus.ERROR);
        }
      };
      loadTrackingScript();
    }
  }, [idStat, supervisionState.hasFetched]);

  return { loadingStatus };
};
