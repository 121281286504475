import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGInputQuantity } from "sg-input";
import { SGSelectOption } from "sg-select";
import { SGSpace } from "sg-space";
import { SGBlock } from "sg-typo";
import { useFeatures } from "website/components/hooks/useFeatures";
import { importAssetAndMembers, importLoans } from "../../../../../store/import/actions";
import { ImportState } from "../../../../../store/import/types";
import { updateFamilyAction } from "../../../../../store/members/actions";
import { CustodyType, Family, FamilyStatus, MaritalStatus, MatrimonialRegime, buildMember } from "../../../../../store/members/types";
import { isCoupleMaritalStatus, isOfficialCoupleMaritalStatus, } from "../../../../../store/members/utils";
import { triggerInformationForRetiredAction } from "../../../../../store/routing/actions";
import { State } from "../../../../../store/store";
import { nextStepAction } from "../../../../../store/tunnel/actions";
import { getMaxDateForMyBirthdate, isOlderThanMaxRetirementAge, utcDate } from "../../../../utils/date/DateUtils";
import { SG_RED } from "../../../../utils/graphs/colors";
import { DateInput, DatePattern } from "../../../atoms/DateInput/DateInput";
import { FormInputs } from "../../../atoms/FormInputs/FormInputs";
import { SGButtonIntl } from "../../../atoms/SGButtonIntl/SGButtonIntl";
import { SGTextIntl } from "../../../atoms/SGTextIntl/SGTextIntl";
import { SGTitleIntl } from "../../../atoms/SGTitleIntl/SGTitleIntl";
import { SelectInput } from "../../../atoms/SelectInput/SelectInput";
import { SpinnerSize, SpinnerWrapper } from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import { useAccount } from "../../../hooks/useAccount";
import { useFamily } from "../../../hooks/useFamily";
import { useRedirect } from "../../../hooks/useRedirect";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface FamilySituationFormData {
   maritalStatus: string;
   dateOfBirth?: Date;
}

const TunnelFamilySituation: FunctionComponent = () => {
   const dispatch = useDispatch();
   const family = useFamily();
   const account = useAccount();
   const intl = useIntl();
   useRedirect();
   const { trackPage } = useTracking();

   const maritalStatuses: MaritalStatus[] = Object.values(MaritalStatus);
   const importData: ImportState = useSelector<State, ImportState>((state) => state.importData);

   const [dateOfBirth, setDateOfBirth] = useState<Date>();
   const [maritalStatus, setMaritalStatus] = useState(MaritalStatus.SINGLE);
   const [numberChild, setNumberChild] = useState(0);
   const [numberChildNoCustody, setNumberChildNoCustody] = useState(0);
   const [numberChildShared, setNumberChildShared] = useState(0);
   const [showMoreOption, setShowMoreOption] = useState(false);
   const { disponibiliteParcoursRevenusComplementaires } = useFeatures();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });
   const { handleSubmit, formState } = methods;

   const addChildren = () => {
      setNumberChild((prevState) => Math.min(prevState + 1, 10));
   };

   const removeChildren = () => {
      setNumberChild((prevState) => Math.max(prevState - 1, 0));
   };

   const addChildrenNoCustody = () => {
      setNumberChildNoCustody((prevState) => Math.min(prevState + 1, 10));
   };

   const removeChildrenNoCustody = () => {
      setNumberChildNoCustody((prevState) => Math.max(prevState - 1, 0));
   };

   const addChildrenShared = () => {
      setNumberChildShared((prevState) => Math.min(prevState + 1, 10));
   };

   const removeChildrenShared = () => {
      setNumberChildShared((prevState) => Math.max(prevState - 1, 0));
   };

   useEffect(() => {
      if (family?.me?.birthday) {
         if (family?.me) {
            if (isOlderThanMaxRetirementAge(family.me.birthday)) {
               dispatch(triggerInformationForRetiredAction());
            }
            setDateOfBirth(new Date(family.me.birthday));
            methods.setValue("dateOfBirth", new Date(family.me.birthday), { shouldValidate: true });

            setMaritalStatus(family.me.maritalStatus);
            methods.setValue("maritalStatus", family.me.maritalStatus, { shouldValidate: true });

            const numberOfChildren = family.children.filter((member) => member.custodyType === CustodyType.FULL_CUSTODY || member.custodyType === CustodyType.FULL_CUSTODY_ME).length
            setNumberChild(numberOfChildren);
            methods.setValue("numberChild", numberOfChildren, { shouldValidate: true });

            const numberOfNoCustodyChildren = family.children.filter((member) => member.custodyType === CustodyType.NO_CUSTODY).length;
            setNumberChildNoCustody(numberOfNoCustodyChildren);
            methods.setValue("numberChildNoCustody", numberOfNoCustodyChildren, { shouldValidate: true });

            const numberOfSharedCustodyChildren = family.children.filter((member) => member.custodyType === CustodyType.SHARED_CUSTODY || member.custodyType === CustodyType.SHARED_CUSTODY_ME).length;
            setNumberChildShared(numberOfSharedCustodyChildren);
            methods.setValue("numberChildShared", numberOfSharedCustodyChildren, { shouldValidate: true });

            setShowMoreOption(family.me.maritalStatus === MaritalStatus.DIVORCED_OR_SEPARATED || numberOfNoCustodyChildren > 0 || numberOfSharedCustodyChildren > 0);
         }
         setDateOfBirth(new Date(family.me.birthday));
         setMaritalStatus(family.me.maritalStatus);
         setNumberChild(
            family.children.filter((member) => member.custodyType === CustodyType.FULL_CUSTODY || member.custodyType === CustodyType.FULL_CUSTODY_ME).length
         );
         setNumberChildNoCustody(
            family.children.filter((member) => member.custodyType === CustodyType.NO_CUSTODY).length
         );
         setNumberChildShared(
            family.children.filter((member) => member.custodyType === CustodyType.SHARED_CUSTODY || member.custodyType === CustodyType.SHARED_CUSTODY_ME).length
         );
         setShowMoreOption(family.me.maritalStatus === MaritalStatus.DIVORCED_OR_SEPARATED);
      }
   }, [family, importData]);

   useEffect(() => {
      trackPage("funnel", "ma-situation", "formulaire-ecran", "inscription", "1");
   }, []);

   if (importData.isImportingMembers) {
      return (
         <SpinnerWrapper displayComponent={false} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout />
      );
   }

   const computeAndFillFamilyWithChildren = (familyToDispatch: Family) => {
      let ordinal = 0;
      for (let i = 0; i < numberChild; i += 1) {
         ordinal += 1;
         familyToDispatch.children.push(buildMember({
            name: ordinal === 1 ? "Mon enfant" : `Mon enfant ${ordinal}`,
            status: FamilyStatus.CHILD,
            custodyType: isOfficialCoupleMaritalStatus(familyToDispatch.me.maritalStatus)
               ? CustodyType.FULL_CUSTODY
               : CustodyType.FULL_CUSTODY_ME,
         }));
      }
      for (let i = 0; i < numberChildShared; i += 1) {
         ordinal += 1;
         familyToDispatch.children.push(buildMember({
            name: ordinal === 1 ? "Mon enfant" : `Mon enfant ${ordinal}`,
            status: FamilyStatus.CHILD,
            custodyType: isCoupleMaritalStatus(familyToDispatch.me.maritalStatus)
               ? CustodyType.SHARED_CUSTODY
               : CustodyType.SHARED_CUSTODY_ME,
         }));
      }
      for (let i = 0; i < numberChildNoCustody; i += 1) {
         ordinal += 1;
         familyToDispatch.children.push(buildMember({
            name: ordinal === 1 ? "Mon enfant" : `Mon enfant ${ordinal}`,
            status: FamilyStatus.CHILD,
            custodyType: CustodyType.NO_CUSTODY,

         }));
      }
   }

   const onSubmit = (data: FamilySituationFormData) => {
      if (data.dateOfBirth) {
         if (isOlderThanMaxRetirementAge(data.dateOfBirth)) {
            dispatch(triggerInformationForRetiredAction());

            return
         }
         const familyToDispatch: Family = {
            me: {
               ...buildMember({
                  name: "Moi même",
                  status: FamilyStatus.ME,
               }),
               birthday: utcDate(data.dateOfBirth),
               maritalStatus: data.maritalStatus
            },
            children: [],
            relatives: [],
         };
         if (isCoupleMaritalStatus(data.maritalStatus)) {
            familyToDispatch.partner = {
               ...buildMember({
                  name: "Mon conjoint",
                  status: FamilyStatus.PARTNER,
               }),
               maritalStatus: data.maritalStatus
            };
            if (data.maritalStatus === MaritalStatus.MARRIED) {
               familyToDispatch.me.matrimonialRegime = MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS;
               familyToDispatch.partner.matrimonialRegime = MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS;
            }
         }
         computeAndFillFamilyWithChildren(familyToDispatch);
         dispatch(updateFamilyAction(familyToDispatch, true, true));
      }
      if (!account.assetsImported && account.ssouser && disponibiliteParcoursRevenusComplementaires !== undefined) {
         dispatch(importAssetAndMembers(disponibiliteParcoursRevenusComplementaires));
         dispatch(importLoans(JSON.parse(sessionStorage.getItem("assetsAndLoans") || "{}")));
      }
      dispatch(nextStepAction());
   };

   const onShowMoreOptionClick = () => {
      setShowMoreOption(true);
   };

   return (
      <SGSpace direction="vertical" size="xl">
         <FormProvider {...methods}>
            <form>
               <SGTitleIntl intlId="tunnel.step.situation" cypressName="tunnel-situation-title" />

               <SGCard data-cy="tunnel-family-situation" borderleft={`.3rem solid ${SG_RED.hexCode}`}>

                  <FormInputs colLength={8}>
                     <DateInput defaultValue={dateOfBirth || ""} min="1935-01-01" max={getMaxDateForMyBirthdate()} pattern={DatePattern.DAYS}
                        rangeMessage="'tunnel.situation.error.birthDate.values'" name="dateOfBirth" label="tunnel.situation.birthDate"
                        cypressName="situation-dateOfBirth" />
                     <SelectInput cypressName="tunnel-status" name="maritalStatus"
                        required label="tunnel.situation.marital-status" defaultValue={maritalStatus || ""}>
                        {maritalStatuses.map(
                           (maritalStatusOption: MaritalStatus) => (
                              <SGSelectOption key={maritalStatusOption} data-cy={`tunnel-marital-status-${maritalStatusOption}`} value={maritalStatusOption}>
                                 {intl.formatMessage({
                                    id: `marital.status.${maritalStatusOption}`,
                                 })}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>
                     <SGInputQuantity data-cy="dependent-children" onIncrement={addChildren} onDecrement={removeChildren} min={0} max={10} unitvalue=""
                        precision={0} label={<SGTextIntl intlId="tunnel.situation.dependent-children" />} increment={1} value={numberChild} />
                     {!showMoreOption && (
                        <SGButtonGroup align="center">
                           <SGButtonIntl type="link" onClick={onShowMoreOptionClick} intlId="tunnel.situation.more-options" />
                        </SGButtonGroup>
                     )}
                     {showMoreOption && (
                        <SGInputQuantity data-cy="shared-children" onIncrement={addChildrenShared} onDecrement={removeChildrenShared} min={0} max={10}
                           precision={0} unitvalue="" label={<SGTextIntl intlId="tunnel.situation.shared-children" />} increment={1}
                           value={numberChildShared} />

                     )}
                     {showMoreOption && (
                        <SGInputQuantity data-cy="no-custody-children" onIncrement={addChildrenNoCustody} onDecrement={removeChildrenNoCustody} min={0}
                           precision={0} unitvalue="" max={10} label={<SGTextIntl intlId="tunnel.situation.no-custody-children" />} increment={1}
                           value={numberChildNoCustody} />
                     )}
                  </FormInputs>
               </SGCard>

               <SGButtonGroup align="center">
                  <SGButtonIntl type="primary" size="md" disabled={!formState.isValid} cypressName="tunnel-next" onClick={handleSubmit(onSubmit)}
                     trackingName="ma-situation::clic-sur-confirmer" intlId="tunnel.buttons.next" />
               </SGButtonGroup>

            </form>
         </FormProvider>
         <SGAccordion accordion={false} disableContentMargin>
            <SGAccordionPanel header={<SGTextIntl intlId="tunnel.situation.disclaimer.personal.data.title" strong />}>
               <SGBlock>
                  <SGTextIntl intlId="tunnel.situation.disclaimer.personal.data.content" transformations={{ linebreak: <br /> }} />
                  <SGButtonIntl type="link" onClick={() => {
                     (window.location.href = `${window.env.REACT_APP_CGU_URL}`)
                  }} intlId="tunnel.situation.disclaimer.personal.data.link" />
               </SGBlock>
            </SGAccordionPanel>
            <SGAccordionPanel header={<SGTextIntl intlId="cgu.title" strong />}>
               <SGTextIntl intlId="tunnel.situation.cgu.content" transformations={{ linebreak: <br /> }} />
            </SGAccordionPanel>
         </SGAccordion>
      </SGSpace>
   );
};

export { TunnelFamilySituation };
