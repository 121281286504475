import React, {FunctionComponent} from "react";
import {ChangeLifeProject} from "../../components/organisms/Projects/ChangeLifeProject/ChangeLifeProject";
import {SGContent} from "sg-layout";

const ChangeLifeProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <ChangeLifeProject/>
   </SGContent>
);

export {ChangeLifeProjectPage};
