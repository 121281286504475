import React, {FunctionComponent, useEffect} from "react";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {useIncomes} from "../../../hooks/useIncomes";
import {useExpenses} from "../../../hooks/useExpenses";
import {getExpensesAction} from "../../../../../store/expenses/actions";
import {useDispatch, useSelector} from "react-redux";
import {SGGridCol, SGGridRow} from "sg-grid";
import {GraphIncomesExpensesBarChart} from "../../../molecules/GraphIncomesExpensesBarChart/GraphIncomesExpensesBarChart";
import {NameAndValuationCollapsableFrames} from "../../../molecules/NameAndValuationCollapsableFrames/NameAndValuationCollapsableFrames";
import {SavingCapacity} from "../../Advices/SavingCapacity/SavingCapacity";
import {getSavingCapacityAction} from "../../../../../store/savingCapacity/actions";
import {State} from "../../../../../store/store";

const ProfileIncomeExpenses: FunctionComponent = () => {
   const dispatch = useDispatch();
   const incomes = useIncomes();
   const expenses = useExpenses();

   const hasFetchedIncomesAndExpenses = useSelector<State, boolean>((state) => state.incomes.hasFetched && state.expenses.hasFetchedExpenses);

   useEffect(() => {
      dispatch(getExpensesAction());
      dispatch(getSavingCapacityAction());
   }, []);

   return (
      <SpinnerWrapper displayComponent={hasFetchedIncomesAndExpenses} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGGridRow>
            <SGGridCol span={12}>
               <SGTitleIntl intlId="profile.incomes.expenses.graph.title" cypressName="graph-incomes-expenses-title"/>
               <GraphIncomesExpensesBarChart/>
            </SGGridCol>
         </SGGridRow>

         <SGGridRow>
            <SGGridCol xs={12} xl={6}>
               <NameAndValuationCollapsableFrames cypressName="profile-incomes" title="profile.incomes.title"
                                                  incomes={incomes}/>
            </SGGridCol>
            <SGGridCol xs={12} xl={6}>
               <NameAndValuationCollapsableFrames cypressName="profile-expenses" title="profile.expenses.title"
                                                  expenses={expenses}/>
            </SGGridCol>
         </SGGridRow>
         <SavingCapacity/>
      </SpinnerWrapper>
   );
};

export {ProfileIncomeExpenses};
