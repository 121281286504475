import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGCarousel } from "sg-carousel";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Image } from "website/components/atoms/Image/Image";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { ServiceSapiendoCard } from "website/components/molecules/ServiceSapiendoCard/ServiceSapiendoCard";
import { externalTel, transformTel } from "website/utils/externalTel";
import { ServiceCardInfo } from "../ExpertRetraite";
import "./ExpertRetraiteInfos.scss";

export interface ExpertRetraiteInfosProps {
    services: ServiceCardInfo[];
    setServiceId: (serviceId: string) => void;
    setShowModalInfo: (show: boolean) => void;
}

// Partie basse de l'onglet Sapiendo Expert Retraite sur la page Mes Services
export const ExpertRetraiteInfos: FunctionComponent<ExpertRetraiteInfosProps> = (props: React.PropsWithChildren<ExpertRetraiteInfosProps>) => {
    const intl = useIntl();
    const { services, setServiceId, setShowModalInfo } = props;
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });
    const { trackClick } = useTracking();

    return (
        <>
            <SGLayout stretch background="Sec_Sg2">
                <SGBox padding={{top: "md", left: "md"}} margin={{top: "xl"}}>
                    <SGGridRow align="middle" style={{ marginLeft: "0", marginRight: "0"}}>
                        <SGMediaQuery minwidth="xl">
                            <SGGridCol flex="initial">
                                <Image src="images/mesServices/mes-services-appel.png" alt="mes-services-appel" />
                            </SGGridCol>
                        </SGMediaQuery>
                        <SGGridCol span={12} xl={5}>
                            <SGGridRow gutter={[0, 18]}>
                                <SGGridCol span={10} sm={11}>
                                    <SGTitle level={2}>{intl.formatMessage({ id: "mesServices.sapiendo.encart.title" })}</SGTitle>
                                </SGGridCol>
                                <SGGridCol span={10}>
                                    <SGText style={{ color: "#000000" }}>
                                        {intl.formatMessage({ id: "mesServices.sapiendo.encart.description" }, { linebreak: <br /> })}
                                    </SGText>
                                </SGGridCol>
                                <SGGridCol>
                                    <a href={`tel:${externalTel.EXTERNAL_TEL_SAPIENDO}`} onClick={() => trackClick('sapiendo-l-expert-retraite:clic-sur-appeler')} className="expert-retraite__call-link">
                                        <div className="expert-retraite-infos__appel-container">
                                            <SGText size="s" style={{ color: "#FFFFFF" }}>
                                                {intl.formatMessage(
                                                    { id: `mesServices.sapiendo.encart.appel${isPhone ? ".mobile" : ""}` },
                                                    {
                                                        number: (word: string) => <strong style={{ color: "#E2010B", paddingLeft: isPhone ? "0px" : "5px", fontSize: "20px" }}>{transformTel(externalTel[word])}</strong>,
                                                        linebreak: <br />
                                                    }
                                                )}
                                            </SGText>
                                        </div>
                                    </a>
                                </SGGridCol>
                                <SGMediaQuery minwidth="xl">
                                    <SGGridCol>
                                        <SGText size="s">
                                            {intl.formatMessage(
                                                { id: "mesServices.sapiendo.encart.info" },
                                                { b: (word: string) => <strong>{word}</strong>, linebreak: <br /> }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                </SGMediaQuery>
                            </SGGridRow>
                            <SGMediaQuery minwidth={0} maxwidth="lg">
                                <SGGridRow align="middle">
                                    <SGGridCol span={7}>
                                        <SGText size="s">
                                            {intl.formatMessage(
                                                { id: "mesServices.sapiendo.encart.info" },
                                                { b: (word: string) => <strong>{word}</strong>, linebreak: <br /> }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={5} textalign="right">
                                        <Image className="expert-retraite-infos__image" src="images/mesServices/mes-services-appel-mobile.png" alt="mes-services-appel-mobile" />
                                    </SGGridCol>
                                </SGGridRow>
                            </SGMediaQuery>
                        </SGGridCol>
                    </SGGridRow>
                </SGBox>
            </SGLayout>
            <SGBlock>
                <div className="containerAssu">
                    <SGGridRow>
                        <SGGridCol span={12} textalign={isPhone ? "left" : "center"}>
                            <SGBox margin={{bottom: "lg"}}>
                                <SGTitle level={2}>{intl.formatMessage({ id: "mesServices.sapiendo.carousel.title" })}</SGTitle>
                            </SGBox>
                        </SGGridCol>
                        <SGGridCol span={12}>
                            <SGBox padding={{bottom: "xl"}}>

                                <SGCarousel
                                    settings={{
                                        slidesToScroll: 1,
                                        slidesToShow: 3,
                                        infinite: true,
                                        responsive: [
                                            {
                                                breakpoint: 812,
                                                settings: {
                                                    slidesToShow: 1,
                                                    slidesToScroll: 1
                                                }
                                            },
                                            {
                                                breakpoint: 1440,
                                                settings: {
                                                    slidesToShow: 2,
                                                    slidesToScroll: 1
                                                }
                                            }
                                        ]
                                    }}
                                >
                                    {services.map((service) => (
                                        <ServiceSapiendoCard
                                            service={service}
                                            key={service.type}
                                            isInCarousel
                                            setServiceId={setServiceId}
                                            setShowModalInfo={setShowModalInfo}
                                        />
                                    ))}
                                </SGCarousel>
                            </SGBox>
                        </SGGridCol>
                    </SGGridRow>
                </div>
            </SGBlock>
        </>
    );
};
