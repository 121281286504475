import React, {FunctionComponent} from "react";
import "./HeaderXSDefault.scss";
import {useDispatch, useSelector} from "react-redux";
import "react-dropdown/style.css";
import {AccountState, isLoggedIn,} from "../../../../../../../store/account/types";
import {State} from "../../../../../../../store/store";
import {logout} from "../../../../../../../store/login/actions";
import {HeaderNav} from "../../HeaderNav";
import {ADVICES, PROJECTS, SIMULATIONS, SITUATION} from "../../../../../../utils/privateRoutes";
import {SGSpace} from "sg-space";
import {SGContent} from "sg-layout";
import {SGIcon} from "sg-icon";
import {SGAvenirStrokedCadenas, SGAvenirStrokedCompte, SGAvenirStrokedPresentation, SGAvenirStrokedSolution} from "sg-icon-pack-base";
import {SGGridCol} from "sg-grid";
import {SGBlock} from "sg-typo";

const HeaderXSDefault: FunctionComponent = () => {
   const dispatch = useDispatch();
   const accountState = useSelector<State, AccountState>(
      (state) => state.account
   );

   const handleLogout = () => {
      dispatch(logout());
   };

   return (
      <SGBlock className="headerDefaultXS">
         <SGContent disableautomargin>
            <SGGridCol align="center">
               {accountState.account.tunnelCompleted &&
                  <SGSpace direction="horizontal" disableautomargin textalign="center">
                     <HeaderNav url={ADVICES} text="header.title.coach"
                                buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     <HeaderNav url={SITUATION} text="header.title.profile" icon={<SGIcon currentcolor component={<SGAvenirStrokedCompte/>}/>}
                                buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     <HeaderNav url={PROJECTS} text="header.title.projects" icon={<SGIcon currentcolor component={<SGAvenirStrokedSolution/>}/>}
                                buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     <HeaderNav url={SIMULATIONS} text="header.title.simulations" icon={<SGIcon currentcolor component={<SGAvenirStrokedPresentation/>}/>}
                                buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     {isLoggedIn(accountState) && (
                        <HeaderNav onClick={handleLogout} data-cy="logout" text="header.title.logout.short"
                                   icon={<SGIcon currentcolor component={<SGAvenirStrokedCadenas/>}/>}
                                   buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     )}
                  </SGSpace>}
            </SGGridCol>
         </SGContent>
      </SGBlock>
   );
};

export {HeaderXSDefault};
