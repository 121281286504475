import React, {FunctionComponent, useContext} from "react";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {IncomesEditor} from "../components/organisms/Editors/IncomesEditor/IncomesEditor";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SITUATION_INCOMES} from "../utils/privateRoutes";
import {EditorContext, EditorContextProvider} from "../components/providers/EditorProvider";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {SGContent} from "sg-layout";
import {SGButtonGroup} from "sg-button";

const IncomesPage: FunctionComponent = () => {
   const IncomesPageButton = () => {
      const {isEditing} = useContext(EditorContext);

      return (
         <SGButtonGroup align="right">
            <SGButtonIntl type="primary" size="sm" cypressName="incomes-confirm" onClick={() => redirectToRelativePath(SITUATION_INCOMES)}
                          intlId="common.next" disabled={isEditing}/>
         </SGButtonGroup>
      );
   };

   return (
      <SGContent span={8}>
         <SGTitleIntl intlId="tunnel.incomes.title" cypressName="incomes-title-page"/>
         <EditorContextProvider>
            <IncomesEditor cypressName="profile-incomes"/>
            <IncomesPageButton/>
         </EditorContextProvider>
      </SGContent>
   )
};

export {IncomesPage};
