import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {InheritanceSimulatorResult, InheritanceSimulatorState} from "./types";
import {AssetWithValuation} from "../assets/types";

const initialState: InheritanceSimulatorState = {
   result: {
      amountPerHeir: 0,
      feesAmountPerHeir: 0,
      assetDTOList: new Array<AssetWithValuation>()
   },
   hasFetched: false
};

export const inheritanceSimulatorSlice = createSlice({
   name: "inheritanceSimulator",
   initialState,
   reducers: {
      inheritanceSharesFetched: (currentState, action: PayloadAction<InheritanceSimulatorResult>) => ({
         result: action.payload,
         hasFetched: true
      })
   }
});

export const {inheritanceSharesFetched} = inheritanceSimulatorSlice.actions;

export default inheritanceSimulatorSlice.reducer;
