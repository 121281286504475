import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Income, IncomeState, IncomeType} from "../../../store/incomes/types";
import {getIncomesAction} from "../../../store/incomes/actions";
import {Member} from "../../../store/members/types";

const useWorkOrRetirementIncome = (member: Member | undefined) => {
   const dispatch = useDispatch();
   const incomeState: IncomeState = useSelector<State, IncomeState>((state) => state.incomes);
   const [workOrRetirementIncome, setWorkOrRetirementIncome] = useState<Income | undefined>(undefined);

   useEffect(() => {
      if (!incomeState.hasFetched) {
         dispatch(getIncomesAction());
      }
   }, []);

   useEffect(() => {
      if (incomeState.hasFetched && member) {
         setWorkOrRetirementIncome(incomeState.incomes.filter((i) => (i.type === IncomeType.WORK_INCOME || i.type === IncomeType.RETIREMENT_INCOME) && i.memberId === member.id).shift());
      }
   }, [incomeState]);

   return workOrRetirementIncome;
};

export {useWorkOrRetirementIncome};
