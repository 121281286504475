import React, {FunctionComponent} from "react";
import {DefaultProject} from "../../components/organisms/Projects/DefaultProject/DefaultProject";
import {ProjectType} from "../../../store/projects/types";
import {SGContent} from "sg-layout";

const ConstructionWorkProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <DefaultProject
         projectType={ProjectType.CONSTRUCTION_WORK}
         projectTitle="project.construction.work.title"
         projectSubtitle="project.construction.work.subtitle"
         projectAmountLabel="project.construction.work.form.amount"
         projectAmountPlaceholder="project.construction.work.form.amount.placeholder"
         projectHorizonLabel="project.construction.work.form.horizon"
         newProjectTitle="new.project.title.construction.work"
         cypressName="construction-work"
      />
   </SGContent>
);

export {ConstructionWorkProjectPage};
