import { ReactComponent as Check } from "assets/images/green-check.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGText, SGTitle } from "sg-typo";
import { servicesSapiendo } from "website/components/hooks/servicesSapiendoList";
import { ServiceCardInfo } from "website/components/organisms/MesServices/ExpertRetraite/ExpertRetraite";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";

export interface ServiceSapiendoCardProps {
    service: ServiceCardInfo;
    isInCarousel?: boolean;
    setServiceId: (serviceId: string) => void;
    setShowModalInfo: (show: boolean) => void;
}

export const ServiceSapiendoCard: FunctionComponent<ServiceSapiendoCardProps> = (props: React.PropsWithChildren<ServiceSapiendoCardProps>) => {
    const { service, isInCarousel, setServiceId, setShowModalInfo } = props;

    const intl = useIntl();
    const { trackClick } = useTracking();

    const isPhone = useMediaQuery({ minwidth: 0, maxwidth: 'sm' });

    const handleOpenModal = () => {
        const idService = servicesSapiendo.find((ser) => ser === service.fileName);
        if (idService) {
            if (isInCarousel) {
                trackClick(`sapiendo-l-expert-retraite:clic-sur-en-savoir-plus-bloc-tous-les-services-${service.trackKey}`);
            } else {
                trackClick(`sapiendo-l-expert-retraite:clic-sur-en-savoir-plus-bloc-services-utiles-${service.trackKey}`);
            }
            setServiceId(idService);
            setShowModalInfo(true);
        }
    };

    return (
        <SGCard hoverable>
            <SGBox padding={{left: "md", top: "xs", right: "md", bottom: "sm"}}>
                <SGSpace textalign="center" direction="vertical" disableautomargin size={isPhone ? "sm" : "md"}>
                    {service.formule && (
                        <SGTag key="cardTag" tagcolor={service.formule === "essentielle" ? '3' : 'error'}>
                             {intl.formatMessage({ id: `mesServices.sapiendo.formule.${service.formule}` })}
                        </SGTag>
                    )}
                    <SGTitle key="cardTitle" level={3}>
                        {intl.formatMessage(
                            { id: `mesServices.sapiendo.services.${service.type}.title` },
                            { linebreak: <br />, b: (word: string) => <strong>{word}</strong> }
                        )}
                    </SGTitle>
                    <SGText key="cardSubtitle" size="s">
                        {intl.formatMessage(
                            { id: `mesServices.sapiendo.services.${service.type}.subtitle` },
                            { linebreak: <br />, b: (word: string) => <strong>{word}</strong> }
                        )}
                    </SGText>
                    <SGDivider key="divider"/>
                    {service.items.map((item) => (
                        <SGGridRow key={item}>
                            <SGGridCol>
                                <Check />
                            </SGGridCol>
                            <SGGridCol flex="1 0 0%">
                                <SGText size="s">
                                    {intl.formatMessage({ id: `mesServices.sapiendo.services.${service.type}.${item}` }, { linebreak: <br /> })}
                                </SGText>
                            </SGGridCol>
                        </SGGridRow>
                    ))}
                    <SGDivider key="divider2" disableautomargin />
                    {!isInCarousel && service.formule && (
                        <SGText key="cardTextPlus" weight="600">
                            {intl.formatMessage(
                                { id: `mesServices.sapiendo.services.${service.type}.plus` },
                                { b: (word: string) => <strong>{word}</strong> }
                            )}
                        </SGText>
                    )}
                    <SGButton key="button" size="sm" type="primary" onClick={handleOpenModal}>
                        {intl.formatMessage({ id: "mesServices.sapiendo.services.button" })}
                    </SGButton>
                </SGSpace>
            </SGBox>
        </SGCard>
    );
};
