import React, {FunctionComponent} from "react";
import {SGTitleIntl} from "../../../../atoms/SGTitleIntl/SGTitleIntl";
import {redirectToRelativePath} from "../../../../../utils/routes/routing";
import {SITUATION_SUCCESSION} from "../../../../../utils/privateRoutes";
import {SGButtonIntl} from "../../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../../../../atoms/SGTextIntl/SGTextIntl";
import {RealizedDonationsEditor} from "../../../Editors/RealizedDonationsEditor/RealizedDonationsEditor";
import {SGSpace} from "sg-space";
import {SGButtonGroup} from "sg-button";
import {EditorContextProvider} from "../../../../providers/EditorProvider";

const ProfileRealizedDonations: FunctionComponent = () => {
   const goBack = () => {
      redirectToRelativePath(SITUATION_SUCCESSION);
   };

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="inheritance.realized-donation.page.title" cypressName="profile-realized-donation"/>
         <SGAlertIntl cypressName="realized-donation-page-info" type="info" title="common.did.you.know">
            <SGTextIntl intlId="inheritance.realized-donation.page.info"/>
         </SGAlertIntl>
         <EditorContextProvider>
            <RealizedDonationsEditor cypressName="profile-realized-donations"/>
         </EditorContextProvider>
         <SGButtonGroup align="center">
            <SGButtonIntl type="link" cypressName="situation-succession-go-back" onClick={goBack} intlId="common.previous"/>
         </SGButtonGroup>
      </SGSpace>
   );
};

export {ProfileRealizedDonations};
