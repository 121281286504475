import {call, put, takeEvery, takeLeading} from "redux-saga/effects";
import {
   DELETE_EXPENSE,
   Expense,
   FETCH_EXPENSES,
   FETCH_SPENDING_PROFILE,
   SpendingProfile,
   UPDATE_EXPENSE_IN_STORE,
   UPDATE_EXPENSES,
   UPDATE_SPENDING_PROFILE
} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiDelete, apiGet, apiPost, apiPut} from "../apiCaller";
import {getExpensesAction} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {expenseDeleted, expensesFetched, setFetchedExpensesToFalse, spendingProfileFetched, updateExpenseInStore, updateSpendingProfile} from "./expensesSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeEvery(UPDATE_SPENDING_PROFILE, safe(onDefaultError, handleSaveSpendingProfile));
   yield takeEvery(FETCH_SPENDING_PROFILE, safe(onDefaultError, handleGetSpendingProfile));
   yield takeLeading(FETCH_EXPENSES, safe(onDefaultError, handleGetExpenses));
   yield takeLeading(UPDATE_EXPENSES, safe(onDefaultError, handleUpdateExpenses));
   yield takeLeading(UPDATE_EXPENSE_IN_STORE, safe(onDefaultError, handleUpdateExpenseInStore));
   yield takeLeading(DELETE_EXPENSE, safe(onDefaultError, handleDeleteExpense));
}

function* handleSaveSpendingProfile(action: PayloadAction<SpendingProfile>) {
   yield call(apiPost, `wealth/api/expenses/spending-profile/`, {spendingProfile: action.payload});
   yield put(updateSpendingProfile(action.payload));
}

function* handleGetSpendingProfile() {
   const payload: AxiosResponse<{ spendingProfile: SpendingProfile }> = yield call(apiGet, `wealth/api/expenses/spending-profile/`);
   yield put(spendingProfileFetched(payload.data.spendingProfile));
}

function* handleGetExpenses() {
   yield put(setFetchedExpensesToFalse());
   const payload: AxiosResponse<Expense[]> = yield call(apiGet, `wealth/api/expenses?ongoingOnly=true`);
   yield put(expensesFetched(payload.data));
}

function* handleUpdateExpenseInStore(action: PayloadAction<Expense>) {
   yield put(updateExpenseInStore(action.payload));
}

function* handleDeleteExpense(action: PayloadAction<Expense>) {
   yield call(apiDelete, `wealth/api/expenses/${action.payload.id}`);
   yield put(expenseDeleted(action.payload));
}

function* handleUpdateExpenses(action: PayloadAction<{
   rent: Partial<Expense> | undefined,
   plannedSavings: Partial<Expense> | undefined,
   childSupportExpense: Partial<Expense> | undefined
}>) {
   // Sauvegarde des quatre types d'expenses
   // 1 - LOYER
   if (action.payload.rent) {
      yield call(apiDelete, `wealth/api/rents`);
      if (action.payload.rent.monthlyAmount !== 0) {
         yield call(apiPost, `wealth/api/rents`, action.payload.rent);
      }
   }

   // 2 - Planned Savings
   if (action.payload.plannedSavings) {
      if (action.payload.plannedSavings.monthlyAmount !== 0) {
         yield call(
            action.payload.plannedSavings.id === undefined ? apiPost : apiPut,
            `wealth/api/planned-savings`,
            action.payload.plannedSavings
         );
      } else if (action.payload.plannedSavings.id !== undefined) {
         yield call(apiDelete, `wealth/api/expenses/${action.payload.plannedSavings.id}`);
      }
   }

   // 3 - Pensions alimentaires versées
   if (action.payload.childSupportExpense) {
      if (action.payload.childSupportExpense.monthlyAmount !== 0) {
         yield call(
            action.payload.childSupportExpense.id === undefined ? apiPost : apiPut,
            `wealth/api/expenses`,
            action.payload.childSupportExpense
         );
      } else if (action.payload.childSupportExpense.id !== undefined) {
         yield call(apiDelete, `wealth/api/expenses/${action.payload.childSupportExpense.id}`);
      }
   }

   yield put(getExpensesAction());
}
