import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { SGCarousel } from "sg-carousel";
import { useMediaQuery } from "sg-media-query";
import { TutorialContext } from "website/components/providers/TutorialProvider";
import { InfoCard, InfoCardType } from "../InfoCard/InfoCard";

interface CarouselNavigatorProps {
    onCarouselAction: (cardType: InfoCardType) => void;
}

const CarouselNavigator: FunctionComponent<CarouselNavigatorProps> = (props: CarouselNavigatorProps) => {
    const { onCarouselAction } = props;
    const { isTutorialDisplayed } = useContext(TutorialContext);

    const cardsMobile = [InfoCardType.monGuide, InfoCardType.monPlan, InfoCardType.mesServices, InfoCardType.saviezVous, InfoCardType.mesArticles];
    const cardsDesktop = [InfoCardType.monGuide, InfoCardType.monPlan, InfoCardType.mesServices, InfoCardType.saviezVous];

    // fait référence au carousel pour pouvoir utiliser plusieurs méthodes
    const myRef = useRef<any>();

    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });

    useEffect(() => {
        // Tutoriel ouvert
        if (isTutorialDisplayed) {
            // Le caroussel ne tourne plus
            myRef.current.slickPause();

            // Carte "mes services" du caroussel
            // timer car si l'utilisateur clique rapidement sur "je découvre" du guide pendant que le carrousel tourne,
            // le goTo(2) ne fonctionne pas
            setTimeout(() => {
                myRef.current.slickGoTo(2);
            }, 1500);
        } else {
            // Tutoriel fermé
            // Le caroussel  tourne de nouveau
            myRef.current.slickPlay();
        }
    }, [isTutorialDisplayed])

    return (
        <SGCarousel
            // référence
            ref={myRef}
            slidesToScroll={1}
            autoplay
            settings={{
                autoplaySpeed: 5000,
                speed: isTutorialDisplayed ? 0 : 2000,
                infinite: true,
                slidesToShow: isPhone ? 1 : 2
            }}
        >
        {(isPhone ? cardsMobile : cardsDesktop).map((card) =>
            <InfoCard key={card} id={card} onCardAction={() => onCarouselAction(card)} />
        )}
        </SGCarousel>
    );
}

export { CarouselNavigator };
