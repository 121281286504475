import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Expense, ExpenseState} from "../../../store/expenses/types";
import {getExpensesAction} from "../../../store/expenses/actions";
import {ProjectType} from "../../../store/projects/types";

const useTotalExpenses = (filteredOutTypes: ProjectType[]) => {
   const dispatch = useDispatch();
   const [totalExpenses, setTotalExpenses] = useState(0);
   const expenseState: ExpenseState = useSelector<State, ExpenseState>((state) => state.expenses);

   useEffect(() => {
      if (!expenseState.hasFetchedExpenses) {
         dispatch(getExpensesAction());
      }
   }, []);

   useEffect(() => {
      if (expenseState.hasFetchedExpenses) {
         setTotalExpenses(expenseState.expenses.filter((expense) => !expense.type || !filteredOutTypes.includes(expense.type)).reduce((sum: number, cur: Expense) => sum + cur.monthlyAmount, 0));
      }
   }, [expenseState.expenses]);

   return totalExpenses;
};

export {useTotalExpenses};
