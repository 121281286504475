import React, { Fragment, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGSkeleton } from "sg-skeleton";
import { SGBox, SGSpace } from "sg-space";
import { SGTag } from "sg-tag";
import { SGTile } from "sg-tile";
import { SGPriceCommercial, SGText, SGTitle } from "sg-typo";

export enum NomCarteEnum {
  PATRIMOINE = "patrimoine",
}

export enum TypeContenuCarteEnum {
  DESCRIPTION = "description",
  MONTANT = "montant",
}

interface CarteDonneeProps {
  nom: NomCarteEnum;
  isActif?: boolean;
  tagColor: string;
  typeContenu: TypeContenuCarteEnum;
  montant?: number;
  onClick: () => void;
  disponibiliteFeatureFlipping?: boolean;
  showSkeleton?: boolean;
}

const CarteDonnee: FunctionComponent<CarteDonneeProps> = (
  props: React.PropsWithChildren<CarteDonneeProps>
) => {
  const {
    nom,
    isActif = false,
    typeContenu,
    tagColor,
    montant,
    onClick,
    disponibiliteFeatureFlipping = false,
    showSkeleton = false,
    children,
  } = props;
  const intl = useIntl();

  const action = isActif ? "actif" : "inactif";

  return (
    <SGTile clickable>
      <SGSpace direction="vertical" disableautomargin size="sm">
        <SGTag key="tag" tagcolor={tagColor} border>
          {intl.formatMessage({ id: `dashboard.carteDonnee.${nom}.tag` })}
        </SGTag>
        {/** TODO PERSONNALISATION : dans la version final du dashboard, mettre level=3 */}
        <SGTitle key="titre" visuallevel={5} level={2}>
          {intl.formatMessage({
            id: `dashboard.carteDonnee.${nom}.${action}.titre`,
          })}
        </SGTitle>
        {showSkeleton ? (
          <SGSkeleton key="skeleton" title={false} />
        ) : (
          <Fragment key="fragment">
            {typeContenu === TypeContenuCarteEnum.DESCRIPTION && (
              <SGText>
                {intl.formatMessage({
                  id: `dashboard.carteDonnee.${nom}.description`,
                })}
              </SGText>
            )}
            {typeContenu === TypeContenuCarteEnum.MONTANT && (
              <SGPriceCommercial
                value={montant?.toLocaleString("fr-FR") || "--"}
                size="xl"
                layout="l2"
                term="mois (net)"
              />
            )}
            {children}
          </Fragment>
        )}
        <SGBox key="box" textalign="right">
          {disponibiliteFeatureFlipping ? (
            <SGButton
              type="tertiary"
              icon={
                <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
              }
              iconposition="right"
              onClick={onClick}
            >
              {intl.formatMessage({
                id: `dashboard.carteDonnee.${nom}.${action}.button`,
              })}
            </SGButton>
          ) : (
            <SGText>
              {intl.formatMessage({
                id: "dashboard.patrimoine.indisponible",
              })}
            </SGText>
          )}
        </SGBox>
      </SGSpace>
    </SGTile>
  );
};

export { CarteDonnee };
