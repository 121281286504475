import {call, put, takeLeading} from "redux-saga/effects";
import {FETCH_SAVING_CAPACITY, SavingCapacityData} from "./types";
import {safe} from "../error/utils";
import {apiGet} from "../apiCaller";
import {AxiosError, AxiosResponse} from "axios";
import {fetchSavingCapacityError, resetSavingCapacity, savingCapacityFetched} from "./savingCapacitySlice";

export function* watcherSaga() {
   yield takeLeading(FETCH_SAVING_CAPACITY, safe(onSavingCapacityError, handleGetSavingCapacity));
}

function* handleGetSavingCapacity() {
   yield put(resetSavingCapacity());
   const payload: AxiosResponse<SavingCapacityData> = yield call(apiGet, `wealth/api/saving-capacity`);
   yield put(savingCapacityFetched(payload.data));
}

function* onSavingCapacityError(err: AxiosError) {
   yield put(fetchSavingCapacityError());
}
