import React, {FunctionComponent, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {Loan} from "../../../../../store/loans/types";
import {ProjectType} from "../../../../../store/projects/types";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {useLoans} from "../../../hooks/useLoans";
import {useTotalIncomes} from "../../../hooks/useTotalIncomes";
import {useTotalExpenses} from "../../../hooks/useTotalExpenses";
import {useExpense} from "../../../hooks/useExpense";
import {useSpendingProfile} from "../../../hooks/useSpendingProfile";
import {getTotalIncomeSpendingPercentage} from "../../../../../store/expenses/utils";
import {useTaxCredits} from "../../../hooks/useTaxCredits";
import {TaxCredit} from "../../../../../store/tax/types";
import {useDebounce} from "../../../hooks/useDebounce";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGGridRow} from "sg-grid";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {useFamily} from "../../../hooks/useFamily";
import {SGCard} from "sg-card";
import {SGButtonGroup} from "sg-button";
import {updateExpensesAction} from "store/expenses/actions";

interface ExpensesEditorFormData {
   rent: string;
   plannedSavings: string;
   childSupportExpense: string;
}

export interface ExpensesEditorProps {
   onValidate: () => void;
}

const ExpensesEditor: FunctionComponent<ExpensesEditorProps> = (props: ExpensesEditorProps) => {
   const {onValidate} = props;

   const dispatch = useDispatch();
   const family = useFamily();
   const loans = useLoans([]);
   const taxCredits = useTaxCredits();
   const otherExpense = useExpense(ProjectType.EXPENSES);
   const rentExpense = useExpense(ProjectType.RENT);
   const childSupportExpense = useExpense(ProjectType.CHILD_SUPPORT_EXPENSE)
   const plannedSavingsExpense = useExpense(ProjectType.EXPENSE_PLANNED_SAVINGS);
   const assetExpense = useExpense(ProjectType.ASSET_EXPENSE);
   const taxExpenseAmount = useTotalExpenses([ProjectType.RENT, ProjectType.EXPENSE_PLANNED_SAVINGS, ProjectType.EXPENSES, ProjectType.CHILD_SUPPORT_EXPENSE, ProjectType.LOAN, ProjectType.ASSET_EXPENSE]);
   const constantExpenseAmount = useTotalExpenses([ProjectType.RENT, ProjectType.EXPENSE_PLANNED_SAVINGS, ProjectType.EXPENSES, ProjectType.CHILD_SUPPORT_EXPENSE]);
   const totalIncomes = useTotalIncomes();
   const totalExpenses = useTotalExpenses([])
   const spendingProfile = useSpendingProfile()

   const [shouldUpdateTax, setShouldUpdateTax] = useState<boolean>(false);
   const debouncedShouldUpdateTax = useDebounce<boolean>(shouldUpdateTax, 1000);

   const expenseMethods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   // Ce hook est appelé après l'effet de debounce sur les pensions alimentaires versées
   // Il envoie vers le back les valeurs à sauvegarder et demande un fetch des expenses pour recalcul des impots;
   useEffect(() => {
      if (shouldUpdateTax) {
         updateExpensesInStore(expenseMethods.getValues("rent"), expenseMethods.getValues("plannedSavings"), expenseMethods.getValues("childSupportExpense"));
         setShouldUpdateTax(false);
      }
   }, [debouncedShouldUpdateTax]);

   useEffect(() => {
      expenseMethods.setValue("totalExpenses", totalExpenses, {shouldValidate: true, shouldDirty: true});
      expenseMethods.setValue(
         "availableIncome",
         Math.max(0, totalIncomes - totalExpenses),
         {
            shouldValidate: true,
            shouldDirty: true,
         }
      );
   }, [totalExpenses, totalIncomes]);

   useEffect(() => {
      expenseMethods.setValue("monthlyTax", taxExpenseAmount, {shouldValidate: true, shouldDirty: true});
   }, [taxExpenseAmount]);

   const updateExpensesInStore = (rentAmount: string, plannedSavingsAmount: string, childSupportExpenseAmount: string) => {
      const rent = {type: ProjectType.RENT, monthlyAmount: Number(rentAmount)};
      const plannedSaving = {
         type: ProjectType.EXPENSE_PLANNED_SAVINGS,
         monthlyAmount: Number(plannedSavingsAmount),
      };
      const childSupport = {
         type: ProjectType.CHILD_SUPPORT_EXPENSE,
         monthlyAmount: Number(childSupportExpenseAmount),
         id: childSupportExpense?.id,
         name: "CHILD_SUPPORT_EXPENSE",
         member: childSupportExpense?.member || family.me,
      }

      dispatch(updateExpensesAction(rent, plannedSaving, childSupport));
   };

   const onSubmit = (data: ExpensesEditorFormData) => {
      updateExpensesInStore(data.rent, data.plannedSavings, data.childSupportExpense);
      onValidate();
   }

   const updateExpenses = () => {
      const allExpensesButOthers = constantExpenseAmount +
         Number(expenseMethods.getValues("rent")) +
         Number(expenseMethods.getValues("plannedSavings")) +
         Number(expenseMethods.getValues("childSupportExpense") || 0);

      const otherExpenses = Math.max(0, Math.round(totalIncomes * (1 - getTotalIncomeSpendingPercentage(spendingProfile)) - (allExpensesButOthers || 0)))

      expenseMethods.setValue("otherExpenses", otherExpenses, {shouldValidate: true, shouldDirty: true});

      expenseMethods.setValue("totalExpenses", allExpensesButOthers + otherExpenses, {shouldValidate: true, shouldDirty: true});

      expenseMethods.setValue(
         "availableIncome",
         Math.max(0, totalIncomes - allExpensesButOthers - otherExpenses), {shouldValidate: true, shouldDirty: true}
      );
   };

   return (
      <FormProvider {...expenseMethods}>
         <form key={expenseMethods.getValues("totalExpenses")}>
            <SGGridRow span={8} align="center">
               <SGCard>
                  <FormInputs colLength={10}>
                     {/* Champ d'affichage des impots estimés */}
                     <NumericInput cypressName="tax-expense-input" defaultValue={taxExpenseAmount} name="monthlyTax"
                                   label="expenses.estimate.tax" tooltipLabel="expenses.estimate.tax.info" placeholder=""
                                   tooltipTransformations={{
                                      totalIncome: String(taxExpenseAmount * 12),
                                      br: <br/>,
                                   }}
                                   suffix="common.euro.per.month" disabled/>
                     {/* Champ d'affichage des pensions alimentaires */}
                     <NumericInput cypressName="expenses-editor-child-support-expense" label="expenses.child.support.expense"
                                   defaultValue={childSupportExpense?.monthlyAmount || 0} placeholder=""
                                   name="childSupportExpense" suffix="common.euro.per.month"
                                   onValueChange={(value: number) => {
                                      setShouldUpdateTax(true);
                                   }}/>
                     {/* Affichage des différents emprunts en cours */}
                     {loans.map((loan: Loan) => (
                        <NumericInput key={loan.id} cypressName={`loan-expense-input-${loan.name}`} defaultValue={loan.monthlyAmount}
                                      name={`${loan.name}monthlyAmount`} suffix="common.euro.per.month" disabled label="expenses.loan"
                                      tooltipLabel="expenses.loan.info" labelTransformations={{loanName: loan.name}} placeholder=""/>
                     ))}
                     {/* Affichage des dépenses liées à des réduction d'impots */}
                     {taxCredits.map((taxCredit: TaxCredit) => (
                        <NumericInput key={taxCredit.id} cypressName={`loan-expense-input-${taxCredit.name}`} defaultValue={taxCredit.amount || 0 / 12}
                                      name={`${taxCredit.name}monthlyAmount`} label="expenses.tax-credit" labelTransformations={{taxCreditName: taxCredit.name}}
                                      tooltipLabel="expenses.tax-credit.info" suffix="common.euro.per.month" disabled placeholder=""/>
                     ))}

                     {/* Affichage des investissements locatifs */}
                     {(assetExpense?.monthlyAmount || 0) !== 0 && (
                        <NumericInput cypressName="asset-expense-input" defaultValue={assetExpense?.monthlyAmount || 0} name="assetExpense"
                                      suffix="common.euro.per.month" disabled label="expenses.asset" tooltipLabel="expenses.asset.info" placeholder=""/>
                     )}
                     {/* Affichage du loyer */}
                     <NumericInput name="rent" defaultValue={rentExpense?.monthlyAmount || 0} min={0} label="expenses.rent" placeholder=""
                                   cypressName="rent-expense-input" suffix="common.euro.per.month" onValueChange={() => {
                        updateExpenses();
                     }}/>
                     {/* Affichage de l'épargne programmée */}
                     <NumericInput name="plannedSavings" label="expenses.planned.savings" min={0} placeholder=""
                                   defaultValue={(plannedSavingsExpense?.monthlyAmount || 0)} suffix="common.euro.per.month"
                                   cypressName="planned-savings-expense-input" onValueChange={() => {
                        updateExpenses();
                     }}/>
                     {/* Affichage des autres dépenses, initialisées selon le profil de dépense
                        Lorsque modifié, impact le revenu disponible et le total dépense */}
                     <NumericInput name="otherExpenses" min={0} cypressName="other-expense-input" label="expenses.others"
                                   defaultValue={(otherExpense?.monthlyAmount || 0)} disabled placeholder=""
                                   suffix="common.euro.per.month"
                     />
                     {/* Affichage du total des dépenses */}
                     <NumericInput name="totalExpenses" cypressName="total-expense-input" defaultValue={totalExpenses} disabled
                                   suffix="common.euro.per.month" label="expenses.total" placeholder=""
                     />
                     {/* Affichage du revenu disponible
                        Initialisé à total revenus - total dépense et impact les dépenses "Autres" à la modification */}
                     <NumericInput cypressName="available-income-input" disabled
                                   name="availableIncome" suffix="common.euro.per.month" label="expenses.income.available"
                                   defaultValue={Math.max(Math.round(totalIncomes - totalExpenses), 0)} placeholder=""
                     />
                  </FormInputs>
               </SGCard>
            </SGGridRow>
            <SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" intlId="common.next" disabled={!expenseMethods.formState.isValid} cypressName="expenses-confirm"
                             onClick={expenseMethods.handleSubmit(onSubmit)}
               />
            </SGButtonGroup>
         </form>
      </FormProvider>
   );
}

export {ExpensesEditor};
