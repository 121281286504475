import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedMesRendezVous,
  SGAvenirStyledEpargne,
  SGAvenirStyledRetraite,
} from "sg-icon-pack-base";
import { SGLayout } from "sg-layout";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { DashboardAge } from "store/dashboard/types";
import { isMobile } from "store/import/utils";
import { State } from "store/store";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import {
  EpargnerCard,
  getDureeComplementYear,
  getDureeEpargneYear,
} from "website/components/atoms/EpargnerCard/EpargnerCard";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { TRACKING_CLICK_HYPOTHESES } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import {
  HypothesisAnchor,
  HypothesisModal,
} from "website/components/molecules/HypothesisModal/HypothesisModal";
import { DetailTab } from "website/components/organisms/ParcoursEpargner/DetailTab/DetailTab";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import "./TabEpargner.scss";

interface TabEpargnerProps {
  selectedRetirementAge: DashboardAge;
  objectif: number;
  montantEpargne: number;
  setMontantEpargne: (value: number) => void;
  showLoader: boolean;
}

const TabEpargner: FunctionComponent<TabEpargnerProps> = (
  props: TabEpargnerProps
) => {
  const {
    selectedRetirementAge,
    objectif,
    montantEpargne,
    setMontantEpargne,
    showLoader,
  } = props;
  const intl = useIntl();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);
  const [montantPension, setMontantPension] = useState(0);
  const [montantPatrimoine, setMontantPatrimoine] = useState(0);
  const [lastMonthlyIncome, setLastMonthlyIncome] = useState(0);
  const deltaObjectif =
    objectif - (montantPension + montantPatrimoine) < 0
      ? 0
      : objectif - (montantPension + montantPatrimoine);
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda } = useFeatures();
  const { trackClick } = useTracking();

  const handleOnClickRdv = () => {
    trackClick("age-cle::clic-sur-prendre-rendez-vous", {
      form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
    });
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    setMontantPension(
      roundDown10(selectedRetirementAge.retirementMonthlyIncome)
    );
    setLastMonthlyIncome(roundDown10(selectedRetirementAge.lastMonthlyIncome));
  }, [
    selectedRetirementAge.retirementMonthlyIncome,
    selectedRetirementAge.lastMonthlyIncome,
  ]);

  useEffect(() => {
    // Fetched dans ModuleEpargner
    if (assetState.hasFetched && hasFetchedAssets(assetIncomesState)) {
      setMontantPatrimoine(
        roundDown10(
          computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
        )
      );
    }
  }, [assetState, assetIncomesState]);

  const onClickHypothesisModal = () => {
    trackClick(`age-cle::${TRACKING_CLICK_HYPOTHESES}`);
    setShowHypothesisModal(true);
  };

  if (!isMobile()) {
    return (
      <div className="tab-epargner">
        <SGGridRow>
          <SGGridCol
            align="start"
            textalign="center"
            span={12}
          >
            <SGCard>
              <SGGridRow>
                <SGGridCol span={12}>
                  <SGGridRow gutter={[0, 10]}>
                    <SGGridCol span={12}>
                      <SGGridRow>
                        <SGGridCol>
                          <SGText
                            weight="700"
                            style={{
                              fontSize: "20px",
                              fontFamily: "Montserrat",
                              color: "#010035",
                            }}
                          >
                            <FormattedMessage
                              id="parcoursEpargner.tabEpargner.title.futur"
                              values={{
                                objectif: objectif.toLocaleString("fr-FR"),
                                montantEpargne:
                                  montantEpargne.toLocaleString("fr-FR"),
                                grey: (word: string) => (
                                  <label className="tab-epargner__montant-objectif">
                                    {word}
                                  </label>
                                ),
                                blue: (word: string) => (
                                  <label className="tab-epargner__montant-epargne">
                                    {word}
                                  </label>
                                ),
                              }}
                            />
                          </SGText>
                        </SGGridCol>
                      </SGGridRow>
                      <SGGridRow>
                        <SGGridCol>
                          <SGText underline weight="600" size="s">
                            <FormattedMessage
                              id="parcoursEpargner.tabEpargner.title.hypotheses"
                              values={{
                                hypothese: (word: string) => (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={onClickHypothesisModal}
                                  >
                                    {word}
                                  </span>
                                ),
                              }}
                            />
                          </SGText>
                        </SGGridCol>
                        {showHypothesisModal && (
                          <HypothesisModal
                            setVisible={setShowHypothesisModal}
                            visible={showHypothesisModal}
                            anchor={HypothesisAnchor.EPARGNER}
                            closeFirstGroup
                          />
                        )}
                      </SGGridRow>
                    </SGGridCol>
                    <SGDivider />
                    <SGGridCol span={12}>
                      <SGGridRow>
                        <SGGridCol span={4}>
                          <EpargnerCard
                            salaryMonth={montantEpargne}
                            color="blue"
                            isInput
                            setSalaryMonth={setMontantEpargne}
                            selectedRetirementAge={selectedRetirementAge}
                            title="parcoursEpargner.tabEpargner.subtitle.epargne"
                            subtitle={
                              <FormattedMessage
                                id="parcoursEpargner.card.annee.pendant"
                                values={{
                                  dureeEpargne: getDureeEpargneYear(
                                    selectedRetirementAge
                                  ),
                                }}
                              />
                            }
                            tooltip="parcoursEpargner.tabEpargner.subtitle.epargne.tooltip"
                            className="tab-epargner__card-epargne"
                            icone={<SGAvenirStyledEpargne />}
                          />
                        </SGGridCol>
                        <SGGridCol span={4}>
                          <EpargnerCard
                            salaryMonth={deltaObjectif}
                            color="dark-green"
                            selectedRetirementAge={selectedRetirementAge}
                            showLoader={showLoader}
                            title="parcoursEpargner.tabEpargner.subtitle.complement"
                            subtitle={
                              <FormattedMessage
                                id="parcoursEpargner.card.annee.environ"
                                values={{
                                  dureeEpargne: getDureeComplementYear(
                                    selectedRetirementAge
                                  ),
                                }}
                              />
                            }
                            tooltip="parcoursEpargner.tabEpargner.subtitle.complement.tooltip"
                            icone={<SGAvenirStyledRetraite />}
                          />
                        </SGGridCol>
                        <SGGridCol span={4}>
                          <EpargnerCard
                            salaryMonth={objectif}
                            color="grey"
                            showLoader={showLoader}
                            title="parcoursEpargner.tabEpargner.subtitle.objectif"
                            subtitle={
                              <FormattedMessage
                                id="parcoursEpargner.card.annee.depart"
                                values={{
                                  annee: selectedRetirementAge.retirementAgeYear,
                                  mois: selectedRetirementAge.retirementAgeMonth,
                                  showMois: (word: string) =>
                                    selectedRetirementAge.retirementAgeMonth ? (
                                      <SGText>{word}</SGText>
                                    ) : (
                                      <SGText />
                                    ),
                                }}
                              />
                            }
                            className="tab-epargner__card-objectif"
                            icone={<SGAvenirStyledRetraite />}
                          />
                        </SGGridCol>
                      </SGGridRow>
                    </SGGridCol>
                    <SGGridCol span={12}>
                      <SGBox margin={{top: "lg"}}>
                        <SGGridRow>
                          <SGGridCol offset={1}>
                            <BoutonSGAgenda
                              type="primary"
                              onClick={handleOnClickRdv}
                              icon={
                                <SGIcon
                                  component={<SGAvenirStrokedMesRendezVous />}
                                  currentcolor
                                />
                              }
                              disabled={showLoader}
                              disableautomargin
                            >
                              {intl.formatMessage({
                                id: "parcoursEpargner.button.contacter",
                              })}
                            </BoutonSGAgenda>
                          </SGGridCol>
                          <SGGridCol
                            span={8}
                          >
                            <SGText>
                              {intl.formatMessage(
                                {
                                  id: "parcoursEpargner.tabEpargner.contacter.text",
                                },
                                {
                                  linebreak: <br />,
                                }
                              )}
                            </SGText>
                          </SGGridCol>
                        </SGGridRow>
                      </SGBox>
                    </SGGridCol>
                  </SGGridRow>
                </SGGridCol>
                <SGGridCol span={12}>
                  <SGAccordion
                    onChange={() => setIsAccordionOpen(!isAccordionOpen)}
                    defaultActiveKey={isAccordionOpen ? "panelDetail" : ""}
                    disableContentMargin
                  >
                    <SGAccordionPanel
                      header={intl.formatMessage({
                        id: `parcoursEpargner.tabEpargner.detail`,
                      })}
                      key="panelDetail"
                    >
                      <SGGridRow>
                        <SGGridCol span={4}>
                          <DetailTab
                            lines={[
                              {
                                id: "epargne",
                                value:
                                  montantEpargne > lastMonthlyIncome
                                    ? lastMonthlyIncome
                                    : montantEpargne,
                              },
                              { id: "revenu", value: lastMonthlyIncome },
                              {
                                id: "deltaEpargneRevenu",
                                value:
                                  selectedRetirementAge.lastMonthlyIncome -
                                    montantEpargne >
                                  0
                                    ? lastMonthlyIncome - montantEpargne
                                    : 0,
                              },
                            ]}
                          />
                        </SGGridCol>
                        <SGGridCol span={8}>
                          <SGGridRow>
                            <SGGridCol span={12}>
                              <DetailTab
                                lines={[
                                  { id: "pension", value: montantPension },
                                  { id: "patrimoine", value: montantPatrimoine },
                                  { id: "delta-objectif", value: deltaObjectif },
                                  { id: "objectif", value: objectif },
                                ]}
                              />
                            </SGGridCol>
                            <SGGridCol
                              align="center"
                            >
                              <SGBox margin={{top: "xs"}}>
                                <SGLayout tagName="div" background="light">
                                  <SGBox padding={{top: "xs", bottom: "xs", left: "xs", right: "xs"}}>
                                    <SGText>
                                      <SGTextIntl
                                        intlId="parcoursEpargner.tabEpargner.info.2"
                                        transformations={{
                                          objectif: objectif.toLocaleString("fr-FR"),
                                          montantComplement:
                                            deltaObjectif.toLocaleString("fr-FR"),
                                          montantEpargne:
                                            montantEpargne.toLocaleString("fr-FR"),
                                          retirementYear:
                                            selectedRetirementAge.retirementYear,
                                          b: (word: string) => (
                                            <strong
                                              style={{
                                                fontFamily: "Source Sans Pro Bold",
                                              }}
                                            >
                                              {word}
                                            </strong>
                                          ),
                                        }}
                                      />
                                    </SGText>
                                  </SGBox>
                                </SGLayout>
                              </SGBox>
                            </SGGridCol>
                          </SGGridRow>
                        </SGGridCol>
                      </SGGridRow>
                    </SGAccordionPanel>
                  </SGAccordion>
                </SGGridCol>
              </SGGridRow>
            </SGCard>
            {showErrorModal && (
              <ErrorModal
                visible={showErrorModal}
                setVisible={setShowErrorModal}
                description="modal.error.message.indisponible"
              />
            )}
          </SGGridCol>
        </SGGridRow>
      </div>
    );
  }

  return (
    <>
      <SGBox margin={{top: "lg"}}>
        <SGText
          weight="700"
          style={{
            fontSize: "14px",
            fontFamily: "Montserrat",
            color: "#010035",
          }}
        >
          <FormattedMessage
            id="parcoursEpargner.tabEpargner.title.futur"
            values={{
              objectif: objectif.toLocaleString("fr-FR"),
              montantEpargne:
                montantEpargne.toLocaleString("fr-FR"),
              grey: (word: string) => (
                <label className="tab-epargner-mob__montant-objectif">
                  {word}
                </label>
              ),
              blue: (word: string) => (
                <label className="tab-epargner-mob__montant-epargne">
                  {word}
                </label>
              ),
            }}
          />
        </SGText>
      </SGBox>
      <SGText underline weight="600" size="s">
        <FormattedMessage
          id="parcoursEpargner.tabEpargner.title.hypotheses"
          values={{
            hypothese: (word: string) => (
              <span
                style={{ cursor: "pointer" }}
                onClick={onClickHypothesisModal}
              >
                {word}
              </span>
            ),
          }}
        />
      </SGText>
      {showHypothesisModal && (
        <HypothesisModal
          setVisible={setShowHypothesisModal}
          visible={showHypothesisModal}
          anchor={HypothesisAnchor.EPARGNER}
          closeFirstGroup
        />
      )}
      <SGCard className="tab-epargner-mob__card-container">
        <SGSpace direction="vertical">
          <EpargnerCard
            key="epargnerCard-1"
            salaryMonth={montantEpargne}
            color="blue"
            isInput
            setSalaryMonth={setMontantEpargne}
            selectedRetirementAge={selectedRetirementAge}
            title="parcoursEpargner.tabEpargner.subtitle.epargne"
            subtitle={
              <FormattedMessage
                id="parcoursEpargner.card.annee.pendant"
                values={{
                  dureeEpargne: getDureeEpargneYear(
                    selectedRetirementAge
                  ),
                }}
              />
            }
            icone={<SGAvenirStyledEpargne />}
            tooltip="parcoursEpargner.tabEpargner.subtitle.epargne.tooltip"
          />
          <SGDivider key="divider-1"/>
          <EpargnerCard
            key="epargnerCard-2"
            salaryMonth={deltaObjectif}
            color="dark-green"
            selectedRetirementAge={selectedRetirementAge}
            showLoader={showLoader}
            title="parcoursEpargner.tabEpargner.subtitle.complement"
            subtitle={
              <FormattedMessage
                id="parcoursEpargner.card.annee.pendant"
                values={{
                  dureeEpargne: getDureeComplementYear(
                    selectedRetirementAge
                  ),
                }}
              />
            }
            icone={<SGAvenirStyledRetraite />}
            tooltip="parcoursEpargner.tabEpargner.subtitle.complement.tooltip"
          />
          <SGDivider key="divider-2" />
          <EpargnerCard
            key="epargnerCard-3"
            salaryMonth={objectif}
            color="grey"
            showLoader={showLoader}
            title="parcoursEpargner.tabEpargner.subtitle.objectif"
            subtitle={
              <FormattedMessage
                id="parcoursEpargner.card.annee.depart"
                values={{
                  annee: selectedRetirementAge.retirementAgeYear,
                  mois: selectedRetirementAge.retirementAgeMonth,
                  showMois: (word: string) =>
                    selectedRetirementAge.retirementAgeMonth ? (
                      <SGText>{word}</SGText>
                    ) : (
                      <SGText />
                    ),
                }}
              />
            }
            icone={<SGAvenirStyledRetraite />}
          />
        </SGSpace>
         <SGAccordion
            onChange={() => setIsAccordionOpen(!isAccordionOpen)}
            defaultActiveKey={isAccordionOpen ? "panelDetail" : ""}
            disableContentMargin
          >
            <SGAccordionPanel
              header={intl.formatMessage({
                id: `parcoursEpargner.tabEpargner.detail`,
              })}
              key="panelDetail"
            >
              <SGLayout tagName="div" background="light">
                <SGSpace direction="vertical" disableautomargin>
                  <DetailTab
                    key="detailTab-1"
                    disableautomarginDivider
                    lines={[
                      {
                        id: "epargne",
                        value:
                          montantEpargne > lastMonthlyIncome
                            ? lastMonthlyIncome
                            : montantEpargne,
                      },
                      { id: "revenu", value: lastMonthlyIncome },
                      {
                        id: "deltaEpargneRevenu",
                        value:
                          selectedRetirementAge.lastMonthlyIncome -
                            montantEpargne >
                          0
                            ? lastMonthlyIncome - montantEpargne
                          : 0,
                      },
                    ]}
                  />
                  <DetailTab
                    key="detailTab-2"
                    disableautomarginDivider
                    lines={[
                      { id: "pension", value: montantPension },
                      { id: "patrimoine", value: montantPatrimoine },
                      { id: "delta-objectif", value: deltaObjectif },
                      { id: "objectif", value: objectif },
                    ]}
                  />
                  <SGBox key="box" padding={{right: "sm", left: "sm"}}>
                    <SGGridRow gutter={[0,8]}>
                      <SGGridCol span={12}>
                        <SGDivider disableautomargin />
                      </SGGridCol>
                      <SGGridCol span={6}>
                        <SGText size="s">
                          <SGTextIntl intlId="parcoursEpargner.DetailTab.label.objectif" />
                        </SGText>
                      </SGGridCol>
                      <SGGridCol textalign="right" span={6}>
                        <SGText size="s">
                          {objectif?.toLocaleString("fr-FR")} € / mois
                        </SGText>
                      </SGGridCol>
                      <SGGridCol span={12}>
                        <SGBox margin={{top: "sm"}}>
                          <SGText>
                            <SGTextIntl
                              intlId="parcoursEpargner.tabEpargner.info.2"
                              transformations={{
                                objectif: objectif.toLocaleString("fr-FR"),
                                montantComplement:
                                  deltaObjectif.toLocaleString("fr-FR"),
                                montantEpargne:
                                  montantEpargne.toLocaleString("fr-FR"),
                                retirementYear:
                                  selectedRetirementAge.retirementYear,
                                b: (word: string) => (
                                  <strong
                                    style={{
                                      fontFamily: "Source Sans Pro Bold",
                                    }}
                                  >
                                    {word}
                                  </strong>
                                ),
                              }}
                            />
                          </SGText>
                        </SGBox>
                      </SGGridCol>
                      <SGGridCol span={12}>
                        <SGDivider disableautomargin />
                      </SGGridCol>
                      <SGGridCol span={12}>
                        <SGText size="s" color="lighter">
                          <FormattedMessage id="parcoursEpargner.moduleEpargner.mention" />
                        </SGText>
                      </SGGridCol>
                      <SGGridCol span={12}>
                        <SGBox padding={{ top: "xs", bottom: "md" }}>
                          <SGButton
                            type="link"
                            onClick={onClickHypothesisModal}
                          >
                            <FormattedMessage id="parcoursEpargner.moduleEpargner.mention.link" />
                          </SGButton>
                        </SGBox>
                      </SGGridCol>
                    </SGGridRow>
                  </SGBox>
                </SGSpace>
              </SGLayout>
            </SGAccordionPanel>
          </SGAccordion>
        </SGCard>
        {showErrorModal && (
          <ErrorModal
            visible={showErrorModal}
            setVisible={setShowErrorModal}
            description="model.error.message.indisponible"
          />
        )}
      </>
    );
};

export { TabEpargner };
