import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGProgress } from "sg-progress";
import { SGText } from "sg-typo";
import { IncomeTaxPayment } from "store/simulateurEconomiesImpots/types";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import "./BlocImpot.scss";
import { useMediaQuery } from "sg-media-query";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface Tranche {
    taux: number;
    revenu: number;
    ratio: number;
    plafond: number;
}

interface BlocImpotProps {
    typeBloc: "apres" | "avant";
    incomeTax: IncomeTaxPayment;
    handleDeplier: (deplie: boolean) => void;
}

const BlocImpot: FunctionComponent<BlocImpotProps> = (props: BlocImpotProps) => {
    const { typeBloc, incomeTax, handleDeplier } = props;
    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });
    const { trackClick } = useTracking();

    const [tranches, setTranches] = useState<Tranche[]>([]);

    useEffect(() => {
        const newTranches: Tranche[] = [
            { taux: 0, revenu: incomeTax.taxableIncomeBracket1, ratio: 0, plafond: incomeTax.taxableIncomeBracket1 },
            { taux: 11, revenu: incomeTax.taxableIncomeBracket2, ratio: 0, plafond: incomeTax.taxableIncomeBracket2 },
            { taux: 30, revenu: incomeTax.taxableIncomeBracket3, ratio: 0, plafond: incomeTax.taxableIncomeBracket3 },
            { taux: 41, revenu: incomeTax.taxableIncomeBracket4, ratio: 0, plafond: incomeTax.taxableIncomeBracket4 },
            { taux: 45, revenu: incomeTax.taxableIncomeBracket5, ratio: 0, plafond: incomeTax.taxableIncomeBracket5 },
        ];
        for (let i = 0; i < newTranches.length; i += 1) {
            // Calcul de la borne en soustrayant la borne inférieure
            const bornesTranche = newTranches[i].plafond - (i > 0 ? newTranches[i - 1].plafond : 0);
            // Si on est dans une tranche supérieure, en comparant le revenu initial de la tranche
            if (incomeTax.taxableIncome > newTranches[i].plafond) {
                newTranches[i].ratio = 100;
                newTranches[i].revenu = bornesTranche;
            } else {
                const revenuImposableTranche = incomeTax.taxableIncome - (i > 0 ? newTranches[i - 1].plafond : 0);
                newTranches[i].ratio = revenuImposableTranche / bornesTranche;
                newTranches[i].revenu = revenuImposableTranche;
                break;
            }
        }
        // Suppression des tranches supérieures, tri décroissant
        // Filtre des 3 plus hautes en mobile et l'intégralité en desktop avant l'affichage sinon non dynamique
        setTranches(
            newTranches
                .filter((tranche) => tranche.ratio > 0)
                .reverse()
        );
    }, [incomeTax]);

    const onChange = (activeKey: string) => {
        trackClick(`mon-economie-impot::clic-sur-deplier-impot-${typeBloc}-versement`);
        handleDeplier(activeKey === "mainPanel");
    }

    return (
        <SGCard className="bloc-impot">
            <SGAccordion onChange={onChange} disableautomargin disableContentMargin expandIconPosition="right" defaultActiveKey="" divider={false}>
                <SGAccordionPanel
                    key="mainPanel"
                    header={
                        <SGText className="bloc-impot__header-text">{
                            intl.formatMessage(
                                { id: `simulateurEconomiesImpots.step.versement.bloc-impot.header.${typeBloc}` },
                                { value: <SGText className="bloc-impot__header-text-value">{`${incomeTax.incomeTax.toLocaleString("fr-FR")} €`}</SGText> }
                            )}</SGText>
                    }
                >
                    <SGGridRow gutter={[0, 10]}>
                        <SGGridCol span={12}>
                            <SGGridRow>
                                <SGGridCol span={6} textalign="left">
                                    <SGTextIntl intlId="simulateurEconomiesImpots.step.versement.bloc-impot.title.taux" className="bloc-impot__table-header" />
                                </SGGridCol>
                                <SGGridCol span={6} textalign="right">
                                    <SGTextIntl intlId="simulateurEconomiesImpots.step.versement.bloc-impot.title.revenu" className="bloc-impot__table-header" />
                                </SGGridCol>
                            </SGGridRow>
                        </SGGridCol>
                        {tranches.slice(0, isPhone ? 3 : 5).map((tranche: Tranche) => (
                            <SGGridCol span={12} key={tranche.taux}>
                                <SGGridRow align="middle">
                                    <SGGridCol span={2}>{tranche.taux}&nbsp;%</SGGridCol>
                                    <SGGridCol span={7}>
                                        <SGProgress disableautomargin percent={tranche.ratio * 100} status="success" size="lg" showInfo={false} arialabel={`${tranche.taux} %`} />
                                    </SGGridCol>
                                    <SGGridCol span={3}>{tranche.revenu.toLocaleString("fr-FR")}&nbsp;€</SGGridCol>
                                </SGGridRow>
                            </SGGridCol>
                        ))}
                    </SGGridRow>
                </SGAccordionPanel>
            </SGAccordion>
        </SGCard>
    );
};

export { BlocImpot };
