import debounce from "lodash.debounce";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { updateAndReloadPrefs, updatePrefs } from "store/dashboard/actions";
import { DashboardAge, User, hasFetchedDashboard } from "store/dashboard/types";
import { getMonthlyIncome, getSavingEffort } from "store/savingPath/actions";
import {
  MonthlyIncomeRequest,
  SavingEffortRequest,
  SavingPathState,
  convertAgeTypeToWealthProfile,
  hasFetchedSavingPathMonthlyEffort,
  hasFetchedSavingPathObjective,
} from "store/savingPath/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { ChoixAge } from "website/components/organisms/Dashboard/ChoixAge/ChoixAge";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { TabEpargner } from "../TabEpargner/TabEpargner";
import "./ModuleEpargner.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface TabModel {
  tabName: ReactNode;
  key: string;
  ageRetraite?: DashboardAge;
}

interface ModuleEpargnerProps {
  montantEpargne: number; // Initialisé à 0 pour SGAgenda
  setMontantEpargne: (montantEpargne: number) => void;
  showLoader: boolean;
  setShowLoader: (showLoader: boolean) => void;
}

const ModuleEpargnerMob: FunctionComponent<ModuleEpargnerProps> = (
  props: ModuleEpargnerProps
) => {
  const dispatch = useDispatch();
  const { montantEpargne, setMontantEpargne, showLoader, setShowLoader } =
    props;
  const { trackClick, trackPage } = useTracking();

  const dashboardState = useDashboardState();
  const { agesTries, selectedRetirementAge } = useSelectedRetirementAge();
  const savingPathState = useSelector<State, SavingPathState>(
    (state) => state.savingPath
  );
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const [objectif, setObjectif] = useState(0);
  const [saveMotorResult, setSaveMotorResult] = useState(true);
  const [lastProfile, setLastProfile] = useState<string>();
  const [lastSavingAmount, setLastSavingAmount] = useState(0);
  const [agesDepartDashboard, setAgesDepartDashboard] = useState<
    DashboardAge[]
  >([]);
  const [lastSavedIncomeGoal, setLastSavedIncomeGoal] = useState(0);
  const [selectedAge, setSelectedAge] = useState<DashboardAge>(
    agesDepartDashboard[0]
  );

  const saveChoosenAgeToDb = (val: DashboardAge) => {
    setSelectedAge(val);
    trackClick(
      `age-cle::clic-sur-selection-ages-${val.type.replace(/_/g, "-")}`
    );
    const req: User = {
      choosenAge: val.type,
    };
    dispatch(updatePrefs(req));
  };

  const saveMonthlyIncomeGoalToDb = (val: number) => {
    const roundedVal = roundDown10(val);
    setObjectif(roundedVal);

    if (lastSavedIncomeGoal !== roundedVal) {
      const req: User = {
        monthlyIncomeGoal: roundedVal,
      };
      dispatch(updateAndReloadPrefs(req));
      setLastSavedIncomeGoal(roundedVal);
    }
  };

  const debouncedCall = useCallback(
    debounce(
      (val: number, rev: boolean, wp: string) =>
        callSavingPathMotor(val, rev, wp),
      400
    ),
    [] // will be created only once initially
  );

  /**
   * Appelle le moteur dans un sens ou dans l'autre
   *
   * @param val Valeur à envoyer
   * @param rev Si oui, on envoie l'effort d'épargne
   * @param wp Profil Wealth
   */
  const callSavingPathMotor = (val: number, rev: boolean, wp: string) => {
    if (rev) {
      const req: MonthlyIncomeRequest = {
        profile: wp,
        monthlySavingEffort: val,
      };
      dispatch(getMonthlyIncome(req));
    } else {
      const req: SavingEffortRequest = {
        profile: wp,
        monthlyIncomeObjective: val,
      };
      dispatch(getSavingEffort(req));
    }
  };

  useEffect(() => {
    trackPage("epargner", "age-cle", PAGE_TYPE_GESTION);

    if (!assetState.hasFetched) {
      dispatch(getAssetsWithoutLoans());
    }
    if (!hasFetchedAssets(assetIncomesState)) {
      dispatch(getAssetsIncomes());
    }
  }, []);

  // Récupération des âges dans le dashboard
  useEffect(() => {
    // Si on a déjà récupéré les âges, on s'arrête là
    if (agesDepartDashboard && agesDepartDashboard.length > 0) {
      return;
    }
    if (
      hasFetchedDashboard(dashboardState) &&
      agesTries &&
      selectedRetirementAge &&
      assetState.hasFetched &&
      hasFetchedAssets(assetIncomesState)
    ) {
      // Construction des AgesDeparts à partir de dashboardInformations, en supervision mode consultation on récupère seulement l'âge choisi
      setAgesDepartDashboard(
        !possedeDroitsActionUtilisateur(supervisionState)
          ? [selectedRetirementAge]
          : agesTries
      );

      // Récupération de l'objectif
      let newObjectif = dashboardState.dashboard.monthlyIncomeGoal || 0;
      const additionalIncome = roundDown10(
        computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
      );
      const minIncome = roundDown10(
        selectedRetirementAge.retirementMonthlyIncome + additionalIncome
      );

      if (newObjectif < minIncome) {
        newObjectif = minIncome;
      }

      if (newObjectif !== dashboardState.dashboard.monthlyIncomeGoal) {
        saveMonthlyIncomeGoalToDb(newObjectif);
      } else {
        // Car initialisé à 0
        setObjectif(newObjectif);
      }

      // Get choosen age
      setSelectedAge(selectedRetirementAge);
    }
  }, [
    dashboardState,
    agesTries,
    selectedRetirementAge,
    assetState,
    assetIncomesState,
  ]);

  useEffect(() => {
    if (agesDepartDashboard && agesDepartDashboard.length > 0) {
      // save first Saving Path Date
      if (dashboardState.dashboard.firstSavingPathDate === undefined) {
        const req: User = {
          firstSavingPathDate: new Date(),
        };
        dispatch(updatePrefs(req));
      }
    }
  }, [agesDepartDashboard]);

  // Changement d'onglet ou de montantEpargne, hors COMPARER
  useEffect(() => {
    if (!selectedAge) {
      return;
    }
    const wealthProfile = convertAgeTypeToWealthProfile(selectedAge.type);
    // init TabEpargner : on calcule le premier effort d'épargner à afficher
    if (!lastProfile) {
      // On initialise l'effort d'épargne si l'objectif de revenu est défini
      // sinon on initialise les revenus de retraite sans effort d'épargne
      if (objectif !== 0) {
        setSaveMotorResult(false);
        callSavingPathMotor(objectif, false, wealthProfile);
      } else {
        callSavingPathMotor(0, true, wealthProfile);
      }
      // Le montant d'épargne a changé : on calcul l'objectif de revenu correspondant
    } else if (lastSavingAmount !== montantEpargne) {
      if (lastSavedIncomeGoal === objectif) {
        setShowLoader(true);
      }

      debouncedCall(montantEpargne, true, wealthProfile);
      // On change d'onglet TabEpargner : on calcul l'objectif de revenu correspondant
    } else if (lastProfile !== wealthProfile) {
      setShowLoader(true);
      callSavingPathMotor(montantEpargne, true, wealthProfile);
    }
    setLastSavingAmount(montantEpargne);
    setLastProfile(wealthProfile);
  }, [montantEpargne, selectedAge]);

  // Récupération de l'objectif ou du revenu via le moteur
  useEffect(() => {
    if (!selectedAge) {
      return;
    }
    if (saveMotorResult && hasFetchedSavingPathObjective(savingPathState)) {
      saveMonthlyIncomeGoalToDb(savingPathState.objective);
      setShowLoader(false);
    } else if (
      !saveMotorResult &&
      hasFetchedSavingPathMonthlyEffort(savingPathState)
    ) {
      setSaveMotorResult(true);
      setMontantEpargne(savingPathState.monthlyEffort);
    }
  }, [savingPathState]);

  return (
    <SGGridCol span={12} >
      <div className="ModuleEpargner">
        <SGCard className="ModuleEpargner__choix-age">
          <ChoixAge
            agesDepartRetraite={agesDepartDashboard}
            setSelectedRetirementAge={saveChoosenAgeToDb}
            selectedRetirementAge={selectedAge}
          />
        </SGCard>
        {selectedAge && (
          <TabEpargner
            selectedRetirementAge={selectedAge}
            objectif={objectif}
            montantEpargne={montantEpargne}
            setMontantEpargne={setMontantEpargne}
            showLoader={showLoader}
          />
        )}
      </div>
    </SGGridCol>
  );
};

export { ModuleEpargnerMob };
