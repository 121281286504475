import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {ExpenseState} from "../../../store/expenses/types";
import {getExpensesAction} from "../../../store/expenses/actions";

const useExpenses = () => {
   const dispatch = useDispatch();
   const expenseState: ExpenseState = useSelector<State, ExpenseState>((state) => state.expenses);

   useEffect(() => {
      if (!expenseState.hasFetchedExpenses) {
         dispatch(getExpensesAction());
      }
   }, []);

   return expenseState.expenses;
};

export {useExpenses};
