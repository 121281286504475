import React, {FunctionComponent} from "react";
import {useSelector} from "react-redux";
import {NewProject} from "../../../../../store/newProjects/types";
import {State} from "../../../../../store/store";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGCard} from "sg-card";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGSpace} from "sg-space";
import {ProjectIcon} from "../../../atoms/ProjectIcon/ProjectIcon";
import {replaceSpecialCharsAndLowercase} from "../../../../utils/formatting/replaceSpecialCharsAndLowercase";
import {useIntl} from "react-intl";
import {SGText} from "sg-typo";
import {SGDivider} from "sg-divider";
import {ProjectCategory} from "../../../../../store/projects/types";
import {useNewProject} from "../../../hooks/useNewProject";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const NewProjects: FunctionComponent = () => {
   const displayComponent = useSelector<State, boolean>((state) => state.newProjects.hasFetched);
   const intl = useIntl();
   const { trackClick } = useTracking();

   const categories: ProjectCategory[] = Object.values(ProjectCategory);

   const newProjectState = useNewProject();

   const newProjectCard = (newProject : NewProject) => (
         <SGGridCol lg={4} md={6} xs={12} data-cy={`newProjects-element-${newProject.projectType}`} key={newProject.projectType}>
            <SGCard clickable={newProject.clickable} bordered dark={!newProject.clickable} textalign="center"
                    onClick={() => {
                       if (newProject.clickable) {
                          trackClick(`clic-sur-le-projet-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: newProject.title}))}`);
                          redirectToRelativePath(newProject.url)
                       }
                    }}>
               <SGSpace direction="vertical">
                  <ProjectIcon projectType={newProject.projectType}/>
                  <SGTextIntl intlId={newProject.title} cypressName={`newProjects-element-${newProject.projectType}-title`} strong/>
                  {newProject.clickable &&
                     <SGTextIntl intlId={newProject.description}/>
                  }
                  {!newProject.clickable &&
                     <SGText>{newProject.notClickableReason}</SGText>
                  }
               </SGSpace>
            </SGCard>
         </SGGridCol>
      );

   return (
      <>
         <SGTitleIntl intlId="new.project.selection" cypressName="newProject-title"/>
         {newProjectState.cannotFetchNewProjects && (
            <SGAlertIntl cypressName="newProject-cannot-fetch-error" type="warning" title="common.warning">
               <SGTextIntl intlId="new.project.error"/>
            </SGAlertIntl>
         )}
         {!newProjectState.cannotFetchNewProjects && (
            <SpinnerWrapper spinnerSize={SpinnerSize.LG} displayComponent={displayComponent} fatalErrorOnTimeout={false}>
               {categories.map((category: ProjectCategory) => (
                  <>
                     <SGSpace direction="vertical">
                        <SGTextIntl intlId={`new.project.${category}`} cypressName={`newProject-${category}`} size="l" strong/>
                     </SGSpace>
                     <SGGridRow align="stretch" justify="center">
                        {displayComponent &&
                           newProjectState.newProjects.filter((newProject: NewProject) => newProject.category === category).map((newProject: NewProject) => newProjectCard(newProject))
                        }
                     </SGGridRow>
                     {category !== ProjectCategory.OTHER &&
                        <SGDivider/>
                     }
                  </>
               ))}
            </SpinnerWrapper>
         )}
      </>
   );
};

export {NewProjects};
