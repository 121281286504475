import {FamilyStatus} from "../members/types";
import {AssetWithValuation} from "../assets/types";

export interface InheritanceSimulatorState {
   result: InheritanceSimulatorResult;
   hasFetched: boolean;
}

export interface InheritanceSimulatorResult {
   amountPerHeir: number;
   feesAmountPerHeir: number;
   assetDTOList: Array<AssetWithValuation>;
}

export interface InheritanceSimulatorParameter {
   heirsType: FamilyStatus;
   numberOfHeirs: number;
}

// actions
export const FETCH_INHERITANCE_SHARES = "FETCH_INHERITANCE_SHARES";
