import {LIFE_PROJECTS, Project} from "../../../store/projects/types";
import {ChartsAlert, ChartsPoint, ChartsProject} from "../../../store/charts/types";

export function toGraph(
   datum: any,
   dataType: string,
   range: Array<number>,
   isExpense: boolean
): Array<ChartsPoint> {
   const graph: Array<any> = [];
   const data = datum[dataType];
   if (!data) {
      return graph;
   }
   const keys = range;
   for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!!data[key] && data[key].d !== 0) {
         graph.push({x: parseInt(data[key].a), y: isExpense ? -data[key].d : data[key].d, type: dataType});
      }
   }

   return graph;
}

export function toAggregatedGraph(
   datum: any,
   serie: { name: string; series: Array<string> },
   range: Array<number>
): Array<ChartsPoint> {
   const graph: Array<any> = [];
   const keys = range;
   for (let i = 0; i < keys.length; i++) {
      let total = 0;
      const key = keys[i];
      let age = -1;
      for (let j = 0; j < serie.series.length; j++) {
         const name = serie.series[j];
         if (!!datum[name] && !!datum[name][key]) {
            total += datum[name][key].d;
            age = parseInt(datum[name][key].a);
         }
      }
      if (age > 0) {
         graph.push({x: age, y: total, type: serie.name});
      }
   }

   return graph;
}

export function sumArrays(range: number[], type: string, birthday: number, ...arrays: ChartsPoint[] | []): ChartsPoint[] {
   const graph: Array<any> = [];
   for (let i = 0; i < range.length; i++) {
      graph.push({
         x: range[i] - birthday,
         // @ts-ignore
         y: arrays.map((xs: ChartsPoints[]) => xs.filter((cp) => cp.x === range[i] - birthday)[0]?.y || 0).reduce((sum, value) => sum + value, 0),
         type: type
      });
   }

   return graph;
}

export function getGraphPoints(
   xLabels: Array<number>,
   data: any,
   dataType: string,
   hiddenNetWorthTypes: Array<string> = ["SIMULATED_CASH"]
): Array<ChartsPoint> {
   const dataUp = data[dataType];
   const tmpUp: Array<any> = [];

   const keys = Object.keys(dataUp);
   for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const dUp = dataUp[key];
      for (let j = 0; j < xLabels.length; j++) {
         let ok = true;
         if (hiddenNetWorthTypes !== undefined) {
            for (let j = 0; j < hiddenNetWorthTypes.length; j++) {
               if (hiddenNetWorthTypes[j] === key) {
                  ok = false;
               }
            }
         }
         if (ok) {
            const year = xLabels[j];
            if (dUp[year]) {
               const age = dUp[year].a;
               tmpUp[age] = (tmpUp[age] || 0) + (dUp[year].d || 0);
            }
         }
      }
   }

   const NetWorth: Array<ChartsPoint> = [];
   const ages: Array<any> = Object.keys(tmpUp);
   ages.forEach((age) =>
      NetWorth.push({x: parseInt(age), y: tmpUp[age], type: dataType})
   );

   return NetWorth;
}

export function getGraphPointsIncomeExpenses(
   xLabels: Array<number>,
   data: any
): Array<ChartsPoint> {
   const tmpUp: Array<any> = [];

   const keys = Object.keys(data);
   for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const dUp = data[key];
      for (let j = 0; j < xLabels.length; j++) {
         let ok = true;
         if (ok) {
            const year = xLabels[j];
            if (dUp[year]) {
               const age = dUp[year].a;
               tmpUp[age] = (tmpUp[age] || 0) + (dUp[year].d || 0);
            }
         }
      }
   }

   const incomeExpenses: Array<ChartsPoint> = [];
   const ages: Array<any> = Object.keys(tmpUp);
   ages.forEach((age) =>
      incomeExpenses.push({x: parseInt(age), y: tmpUp[age], type: "all"})
   );

   return incomeExpenses;
}


export function getAlertsByAge(simulation: any): Array<ChartsAlert> {
   // On affiche les alerts qui ont 80% de chance de se produire
   const alerts = simulation.alerts.CONSERVATIVE;
   const years = Object.keys(alerts);

   return years
   .map((year) => alerts[year])
   .map(readAlertsByAge)
   .reduce((a: Array<ChartsAlert>, b: Array<ChartsAlert>) => a.concat(b), []);
}

export function readAlertsByAge(alerts: {
   a: string;
   y: string;
   d: Array<string>;
}): Array<ChartsAlert> {
   return alerts.d.map((alert: string) => ({
      year: parseInt(alerts.a),
      alert: `simulation.warningList.${alert}`,
      type: alert
   }));
}

export function filterPointByType(type: string): (point: any) => boolean {
   return (point) => point.point.type === type;
}

const keepVisibleProjects = function (projects: Array<Project>) {
   return projects.filter((x: Project) => LIFE_PROJECTS.includes(x.projectType));
};

export function place(
   projects: Array<Project>,
   projectDataCallback: (project: Project) => ChartsProject
) {
   const projectData = keepVisibleProjects(projects);
   return projectData.map(projectDataCallback).reduce(groupByYear, []);
}

function groupByYear(
   projects: Array<ChartsProject>,
   project: ChartsProject
): Array<ChartsProject> {
   // If some other project has a start year than another one, we stack the labels.
   // First, we try to find another project with the same year.
   const found = projects.find((item: any) => item.x === project.x);
   // If we find one, we stack actual project label to the found project.
   if (found) {
      found.name = `${found.name}<br/>${project.name}`;
   } else {
      projects.push(project);
   }

   return projects;
}
