import React, { FunctionComponent, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowTutoriel, updatePrefs } from "store/dashboard/actions";
import { User } from "store/dashboard/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AssuModal } from "website/components/molecules/AssuModal/AssuModal";

interface AccesTutorielModalProps {
    visible: boolean;
    setVisible: (showAccesTutorielModal: boolean) => void;
}

const AccesTutorielModal: FunctionComponent<AccesTutorielModalProps> = (props: AccesTutorielModalProps) => {
    const { visible, setVisible } = props;
    const dispatch = useDispatch();
    const { trackPopin, resetTracking } = useTracking();

    const saveTutorialDone = (afficheTuto: boolean, croix?: boolean) => {
        if (afficheTuto) {
            trackPopin(true, "tableau-de-bord", "popin-tutoriel-intro::clic-sur-je-decouvre", "tutoriel", "0");
        } else {
            trackPopin(
                true,
                "tableau-de-bord",
                croix ? "popin-tutoriel-intro::clic-sur-croix-fermeture" : "popin-tutoriel-intro::clic-sur-passer",
                "tutoriel",
                "0"
            );
        }
        setVisible(false);
        const req: User = {
            tutorialModalDate: new Date(),
        };

        dispatch(updatePrefs(req));

        if (!afficheTuto) {
            // Si on affiche le tuto, pas besoin de réinit le paramètre
            resetTracking();
        }

        dispatch(setShowTutoriel(afficheTuto));
    };

    return (
        <AssuModal
            type="accesTutoriel"
            visible={visible}
            closable
            onClickLeftButton={() => saveTutorialDone(false)}
            onClickRightButton={() => saveTutorialDone(true)}
            onCancel={() => saveTutorialDone(false, true)}
            isSubtitle
        />
    );
};

export { AccesTutorielModal };
