import {HighChartsAlert, Simulation, SimulationAlertByYear, SimulationAlerts} from "./types";

export const getSimulationRange = (simulation: Simulation): number[] => Array.from(Array(simulation.to + 1 - simulation.from).keys()).map((value: number, i: number) => simulation.from + i);

export const getAlertsByAge = (simulation: SimulationAlerts): Array<HighChartsAlert> => {
   // On affiche les alerts qui ont 80% de chance de se produire
   const conservativeAlerts = simulation.CONSERVATIVE;
   const years = Object.keys(conservativeAlerts);

   return years
   .map((year) => conservativeAlerts[parseInt(year)])
   .map(readAlertsByAge)
   .reduce(
      (a: Array<HighChartsAlert>, b: Array<HighChartsAlert>) => a.concat(b),
      []
   );
};

const readAlertsByAge = (alerts: SimulationAlertByYear): Array<HighChartsAlert> =>
   alerts.d.map((alert: string) => ({
      year: parseInt(alerts.a),
      alert,
      type: alert
   }));

export const LIMIT_AGE_FOR_RETIREMENT_ACHIEVEMENT = 45;
