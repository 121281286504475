import React, {FunctionComponent, ReactNode} from "react";
import {useIntl} from "react-intl";
import {SGButton} from "sg-button";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface SGButtonIntlProps {
   intlId: string;
   type: "primary" | "secondary" | "tertiary" | "icon" | "link";
   disabled?: boolean;
   size?: string;
   onClick?: () => void;
   icon?: ReactNode;
   iconPosition?: "left" | "right";
   cypressName?: string;
   disableautomargin?: boolean;
   trackingName?: string;
}

const SGButtonIntl: FunctionComponent<SGButtonIntlProps> = (props: SGButtonIntlProps) => {
   const {intlId, type, size, disabled, onClick, icon, iconPosition, disableautomargin, cypressName, trackingName} = props;

   const intl = useIntl();
   const { trackClick } = useTracking();

   return (
      <SGButton data-cy={cypressName} type={type} onClick={() => {
         if (trackingName) {
            trackClick(trackingName);
         }
         if (onClick) {
            onClick();
         }
      }} disabled={disabled} size={size || "md"} icon={icon} iconposition={iconPosition}
                disableautomargin={disableautomargin}>
         {intlId !== "" && intl.formatMessage({id: intlId})}
      </SGButton>
   );
};

export {SGButtonIntl};
