import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Income, IncomeState} from "../../../store/incomes/types";
import {getIncomesAction} from "../../../store/incomes/actions";

const useTotalIncomes = () => {
   const dispatch = useDispatch();
   const [totalIncomes, setTotalIncomes] = useState(0);
   const incomeState: IncomeState = useSelector<State, IncomeState>((state) => state.incomes);

   useEffect(() => {
      if (!incomeState.hasFetched) {
         dispatch(getIncomesAction());
      }
   }, []);

   useEffect(() => {
      if (incomeState.hasFetched) {
         setTotalIncomes(incomeState.incomes.reduce((sum: number, cur: Income) => sum + cur.monthlyAmount, 0));
      }
   }, [incomeState.incomes]);

   return totalIncomes;
};

export {useTotalIncomes};
