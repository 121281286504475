import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGAvatar } from "sg-avatar";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { AccountData } from "store/account/types";
import { PathType } from "store/dashboard/types";
import { Image } from "website/components/atoms/Image/Image";
import "./SideBarChatBot.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface CTA {
    text: string;
    redirectTo?: (value: boolean) => void;
    trackKey: string;
    hideCard?: boolean;
    complementTracking?: any;
}
interface SideBarChatBotProps {
    ctaToDisplay: CTA[];
    animateCTA: boolean;
    lastPathDone?: PathType;
    pourcentage: number;
    user?: AccountData;
    showCTA: boolean;
}

export const SideBarChatBot: FunctionComponent<SideBarChatBotProps> = (props: React.PropsWithChildren<SideBarChatBotProps>) => {
    const intl = useIntl();
    const { ctaToDisplay, animateCTA, lastPathDone, pourcentage, user, showCTA } = props;
    const [cta, setCta] = useState<CTA[]>([]);
    const [userProfile, setUserProfile] = useState<{ lastName: string; gender: string }>();
    const { trackClick } = useTracking();

    function trackChatBot(cta: CTA) {
        trackClick(`clic-sur-compagnon-${cta.trackKey}`, cta.complementTracking ?? {});

        return cta.redirectTo && cta.redirectTo(true);
    }

    useEffect(() => {
        if (user) {
            const gender = user?.title + user?.title !== "" ? user.title : "M.";
            setUserProfile({ lastName: capitalize(user?.lastName), gender: gender || "M." });
        }
    }, [user]);

    function capitalize(str: string) {
        if (str) {
            return str[0].toUpperCase() + str.toLowerCase().slice(1);
        }

        return "";
    }

    useEffect(() => {
        if (showCTA) {
            if (!animateCTA) {
                setCta(ctaToDisplay);
            }
            if (animateCTA && ctaToDisplay.length > 0 && ctaToDisplay[0] !== undefined) {
                setCta([]);
                const timer = setTimeout(() => {
                    setCta([ctaToDisplay[0]]);
                }, 3000);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [showCTA, ctaToDisplay]);

    useEffect(() => {
        if (animateCTA && ctaToDisplay.length > 0 && cta.length < ctaToDisplay.length) {
            const timer = setTimeout(() => {
                setCta((cta) => {
                    const newCtaToDisplay = [...cta];
                    newCtaToDisplay.push(ctaToDisplay[cta.length]);

                    return newCtaToDisplay;
                });
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [cta]);

    return (
        <>
            <SGBox padding={{ top: "xl", left: "sm" }}>
                <Image className="side-bar-chat-bot__logoMCR" src="images/SG_MCR.png" alt="Mon Compagnon Retraite" />
            </SGBox>
            {showCTA && (
                <>
                    {lastPathDone && (
                        <div className={`side-bar-chat-bot ${animateCTA ? "animated" : ""}`} >
                        <SGGridRow align="bottom" justify="end" span={12} gutter={[12]}>
                            <SGGridCol span={2} textalign="right">
                                <SGAvatar className="side-bar-chat-bot__avatar" src="images/SG_MCR_avatar.png" alt="avatar" />
                            </SGGridCol>
                            <SGGridCol span={10}>
                                <SGCard
                                    className="side-bar-chat-bot__chat-sg"
                                    shadow
                                    title={
                                        <SGText
                                            style={{
                                                fontSize: "24px",
                                                lineHeight: "30px",
                                                color: "#E2010B",
                                            }}
                                            weight="600"
                                        >
                                            {intl.formatMessage(
                                                { id: `dashboard.chatBot.subtitle.${lastPathDone}` },
                                                {
                                                    blue: (word: string) => (
                                                        <SGBlock disableautomargin
                                                            style={{
                                                                lineHeight: "20px",
                                                                color: "#0072B9",
                                                            }}>
                                                            <SGText
                                                                size="s"
                                                            >
                                                                {word}
                                                            </SGText>
                                                        </SGBlock>
                                                    ),
                                                    pourcentage,
                                                    gender: userProfile?.gender ? userProfile?.gender : "",
                                                    lastName: userProfile?.lastName,
                                                }
                                            )}
                                        </SGText>
                                    }
                                >
                                    <SGGridRow>
                                        <SGGridCol span={12}>
                                            <SGText
                                                style={{
                                                    color: "#010035",
                                                }}
                                                weight="600"
                                                size="s"
                                            >
                                                {intl.formatMessage({ id: `dashboard.chatBot.${lastPathDone}` })}
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGCard>
                            </SGGridCol>
                        </SGGridRow>
                        </div>
                    )}
                    <SGSpace
                        direction="vertical"
                        align="end"
                    >
                        {cta?.map(
                            (value: CTA) =>
                                !value.hideCard && (
                                    <SGCard
                                        className={`side-bar-chat-bot__chat-link ${animateCTA ? "animated" : ""}`}
                                        clickable
                                        key={value.trackKey}
                                    >
                                        <SGButton
                                            // FIXME Utiliser plutôt tertiary, mais ne supporte pas le disabled pour la couleur
                                            type="primarylink"
                                            icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                            iconposition="right"
                                            onClick={() => trackChatBot(value)}
                                            disabled={!value.redirectTo}
                                        >
                                            <SGBox textalign="left">
                                                {intl.formatMessage({ id: `dashboard.side.bar.chat.${value.text}` })}
                                            </SGBox>
                                        </SGButton>
                                    </SGCard>
                                )
                        )}
                    </SGSpace>
                </>
            )}
        </>
    );
};
