import React, {FunctionComponent} from "react";
import {DefaultProject} from "../../components/organisms/Projects/DefaultProject/DefaultProject";
import {ProjectType} from "../../../store/projects/types";
import {SGContent} from "sg-layout";

const ReceivedDonationProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <DefaultProject
         projectType={ProjectType.RECEIVED_DONATION}
         projectTitle="project.received.donation.title"
         projectSubtitle="project.received.donation.subtitle"
         projectAmountLabel="project.received.donation.form.amount"
         projectAmountPlaceholder="project.received.donation.form.amount.placeholder"
         projectHorizonLabel="project.received.donation.form.horizon"
         newProjectTitle="new.project.title.received.donation"
         cypressName="received-donation"
      />
   </SGContent>
);

export {ReceivedDonationProjectPage};
