import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { DashboardAge, DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { TRACKING_CLICK_HYPOTHESES } from "website/components/hooks/tracking/types";
import { HypothesisModal } from "website/components/molecules/HypothesisModal/HypothesisModal";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { MaxRisErrorModal } from "website/components/molecules/MaxRisErrorModal/MaxRisErrorModal";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { PARCOURS_RIS, SIMULATEUR_FLASH } from "website/utils/privateRoutes";
import { SalaryByMonth } from "../../../atoms/SalaryByMonth/SalaryByMonth";
import "./Pension.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { PARAM_RESULTAT, QUERY_PARAM_ETAPE } from "website/pages/ParcoursRISPage";

interface PensionProps {
  selectedRetirementAge?: DashboardAge;
  showDisclamerAnalyserRIS: boolean;
}

const Pension: FunctionComponent<PensionProps> = (props: PensionProps) => {
  const history = useHistory();
  const {
    selectedRetirementAge,
    showDisclamerAnalyserRIS,
  } = props;
  const [montantPension, setMontantPension] = useState<number>();
  const [fiabilite, setFiabilite] = useState<number>();
  const [isParcoursRISDone, setIsParcoursRISDone] = useState(false);
  const [dateParcoursRis, setDateParcoursRis] = useState<string>();
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);
  const [accessibiliteRisClicked, setAccessibiliteRisClicked] =
    useState<boolean>(false);
  const { disponibiliteSimulateurFlash, disponibiliteParcoursRIS } =
    useFeatures();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const intl = useIntl();
  const { trackClick } = useTracking();

  useEffect(() => {
    setFiabilite(dashboardState?.dashboard?.reliability);
    setIsParcoursRISDone(dashboardState?.dashboard?.risDate !== undefined);
    if (dashboardState?.dashboard?.lastProjectCreationDate !== undefined) {
      const { lastProjectCreationDate } = dashboardState.dashboard;
      setDateParcoursRis(
        new Date(lastProjectCreationDate).toLocaleDateString()
      );
    }
  }, [dashboardState]);

  useEffect(() => {
    if (
      selectedRetirementAge &&
      selectedRetirementAge.retirementMonthlyIncome
    ) {
      setMontantPension(
        roundDown10(selectedRetirementAge.retirementMonthlyIncome)
      );
    }
  }, [selectedRetirementAge]);

  const getFiabilite = () => {
    if (fiabilite) {
      if (fiabilite < 33) {
        return "faible";
      }

      if (fiabilite > 66) {
        return "eleve";
      }
    }

    return "modere";
  };

  const handleOnClickModifier = () => {
    if (disponibiliteSimulateurFlash && !isParcoursRISDone) {
      trackClick(`clic-sur-modification-simulateur-flash`);
      history.push(SIMULATEUR_FLASH);
    } else if (isParcoursRISDone) {
      trackClick(`clic-sur-modification-ris`);
      setAccessibiliteRisClicked(true);
    }
  };

  const handleOnClickLinkRIS = () => {
    if (isParcoursRISDone) {
      // Action consulter
      trackClick("clic-sur-consulter-mon-analyse-ris");
      history.push(`${PARCOURS_RIS}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`);
    } else {
      // Action analyser
      trackClick("clic-sur-analyser-mon-ris");
      setAccessibiliteRisClicked(true);
    }
  };

  const redirectToParcoursRIS = () => {
      history.push(PARCOURS_RIS);
  };

  function onClickHypothesisModal() {
    trackClick(TRACKING_CLICK_HYPOTHESES);
    setShowHypothesisModal(true);
  }

  return (
    <SGCard
      bordered={false}
      borderleft="7px solid #9F2D62 !important"
      borderright=""
      coversize="md"
      className={`pension__card ${isParcoursRISDone && "pension__card-color"}`}
      hoverable
      title={
        <SGSpace direction="vertical" size="sm">
          <SGBox key="box-padding" padding={{ left: "sm", right: "sm" }}>
            <SGGridRow
              align="middle"
              justify="start"
            >
              <SGGridCol flex="auto">
                <SGBox inline margin={{ right: "xs" }}>
                  <SGTitle level={2} visuallevel={4}>
                    {intl.formatMessage({ id: "dashboard.pension.title" })}
                  </SGTitle>
                </SGBox>
                <SGBox inline>
                  <InfoTooltipAssu
                    text="dashboard.pension.tooltip.info"
                    placement="leftTop"
                    transformations={{
                      hypothese: (word: string) => (
                        <SGButton
                          type="link"
                          onClick={() => onClickHypothesisModal()}
                        >
                          <SGText size="xs">{word}</SGText>
                        </SGButton>
                      ),
                    }}
                  />
                </SGBox>
              </SGGridCol>
              {((!isParcoursRISDone && disponibiliteSimulateurFlash) ||
                (isParcoursRISDone && disponibiliteParcoursRIS)) && (
                <SGGridCol textalign="right" flex="initial">
                    <BoutonSupervision type="secondary" size="sm" disableautomargin onClick={handleOnClickModifier}>
                        {intl.formatMessage({ id: "dashboard.pension.modifier" })}
                    </BoutonSupervision>
                </SGGridCol>
              )}
              <SGGridCol span={12}>
                {montantPension && (
                  <SalaryByMonth
                    color="purple"
                    salaryMonth={montantPension}
                  />
                )}
              </SGGridCol>
              {isParcoursRISDone && !showDisclamerAnalyserRIS && (
                <>
                  <SGGridCol span={12}>
                    <SGText
                      size="m"
                      weight="600"
                      className="pension__fiabilite-ris"
                    >
                      {intl.formatMessage(
                        {
                          id: "parcoursRIS.resultat.reliability",
                        },
                        {
                          b: (word: string) => (
                            <strong
                              style={{ fontFamily: "Source Sans Pro Bold" }}
                            >
                              {word}
                            </strong>
                          ),
                          reliability: intl.formatMessage({
                            id: `parcoursRIS.resultat.reliability.${getFiabilite()}`,
                          }),
                        }
                      )}
                    </SGText>
                  </SGGridCol>
                  <SGGridCol span={12}>
                    <SGText size="m" weight="400">
                      {intl.formatMessage(
                        {
                          id: "dashboard.pension.resultats.ris",
                        },
                        {
                          date: dateParcoursRis,
                        }
                      )}
                    </SGText>
                  </SGGridCol>
                </>
              )}
              {showDisclamerAnalyserRIS && (
                <SGGridCol span={12}>
                  <SGText>
                    {intl.formatMessage(
                      { id: "dashboard.disclaimer.parcoursRIS.departAnticipe" },
                      { b: (word) => <SGText strong>{word}</SGText> }
                    )}
                  </SGText>
                </SGGridCol>
              )}
            </SGGridRow>
          </SGBox>
          <SGDivider key="divider" disableautomargin />
        </SGSpace>
      }
    >
      <SGBox textalign="center" margin={{ left: "xs" }}>
        {disponibiliteParcoursRIS || isParcoursRISDone ? (
          <SGButton
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
            onClick={handleOnClickLinkRIS}
          >
            {intl.formatMessage({
              id: `${
                isParcoursRISDone
                  ? "dashboard.pension.consulter"
                  : "dashboard.pension.analyse.releve"
              }`,
            })}
          </SGButton>
        ) : (
          <SGText>
            {intl.formatMessage({ id: "dashboard.pension.ris.indisponible" })}
          </SGText>
        )}
      </SGBox>

      {showHypothesisModal && (
        <HypothesisModal
          setVisible={setShowHypothesisModal}
          visible={showHypothesisModal}
          closeSecondGroup
        />
      )}
      <MaxRisErrorModal
        accessibiliteRisClicked={accessibiliteRisClicked}
        setAccessibiliteRisClicked={setAccessibiliteRisClicked}
        redirectToParcoursRIS={redirectToParcoursRIS}
      />
    </SGCard>
  );
};

export { Pension };
