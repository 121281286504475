import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFamilyAction } from "store/members/actions";
import { callRetirementSimulation, updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters, RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import { saveWorkIncomes } from "store/workIncomes/action";
import { WorkIncomesState } from "store/workIncomes/types";
import { saveWorkIncomesHistoryAction } from "store/workIncomesHistory/action";
import { WorkIncomeRaisesType, WorkIncomeRaisesTypeSimulation, WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { WorkIncomesForecast } from "../../WorkIncomesForecast/WorkIncomesForecast";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const SimulateurFlashWorkIncomesForecast: FunctionComponent = () => {
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const account = useAccount();
    const { trackClick, trackPage } = useTracking();

    const workIncomesHistoryData: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const workIncomesData: WorkIncomesState = useSelector<State, WorkIncomesState>((state) => state.workIncomes);
    const family = useFamily();

    const [pastForecast, setPastForecast] = useState<WorkIncomeRaisesType>();
    const [futureForecast, setFutureForecast] = useState<WorkIncomeRaisesType>();
    const [hasSubmitParcours, setHasSubmitParcours] = useState<boolean>(false);
    const [callTimer, setCallTimer] = useState<number>(0);
    const [errorHappened, setErrorHappened] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );

    useEffect(() => {
        if (workIncomesHistoryData && workIncomesHistoryData.workIncomesHistory) {
            setPastForecast(workIncomesHistoryData.workIncomesHistory.pastYearlyIncrease);
            setFutureForecast(workIncomesHistoryData.workIncomesHistory.expectedYearlyIncrease);
        }
        trackPage("simulateur-flash", "mon-evolution", PAGE_TYPE_FORMULAIRE, "simulation", "3");
    }, []);

    const onSubmit = () => {
        if (pastForecast && futureForecast) {
            // Suite au clic sur le bouton, on procède à l'enregistrement des données des différentes étapes du parcours
            setHasSubmitParcours(true);

            // Enregistrement des données de l'étape 1
            dispatch(updateFamilyAction(family, true, true));

            // Enregistrement des données de l'étape 2
            dispatch(saveWorkIncomes(workIncomesData.workIncomes || []));

            // Enregistrement des données de l'étape 3
            dispatch(
                updateRetirementSimulation({
                    ...simulationAssuState.retirementSimulationParameters,
                    evolSalaireFutur: WorkIncomeRaisesTypeSimulation[futureForecast],
                    evolSalairePasse: WorkIncomeRaisesTypeSimulation[pastForecast],
                    genre: "m",
                    idSimulSimple: "",
                    simulationErrorCode: undefined,
                    simulationErrorMessage: undefined,
                })
            );

            const workIncomesHistoryToSave: WorkIncomesHistory = {
                ...workIncomesHistoryData.workIncomesHistory,
                pastYearlyIncrease: pastForecast,
                expectedYearlyIncrease: futureForecast,
            };

            // FIXME Décalage de 2h retirant 1 jour dans le toString => ajout de 12h en paliatif temporaire
            if (workIncomesHistoryData.workIncomesHistory.carrierStartDate) {
                const startYear = workIncomesHistoryData.workIncomesHistory.carrierStartDate.getFullYear();
                const startMonth = workIncomesHistoryData.workIncomesHistory.carrierStartDate.getMonth();
                // Pas de -1 sur le mois car valeur issue du store
                workIncomesHistoryToSave.carrierStartDate = new Date(startYear, startMonth, 1, 12);
            }
            trackClick('mon-evolution::clic-sur-j-estime-ma-pension');
            dispatch(saveWorkIncomesHistoryAction(workIncomesHistoryToSave));
        }
    };

    useEffect(() => {
        if (
            hasSubmitParcours &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple === "" &&
            simulationAssuState.retirementSimulationParameters.simulationErrorCode === undefined
        ) {
            setCallTimer((new Date()).getTime());
            // Suite au clic sur le bouton et à l'enregistrement des données dans la méthode onSubmit, on fait appel au simulateur 45s
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            dispatch(callRetirementSimulation(simulationAssuState.retirementSimulationParameters as RetirementSimulationParameters));
        }
    }, [hasSubmitParcours, simulationAssuState.retirementSimulationParameters]);

    useEffect(() => {
        if (hasSubmitParcours && !simulationAssuState.retirementSimulationParameters.isParcoursDone) {
            if (simulationAssuState.retirementSimulationParameters.idSimulSimple && simulationAssuState.retirementSimulationParameters.idSimulSimple !== "") {
                const newTimer = (new Date()).getTime();
                saveEvent(trackingEvent.FLASH_SAPIENDO_OK, `${newTimer - callTimer} ms`);
                // après récupération des données de sapiendo, on met à jour le booléen isParcoursDone pour permettre le débranchement sur le dashboard
                dispatch(updateRetirementSimulation({ ...simulationAssuState.retirementSimulationParameters, isParcoursDone: true }));
            } else if (simulationAssuState.retirementSimulationParameters.simulationErrorCode !== undefined) {
                saveEvent(trackingEvent.FLASH_SAPIENDO_KO,
                    `[${simulationAssuState.retirementSimulationParameters.simulationErrorCode}] ${simulationAssuState.retirementSimulationParameters.simulationErrorMessage}`);
                // Cas d'erreur. Annulation
                setHasSubmitParcours(false);
                handleSetVisible(true);
            }
        }
    }, [simulationAssuState.retirementSimulationParameters]);

    const handleSetVisible = (visible: boolean) => {
        setErrorHappened(visible);
        setShowErrorModal(visible);
    };

    return (
        <WorkIncomesForecast
            onSubmit={onSubmit}
            pastForecast={pastForecast}
            setPastForecast={setPastForecast}
            futureForecast={futureForecast}
            setFutureForecast={setFutureForecast}
            hasSubmitStep={hasSubmitParcours}
            errorHappened={errorHappened}
            showErrorModal={showErrorModal}
            handleError={handleSetVisible}
        />
    );
};

export { SimulateurFlashWorkIncomesForecast };
