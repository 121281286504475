import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {TaxCredit, TaxCreditType} from "../../../../../store/tax/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {TaxCreditElement} from "../../../molecules/TaxCreditElement/TaxCreditElement";
import {hasChildrenUnder6,} from "../../../../../store/members/utils";
import {useFamily} from "../../../hooks/useFamily";
import {useTaxCredits} from "../../../hooks/useTaxCredits";
import {useIntl} from "react-intl";
import {EditorContext} from "../../../providers/EditorProvider";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {ButtonAddNewElement} from "../../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

interface TaxCreditsEditorProps {
   cypressName: string;
}

const TaxCreditsEditor: FunctionComponent<TaxCreditsEditorProps> = (props: TaxCreditsEditorProps) => {
   const {cypressName} = props;

   const intl = useIntl();
   const taxCredits = useTaxCredits();
   const family = useFamily();

   const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);

   const [isCreatingTaxCredit, setIsCreatingTaxCredit] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);
   const [defaultName, setDefaultName] = useState<string>("");

   useEffect(() => {
      setEditing(isCreatingTaxCredit);
   }, [isCreatingTaxCredit]);

   useEffect(() => {
      setIsCreatingTaxCredit(false);
   }, [taxCredits]);

   useEffect(() => {
      const amountOfTaxCreditsOfSameType = taxCredits.filter((tc) => tc.taxCreditType === TaxCreditType.EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE).length;
      setDefaultName(amountOfTaxCreditsOfSameType < 1 ? `${intl.formatMessage({id: `tax.credit.type.name.EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE`})}` :
         `${intl.formatMessage({id: `tax.credit.type.name.EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE`})} ${amountOfTaxCreditsOfSameType + 1}`)
   }, [taxCredits]);

   return (
      <SpinnerWrapper displayComponent={hasFetchedFamily && !!taxCredits} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         {isCreatingTaxCredit && hasFetchedFamily && !hasChildrenUnder6(family.children) && (
            <SGAlertIntl cypressName={`${cypressName}-no-child-under6-warning`} type="warning" title="common.warning">
               <SGTextIntl intlId="tax.credit.page.info.no.children"/>
            </SGAlertIntl>
         )}
         {isCreatingTaxCredit && hasFetchedFamily && !!taxCredits && (
            <TaxCreditElement taxCredit={{
               name: defaultName,
               beneficiary: family.me,
               taxCreditType: TaxCreditType.EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE
            }} isCreating cypressName={`${cypressName}-new-tax-credit`} onCancel={() => setIsCreatingTaxCredit(false)}/>
         )}
         {!!taxCredits && hasFetchedFamily &&
            taxCredits.map((taxCredit: TaxCredit) => (
               <TaxCreditElement key={taxCredit.id} taxCredit={taxCredit} cypressName={`${cypressName}-element-${taxCredit.name}`}/>
            ))}
         <ButtonAddNewElement cypressName="tax-credit-add" text="tax.credit.editor.add.new" disabled={isEditing} onClick={() => setIsCreatingTaxCredit(true)}/>
      </SpinnerWrapper>
   );
};

export {TaxCreditsEditor};
