import { FunctionComponent, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGAvenirStrokedHabitation } from "sg-icon-pack-base";
import { SGPrice, SGText } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import {
  computeAdditionalIncomes,
  getMontantTotalAlloue,
} from "store/assets/utils";
import { DashboardAge, DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import {
  PARAM_RESULTAT,
  QUERY_PARAM_ETAPE,
} from "website/pages/ParcoursRISPage";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { PARCOURS_REVENUS_COMPLEMENTAIRES } from "website/utils/privateRoutes";
import { CadreInfoSup } from "../CadreInfoSup";
import { CarteDonnee, NomCarteEnum, TypeContenuCarteEnum } from "./CarteDonnee";

interface PatrimoineProps {
  selectedRetirementAge?: DashboardAge;
}

const Patrimoine: FunctionComponent<PatrimoineProps> = (
  props: PatrimoineProps
) => {
  const { selectedRetirementAge } = props;
  const intl = useIntl();
  const { trackClick } = useTracking();
  const history = useHistory();
  const dispatch = useDispatch();
  const { disponibiliteParcoursRevenusComplementaires } = useFeatures();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );

  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const isParcoursPatrimoineDone = useMemo(
    () => dashboardState?.dashboard?.firstPatrimonyPathDate !== undefined,
    [dashboardState]
  );

  const isAssetsChecked = useMemo(
    () =>
      assets?.filter((asset) => asset.percentageUsedForRetirement > 0)
        .length !== 0,
    [assets]
  );

  useEffect(() => {
    if (!assetState.hasFetched) {
      dispatch(getAssetsWithoutLoans());
    }
    if (!hasFetchedAssets(assetIncomesState)) {
      dispatch(getAssetsIncomes());
    }
  }, []);

  const montantRevenusComplementaires = useMemo(() => {
    if (
      isParcoursPatrimoineDone &&
      selectedRetirementAge &&
      assets &&
      assetIncomes
    ) {
      return roundDown10(
        computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
      );
    }

    return undefined;
  }, [isParcoursPatrimoineDone, selectedRetirementAge, assets, assetIncomes]);

  const montantTotalAlloue = useMemo(() => {
    if (assets) {
      return roundDown10(getMontantTotalAlloue(assets));
    }

    return undefined;
  }, [assets]);

  return (
    <>
      {isParcoursPatrimoineDone && (isAssetsChecked || montantRevenusComplementaires === undefined ||
            montantTotalAlloue === undefined) ? (
        <CarteDonnee
          nom={NomCarteEnum.PATRIMOINE}
          tagColor="38"
          isActif
          typeContenu={TypeContenuCarteEnum.MONTANT}
          montant={montantRevenusComplementaires}
          disponibiliteFeatureFlipping={
            disponibiliteParcoursRevenusComplementaires
          }
          onClick={() => {
            trackClick("clic-sur-consulter-mon-patrimoine");
            history.push(
              `${PARCOURS_REVENUS_COMPLEMENTAIRES}?${QUERY_PARAM_ETAPE}=${PARAM_RESULTAT}`
            );
          }}
          showSkeleton={
            montantRevenusComplementaires === undefined ||
            montantTotalAlloue === undefined
          }
        >
          <CadreInfoSup
            icone={<SGAvenirStrokedHabitation />}
          >
            <SGText key="titre-patrimoine" weight="600">
              {intl.formatMessage({
                id: "dashboard.cadreGris.carteDonnee.patrimoine.titre",
              })}
            </SGText>
            <SGPrice
              key="price"
              size="m"
              value={montantTotalAlloue?.toLocaleString("fr-FR") || "--"}
            />
          </CadreInfoSup>
        </CarteDonnee>
      ) : (
        <CarteDonnee
          nom={NomCarteEnum.PATRIMOINE}
          tagColor="38"
          typeContenu={TypeContenuCarteEnum.DESCRIPTION}
          disponibiliteFeatureFlipping={
            disponibiliteParcoursRevenusComplementaires
          }
          onClick={() => {
            trackClick("clic-sur-estimer-mon-patrimoine");
            history.push(PARCOURS_REVENUS_COMPLEMENTAIRES);
          }}
        />
      )}
    </>
  );
};

export { Patrimoine };
