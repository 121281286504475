import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {buildDefaultRealizedDonation, RealizedDonation} from "../../../../../store/realizedDonations/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {RealizedDonationElement} from "../../../molecules/RealizedDonationElement/RealizedDonationElement";
import {ButtonAddNewElement} from "../../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import {SGSpace} from "sg-space";
import {useRealizedDonations} from "../../../hooks/useRealizedDonations";
import {EditorContext} from "../../../providers/EditorProvider";
import {useIntl} from "react-intl";
import {AssetType} from "../../../../../store/assets/types";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

interface RealizedDonationsEditorProps {
   cypressName: string;
}

const RealizedDonationsEditor: FunctionComponent<RealizedDonationsEditorProps> = (props: RealizedDonationsEditorProps) => {
   const {cypressName} = props;

   const {isEditing, setEditing} = useContext(EditorContext);
   const realizedDonations = useRealizedDonations();
   const hasFetchedRealizedDonations = useSelector<State, boolean>((state) => state.realizedDonations.hasFetched);
   const intl = useIntl();

   const [isCreatingDonation, setCreatingDonation] = useState(false);

   useEffect(() => {
      setEditing(isCreatingDonation);
   }, [isCreatingDonation]);

   useEffect(() => {
      setCreatingDonation(false);
   }, [realizedDonations]);

   const hasDonations = () => hasFetchedRealizedDonations && realizedDonations.length > 0;

   const [defaultName, setDefaultName] = useState<string>("");

   useEffect(() => {
      if (hasFetchedRealizedDonations) {
         const amountOfDonationOfSameType = realizedDonations.filter((rd) => rd.assetType === AssetType.CASH_ACCOUNT).length;
         setDefaultName(amountOfDonationOfSameType < 1 ? `${intl.formatMessage({id: `inheritance.realized-donation.label.CASH_ACCOUNT`})}` :
            `${intl.formatMessage({id: `inheritance.realized-donation.label.CASH_ACCOUNT`})} ${amountOfDonationOfSameType + 1}`)
      }
   }, [hasFetchedRealizedDonations, realizedDonations]);

   return (
      <SGSpace direction="vertical">
         {isCreatingDonation && (
            <RealizedDonationElement realizedDonation={buildDefaultRealizedDonation(defaultName)} isCreating onCancel={() => setCreatingDonation(false)}
                                     cypressName={`${cypressName}-new-realize-donation`}/>
         )}
         <SpinnerWrapper displayComponent={hasFetchedRealizedDonations} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
            {hasDonations() && realizedDonations.map((realizedDonation: RealizedDonation) => (
                  <RealizedDonationElement key={realizedDonation.id} realizedDonation={realizedDonation}
                                           cypressName={`${cypressName}-${realizedDonation.projectName}`}/>
               )
            )}
         </SpinnerWrapper>
         <ButtonAddNewElement text="inheritance.realized-donation.page.add-new" cypressName="button-add-new-realized-donation"
                              onClick={() => setCreatingDonation(true)}
                              disabled={isEditing}/>
      </SGSpace>
   );
};

export {RealizedDonationsEditor};
