import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "sg-media-query";
import { getOptionsMedia } from "./GraphOptions";

interface GraphRevenuTotalRetraiteProps {
  montantPension?: number;
  montantRevenusComplementaires?: number;
  montantRevenusAFinancer?: number;
  montantTotal?: number;
  assetsLength?: number;
}

const GraphRevenuTotalRetraite: FunctionComponent<
  GraphRevenuTotalRetraiteProps
> = (props: GraphRevenuTotalRetraiteProps) => {
  const {
    montantPension,
    montantRevenusComplementaires,
    montantTotal,
    assetsLength,
    montantRevenusAFinancer,
  } = props;

  const isPhone = useMediaQuery({ minwidth: 0, maxwidth: "sm" });

  const intl = useIntl();
  const [data, setData] = useState<
    { name: string; y: number | undefined; color: string }[]
  >([]);
  const [options, setOptions] = useState(() => getInitOptions());

  useEffect(() => {
    setOptions((opt) =>
      isPhone
        ? getOptionsMedia(
            opt,
            180,
            0,
            -10,
            "22px",
            35,
            "12px",
            "82%"
          )
        : getOptionsMedia(opt, 360, 12, 10, "47px", 65, "18px", "87%")
    );
  }, [isPhone]);

  useEffect(() => {
    if (montantTotal) {
      const newData: { name: string; y: number | undefined; color: string }[] =
        [];
      if (montantPension) {
        newData.push({
          name: "Revenu Total Retraite",
          color: "#9F2D62",
          y: montantPension / montantTotal,
        });
      }
      if (montantRevenusComplementaires) {
        newData.push({
          name: "Compléments revenus 1",
          color: "#208382",
          y: montantRevenusComplementaires / montantTotal,
        });
      }
      if (montantRevenusAFinancer) {
        newData.push({
          name: "Revenus à financer",
          color: "#090F70",
          y: montantRevenusAFinancer / montantTotal,
        });
      }
      setData(newData);
      setOptions((opt) => ({
        ...opt,
        title: isPhone
          ? { ...opt.titleDashboardMobile, text: getGraphTitle() }
          : { ...opt.title, text: getGraphTitle() },
        subtitle: {
          ...opt.subtitle,
          text: !isPhone ? getGraphSubtitle() : "",
        },
        series: [{ ...opt.series[0], data: newData }],
      }));
    }
  }, [
    isPhone,
    montantTotal,
    montantPension,
    montantRevenusComplementaires,
    montantRevenusAFinancer,
  ]);

  function getStringValue(value: number | undefined) {
    if (value !== undefined && value >= 0) {
      return value?.toLocaleString("fr-FR");
    }

    return "--";
  }

  function getGraphTitle() {
    const fontSize = isPhone ? "16px" : "18px";

    return isPhone
      ? `<label style='font-size: 24px;font-family:Montserrat;font-weight: 700'>${getStringValue(
          montantTotal
        )} €</label><br><label style='font-size: 12px;font-family:Montserrat'>${intl.formatMessage(
          { id: `dashboard.graph.parmois` }
        )}</label>`
      : `<label>${getStringValue(
          montantTotal
        )} €</label><br><label style='font-size: ${fontSize};font-family:Source Sans Pro Bold;color:#545454'>${intl.formatMessage(
          {
            id: `dashboard.graph.parmois`,
          }
        )}</label>`;
  }
  function getGraphSubtitle() {
    return `<label style='color:#9F2D62'>${getStringValue(
      montantPension
    )} € (pension)</label><br><label style='color:#208382'>${
      assetsLength !== 0 ? getStringValue(montantRevenusComplementaires) : "--"
    } € (patrimoine)</label><br><label style='color:#090F70'>${getStringValue(
      montantRevenusAFinancer
    )} € (épargne future)</label>`;
  }

  function getInitOptions() {
    return {
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        backgroundColor: "transparent",
        spacingRight: 0,
        spacingLeft: 0,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          useHTML: true,
          dataLabels: {
            enabled: false,
          },
          startAngle: 180,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
        },
      },
      title: {
        text: getGraphTitle(),
        align: "center",
        verticalAlign: "middle",
        style: {
          fontWeight: "bold",
          color: "#000000",
          fontFamily: "'Montserrat'",
        },
      },
      titleDashboardMobile: {
        text: getGraphTitle(),
        align: "center",
        verticalAlign: "middle",
        y: 25,
        style: {
          fontWeight: "bold",
          fontSize: "22px",
          color: "#000000",
          fontFamily: "'Montserrat'",
        },
      },
      subtitle: {
        text: getGraphSubtitle(),
        align: "center",
        verticalAlign: "middle",
        style: {
          fontWeight: 600,
          color: "#545454",
          fontFamily: "Source Sans Pro",
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: "Revenus",
          colorByPoint: true,
          data,
        },
      ],
    };
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export { GraphRevenuTotalRetraite };
