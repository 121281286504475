import React, {FunctionComponent} from "react";
import {ChildSupportExpenseEditor} from "../components/molecules/ChildSupportExpenseEditor/ChildSupportExpenseEditor";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {SGContent} from "sg-layout";

const ChildSupportExpensePage: FunctionComponent = () => (
   <SGContent span={8}>
      <SGTitleIntl intlId="tax.child.support.expense.title" cypressName="child-support-expense-editor-title"/>
      <SGTextIntl intlId="tax.child.support.expense.subtitle"/>
      <ChildSupportExpenseEditor/>
   </SGContent>
);

export {ChildSupportExpensePage};
