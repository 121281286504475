import React, {FunctionComponent, ReactNode, useEffect, useState} from "react";
import {SGButton} from "sg-button";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {useIntl} from "react-intl";
import {useLocation} from "react-router-dom";
import {SGSpace} from "sg-space";
import {SGText} from "sg-typo";
import {replaceSpecialCharsAndLowercase} from "../../../../utils/formatting/replaceSpecialCharsAndLowercase";
import {NEW_PROJECTS, PROJECTS} from "../../../../utils/privateRoutes";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface HeaderNavProps {
   text?: string;
   icon?: ReactNode;
   url?: string;
   onClick?: () => void;
   buttonSelectedType: string;
   buttonNotSelectedType: string;
   cypressName?: string
}

const HeaderNav: FunctionComponent<HeaderNavProps> = ({text, icon, url, onClick, buttonSelectedType, buttonNotSelectedType, cypressName}: HeaderNavProps) => {
   const intl = useIntl();
   const location = useLocation();
   const { trackClick } = useTracking();

   const [isCurrentPage, setIsCurrentPage] = useState(false);

   const handleClickHeaderNav = () => {
      trackClick(`clic-sur-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: text}))}`);
      if (onClick) {
         onClick();
      }
      if (url) {
         redirectToRelativePath(url);
      }
   }

   useEffect(() => {
      if (url && url === PROJECTS && location.pathname.includes(NEW_PROJECTS)) {
         setIsCurrentPage(true);
      } else {
         setIsCurrentPage(!!url && location.pathname.includes(url));
      }
   }, [location]);

   return (
      <SGButton type={isCurrentPage ? buttonSelectedType : buttonNotSelectedType} onClick={handleClickHeaderNav} data-cy={cypressName}>
         <SGSpace direction="vertical" disableautomargin textalign="center" size="xl">
            {icon && icon}
            {text && <SGText size="l">{intl.formatMessage({id: text})}</SGText>}
         </SGSpace>
      </SGButton>
   );
};

export {HeaderNav};
