import * as HighCharts from "highcharts";
import {LIFE_PROJECTS, Project} from "../../../store/projects/types";

export function formatLabelAmount(self: any, displayZero = false): string {
   const label = self.axis.defaultLabelFormatter.call(self);
   if (label !== 0 || displayZero) {
      return `${label}€`;
   }

   return "";
}

export function formatAge(self: any, formatMessage: Function) {
   const label = self.axis.defaultLabelFormatter.call(self);

   return label + formatMessage({id: "graphs.years"});
}

export function generateTooltipSimulation(chartPoint: any): string {
   // point is of type Highcharts Point
   if (!chartPoint || chartPoint.point.y === 0) {
      return "";
   }

   return `<span style="color:${chartPoint.point.color}">\u25CF</span>   ${chartPoint.series.name}: <b>${Math.abs(chartPoint.point.y).toString().toString()} € </b><br/>`;
}

export function generateTooltipCapacity(chartPoint: any): string {
   // point is of type Highcharts Point
   if (!chartPoint || chartPoint.point.y === 0) {
      return "";
   }

   return `<span style="color:${chartPoint.point.color}">\u25CF</span>   ${chartPoint.series.name}: <b>${Math.abs(chartPoint.point.y).toString().toString()} € </b><br/>`;
}

export function generateTooltipIncomesExpenses(chartPoint: any, formatMessage: Function): string {
   // point is of type Highcharts Point
   if (!chartPoint || chartPoint.point.y === 0) {
      return "";
   }
   const annualNumber = chartPoint.point.y;

   return `<span style="color:${chartPoint.point.color}">\u25CF</span>   ${formatMessage({
      id: `graphs.income-expense.${chartPoint.series.name}`
   })}: <b><span style="font-size:12px">${HighCharts.numberFormat(Math.abs(annualNumber), 0)} € </span></b>&nbsp;(<b><span style="font-size:12px">${HighCharts.numberFormat(Math.ceil(Math.abs(annualNumber / 12)), 0)} €/mois</span></b>)<br/>`;
}

export function generateTooltipTitle(year: number): string {
   return `<span style="font-size: 18px">en ${year}</span><br/>`;
}

export function generateTooltipTitleWithAmount(year: number, amount: number): string {
   const formattedAmount: string = HighCharts.numberFormat(amount, 0);

   return `<span style="font-size: 18px">en ${year} - Total : ${formattedAmount} € </span><br/>`;
}

export function getGraphFormattedProjectNames(projects: Array<Project>, year: number): string {
   const filtered = projects.filter((project) => LIFE_PROJECTS.includes(project.projectType) && computeMemberAgeAtProjectStartDate(project) === year);
   let result = "";
   if (filtered.length !== 0) {
      result += `<span style="text-decoration: underline"><b>Liste des projets</b></span><br/>`;
      filtered.forEach((project) => {
         (result += `<span>${project.projectName}</span><br/>`);
      });
      result += "<br/>";
   }

   return result;
}

export function computeMemberAgeAtProjectStartDate(project: Project): number {
   return project.memberAgeAtProjectStartDate ? project.memberAgeAtProjectStartDate : new Date(project.startDate).getFullYear();
}

export function generateTooltipTitleForNetWorth(year: number): string {
   return `<span style="font-size: 18px">Projection à ${year} ans</span><br/>`;
}
