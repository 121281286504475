import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Expense, ExpenseState} from "../../../store/expenses/types";
import {getExpensesAction} from "../../../store/expenses/actions";
import {ProjectType} from "../../../store/projects/types";

const useExpense = (expenseType: ProjectType) => {
   const dispatch = useDispatch();
   const [expense, setExpense] = useState<Expense>();
   const expenseState: ExpenseState = useSelector<State, ExpenseState>((state) => state.expenses);

   useEffect(() => {
      if (!expenseState.hasFetchedExpenses) {
         dispatch(getExpensesAction());
      }
   }, []);

   useEffect(() => {
      if (expenseState.hasFetchedExpenses) {
         setExpense(expenseState.expenses.filter((expense) => !expenseType || expense.type === expenseType).shift());
      }
   }, [expenseState]);

   return expense;
};

export {useExpense};
