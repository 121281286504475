import React, {FunctionComponent} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledChargesAffaires} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";
import parse from "html-react-parser";

interface CoachAdviceProps {
   text: string;
}

const CoachAdvice: FunctionComponent<CoachAdviceProps> = (props: CoachAdviceProps) => {
   const {text} = props;

   return (
      <SGSpace direction="vertical" align="center">
         <SGIcon component={<SGAvenirStyledChargesAffaires/>} size="xl"/>
         <SGTextIntl strong intlId="recommendation.text.coach-advice.title"/>
         {parse(text)}
      </SGSpace>
   );
};
export { CoachAdvice };
