import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {IncomeState} from "../../../store/incomes/types";
import {getIncomesAction} from "../../../store/incomes/actions";

const useIncomes = () => {
   const dispatch = useDispatch();
   const incomeState: IncomeState = useSelector<State, IncomeState>((state) => state.incomes);

   useEffect(() => {
      if (!incomeState.hasFetched) {
         dispatch(getIncomesAction());
      }
   }, []);

   return incomeState.incomes;
};

export {useIncomes};
