import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AmortizationType, Loan, LoanType} from "../../../../../store/loans/types";
import {PossessionType} from "../../../../../store/assets/types";
import {LoanElement} from "../../../molecules/LoanElement/LoanElement";
import {LoanEdit} from "../../../molecules/LoanEdit/LoanEdit";
import {createLoanAction} from "../../../../../store/loans/actions";
import {useLoans} from "../../../hooks/useLoans";
import {useFamily} from "../../../hooks/useFamily";
import {EditorContext} from "../../../providers/EditorProvider";
import {defaultAssetMemberLinkSet} from "../../../../../store/assets/utils";
import {useIntl} from "react-intl";
import {ButtonAddNewElement} from "../../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import {SGSpace} from "sg-space";
import {SGCard} from "sg-card";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {State} from "../../../../../store/store";

interface LoansEditorProps {
   cypressName: string;
}

const LoansEditor: FunctionComponent<LoansEditorProps> = (props: LoansEditorProps) => {
   const dispatch = useDispatch();
   const loans = useLoans([]);
   const family = useFamily();
   const displayComponent = useSelector<State, boolean>((state) => state.loans.hasFetched && state.family.hasFetched);
   const intl = useIntl();

   const {cypressName} = props;
   const {isEditing, setEditing} = useContext(EditorContext);
   const [isCreatingLoan, setIsCreatingLoan] = useState(false);
   const [loan, setLoan] = useState<Partial<Loan>>({
      name: intl.formatMessage({id: "tunnel.situation.home.my-real-estate-loan"})
   });

   const methodsLoan = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   useEffect(() => {
      setEditing(isCreatingLoan);
   }, [isCreatingLoan]);

   useEffect(() => {
      setIsCreatingLoan(false);
      const defaultName = loans.length < 1 ? intl.formatMessage({id: "tunnel.situation.home.my-real-estate-loan"})
         : `${intl.formatMessage({id: "tunnel.situation.home.my-real-estate-loan"})} ${loans.length + 1}`;

      setLoan({
         name: defaultName,
         amortizationType: AmortizationType.AMORTIZATION,
         assetMemberLinkSet: defaultAssetMemberLinkSet(family.me),
         loanType: LoanType.REAL_ESTATE,
      });
   }, [loans]);

   const handleCreateLoan = (data: Loan) => {
      let currentLoan: Partial<Loan> = {
         assetMemberLinkSet: [
            {
               familyMember: family.me,
               percentage: 100,
               possessionType: PossessionType.PLEINE_PROPRIETE,
            },
         ],
      };
      currentLoan = {...currentLoan, ...data};
      dispatch(createLoanAction(currentLoan));
   };

   return (
      <SpinnerWrapper displayComponent={displayComponent} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace direction="vertical">
            {loans.map((currentLoan: Loan) => (
               <LoanElement key={currentLoan.id} loan={currentLoan} cypressName={`${cypressName}-${currentLoan.name}`}/>
            ))}
            {isCreatingLoan && (
               <FormProvider {...methodsLoan}>
                  <form>
                     <SGCard disableautomargin>
                        <LoanEdit editedLoan={loan} onCancel={() => setIsCreatingLoan(false)} onSubmit={handleCreateLoan}
                                  cypressName={`${cypressName}-new-edit`}/>
                     </SGCard>
                  </form>
               </FormProvider>
            )}
            <ButtonAddNewElement cypressName="loans-add" text="form.loan.new" disabled={isEditing} onClick={() => setIsCreatingLoan(true)}/>
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {LoansEditor};
