export interface SavingCapacityState {
   savingCapacity: SavingCapacityData;
   hasFetched: boolean;
   cannotFetchSavingCapacity: boolean;
}

export interface SavingCapacityData {
   clientId: number;
   numberOfProjects: number;

   monthlyIncome: number;
   monthlyExpenses: number;

   monthlySavingCapacity: number;
   availableMonthlySavingCapacity: number;

   initialAmountSavingCapacity: number;
   availableInitialAmountSavingCapacity: number;

   borrowingCapacity: number;
   availableBorrowingCapacity: number;
}

export const FETCH_SAVING_CAPACITY = "FETCH_SAVING_CAPACITY";
export const SAVING_CAPACITY_FETCHED = "SAVING_CAPACITY_FETCHED";
export const RESET_SAVING_CAPACITY = "RESET_SAVING_CAPACITY";
export const FETCH_SAVING_CAPACITY_ERROR = "FETCH_SAVING_CAPACITY_ERROR";
