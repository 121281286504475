import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SavingCapacityData, SavingCapacityState} from "./types";

const initialState: SavingCapacityState = {
   savingCapacity: {
      clientId: 0,
      numberOfProjects: 0,
      monthlyIncome: 0,
      monthlyExpenses: 0,
      monthlySavingCapacity: 0,
      availableMonthlySavingCapacity: 0,
      initialAmountSavingCapacity: 0,
      availableInitialAmountSavingCapacity: 0,
      borrowingCapacity: 0,
      availableBorrowingCapacity: 0
   },
   hasFetched: false,
   cannotFetchSavingCapacity: false
};

export const savingCapacitySlice = createSlice({
   name: "savingCapacity",
   initialState,
   reducers: {
      savingCapacityFetched: (currentState, action: PayloadAction<SavingCapacityData>) => ({
         savingCapacity: action.payload,
         cannotFetchSavingCapacity: false,
         hasFetched: true
      }),
      fetchSavingCapacityError: (currentState) => ({
         ...currentState,
         cannotFetchSavingCapacity: true
      }),
      resetSavingCapacity: () => initialState
   }
});

export const {savingCapacityFetched, fetchSavingCapacityError, resetSavingCapacity} = savingCapacitySlice.actions;

export default savingCapacitySlice.reducer;
