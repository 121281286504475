import React, {FunctionComponent} from "react";
import {GivenDonationProject} from "../../components/organisms/Projects/GivenDonationProject/GivenDonationProject";
import {SGContent} from "sg-layout";

const GivenDonationProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <GivenDonationProject/>
   </SGContent>
);

export {GivenDonationProjectPage};
