import React, {PropsWithChildren, useState} from "react";

export type TutorialContextType = {
   isTutorialDisplayed: boolean;
   setTutorialDisplayed: (isTutorialDisplayed: boolean) => void;
};

export const TutorialContext = React.createContext<TutorialContextType>({
   isTutorialDisplayed: false,
   setTutorialDisplayed: () => null
});

export const TutorialContextProvider = ({children}: PropsWithChildren<Record<never, never>>) => {
   const [isTutorialDisplayed, setTutorialDisplayed] = useState(false);

   return (
      <TutorialContext.Provider value={{isTutorialDisplayed, setTutorialDisplayed}}>
         {children}
      </TutorialContext.Provider>
   );
}
