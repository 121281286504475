import React, {FunctionComponent} from "react";
import {ProjectFeasibility, ProjectPlanAlert, ProjectPlanStatus, ProjectType,} from "../../../../store/projects/types";
import {AssetType} from "../../../../store/assets/types";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {ProjectAction} from "../ProjectAction/ProjectAction";
import {SGSpace} from "sg-space";
import {CustomNumberFormat} from "../../../utils/formatting/CustomNumberFormat";
import {SGVisualHighlight} from "sg-visual-highlight";
import {SGCard} from "sg-card";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import savingsImage from "../../../../assets/images/recommendations/1201269168_super.jpg";
import perImage from "../../../../assets/images/recommendations/enjoy_your_retirement_low.jpeg";
import lifeInsuranceImage from "../../../../assets/images/recommendations/1211622412_medium.jpg"
import {SGText} from "sg-typo";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";

interface ProjectActionPlanProps {
   projectFeasibility: ProjectFeasibility
}

const ProjectActionPlan: FunctionComponent<ProjectActionPlanProps> = (props: ProjectActionPlanProps) => {
   const {projectFeasibility} = props
   const hasFetchedProjectFeasibility = useSelector<State, boolean>((state) => state.projects.hasFetchedFeasibilities);

   const displaySavingAdvice = (): boolean => (isLifeInsuranceRecommendation() || isSavingsAccountRecommendation() || isRetirementPensionRecommendation()) && hasSavingCapacity();

   const displayFuturePlan = (): boolean => hasFuturePlan() && projectFeasibility.plan.status !== ProjectPlanStatus.OPTIMIZE;

   const hasSavingCapacity = (): boolean => projectFeasibility && (projectFeasibility.maxMonthlyDepositAmount > 0 || projectFeasibility.maxInitialDepositAmount > 0);

   const hasFuturePlan = (): boolean =>
      (projectFeasibility && projectFeasibility.projectLoanAllowed && projectFeasibility.plan.loanAmount > 0 && projectFeasibility.projectHorizon - new Date().getFullYear() > 0)
      || isAdditionalSavingRequired()
      || projectFeasibility.plan.soldAssetAmount > 0;

   const isAdditionalSavingRequired = (): boolean => projectFeasibility?.plan?.alert && [ProjectPlanAlert.BANKRUPTCY_REC, ProjectPlanAlert.USES_CASH, ProjectPlanAlert.USES_SAVING, ProjectPlanAlert.USES_INVESTMENT].includes(projectFeasibility.plan.alert)

   const isLifeInsuranceRecommendation = (): boolean =>
      projectFeasibility?.plan &&
      projectFeasibility.plan.asset.type === AssetType.LIFE_INSURANCE;

   const isSavingsAccountRecommendation = (): boolean => {
      const savingsAssets = [AssetType.LIVRET_A, AssetType.LDDS, AssetType.CSL];

      return (
         projectFeasibility?.plan &&
         savingsAssets.filter((assetType) => assetType === projectFeasibility.plan.asset.type).length > 0
      );
   };

   const isRetirementPensionRecommendation = (): boolean =>
      projectFeasibility?.plan &&
      projectFeasibility.plan.asset.type === AssetType.PER;

   const hasRecommendation = (recommendation: string): boolean => projectFeasibility.plan.recommendations.filter((r) => r === recommendation).length > 0;

   const getAdditionalSavingRequire = (): number => {
      if (!projectFeasibility) {
         return 0;
      }

      if (projectFeasibility.projectType === ProjectType.RETIREMENT_PROJECT) {
         return (projectFeasibility.plan.investmentAmount + projectFeasibility.plan.savingsAmount + projectFeasibility.plan.cashAmount);
      }

      return Math.max(0, projectFeasibility.projectTotalObjective - (projectFeasibility.plan.soldAssetAmount + projectFeasibility.plan.loanAmount + projectFeasibility.plan.asset.netAmount));
   };

   const getDiscoverMoreLink = (discoverType: string): string => {
      switch (discoverType) {
         case "LIFE_INSURANCE":
            return `${window.env.REACT_APP_LIFE_INSURANCE_URL}`;
         case "LIVRET_A":
         case "LDDS":
         case "CSL":
            return `${window.env.REACT_APP_SAVING_ACCOUNT_URL}`;
         case "REAL_ESTATE_SIMULATOR":
            return `${window.env.REACT_APP_REAL_ESTATE_SIMULATOR_URL}`;
         case "ADVICE_LOAN":
            return `${window.env.REACT_APP_LOAN_URL}`;
         case "CREDIT_ETUDE":
            return `${window.env.REACT_APP_CREDIT_ETUDE_URL}`;
         case "FCPI":
            return `${window.env.REACT_APP_TAXATION_URL}`;
         case "REAL_ESTATE_RENT":
            return `${window.env.REACT_APP_REAL_ESTATE_RENT_URL}`;
         case "PER":
            return `${window.env.REACT_APP_PER_URL}`;
         case "EXPRESSO":
            return `${window.env.REACT_APP_WORK_CREDITS}`;
         default:
            return "";
      }
   };

   const getVisualHighlightImage = (discoverType: string): string => {
      switch (discoverType) {
         case "LIFE_INSURANCE":
            return lifeInsuranceImage;
         case "PER":
            return perImage
         case "LIVRET_A":
         case "LDDS":
         case "CSL":
         default:
            return savingsImage;
      }
   };

   return (
      <SpinnerWrapper displayComponent={hasFetchedProjectFeasibility} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         {hasFetchedProjectFeasibility && (
            <SGSpace direction="vertical" textalign="left">
               <SGTitleIntl intlId="project.action.plan.title" cypressName="project-action-plan-title"/>
               {/* Dès Maintenant */}
               {displaySavingAdvice() && (
                  <>
                     <SGTextIntl intlId="project.action.plan.now" strong/>
                     {projectFeasibility.plan.asset.netAmount > 0 && (
                        <SGVisualHighlight reversecontent
                                           content={(
                                              <SGCard bordered textalign="center">
                                                 <SGSpace direction="vertical">
                                                    <SGTitleIntl intlId={`project.action.plan.${projectFeasibility.plan.asset.type}.plan.title`}
                                                                 cypressName={`${projectFeasibility.plan.asset.type}-frame-title`}/>
                                                    <SGTextIntl intlId={`project.action.plan.${projectFeasibility.plan.asset.type}.plan`} size="m"/>
                                                    <SGTextIntl intlId={projectFeasibility.plan.asset.initialDepositAmount <= 0 ? `project.action.plan.${projectFeasibility.plan.asset.type}.plan.subtitle` : `project.action.plan.${projectFeasibility.plan.asset.type}.plan.subtitle.initial`}
                                                                transformations={{
                                                                   amount:
                                                                      <SGText strong>
                                                                         <CustomNumberFormat displayType="text" thousandSeparator={" "}
                                                                                             value={projectFeasibility.plan.asset.monthlyDepositAmount}/>
                                                                      </SGText>,
                                                                   totalAmount:
                                                                      <SGText strong>
                                                                         <CustomNumberFormat displayType="text" thousandSeparator={" "}
                                                                                             value={projectFeasibility.plan.asset.netAmount}/>
                                                                      </SGText>,
                                                                   initialDeposit:
                                                                      <SGText strong>
                                                                         <CustomNumberFormat displayType="text" thousandSeparator={" "}
                                                                                             value={projectFeasibility.plan.asset.initialDepositAmount}/>
                                                                      </SGText>
                                                                }}/>
                                                    <SGButtonIntl type="primary"
                                                                  onClick={() => window.open(getDiscoverMoreLink(projectFeasibility.plan.asset.type))}
                                                                  intlId="project.action.plan.discover.more" cypressName="project-action-discover-more"/>
                                                 </SGSpace>
                                              </SGCard>
                                           )}
                                           image={(<img alt="" src={getVisualHighlightImage(projectFeasibility.plan.asset.type)}/>)}/>
                     )}
                     {projectFeasibility.plan.asset.netAmount === 0 && (
                        <ProjectAction plan="project.action.plan.nothing"/>
                     )}
                     {hasRecommendation("realEstate") &&
                        !hasRecommendation("realEstateDispoDefisc") && (
                           <ProjectAction plan="project.action.plan.real.estate.plan"
                                          subtitle="project.action.plan.real.estate.plan.subtitle"/>
                        )}
                     {hasRecommendation("realEstateDispoDefisc") && (
                        <ProjectAction plan="project.action.plan.real.estate.tax.exemption.plan"
                                       subtitle="project.action.plan.real.estate.tax.exemption.plan.subtitle"
                                       discoverMoreLink={getDiscoverMoreLink("REAL_ESTATE_RENT")}/>
                     )}
                     {hasRecommendation("createFcpi") && (
                        <ProjectAction plan="project.action.plan.createFcpi.plan"
                                       subtitle="project.action.plan.createFcpi.plan.subtitle"
                                       discoverMoreLink={getDiscoverMoreLink("FCPI")}/>
                     )}
                     {hasRecommendation("createPer") && (
                        <ProjectAction plan="project.action.plan.createPer.plan"
                                       subtitle="project.action.plan.createPer.plan.subtitle"
                                       discoverMoreLink={getDiscoverMoreLink("PER")}/>
                     )}
                     {hasRecommendation("saveOnPer") && (
                        <ProjectAction plan="project.action.plan.saveOnPer.plan"
                                       subtitle="project.action.plan.saveOnPer.plan.subtitle"/>
                     )}
                     {hasRecommendation("expressoLoan") && (
                        <ProjectAction plan="project.action.plan.construction.work.plan"
                                       subtitle="project.action.plan.construction.work.plan.subtitle"
                                       discoverMoreLink={getDiscoverMoreLink("EXPRESSO")}/>
                     )}
                     {hasRecommendation("studyLoan") && (
                        <ProjectAction plan="project.action.plan.credit.etude.plan"
                                       subtitle="project.action.plan.credit.etude.plan.subtitle"
                                       discoverMoreLink={getDiscoverMoreLink("CREDIT_ETUDE")}/>
                     )}
                  </>
               )}
               {displayFuturePlan() && (
                  <>
                     <SGTextIntl intlId="project.action.plan.later" strong
                                 transformations={{
                                    year:
                                       String(projectFeasibility.projectHorizon -
                                          new Date().getFullYear()),
                                 }}
                     />
                     {projectFeasibility.plan.soldAssetAmount > 0 && (
                        <ProjectAction
                           plan="project.action.plan.soldAsset.plan"
                           subtitle="project.action.plan.soldAsset.plan.subtitle"
                           amount={projectFeasibility.plan.soldAssetAmount}
                        />
                     )}
                     {projectFeasibility.projectLoanAllowed && projectFeasibility.plan.loanAmount > 0 && (
                        <ProjectAction
                           plan="project.action.plan.adviceLoan.plan"
                           subtitle="project.action.plan.adviceLoan.plan.subtitle"
                           subtitleTransformations={{
                              amount: <CustomNumberFormat displayType="text" thousandSeparator={" "} value={projectFeasibility.maxLoanAmount}/>
                           }}
                           secondSubtitle="project.action.plan.adviceLoan.plan.subtitle2"
                           textDiscoverMoreLink={getDiscoverMoreLink(
                              "REAL_ESTATE_SIMULATOR"
                           )}
                           amount={projectFeasibility.plan.loanAmount}
                           discoverMoreLink={getDiscoverMoreLink("ADVICE_LOAN")}
                        />
                     )}
                     {isAdditionalSavingRequired() && (
                        <ProjectAction
                           plan="project.action.plan.additional.effort.plan"
                           subtitle={
                              projectFeasibility.projectLoanAllowed
                                 ? "project.action.plan.additional.effort.plan.loan.subtitle"
                                 : "project.action.plan.additional.effort.plan.noloan.subtitle"
                           }
                           amount={getAdditionalSavingRequire()}
                        />
                     )}
                  </>
               )}
            </SGSpace>
         )}
      </SpinnerWrapper>
   );
};

export {ProjectActionPlan};
