import {Income, IncomeType} from "./types";
import {Member} from "../members/types";

export const getIncome = (incomes: Income[], type: IncomeType, member: Member | undefined | null): Income | undefined => {
   if (!member) {
      return incomes.filter((i) => i.type === type && i.memberId === undefined)[0];
   }

  return incomes.filter((i) => i.type === type && i.memberId === member.id)[0];
};

export const filterOutStopWorkIncomes = (incomes: Income[]): Income[] => incomes.filter((i) => i.type !== IncomeType.WORK_INCOME || i.monthlyAmount !== 0);
