import { PayloadAction } from "@reduxjs/toolkit";
import {
  CREATE_PREFS, Dashboard, FETCH_DASHBOARD, ONBOARDED, RESET_DASHBOARD_ERROR,
  UPDATE_DASHBOARD, UPDATE_PREFS, UPDATE_REFORME_DA, UPDATE_RELOAD_PREFS, UPDATE_SHOWTUTORIEL, User
} from "./types";

/**
 * Appelle le backpfr pour récupérer les préférences
 */
export const callDashboard = () => ({
  type: FETCH_DASHBOARD,
});

/**
 * Appelle le backpfr pour créer les préférences
 */
export const createPrefs = () => ({
  type: CREATE_PREFS,
});

/**
 * Appelle le backpfr pour mettre à jour les préférences
 */
export const updatePrefs = (data: User): PayloadAction<User> => ({
  type: UPDATE_PREFS,
  payload: data,
});

/**
 * Appelle le backpfr pour mettre à jour les préférences et relance un fetch
 */
export const updateAndReloadPrefs = (data: User): PayloadAction<User> => ({
  type: UPDATE_RELOAD_PREFS,
  payload: data,
});

export const resetDashboardError = () => ({
  type: RESET_DASHBOARD_ERROR,
});

/**
 * Met à jour le dashboard en local
 * @param data Dashboard complet avec mises à jour
 */
export const updateDashboard = (data: Partial<Dashboard>): PayloadAction<Partial<Dashboard>> => ({
   type: UPDATE_DASHBOARD,
   payload: data,
});

export const setIsOnboarded = () => ({
  type: ONBOARDED
});

export const setVientDeFaireReformeDA = (data: boolean) => ({
  type: UPDATE_REFORME_DA,
  payload: data,
});

export const setShowTutoriel = (data: boolean) => ({
  type: UPDATE_SHOWTUTORIEL,
  payload: data,
});
