import {CustodyType, FamilyStatus} from "../members/types";
import {Asset} from "../assets/types";

export interface SurvivingPartnerSimulatorState {
   result: SurvivingPartnerSimulatorResult[];
   hasFetched: boolean;
}

export interface SurvivingPartnerSimulatorResult {
   familyStatus: FamilyStatus;
   valuationByPossessionTypeChoice: { [key: string]: number };
   asset: Asset;
}

export interface SurvivingPartnerSimulatorParameter {
   familyStatus?: FamilyStatus;
   heirFamilyStatus: string;
   custodyType?: CustodyType;
   bothParent: boolean;
}

// actions
export const FETCH_SHARE_OF_SURVIVING_PARTNER = "FETCH_SHARE_OF_SURVIVING_PARTNER";
