import { ReactComponent as CheckProgression } from "assets/images/check-progression.svg";
import { ReactComponent as CheckVideProgression } from "assets/images/check-vide-progression.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGPopover } from "sg-popover";
import { SGText } from "sg-typo";
import { Parcours } from "website/components/hooks/dashboard/useDashboardProgression";
import "./ProgressionPopHover.scss";

interface ProgressionPopHoverProps {
    pourcentage: number;
    parcoursDoneTab: Parcours[];
}

const ProgressionPopHover: FunctionComponent<ProgressionPopHoverProps> = (props: React.PropsWithChildren<ProgressionPopHoverProps>) => {
    const { pourcentage, parcoursDoneTab, children } = props;
    const intl = useIntl();

    return (
        <SGPopover
            placement="bottom"
            content={(
                <>
                    <SGGridRow>
                        <SGGridCol>
                            <SGText
                                style={{
                                    lineHeight: "17px",
                                    color: "#000000",
                                }}
                                weight="600"
                            >
                                {intl.formatMessage(
                                    { id: "progression.popHover.title" },
                                    {
                                        blue: (word: string) => (
                                            <SGText
                                                weight="bold"
                                                style={{
                                                    color: "#0072B9"
                                                }}
                                            >
                                                {word}
                                            </SGText>
                                        ),
                                        pourcentage,
                                    }
                                )}
                            </SGText>
                        </SGGridCol>
                    </SGGridRow>
                    <SGGridRow>
                        {parcoursDoneTab?.map((parcours) => (
                            <SGGridCol span={12} align="start" key={parcours.nom}>
                                <SGGridRow>
                                    <SGGridCol span={1}>
                                        {parcours.date !== undefined ? <CheckProgression /> : <CheckVideProgression />}
                                    </SGGridCol>
                                    <SGGridCol>
                                        <SGText weight="400" style={{ color: parcours.date !== undefined && "#0072B9" }}>
                                            {intl.formatMessage({ id: `progression.popHover.check.${parcours.nom}` })}
                                        </SGText>
                                    </SGGridCol>
                                </SGGridRow>
                            </SGGridCol>
                        )
                        )}
                    </SGGridRow>
                </>
            )}
        >
            {children}
        </SGPopover>
    );
};
export { ProgressionPopHover };
