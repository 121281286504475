import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
    import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGStep, SGSteps } from "sg-steps";
import { SGTitle } from "sg-typo";
import { resetTunnelAction } from "store/tunnel/actions";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { BreadCrumbAssu } from "website/components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { SimulateurFlashWorkIncomes } from "website/components/organisms/SimulateurFlash/SimulateurFlashWorkIncomes/SimulateurFlashWorkIncomes";
import { SimulateurFlashWorkIncomesForecast } from "website/components/organisms/SimulateurFlash/SimulateurFlashWorkIncomesForecast/SimulateurFlashWorkIncomesForecast";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { State } from "../../store/store";
import { SimulateurFlashFamilySituation } from "../components/organisms/SimulateurFlash/SimulateurFlashFamilySituation/SimulateurFlashFamilySituation";
import { SimulateurFlash } from "../components/templates/SimulateurFlash/SimulateurFlash";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGBox } from "sg-space";

export const SimulateurFlashPage: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);
    const stepComponentsAssu = [SimulateurFlashFamilySituation, SimulateurFlashWorkIncomes, SimulateurFlashWorkIncomesForecast];
    const [reset, setReset] = useState(false);

    const categories = [
        intl.formatMessage({ id: "tunnel.step.etape2" }),
        intl.formatMessage({ id: "tunnel.step.etape3" }),
        intl.formatMessage({ id: "tunnel.step.etape4" }),
    ];

    const idTrackingSteps = ["ma-situation", "mes-revenus", "mon-evolution"];

    useEffect(() => {
        dispatch(resetTunnelAction());
        // On n'affiche pas le composant trop tôt, pour éviter un tracking d'ancien parcours
        setReset(true);
    }, []);

    useScrollToTopWithCurrentStep(currentStep);

    const getStatus = (index: number): string => {
        const indexStep: number = currentStep;
        if (indexStep === index) {
            return "process";
        }
        if (indexStep < index) {
            return "wait";
        }

        return "finish";
    };

    return reset ? (
        <SGLayout tagName="div">
        <SGContent disableautomargin>
        <SGGridRow justify="center" gutter={[0, 20]}>
            <SGMediaQuery minwidth="sm">
                <SGGridCol span={10}>
                    <BreadCrumbAssu step="tunnel.title" idTracking={idTrackingSteps[currentStep]} />
                </SGGridCol>
                <SGGridCol span={10} align="start">
                    <SGTitle textalign="left" caps level={2}>
                        {intl.formatMessage({ id: "tunnel.title" })}
                    </SGTitle>
                </SGGridCol>
            </SGMediaQuery>
            <SGGridCol span={12}>
                <SGBox margin={isPhone ? {bottom: "md", top: "md"} : {bottom: "md"}}>
                    <SGSteps current={currentStep}>
                        {categories.map((categorie, index) => (
                            <SGStep title={categorie} status={getStatus(index)} key={categorie} />
                        ))}
                    </SGSteps>
                </SGBox>
            </SGGridCol>
        </SGGridRow>
        </SGContent>
        <SGLayout background="light">
            <SGHeader disableautomargin />
            <SGContent disableautomargin>
                <DetailTabProvider>
                    <SimulateurFlash steps={stepComponentsAssu} />
                </DetailTabProvider>
            </SGContent>
        </SGLayout>
    </SGLayout>
    ) : (
        <></>
    );
};
