import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {TaxState} from "../../../store/tax/types";
import {getTaxSituationAction} from "../../../store/tax/actions";

const useTaxSituation = (forceFetch: boolean) => {
   const dispatch = useDispatch();
   const taxState: TaxState = useSelector<State, TaxState>((state) => state.tax);

   useEffect(() => {
      if (!taxState.hasFetchedTaxSituation || forceFetch) {
         dispatch(getTaxSituationAction());
      }
   }, []);

   return taxState.taxSituation;
};

export {useTaxSituation};
