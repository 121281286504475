import { ReactComponent as Arrow } from "assets/images/arrow-right-solid.svg";
import { FunctionComponent, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { resetAssetsIncomes } from "store/assetIncomes/actions";
import { callDashboard } from "store/dashboard/actions";
import { hasFetchedDashboard } from "store/dashboard/types";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { DetailTab } from "website/components/atoms/DetailTab/DetailTab";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAccount } from "website/components/hooks/useAccount";
import { Faq } from "website/components/molecules/Faq/Faq";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { DASHBOARD } from "website/utils/privateRoutes";
import { State } from "../../../../store/store";
import { TunnelStep } from "../../organisms/Tunnel/TunnelStep/TunnelStep";
import "./SimulateurFlash.scss";

export interface SimulateurFlashProps extends RouteProps {
    steps: FunctionComponent[];
}

export const SimulateurFlash: FunctionComponent<SimulateurFlashProps> = (props: SimulateurFlashProps) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const { trackClick } = useTracking();

    const account = useAccount();
    const dashboardState = useDashboardState();
    const {showDetailFonctionnaire} = useContext(DetailTabContext);
    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );

    const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);
    const { steps } = props;

    const faqBodyList = ["personal.data", "sapiendo"];
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'sm' });

    useEffect(() => {
        if (
            simulationAssuState?.retirementSimulationParameters?.isParcoursDone &&
            simulationAssuState?.retirementSimulationParameters?.idSimulSimple &&
            simulationAssuState?.retirementSimulationParameters?.idSimulSimple !== ""
        ) {
            dispatch(resetAssetsIncomes());
            dispatch(callDashboard());
        }
    }, [simulationAssuState?.retirementSimulationParameters]);

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState) && simulationAssuState?.retirementSimulationParameters?.isParcoursDone) {
            dispatch(updateRetirementSimulation({ ...simulationAssuState.retirementSimulationParameters, isParcoursDone: false }));
            history.push(DASHBOARD);
        }
    }, [dashboardState]);

    const handleClickRetour = () => {
        trackClick('clic-sur-retour-a-mon-tableau-de-bord');
        history.push(DASHBOARD);
    }

    return (
        <SGGridRow justify="center" style={{ paddingLeft: "5px" }}>
            <SGGridCol span={12} sm={11} md={10}>
                <SGGridRow>
                    <SGGridCol span={12}>
                        <SGCard hoverable shadow>
                            {steps.map((step, index) => currentStep === index && (
                                <SGGridRow key={step.name} gutter={[0, 32]}>
                                    <SGGridCol span={12} md={7}>
                                        <SGBox margin={isPhone ? {left: "sm", right: "sm", top: "lg"} : {left: "sm", right: "sm",bottom: "lg" , top: "lg"}}>
                                            <TunnelStep stepIndex={index} component={step} />
                                        </SGBox>
                                    </SGGridCol>
                                    <SGGridCol span={12} md={5} align="stretch">
                                        <SGBox margin={isPhone ? {bottom: "lg"} : {}}>
                                            <DetailTab
                                                titleDetailTab="tunnel.detail.title"
                                                textDetailTab={`tunnel.detail.text.step${currentStep + 1}`}
                                                hideListStyle={isPhone ? true : currentStep === 2}
                                                transformations={{ fonctionnaire: (word: string) => (showDetailFonctionnaire ? word : "") }}
                                            />
                                        </SGBox>
                                    </SGGridCol>
                                </SGGridRow>
                            ))}
                        </SGCard>
                    </SGGridCol>
                    {currentStep === 0 && (
                        <SGGridCol span={12} align="start">
                            <SGBox margin={{top: "md"}}>
                                <SGButton
                                    type="tertiary"
                                    icon={<SGIcon rotate="left" component={<Arrow className="tab-epargner__arrow" />} />}
                                    iconposition="left"
                                    onClick={handleClickRetour}
                                >
                                    <SGText className="simulateur-flash__link">
                                        {intl.formatMessage({
                                            id: "link.retourBilanRetraite",
                                        })}
                                    </SGText>
                                </SGButton>
                            </SGBox>
                        </SGGridCol>
                    )}
                </SGGridRow>
            </SGGridCol>
            <SGGridCol span={10} align="start">
                <Faq bodyFaq={faqBodyList} openDefaultKeys={currentStep === 0 && !isPhone ? [faqBodyList[0]] : [""]} isMainAccordionOpen hideMainAccordion />
            </SGGridCol>
        </SGGridRow>
    );
}
