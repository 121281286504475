import React, {FunctionComponent} from "react";
import {AdditionalIncomeProject} from "../../components/organisms/Projects/AdditionaIncomeProject/AdditionaIncomeProject";
import {SGContent} from "sg-layout";

const AdditionalIncomeProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <AdditionalIncomeProject/>
   </SGContent>
);

export {AdditionalIncomeProjectPage};
