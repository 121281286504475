import React, {FunctionComponent, useEffect, useState,} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {AssetState, AssetType, AssetWithValuation} from "../../../../../store/assets/types";
import {LoanEdit} from "../../../molecules/LoanEdit/LoanEdit";
import {AmortizationType, Loan, LoanType,} from "../../../../../store/loans/types";
import {RealEstateSimulator} from "../../../molecules/RealEstateSimulator/RealEstateSimulator";
import {defaultAssetMemberLinkSet} from "../../../../../store/assets/utils";
import {nextStepAction, previousStepAction} from "../../../../../store/tunnel/actions";
import {createRealEstateAsset, createRealEstateAssetAndLoan, updateRealEstateAsset, updateRealEstateAssetAndLoan} from "../../../../../store/assets/actions";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {useFamily} from "../../../hooks/useFamily";
import {useLoans} from "../../../hooks/useLoans";
import {useAssets} from "../../../hooks/useAssets";
import {useExpense} from "../../../hooks/useExpense";
import {ProjectType} from "../../../../../store/projects/types";
import {updateExpensesAction} from "../../../../../store/expenses/actions";
import {State} from "../../../../../store/store";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {computeLoanName} from "../../../molecules/AssetEditForms/AssetAssociationForm/AssetAssociationForm";
import {SGSpace} from "sg-space";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SelectInputButtons} from "../../../atoms/SelectInputButtons/SelectInputButtons";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface HomeFormData {
   // rent
   rentAmount: number;
   // real estate
   assetValuation: number;
   // loan
   name: string;
   monthlyAmount: number;
   startDate: Date;
   displayedAmount: number;
   durationInMonth: number;
   amortizationType: AmortizationType;
   existingLoan: string;
}

const HomeStatus = {
   OWNER: "OWNER",
   OWNER_WITH_LOAN: "OWNER_WITH_LOAN",
   RENT: "RENT",
}

type HomeStatus = typeof HomeStatus[keyof typeof HomeStatus];

const TunnelHome: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();
   const { assets } = useAssets();
   const loans = useLoans([]);
   const realEstateLoans = useLoans([LoanType.CONSUMER, LoanType.STUDENT]);
   const rentExpense = useExpense(ProjectType.RENT);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
   const { trackPage } = useTracking();

   const [homeStatus, setHomeStatus] = useState<HomeStatus>();
   const [isPriceKnown, setIsPriceKnown] = useState<boolean | null>(null);
   const [hasJustSubmitted, setHasJustSubmitted] = useState<boolean>(false);
   const [price, setPrice] = useState<number | undefined>(undefined);

   const [asset, setAsset] = useState<Partial<AssetWithValuation>>({
      assetName: intl.formatMessage({
         id: "tunnel.situation.home.my-primary-real-estate",
      }),
      assetType: AssetType.REAL_ESTATE_PRIMARY,
   });
   const [loan, setLoan] = useState<Partial<Loan>>({
      name: intl.formatMessage({id: "tunnel.situation.home.my-real-estate-loan"})
   });

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });
   const {errors, handleSubmit, formState} = methods;

   const isValid = (): boolean => {
      if (!homeStatus) {
         return false;
      }
      if (homeStatus === HomeStatus.RENT) {
         return true;
      }

      return !(isPriceKnown === null || !price);
   };

   useEffect(() => {
      if (assetState.hasFetched && assetState.assets.length > 0) {
         if (hasJustSubmitted) {
            dispatch(nextStepAction());
         }
         setHasJustSubmitted(false);
      }
   }, [assetState]);

   useEffect(() => {
      if (asset.assetValuation && !!asset.assetValuation.totalValuation) {
         setIsPriceKnown(true);
         setPrice(asset.assetValuation.totalValuation);
      }
   }, [asset]);

   useEffect(() => {
      if (assets) {
         const asset: AssetWithValuation | undefined = assets.filter((value) => value.assetType === "REAL_ESTATE_PRIMARY").shift();
         if (asset) {
            setAsset(asset);
            setHomeStatus(HomeStatus.OWNER);
            if (asset.loans.length > 0) {
               setHomeStatus(HomeStatus.OWNER_WITH_LOAN);
               setLoan(loans.filter((loan) => loan.id === asset.loans[0])[0]);
            }
         }
      }
      if (realEstateLoans && realEstateLoans.length > 0) {
         setHomeStatus(HomeStatus.OWNER_WITH_LOAN);
         setLoan(realEstateLoans[0]);
      }

      if (!homeStatus && rentExpense && Object.keys(rentExpense).length > 0) {
         setHomeStatus(HomeStatus.RENT);
      }
   }, [rentExpense, assets, realEstateLoans]);

   useEffect(() => {
      trackPage("funnel", "mon-patrimoine-residence-principale", "formulaire-ecran", "inscription", "2.1");
   }, []);

   const calculatePrice = (estimatedPrice: number) => {
      setPrice(estimatedPrice);
      const assetValuation = {
         ...asset.assetValuation,
         totalValuation: estimatedPrice,
      };
      setAsset((prevState) => ({...prevState, assetValuation}));
      methods.setValue("assetValuation", estimatedPrice, {
         shouldValidate: true,
      });
   };

   const onSubmit = (data: HomeFormData) => {
      if (homeStatus === HomeStatus.RENT) {
         dispatch(updateExpensesAction({monthlyAmount: data.rentAmount}, undefined, undefined));
         dispatch(nextStepAction());
      }
      if (homeStatus === HomeStatus.OWNER && asset) {
         const currentAsset: Partial<AssetWithValuation> = {
            ...asset,
            assetMemberLinkSet: defaultAssetMemberLinkSet(family.me),
         };
         currentAsset.assetValuation = {
            ...asset.assetValuation,
            totalValuation: data.assetValuation,
         };
         currentAsset.loans = [];
         // Si l'on a une maison, on supprime le loyer
         if (rentExpense) {
            dispatch(updateExpensesAction({monthlyAmount: 0}, undefined, undefined));
         }
         if (asset.id) {
            dispatch(updateRealEstateAsset(currentAsset))
         } else {
            dispatch(createRealEstateAsset(currentAsset));
         }
      }
      if (homeStatus === HomeStatus.OWNER_WITH_LOAN && asset) {
         const currentAsset: Partial<AssetWithValuation> = {
            ...asset,
            assetMemberLinkSet: defaultAssetMemberLinkSet(family.me),
         };
         currentAsset.assetValuation = {
            ...asset.assetValuation,
            totalValuation: data.assetValuation,
         };
         let currentLoan: Partial<Loan>;

         if (data.existingLoan !== null) {
            // Selection Loan Existant
            const existingLoan = loans.find(
               (loan) => loan.id === parseInt(data.existingLoan)
            );
            currentLoan = {...existingLoan};
         } else {
            // Création Loan
            currentLoan = {};
            currentLoan.loanType = LoanType.REAL_ESTATE;
            currentLoan.assetMemberLinkSet = defaultAssetMemberLinkSet(family.me);
         }
         if (!currentLoan.externalId) {
            const {amortizationType} = currentLoan;
            currentLoan = {
               ...currentLoan,
               ...data,
               name: !data.name ? currentLoan.name : data.name,
            };
            currentLoan.amortizationType = currentLoan.amortizationType || amortizationType;
         }
         if (asset.id) {
            if (currentLoan) {
               dispatch(updateRealEstateAssetAndLoan(currentAsset, currentLoan))
            } else {
               dispatch(updateRealEstateAsset(currentAsset))
            }
         } else if (currentLoan) {
            dispatch(createRealEstateAssetAndLoan(currentAsset, currentLoan));
         } else {
            dispatch(createRealEstateAsset(currentAsset));
         }
      }
      setHasJustSubmitted(true);
   };

   const onSelectLoan = (newLoanId: string) => {
      setLoan(loans.filter((m) => m.id === parseInt(newLoanId))[0]);
   };

   const displayRealEstateAssetEdit = () => (
      <FormInputs colLength={8}>
         <SGSpace direction="vertical">
            <RealEstateSimulator setPrice={calculatePrice} setPriceKnown={setIsPriceKnown} priceKnown={isPriceKnown} monthlyFeesKnown={false}
                                 trackingName="mon-patrimoine-residence-principale::clic-sur-detention-credit"
                                 setMonthlyFeesKnown={setIsPriceKnown}/>
            {isPriceKnown && (
               <NumericInput cypressName="real-estate-amount" label="tunnel.situation.home.my-primary-real-estate-valuation"
                             defaultValue={asset.assetValuation ? asset.assetValuation.totalValuation : undefined} suffix="€"
                             name="assetValuation" onValueChange={(value: number) => setPrice(value)} min={5_000} max={10_000_000}
                             placeholder={intl.formatMessage({id: "form.asset.total-valuation.placeholder"})}/>
            )}

            {isPriceKnown && !errors?.assetValuation && price && (
               <SGSpace direction="vertical">
                  <SGTextIntl intlId="tunnel.situation.home.loan-reimbursement"/>
                  <SelectInputButtons cypressName="homeSituation-hasLoan" labels={["common.yes", "common.no"]} size="sm" direction="horizontal"
                                      choices={[HomeStatus.OWNER_WITH_LOAN, HomeStatus.OWNER]} choice={homeStatus}
                                      trackingName="mon-patrimoine-residence-principale::clic-sur-prix-du-bien"
                                      setChoice={setHomeStatus}/>
               </SGSpace>

            )}
            {isPriceKnown && !errors?.assetValuation && price && homeStatus === HomeStatus.OWNER_WITH_LOAN && (
               <>
                  <SelectInput name="existingLoan" onValueChange={onSelectLoan} defaultValue={loan?.id}
                               cypressName="tunnel-select-existing-loan" required={false}
                               placeholder={intl.formatMessage({id: "form.loan.fill-a-loan"})} label="form.loan.choose-a-loan">
                     {loans.map((currentLoan: Loan) => (
                        <SGSelectOption key={currentLoan.id} value={currentLoan.id}>
                           {computeLoanName(currentLoan)}
                        </SGSelectOption>
                     ))}
                  </SelectInput>
                  <LoanEdit hideButtons editedLoan={loan} onSubmit={onSubmit} cypressName="tunnel-owner-loan-edit"/>
               </>
            )}
         </SGSpace>
      </FormInputs>
   );

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="tunnel.situation.home.my-primary-real-estate" cypressName="tunnel-primary-real-estate-title"/>
         <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <FormInputs colLength={8}>
                  <SGSpace direction="vertical">
                     <SGTextIntl intlId="tunnel.situation.home.are-you-owner"/>
                     <SelectInputButtons cypressName="homeSituation-status" labels={["tunnel.situation.home.rent", "tunnel.situation.home.owner"]}
                                      size="sm" direction="horizontal" choices={[HomeStatus.RENT, HomeStatus.OWNER]}
                                      trackingName="mon-patrimoine-residence-principale::clic-sur"
                                      choice={homeStatus === HomeStatus.OWNER_WITH_LOAN ? HomeStatus.OWNER : homeStatus} setChoice={setHomeStatus}/>
                     {homeStatus === HomeStatus.RENT &&
                        <NumericInput cypressName="homeSituation-rental-amount" label="tunnel.situation.home.rent-amount" suffix="€"
                                   defaultValue={rentExpense?.monthlyAmount ? +rentExpense.monthlyAmount : undefined} name="rentAmount" max={10_000}
                                   min={100} placeholder={intl.formatMessage({id: "form.asset.rent.placeholder"})}/>}
                  </SGSpace>
               </FormInputs>
               {(homeStatus === HomeStatus.OWNER || homeStatus === HomeStatus.OWNER_WITH_LOAN) &&
                  displayRealEstateAssetEdit()}

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={!isValid() || !formState.isValid} cypressName="tunnel-next"
                                onClick={handleSubmit(onSubmit)} trackingName="mon-patrimoine-residence-principale::clic-sur-confirmer"
                                intlId="tunnel.buttons.next"/>
                  <SGButtonIntl type="link" cypressName="tunnel-previous"
                                onClick={() => { dispatch(previousStepAction()) }} trackingName="mon-patrimoine-residence-principale::clic-sur-retour"
                                intlId="tunnel.buttons.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>
      </SGSpace>
   );
};

export {TunnelHome};
