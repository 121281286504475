import React, {FunctionComponent} from "react";
import "./ProjectAction.scss";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGText} from "sg-typo";
import {CustomNumberFormat} from "../../../utils/formatting/CustomNumberFormat";

interface ProjectActionProps {
   plan?: string;
   subtitle?: string;
   subtitleTransformations?: Record<string, string | React.ReactNode>;
   secondSubtitle?: string;
   textDiscoverMoreLink?: string;
   amount?: number;
   discoverMoreLink?: string;
}

const ProjectAction: FunctionComponent<ProjectActionProps> = (props) => {
   const {plan, subtitle, subtitleTransformations, secondSubtitle, textDiscoverMoreLink, amount, discoverMoreLink,} = props;

   const goToLink = (link: string) => {
      window.open(link);
   };

   return (
      <div className="project-action__frame">
         <div>
            {plan && <SGTextIntl intlId={plan} strong/>}
            {subtitle && (
               <div>
                  <SGTextIntl intlId={subtitle} transformations={subtitleTransformations}/>
               </div>
            )}
            {(window.env.REACT_APP_INSTANCE === "bddf" ||
                  window.env.REACT_APP_INSTANCE === "assu") &&
               secondSubtitle && (
                  <div>
                     <SGTextIntl intlId={secondSubtitle}/>
                  </div>
               )}
            {(window.env.REACT_APP_INSTANCE === "bddf" ||
                  window.env.REACT_APP_INSTANCE === "assu") &&
               textDiscoverMoreLink && (
                  <SGButtonIntl type="link" onClick={() => goToLink(textDiscoverMoreLink)} intlId="project.action.plan.click.here"
                                cypressName="project-action-discover-more"/>

               )}
         </div>
         <div>
            {amount !== undefined && (
               <SGText strong size="l">
                  <CustomNumberFormat displayType="text" value={amount} thousandSeparator={" "} suffix="€"/>
               </SGText>
            )}
            {(window.env.REACT_APP_INSTANCE === "bddf" || window.env.REACT_APP_INSTANCE === "assu") &&
               discoverMoreLink && (
                  <div className="project-action__discover-more">
                     <SGButtonIntl type="link" onClick={() => goToLink(discoverMoreLink)} intlId="project.action.plan.discover.more"
                                   cypressName="project-action-discover-more"/>
                  </div>
               )}
         </div>
      </div>
   );
};

export {ProjectAction};
