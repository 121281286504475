import React, {FunctionComponent, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {ProjectType} from "../../../../../store/projects/types";
import {createProjectAction} from "../../../../../store/projects/actions";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {StringInput} from "../../../atoms/StringInput/StringInput";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGCard} from "sg-card";
import {useProjects} from "website/components/hooks/useProjects";
import {useNumberProjects} from "../../../hooks/useNumberProjects";

interface DefaultProjectProps {
   projectType: ProjectType;
   projectTitle: string;
   projectSubtitle: string;
   projectAmountLabel: string;
   projectHorizonLabel: string;
   projectAmountPlaceholder: string;
   newProjectTitle: string;
   cypressName: string;
}

interface DefaultProjectFormData {
   amount: number;
   name: string;
   horizon: string;
}

const DefaultProject: FunctionComponent<DefaultProjectProps> = (props: DefaultProjectProps) => {
   const {
      projectType,
      projectTitle,
      projectSubtitle,
      projectAmountLabel,
      projectHorizonLabel,
      newProjectTitle,
      projectAmountPlaceholder,
      cypressName
   } = props;

   const dispatch = useDispatch();
   const intl = useIntl();
   const currentProjects = useProjects(false, false);

   useEffect(() => {
      let amount = 0;
      if (currentProjects.length > 0) {
         amount = currentProjects.filter((proj) => proj.projectType === projectType).length;
      }

      const defaultName = amount < 1 ? intl.formatMessage({id: newProjectTitle})
         : `${intl.formatMessage({id: newProjectTitle})} ${amount + 1}`;

      methods.setValue("name", defaultName, {shouldValidate: true});
   });

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange"
   });

   const numberProjects = useNumberProjects();

   const goToNewProject = () => {
      redirectToRelativePath(NEW_PROJECTS);
   };

   const onSubmit = (data: DefaultProjectFormData) => {
      dispatch(
         createProjectAction({
            amount: data.amount,
            projectType,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectName: data.name,
         })
      );
   };

   const availableHorizonOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];

   return (
      <>
         <SGTitleIntl intlId={projectTitle} cypressName={`${cypressName}-title`}/>
         <SGTextIntl intlId={projectSubtitle}/>
         {numberProjects === 3 && (
            <SGAlertIntl cypressName={`${cypressName}-error-element-frame`} type="warning" title="common.warning">
               <SGTextIntl intlId="project.error.already-three"/>
            </SGAlertIntl>
         )}
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
               <SGCard data-cy="default-project-input-frame">
                  <FormInputs colLength={10}>
                     <NumericInput name="amount" suffix="€" cypressName={`${cypressName}-amount`} min={5000} max={10_000_000}
                                   label={projectAmountLabel} placeholder={intl.formatMessage({id: projectAmountPlaceholder})}/>

                     <SelectInput name="horizon" cypressName={`${cypressName}-horizon`} defaultValue={5} label={projectHorizonLabel}>
                        {availableHorizonOptions.map(
                           (horizon: number) => (
                              <SGSelectOption key={horizon} value={horizon}>
                                 {horizon === 0
                                    ? intl.formatMessage({id: "common.now"})
                                    : horizon +
                                    intl.formatMessage({id: "common.years"})}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>

                     <StringInput label="project.form.name" name="name" cypressName={`${cypressName}-name`}
                                  defaultValue={intl.formatMessage({id: newProjectTitle})}/>
                  </FormInputs>
               </SGCard>

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               {numberProjects !== 3 && (
                  <SGButtonGroup align="center" layout="column">
                     <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName={`${cypressName}-next`}
                                   onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                     <SGButtonIntl type="link" cypressName={`${cypressName}-previous`} onClick={goToNewProject} intlId="common.previous"/>
                  </SGButtonGroup>)
               }
            </form>
         </FormProvider>
      </>
   );
};

export {DefaultProject};
