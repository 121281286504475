import React, {FunctionComponent, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {StringInput} from "../../../atoms/StringInput/StringInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {emailValidationRegex} from "../Signup/const";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {CGU, LOGIN} from "../../../../utils/publicRoutes";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGButtonGroup} from "sg-button";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {useDispatch} from "react-redux";
import {requestNewPassword} from "../../../../../store/login/actions";
import {SGCard} from "sg-card";

const PasswordResetInit: FunctionComponent = () => {
   const dispatch = useDispatch();

   const formMethods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const {handleSubmit} = formMethods;
   const [didSubmit, setDidSubmit] = useState<boolean>(false);

   const onSubmit = (data: {email: string}) => {
      dispatch(requestNewPassword(data.email));
      setDidSubmit(true);
   };

   return (
      <SGCard textalign="center">
         <SGTitleIntl intlId="password-reset.init.title" cypressName="password-reset-init-title"/>
         <SGTextIntl intlId={didSubmit ? "password-reset.init.submitted" : "password-reset.init.email"}/>

         {!didSubmit &&
         <FormProvider {...formMethods}>
            <form>
               <FormInputs colLength={10}>

                  <StringInput label="login.form.your.email" defaultValue="" name="email" minLength={1} maxLength={50} cypressName="password-reset-init-mail"
                               placeholder="signup.form.your.email.placeholder" maxLengthMessage="login.form.error.username.size"
                               minLengthMessage="login.form.error.username.size" pattern={emailValidationRegex}
                                  patternMessage="login.form.error.username.bad.email"/>
               </FormInputs>
               <SGButtonGroup layout="column" align="center">
                  <SGButtonIntl type="primary" size="md" disabled={!formMethods.formState.isValid} cypressName="password-reset-init-submit"
                                onClick={handleSubmit(onSubmit)} intlId="password-reset.init.submit"/>
                  <SGButtonIntl type="link" cypressName="password-reset-init-back" onClick={() => redirectToRelativePath(LOGIN)}
                                intlId="password-reset.init.back-to-login"/>
                  <SGButtonIntl type="link" onClick={() => redirectToRelativePath(CGU)} intlId="signup.link.cgu"/>
               </SGButtonGroup>
            </form>
         </FormProvider>}
      </SGCard>
   );
};
export {PasswordResetInit};
