import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { DashboardState } from "store/dashboard/types";
import { State } from "store/store";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { GraphRevenuTotalRetraite } from "website/components/molecules/GraphRevenuTotalRetraite/GraphRevenuTotalRetraite";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { Image } from "website/components/atoms/Image/Image";
import "./RevenuTotalRetraiteMobile.scss";
import { SGBox } from "sg-space";

const RevenuTotalRetraiteMobile: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { selectedRetirementAge } = useSelectedRetirementAge();
    const { montantRevenusAFinancer, montantPension, montantTotal } = useMonPlanMontants(selectedRetirementAge);
    const [montantRevenusComplementaires, setMontantRevenusComplementaires] = useState<number>();
    const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
    const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);
    const dashboardState: DashboardState = useSelector<State, DashboardState>((state) => state.dashboard);
    const [isParcoursEpargnerDone, setIsParcoursEpargnerDone] = useState(false);
    const [isParcoursPatrimoineDone, setIsParcoursPatrimoineDone] = useState(false);

    const {assets} = assetState;
    const {assetIncomes} = assetIncomesState;
    const assetsLength = assets ? assets.filter((asset) => asset.percentageUsedForRetirement > 0).length : 0;

    useEffect(() => {
        if (!assetState) {
            dispatch(getAssetsWithoutLoans());
        }
        if (!hasFetchedAssets(assetIncomesState)) {
            dispatch(getAssetsIncomes());
        }
    }, []);

    useEffect(() => {
        setIsParcoursEpargnerDone(dashboardState?.dashboard?.firstSavingPathDate !== undefined);
        setIsParcoursPatrimoineDone(dashboardState?.dashboard?.firstPatrimonyPathDate !== undefined);
    }, [dashboardState]);

    useEffect(() => {
        if (isParcoursPatrimoineDone && selectedRetirementAge && assetState.hasFetched && hasFetchedAssets(assetIncomesState)) {
            setMontantRevenusComplementaires(roundDown10(computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)));
        }
    }, [isParcoursPatrimoineDone, selectedRetirementAge, assetState, assetIncomesState]);

    function getStringValue(value: number | undefined) {
        if (value !== undefined && value >= 0) {
            return value?.toLocaleString("fr-FR");
        }

        return "--";
    }

    const montants = [{
        type: "pension",
        color: "#9F2D62",
        montant: getStringValue(montantPension),
        isParcoursDone: true
    },
    {
        type: "patrimoine",
        color: "#208382",
        montant: assetsLength !== 0 ? getStringValue(montantRevenusComplementaires) : "--",
        isParcoursDone: isParcoursPatrimoineDone
    },
    {
        type: "épargne",
        color: "#090F70",
        montant: getStringValue(montantRevenusAFinancer),
        isParcoursDone: isParcoursEpargnerDone
    }]

    return (
        <SGGridRow>
            <SGGridCol span={6}>
                <GraphRevenuTotalRetraite
                    montantPension={montantPension}
                    montantRevenusComplementaires={montantRevenusComplementaires}
                    montantTotal={montantTotal}
                    montantRevenusAFinancer={montantRevenusAFinancer}
                    assetsLength={assets ? assetsLength : 0}
                />
            </SGGridCol>
            <SGGridCol span={6} style={{paddingLeft: 0}}>
                <SGBox margin={{top: "md"}}>
                    <SGGridRow>
                        <SGGridCol span={12}>
                            <Image className="revenu-total-retraite-mobile__logoMCR" src="images/SG_MCR.png" alt="Mon Compagnon Retraite" />
                        </SGGridCol>
                        <SGGridCol span={12} style={{paddingRight: 0}}>
                            <SGText size="s" weight="700" titlerender>
                                {intl.formatMessage({ id: "dashboard.revenus.title" }, {
                                    i: <InfoTooltipAssu
                                        text="dashboard.revenus.info"
                                        placement="bottomLeft"
                                        className="info-tooltipAssu__align"
                                    />
                                })}
                            </SGText>
                        </SGGridCol>
                        {montants.map((montant) =>
                            <SGGridCol span={12} key={montant.type}>
                                <SGText weight="700" size="s" type="number" style={{ color: montant.color, opacity: montant.isParcoursDone ? "1" : "0.5" }}>
                                    {intl.formatMessage(
                                        { id: `monPlan.revenus.${montant.type}` },
                                        {
                                            montant: montant.montant,
                                            light: (word) => <SGText weight="400" style={{ fontFamily: "Source Sans Pro" }}>{word}</SGText>,
                                        }
                                    )}
                                </SGText>
                            </SGGridCol>
                        )}
                    </SGGridRow>
                </SGBox>
            </SGGridCol>
        </SGGridRow>
    );
};

export { RevenuTotalRetraiteMobile };
