import { ArticlesPage } from "website/pages/ArticlesPage";
import { CheckListPage } from "website/pages/CheckListPage";
import { LogoutPage } from "website/pages/LogoutPage";
import { MesServicesPage } from "website/pages/MesServicesPage";
import { MonPlanPage } from "website/pages/MonPlanPage";
import { ReformeDepartsAnticipesPage } from "website/pages/ReformeDepartsAnticipesPage";
import { SGAgendaPage } from "website/pages/SGAgendaPage";
import { SimulateurAvantApresPage } from "website/pages/SimulateurAvantApresPage";
import { SimulateurEconomiesImpotsPage } from "website/pages/SimulateurEconomiesImpotsPage";
import { SimulateurFlashPage } from "website/pages/SimulateurFlashPage";
import { TaxSimulatorPage } from "website/pages/TaxSimulatorPage";
import { ConstructionWorkProjectPage } from "website/pages/projects/ConstructionWorkProjectPage";
import { AdvicesPage } from "../pages/AdvicesPage";
import { AssetsPage } from "../pages/AssetsPage";
import { ChildSupportExpensePage } from "../pages/ChildSupportExpensePage";
import { DashboardPage } from "../pages/DashboardPage";
import { ExpensesPage } from "../pages/ExpensesPage";
import { IncomesPage } from "../pages/IncomesPage";
import { InheritanceSimulatorPage } from "../pages/InheritanceSimulatorPage";
import { LoansPage } from "../pages/LoansPage";
import { NewProjectPage } from "../pages/NewProjectPage";
import { ParcoursEpargnerPage } from "../pages/ParcoursEpargnerPage";
import { ParcoursRISPage } from "../pages/ParcoursRISPage";
import { ParcoursRevenusComplementairesPage } from "../pages/ParcoursRevenusComplementairesPage";
import { ProfilePage } from "../pages/ProfilePage";
import { ProjectsPage } from "../pages/ProjectsPage";
import { RealizedDonationsPage } from "../pages/RealizedDonationsPage";
import { SabbaticalLeavePage } from "../pages/SabbaticalLeavePage";
import { SimulationsPage } from "../pages/SimulationsPage";
import { SurvivingPartnerSimulatorPage } from "../pages/SurvivingPartnerSimulatorPage";
import { TaxCreditsPage } from "../pages/TaxCreditsPage";
import { TravelPage } from "../pages/TravelPage";
import { TunnelPage } from "../pages/TunnelPage";
import { AdditionalIncomeProjectPage } from "../pages/projects/AdditionalIncomeProjectPage";
import { CapitalGainProjectPage } from "../pages/projects/CapitalGainProjectPage";
import { ChangeLifeProjectPage } from "../pages/projects/ChangeLifeProjectPage";
import { ChildrenEducationProjectPage } from "../pages/projects/ChildrenEducationProjectPage";
import { ExceptionalExpenseProjectPage } from "../pages/projects/ExceptionalExpenseProjectPage";
import { GivenDonationProjectPage } from "../pages/projects/GivenDonationProjectPage";
import { RealEstateProjectPage } from "../pages/projects/RealEstateProjectPage";
import { ReceivedDonationProjectPage } from "../pages/projects/ReceivedDonationProjectPage";
import { RetirementProjectPage } from "../pages/projects/RetirementProjectPage";
import { TaxExemptionProjectPage } from "../pages/projects/TaxExemptionProjectPage";
import { WeddingProjectPage } from "../pages/projects/WeddingProjectPage";
import { MediaPage } from "website/pages/MediaPage/MediaPage";
import { ModifierProfilPage } from "website/pages/ModifierProfilPage";
import { SGAgendaConnexionPage } from "website/pages/SGAgendaConnexionPage";

// Pages de l'application
export const ADVICES = "/advices";
export const TUNNEL = "/tunnel";
export const EDIT_INCOMES = "/edit-incomes";
export const EDIT_EXPENSES = "/edit-expenses";
export const EDIT_TAX_CREDITS = "/edit-tax-credits";
export const EDIT_REALIZED_DONATIONS = "/edit-realized-donations";
export const EDIT_CHILD_SUPPORT_EXPENSES = "/edit-child-support-expense";
export const EDIT_LOANS = "/edit-loans";
export const EDIT_ASSETS = "/edit-assets";
export const SURVIVING_PARTNER_SIMULATOR = "/surviving-partner-simulator";
export const INHERITANCE_SIMULATOR = "/inheritance-simulator";
export const TAX_SIMULATOR = "/tax-simulator";
export const NEW_PROJECTS = "/new-projects";
export const PROJECTS = "/projects";
export const SIMULATIONS = "/simulations";
export const RETIREMENT_PROJECT = "/retirement-project";
export const REAL_ESTATE_PROJECT = "/real-estate-project";
export const CONSTRUCTION_WORK_PROJECT = "/construction-work";
export const EXCEPTIONAL_EXPENSE_PROJECT = "/exceptional-expense-project";
export const WEDDING_PROJECT = "/wedding-project";
export const CAPITAL_GAIN_PROJECT = "/capital-gain-project";
export const TAX_EXEMPTION_PROJECT = "/tax-exemption-project";
export const ADDITIONAL_INCOME_PROJECT = "/additional-income-project";
export const CHILDREN_EDUCATION_PROJECT = "/children-education-project";
export const RECEIVED_DONATION_PROJECT = "/received-donation-project";
export const GIVEN_DONATION_PROJECT = "/given-donation-project";
export const TRAVEL_PROJECT = "/travel-project";
export const SABBATICAL_LEAVE = "/sabbatical-leave-project";
export const CHANGE_LIFE_PROJECT = "/change-life-project";
export const SITUATION = "/situation";
export const SITUATION_FAMILY = "/situation/family";
export const SITUATION_NET_WORTH = "/situation/net-worth";
export const SITUATION_INCOMES = "/situation/incomes";
export const SITUATION_TAXATION = "/situation/taxation";
export const SITUATION_SUCCESSION = "/situation/succession";
export const DASHBOARD = "/dashboard";
export const ARTICLES = "/articles";
export const ARTICLE = "/articles/";
export const MON_PLAN = "/mon-plan";
export const MES_SERVICES = "/mes-services";
export const PARCOURS_EPARGNER = "/parcours-epargner";
export const PARCOURS_REVENUS_COMPLEMENTAIRES =
  "/parcours-revenus-complementaires";
export const PARCOURS_RIS = "/parcours-ris";
export const SIMULATEUR_FLASH = "/simulateur-flash";
export const MA_CHECKLIST = "/ma-checklist/:checkListType";
export const SIMULATEUR_ECONOMIES_IMPOTS = "/simulateur-economies-impots";
export const LOGOUT = "/logout";
export const SIMULATEUR_AVANT_APRES = "/simulateur-avant-apres";
export const REFORME_DEPART_ANTICIPE = "/reforme-departs-anticipes";
export const MODIFIER_PROFIL = "/modifier-profil";
export const SGAGENDA = "/sgagenda";
export const SGAGENDA_CONNEXION = "/sgagenda-connexion";

// Pop-ups
export const FEEDBACK_DIALOG = "feedback-dialog";
export const DIALOGS = [FEEDBACK_DIALOG];

// Pages bloquantes
export const PAGES_BLOQUANTES = [TUNNEL, REFORME_DEPART_ANTICIPE];

export const privateRoutes = [
  // Navigation
  { path: ADVICES, component: AdvicesPage },
  { path: TUNNEL, component: TunnelPage },
  // Revenus - Dépenses
  { path: EDIT_INCOMES, component: IncomesPage },
  { path: EDIT_EXPENSES, component: ExpensesPage },
  // Patrimoine
  { path: EDIT_LOANS, component: LoansPage },
  { path: EDIT_ASSETS, component: AssetsPage },
  // Fiscalité
  { path: EDIT_TAX_CREDITS, component: TaxCreditsPage },
  { path: EDIT_REALIZED_DONATIONS, component: RealizedDonationsPage },
  { path: EDIT_CHILD_SUPPORT_EXPENSES, component: ChildSupportExpensePage },
  // Simulateurs
  {
    path: SURVIVING_PARTNER_SIMULATOR,
    component: SurvivingPartnerSimulatorPage,
  },
  { path: INHERITANCE_SIMULATOR, component: InheritanceSimulatorPage },
  { path: TAX_SIMULATOR, component: TaxSimulatorPage },
  // Projets et Simulations
  { path: NEW_PROJECTS, component: NewProjectPage },
  { path: PROJECTS, component: ProjectsPage },
  { path: SIMULATIONS, component: SimulationsPage },
  // Créations de projet
  { path: RETIREMENT_PROJECT, component: RetirementProjectPage },
  { path: REAL_ESTATE_PROJECT, component: RealEstateProjectPage },
  { path: CONSTRUCTION_WORK_PROJECT, component: ConstructionWorkProjectPage },
  {
    path: EXCEPTIONAL_EXPENSE_PROJECT,
    component: ExceptionalExpenseProjectPage,
  },
  { path: WEDDING_PROJECT, component: WeddingProjectPage },
  { path: CAPITAL_GAIN_PROJECT, component: CapitalGainProjectPage },
  { path: TAX_EXEMPTION_PROJECT, component: TaxExemptionProjectPage },
  { path: ADDITIONAL_INCOME_PROJECT, component: AdditionalIncomeProjectPage },
  { path: CHILDREN_EDUCATION_PROJECT, component: ChildrenEducationProjectPage },
  { path: RECEIVED_DONATION_PROJECT, component: ReceivedDonationProjectPage },
  { path: GIVEN_DONATION_PROJECT, component: GivenDonationProjectPage },
  { path: TRAVEL_PROJECT, component: TravelPage },
  { path: SABBATICAL_LEAVE, component: SabbaticalLeavePage },
  { path: CHANGE_LIFE_PROJECT, component: ChangeLifeProjectPage },
  // pages profil
  { path: SITUATION_FAMILY, component: ProfilePage },
  { path: SITUATION_NET_WORTH, component: ProfilePage },
  { path: SITUATION_INCOMES, component: ProfilePage },
  { path: SITUATION_TAXATION, component: ProfilePage },
  { path: SITUATION_SUCCESSION, component: ProfilePage },
  { path: SITUATION, component: ProfilePage },
  { path: DASHBOARD, component: DashboardPage },
  {
    path: PARCOURS_RIS,
    component: ParcoursRISPage,
    errorPage: DASHBOARD,
  },
  { path: ARTICLES, component: ArticlesPage },
  { path: `${ARTICLE}:mediaPath`, component: MediaPage },
  {
    path: PARCOURS_REVENUS_COMPLEMENTAIRES,
    component: ParcoursRevenusComplementairesPage,
    errorPage: DASHBOARD,
  },
  { path: PARCOURS_EPARGNER, component: ParcoursEpargnerPage },
  { path: SGAGENDA, component: SGAgendaPage },
  {
    path: SGAGENDA_CONNEXION,
    component: SGAgendaConnexionPage,
    errorPage: DASHBOARD,
  },
  { path: MON_PLAN, component: MonPlanPage },
  { path: MA_CHECKLIST, component: CheckListPage },
  { path: MES_SERVICES, component: MesServicesPage },
  {
    path: SIMULATEUR_FLASH,
    component: SimulateurFlashPage,
    errorPage: DASHBOARD,
  },
  {
    path: SIMULATEUR_ECONOMIES_IMPOTS,
    component: SimulateurEconomiesImpotsPage,
    errorPage: DASHBOARD,
  },
  { path: LOGOUT, component: LogoutPage },
  {
    path: SIMULATEUR_AVANT_APRES,
    component: SimulateurAvantApresPage,
    errorPage: DASHBOARD,
  },
  { path: REFORME_DEPART_ANTICIPE, component: ReformeDepartsAnticipesPage },
  { path: MODIFIER_PROFIL, component: ModifierProfilPage },
];
