import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedCadenas } from "sg-icon-pack-base";
import { SGContent } from "sg-layout";
import { SGLogo } from "sg-logo";
import { useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { AccountState } from "../../../../../../../store/account/types";
import { logout } from "../../../../../../../store/login/actions";
import { State } from "../../../../../../../store/store";
import { ADVICES, PROJECTS, SIMULATIONS, SITUATION } from "../../../../../../utils/privateRoutes";
import { SGButtonIntl } from "../../../../../atoms/SGButtonIntl/SGButtonIntl";
import { HeaderNav } from "../../HeaderNav";
import "./HeaderBddf.scss";

const HeaderBddf: FunctionComponent = () => {
    const dispatch = useDispatch();
    const accountState = useSelector<State, AccountState>((state) => state.account);
    const areButtonsBigger = useMediaQuery({
        minwidth: "xl",
    });

    return (
        <>
            <div className="headerBddfBlack" />
            <div className="headerBddf">
                <SGContent disableautomargin>
                    <SGGridRow align="middle">
                        <SGGridCol textalign="left" span={4}>
                            <SGLogo baseline width={256} />
                        </SGGridCol>
                        <SGGridCol span={5}>
                            {accountState.account.tunnelCompleted && (
                                <SGSpace direction="horizontal" disableautomargin textalign="center" size="xl">
                                    <HeaderNav url={ADVICES} text="header.title.coach" buttonSelectedType="tertiary" buttonNotSelectedType="icon" />
                                    <HeaderNav url={SITUATION} text="header.title.profile" buttonSelectedType="tertiary" buttonNotSelectedType="icon" />
                                    <HeaderNav url={PROJECTS} text="header.title.projects" buttonSelectedType="tertiary" buttonNotSelectedType="icon" />
                                    <HeaderNav url={SIMULATIONS} text="header.title.simulations" buttonSelectedType="tertiary" buttonNotSelectedType="icon" />
                                </SGSpace>
                            )}
                        </SGGridCol>
                        <SGGridCol span={3} textalign="right">
                            <SGSpace direction="horizontal" disableautomargin align="center">
                                <SGButtonIntl
                                    onClick={() => dispatch(logout())}
                                    data-cy="logout"
                                    intlId={areButtonsBigger ? "header.title.logout.bddf" : ""}
                                    type="primary"
                                    size={areButtonsBigger ? "md" : "sm"}
                                    icon={<SGIcon currentcolor component={<SGAvenirStrokedCadenas />} />}
                                />
                            </SGSpace>
                        </SGGridCol>
                    </SGGridRow>
                </SGContent>
            </div>
        </>
    );
};

export { HeaderBddf };
