import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {ExpenseState} from "../../../store/expenses/types";
import {getSpendingProfileAction} from "../../../store/expenses/actions";

const useSpendingProfile = () => {
   const dispatch = useDispatch();
   const expenseState: ExpenseState = useSelector<State, ExpenseState>((state) => state.expenses);

   useEffect(() => {
      if (!expenseState.hasFetchedSpendingProfile) {
         dispatch(getSpendingProfileAction());
      }
   }, []);

   return expenseState.spendingProfile;
};

export {useSpendingProfile};
