import React, {FunctionComponent} from "react";
import "./HeaderDefault.scss";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../../../../store/store";
import {AccountState, isLoggedIn,} from "../../../../../../../store/account/types";
import {logout} from "../../../../../../../store/login/actions";
import Logo from "../../../../../../../assets/images/headers/logo-default.svg";
import {SGSpace} from "sg-space";
import {HeaderNav} from "../../HeaderNav";
import {ADVICES, PROJECTS, SIMULATIONS, SITUATION} from "../../../../../../utils/privateRoutes";
import {CGU} from "../../../../../../utils/publicRoutes";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGContent} from "sg-layout";

const HeaderDefault: FunctionComponent = () => {
   const dispatch = useDispatch();
   const accountState = useSelector<State, AccountState>((state) => state.account);

   return (
      <div className="headerDefault">
         <SGContent disableautomargin>
            <SGGridRow align="middle">
               <SGGridCol textalign="left" span={3}>
                  <img src={Logo} alt="logo_elyxir" width={180} height={60}/>
               </SGGridCol>
               <SGGridCol span={6}>
                  {accountState.account.tunnelCompleted &&
                     <SGSpace direction="horizontal" disableautomargin textalign="center" size="xl">
                        <HeaderNav url={ADVICES} text="header.title.coach" buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                        <HeaderNav url={SITUATION} text="header.title.profile" buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                        <HeaderNav url={PROJECTS} text="header.title.projects" buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                        <HeaderNav url={SIMULATIONS} text="header.title.simulations" buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     </SGSpace>}
               </SGGridCol>
               <SGGridCol span={3} textalign="right">
                  <SGSpace direction="horizontal" disableautomargin>
                     <HeaderNav url={CGU} text="header.title.cgu" buttonSelectedType="tertiary" buttonNotSelectedType="tertiarylight"/>
                     {isLoggedIn(accountState) && (
                        <HeaderNav onClick={() => dispatch(logout())} text="header.title.logout" buttonSelectedType="tertiary"
                                   buttonNotSelectedType="tertiarylight" cypressName="logout"/>
                     )}
                  </SGSpace>
               </SGGridCol>
            </SGGridRow>
         </SGContent>
      </div>
   )
};

export {HeaderDefault};
