import React, {FunctionComponent, useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import "rc-slider/assets/index.css";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {http} from "../../../utils/axios";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import Autosuggest from "react-autosuggest";
import {SelectInputButtons} from "../../atoms/SelectInputButtons/SelectInputButtons";
import {SGButtonGroup} from "sg-button";
import {SGBox, SGSpace} from "sg-space";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGBlock, SGText} from "sg-typo";
import Slider from "rc-slider";
import {useIntl} from "react-intl";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";

interface RealEstateSimulatorProps {
   priceMode?: boolean; // calculation of the price and ask to the use if they want to use it
   setPrice?: (price: number) => void;
   priceKnown: boolean | null;
   setPriceKnown: (isPriceKnown: boolean) => void;
   monthlyFeesKnown: boolean | null;
   setMonthlyFeesKnown: (areMonthlyFeesKnow: boolean) => void;
   givenPrice?: number; // mandatory for fees calculation
   setTransactionFees?: (transactionFees: number) => void;
   setMonthlyFees?: (monthlyFees: number) => void;
   displayMonthlyFees?: boolean;
   displayTransactionFees?: boolean;
   retrieveSurface?: (surface: string) => void;
   surface?: string;
   trackingName?: string;
}

const RealEstateType = {
   HOUSE: "HOUSE",
   APARTMENT: "APARTMENT",
};

type RealEstateType = typeof RealEstateType[keyof typeof RealEstateType];

interface RealEstate {
   location: string;
   surface: string;
   realEstateType: RealEstateType;
   aNewProperty: boolean;
   department: number;
   givenPrice: number;
}

interface City {
   name: string;
   departmentNumber: number;
}

const RealEstateSimulator: FunctionComponent<RealEstateSimulatorProps> = (props) => {
   const {
      priceMode = true,
      setPrice,
      setMonthlyFees,
      givenPrice,
      priceKnown,
      setPriceKnown,
      monthlyFeesKnown,
      setMonthlyFeesKnown,
      setTransactionFees,
      displayMonthlyFees = false,
      displayTransactionFees = false,
      retrieveSurface,
      surface,
      trackingName
   } = props;

   const intl = useIntl();

   const [cityList, setCityList] = useState<City[]>([]);
   const [location, setLocation] = useState<string>("");
   const [realEstate, setRealEstate] = useState<Partial<RealEstate>>({
      department: -1,
      realEstateType: RealEstateType.HOUSE,
      aNewProperty: false,
   });

   const [hasPriceData, setHasPriceData] = useState<boolean>(false);
   const [estimatedPrice, setEstimatedPrice] = useState<number>();
   const [estimatedMonthlyFees, setEstimatedMonthlyFees] = useState<number>();
   const [estimatedTransactionFees, setEstimatedTransactionFees] = useState<number>();
   const [lowerRangeEstimatedPrice, setLowerRangeEstimatedPrice] = useState<number>();
   const [upperRangeEstimatedPrice, setUpperRangeEstimatedPrice] = useState<number>();
   const [amountOfTransactions, setAmountOfTransactions] = useState<number>();
   const [hasLocationError, setHasLocationError] = useState<boolean>();

   useEffect(() => {
      if (surface) {
         realEstate.surface = surface;
      }
   }, [surface]);

   // TODO ELYXIR-10416 Passer par redux sagas
   const getCities = (value: { value: string }) => {
      setRealEstate((prevState) => ({
         ...prevState,
         location: "",
         department: -1,
      }));
      if (value.value.length >= 3) {
         http
            .get(`wealth/api/simulation/real-estate/cities/${value.value}`)
            .then((cities: AxiosResponse<City[]>) => {
               setCityList(cities.data);
            });
      } else {
         setCityList([]);
      }
   };

   const suggestionToString = (suggestion: City) => `${suggestion.name} (${suggestion.departmentNumber})`;

   const getSuggestionValue = (suggestion: City) => {
      setRealEstate((prevState) => ({
         ...prevState,
         location: suggestion.name,
         department: suggestion.departmentNumber,
      }));

      return suggestionToString(suggestion);
   };

   const handleSurfaceInput = (surface: number) => {
      setRealEstate((prevState) => ({...prevState, surface: String(surface)}));
   };

   const isInvalidRealEstate = () => {
      setHasLocationError(false);
      setHasPriceData(false);
      if (priceMode && (!realEstate.location || realEstate.location === "" || realEstate.department === -1)) {
         setHasLocationError(true);

         return true;
      }

      return !realEstate.surface;
   };

   const pushEstimation = () => {
      setPriceKnown(true);
      if (setPrice && estimatedPrice) {
         setPrice(estimatedPrice);
      }

      if (setMonthlyFees && estimatedMonthlyFees) {
         setMonthlyFees(estimatedMonthlyFees);
      }
      if (setTransactionFees && estimatedTransactionFees) {
         setTransactionFees(estimatedTransactionFees);
      }

      return true;
   };

   const estimatePrice = () => {
      // TODO Vérification des paramètres isValidRealEstate
      if (isInvalidRealEstate()) {
         return;
      }
      if (retrieveSurface && realEstate.surface) {
         retrieveSurface(realEstate.surface);
      }
      let apiUrl: string;
      if (priceMode) {
         apiUrl = "wealth/api/simulation/real-estate/price";
      } else {
         apiUrl = "wealth/api/simulation/real-estate/fees";
         realEstate.givenPrice = givenPrice;
      }
      http.post(apiUrl, realEstate).then((res) => {
         setHasPriceData(true);
         setEstimatedPrice(res.data.price.estimatedPrice);
         setLowerRangeEstimatedPrice(res.data.price.lowerRangeEstimatedPrice);
         setUpperRangeEstimatedPrice(res.data.price.upperRangeEstimatedPrice);
         setAmountOfTransactions(res.data.price.amountOfTransactions);
         setEstimatedTransactionFees(res.data.fees.transaction);
         setEstimatedMonthlyFees(res.data.fees.recurrent);
         if (!priceMode) {
            setMonthlyFeesKnown(true);
            if (setMonthlyFees) {
               setMonthlyFees(res.data.fees.recurrent);
            }
            if (setTransactionFees) {
               setTransactionFees(res.data.fees.transaction);
            }
         }
      });
   };

   const simulator = () => (
      <SGSpace direction="vertical">
         <SGTextIntl intlId="realEstateSimulator.location.label"/>
         <div data-cy={`real-estate-simulator-city-picker${priceMode ? "" : "-with-fees"}`}>
            {/* @ts-ignore */}
            <Autosuggest suggestions={cityList} onSuggestionsClearRequested={() => setCityList([])} onSuggestionsFetchRequested={getCities}
                         getSuggestionValue={getSuggestionValue} renderSuggestion={(suggestion: City) => (
               <div>{suggestionToString(suggestion)}</div>
            )} inputProps={{
               placeholder: "Ex: LYON",
               value: location,
               onChange: (_, {newValue}) => {
                  setLocation(newValue);
               },
            }}/>

         </div>
         <SGBox padding={{top: "sm", bottom: "xs"}}>
            <NumericInput cypressName="real-estate-simulator-surface" defaultValue={!realEstate.surface ? undefined : +realEstate.surface}
                       name="realEstateSurface" onValueChange={handleSurfaceInput} min={10} max={1000}
                       suffix="m²" label="realEstateSimulator.surface.label"
                       placeholder={intl.formatMessage({id: "realEstateSimulator.surface.placeholder"})}/>
         </SGBox>
         <SelectInputButtons cypressName="homeSituation-real-estate-type" labels={["realEstateSimulator.house", "realEstateSimulator.apartment"]}
                             choices={[RealEstateType.HOUSE, RealEstateType.APARTMENT]} choice={realEstate.realEstateType} size="sm" direction="horizontal"
                             trackingName={trackingName}
                             setChoice={(val: string) => {
                                setRealEstate({...realEstate, realEstateType: val});
                             }}
         />

         {displayErrors()}

         <SGButtonGroup>
            <SGButtonIntl type="primary" size="sm" disabled={!realEstate.surface || !realEstate.location || +realEstate.surface < 10}
                          cypressName="real-estate-simulator-calculate" onClick={estimatePrice}
                          intlId="realEstateSimulator.calculate.label"/>
         </SGButtonGroup>
      </SGSpace>
   );

   const displayErrors = () => (
      <div>
         {hasLocationError && (
            <div>
               <SGTextIntl intlId="realEstateSimulator.location.error" color="error"/>
            </div>
         )}
      </div>
   );

   const displayPriceResult = () => (
      <SGSpace direction="vertical">
         <SGTextIntl intlId="realEstateSimulator.results.label"/>
         <SGGridRow>
            <SGGridCol span={6} textalign="left">
               <SGText italic>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={lowerRangeEstimatedPrice}/>
               </SGText>
            </SGGridCol>
            <SGGridCol span={6} textalign="right" align="end">
               <SGText italic>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={upperRangeEstimatedPrice}/>
               </SGText>
            </SGGridCol>
         </SGGridRow>
         <SGBlock style={{width: "100%"}}>
            {/* @ts-ignore */}
            <Slider min={lowerRangeEstimatedPrice} max={upperRangeEstimatedPrice} value={estimatedPrice} onChange={(value) => setEstimatedPrice(value)}
                    step={1000}/>
         </SGBlock>
         <SGBlock>
            <SGTextIntl intlId="realEstateSimulator.results.estimatedPrice"/>
            <SGText strong>
               <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={estimatedPrice}/>
            </SGText>
         </SGBlock>
         <SGBlock>
            <SGTextIntl
               intlId="realEstateSimulator.results.amount-of-transactions"
               transformations={{amountOfTransactions: <SGText strong>{amountOfTransactions}</SGText>}}/>
         </SGBlock>
      </SGSpace>
   );

   const results = () => (
      <>
         {amountOfTransactions === 0 &&
            <SGTextIntl intlId="form.asset.error.no-transactions" color="error"/>
         }
         {amountOfTransactions !== 0 &&
            <div>
               {priceMode && displayPriceResult()}
               {displayTransactionFees && (
                  <div>
                <span>
                  <SGTextIntl intlId="realEstateSimulator.results.fees.transaction"/>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={estimatedTransactionFees}/>
                </span>
                  </div>
               )}
               {displayMonthlyFees && (
                  <div>
                <span>
                  <SGTextIntl intlId="realEstateSimulator.results.fees.monthly"/>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={estimatedMonthlyFees}/>
                </span>
                  </div>
               )}
               {priceMode && (
                  <SGSpace direction="vertical">
                     <SGButtonGroup align="right">
                        <SGButtonIntl type="primary" size="sm" cypressName="real-estate-simulator-use-price" onClick={pushEstimation}
                                      intlId="realEstateSimulator.results.usePrice"/>
                     </SGButtonGroup>
                     <SGTextIntl intlId="realEstateSimulator.results.legal.title" strong/>
                     <SGTextIntl intlId="realEstateSimulator.results.legal.phrase1"/>
                     <SGTextIntl intlId="realEstateSimulator.results.legal.phrase2"/>
                  </SGSpace>
               )}
            </div>
         }
      </>
   );

   const containerTitleIdNoPriceMode = displayTransactionFees ? "realEstateSimulator.transactionFeesQuestion" : "realEstateSimulator.feesQuestion";

   return (
      <SGSpace direction="vertical">
         <SGTextIntl intlId={priceMode ? "realEstateSimulator.priceQuestion" : containerTitleIdNoPriceMode}/>
         {priceMode && (
            <SelectInputButtons cypressName="real-estate-simulator-priceKnown" labels={["common.yes", "common.no"]} size="sm"
                                choices={["true", "false"]} choice={String(priceKnown)} setChoice={(choice: string) => {
               setPriceKnown(choice === "true")
            }}
                                direction="horizontal"/>
         )}
         {!priceMode && (
            <SelectInputButtons cypressName="real-estate-simulator-monthlyFees" labels={["common.yes", "common.no"]} size="sm"
                                choices={["true", "false"]} direction="horizontal"
                                choice={String(monthlyFeesKnown)} setChoice={(choice: string) => setMonthlyFeesKnown(choice === "true")}/>
         )}
         {priceMode && priceKnown === false && simulator()}
         {priceMode && priceKnown === false && hasPriceData && results()}
         {!priceMode && monthlyFeesKnown === false && simulator()}
         {!priceMode && monthlyFeesKnown === false && hasPriceData && results()}
      </SGSpace>
   );
}

export {RealEstateSimulator};
