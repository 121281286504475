import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountAction } from "store/account/actions";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { callRetirementSimulation, updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters, RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import { saveWorkIncomesHistoryAction } from "store/workIncomesHistory/action";
import { WorkIncomeRaisesType, WorkIncomeRaisesTypeSimulation, WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useAccount } from "website/components/hooks/useAccount";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { WorkIncomesForecast } from "../../../WorkIncomesForecast/WorkIncomesForecast";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const TunnelWorkIncomesForecast: FunctionComponent = () => {
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const account = useAccount();
    const { trackClick, trackPage } = useTracking();

    const workIncomesHistoryData: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);

    const [pastForecast, setPastForecast] = useState<WorkIncomeRaisesType>();
    const [futureForecast, setFutureForecast] = useState<WorkIncomeRaisesType>();
    const [hasSubmitStep, setHasSubmitStep] = useState<boolean>(false);
    const [callTimer, setCallTimer] = useState<number>(0);
    const [errorHappened, setErrorHappened] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );
    const isTunnelReforme = useSelector<State, boolean>((state) => state.tunnel.isTunnelReforme);

    const onSubmit = () => {
        if (pastForecast && futureForecast) {
            trackClick(`mon-evolution::clic-sur-${isTunnelReforme ? "j-accede-a-mes-resultats" : "j-estime-ma-pension"}`);
            setHasSubmitStep(true);
            dispatch(
                updateRetirementSimulation({
                    ...simulationAssuState.retirementSimulationParameters,
                    evolSalaireFutur: WorkIncomeRaisesTypeSimulation[futureForecast],
                    evolSalairePasse: WorkIncomeRaisesTypeSimulation[pastForecast],
                    genre: "m",
                    idSimulSimple: "",
                    simulationErrorCode: undefined,
                    simulationErrorMessage: undefined,
                })
            );
            const workIncomesHistory: WorkIncomesHistory = {
                ...workIncomesHistoryData.workIncomesHistory,
                pastYearlyIncrease: pastForecast,
                expectedYearlyIncrease: futureForecast,
            };
            dispatch(saveWorkIncomesHistoryAction(workIncomesHistory));
        }
    };

    useEffect(() => {
        trackPage("parcours-onboarding", "mon-evolution", PAGE_TYPE_FORMULAIRE, "inscription", "4");
    }, []);

    useEffect(() => {
        if (
            hasSubmitStep &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple === "" &&
            simulationAssuState.retirementSimulationParameters.simulationErrorCode === undefined
        ) {
            setCallTimer((new Date()).getTime());
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            dispatch(callRetirementSimulation(simulationAssuState.retirementSimulationParameters as RetirementSimulationParameters));
        }
    }, [hasSubmitStep, simulationAssuState.retirementSimulationParameters]);

    useEffect(() => {
        if (
            hasSubmitStep &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple !== ""
        ) {
            const newTimer = (new Date()).getTime();
            saveEvent(trackingEvent.ONBOARDING_SAPIENDO_OK, `${newTimer - callTimer} ms`);
            dispatch(updateAccountAction({ ...account, tunnelCompleted: true }));
            // Mise à jour des assets depuis wealth après leur alimentation via scrapping
            // Et après avoir réalisé la simulation
            dispatch(getAssetsWithoutLoans());
            dispatch(getAssetsIncomes());
        } else if (simulationAssuState.retirementSimulationParameters.simulationErrorCode !== undefined) {
            saveEvent(trackingEvent.ONBOARDING_SAPIENDO_KO,
                `[${simulationAssuState.retirementSimulationParameters.simulationErrorCode}] ${simulationAssuState.retirementSimulationParameters.simulationErrorMessage}`);
            // Cas d'erreur. Annulation
            setHasSubmitStep(false);
            handleSetVisible(true);
        }
    }, [simulationAssuState.retirementSimulationParameters]);

    const handleSetVisible = (visible: boolean) => {
        setErrorHappened(visible);
        setShowErrorModal(visible);
    };

    return (
        <WorkIncomesForecast
            onSubmit={onSubmit}
            pastForecast={pastForecast}
            setPastForecast={setPastForecast}
            futureForecast={futureForecast}
            setFutureForecast={setFutureForecast}
            hasSubmitStep={hasSubmitStep}
            errorHappened={errorHappened}
            showErrorModal={showErrorModal}
            handleError={handleSetVisible}
        />
    );
};

export { TunnelWorkIncomesForecast };
