import debounce from "lodash.debounce";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGCard } from "sg-card";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { updatePrefs } from "store/dashboard/actions";
import { DashboardAge, User, hasFetchedDashboard } from "store/dashboard/types";
import { getMonthlyIncome, getSavingEffort } from "store/savingPath/actions";
import {
    MonthlyIncomeRequest,
    SavingEffortRequest,
    SavingPathState,
    convertAgeTypeToWealthProfile,
    hasFetchedSavingPathMonthlyEffort,
    hasFetchedSavingPathObjective,
} from "store/savingPath/types";
import { State } from "store/store";
import { SGButtonIntl } from "website/components/atoms/SGButtonIntl/SGButtonIntl";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { PARCOURS_EPARGNER } from "website/utils/privateRoutes";
import "./EffortEpargne.scss";
import { EffortEpargneTab } from "./EffortEpargneTab/EffortEpargneTab";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const EFFORT_EPARGNE_TAB_OBJECTIF = "objectif";
export const EFFORT_EPARGNE_TAB_EPARGNE = "epargne";

interface EffortEpargneProps {
    selectedRetirementAge: DashboardAge;
}

/**
 * Composant chapeau de l'objectif/épargne du Dashboard.
 * Permet de gérer le fonctionnel et la gestion des deux onglets.
 */
const EffortEpargne: FunctionComponent<EffortEpargneProps> = (props: EffortEpargneProps) => {
    const { selectedRetirementAge } = props;
    const savingPathState = useSelector<State, SavingPathState>((state) => state.savingPath);
    const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
    const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);
    const dashboardState = useDashboardState();
    const { maxObjectifInput, maxEpargneInput } = useMontantsMax();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

    const { assets } = assetState;
    const { assetIncomes } = assetIncomesState;

    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(EFFORT_EPARGNE_TAB_OBJECTIF);
    const [fromObjectif, setFromObjectif] = useState(true);
    const [wealthProfile, setWealthProfile] = useState<string>(() => convertAgeTypeToWealthProfile(selectedRetirementAge.type));

    const [objectifRevenu, setObjectifRevenu] = useState<number>();
    const [savedObjectifRevenu, setSavedObjectifRevenu] = useState<number>();

    const [montantEpargne, setMontantEpargne] = useState<number>();
    const [savedMontantEpargne, setSavedMontantEpargne] = useState<number>();

    const [revenuAFinancer, setRevenuAFinancer] = useState<number>(0);

    const [hasChangedValueObjectifRevenu, setHasChangedValueObjectifRevenu] = useState<boolean>(false);
    const [hasChangedValueMontantEpargne, setHasChangedValueMontantEpargne] = useState<boolean>(false);

    const history = useHistory();
    const intl = useIntl();
    const { trackClick, trackDisclaimer } = useTracking();

    /**
     * Init avec infos du dashboard => Mise à jour de l'objectif et récupération de l'effort d'épargne (ou l'inverse)
     */
    useEffect(() => {
        if (hasFetchedDashboard(dashboardState) && savedObjectifRevenu === undefined) {
            if (dashboardState.dashboard.monthlyIncomeGoal !== undefined) {
                setObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
                setSavedObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
                calculateRevenuAFinancer(dashboardState.dashboard.monthlyIncomeGoal ?? 0);
            }

            // On initialise l'effort d'épargne si l'objectif de revenu est défini
            // sinon on initialise les revenus de retraite sans effort d'épargne
            const firstObjective = dashboardState.dashboard.monthlyIncomeGoal ?? 0;
            if (firstObjective > 0) {
                // On passe le même objectif pour éviter la persistance inutile
                callSavingPathMotor(firstObjective, firstObjective, false, wealthProfile);
            }
        }
    }, [dashboardState]);

    useEffect(() => {
        if (!assetState.hasFetched) {
            dispatch(getAssetsWithoutLoans());
        }
        if (!hasFetchedAssets(assetIncomesState)) {
            dispatch(getAssetsIncomes());
        }
    }, []);

    useEffect(() => {
        if (assetState && hasFetchedAssets(assetIncomesState)) {
            calculateRevenuAFinancer(objectifRevenu ?? 0);
        }
    }, [assetState, assetIncomesState]);

    useEffect(() => {
        if (objectifRevenu !== undefined) {
            const wp = convertAgeTypeToWealthProfile(selectedRetirementAge.type);
            if (wp !== wealthProfile) {
                if (fromObjectif) {
                    debouncedCallEffortEpargne(objectifRevenu ?? 0, savedObjectifRevenu ?? 0, !fromObjectif, wp);
                } else {
                    debouncedCallEffortEpargne(montantEpargne ?? 0, savedMontantEpargne ?? 0, !fromObjectif, wp);
                }
            }
            setWealthProfile(wp);
            calculateRevenuAFinancer(objectifRevenu);
        }
    }, [selectedRetirementAge]);

    // Changement d'âge sur le Dashboard ou de montantEpargne
    useEffect(() => {
        if (objectifRevenu === undefined && montantEpargne === undefined) {
            return;
        }

        // Si on édite l'objectif, on calcule le montant d'épargne
        if (fromObjectif && savedObjectifRevenu !== objectifRevenu) {
            debouncedCallEffortEpargne(objectifRevenu ?? 0, savedObjectifRevenu ?? 0, false, wealthProfile);
            calculateRevenuAFinancer(objectifRevenu ?? 0);
        } else if (!fromObjectif && savedMontantEpargne !== montantEpargne) {
            // Si on édite le montant d'épargne, on calcule l'objectif
            debouncedCallEffortEpargne(montantEpargne ?? 0, savedMontantEpargne ?? 0, true, wealthProfile);
        }
    }, [objectifRevenu, montantEpargne]);

    useEffect(() => {
        if (hasChangedValueObjectifRevenu === true) {
            trackDisclaimer(`disclaimer-resultats-saisie-objectif-revenus`);
        }
    }, [hasChangedValueObjectifRevenu]);
    useEffect(() => {
        if (hasChangedValueMontantEpargne === true) {
            trackDisclaimer(`disclaimer-resultats-saisie-objectif-epargne`);
        }
    }, [hasChangedValueMontantEpargne]);

    // Récupération de l'objectif ou du revenu via le moteur
    useEffect(() => {
        // On ne met à jour que la valeur du bas
        // Si on a changé d'onglet, on ne met pas à jour les données ici
        if (fromObjectif && objectifRevenu !== undefined && hasFetchedSavingPathMonthlyEffort(savingPathState)) {
            const newEffort = roundDown10(savingPathState.monthlyEffort);
            setMontantEpargne(newEffort);
            // Appel OK, on sauvegarde l'objectif saisi
            setSavedObjectifRevenu(objectifRevenu);

            setSavedMontantEpargne(newEffort);
        } else if (!fromObjectif && hasFetchedSavingPathObjective(savingPathState)) {
            const newObjective = roundDown10(savingPathState.objective);
            setObjectifRevenu(newObjective);
            // Appel OK, on sauvegarde le montant saisi
            setSavedMontantEpargne(montantEpargne);

            setSavedObjectifRevenu(newObjective);
            saveUserRetirementPathObjective(newObjective);
            calculateRevenuAFinancer(newObjective);
        }
    }, [savingPathState]);

    const changeTab = (target: string) => {
        if (target !== currentTab) {
            setCurrentTab(target);
            // Tracking [Onglet] Changement d'onglet "revenus" / "épargne"
            trackClick(`clic-sur-onglet-${target === EFFORT_EPARGNE_TAB_OBJECTIF ? "mon-objectif-de-revenus" : "mon-epargne"}`);
            const newFromObjectif = target === EFFORT_EPARGNE_TAB_OBJECTIF;
            setFromObjectif(newFromObjectif);

            // On arrondit l'autre valeur au changement d'onglet, pour ne pas avoir d'unité
            // Seulement si elle est définie
            if (fromObjectif && montantEpargne) {
                roundMontantEpargne(montantEpargne ?? 0);
            } else if (!fromObjectif && objectifRevenu) {
                roundObjectifRevenu(objectifRevenu ?? 0);
            }

            // Si changement d'onglet pendant un recalcul, on remet l'ancienne valeur
            if (!newFromObjectif && objectifRevenu !== savedObjectifRevenu) {
                setObjectifRevenu(savedObjectifRevenu);
            } else if (newFromObjectif && montantEpargne !== savedMontantEpargne) {
                setMontantEpargne(savedMontantEpargne);
            }
        }
    };

    /**
     * Calcul du revenu à financer en fonction de l'objectif, du patrimoine et de la pension de retraite.
     */
    const calculateRevenuAFinancer = (objectif: number) => {
        let total = objectif;
        if (selectedRetirementAge.retirementMonthlyIncome >= 0) {
            total -= roundDown10(selectedRetirementAge.retirementMonthlyIncome);
        }
        const additionalIncome = roundDown10(computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge));
        total -= additionalIncome;
        if (total > 0) {
            setRevenuAFinancer(total);
        } else {
            setRevenuAFinancer(0);
        }
    };

    const roundObjectifRevenu = (value: number) => {
        setObjectifRevenu(roundDown10(value));
    };

    const roundMontantEpargne = (value: number) => {
        setMontantEpargne(roundDown10(value));
    };

    const saveUserRetirementPathObjective = (newObjective: number) => {
        const user: User = {
            monthlyIncomeGoal: newObjective,
        };
        dispatch(updatePrefs(user));
    };

    /**
     * Décale l'appel au moteur et annule les appels successifs trop courts
     */
    const debouncedCallEffortEpargne = useCallback(
        debounce((val: number, oldVal: number, fromMonthlyEffort: boolean, wp: string) => callSavingPathMotor(val, oldVal, fromMonthlyEffort, wp), 400),
        [] // will be created only once initially
    );

    /**
     * Appelle le moteur dans un sens ou dans l'autre
     *
     * @param val Valeur à envoyer
     * @param fromMonthlyEffort Si oui, on envoie l'effort d'épargne
     * @param wp Profil Wealth
     */
    const callSavingPathMotor = (val: number, oldVal: number, fromMonthlyEffort: boolean, wp: string) => {
        if (fromMonthlyEffort) {
            const req: MonthlyIncomeRequest = {
                profile: wp,
                monthlySavingEffort: val,
            };
            dispatch(getMonthlyIncome(req));
        } else {
            // On ne sauvegarde que si l'objectif a changé
            if (val !== oldVal) {
                saveUserRetirementPathObjective(val);
            }
            const req: SavingEffortRequest = {
                profile: wp,
                monthlyIncomeObjective: val,
            };
            dispatch(getSavingEffort(req));
        }
    };

    const handleRedirectParcoursEpargner = () => {
        // Tracking [Clic] Clic sur "modifier le détail de l'épargne"
        trackClick("clic-sur-modifier-le-detail-de-l-epargne");
        history.push(PARCOURS_EPARGNER);
    };

    return (
        <div className={isPhone ? "effort-epargne" : ""}>
            <SGBox
                margin={isPhone ? {
                    bottom: "sm",
                } : {
                    top: "md",
                    bottom: "sm",
                }}
            >
                <SGCard>
                    <SGTabs disableautomargin activeKey={currentTab} onChange={(onglet: string) => changeTab(onglet)}>
                        <SGTabPane
                            ariaId={EFFORT_EPARGNE_TAB_OBJECTIF}
                            tab={intl.formatMessage({ id: `dashboard.effortEpargne.objectif.tab` })}
                            key={EFFORT_EPARGNE_TAB_OBJECTIF}
                        >
                            {currentTab === EFFORT_EPARGNE_TAB_OBJECTIF && (
                                <EffortEpargneTab
                                    currentTab={currentTab}
                                    editableValue={objectifRevenu}
                                    setEditableValue={setObjectifRevenu}
                                    maxValueInput={maxObjectifInput}
                                    otherValue={montantEpargne}
                                    revenuAFinancer={revenuAFinancer}
                                    setHasChangedValue={setHasChangedValueObjectifRevenu}
                                />
                            )}
                        </SGTabPane>
                        <SGTabPane
                            ariaId={EFFORT_EPARGNE_TAB_EPARGNE}
                            tab={intl.formatMessage({ id: `dashboard.effortEpargne.epargne.tab` })}
                            key={EFFORT_EPARGNE_TAB_EPARGNE}
                        >
                            {currentTab === EFFORT_EPARGNE_TAB_EPARGNE && (
                                <EffortEpargneTab
                                    currentTab={currentTab}
                                    editableValue={montantEpargne}
                                    setEditableValue={setMontantEpargne}
                                    maxValueInput={maxEpargneInput}
                                    otherValue={objectifRevenu}
                                    revenuAFinancer={revenuAFinancer}
                                    setHasChangedValue={setHasChangedValueMontantEpargne}
                                />
                            )}
                        </SGTabPane>
                    </SGTabs>

                    <SGBox alignchildren={["center"]} margin={{ top: isPhone ? "md" : "sm" }}>
                        <SGButtonIntl
                            intlId="dashboard.effortEpargne.button.epargne.detail"
                            type="secondary"
                            onClick={handleRedirectParcoursEpargner}
                            size={isPhone && ["md", "sm"]}
                        />
                    </SGBox>
                </SGCard>
            </SGBox>
        </div>
    );
};

export { EffortEpargne };
