import {Income, IncomeType} from "../incomes/types";
import {
   ADDITIONAL_INCOME_PROJECT,
   CAPITAL_GAIN_PROJECT,
   CHANGE_LIFE_PROJECT,
   CHILDREN_EDUCATION_PROJECT,
   CONSTRUCTION_WORK_PROJECT,
   EDIT_ASSETS,
   EDIT_INCOMES,
   EXCEPTIONAL_EXPENSE_PROJECT,
   FEEDBACK_DIALOG,
   GIVEN_DONATION_PROJECT,
   INHERITANCE_SIMULATOR,
   NEW_PROJECTS,
   PROJECTS,
   REAL_ESTATE_PROJECT,
   RECEIVED_DONATION_PROJECT,
   RETIREMENT_PROJECT,
   SIMULATIONS,
   SITUATION,
   SITUATION_FAMILY,
   SITUATION_INCOMES,
   SITUATION_NET_WORTH,
   SITUATION_SUCCESSION,
   SITUATION_TAXATION,
   TAX_EXEMPTION_PROJECT,
   TRAVEL_PROJECT,
   WEDDING_PROJECT
} from "../../website/utils/privateRoutes";
import {calculateAgeByBirthdate, getAvailableCustodyTypes, toMembers} from "../members/utils";
import {getAlertsByAge, LIMIT_AGE_FOR_RETIREMENT_ACHIEVEMENT} from "../simulation/utils";
import {LIFE_PROJECTS, ProjectType} from "../projects/types";
import {MaritalStatus} from "../members/types";
import {AssetWithValuation} from "../assets/types";
import {Loan} from "../loans/types";
import {CoachAdvice, CoachAdviceType, CoachState} from "./types";
import {State} from "../store";
import {Expense} from "../expenses/types";
import {AlertType, INCOMES_EXPENSES_ALERTS, SIMULATION_ALERTS} from "../simulation/types";

export const computeCoachAdvices = (store: State, location: string): CoachState => {
   const coachAdvicesToReturn: CoachState = {advices: []};

   // TUTORIEL
   if (!store.account.account.completedTutorial) {
      if (store.incomes.hasFetched && store.family.hasFetched && !store.incomes.incomes.some((i) => ((i.type === IncomeType.WORK_INCOME && i.monthlyAmount !== 0) || i.type === IncomeType.RETIREMENT_INCOME) && i.memberId === store.family.family.me.id)) {
         if (location === EDIT_INCOMES) {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-incomes.page",
               type: CoachAdviceType.TUTORIAL
            });
         } else {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-incomes",
               type: CoachAdviceType.TUTORIAL,
               ctaIntlId: "coach.advices.cta.input",
               redirection: EDIT_INCOMES
            });
         }
      }
      if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && store.assets.hasFetched && store.assets.assets.length === 0) {
         if (location === EDIT_ASSETS) {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-assets.page",
               type: CoachAdviceType.TUTORIAL
            });
         } else {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-assets",
               type: CoachAdviceType.TUTORIAL,
               ctaIntlId: "coach.advices.cta.input",
               redirection: EDIT_ASSETS
            });
         }
      }
      if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && store.family.hasFetched && calculateAgeByBirthdate(store.family.family.me.birthday) > LIMIT_AGE_FOR_RETIREMENT_ACHIEVEMENT) {
         if (store.projects.hasFetchedProjects && !store.projects.projects.some((p) => (p.projectType === ProjectType.RETIREMENT_PROJECT))) {
            if (location === RETIREMENT_PROJECT) {
               coachAdvicesToReturn.advices.push({
                  intlId: "coach.advices.tutorial.no-retirement-project.page",
                  type: CoachAdviceType.TUTORIAL
               });
            } else {
               coachAdvicesToReturn.advices.push({
                  intlId: "coach.advices.tutorial.no-retirement-project",
                  type: CoachAdviceType.TUTORIAL,
                  ctaIntlId: "coach.advices.cta.create",
                  redirection: RETIREMENT_PROJECT
               });
            }
         }
      }
      if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && store.projects.hasFetchedProjects && !store.projects.projects.some((p) => (p.projectType !== ProjectType.RETIREMENT_PROJECT && LIFE_PROJECTS.includes(p.projectType)))) {
         if (location === NEW_PROJECTS) {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-projects.page",
               type: CoachAdviceType.TUTORIAL
            });
         } else if ([RETIREMENT_PROJECT, REAL_ESTATE_PROJECT, EXCEPTIONAL_EXPENSE_PROJECT, WEDDING_PROJECT, CAPITAL_GAIN_PROJECT, TAX_EXEMPTION_PROJECT, ADDITIONAL_INCOME_PROJECT, CHILDREN_EDUCATION_PROJECT, RECEIVED_DONATION_PROJECT, GIVEN_DONATION_PROJECT, TRAVEL_PROJECT, CHANGE_LIFE_PROJECT].includes(location)) {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-projects.page2",
               type: CoachAdviceType.TUTORIAL
            });
         } else {
            coachAdvicesToReturn.advices.push({
               intlId: "coach.advices.tutorial.no-projects",
               type: CoachAdviceType.TUTORIAL,
               ctaIntlId: "coach.advices.cta.create",
               redirection: NEW_PROJECTS
            });
         }
      }
      if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && store.account.hasFetched && !store.account.account.visitedTaxSimulator) {
         coachAdvicesToReturn.advices.push({
            intlId: "coach.advices.tutorial.no-visit-tax-simulator",
            type: CoachAdviceType.TUTORIAL,
            ctaIntlId: "coach.advices.cta.default",
            redirection: SITUATION_TAXATION
         });
      }
      if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && store.account.hasFetched && !store.account.account.visitedSuccessionSimulator) {
         coachAdvicesToReturn.advices.push({
            intlId: "coach.advices.tutorial.no-visit-succession-simulator",
            type: CoachAdviceType.TUTORIAL,
            ctaIntlId: "coach.advices.cta.default",
            redirection: INHERITANCE_SIMULATOR
         });
      }
      if ([SITUATION, SITUATION_TAXATION, SITUATION_FAMILY, SITUATION_INCOMES, SITUATION_NET_WORTH, SITUATION_SUCCESSION].includes(location)) {
         coachAdvicesToReturn.advices.push({
            intlId: `coach.advices.tutorial.page.all-situations`,
            type: CoachAdviceType.TUTORIAL
         });
      }
      if ([RETIREMENT_PROJECT, REAL_ESTATE_PROJECT, CONSTRUCTION_WORK_PROJECT, EXCEPTIONAL_EXPENSE_PROJECT, WEDDING_PROJECT, CAPITAL_GAIN_PROJECT, TAX_EXEMPTION_PROJECT, ADDITIONAL_INCOME_PROJECT, CHILDREN_EDUCATION_PROJECT, RECEIVED_DONATION_PROJECT, GIVEN_DONATION_PROJECT, TRAVEL_PROJECT, CHANGE_LIFE_PROJECT].includes(location)) {
         coachAdvicesToReturn.advices.push({
            intlId: "coach.advices.tutorial.page.project-input",
            type: CoachAdviceType.TUTORIAL
         });
      } else {
         coachAdvicesToReturn.advices.push({
            intlId: `coach.advices.tutorial.page${location.replace(/\//g, ".")}`,
            type: CoachAdviceType.TUTORIAL
         });
      }

      if (location === PROJECTS && store.projects.projects.filter((p) => LIFE_PROJECTS.includes(p.projectType)).length !== 0) {
         coachAdvicesToReturn.advices.push({
            intlId: `coach.advices.tutorial.page.projects-non-empty`,
            type: CoachAdviceType.TUTORIAL
         });
      }
   }

   if (store.account.account.completedTutorial && !store.feedback.hasSentFeedback) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.advices.give-feedback",
         type: CoachAdviceType.OTHER,
         ctaIntlId: "coach.advices.cta.give-feedback",
         redirection: FEEDBACK_DIALOG
      });
   }

   // WARNING DE SAISIE DE SITUATION
   // On affiche un avertissement si les dépenses de l'utilisateur dépassent ses revenus
   const totalIncomes = store.incomes.incomes.reduce((sum: number, cur: Income) => sum + cur.monthlyAmount, 0);
   const totalExpenses = store.expenses.expenses.filter((expense) => !expense.type).reduce((sum: number, cur: Expense) => sum + cur.monthlyAmount, 0);
   if (store.expenses.hasFetchedExpenses && store.incomes.hasFetched && totalExpenses > totalIncomes) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.more-expenses-than-income",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.change",
         redirection: SITUATION_INCOMES
      });
   }

   // On affiche un avertissement si le PARTNER n'a pas de revenu
   if (store.family.hasFetched && store.incomes.hasFetched && !!store.family.family.partner && !store.incomes.incomes.some((i) => ((i.type === IncomeType.WORK_INCOME && i.monthlyAmount !== 0) || i.type === IncomeType.RETIREMENT_INCOME) && i.memberId === store.family.family.partner?.id)) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.no-incomes-for-partner",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.input",
         redirection: EDIT_INCOMES
      });
   }
   // On affiche un avertissement si il manque une date de naissance sur l'un des membres saisis
   if (store.family.hasFetched && toMembers(store.family.family).some((m) => !m.birthday)) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.missing-birthdate",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.input.f",
         redirection: SITUATION_FAMILY
      });
   }
   // On affiche un avertissement si l'utilisateur et son partenaire n'ont pas le même statut marital
   if (store.family.hasFetched && store.family.family.partner && store.family.family.partner?.maritalStatus !== store.family.family.me.maritalStatus) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.wrong-marital-status",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.fix",
         redirection: SITUATION_FAMILY
      });
   }
   // On affiche un avertissement si l'utilisateur possède un enfant dont le type de garde ne correspond pas à son statut marital
   if (store.family.hasFetched && store.family.family.children.some((child) => child.custodyType && !getAvailableCustodyTypes(store.family.family).includes(child.custodyType))) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.wrong-custody-type",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.fix",
         redirection: SITUATION_FAMILY
      });
   }
   // On affiche un avertissement si l'utilisateur et son partenaire n'ont pas le même régime matrimonial
   if (store.family.hasFetched && store.family.family.partner && store.family.family.partner?.matrimonialRegime !== store.family.family.me.matrimonialRegime) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.wrong-matrimonial-regime",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.fix",
         redirection: SITUATION_FAMILY
      });
   }
   // On affiche un avertissement si l'utilisateur est marié et ne possède pas de régime matrimonial
   if (store.family.hasFetched && store.family.family.me.maritalStatus === MaritalStatus.MARRIED && store.family.family.me.matrimonialRegime === undefined) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.missing-matrimonial-regime",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.fix",
         redirection: SITUATION_FAMILY
      });
   }
   // On affiche un avertissement si l'utilisateur a un patrimoine de départ négatif
   if (store.loans.hasFetched && store.assets.hasFetched && store.projects.hasFetchedProjects) {
      const assetsTotalValuation = store.assets.assets.reduce((acc: number, item: AssetWithValuation) => acc + (item?.assetValuation?.totalValuation || 0), 0);
      const loansTotalPrincipal = store.loans.loans.reduce((acc: number, item: Loan) => acc + (item && item.remainingPrincipal), 0);
      if (loansTotalPrincipal > assetsTotalValuation && store.projects.projects.length === 0) {
         coachAdvicesToReturn.advices.push({
            intlId: "coach.advices.situation.negative-net-worth",
            type: CoachAdviceType.SITUATION_WARNING,
            ctaIntlId: "coach.advices.cta.fix",
            redirection: SITUATION_NET_WORTH
         });
      }
   }
   // On affiche un avertissement si l'utilisateur a modifié ses revenus alors qu'il avait un projet retraite
   if (store.projects.isRetirementProjectAutomaticallyUpdated) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.situation.isRetirementProjectAutomaticallyUpdated",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.change",
         redirection: PROJECTS
      });
   }

   // On affiche un avertissement pour préciser que la retraite est simulée, une fois la retraite saisie, on peut afficher
   // les warnings de situation
   if (store.projects.hasFetchedProjects && !store.projects.projects.some((p) => p.projectType === ProjectType.RETIREMENT_PROJECT)) {
      coachAdvicesToReturn.advices.push({
         intlId: location === SIMULATIONS ? "coach.advices.simulation.default-retirement" : "coach.advices.situation.default-retirement",
         type: CoachAdviceType.SITUATION_WARNING,
         ctaIntlId: "coach.advices.cta.create",
         redirection: RETIREMENT_PROJECT
      });
   } else if (store.simulation.hasFetched) {
      // WARNING APRES CALCUL DE SIMULATION
      if (location === SIMULATIONS) {
         getAlertsByAge(store.simulation.simulation.alerts).forEach((alert) => {
            if (SIMULATION_ALERTS.includes(alert.type) || INCOMES_EXPENSES_ALERTS.includes(alert.type)) {
               coachAdvicesToReturn.advices.push({
                  intlId: `coach.advices.simulation.${alert.type}`,
                  type: CoachAdviceType.SIMULATION_WARNING,
                  ctaIntlId: alert.type === AlertType.ALERT_DEFICIT_CREATED ? "coach.advices.cta.know-more" : "coach.advices.cta.fix",
                  transformations: {year: alert.year},
                  redirection: SITUATION
               });
            }
            if (alert.type === AlertType.WARNING_DEPARTURE_OF_A_CHILD && location === SIMULATIONS) {
               coachAdvicesToReturn.advices.push({
                  intlId: `coach.advices.simulation.WARNING_DEPARTURE_OF_A_CHILD`,
                  type: CoachAdviceType.SIMULATION_WARNING,
                  ctaIntlId: "coach.advices.cta.change",
                  transformations: {year: alert.year},
                  redirection: SITUATION_FAMILY
               });
            }
         });
      } else if (getAlertsByAge(store.simulation.simulation.alerts).filter((alert) => SIMULATION_ALERTS.includes(alert.type) || INCOMES_EXPENSES_ALERTS.includes(alert.type)).length !== 0) {
         coachAdvicesToReturn.advices.push({
            intlId: `coach.advices.simulation.see-more`,
            type: CoachAdviceType.SIMULATION_WARNING,
            ctaIntlId: "coach.advices.cta.alerts",
            redirection: SIMULATIONS
         });
      }
   }

   // Explications dans la simulation  si le tutoriel est fini
   if (!coachAdvicesToReturn.advices.some((ca) => ca.type === CoachAdviceType.TUTORIAL) && location === SIMULATIONS) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.advices.simulation.incoherent",
         type: CoachAdviceType.OTHER
      });
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.advices.simulation.save",
         type: CoachAdviceType.OTHER
      });
   }

   if (coachAdvicesToReturn.advices.length === 0) {
      coachAdvicesToReturn.advices.push({
         intlId: "coach.advices.advices.no-advices",
         type: CoachAdviceType.OTHER,
         ctaIntlId: "coach.advices.cta.default",
         redirection: NEW_PROJECTS
      });
   }

   return coachAdvicesToReturn;
};

export const computeIsNewCoachAdvices = (oldAdvices: CoachState, newAdvices: CoachState): CoachState => {
   const advices: CoachAdvice[] = newAdvices.advices.map((ca) => ({...ca, isNew: !oldAdvices.advices.some((ca2) => ca2.intlId === ca.intlId)}));

   return {advices};
};
