import React, {FunctionComponent, useEffect, useState} from "react";
import {getAssetCategory} from "../../../../store/assets/utils";
import {useAssets} from "../../hooks/useAssets";
import {useLoans} from "../../hooks/useLoans";
import {convertNumberToStringWithSeparators} from "../../../utils/formatting/numberFormatter";
import {SGDonut} from "sg-donut";
import {SGBlock, SGTitle} from "sg-typo";
import {useDemoMode} from "../../hooks/useDemoMode";

const GraphAssetsPieChart: FunctionComponent = () => {
   const { assets } = useAssets()
   const loans = useLoans([])
   const demoMode = useDemoMode()
   const [data, setData] = useState<{ name: string; y: number | undefined; color: string }[]>([]);

   useEffect(() => {
      if (assets) {
         setData(
            assets?.map((item) => ({
               name: item.assetName,
               id: item.id,
               y: item.assetValuation.totalValuation,
               color: getAssetCategory(item.assetType).color.hexCode,
            }))
         );
      }
   }, [assets]);

   const getGraphTitle = () => {
      const totalValuation = assets
         ?.map((item) => item.assetValuation.totalValuation || 0)
         .reduce((a, b) => a + b, 0);
      const totalRemainingPrincipalLoan = loans
         ?.map((item) => item.remainingPrincipal || 0)
         .reduce((a, b) => a + b, 0);

      if (Number.isNaN(totalValuation) || Number.isNaN(totalRemainingPrincipalLoan)) {
         return "0 €";
      }

      return `${convertNumberToStringWithSeparators(Math.trunc(totalValuation) - Math.trunc(totalRemainingPrincipalLoan))} €`;
   };

   return (
      <SGBlock textalign="center">
         <SGDonut
            id="donut" dataLabels={false}
            inner={<SGTitle className={demoMode ? "blur-5" : ""} level={3}>{getGraphTitle()}</SGTitle>}
            series={data} size="xl" tooltip animated
            title={null} subtitle={null} shareClick={() => undefined} tooltipPrefix="€"
            borderWidth={0} innerSize="" slicedOffset={12} width="auto"/>
      </SGBlock>
   );
};

export {GraphAssetsPieChart};
