import React, {FunctionComponent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {deleteExpenseAction, updateExpensesAction,} from "../../../../store/expenses/actions";
import {ProjectType} from "../../../../store/projects/types";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {getMembersOfCouple} from "../../../../store/members/utils";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {SITUATION_TAXATION} from "../../../utils/privateRoutes";
import {useFamily} from "../../hooks/useFamily";
import {useExpense} from "../../hooks/useExpense";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {SGButtonGroup} from "sg-button";
import {State} from "../../../../store/store";

interface ChildSupportExpenseFormData {
   amount: number;
   beneficiaryId: string;
}

const ChildSupportExpenseEditor: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();

   const displayComponent = useSelector<State, boolean>((state) => state.expenses.hasFetchedExpenses && state.family.hasFetched);
   const childSupportExpense = useExpense(ProjectType.CHILD_SUPPORT_EXPENSE);

   const expenseMethods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   const deleteChildSupportExpense = () => {
      if (childSupportExpense) {
         dispatch(deleteExpenseAction(childSupportExpense));
      }
      redirectToRelativePath(SITUATION_TAXATION);
   };

   const onSubmit = (data: ChildSupportExpenseFormData) => {
      const beneficiary = getMembersOfCouple(family).find((m) => m.id === Number(data.beneficiaryId));
      const expense = {
         type: ProjectType.CHILD_SUPPORT_EXPENSE,
         monthlyAmount: Number(data.amount),
         id: childSupportExpense?.id,
         name: ProjectType.CHILD_SUPPORT_EXPENSE,
         member: beneficiary,
      };

      dispatch(updateExpensesAction(undefined, undefined, expense));
      redirectToRelativePath(SITUATION_TAXATION);
   };

   return (
      <SpinnerWrapper spinnerSize={SpinnerSize.LG} displayComponent={displayComponent} fatalErrorOnTimeout>
         {displayComponent && (
            <FormProvider {...expenseMethods}>
               <form>
                  <FormInputs colLength={10}>
                     <SelectInput key={childSupportExpense?.id} cypressName="child-support-expense-beneficiary" name="beneficiaryId" defaultValue={
                        childSupportExpense?.member?.id ? childSupportExpense?.member.id : family.me.id} label="tax.child.support.expense.form.member">
                        {getMembersOfCouple(family).map(
                           (member) => (
                              <SGSelectOption key={member.id} data-cy={`child-support-expense-beneficiary-${member.status}`} value={member.id}>
                                 {intl.formatMessage({id: `family.status.${member.status}`})}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>

                     <NumericInput cypressName="child-support-expense-amount" defaultValue={childSupportExpense?.monthlyAmount} min={100} max={10_000}
                                   name="amount" key={childSupportExpense?.id} label="tax.child.support.expense.form.amount"
                                   suffix="common.euro.per.month" placeholder={intl.formatMessage({id: "tax.child.support.expense.form.amount.placeholder"})}/>
                  </FormInputs>

                  <SGButtonGroup>
                     <SGButtonIntl type="primary" size="sm" cypressName="child-support-expense-submit" onClick={expenseMethods.handleSubmit(onSubmit)}
                                   intlId="common.save"/>

                     <SGButtonIntl type="secondary" size="sm" cypressName="child-support-expense-delete" onClick={() => deleteChildSupportExpense()}
                                   intlId="common.delete"/>
                  </SGButtonGroup>
               </form>
            </FormProvider>
         )}
      </SpinnerWrapper>
   );
};

export {ChildSupportExpenseEditor};
