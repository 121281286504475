import React, {FunctionComponent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {FamilyStatus, Member,} from "../../../../../store/members/types";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {AddMemberModal} from "../../../molecules/AddMemberModal/AddMemberModal";
import {ProjectType} from "../../../../../store/projects/types";
import {createProjectAction} from "../../../../../store/projects/actions";
import {getDefaultCustodyType} from "../../../../../store/members/utils";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {useFamily} from "../../../hooks/useFamily";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButtonGroup} from "sg-button";
import {SGIcon} from 'sg-icon'
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGBlock} from "sg-typo";
import {SGAvenirNavPlus, SGAvenirStyledBillet, SGAvenirStyledMonde, SGAvenirStyledUniversite} from "sg-icon-pack-base";
import {SGCard} from "sg-card";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGSpace} from "sg-space";

interface ChildrenEducationProjectFormData {
   member: string;
   horizon: string;
   amount: number;
}

const ChildrenEducationProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   useEffect(() => {
      if (family) {
         setLocalChildren(family.children);
      }
   }, [family]);

   const availableOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
   const [isAddMemberModalVisible, setAddMemberModalVisible] = useState(false);
   const [localChildren, setLocalChildren] = useState<Member[]>([]);
   const [selectedStudies, setSelectedStudies] = useState<number | undefined>(undefined);

   const onAddNewMember = (member: Member) => {
      setLocalChildren([...localChildren, {...member, status: FamilyStatus.CHILD, custodyType: getDefaultCustodyType(family.me)}]);
      methods.setValue("member", localChildren[localChildren.length]);
   };

   const goToNewProject = () => {
      redirectToRelativePath(NEW_PROJECTS);
   };

   const onSubmit = (data: ChildrenEducationProjectFormData) => {
      const concernedChild: Partial<Member> = localChildren[Number(data.member)];

      dispatch(
         createProjectAction({
            concernedMember: concernedChild,
            projectType: ProjectType.CHILDREN_EDUCATION,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectName: `Études de ${concernedChild.name}`,
            amount: data.amount,
         })
      );
   };

   return (
      <>
         <SGTitleIntl intlId="project.children.studies.title" cypressName="children-studies-project-title"/>
         <SGTextIntl intlId="project.children.studies.subtitle"/>
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
               <SGCard data-cy="project-children-eduction-frame-input">
                  <FormInputs colLength={10}>
                     <SelectInput name="horizon" cypressName="children-studies-select-horizon" defaultValue={5} label="project.children.studies.form.horizon">
                        {availableOptions.map((horizon: number) => (
                           <SGSelectOption key={horizon} value={horizon}>
                              {horizon === 0 ? intl.formatMessage({id: "common.now"}) : horizon + intl.formatMessage({id: "common.years"})}
                           </SGSelectOption>
                        ))}
                     </SelectInput>
                     <SGBlock>
                        <SelectInput name="member" cypressName="children-studies-select-member" required
                                     label="project.children.studies.form.member" placeholder={intl.formatMessage({id: "common.choose.member"})}>
                           {localChildren.map((member: Member, index: number) => (
                              <SGSelectOption key={member.id} value={index}>
                                 {member.name}
                              </SGSelectOption>
                           ))}
                        </SelectInput>
                        <SGTextIntl intlId="project.children.studies.form.member.not.in.list"/>
                        <SGBlock>
                           <SGButtonIntl type="tertiary" data-cy="children-education-project-add-member" onClick={() => setAddMemberModalVisible(true)}
                                         icon={<SGIcon currentcolor component={<SGAvenirNavPlus/>}/>} intlId="common.add-member"/>
                        </SGBlock>
                     </SGBlock>
                  </FormInputs>
               </SGCard>
               <SGCard data-cy="project-children-eduction-frame-choose-budget">
                  <SGTitleIntl intlId="project.children.studies.budget.title" cypressName="project-children-budget"/>
                  <SGTextIntl intlId="project.children.studies.budget.subtitle"/>
                  <SGCard bordered clickable dark={selectedStudies === 0} data-cy="select-public-school" onClick={() => {
                     methods.setValue("amount", 15_000, {shouldValidate: true});
                     setSelectedStudies(0);
                  }}>
                     <SGGridRow data-cy="public-school-button" align="middle">
                        <SGGridCol span={1} xs={2} textalign="center">
                           <SGIcon size="xl" component={<SGAvenirStyledUniversite/>} align="center"/>
                        </SGGridCol>
                        <SGGridCol span={10} offset={1} xs={9}>
                           <SGSpace direction="vertical" disableautomargin>
                              <SGTextIntl intlId="project.children.studies.public.school.title" strong/>
                              <SGTextIntl intlId="project.children.studies.public.school.subtitle"/>
                              <SGTextIntl intlId="project.children.studies.public.school.amount" strong/>
                           </SGSpace>
                        </SGGridCol>
                     </SGGridRow>
                  </SGCard>

                  <SGCard data-cy="select-private-school" bordered clickable dark={selectedStudies === 1} onClick={() => {
                     methods.setValue("amount", 33_000, {shouldValidate: true});
                     setSelectedStudies(1);
                  }}>
                     <SGGridRow data-cy="private-school-button" align="middle">
                        <SGGridCol span={1} xs={2}>
                           <SGIcon size="xl" component={<SGAvenirStyledBillet/>} align="center"/>
                        </SGGridCol>
                        <SGGridCol span={10} offset={1} xs={9}>
                           <SGSpace direction="vertical" disableautomargin>
                              <SGTextIntl intlId="project.children.studies.private.school.title" strong/>
                              <SGTextIntl intlId="project.children.studies.private.school.subtitle"/>
                              <SGTextIntl intlId="project.children.studies.private.school.amount" strong/>
                           </SGSpace>
                        </SGGridCol>
                     </SGGridRow>
                  </SGCard>

                  <SGCard bordered clickable data-cy="select-foreign-studies" dark={selectedStudies === 2} onClick={() => {
                     methods.setValue("amount", 90_000, {shouldValidate: true});
                     setSelectedStudies(2);
                  }}>
                     <SGGridRow data-cy="foreign-studies-button" align="middle">
                        <SGGridCol span={1} xs={2}>
                           <SGIcon size="xl" component={<SGAvenirStyledMonde/>} align="center"/>
                        </SGGridCol>
                        <SGGridCol span={10} offset={1} xs={9}>
                           <SGSpace direction="vertical" disableautomargin>
                              <SGTextIntl intlId="project.children.studies.foreign.studies.title" strong/>
                              <SGTextIntl intlId="project.children.studies.foreign.studies.subtitle"/>
                              <SGTextIntl intlId="project.children.studies.foreign.studies.amount" strong/>
                           </SGSpace>
                        </SGGridCol>
                     </SGGridRow>
                  </SGCard>
               </SGCard>

               <SGCard data-cy="project-children-eduction-amount">
                  <FormInputs colLength={10}>
                     <NumericInput name="amount" suffix="€" cypressName="children-studies-amount" min={5_000} max={300_000}
                                   label="project.children.studies.form.amount"
                                   placeholder={intl.formatMessage({id: "project.children.studies.form.amount.placeholder"})}/>
                  </FormInputs>
               </SGCard>

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="children-studies-next"
                                onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                  <SGButtonIntl type="link" cypressName="children-studies-previous" onClick={goToNewProject} intlId="common.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>

         {isAddMemberModalVisible && (
            <AddMemberModal hideModal={() => setAddMemberModalVisible(false)} cypressName="children-studies-modal"
                            onAddNewMemberSubmit={onAddNewMember} displayFamilyType={false}/>
         )}
      </>
   );
};

export {ChildrenEducationProject};
