import { ReactComponent as BackArrowIcon } from "assets/images/headers/back-arrow.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SGLogo } from "sg-logo";
import { AccountState } from "store/account/types";
import { State } from "store/store";
import "./HeaderAssuXS.scss";

const HeaderAssuXS: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const accountState = useSelector<State, AccountState>(
    (state) => state.account
  );

  const getLocationName = () => {
    switch (location.pathname) {
      case "/tunnel":
        return intl.formatMessage({ id: "header.title.tunnel" });
      case "/help":
        return intl.formatMessage({ id: "header.title.help" });
      case "/cgu":
        return intl.formatMessage({ id: "header.title.cgu" });
      case "/new-project":
        return intl.formatMessage({ id: "header.title.new.project" });
      case "/projects":
        return intl.formatMessage({ id: "header.title.projects" });
      case "/situation":
        return intl.formatMessage({ id: "header.title.profile" });
      case "/advices":
      default:
        return intl.formatMessage({ id: "header.title.coach" });
    }
  };

  return (
    <header className="headerAssuXS">
      {accountState.account.id === -1 && (
        <>
          <SGLogo baseline width={256} />
          <div className="headerAssuXS__linear-gradient" />
        </>
      )}
      {accountState.account.id !== -1 && (
        <div className="headerAssuXS__nav">
          <BackArrowIcon
            className="headerAssuXS__back-arrow"
            onClick={() => history.goBack()}
          />
          <div className="headerAssuXS__page-name">
            <span>{getLocationName()}</span>
          </div>
        </div>
      )}
    </header>
  );
};

export { HeaderAssuXS };
