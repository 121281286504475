import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGTitle } from "sg-typo";
import { BoutonSGAgenda } from "../../BoutonSGAgenda/BoutonSGAgenda";
import "./StickyBanner.scss";

interface StickyBannerProps {
  intlId: string;
  onClick: () => void;
  icon?: ReactNode;
  disabled?: boolean;
}

const StickyBanner: FunctionComponent<PropsWithChildren<StickyBannerProps>> = ({
  intlId,
  onClick,
  icon,
  disabled = false,
}: PropsWithChildren<StickyBannerProps>) => {
  const intl = useIntl();

  return (
    <div className="sticky-banner">
      <SGCard>
        <SGGridRow align="middle">
          <SGGridCol span={6} textalign="left">
            <SGTitle level={3}>
              {intl.formatMessage({
                id: "parcoursEpargner.sitckyBanner.contacter",
              })}
            </SGTitle>
          </SGGridCol>
          <SGGridCol span={6} textalign="right">
            <BoutonSGAgenda
              disableautomargin
              type="primary"
              icon={icon}
              onClick={onClick}
              disabled={disabled}
            >
              {intl.formatMessage({
                id: intlId,
              })}
            </BoutonSGAgenda>
          </SGGridCol>
        </SGGridRow>
      </SGCard>
    </div>
  );
};

export { StickyBanner };
