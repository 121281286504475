import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { TRACKING_CLICK_HYPOTHESES } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { BoutonAccesRIS } from "website/components/molecules/BoutonAccesRIS";
import {
  HypothesisAnchor,
  HypothesisModal,
} from "website/components/molecules/HypothesisModal/HypothesisModal";
import { DASHBOARD } from "website/utils/privateRoutes";

export const FooterEstimation: FunctionComponent = () => {
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);

  const history = useHistory();
  const intl = useIntl();
  const { trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { disponibiliteParcoursRIS } = useFeatures();

  const redirectToDashboard = () => {
    trackClick("resultats::clic-sur-retour-au-tableau-de-bord");
    history.push(DASHBOARD);
  };

  const onClickHypothesisModal = () => {
    trackClick(`resultats::${TRACKING_CLICK_HYPOTHESES}`);
    setShowHypothesisModal(true);
  };

  return (
    <SGBlock disableautomargin>
      <SGButtonGroup align={isPhone || !disponibiliteParcoursRIS ? "center" : "opposite"} disableautomargin>
        <SGButton
          type="primary"
          size="md"
          onClick={redirectToDashboard}
          data-cy="parcours-ris-dashboard-button"
          ariaLabel={intl.formatMessage({
            id: "parcoursRIS.resultat.buttonRetour.ariaLabel",
          })}
        >
          <SGTextIntl intlId="parcoursRIS.resultat.buttonRetour" size="m" />
        </SGButton>
        <BoutonAccesRIS
          cleLabelBouton="parcoursRIS.resultat.buttonModifier"
          trackingClic="resultats::clic-sur-modifier-mon-ris"
        />
      </SGButtonGroup>
      <SGBox margin={{ top: "md", bottom: "md" }}>
        <>
          <SGBlock component="p">
            <SGText size="m">
              {intl.formatMessage(
                { id: `parcoursRIS.resultat.retour` },
                {
                  b: (word: string) => <SGText weight="700">{word}</SGText>,
                }
              )}
            </SGText>
          </SGBlock>
          <SGBlock component="p">
            <SGText size="s">
              {intl.formatMessage(
                { id: `parcoursRIS.resultat.footer` },
                {
                  hypothese: (word: string) => (
                    <SGText
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={onClickHypothesisModal}
                    >
                      {word}
                    </SGText>
                  ),
                }
              )}
            </SGText>
          </SGBlock>
        </>
      </SGBox>
      {showHypothesisModal && (
        <HypothesisModal
          setVisible={setShowHypothesisModal}
          visible={showHypothesisModal}
          anchor={HypothesisAnchor.RSI}
          closeSecondGroup
        />
      )}
    </SGBlock>
  );
};
