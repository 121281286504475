import React, { FunctionComponent, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { updateAccountAction } from "store/account/actions";
import { callDashboard, createPrefs, setIsOnboarded } from "store/dashboard/actions";
import { hasFetchedDashboard } from "store/dashboard/types";
import { resetParcoursTunnel } from "store/simulateurAvantApres/slice";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import { DetailTab } from "website/components/atoms/DetailTab/DetailTab";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useAccount } from "website/components/hooks/useAccount";
import { Faq } from "website/components/molecules/Faq/Faq";
import { TunnelStep } from "website/components/organisms/Tunnel/TunnelStep/TunnelStep";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { DASHBOARD, SIMULATEUR_AVANT_APRES } from "website/utils/privateRoutes";
import "./TunnelAssu.scss";

export interface TunnelProps extends RouteProps {
    steps: FunctionComponent[];
}

export const TunnelAssu: FunctionComponent<TunnelProps> = (props: TunnelProps) => {
    const { steps } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const account = useAccount();
    const dashboardState = useDashboardState();
    const { agesTries } = useSelectedRetirementAge();
    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );
    const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);
    const isTunnelReforme = useSelector<State, boolean>((state) => state.tunnel.isTunnelReforme);

    const faqBodyList = ["personal.data", "sapiendo"];
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { showDetailFonctionnaire } = useContext(DetailTabContext);

    useEffect(() => {
        if (
            account.tunnelCompleted &&
            simulationAssuState.retirementSimulationParameters?.idSimulSimple &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple !== ""
        ) {
            dispatch(callDashboard());
        }
    }, [simulationAssuState, account.tunnelCompleted]);

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState)) {
            if (!dashboardState.dashboard.creationDate) {
                dispatch(createPrefs());
            } else if (agesTries && agesTries.length > 0) {
                // Remédiation car tunnel complété côté backpfr
                // A l'arrivée sur l'onboarding, si la simulation a été réalisée, on met à jour le compte
                if (!account.tunnelCompleted) {
                    dispatch(updateAccountAction({ ...account, tunnelCompleted: true }));
                } else if (isTunnelReforme) {
                    dispatch(setIsOnboarded());
                    dispatch(resetParcoursTunnel());
                    history.push(SIMULATEUR_AVANT_APRES);
                } else {
                    dispatch(setIsOnboarded());
                    history.push(DASHBOARD);
                }
            }
        }
    }, [dashboardState, agesTries, account.tunnelCompleted]);

    const getStep = (step : FunctionComponent, index : number) => (
        <SGGridRow key={step.name} gutter={[0, 30]}>
            <SGGridCol span={12} md={currentStep > 0 ? 7 : 12}>
                <SGBox margin={isPhone ? {left: "sm", right: "sm", top: "lg"} : {left: "sm", right: "sm",bottom: "lg" , top: "lg"}}>
                    <TunnelStep stepIndex={index} component={step} />
                </SGBox>
            </SGGridCol>
            {currentStep > 0 && (
                <SGGridCol span={12} md={5} align="stretch">
                    <SGBox margin={isPhone ? {bottom: "lg"} : {}}>
                        <DetailTab
                            titleDetailTab="tunnel.detail.title"
                            textDetailTab={`tunnel.detail.text.step${currentStep}`}
                            hideListStyle={isPhone ? true : currentStep === 3}
                            transformations={{
                                fonctionnaire: (word: string) => (showDetailFonctionnaire ? word : ""),
                            }}
                        />
                    </SGBox>
                </SGGridCol>
            )}
        </SGGridRow>
    );

    return (
        <SGGridRow justify="center">
            <SGGridCol span={12} sm={11} md={10}>
                {steps.map((step, index) => (
                    <React.Fragment key={`step-${step}`}>
                        { currentStep === index && currentStep === 0 &&
                            getStep(step, index)
                        }
                        { currentStep === index && currentStep > 0 &&
                            <SGCard
                                hoverable={currentStep > 0}
                                shadow={currentStep > 0}
                            >
                                {getStep(step, index)}
                            </SGCard>
                        }
                    </React.Fragment>
                ))}
            </SGGridCol>
            <SGGridCol span={10}>
                <Faq bodyFaq={faqBodyList} openDefaultKeys={currentStep === 0 && !isPhone ? [faqBodyList[0]] : [""]} isMainAccordionOpen hideMainAccordion />
            </SGGridCol>
        </SGGridRow>
    );
};
