import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien, SGAvenirStrokedOeil } from "sg-icon-pack-base";
import { SGProgress } from "sg-progress";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { Parcours } from "website/components/hooks/dashboard/useDashboardProgression";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { MODIFIER_PROFIL } from "website/utils/privateRoutes";
import "./Progression.scss";
import { ProgressionPopHover } from "./ProgressionPopHover";

interface ProgressionProps {
  percent: number;
  parcoursDoneTab: Parcours[];
}

const Progression: FunctionComponent<ProgressionProps> = (
  props: ProgressionProps
) => {
  const { percent, parcoursDoneTab } = props;
  const intl = useIntl();
  const [nextStep, setNextStep] = useState<Parcours>();
  const { trackClick } = useTracking();
  const history = useHistory();

  useEffect(() => {
    setNextStep(
      parcoursDoneTab.filter((parcours) => parcours.date === undefined)[0]
    );
  }, [parcoursDoneTab]);

  const handleOnClickLinkProfil = () => {
    trackClick("clic-sur-modifier-mon-profil");
    history.push(MODIFIER_PROFIL);
  };

  return (
    <SGCard
      className="progression"
      disableautomargin
      title={
          <SGGridRow>
            <SGGridCol flex="1 0 0%">
              <SGText size="m" weight="600">
                {intl.formatMessage(
                  { id: "dashboard.progression.title.link" },
                  { pourcentage: percent.toLocaleString("fr-FR") }
                )}
              </SGText>
              {nextStep !== undefined && (
                <SGBox margin={{ bottom: "xxs" }}>
                  <SGText size="xs" weight="400" color="info">
                    {intl.formatMessage({
                      id: "dashboard.progression.prochaine.etape",
                    })}
                    {intl.formatMessage({
                      id: `progression.popHover.check.${nextStep.nom}`,
                    })}
                  </SGText>
                </SGBox>
              )}
              <SGProgress
                size="lg"
                percent={percent}
                showInfo={false}
                disableautomargin
                status="normal"
                arialabel={intl.formatMessage(
                  { id: "dashboard.progression.title.link" },
                  { pourcentage: percent.toLocaleString("fr-FR") }
                )}
              />
            </SGGridCol>
            <SGGridCol flex="initial" align="center">
              <div className="progression__loupe">
                <ProgressionPopHover
                  pourcentage={percent}
                  parcoursDoneTab={parcoursDoneTab}
                >
                  <SGButton
                    ariaLabel={intl.formatMessage({
                      id: "dashboard.progression.ouvrir",
                    })}
                    disableautomargin
                    type="icon"
                    icon={<SGIcon size="l" component={<SGAvenirStrokedOeil />} />}
                    onClick={() => trackClick("clic-sur-progression")}
                  />
                </ProgressionPopHover>
              </div>
            </SGGridCol>
          </SGGridRow>
      }
      actions={[
        <SGBox
          margin={{
            top: "sm",
          }}
        >
          <SGButton
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
            onClick={handleOnClickLinkProfil}
          >
            {intl.formatMessage({
              id: `dashboard.progression.profile`,
            })}
          </SGButton>
        </SGBox>,
      ]}
      actionsdivider
      textalign="center"
    />
  );
};

export { Progression };
