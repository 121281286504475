import React, {FunctionComponent, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useAccount} from "website/components/hooks/useAccount";
import {InheritanceSimulatorParameter, InheritanceSimulatorResult} from "../../../../../store/inheritanceSimulator/types";
import {State} from "store/store";
import {FamilyStatus, HeirsType} from "../../../../../store/members/types";
import {updateAccountAction} from "../../../../../store/account/actions";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {SITUATION_SUCCESSION} from "website/utils/privateRoutes";
import {computeInheritance} from "store/inheritanceSimulator/actions";
import {SGCard} from "sg-card";
import {SGTextIntl} from "website/components/atoms/SGTextIntl/SGTextIntl";
import {CustomNumberFormat} from "../../../../utils/formatting/CustomNumberFormat";
import {convertNumberToStringWithSeparators} from "../../../../utils/formatting/numberFormatter";
import {SGContent} from "sg-layout";
import {SGSpace} from "sg-space";
import {getAssetCategory} from "../../../../../store/assets/utils";
import {SGText} from "sg-typo";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGSelectOption} from "sg-select";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {StringInput} from "../../../atoms/StringInput/StringInput";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";

const InheritanceSimulator: FunctionComponent = () => {
   const intl = useIntl();
   const dispatch = useDispatch();
   const account = useAccount();
   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });
   const {handleSubmit} = methods;
   const cypressName = "inheritance-simulator";
   const inheritanceSimulatorResults: InheritanceSimulatorResult = useSelector<State, InheritanceSimulatorResult>((state) => state.inheritanceSimulator.result);
   const hasFetchedInheritanceSimulatorResults = useSelector<State, boolean>((state) => state.inheritanceSimulator.hasFetched);

   const heirsTypes: HeirsType[] = [
      HeirsType.PARTNER,
      HeirsType.COMMON_CHILDREN,
      HeirsType.PARENT,
      HeirsType.GRANDPARENT,
      HeirsType.BROTHER_SISTER,
   ];

   const [targetType, setTargetType] = useState<HeirsType>();
   const [showPromptNumber, setShowPromptMember] = useState<boolean>(true);
   const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

   useEffect(() => {
      if (!account.visitedSuccessionSimulator) {
         dispatch(updateAccountAction({...account, visitedSuccessionSimulator: true}));
      }
   }, []);

   const onSelectFamilyType = (newHeirsType: string) => {
      setShowPromptMember(true);
      const targetType = heirsTypes.filter((ht) => ht === newHeirsType)[0];
      setTargetType(targetType);
      if (targetType === HeirsType.PARTNER) {
         setShowPromptMember(false);
      }
   };

   const goToSuccession = () => {
      redirectToRelativePath(SITUATION_SUCCESSION);
   };

   const onSubmit = (data: { heirsNumber: number }) => {
      let familyStatus: FamilyStatus;

      switch (targetType) {
         case HeirsType.PARTNER:
            familyStatus = FamilyStatus.PARTNER;
            break;
         case HeirsType.COMMON_CHILDREN:
            familyStatus = FamilyStatus.CHILD;
            break;
         case HeirsType.PARENT:
            familyStatus = FamilyStatus.PARENT;
            break;
         case HeirsType.GRANDPARENT:
            familyStatus = FamilyStatus.GRANDPARENT;
            break;
         case HeirsType.BROTHER_SISTER:
            familyStatus = FamilyStatus.BROTHER_SISTER;
            break;
         default:
            familyStatus = FamilyStatus.CHILD;
      }

      const inheritanceFeesParameter: InheritanceSimulatorParameter = {
         heirsType: familyStatus,
         numberOfHeirs: familyStatus === FamilyStatus.PARTNER ? 1 : data.heirsNumber,
      };

      dispatch(computeInheritance(inheritanceFeesParameter));
      setFormSubmitted(true);
   };

   const displayResult = () => (
      <SGCard bordered>
         <SGTextIntl intlId="inheritance.simulator.inheritance-calculator.page.heirs_share" transformations={{
            amount: <CustomNumberFormat displayType="text" value={convertNumberToStringWithSeparators(inheritanceSimulatorResults.amountPerHeir)}/>,
            fees: <CustomNumberFormat displayType="text" value={convertNumberToStringWithSeparators(inheritanceSimulatorResults.feesAmountPerHeir)}/>,
         }}/>
         <SGContent>
            <SGSpace direction="vertical" textalign="center" size="sm">
               {[...inheritanceSimulatorResults.assetDTOList].sort((asset1, asset2) => getAssetCategory(asset1.assetType).priority - getAssetCategory(asset2.assetType).priority).map((asset) => (
                  <SGCard key={asset.id} disableautomargin borderleft={`.3rem solid ${getAssetCategory(asset.assetType).color.hexCode}`}
                          data-cy={`inheritance-simulator-${asset.assetName}`}>
                     <SGSpace>
                        <SGText strong>{asset.assetName}</SGText>
                        <SGSpace direction="vertical" textalign="right">
                           <SGText>
                              <CustomNumberFormat displayType="text" suffix=" €"
                                                  value={convertNumberToStringWithSeparators(!asset.assetValuation.totalValuation ? 0 : asset.assetValuation.totalValuation)}/>
                           </SGText>
                        </SGSpace>
                     </SGSpace>
                  </SGCard>
               ))}
            </SGSpace>
         </SGContent>
      </SGCard>
   );

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="inheritance.simulator.inheritance-calculator.page.title" cypressName="surviving-partner-simulator-title"/>
         <SGTextIntl intlId="inheritance.simulator.inheritance-calculator.page.paragraph"/>
         <>
            <FormProvider {...methods}>
               <SGSpace direction="vertical">
                  <FormInputs colLength={6}>
                     <SelectInput onValueChange={onSelectFamilyType} cypressName={`${cypressName}-edit-heirs`} name="heirsType" required
                                  placeholder={intl.formatMessage({id: "inheritance.simulator.inheritance-calculator.page.type.placeholder"})}
                                  label="inheritance.simulator.inheritance-calculator.page.type.label">
                        {heirsTypes.map((heirsType: HeirsType) => (
                           <SGSelectOption key={heirsType} data-cy={`${cypressName}-edit-heirs-${heirsType}`} value={heirsType}>
                              {intl.formatMessage({
                                 id: `inheritance.heirsType.${heirsType}`,
                              })}
                           </SGSelectOption>
                        ))}
                     </SelectInput>
                     {showPromptNumber ? (
                        <NumericInput name="heirsNumber" cypressName={`${cypressName}-edit-target`} required min={1} max={20}
                                      label="inheritance.simulator.inheritance-calculator.page.target.label" suffix=" "
                                      placeholder={intl.formatMessage({id: "inheritance.simulator.inheritance-calculator.page.target.placeholder"})}/>
                     ) : (
                        <StringInput label="inheritance.simulator.inheritance-calculator.page.target.label" defaultValue="1" name="maximum-1-heir-when-partner"
                                     cypressName={`${cypressName}-maximum-1-heir-when-partner`} disabled/>
                     )}
                  </FormInputs>
                  <SGButtonGroup align="center">
                     <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName="rd-edit-submit" onClick={handleSubmit(onSubmit)}
                                   intlId="inheritance.simulator.inheritance-calculator.page.compute.button"/>
                  </SGButtonGroup>
               </SGSpace>
            </FormProvider>
            {formSubmitted && hasFetchedInheritanceSimulatorResults && inheritanceSimulatorResults.assetDTOList.length !== 0 && displayResult()}
            {formSubmitted && hasFetchedInheritanceSimulatorResults && inheritanceSimulatorResults.assetDTOList.length === 0 && (
               <SGTextIntl intlId="inheritance.simulator.inheritance-calculator.page.warning_no_share"/>
            )}
         </>
      </SGSpace>
   );
};

export {InheritanceSimulator};
