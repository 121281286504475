import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard, SGCardMeta } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Article } from "website/components/hooks/articles";
import { TagTemps } from "website/components/molecules/TagTemps/TagTemps";
import { getDateString } from "./OngletThematique";

interface CarteArticleThematiqueHorizontaleProps {
    article: Article;
    handleOnClickLireArticle: (path: string, trackKey: string) => void;
    trackKey: string;
}
/**
 * Carte pour représenter un article dans la page des thématiques
 */
export const CarteArticleThematiqueHorizontale: FunctionComponent<CarteArticleThematiqueHorizontaleProps> = (props: CarteArticleThematiqueHorizontaleProps) => {
    const { article, handleOnClickLireArticle, trackKey } = props;
    const intl = useIntl();

    return (
        <SGCard
            coversize="auto"
            coverposition="leftTop"
            coverpriority
            disableautomargin
            layout="row"
            shadow={false}
            cover={
                <>
                    <img
                        alt={`${intl.formatMessage({ id: "articles.couverture.alt" })}${intl.formatMessage({
                            id: `home.article.title.${article.id}`,
                        })}`}
                        src={article.image}
                    />
                    <TagTemps temps={article.time} />
                </>
            }
        >
            <SGBox span={4}>
                <SGCardMeta
                    description={
                        <>
                            <SGText weight="600">{getDateString(article.date)}</SGText>
                            <SGText ellipsis>{intl.formatMessage({ id: `home.article.description.${article.id}` })}</SGText>
                            <SGButton
                                key="lien"
                                type="tertiary"
                                icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                iconposition="right"
                                onClick={() => handleOnClickLireArticle(article.path, trackKey)}
                                ariaLabel={intl.formatMessage({ id: `articles.bouton.lire.${article.type}.ariaLabel` })}
                            >
                                {intl.formatMessage({ id: `articles.bouton.lire.${article.type}` })}
                            </SGButton>
                        </>
                    }
                    title={
                        <SGTitle level={2} visuallevel={3}>
                            {intl.formatMessage({ id: `home.article.title.${article.id}` })}
                        </SGTitle>
                    }
                />
            </SGBox>
        </SGCard>
    );
};
