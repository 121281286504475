import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {getExpenseGraphCategory, getIncomeGraphCategory,} from "../../../../store/assets/utils";
import {Expense} from "../../../../store/expenses/types";
import {useIncomes} from "../../hooks/useIncomes";
import {useExpenses} from "../../hooks/useExpenses";
import {useTotalIncomes} from "../../hooks/useTotalIncomes";
import {useTotalExpenses} from "../../hooks/useTotalExpenses";
import {useDemoMode} from "../../hooks/useDemoMode";

interface GraphData {
   name: string,
   showInLegend: boolean,
   color: string,
   data: (null | number)[]
}

const GraphIncomesExpensesBarChart: FunctionComponent = () => {
   const intl = useIntl();
   const incomes = useIncomes();
   const expenses = useExpenses();
   const demoMode = useDemoMode();
   const totalIncomes = useTotalIncomes();
   const totalExpenses = useTotalExpenses([]);

   const [expensesDisplayed, setExpensesDisplayed] = useState<Expense[]>([]);
   const filter = demoMode ? "blur(4px)" : ""

   useEffect(() => {
      if (incomes && expenses) {
         if (totalIncomes > totalExpenses) {
            setExpensesDisplayed([
               ...expenses,
               {
                  monthlyAmount: totalIncomes - totalExpenses,
                  name: "Revenu disponible",
                  id: 0,
                  member: undefined,
               },
            ]);
         } else {
            setExpensesDisplayed(expenses);
         }
      }
   }, [incomes, expenses, totalIncomes, totalExpenses]);

   const [graphData, setGraphData] = useState<GraphData[]>([]);

   useEffect(() => {
      let series: { name: string, showInLegend: boolean, color: string, data: (null | number)[] } [] = [];

      if (incomes.length === 0 && expensesDisplayed.length === 0) {
         series = [{name: "", showInLegend: false, color: "", data: []}];
      } else {
         const incomesArray = [...incomes];
         incomesArray.sort(
            (first, second) => first.monthlyAmount - second.monthlyAmount
         );

         const expensesArray = [...expensesDisplayed];
         expensesArray.sort(
            (first, second) => first.monthlyAmount - second.monthlyAmount
         );

         for (let i = 0; i < incomesArray.length; i += 1) {
            if (incomesArray[i].monthlyAmount !== 0) {
               series.push({
                  name: incomesArray[i].name,
                  showInLegend: false,
                  data: [incomesArray[i].monthlyAmount, null],
                  color: getIncomeGraphCategory(incomesArray[i].type).color.hexCode,
               });
            }
         }
         for (let i = 0; i < expensesArray.length; i += 1) {
            if (expensesArray[i].monthlyAmount !== 0) {
               series.push({
                  name: expensesArray[i].type
                     ? intl.formatMessage({
                        id: `expenseTypes.${expensesArray[i].type}`,
                     })
                     : intl.formatMessage({id: "expenses.summary.available.income"}),
                  showInLegend: false,
                  data: [null, expensesArray[i].monthlyAmount],
                  color: getExpenseGraphCategory(expensesArray[i].type).color.hexCode,
               });
            }
         }
      }
      setGraphData(series);
   }, [incomes, expensesDisplayed]);

   const incomesExpensesBarChartOptions = {
      navigation: {
         buttonOptions: {
            enabled: false,
         },
      },
      chart: {
         plotBackgroundColor: null,
         plotBorderWidth: null,
         plotShadow: false,
         type: "column",
         backgroundColor: "transparent",
      },
      credits: {
         enabled: false,
      },
      title: {
         text: "",
      },
      xAxis: {
         categories: [
            intl.formatMessage({id: "graphs.income-expense.revenue"}),
            intl.formatMessage({id: "graphs.income-expense.expensesTitle"}),
         ],
         labels: {
            enabled: true,
            style: {
               fontWeight: "bold",
               fontSize: "16px",
            },
         },
         lineColor: "transparent",
         tickColor: "transparent",
      },
      yAxis: {
         min: 0,
         title: {text: ""},
         labels: {
            enabled: false,
            style: {
               fontWeight: "bold",
               fontSize: "16px",
            },
         },
         gridLineColor: "transparent",
         stackLabels: {
            enabled: true,
            format: "{total:,.0f} €",
            allowOverlap: true,
            style: {
               fontWeight: "bold",
               fontSize: "16px",
               filter
            },
         },
      },
      tooltip: {
         headerFormat: "<b>{series.name}</b><br/>",
         pointFormat: "{point.y:,.0f} €",
         borderWidth: 0,
         backgroundColor: "rgba(0, 0, 0, 0.85)",
         style: {
            "stroke-width": "0px",
            textShadow: false,
            fontSize: "12px",
            color: "#ffffff",
            filter
         },
      },
      plotOptions: {
         column: {
            stacking: "normal",
            dataLabels: {
               enabled: true,
               format: "{y:,.0f} €",
               style: {
                  fontWeight: "bold",
                  fontSize: "16px",
                  filter
               },
            },
         },
         series: {
            pointPadding: 0.8,
            groupPadding: 0.75,
         }
      },
      series: graphData,
   };

   return (
      <HighchartsReact highcharts={Highcharts} options={incomesExpensesBarChartOptions}/>
   );
};

export {GraphIncomesExpensesBarChart};
