import React, {FunctionComponent} from "react";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {NameAndValuationCollapsableFrames} from "../../../molecules/NameAndValuationCollapsableFrames/NameAndValuationCollapsableFrames";
import {useRealizedDonations} from "../../../hooks/useRealizedDonations";
import {SGGridCol, SGGridRow} from "sg-grid";
import {AssetType} from "../../../../../store/assets/types";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

const ProfileSuccession: FunctionComponent = () => {
   const realizedDonations = useRealizedDonations();
   const hasFetchedRealizedDonations = useSelector<State, boolean>((state) => state.realizedDonations.hasFetched);

   return (
      <>
         <SGTitleIntl intlId="inheritance.realized-donation.title" cypressName="realized-donations-title"/>
         <SpinnerWrapper displayComponent={hasFetchedRealizedDonations} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>

            {hasFetchedRealizedDonations && <>
               <SGGridRow>
                  <SGGridCol xs={12} xl={6}>
                     <NameAndValuationCollapsableFrames title="inheritance.realized-donation.list.title.cash"
                                                        cypressName="profile-succession-realized-donations-cash"
                                                        realizedDonations={realizedDonations.filter((rd) => rd.assetType === AssetType.CASH_ACCOUNT)}
                                                        emptyListText="profile.succession.no-realized-donations.cash"/>
                  </SGGridCol>
                  <SGGridCol xs={12} xl={6}>
                     <NameAndValuationCollapsableFrames title="inheritance.realized-donation.list.title.immo"
                                                        cypressName="profile-succession-realized-donations-immo"
                                                        realizedDonations={realizedDonations.filter((rd) => rd.assetType === AssetType.REAL_ESTATE_PRIMARY)}
                                                        emptyListText="profile.succession.no-realized-donations.immo"/>

                  </SGGridCol>
               </SGGridRow>
            </>
            }
         </SpinnerWrapper>
      </>
   );
};

export {ProfileSuccession};
