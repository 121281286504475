import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {RealizedDonation} from "../../../../store/realizedDonations/types";
import {createRealizedDonationAction, deleteRealizedDonationAction, updateRealizedDonationAction} from "../../../../store/realizedDonations/actions";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButton, SGButtonGroup} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirNavPlus, SGAvenirStrokedCorbeille, SGAvenirStrokedModification} from "sg-icon-pack-base";
import {SGBlock, SGText} from "sg-typo";
import {SGCard} from "sg-card";
import {DeleteModal} from "../DeleteModal/DeleteModal";
import {SG_BLUE, SG_PINK} from "../../../utils/graphs/colors";
import {FormProvider, useForm} from "react-hook-form";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {SGSelectOption} from "sg-select";
import {FamilyStatus, Member} from "../../../../store/members/types";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {ErrorElement} from "../../atoms/ErrorElement/ErrorElement";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {useFamily} from "../../hooks/useFamily";
import {useRealizedDonations} from "../../hooks/useRealizedDonations";
import {toMembers} from "../../../../store/members/utils";
import {useIntl} from "react-intl";
import {AddMemberModal} from "../AddMemberModal/AddMemberModal";
import {DateInput, DatePattern} from "../../atoms/DateInput/DateInput";
import {SGSpace} from "sg-space";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {AssetType} from "../../../../store/assets/types";
import {EditorContext} from "../../providers/EditorProvider";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";
import {isRealizedDonation} from "../../../../store/realizedDonations/typeguards";

interface RealizedDonationElementProps {
   onCancel?: () => void;
   isCreating?: boolean;
   realizedDonation: RealizedDonation | Omit<RealizedDonation, "id">;
   cypressName: string;
}

const RealizedDonationElement: FunctionComponent<RealizedDonationElementProps> = (props: RealizedDonationElementProps) => {
   const {realizedDonation, onCancel, isCreating, cypressName} = props;

   const dispatch = useDispatch();
   const intl = useIntl();

   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
   const [isEditingDonation, setEditingDonation] = useState(false);
   const [isSubmitting, setSubmitting] = useState(false);
   const [eligibleMembers, setEligibleMembers] = useState(new Array<Member>());
   const [isAddMemberModalVisible, setAddMemberModalVisible] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);
   const family = useFamily();
   const realizedDonations = useRealizedDonations();

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });
   const {handleSubmit} = methods;

   useEffect(() => {
      if (isSubmitting) {
         setEditingDonation(false);
         setSubmitting(false);
      }
   }, [realizedDonations]);

   // Permet de mettre le composant en édition à l'ajout d'un nouvel élément
   useEffect(() => {
      setEditingDonation(!!isCreating);
   }, []);

   useEffect(() => {
      setEditing(isEditingDonation);
   }, [isEditingDonation]);

   useEffect(() => {
      if (family) {
         setEligibleMembers(toMembers(family).filter((m) => m.status !== FamilyStatus.ME));
      }
   }, [family]);

   const handleOnDelete = () => {
      if (isRealizedDonation(realizedDonation)) {
         dispatch(deleteRealizedDonationAction(realizedDonation));
      }
   };

   const onSelectType = (newRealizedDonationType: string) => {
      const amountOfDonationsOfSameType = realizedDonations.filter((rd) => rd.assetType === newRealizedDonationType).length;
      const defaultName = amountOfDonationsOfSameType < 1 ? `${intl.formatMessage({id: `inheritance.realized-donation.label.${newRealizedDonationType}`})}` :
         `${intl.formatMessage({id: `inheritance.realized-donation.label.${newRealizedDonationType}`})} ${amountOfDonationsOfSameType + 1}`;
      methods.setValue("projectName", defaultName, {shouldValidate: true});
   };

   const onAddNewMember = (member: Member) => {
      methods.setValue("member", String(eligibleMembers.length), {shouldValidate: true});
   };

   const onSubmit = (data: RealizedDonation & { member: string }) => {
      const editedDonation: RealizedDonation | Omit<RealizedDonation, "id"> = {
         ...realizedDonation,
         assetType: data.assetType,
         amount: data.amount,
         member: eligibleMembers[Number(data.member)],
         startDate: data.startDate,
         projectName: data.projectName
      };
      if (isRealizedDonation(editedDonation)) {
         dispatch(updateRealizedDonationAction(editedDonation));
      } else {
         dispatch(createRealizedDonationAction(editedDonation));
      }

      setSubmitting(true);
   };

   const renderRealizedDonationElement = () => (
      <SGGridRow align="center">
         <SGGridCol xs={12} lg={5}>
            <SGText data-cy={`${cypressName}-member`}>
               {realizedDonation.projectName}
            </SGText>
         </SGGridCol>
         <SGGridCol xs={12} lg={5}>
            <SGText data-cy={`${cypressName}-amount`}>
               <CustomNumberFormat thousandSeparator={" "} suffix=" €" decimalScale={0} displayType="text" value={realizedDonation.amount}/>
            </SGText>
         </SGGridCol>
         <SGGridCol xs={12} lg={2} textalign="right">
            <SGButton type="icon" data-cy={`${cypressName}-edit`} onClick={() => setEditingDonation(true)} disabled={isEditing}>
               <SGIcon component={<SGAvenirStrokedModification/>}/>
            </SGButton>
            <SGButton type="icon" data-cy={`${cypressName}-delete`} onClick={() => setDeleteModalVisible(true)} disabled={isEditing}>
               <SGIcon component={<SGAvenirStrokedCorbeille/>}/>
            </SGButton>
         </SGGridCol>
      </SGGridRow>
   );

   const renderRealizedDonationEdit = () => (
      <FormProvider {...methods}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs colLength={10}>
               <SelectInput name="assetType" onValueChange={onSelectType} required defaultValue={realizedDonation?.assetType}
                            placeholder={intl.formatMessage({id: "inheritance.realized-donation.page.choose-type"})} disabled={!isCreating}
                            cypressName="rd-edit-type" label="inheritance.realized-donation.page.type">
                  <SGSelectOption key={0} value={AssetType.CASH_ACCOUNT}>
                     {intl.formatMessage({id: `inheritance.realized-donation.type.${AssetType.CASH_ACCOUNT}`})}
                  </SGSelectOption>
                  <SGSelectOption key={1} value={AssetType.REAL_ESTATE_PRIMARY}>
                     {intl.formatMessage({id: `inheritance.realized-donation.type.${AssetType.REAL_ESTATE_PRIMARY}`})}
                  </SGSelectOption>
               </SelectInput>

               <StringInput label="inheritance.realized-donation.page.label" name="projectName" cypressName="rd-edit-label"
                            defaultValue={realizedDonation?.projectName}/>

               {eligibleMembers && (
                  <SGBlock>
                     <SelectInput name="member" required
                                  defaultValue={realizedDonation?.member ? String(eligibleMembers.map((el) => el.id).indexOf(realizedDonation.member.id)) : undefined}
                                  placeholder={intl.formatMessage({id: "common.choose.member"})} cypressName="rd-edit-member"
                                  label="inheritance.realized-donation.page.member">
                        {eligibleMembers.map((member: Member, index: number) => (
                           <SGSelectOption key={member.id || 0} value={String(index)}>
                              {member.name}
                           </SGSelectOption>
                        ))}
                     </SelectInput>
                     <SGSpace disableautomargin direction="vertical">
                        <SGTextIntl intlId="member.not-in-list"/>
                        <SGButtonIntl type="tertiary" data-cy="show-add-member-modal" onClick={() => setAddMemberModalVisible(true)}
                                      icon={<SGIcon currentcolor component={<SGAvenirNavPlus/>}/>} intlId="common.add-member"/>
                     </SGSpace>
                  </SGBlock>
               )}
               <DateInput defaultValue={realizedDonation?.startDate} name="startDate" label="inheritance.realized-donation.page.year"
                          cypressName="rd-date-input"
                          minYear={1980} maxYear={new Date().getFullYear()} pattern={DatePattern.YEARS}/>
               <NumericInput name="amount" cypressName="rd-amount-input" defaultValue={realizedDonation?.amount} label="common.amount"
                             placeholder={intl.formatMessage({id: "inheritance.realized-donation.form.amount.placeholder"})} suffix="€"/>

            </FormInputs>
            <ErrorElement cypressName="realized-donation" errorTextPrefix="inheritance.realized-donation.api.error"/>

            <SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName="rd-edit-submit" onClick={handleSubmit(onSubmit)}
                             intlId="common.save"/>
               <SGButtonIntl type="secondary" size="sm" cypressName="rd-edit-cancel" onClick={() => (onCancel ? onCancel() : setEditingDonation(false))}
                             intlId="common.cancel"/>
            </SGButtonGroup>
         </form>
      </FormProvider>
   );

   return (
      <SGCard align="middle"
              borderleft={isCreating ? "" : `.3rem solid ${realizedDonation.assetType === AssetType.CASH_ACCOUNT ? SG_BLUE.hexCode : SG_PINK.hexCode}`}>
         {isEditingDonation && renderRealizedDonationEdit()}
         {!isEditingDonation && renderRealizedDonationElement()}

         {/* Modal de confirmation de suppression */}
         {isDeleteModalVisible && (
            <DeleteModal hideModal={() => setDeleteModalVisible(false)} onSubmit={handleOnDelete} cypressName={`${cypressName}-confirm-delete`}
                         textId="common.delete.modal.text"/>
         )}

         {isAddMemberModalVisible && (
            <AddMemberModal displayFamilyType cypressName="rd-add-member" hideModal={() => setAddMemberModalVisible(false)}
                            onAddNewMemberSubmit={onAddNewMember}/>
         )}
      </SGCard>
   );
};

export {RealizedDonationElement};
