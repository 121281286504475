import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGMessage } from "sg-message";
import { SGModal } from "sg-modal";
import { SGBox, SGSpace } from "sg-space";
import { SGStep, SGSteps } from "sg-steps";
import { SGText, SGTitle } from "sg-typo";
import {
    SupervisionState,
    possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { resetTunnelAction } from "store/tunnel/actions";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { TunnelWelcome } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWelcome/TunnelWelcome";
import { TunnelWorkIncomes } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWorkIncomes/TunnelWorkIncomes";
import { TunnelWorkIncomesForecast } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWorkIncomesForecast/TunnelWorkIncomesForecast";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { State } from "../../store/store";
import { SGButtonIntl } from "../components/atoms/SGButtonIntl/SGButtonIntl";
import { SGTextIntl } from "../components/atoms/SGTextIntl/SGTextIntl";
import { SGTitleIntl } from "../components/atoms/SGTitleIntl/SGTitleIntl";
import { Tunnel } from "../components/organisms/Tunnel/Tunnel/Tunnel";
import { TunnelAssets } from "../components/organisms/Tunnel/TunnelAssets/TunnelAssets";
import { TunnelFamilySituation as TunnelFamilySituationAssu } from "../components/organisms/Tunnel/TunnelAssu/TunnelFamilySituation/TunnelFamilySituation";
import { TunnelFamilySituation } from "../components/organisms/Tunnel/TunnelFamilySituation/TunnelFamilySituation";
import { TunnelHome } from "../components/organisms/Tunnel/TunnelHome/TunnelHome";
import { TunnelIncomes } from "../components/organisms/Tunnel/TunnelIncomes/TunnelIncomes";
import { TunnelSpendingProfile } from "../components/organisms/Tunnel/TunnelSpendingProfile/TunnelSpendingProfile";
import { TunnelAssu } from "../components/templates/TunnelAssu/TunnelAssu";
import { useHistory } from "react-router-dom";
import { LOGOUT } from "website/utils/privateRoutes";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const TunnelPage: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const history = useHistory();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    // On masque le tunnel tant que la popin n'est pas fermée
    const [visible, setVisible] = useState(false);
    const { trackPage, trackPopUp } = useTracking();

    const supervisionState: SupervisionState = useSelector<State, SupervisionState>((state) => state.supervision);

    // Fetch pour plus tard
    const account = useAccount();
    const family = useFamily();
    const { personalInformationsState } = usePersonalInformations();

    const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);
    const isTunnelReforme = useSelector<State, boolean>((state) => state.tunnel.isTunnelReforme);

    const stepComponents = [TunnelFamilySituation, TunnelHome, TunnelAssets, TunnelIncomes, TunnelSpendingProfile];
    const stepComponentsAssu = [TunnelWelcome, TunnelFamilySituationAssu, TunnelWorkIncomes, TunnelWorkIncomesForecast];

    const categories = [
        intl.formatMessage({ id: "tunnel.step.situation" }),
        intl.formatMessage({ id: "tunnel.step.netWorth" }),
        intl.formatMessage({ id: "tunnel.step.incomes-expenses" }),
    ];
    const categoriesAssu = [
        intl.formatMessage({ id: "tunnel.step.etape1" }),
        intl.formatMessage({ id: "tunnel.step.etape2" }),
        intl.formatMessage({ id: "tunnel.step.etape3" }),
        intl.formatMessage({ id: "tunnel.step.etape4" }),
    ];

    useEffect(() => {
        trackPage("parcours-onboarding", "ma-retraite", PAGE_TYPE_FORMULAIRE, "inscription", "1");
        saveEvent(trackingEvent.ONBOARDING_MA_RETRAITE);
        dispatch(resetTunnelAction());
    }, []);

    useScrollToTopWithCurrentStep(currentStep);

    const getIndexStep = (): number => {
        if (currentStep === 0) {
            return 0;
        }
        if (currentStep > 0 && currentStep < 3) {
            return 1;
        }

        return 2;
    };

    const getStatus = (index: number): string => {
        const isAssu = window.env.REACT_APP_INSTANCE === "assu";
        const indexStep: number = isAssu ? currentStep : getIndexStep();
        if (indexStep === index) {
            return "process";
        }
        if (indexStep < index) {
            return "wait";
        }

        return isAssu ? "finish" : "visited";
    };

    const handleLogout = () => {
        history.push(LOGOUT);
    };

    if (window.env.REACT_APP_INSTANCE === "assu") {
        return (
        <SGLayout tagName="div">
        <SGContent disableautomargin>
          <SGGridRow justify="center" gutter={[0, 20]}>
            <SGGridCol span={12}>
                {!possedeDroitsActionUtilisateur(supervisionState) &&
                    <SGAlert
                        description={<SGText size="m">Le client n’a pas réalisé son onboarding, invitez-le à découvrir Mon&#160;Compagnon&#160;Retraite.</SGText>}
                        icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                        type="info"
                        span={9}
                        action={<SGButton size="small" onClick={handleLogout}>Retour</SGButton>}
                        actionPlacement="top"
                    />
                }
            </SGGridCol>
            <SGMediaQuery minwidth="sm">
                <SGGridCol span={12}>
                    <SGBox margin={{top: isPhone ? "lg" : "sm"}}>
                        <SGTitle textalign="center" caps level={2}>
                            {intl.formatMessage({ id: isTunnelReforme && currentStep !== 0 ? "tunnel.reforme.title" : "tunnel.title" })}
                        </SGTitle>
                    </SGBox>
                </SGGridCol>
            </SGMediaQuery>
            <SGGridCol span={12}>
                <SGBox margin={{bottom: "md"}}>
                    <SGSteps current={currentStep}>
                        {categoriesAssu.map((categorie, index) => (
                            <SGStep title={categorie} status={getStatus(index)} key={categorie} />
                        ))}
                    </SGSteps>
                </SGBox>
            </SGGridCol>
          </SGGridRow>
        </SGContent>
        <SGLayout background="light">
          <SGHeader disableautomargin />
          <SGContent disableautomargin>
            <DetailTabProvider>
                <TunnelAssu steps={stepComponentsAssu} />
            </DetailTabProvider>
          </SGContent>
        </SGLayout>
      </SGLayout>
    )
}

    // !assu
    return (
        <SGContent span={8}>
            <SGSteps current={getIndexStep()}>
                {categories.map((categorie: string, index: number) => (
                    <SGStep
                        title={categorie}
                        className={getStatus(index) === "visited" || getStatus(index) === "process" ? "tunnel__step--visited" : ""}
                        status={getStatus(index)}
                        key={categorie}
                    />
                ))}
            </SGSteps>
            <Tunnel steps={stepComponents} />
            <SGModal
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                    trackPopUp(false, "funnel", "bienvenue::clic-sur-croix-fermeture", "inscription", "0");
                }}
            >
                <SGMessage
                    extra={
                        <SGButtonIntl
                            type="primary"
                            size="sm"
                            cypressName="disclaimer-submit"
                            onClick={() => {
                                setVisible(false);
                                trackPopUp(false, "funnel", "bienvenue::clic-sur-demarrer", "inscription", "0");
                            }}
                            intlId="common.start"
                        />
                    }
                    title={<SGTitleIntl intlId="tunnel.disclaimer.modal.header" cypressName="tunnel-disclaimer-modal-title" />}
                >
                    <SGSpace direction="vertical">
                        <SGTextIntl key="text-1" intlId="tunnel.disclaimer.modal.message1" transformations={{ b: (word: string) => <strong>{word}</strong> }} />
                        <SGTextIntl key="text-2" intlId="tunnel.disclaimer.modal.message2" />
                    </SGSpace>
                </SGMessage>
            </SGModal>
        </SGContent>
    );
};
