import { ReactNodeLike } from "prop-types";
import React, { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGInputNumber } from "sg-input";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGPopover } from "sg-popover";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { DashboardAge } from "store/dashboard/types";
import { Image } from "website/components/atoms/Image/Image";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { Loader } from "../Loader/Loader";
import "./EpargnerCard.scss";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useSelector } from "react-redux";
import { State } from "store/store";

interface EpargnerCardProps {
  salaryMonth?: number;
  color?: string;
  // Pour afficher l'âge dans la carte
  selectedRetirementAge?: DashboardAge;
  isInput?: boolean;
  setSalaryMonth?: (value: number) => void;
  isAssetsNotChecked?: boolean;
  comparer?: boolean;
  showLoader?: boolean;
  environ?: boolean;
  title?: string;
  subtitle?: React.ReactNode;
  className?: string;
  icone?: ReactNodeLike;
  tooltip?: string;
}

// Durée d'épargne
export const getDureeEpargneYear = (selectedRetirementAge: DashboardAge) =>
  selectedRetirementAge !== undefined &&
  (
    selectedRetirementAge.retirementYear - new Date().getFullYear()
  ).toLocaleString("fr-FR");

// Durée: différence entre l'année des 91 ans de l'utilisateur et son âge de retraite choisi
export const getDureeComplementYear = (selectedRetirementAge: DashboardAge) =>
  selectedRetirementAge !== undefined &&
  (91 - selectedRetirementAge.retirementAgeYear).toLocaleString("fr-FR");

const EpargnerCard: FunctionComponent<EpargnerCardProps> = (
  props: EpargnerCardProps
) => {
  const {
    salaryMonth,
    color,
    selectedRetirementAge,
    isInput,
    setSalaryMonth,
    isAssetsNotChecked,
    comparer,
    showLoader,
    environ,
    title,
    subtitle,
    className,
    icone,
    tooltip,
  } = props;
  const intl = useIntl();
  const [price, setPrice] = useState("");
  const [priceMonth, setPriceMonth] = useState("");
  const [salaryMonthInput, setSalaryMonthInput] = useState(salaryMonth);
  const [cursorOut, setCursorOut] = useState(true);

  const classNameColor = `number-per-time__${color}`

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const { maxEpargneInput } = useMontantsMax();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  useEffect(() => {
    setPrice(`number-per-time ${classNameColor}`);
    setPriceMonth(`number-per-time-price number-per-time-price__${color}`);
  }, []);

  // Arrondit le montant d'épargne lorsque l'on sort du champ de saisie
  useEffect(() => {
    if (cursorOut && salaryMonthInput && setSalaryMonth) {
      setSalaryMonthInput(roundDown10(salaryMonthInput));
      setSalaryMonth(roundDown10(salaryMonthInput));
    }
  }, [cursorOut]);

  const onSalaryChangeInput = (value: unknown) => {
    if (setSalaryMonth !== undefined && typeof value === "number") {
      setSalaryMonthInput(value);
      setSalaryMonth(value);
    }
  };

  const getSalaryMonth = () => {
    if (isAssetsNotChecked) {
      return "--";
    }

    return salaryMonth?.toLocaleString("fr-FR") || "--";
  };

  const getLoaderOrSalaryMonth = () => {
    if (showLoader) {
      return <Loader />;
    }

    return getSalaryMonth();
  };

  const getMontant = () => (
    <SGBox key="box_montant" style={comparer ? { lineHeight: "2.25rem" } : {}}>
      <SGText className={price}>
        {intl.formatMessage(
          {
            id: `parcoursRevenusComplementaires.estimate.${
              environ ? "salary.environ" : "salary"
            }`,
          },
          {
            salary: isInput ? "" : getLoaderOrSalaryMonth(),
            label: (word: string) => (
              <>
                <SGMediaQuery minwidth="sm">
                  <label
                    className={
                      comparer ? "epargner-card__comparer-month" : priceMonth
                    }
                  >
                    {word}
                  </label>
                </SGMediaQuery>

                <SGMediaQuery minwidth={0} maxwidth="xs">
                  <label style={{ fontSize: "14px" }} className={priceMonth}>
                    {word}
                  </label>
                </SGMediaQuery>
              </>
            ),
          }
        )}
      </SGText>
    </SGBox>
  );

  const getBoxMontant = () => {
    const padding = isPhone ? "sm" : "xxs";

    return (
      <SGGridRow align="middle" justify={isPhone ? "start" : "center"}>
        <SGMediaQuery minwidth="sm">
          <SGGridCol flex="initial">
            <SGIcon size="s" currentcolor component={icone} />
          </SGGridCol>
        </SGMediaQuery>
        {isInput ? (
          <SGGridCol flex="1 0 0%" align="end">
            <SGSpace disableautomargin align="end">
              {isInput && (
                <SGInputNumber
                  key="inputNumber"
                  disableautomargin
                  value={salaryMonth}
                  onChange={(value: any) => onSalaryChangeInput(value)}
                  unitvalue=""
                  min={0}
                  max={maxEpargneInput}
                  placeholder="--"
                  precision={0}
                  onFocus={() => setCursorOut && setCursorOut(false)}
                  onBlurCapture={() => setCursorOut && setCursorOut(true)}
                  disabled={
                    !possedeDroitsActionUtilisateur(supervisionState)
                  }
                />
              )}
              <SGBox key="box-montant" padding={isInput ? {bottom: padding} : {}}>
                {getMontant()}
              </SGBox>
            </SGSpace>
          </SGGridCol>
        ) : (
          <SGGridCol align="end">{getMontant()}</SGGridCol>
        )}
      </SGGridRow>)
    };

  return (
    <>
    {!comparer ? (
      <>
        { title && subtitle &&
          <SGGridRow>
            <SGGridCol textalign={isPhone ? "left" : "center"} flex="1 0 0%">
              <SGText size={isPhone ? "l" : "xl"} weight="600">
                {intl.formatMessage({ id: title })}
              </SGText>
              <SGBox margin={{ bottom: "sm" }} size="xs">
                <>
                  <SGText color="lighter" size={isPhone ? "m" : "xl"}>
                    {subtitle}
                  </SGText>
                  {tooltip && (
                    <SGPopover
                      content={
                        <SGText>{intl.formatMessage({ id: tooltip })}</SGText>
                      }
                      placement="bottom"
                    >
                      <SGButton
                        icon={
                          <SGIcon
                            component={<SGAvenirStatusInfo />}
                            size="s"
                          />
                        }
                        type="icon"
                      />
                    </SGPopover>
                  )}
                </>
              </SGBox>
            </SGGridCol>
            <SGMediaQuery minwidth={0} maxwidth="xs">
              <SGGridCol flex="initial">
                <SGIcon size="s" currentcolor component={icone} />
              </SGGridCol>
            </SGMediaQuery>
          </SGGridRow>
        }
        <SGMediaQuery minwidth="sm">
          {isInput ? (
              <Image
                src="images/parcoursEpargner/epargner-card-blue.svg"
                alt="epargner-card-blue"
                className="epargner-card__image-card epargner-card__image-card-margin"
              />
            ) : (
              <div className={`epargner-card__padding ${selectedRetirementAge && "epargner-card__padding-objectif"}`}>
                <Image
                  src="images/parcoursEpargner/epargner-card-grey.svg"
                  alt="epargner-card-grey"
                  className="epargner-card__image-card epargner-card__image-card-margin"
                />
              </div>
          )}
          <div className={`epargner-card__container epargner-card__container-${isInput ? "bleu" : "grey"}`}>
            <SGBox padding={isPhone ? {} : { top: "lg", bottom: "md" }}>
              {getBoxMontant()}
            </SGBox>
          </div>
        </SGMediaQuery>
        <SGMediaQuery minwidth={0} maxwidth="xs">
          {getBoxMontant()}
        </SGMediaQuery>
      </>
      ) : (
        <>
         <SGGridRow>
            {color === "blue" ? (
              <Image
                src="images/parcoursEpargner/epargner-comparer-card-blue.svg"
                alt="epargner-comparer-card-blue"
                className="epargner-card__image-card epargner-card__image-card-blue-comparer"
              />
            ) : (
              <Image
                src="images/parcoursEpargner/epargner-comparer-card-grey.svg"
                alt="epargner-comparer-card-grey"
                className="epargner-card__image-card epargner-card__image-card-grey epargner-card__image-card-grey-margin"
              />
            )}
          </SGGridRow>
          <div className="epargner-card__container epargner-card__comparer">
            <SGGridRow
              justify="center">
              <SGGridCol>{getMontant()}</SGGridCol>
            </SGGridRow>
            {selectedRetirementAge && (
              <SGGridRow justify="center">
                <SGGridCol>
                  <SGText>
                    <FormattedMessage
                      id={`parcoursEpargner.card.annee.${
                        color === "blue" ? "pendant" : "environ"
                      }`}
                      values={{
                        dureeEpargne:
                          color === "blue"
                            ? getDureeEpargneYear(selectedRetirementAge)
                           : getDureeComplementYear(selectedRetirementAge),
                      }}
                    />
                  </SGText>
                </SGGridCol>
              </SGGridRow>
            )}
          </div>
        </>
      )}
    </>
  );
};

export { EpargnerCard };
