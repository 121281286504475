import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Slider from "rc-slider";
import {useIntl} from "react-intl";
import {Project, ProjectState, ProjectType} from "../../../../store/projects/types";
import {roundToHundreds, roundToThousands} from "../../../utils/formatting/numberFormatter";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {
   deleteProjectAction,
   getRetirementProjectAction,
   updateProjectAndFetchFeasibilitiesAction,
   updateRetirementProjectValuesAction
} from "../../../../store/projects/actions";
import {State} from "../../../../store/store";
import {calculateAgeByBirthdate, MAX_AGE_TO_RETIRE, MIN_AGE_TO_RETIRE} from "../../../../store/members/utils";
import {SGBlock, SGText} from "sg-typo";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGSpace} from "sg-space";
import {ProjectIcon} from "../../atoms/ProjectIcon/ProjectIcon";
import {CustomNumberFormat} from "../../../utils/formatting/CustomNumberFormat";
import {useFamily} from "../../hooks/useFamily";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirNavCroix} from "sg-icon-pack-base";
import {DeleteModal} from "../DeleteModal/DeleteModal";
import {getIncome} from "../../../../store/incomes/utils";
import {IncomeType} from "../../../../store/incomes/types";
import {useIncomes} from "../../hooks/useIncomes";

interface ProjectConfigurationProps {
   project: Project;
}

const ProjectConfiguration = ({project}: ProjectConfigurationProps) => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();
   const incomes = useIncomes();
   const hasFetchedIncomesAndFamily = useSelector<State, boolean>((state) => state.incomes.hasFetched && state.family.hasFetched);

   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   const myAge = calculateAgeByBirthdate(family.me.birthday);
   const partnerAge = (!family.partner || !family.partner.birthday) ? 0 : calculateAgeByBirthdate(family.partner.birthday);

   const [horizon, setHorizon] = useState(0);
   const [projectAmount, setProjectAmount] = useState(0);

   const [myRetirementAge, setMyRetirementAge] = useState<number>();
   const [partnerRetirementAge, setPartnerRetirementAge] = useState<number>();
   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

   useEffect(() => {
      if (project.projectType === ProjectType.RETIREMENT_PROJECT && projectState.hasFetchedMyRetirementSimulation) {
         setMyRetirementAge(new Date(projectState.retirementProject.myRetirement.memberRetirementDate).getFullYear() -
            new Date(projectState.retirementProject.myRetirement.birthdate).getFullYear()
         );
         if (projectState.retirementProject.partnerRetirement && projectState.hasFetchedPartnerRetirementSimulation) {
            setPartnerRetirementAge(new Date(projectState.retirementProject.partnerRetirement.memberRetirementDate).getFullYear() -
               new Date(projectState.retirementProject.partnerRetirement.birthdate).getFullYear()
            );
         }
      }
   }, [projectState, project]);

   useEffect(() => {
      const projectStartDate: number = new Date(project.startDate).getFullYear();
      setHorizon(projectStartDate - new Date().getFullYear());
      setProjectAmount(project.amount);
   }, [project]);

   useEffect(() => {
      // si le projet actuel est un projet retraite, on fait un appel supplémentaire pour plus d'informations
      if (!projectState.hasFetchedMyRetirementSimulation && hasFetchedIncomesAndFamily && project.projectType === ProjectType.RETIREMENT_PROJECT) {
         dispatch(
            getRetirementProjectAction(
               project.id,
               true,
               getIncome(incomes, IncomeType.WORK_INCOME, family.me)?.monthlyAmount,
               getIncome(incomes, IncomeType.WORK_INCOME, family.partner)?.monthlyAmount
            )
         );
      }
      if (isDeleteModalVisible) {
         setDeleteModalVisible(false);
      }
   }, [project, incomes, hasFetchedIncomesAndFamily, projectState.hasFetchedMyRetirementSimulation, family]);

   const handleDeleteProject = () => {
      dispatch(deleteProjectAction(project));
   }

   const updateProjectAndGetFeasibility = () => {
      if (project.projectType === ProjectType.RETIREMENT_PROJECT) {
         // Mise à jour de l'horizon sur la page (cas première ouverture de page)
         if (myRetirementAge) {
            setHorizon(new Date(projectState.retirementProject.myRetirement.birthdate).getFullYear() + myRetirementAge - new Date().getFullYear());
         } else if (partnerRetirementAge) {
            setHorizon(new Date(projectState.retirementProject.partnerRetirement.birthdate).getFullYear() + partnerRetirementAge - new Date().getFullYear());
         }

         // Mise à jour du retirementProject dans le store avec memberRetirementDate, retirementIncome et dépenses
         const newRetirementProject = {
            ...projectState.retirementProject,
         };
         if (!!newRetirementProject.myRetirement && myRetirementAge) {
            const birthdate = new Date(projectState.retirementProject.myRetirement.birthdate);
            newRetirementProject.myRetirement = {
               ...newRetirementProject.myRetirement,
               memberRetirementDate: new Date(birthdate.setFullYear(birthdate.getFullYear() + myRetirementAge)),
               retirementIncomes: [
                  {
                     name: "Retirement Income",
                     income: newRetirementProject.myRetirement.retirementYearToRetirementIncomeMap[myRetirementAge],
                  },
               ]
            }
         }
         if (!!newRetirementProject.partnerRetirement && partnerRetirementAge) {
            const birthdate = new Date(projectState.retirementProject.partnerRetirement.birthdate);
            newRetirementProject.partnerRetirement = {
               ...newRetirementProject.partnerRetirement,
               memberRetirementDate: new Date(birthdate.setFullYear(birthdate.getFullYear() + partnerRetirementAge)),
               retirementIncomes: [
                  {
                     name: "Retirement Income",
                     income: newRetirementProject.partnerRetirement.retirementYearToRetirementIncomeMap[partnerRetirementAge],
                  },
               ]
            }
         }

         newRetirementProject.expenses = {
            ...newRetirementProject.expenses,
            common: projectAmount,
            total: newRetirementProject.expenses.other + newRetirementProject.expenses.projects + projectAmount,
         };

         dispatch(updateRetirementProjectValuesAction(newRetirementProject));
      }

      const projectToDispatch = {
         ...project,
         startDate: new Date(new Date().setFullYear(new Date().getFullYear() + horizon)).toISOString()
      }

      if (projectToDispatch.endDate) {
         const durationRecurrentProject = new Date(projectToDispatch.endDate).getFullYear() - new Date(projectToDispatch.startDate).getFullYear();
         projectToDispatch.endDate = new Date(new Date().setFullYear(new Date().getFullYear() + projectToDispatch.horizon + durationRecurrentProject)).toISOString();
      }

      dispatch(updateProjectAndFetchFeasibilitiesAction({...projectToDispatch, amount: projectAmount, horizon}));
   };

   // Fonctions utilitaires cypress pour manipuler les sliders
   if (window.Cypress) {
      window.app = {
         setHorizon,
         setProjectAmount,
         setMyRetirementAge,
         setPartnerRetirementAge,
         updateProjectAndGetFeasibility
      };
   }

   const getProjectMaxAmountValue = (): number => {
      if ([ProjectType.ADDITIONAL_INCOME, ProjectType.CHANGE_LIFE_PROJECT, ProjectType.RETIREMENT_PROJECT].includes(project.projectType)) {
         return project.amount === 0 ? 5000 : roundToHundreds(Math.min(1.5 * project.amount, 21000));
      }

      return roundToThousands(Math.min(1.5 * project.amount, 3000000));
   };

   const getProjectMinAmountValue = (): number => {
      if ([ProjectType.ADDITIONAL_INCOME, ProjectType.CHANGE_LIFE_PROJECT, ProjectType.RETIREMENT_PROJECT].includes(project.projectType)) {
         return roundToHundreds(0.5 * project.amount);
      }

      return roundToThousands(0.5 * project.amount);
   };

   const getProjectAmountStep = (): number =>
      [ProjectType.ADDITIONAL_INCOME, ProjectType.CHANGE_LIFE_PROJECT, ProjectType.RETIREMENT_PROJECT,].includes(project.projectType) ? 100 : 1000;

   const isRecurrentProject = (): boolean => [ProjectType.RETIREMENT_PROJECT, ProjectType.ADDITIONAL_INCOME].includes(project.projectType);

   const isChangeLifeProject = (): boolean => project.projectType === ProjectType.CHANGE_LIFE_PROJECT;

   const isRetirementProject = (): boolean => project.projectType === ProjectType.RETIREMENT_PROJECT;

   return (
      <>
         <SGBlock textalign="right">
            <SGButton type="icon" data-cy="project-delete-button" onClick={() => setDeleteModalVisible(true)}>
               <SGIcon component={<SGAvenirNavCroix/>}/></SGButton>
         </SGBlock>
         <SGSpace disableautomargin align="center" direction="vertical">
            <ProjectIcon projectType={project.projectType}/>
            <SGText strong data-cy="project-name">
               {project.projectName}
            </SGText>
         </SGSpace>

         <SGSpace direction="vertical">
            {/* Slider Horizon */}
            {!isChangeLifeProject() && !isRetirementProject() && (
               <SGSpace direction="vertical">
                  <SGTextIntl intlId="project.configuration.horizon" strong/>
                  <SGGridRow data-cy="project-configuration-slider-horizon" align="middle">
                     <SGGridCol sm={10} xs={7}>
                        {/* @ts-ignore */}
                        <Slider min={1} max={30} value={horizon} onChange={(value) => {
                           setHorizon(value);
                        }} onAfterChange={updateProjectAndGetFeasibility} railStyle={{height: 9}} handleStyle={{height: 20, width: 20, marginTop: -6}}
                                trackStyle={{height: 9}} step={1}/>
                     </SGGridCol>
                     <SGGridCol sm={2} xs={5}>
                        <SGTextIntl cypressName="project-configuration-horizon" intlId="project.configuration.horizon.text.year"
                                    transformations={{horizon: String(horizon)}} strong/>
                     </SGGridCol>
                  </SGGridRow>
               </SGSpace>
            )}
            {/* Slider Ages de la retraite */}
            {isRetirementProject() && (
               <SGSpace direction="vertical">
                  <SGTextIntl intlId="project.configuration.retirement.age" strong/>
                  {!!myRetirementAge && (
                     <>
                        <SGTextIntl intlId="family.status.ME"/>
                        <SGGridRow data-cy="project-configuration-slider-amount">
                           <SGGridCol sm={10} xs={7} align="center">
                              {/* @ts-ignore */}
                              <Slider min={MIN_AGE_TO_RETIRE >= myAge ? MIN_AGE_TO_RETIRE : myAge} max={MAX_AGE_TO_RETIRE} railStyle={{height: 9}}
                                      handleStyle={{height: 20, width: 20, marginTop: -6}} trackStyle={{height: 9}} value={myRetirementAge}
                                      onChange={(value) => {
                                         setMyRetirementAge(value);
                                      }}
                                      onAfterChange={updateProjectAndGetFeasibility} step={1}/>
                           </SGGridCol>
                           <SGGridCol sm={2} xs={5}>
                              <SGText strong data-cy="project-configuration-my-retirement-age">
                                 {`${myRetirementAge} ${intl.formatMessage({id: "common.years"})}`}
                              </SGText>
                           </SGGridCol>
                        </SGGridRow>
                     </>
                  )}
                  {!!partnerRetirementAge && (
                     <>
                        <SGTextIntl intlId="family.status.PARTNER"/>
                        <SGGridRow data-cy="project-configuration-slider-amount">
                           <SGGridCol sm={10} xs={7}>
                              {/* @ts-ignore */}
                              <Slider min={
                                 MIN_AGE_TO_RETIRE >= partnerAge
                                    ? MIN_AGE_TO_RETIRE
                                    : partnerAge
                              } max={MAX_AGE_TO_RETIRE} railStyle={{height: 9}} handleStyle={{height: 20, width: 20, marginTop: -6}} trackStyle={{height: 9}}
                                      value={partnerRetirementAge} onChange={(value) => {
                                 setPartnerRetirementAge(value);
                              }} onAfterChange={updateProjectAndGetFeasibility} step={1}/>
                           </SGGridCol>
                           <SGGridCol sm={2} xs={5}>
                              <SGText strong data-cy="project-configuration-partner-retirement-age">
                                 {`${partnerRetirementAge} ${intl.formatMessage({id: "common.years"})}`}
                              </SGText>
                           </SGGridCol>
                        </SGGridRow>
                     </>
                  )}
               </SGSpace>
            )}
            {/* Slider Montant du projet */}
            {!isChangeLifeProject() && (
               <SGSpace direction="vertical">
                  <SGTextIntl intlId="project.configuration.amount" strong/>
                  <SGGridRow data-cy="project-configuration-slider-amount">
                     <SGGridCol sm={10} xs={7} align="middle">
                        {/* @ts-ignore */}
                        <Slider min={getProjectMinAmountValue()} max={getProjectMaxAmountValue()} railStyle={{height: 9}}
                                handleStyle={{height: 20, width: 20, marginTop: -6}} trackStyle={{height: 9}} value={projectAmount} onChange={(value) => {
                           setProjectAmount(value);
                        }} onAfterChange={updateProjectAndGetFeasibility} step={getProjectAmountStep()}/>
                     </SGGridCol>
                     <SGGridCol sm={2} xs={5}>
                        <SGText data-cy="project-configuration-project-amount" strong>
                           <CustomNumberFormat thousandSeparator={" "} suffix={isRecurrentProject() ? intl.formatMessage({id: "common.euro.per.month"}) : " €"}
                                               displayType="text" value={projectAmount}/>
                        </SGText>
                     </SGGridCol>
                  </SGGridRow>
               </SGSpace>
            )}
         </SGSpace>

         {/* Cas Projet de changement de vie */}
         {isChangeLifeProject() && (
            <SGBlock>
               <SGTextIntl intlId="project.configuration.change.life.project" strong/>
            </SGBlock>
         )}

         {/* Modal de confirmation de suppression */}
         {isDeleteModalVisible && (
            <DeleteModal hideModal={() => setDeleteModalVisible(false)} onSubmit={handleDeleteProject} cypressName="modal-delete-project"
                         textId="common.delete.modal.text"/>
         )}
      </>
   );
};

export {ProjectConfiguration};
