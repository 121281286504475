import { ReactComponent as Phone } from "assets/images/phone-arrow-up-right-regular.svg";
import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { SGModal } from "sg-modal";
import { SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { externalTel, transformTel } from "website/utils/externalTel";
import { MES_SERVICES } from "website/utils/privateRoutes";

type OffreSapiendoModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const OffreSapiendoModal: FunctionComponent<OffreSapiendoModalProps> = (props: OffreSapiendoModalProps) => {
  const { visible, setVisible } = props;
  const intl = useIntl();
  const history = useHistory();
  const { trackPopin, resetTracking } = useTracking();

  useEffect(() => {
    if (visible) {
      trackPopin(false, "tableau-de-bord", "popin-diagnostic-gratuit-sapiendo");
    }
  }, [visible]);

  const handleRedirect = () => {
    trackPopin(true, "tableau-de-bord", "popin-diagnostic-gratuit-sapiendo::clic-sur-decouvrir-les-accompagnements");
    history.push(MES_SERVICES);
  };

  const handleClickTel = () => {
    trackPopin(true, "tableau-de-bord", "popin-diagnostic-gratuit-sapiendo::clic-sur-appeler-gratuitement");
    resetTracking();
    setVisible(false);
  }

  return (
    <SGModal
      visible={visible}
      centered
      closable
      onCancel={() => {
        trackPopin(true, "tableau-de-bord", "popin-diagnostic-gratuit-sapiendo::clic-sur-fermer");
        resetTracking();
        setVisible(false);
      }}
      header={
        <SGTitle level={1} visuallevel={3} textalign="left">
          {intl.formatMessage({ id: "offre.sapiendo.modal.title" })}
        </SGTitle>
      }
    >
      <SGSpace direction="vertical" textalign="left" size="xl">
        <SGTitle key="title" level={2} visuallevel={5} textalign="left">
          {intl.formatMessage({ id: "offre.sapiendo.modal.subtitle" })}
        </SGTitle>
        <SGText key="text">
          { intl.formatMessage(
            { id: "offre.sapiendo.modal.text" },
            {
              ul: (word: string) => <SGBlock component="ul">{word}</SGBlock>,
              li: (word: string) => <li>{word}</li>
            }
          )}
        </SGText>
        <SGLayout
          key="layout"
          background="light"
          tagName="div"
        >
          <SGContent tagName="div" style={{padding: "0.75rem 0"}}>
            <SGGridRow gutter={[16]} >
              <SGGridCol>
                <SGIcon component={<Phone />} />
              </SGGridCol>
              <SGGridCol>
                <SGText weight="600">
                  { intl.formatMessage(
                    { id: "offre.sapiendo.modal.appel" },
                    {
                      number: (word: string) =>
                        <SGLink onClick={handleClickTel} type="primarylink" href={`tel:${externalTel.EXTERNAL_TEL_SAPIENDO}`}>
                          <SGText weight="600" size="l">{transformTel(externalTel[word])}</SGText>
                        </SGLink>
                    }
                  )}
                </SGText>
              </SGGridCol>
            </SGGridRow>
          </SGContent>
        </SGLayout>
        <SGText key="text-contact">
          {intl.formatMessage({ id: "offre.sapiendo.modal.contact"})}
        </SGText>
        <SGGridRow key="row">
          <SGGridCol span={12} textalign="right">
            <SGButton
              icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor/>}
              iconposition="right"
              type="primarylink"
              onClick={handleRedirect}
            >
              {intl.formatMessage({ id: "offre.sapiendo.modal.button"})}
            </SGButton>
          </SGGridCol>
        </SGGridRow>
      </SGSpace>
    </SGModal>
  );
};
