import { FunctionComponent, SyntheticEvent, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { State } from "store/store";
import { SupervisionState } from "store/supervision/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { externalURL } from "website/utils/externalUrl";
import { ParcoursRISPreparationModal } from "./ParcoursRISPreparationModal";

const ParcoursRISCarteCommentFaire: FunctionComponent = () => {
  const intl = useIntl();
  const openLink = useOpenLink();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackClick } = useTracking();

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const [showParcoursRISPreparationModal, setShowParcoursRISPreparationModal] =
    useState(false);

  return (
    <>
      <SGBox
        alignchildren={["center"]}
        margin={isPhone ? { top: "md", bottom: "lg" } : {}}
      >
        <SGSpace direction="vertical" size="xs" textalign="center">
          <SGText key="text-1" size="xxl" weight="600">
            {intl.formatMessage({ id: "parcoursRIS.preparation.text.ris.non" })}
          </SGText>
          <SGText key="text-2" size="l">
            {intl.formatMessage(
              { id: "parcoursRIS.preparation.link.infoRetraite" },
              {
                link: (word) => (
                  <SGLink
                    href={externalURL.EXTERNAL_URL_INFO_RETRAITE}
                    onClick={(event: SyntheticEvent) =>
                      openLink(externalURL.EXTERNAL_URL_INFO_RETRAITE, event)
                    }
                  >
                    <SGText weight="600" underline>
                      {word}
                    </SGText>
                  </SGLink>
                ),
              }
            )}
          </SGText>
          <SGBox key="box-1" margin={{ top: "md" }} forcemargin>
            <SGButton
              type="secondary"
              onClick={() => {
                trackClick("preparation::clic-sur-comment-faire");
                setShowParcoursRISPreparationModal(true);
              }}
              ariaLabel={intl.formatMessage({
                id: "parcoursRIS.preparation.commentFaire.ariaLabel",
              })}
            >
              {intl.formatMessage({
                id: "parcoursRIS.preparation.commentFaire",
              })}
            </SGButton>
          </SGBox>
          {supervisionState.estActive &&
            !supervisionState.estModeConsultation && (
              <SGBox key="box-2" margin={{ top: "sm" }} forcemargin>
                <SGLink href="/RIS_exemple.pdf" target="_blank">
                  <SGText weight="600" underline>
                    Télécharger un RIS de test
                  </SGText>
                </SGLink>
              </SGBox>
            )}
        </SGSpace>
      </SGBox>
      {showParcoursRISPreparationModal && (
        <ParcoursRISPreparationModal
          setVisible={setShowParcoursRISPreparationModal}
          visible={showParcoursRISPreparationModal}
        />
      )}
    </>
  );
};

export { ParcoursRISCarteCommentFaire };
