import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Family, RetirementCategory } from "store/members/types";
import { nextStep, parametersUpdated } from "store/simulateurEconomiesImpots/slice";
import { ParametresSEI, ProvisionEnum, TnsStatusEnum } from "store/simulateurEconomiesImpots/types";
import { getTrackProfile } from "store/simulateurEconomiesImpots/utils";
import { State } from "store/store";
import { RadioGroup } from "website/components/atoms/RadioGroup/RadioGroup";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { DASHBOARD } from "website/utils/privateRoutes";
import "./SituationStep.scss";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { anneePrecedente } from "website/utils/date/DateUtils";

export enum TnsStatusChoicesEnum {
    salarie = 1,
    nonsalarie = 2,
}

const tnsStatusChoicesList = Object.entries(TnsStatusChoicesEnum).filter(([key]) => Number.isNaN(parseInt(key)));
const tnsStatusList = Object.entries(TnsStatusEnum).filter(([key]) => Number.isNaN(parseInt(key)));
const provisions = Object.entries(ProvisionEnum).filter(([key]) => Number.isNaN(parseInt(key)));

export enum DeclarationCommuneChoices {
    YES = "OUI",
    NO = "NON",
}
export enum ConjointTnsChoices {
    YES = "OUI",
    NO = "NON",
}

const SituationStep: FunctionComponent = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { trackClick, trackPage } = useTracking();
    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });
    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const { handleSubmit } = methods;

    const declarationCommuneChoicesList: DeclarationCommuneChoices[] = Object.values(DeclarationCommuneChoices);
    const conjointTnsChoicesList: ConjointTnsChoices[] = Object.values(ConjointTnsChoices);

    const parametresSEI: ParametresSEI = useSelector<State, ParametresSEI>((state) => state.simulateurEconomiesImpots.parameters);
    const family: Family = useSelector<State, Family>((state) => state.family.family);
    const [statutSalarie, setStatutSalarie] = useState<number>();
    const [profile, setProfile] = useState<TnsStatusEnum>();
    const [provision, setProvision] = useState<ProvisionEnum>();
    const [isDeclarationCommune, setIsDeclarationCommune] = useState<DeclarationCommuneChoices>();
    const [isConjointTns, setIsConjointTns] = useState<ConjointTnsChoices>();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [nonSalarie, setNonSalarie] = useState<boolean>(false);

    useEffect(() => {
        trackPage("parcours-sei", "ma-situation", PAGE_TYPE_FORMULAIRE, "simulation", "1");
        // Retour arrière ou seconde visite
        if (parametresSEI.isJointDeclaration !== undefined) {
            setIsDeclarationCommune(parametresSEI.isJointDeclaration ? DeclarationCommuneChoices.YES : DeclarationCommuneChoices.NO);
            setIsConjointTns(parametresSEI.isPartnerTns ? ConjointTnsChoices.YES : ConjointTnsChoices.NO);
            // Si TNS défini on alimente les champs dispositif et profile sinon ils restent undefined
            if (parametresSEI.tnsStatus) {
                setStatutSalarie(TnsStatusChoicesEnum.nonsalarie);
                setProfile(parametresSEI.tnsStatus);
                setProvision(parametresSEI.provision);
            } else {
                setStatutSalarie(TnsStatusChoicesEnum.salarie);
            }
        }
    }, []);

    useEffect(() => {
        // si le profile est exploitant agricole alors la suite du parcours est bloquée
        // si le statut est salarié / fonctionnaire, alors uniquement isDecalarationCommune est obligatoire
        // sinon tous les champs sont obligatoire
        const testDeclarationCommune = !isDeclarationCommune || (isDeclarationCommune === DeclarationCommuneChoices.YES && !isConjointTns);
        setIsSubmitDisabled(statutSalarie === TnsStatusChoicesEnum.nonsalarie ? (!statutSalarie || testDeclarationCommune || !profile || profile === TnsStatusEnum.EXPLOITANT_AGRICOLE || !provision) : (!statutSalarie || testDeclarationCommune));
        // travailleur non salarié
        setNonSalarie(statutSalarie === TnsStatusChoicesEnum.nonsalarie);
    }, [statutSalarie, isDeclarationCommune, isConjointTns, profile, provision]);

    useEffect(() => {
        // Premier parcours SEI
        if (!parametresSEI.simulationDate && parametresSEI.isJointDeclaration === undefined) {
            setStatutSalarie(getFirstStatutSalarie(family.me.retirementType));
        }
    }, [family])

    // définit statutSalarie en fonction de retirementType alimenté depuis l'onboarding / simulateur flash
    const getFirstStatutSalarie = (retirementType?: RetirementCategory) => (retirementType === RetirementCategory.SALARIE_PRIVE_CADRE || retirementType === RetirementCategory.FONCTIONNAIRE) ? TnsStatusChoicesEnum.salarie : TnsStatusChoicesEnum.nonsalarie;

    const handleOnSubmit = () => {
        const tnsStatus = TnsStatusChoicesEnum.nonsalarie === statutSalarie ? profile : undefined;
        dispatch(parametersUpdated({
            isJointDeclaration: isDeclarationCommune === DeclarationCommuneChoices.YES,
            tnsStatus,
            provision: TnsStatusChoicesEnum.nonsalarie === statutSalarie ? provision : undefined,
            // Si on était madelin, on remet à 0
            totalAvailableCeiling: parametresSEI.provision === ProvisionEnum.MADELIN ? undefined : parametresSEI.totalAvailableCeiling,
            isPartnerTns: isConjointTns === ConjointTnsChoices.YES
        }))
        dispatch(nextStep());
        trackClick("ma-situation::clic-sur-suivant", { form_field_1: getTrackProfile(tnsStatus) });
    }

    const handleOnChangeProfileSelection = (value: TnsStatusEnum) => {
        setProfile(value);
        trackClick(`ma-situation::clic-sur-${getTrackProfile(value)}`, { form_field_1: getTrackProfile(value) });
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleOnSubmit)} className="situation-step">
                <SGGridRow >
                    <SGGridCol span={12} sm={6}>
                        <SGGridRow>
                            <SGGridCol span={12}>
                                <SGTitle level={2} textalign="left">
                                    {intl.formatMessage({ id: "simulateurEconomiesImpots.step.situation" })}
                                </SGTitle>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <SGText size="l">
                                    {intl.formatMessage({ id: "simulateurEconomiesImpots.step.situation.description" })}
                                </SGText>
                            </SGGridCol>
                        </SGGridRow>
                        <SGGridRow>
                            <SGGridCol span={12} sm={8}>
                                <SGSelect
                                    label={<SGText size="l">{intl.formatMessage({ id: "mesServices.services.youAre" })}</SGText>}
                                    onChange={setStatutSalarie}
                                    placeholder="Sélectionnez"
                                    value={[statutSalarie || ""]}
                                >
                                    <SGSelectOption value="" style={{ display: "none" }}>
                                        Sélectionnez
                                    </SGSelectOption>
                                    {tnsStatusChoicesList.map(([name, choice]) => (
                                        <SGSelectOption key={name} value={choice}>
                                            <SGText>
                                                {intl.formatMessage({ id: `simulateurEconomiesImpots.estimer.statut.${name}` })}
                                            </SGText>
                                        </SGSelectOption>
                                    ))}
                                </SGSelect>
                            </SGGridCol>
                            {nonSalarie &&
                                <>
                                    <SGGridCol span={12} sm={8}>
                                        <SGSelect
                                            label={<SGText size="l">{intl.formatMessage({ id: "mesServices.services.yourProfile" })}</SGText>}
                                            onChange={handleOnChangeProfileSelection}
                                            placeholder="Sélectionnez"
                                            value={[profile || ""]}
                                            status={profile === TnsStatusEnum.EXPLOITANT_AGRICOLE ? "error" : "default"}
                                            validate={intl.formatMessage({ id: "simulateurEconomiesImpots.step.situation.statut.error" })}
                                        >
                                            <SGSelectOption value="" style={{ display: "none" }}>
                                                Sélectionnez
                                            </SGSelectOption>
                                            {tnsStatusList.map(([name, choice]) => (
                                                <SGSelectOption key={name} value={choice}>
                                                    <SGText>
                                                        {intl.formatMessage({ id: `simulateurEconomiesImpots.estimer.profile.${name}` })}
                                                    </SGText>
                                                </SGSelectOption>
                                            ))}
                                        </SGSelect>
                                    </SGGridCol>
                                    {(profile === TnsStatusEnum.BIC_BNC || profile === TnsStatusEnum.GERANT) &&
                                        <>
                                            <SGGridCol span={12} sm={8}>
                                                <SGSelect
                                                    label={<SGText size="l">{intl.formatMessage({ id: "mesServices.services.yourProvision" })}</SGText>}
                                                    onChange={setProvision}
                                                    placeholder="Sélectionnez"
                                                    value={[provision || ""]}
                                                >
                                                    <SGSelectOption value="" style={{ display: "none" }}>
                                                        Sélectionnez
                                                    </SGSelectOption>
                                                    {provisions.map(([name, choice]) => (
                                                        <SGSelectOption key={name} value={choice}>
                                                            <SGText>
                                                                {intl.formatMessage({ id: `simulateurEconomiesImpots.estimer.provision.${name}` })}
                                                            </SGText>
                                                        </SGSelectOption>
                                                    ))}
                                                </SGSelect>
                                            </SGGridCol>
                                            <SGGridCol>
                                                <SGBox margin={{ top: "md" }}>
                                                    <SGText>
                                                        {intl.formatMessage({ id: "simulateurEconomiesImpots.step.situation.message.madelin" }, {anneeN_1: anneePrecedente})}
                                                    </SGText>
                                                </SGBox>
                                            </SGGridCol>
                                        </>
                                    }
                                </>
                            }
                            <SGGridCol span={12}>
                                <SGBox margin={{ top: "lg" }}>
                                    <SGGridRow>
                                        <RadioGroup
                                            title="simulateurEconomiesImpots.step.situation.decalarationCommune"
                                            radioGroupList={declarationCommuneChoicesList}
                                            selectedValue={isDeclarationCommune}
                                            setValue={(val: any) => setIsDeclarationCommune(val)}
                                            name="declaration-commune"
                                            fontSize="l"
                                            orientation="horizontal"
                                        />
                                    </SGGridRow>
                                </SGBox>
                            </SGGridCol>
                            {isDeclarationCommune === DeclarationCommuneChoices.YES &&
                                <SGGridCol span={12}>
                                    <SGBox margin={{ top: "md" }}>
                                        <SGGridRow>
                                            <RadioGroup
                                                title="simulateurEconomiesImpots.step.situation.conjointTns"
                                                radioGroupList={conjointTnsChoicesList}
                                                selectedValue={isConjointTns}
                                                setValue={(val: any) => setIsConjointTns(val)}
                                                name="conjoint-tns"
                                                fontSize="l"
                                                orientation="horizontal"
                                            />
                                        </SGGridRow>
                                    </SGBox>
                                </SGGridCol>
                            }
                        </SGGridRow>
                    </SGGridCol>
                    <SGGridCol span={12}>
                        <SGBox margin={{ top: isPhone ? "lg" : "xxl" }}>
                            <SGButtonGroup layout={isPhone ? "column" : "row"} align="opposite">
                                <BoutonSupervision disabled={isSubmitDisabled}
                                    style={!isPhone ? {order: 1} : {}}
                                    onClick={handleOnSubmit}>
                                    {intl.formatMessage({ id: "tunnel.buttons.next" })}
                                </BoutonSupervision>
                                <SGLink
                                    type="link"
                                    cypressName="tunnel-previous"
                                    href={`#${DASHBOARD}`}
                                >
                                    {intl.formatMessage({ id: "tunnel.buttons.previous" })}
                                </SGLink>
                            </SGButtonGroup>
                        </SGBox>
                    </SGGridCol>
                </SGGridRow>
            </form>
        </FormProvider>
    )
};

export { SituationStep };
