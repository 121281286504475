import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AccountState} from "../../../store/account/types";
import {getAccountAction} from "../../../store/account/actions";
import {getCanScheduleMeeting} from "../../../store/callToAction/actions";
import {CallToActionState, MeetingAppointmentState} from "../../../store/callToAction/types";

const useCustomerMeetingStatePrefix = (customTextPrefix: string | undefined) => {
   const dispatch = useDispatch();
   const [prefix, setPrefix] = useState<string>(customTextPrefix || "download.simulation");
   const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
   const callToActionState: CallToActionState = useSelector<State, CallToActionState>((state) => state.callToAction);

   useEffect(() => {
      if (!accountState.hasFetched && sessionStorage.getItem("token")) {
         dispatch(getAccountAction());
      }
   }, []);

   useEffect(() => {
      dispatch(getCanScheduleMeeting(accountState.account.cookieProvider));
   }, [accountState]);

   useEffect(() => {
      switch (callToActionState.meetingAppointmentState) {
         case MeetingAppointmentState.CAN_BE_SENT:
            setPrefix("be.counseled");
            break;
         case MeetingAppointmentState.ALREADY_SENT_FOR_THIS_USER:
            setPrefix("already.scheduled");
            break;
         default:
            setPrefix(!customTextPrefix ? "download.simulation" : customTextPrefix);
            break;
      }
   }, [callToActionState.meetingAppointmentState]);

   return prefix;
};

export {useCustomerMeetingStatePrefix};
