import React, {FunctionComponent, useEffect, useState,} from "react";
import {FormProvider, useFormContext} from "react-hook-form";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {AvailableIncomeType, Income, IncomeType,} from "../../../../store/incomes/types";
import {AssetEdit} from "../AssetEdit/AssetEdit";
import {AssetType, AssetWithValuation, RealEstateAsset,} from "../../../../store/assets/types";
import {AssetEditFormData, parseFormDataForAsset} from "../../../../store/assets/utils";
import {
   createIncomeAction,
   createPlannedSavingsBuybackIncomeAction,
   updateIncomeAction,
   updatePlannedSavingsBuybackIncomeAction,
} from "../../../../store/incomes/actions";
import {createRealEstateAsset} from "../../../../store/assets/actions";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {MonthlyYearly, NumericInput} from "../../atoms/NumericInput/NumericInput";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {useInvestmentAssets} from "../../hooks/useInvestmentAssets";
import {useLoans} from "../../hooks/useLoans";
import {useFamily} from "../../hooks/useFamily";
import {useIncomes} from "../../hooks/useIncomes";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {calculateAgeByBirthdate, getMembersOfCouple, MIN_AGE_TO_RETIRE} from "../../../../store/members/utils";
import {AssetSelectOption} from "../../atoms/AssetSelectOption/AssetSelectOption";
import {isPlannedSavingsBuybackIncome} from "../../../../store/incomes/typeguards";
import {ErrorElement} from "../../atoms/ErrorElement/ErrorElement";
import {FamilyStatus} from "../../../../store/members/types";
import {useRetirementProject} from "../../hooks/useRetirementProject";
import {getIncome} from "../../../../store/incomes/utils";
import {updateRetirementProjectWithNewIncome} from "../../../../store/projects/actions";
import {State} from "../../../../store/store";

interface IncomeEditProps {
   income?: Income;
   onCancel: () => void;
   cypressName: string;
}

interface IncomeProjectFormData {
   incomeName: string;
   amount: number;
   amountPeriodicity?: MonthlyYearly;
   memberId?: string;
   savingAssetId?: string
   type: IncomeType;
}

const IncomeEdit: FunctionComponent<IncomeEditProps> = (props: IncomeEditProps) => {
   const {income, onCancel, cypressName} = props;

   const dispatch = useDispatch();
   const intl = useIntl();
   const incomes = useIncomes();
   const family = useFamily();
   const loans = useLoans([]);
   const availableInvestmentAssets = useInvestmentAssets(true);
   const allInvestmentAssets = useInvestmentAssets(false);
   const retirementProject = useRetirementProject();

   const hasFetchedIncomes = useSelector<State, boolean>((state) => state.incomes.hasFetched);
   const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);

   const incomeTypesValues: IncomeType[] = Object.values(AvailableIncomeType);

   const [selectedIncomeType, setSelectedIncomeType] = useState<IncomeType | undefined>(income?.type);

   // Définit les valeurs par défaut du formulaire
   useEffect(() => {
      if (selectedIncomeType === undefined && hasFetchedIncomes && hasFetchedFamily) {
         let newIncomeType: string | undefined;
         let newMember;

         // On propose salaire ou retraite si ME ou PARTNER n'ont pas ce type de revenu
         if (!incomes.some((i) => ((i.type === IncomeType.WORK_INCOME && i.monthlyAmount !== 0) || i.type === IncomeType.RETIREMENT_INCOME) && i.memberId === family.me.id)) {
            newIncomeType = calculateAgeByBirthdate(family.me.birthday) < MIN_AGE_TO_RETIRE ? IncomeType.WORK_INCOME : IncomeType.RETIREMENT_INCOME;
            newMember = family.me;
         } else if (hasFetchedIncomes && hasFetchedFamily && !!family.partner) {
            if (!incomes.some((i) => ((i.type === IncomeType.WORK_INCOME && i.monthlyAmount !== 0) || i.type === IncomeType.RETIREMENT_INCOME) && !!family.partner && i.memberId === family.partner.id)) {
               newMember = family.partner;
               if (family.partner.birthday) {
                  newIncomeType = calculateAgeByBirthdate(family.partner.birthday) < MIN_AGE_TO_RETIRE ? IncomeType.WORK_INCOME : IncomeType.RETIREMENT_INCOME
               } else {
                  newIncomeType = IncomeType.WORK_INCOME;
               }
            }
         }

         // Sinon on propose revenu locatif
         if (!newIncomeType) {
            newIncomeType = IncomeType.REAL_ESTATE_INCOME;
         }

         methods.setValue("type", newIncomeType, {shouldValidate: true});
         setSelectedIncomeType(newIncomeType);

         if (newIncomeType === IncomeType.REAL_ESTATE_INCOME) {
            const amountOfIncomeWithSameType = incomes.filter((income) => income.type === newIncomeType).length;
            const incomeNameSuffix = amountOfIncomeWithSameType < 1 ? "" : amountOfIncomeWithSameType + 1;
            methods.setValue("incomeName", `${intl.formatMessage({id: "form.income.name.defaultTitle"})} ${incomeNameSuffix}`, {shouldValidate: true});
         } else {
            methods.setValue("incomeName", `${intl.formatMessage({id: `form.income.name.${newMember?.status}.${newIncomeType}`})}`, {shouldValidate: true});
         }

         if (newMember) {
            methods.setValue("memberId", newMember.id, {shouldValidate: true});
         }
      }
   }, [hasFetchedIncomes, hasFetchedFamily, incomes, family]);

   const [isNew, setIsNew] = useState(true);

   const methods = useFormContext();

   useEffect(() => {
      if (income) {
         setIsNew(false);
      }
   }, [income]);

   const onSelectIncomeType = (newIncomeType: string) => {
      let incomeName: string;
      const amountOfIncomeWithSameType = incomes.filter((income) => income.type === newIncomeType).length;
      const incomeNameSuffix = amountOfIncomeWithSameType < 1 ? "" : amountOfIncomeWithSameType + 1;
      if (newIncomeType === IncomeType.CHILD_SUPPORT_INCOME || newIncomeType === IncomeType.PLANNED_SAVINGS_BUYBACK) {
         incomeName = `${intl.formatMessage({id: `incomeType.${newIncomeType}`})} ${incomeNameSuffix}`;
      } else if ([IncomeType.WORK_INCOME, IncomeType.RETIREMENT_INCOME].includes(newIncomeType)) {
         const selectedMemberId = methods.getValues("memberId");
         const memberStatus = (family.me.id === selectedMemberId || !family.partner) ? FamilyStatus.ME : FamilyStatus.PARTNER;
         incomeName = `${intl.formatMessage({id: `form.income.name.${memberStatus}.${newIncomeType}`})} ${incomeNameSuffix}`
      } else {
         incomeName = `${intl.formatMessage({id: "form.income.name.defaultTitle"})} ${incomeNameSuffix}`;
      }

      methods.setValue("incomeName", incomeName, {shouldValidate: true});
      setSelectedIncomeType(newIncomeType);
   };

   const getAmountTooltip = (incomeType: IncomeType | undefined) => {
      switch (incomeType) {
         case IncomeType.PLANNED_SAVINGS_BUYBACK:
            return "form.income.amount.planned-savings.tooltip";
         case IncomeType.WORK_INCOME:
            return "form.income.amount.work-income.tooltip";
         default:
            return undefined;
      }
   }

   const handleSaveIncome = (data: IncomeProjectFormData) => {
      const numberSavingAssetId = data.savingAssetId !== undefined ? parseInt(data.savingAssetId) : undefined;

      const incomeToSubmit = {
         ...income,
         monthlyAmount: data.amountPeriodicity === MonthlyYearly.YEARLY ? Math.trunc(data.amount / 12) : data.amount,
         name: data.incomeName,
         type: data.type,
         memberId: Number(data.memberId),
         savingsAsset: isNew ? availableInvestmentAssets.filter((a) => a.id === numberSavingAssetId)[0] : allInvestmentAssets.filter((a) => a.id === numberSavingAssetId)[0],
      };

      // Dans le cas de la création ou de la modification d'un work_income, on lance un recalcul du projet retraite
      if (incomeToSubmit.type === IncomeType.WORK_INCOME && retirementProject) {
         if (incomeToSubmit.memberId === family.me.id) {
            dispatch(updateRetirementProjectWithNewIncome(retirementProject.id, incomeToSubmit.monthlyAmount, family.partner ? getIncome(incomes, IncomeType.WORK_INCOME, family.partner)?.monthlyAmount : undefined));
         }
         if (family.partner && incomeToSubmit.memberId === family.partner.id) {
            dispatch(updateRetirementProjectWithNewIncome(retirementProject.id, getIncome(incomes, IncomeType.WORK_INCOME, family.me)?.monthlyAmount, incomeToSubmit.monthlyAmount));
         }
      }

      if (isNew) {
         if (isPlannedSavingsBuybackIncome(incomeToSubmit)) {
            dispatch(createPlannedSavingsBuybackIncomeAction(incomeToSubmit));
         } else {
            dispatch(createIncomeAction(incomeToSubmit));
         }
      } else if (isPlannedSavingsBuybackIncome(incomeToSubmit)) {
         dispatch(updatePlannedSavingsBuybackIncomeAction(incomeToSubmit));
      } else {
         dispatch(updateIncomeAction(incomeToSubmit));
      }
   };

   const displayMemberField = () => (
      <SelectInput name="memberId" required label="form.income.member"
                   defaultValue={income?.memberId} cypressName={`${cypressName}-member`}
                   placeholder={intl.formatMessage({id: "form.income.select-member"})}>
         {getMembersOfCouple(family)
            .map((member) => (
               <SGSelectOption key={member.id} value={member.id}>
                  {member.name}
               </SGSelectOption>
            ))}
      </SelectInput>
   );

   const displaySavingsField = () => (
      <SelectInput name="savingAssetId" required label="form.income.savingAsset"
                   defaultValue={(income && isPlannedSavingsBuybackIncome(income)) ? income?.savingsAsset?.id : undefined}
                   placeholder={intl.formatMessage({id: "form.income.select-savingAsset"})}
                   cypressName={`${cypressName}-saving-asset`} disabled={!isNew}>
         {isNew &&
            availableInvestmentAssets.map((asset: AssetWithValuation) => (
               asset.id !== undefined && asset.assetValuation.totalValuation &&
               <SGSelectOption value={asset.id} key={asset.id}>
                  <AssetSelectOption assetName={asset.assetName} isLoss={false} assetValuation={asset.assetValuation.totalValuation}/>
               </SGSelectOption>

            ))
         }
         {!isNew && income && isPlannedSavingsBuybackIncome(income) &&
            <SGSelectOption value={income.savingsAsset.id || 0}>
               {income.savingsAsset.assetName}
            </SGSelectOption>
         }
      </SelectInput>
   );

   const onSubmitRentalIncome = (data: AssetEditFormData) => {
      const currentAsset: Partial<RealEstateAsset> = parseFormDataForAsset(
         {...data, assetType: AssetType.REAL_ESTATE_RENT},
         family,
         loans
      );
      dispatch(createRealEstateAsset(currentAsset));
   };

   const displayProjectIncome = () => (
      <>
         {selectedIncomeType !== IncomeType.PLANNED_SAVINGS_BUYBACK &&
            displayMemberField()}

         <StringInput cypressName={`${cypressName}-name`}
                      defaultValue={income?.name} name="incomeName" label="form.income.name"/>

         <NumericInput defaultValue={income?.monthlyAmount} name="amount" cypressName={`${cypressName}-monthly-amount`}
                       maxMessage="form.income.error.minmax" minMessage="form.income.error.minmax"
                       min={0} max={100_000} suffix="€" label="form.income.amount"
                       placeholder={intl.formatMessage({id: "form.income.amount.placeholder"})}
                       displayPeriodicChoice={selectedIncomeType === IncomeType.WORK_INCOME || selectedIncomeType === IncomeType.RETIREMENT_INCOME}
                       tooltipLabel={getAmountTooltip(selectedIncomeType)}/>

         {selectedIncomeType === IncomeType.PLANNED_SAVINGS_BUYBACK && displaySavingsField()}

         <SGButtonGroup>
            <SGButtonIntl type="primary" size="sm" intlId="form.income.save" disabled={!methods.formState.isValid} cypressName={`${cypressName}-submit`}
                          onClick={methods.handleSubmit(handleSaveIncome)}/>
            <SGButtonIntl type="secondary" size="sm" cypressName={`${cypressName}-cancel`} intlId="form.income.cancel" onClick={() => {
               onCancel();
            }}/>
         </SGButtonGroup>
      </>
   );

   return (
      <>
         <FormInputs colLength={10}>
            <SelectInput onValueChange={onSelectIncomeType} name="type" defaultValue={income?.type || selectedIncomeType} disabled={!isNew}
                         cypressName={`${cypressName}-type`}
                         label="form.income.type">
               {incomeTypesValues.filter((incomeType: IncomeType) => incomeType !== IncomeType.PLANNED_SAVINGS_BUYBACK || allInvestmentAssets.length !== 0).map(
                  (incomeType) =>
                     <SGSelectOption key={incomeType} value={incomeType}>
                        {intl.formatMessage({id: `incomeType.${incomeType}`})}
                     </SGSelectOption>
               )}
            </SelectInput>
            {selectedIncomeType !== IncomeType.REAL_ESTATE_INCOME &&
               displayProjectIncome()}
         </FormInputs>

         {selectedIncomeType === IncomeType.REAL_ESTATE_INCOME && (
            <FormProvider {...methods}>
               <form>
                  <AssetEdit {...methods} isRealEstateIncome onCancel={onCancel} onSubmit={onSubmitRentalIncome} isAssetMemberLinkDisabled={false}
                             displayAssetShares cypressName={`${cypressName}-asset-edit`}/>
               </form>
            </FormProvider>
         )}
         <ErrorElement cypressName={cypressName} errorTextPrefix="form.income.error"/>
      </>
   );
};

export {IncomeEdit};
