import {CREATE_LOAN, DELETE_LOAN, FETCH_LOANS, Loan, UPDATE_LOAN} from "./types";

export const getLoansAction = () => ({
   type: FETCH_LOANS
});

export const updateLoanAction = (loan: Loan) => ({
   type: UPDATE_LOAN,
   payload: loan
});

export const createLoanAction = (loan: Partial<Loan>) => ({
   type: CREATE_LOAN,
   payload: loan
});

export const deleteLoanAction = (loan: Loan) => ({
   type: DELETE_LOAN,
   payload: loan
});
