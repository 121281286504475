import React, { FunctionComponent, useContext } from "react";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AssuModal } from "website/components/molecules/AssuModal/AssuModal";
import { TutorialContext } from "website/components/providers/TutorialProvider";

interface StopTutorialModalProps {
   visible: boolean;
   setVisible: (showAccesTutorielModal: boolean) => void;
   setShowTutoriel: (showTutoriel: boolean) => void;
}

const StopTutorialModal: FunctionComponent<StopTutorialModalProps> = (props: StopTutorialModalProps) => {
   const {visible, setVisible, setShowTutoriel } = props;

   const { setTutorialDisplayed } = useContext(TutorialContext);
   const { trackPopin, resetTracking } = useTracking();

   const continueTutorial = () => {
      trackPopin(true, "tableau-de-bord", "popin-tutoriel-sortie::clic-sur-je-continue", "tutoriel");
      setShowTutoriel(true);
      setVisible(false);
   };

   const stopTutorial = () => {
      trackPopin(true, "tableau-de-bord", "popin-tutoriel-sortie::clic-sur-quitter", "tutoriel");
      setTutorialDisplayed(false);
      setVisible(false);
      window.scrollTo(0, 0);
      resetTracking();
   };

   return (
      <AssuModal
         type="stopTutoriel"
         visible={visible}
         closable={false}
         onClickLeftButton={stopTutorial}
         onClickRightButton={continueTutorial}
         isSubtitle
      />
   );
};

export { StopTutorialModal };
