import { FunctionComponent, useEffect, useState } from "react";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGGridCol, SGGridRow } from "sg-grid";
import { BreadCrumbAssu } from "../components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { useIntl } from "react-intl";
import { SGText, SGTitle } from "sg-typo";
import { SGBox, SGSpace } from "sg-space";
import { SGIcon } from "sg-icon";
import { SGTile } from "sg-tile";
import {
  SGAvenirNavFleche,
  SGAvenirNavFlecheBtnLien,
  SGAvenirStyledCorbeille,
} from "sg-icon-pack-base";
import { SGButton } from "sg-button";
import { DASHBOARD } from "website/utils/privateRoutes";
import { useHistory } from "react-router";
import { AlertDeleteProfileModal } from "website/components/molecules/AlertDeleteProfileModal/AlertDeleteProfileModal";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const ModifierProfilPage: FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();

  const { trackClick, trackPage } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const [isAlertDeleteProfileModalVisible, setAlertDeleteProfileModalVisible] =
    useState(false);

  useEffect(() => {
    trackPage("tableau-de-bord", "modifier-mon-profil", "gestion-ecran");
  }, []);

  const handleOnClickLinkDashboard = () => {
    trackClick("retour-au-tableau-de-bord");
    history.push(DASHBOARD);
  };

  const handleOnClickOpenModal = () => {
    setAlertDeleteProfileModalVisible(true);
    trackClick("supprimer-mes-donnees");
  };

  return (
    <SGLayout background="light">
      <SGMediaQuery minwidth={0} maxwidth="md">
        <SGBox padding={{ top: "xxl" }}/>
      </SGMediaQuery>
      <SGContent
        disableautomargin
        extra={
          <SGMediaQuery minwidth="md">
            <SGBox textalign="left" span={12} margin={{ top: "xxs" }}>
              <SGGridRow>
                <SGGridCol span={12}>
                  <BreadCrumbAssu step="modifierProfil.header" />
                </SGGridCol>
              </SGGridRow>
            </SGBox>
          </SGMediaQuery>
        }
      >
        <SGSpace direction="vertical" align="center" textalign="center">
          <SGTitle key="title" level={1} visuallevel={1}>
            {intl.formatMessage({ id: "modifierProfil.titre" })}
          </SGTitle>
          <SGText key="text" size="l">
            {intl.formatMessage({ id: "modifierProfil.sousTitre" })}
          </SGText>
        </SGSpace>

        <SGTile
          // @ts-ignore le temps que SGTile soit corrigé
          clickable="full"
          width={isPhone ? "null" : "auto"}
          onClick={handleOnClickOpenModal}
        >
          <SGGridRow align="middle">
            <SGMediaQuery minwidth="md">
              <SGGridCol flex="0 0 0%" textalign="center">
                <SGIcon component={<SGAvenirStyledCorbeille />} size="s" />
              </SGGridCol>
            </SGMediaQuery>
            <SGGridCol flex="1 0 0%">
              <SGTitle level={3} visuallevel={4}>
                Supprimer mes données
              </SGTitle>
              <SGText size="s">
                Demander la suppression des données de simulation Mon Compagnon Retraite
              </SGText>
            </SGGridCol>
            <SGGridCol flex="0 0 0%">
              <SGButton
                icon={<SGIcon component={<SGAvenirNavFleche />} />}
                type="icon"
              />
            </SGGridCol>
          </SGGridRow>
        </SGTile>

        <AlertDeleteProfileModal
          isVisible={isAlertDeleteProfileModalVisible}
          setVisible={setAlertDeleteProfileModalVisible}
        />

        <SGMediaQuery minwidth={0} maxwidth="sm">
          <SGBox margin={{ top: "xxl" }} textalign="center">
            <SGButton
              type="tertiary"
              icon={<SGIcon currentcolor component={<SGAvenirNavFlecheBtnLien />} />}
              iconposition="right"
              onClick={handleOnClickLinkDashboard}
            >
              {intl.formatMessage({
                id: `modifierProfil.retour`,
              })}
            </SGButton>
          </SGBox>
        </SGMediaQuery>
      </SGContent>
      <SGBox margin={{ top: "xxl" }} padding={{ top: "xxl", bottom: "xxl" }}/>
    </SGLayout>
  );
};

export { ModifierProfilPage };
