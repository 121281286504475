import React, {FunctionComponent} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGCard} from "sg-card";
import {SGSpace} from "sg-space";
import {GoFurtherButton} from "../GoFurtherButton/GoFurtherButton";
import {useCustomerMeetingStatePrefix} from "../../hooks/useCustomerMeetingStatePrefix ";

interface GoFurtherProps {
   customTextPrefix?: string;
   cypressParentName: string;
   buttonSize?: string;
}

const GoFurther: FunctionComponent<GoFurtherProps> = ({customTextPrefix, cypressParentName, buttonSize}: GoFurtherProps) => {
   const prefix = useCustomerMeetingStatePrefix(customTextPrefix);

   return (
      <SGCard data-cy={`${cypressParentName}-frame`} bordered textalign="center">
         <SGSpace direction="vertical">
            <SGTitleIntl intlId={`netWorth.distribution.${prefix}.title`} cypressName={`${cypressParentName}-frame-title`}/>
            <SGTextIntl intlId={`netWorth.distribution.${prefix}.text`} size="m"/>
            <GoFurtherButton size={buttonSize || "md"}/>
         </SGSpace>
      </SGCard>
   );
};

export {GoFurther};
