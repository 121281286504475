export interface DashboardState {
   dashboard: Partial<Dashboard>;
   isOnboarded: boolean;
   vientDeFaireReformeDA: boolean;
   showTutoriel: boolean;
}

export const hasFetchedDashboard = (dashboardState: DashboardState): boolean => dashboardState.dashboard !== undefined;

/**
 * Objet récupéré du backpfr
 */
export interface Dashboard {
   lastProjectCreationDate: string;
   monthlyIncomeGoal: number;
   ages: DashboardAge[];
   choosenAge?: DashboardAgeType;
   firstPatrimonyPathDate?: string;
   creationDate?: string;
   onBoardingDate?: string;
   // Début RIS
   firstRISPathDate?: string;
   lastRISPathDate?: string;
   risDate?: string;
   reliability: number;
   acquiredQuarters: number;
   remainingQuarters: number;
   departureDate: string;
   // Fin RIS
   firstMonthlyIncomeGoalInputDate?: string;
   firstSavingPathDate?: string;
   firstMyPlanDate?: string;
   firstMyServicesDate?: string;
   legalNoticesAcceptanceDate?: string;
   firstDiaryConnectionDate?: string;
   firstAppointmentDate?: string;
   firstSimulatorBeforeAfterDate?: string;

   readMessageADate?: string;
   readMessageBDate?: string;
   readMessageC1Date?: string;
   readMessageC2Date?: string;
   readMessageC3Date?: string;
   readMessageDDate?: string;
   readMessageEDate?: string;
   readMessageFDate?: string;
   readMessageZDate?: string;
   tutorialModalDate?: string;
   disclaimerSeiAcceptanceDate?: string;
}

/**
 * Objet envoyé au backpfr
 */
export interface User {
   monthlyIncomeGoal?: number;
   choosenAge?: DashboardAgeType;
   firstPatrimonyPathDate?: Date;
   firstSavingPathDate?: Date;
   firstMyPlanDate?: Date;
   firstMyServicesDate?: Date;
   legalNoticesAcceptanceDate?: Date;
   firstDiaryConnectionDate?: Date;
   firstAppointmentDate?: Date;
   firstSimulatorBeforeAfterDate?: Date;
   readMessageADate?: Date;
   readMessageBDate?: Date;
   readMessageC1Date?: Date;
   readMessageC2Date?: Date;
   readMessageC3Date?: Date;
   readMessageDDate?: Date;
   readMessageEDate?: Date;
   readMessageFDate?: Date;
   readMessageZDate?: Date;
   tutorialModalDate?: Date;
   disclaimerSeiAcceptanceDate?: Date;
}

export interface DashboardAge {
   /**
    * Type de départ principal, utilisé pour les appels Wealth
    */
   type: DashboardAgeType;
   /**
    * Liste des types de départ, triés par priorité décroissante
    */
   typesDepart: DashboardAgeType[];
   typesDepartTitre: string;

   retirementYear: number;
   retirementMonth: number;
   retirementAgeYear: number;
   retirementAgeMonth: number;
   lastMonthlyIncome: number;
   retirementMonthlyIncome: number;
   creationDate: string;
}

// actions
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const DASHBOARD_FETCHED = "DASHBOARD_FETCHED";
export const RESET_DASHBOARD_ERROR = "RESET_DASHBOARD_ERROR";
export const CREATE_PREFS = "CREATE_PREFS";
export const UPDATE_PREFS = "UPDATE_PREFS";
export const UPDATE_RELOAD_PREFS = "UPDATE_RELOAD_PREFS";
export const PREFS_UPDATED = "PREFS_UPDATED";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const ONBOARDED = "ONBOARDED";
export const UPDATE_REFORME_DA = "UPDATE_REFORME_DA";
export const UPDATE_SHOWTUTORIEL = "UPDATE_SHOWTUTORIEL";

// Enum

/**
 * Type d'âge géré par BackPFR
 * Code envoyé à Wealth
 */
/* eslint-disable camelcase */
export enum DashboardAgeType {
   age_legal = "age_legal", // AgeLegal 45s + Code 1 RIS
   age_taux_plein = "age_taux_plein", // AgeTauxPlein 45s + Code 2 RIS
   age_maximum = "age_maximum", // AgeMaximum 45s + Ancien Code 3 RIS
   age_tp_carcdsf = "age_tp_carcdsf",
   age_tp_carpimko = "age_tp_carpimko",
   age_tp_cavec = "age_tp_cavec",
   age_tp_cavamac = "age_tp_cavamac",
   age_tp_cavp = "age_tp_cavp",
   age_tp_cprn = "age_tp_cprn",

   age_depart_immediat = "age_depart_immediat",
   age_taux_plein_auto = "age_taux_plein_auto",

   age_da_carriere_longue = "age_da_carriere_longue",
   age_da_inapt_travail = "age_da_inapt_travail",
   age_da_inapt_permanente = "age_da_inapt_permanente",

   age_tp_plus_un_an = "age_tp_plus_un_an",
   age_tp_surcote = "age_tp_surcote"
}
/* eslint-enable camelcase */

export const MONTHS = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

// Type de parcours pour le ChatBot
export enum PathType {
   ONBOARDING = "onboarding",
   RIS = "ris",
   PATRIMONY = "patrimony",
   INCOMEGOAL = "incomeGoal",
   SAVING = "saving",
   PLAN = "plan",
   DEFAULT = "default",
}

export interface PathDate {
   path: PathType,
   date: Date
}
