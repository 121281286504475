import React, {FunctionComponent, useEffect} from "react";
import {NewProjects} from "../components/organisms/Projects/NewProject/NewProjects";
import {SGContent} from "sg-layout";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const NewProjectPage: FunctionComponent = () => {
   const { trackPage } = useTracking();

   useEffect(() => {
      trackPage("nouveau-projet", "accueil", "gestion-ecran");
   }, []);

   return (
      <SGContent>
         <NewProjects/>
      </SGContent>
   );
};

export {NewProjectPage};
