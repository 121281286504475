import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {RecommendationState} from "../../../store/recommendations/types";
import {getRecommendationsAction} from "../../../store/recommendations/actions";

const useRecommendations = (forceFetch: boolean) => {
   const dispatch = useDispatch();
   const recommendationState: RecommendationState = useSelector<State, RecommendationState>((state) => state.recommendations);

   useEffect(() => {
      if ((!recommendationState.hasFetched || forceFetch) && !recommendationState.cannotFetchRecommendations) {
         dispatch(getRecommendationsAction());
      }
   }, []);

   return recommendationState.recommendations;
};

export {useRecommendations};
