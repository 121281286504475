import React, {FunctionComponent, ReactNode} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {SGTabPane, SGTabs} from "sg-tabs";
import {useIntl} from "react-intl";
import {SITUATION_NET_WORTH} from "../../../utils/privateRoutes";
import {replaceSpecialCharsAndLowercase} from "../../../utils/formatting/replaceSpecialCharsAndLowercase";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface ElyxirTabsProps {
   tabsNames: string[];
   tabsIcons?: string[];
   tabsCypressNames: string[];
   tabsComponents: ReactNode[];
   tabsUrls?: string[];
   centered?: boolean;
}

const ElyxirTabs: FunctionComponent<ElyxirTabsProps> = (props: ElyxirTabsProps) => {
   const history = useHistory();
   const intl = useIntl();
   const location = useLocation();
   const { trackClick } = useTracking();

   const {tabsNames, tabsIcons, tabsComponents, tabsUrls, tabsCypressNames, centered} = props;

   return (
      <>
         <SGTabs centered={centered} defaultActiveKey={tabsUrls?.includes(location.pathname) ? location.pathname : SITUATION_NET_WORTH}
                 onChange={(activeUrl: string) => {
                    trackClick(`clic-sur-${tabsIcons ? 'graphique' : 'onglet'}-${replaceSpecialCharsAndLowercase(activeUrl)}`);
                    if (tabsUrls) {
                       history.push(activeUrl);
                    }
                 }}>
            {!!tabsUrls &&
               tabsNames.map((tabsName: string, index: number) => (
                  <SGTabPane ariaId={tabsUrls[index]} tab={intl.formatMessage({id: tabsNames[index]})} key={tabsUrls[index]} data-cy={`${tabsCypressNames[index]}-tab`}>
                     {tabsComponents[index]}
                  </SGTabPane>
               ))}

            {!!tabsIcons &&
               tabsIcons.map((tabsIcon: string, index: number) => (
                  <SGTabPane ariaId={tabsNames[index]} tab={<img src={tabsIcon} alt={`graph ${index}${1}`}/>} key={tabsNames[index]} data-cy={`${tabsCypressNames[index]}-tab`}>
                     {tabsComponents[index]}
                  </SGTabPane>
               ))}

         </SGTabs>
      </>
   );
};

export {ElyxirTabs};
