import {
  Asset,
  AssetWithValuation,
  CREATE_ASSET,
  CREATE_REAL_ESTATE_ASSET,
  CREATE_REAL_ESTATE_ASSET_AND_LOAN,
  CREATE_SCI_ASSET,
  DELETE_ASSET,
  FETCH_ASSETS_WITHOUT_LOANS,
  RealEstateAsset,
  SCIAsset,
  UPDATE_ASSET,
  UPDATE_ASSETS_FOR_RETIREMENT,
  UPDATE_REAL_ESTATE_ASSET,
  UPDATE_REAL_ESTATE_ASSET_AND_LOAN,
  UPDATE_SCI_ASSET
} from "./types";
import {Loan} from "../loans/types";

export const getAssetsWithoutLoans = () => ({
   type: FETCH_ASSETS_WITHOUT_LOANS
});

export const createAsset = (data: Partial<AssetWithValuation>) => ({
   type: CREATE_ASSET,
   payload: data
});

export const updateAsset = (data: AssetWithValuation) => ({
   type: UPDATE_ASSET,
   payload: data
});

export const updateAssetsForRetirement = (data: AssetWithValuation[]) => ({
  type: UPDATE_ASSETS_FOR_RETIREMENT,
  payload: data,
});

export const deleteAsset = (data: Asset) => ({
   type: DELETE_ASSET,
   payload: data
});

export const createRealEstateAsset = (asset: Partial<RealEstateAsset>) => ({
   type: CREATE_REAL_ESTATE_ASSET,
   payload: asset
});

export const updateRealEstateAsset = (asset: Partial<RealEstateAsset>) => ({
   type: UPDATE_REAL_ESTATE_ASSET,
   payload: asset
});

export const createRealEstateAssetAndLoan = (asset: Partial<RealEstateAsset>, loan: Partial<Loan>) => ({
   type: CREATE_REAL_ESTATE_ASSET_AND_LOAN,
   payload: {asset, loan}
});

export const updateRealEstateAssetAndLoan = (asset: Partial<RealEstateAsset>, loan: Partial<Loan>) => ({
   type: UPDATE_REAL_ESTATE_ASSET_AND_LOAN,
   payload: {asset, loan}
});

export const createSCIAsset = (asset: SCIAsset) => ({
   type: CREATE_SCI_ASSET, payload: asset
});

export const updateSCIAsset = (asset: SCIAsset) => ({
   type: UPDATE_SCI_ASSET, payload: asset
});
