import {Feedback, FETCH_HAS_SENT_FEEDBACK, SEND_FEEDBACK} from "./types";

export const sendFeedback = (feedback: Feedback) => ({
  type: SEND_FEEDBACK,
  payload: feedback,
});

export const getHasAlreadySentFeedback = () => ({
  type: FETCH_HAS_SENT_FEEDBACK,
});
