import React, {FunctionComponent} from "react";
import {DefaultProject} from "../../components/organisms/Projects/DefaultProject/DefaultProject";
import {ProjectType} from "../../../store/projects/types";
import {SGContent} from "sg-layout";

const ExceptionalExpenseProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <DefaultProject
         projectType={ProjectType.EXCEPTIONAL_EXPENSE}
         projectTitle="project.exceptional.expense.title"
         projectSubtitle="project.exceptional.expense.subtitle"
         projectAmountLabel="project.exceptional.expense.form.amount"
         projectAmountPlaceholder="project.exceptional.expense.form.amount.placeholder"
         projectHorizonLabel="project.exceptional.expense.form.horizon"
         newProjectTitle="new.project.title.exceptional.expense"
         cypressName="exceptional-expense"
      />
   </SGContent>
);

export {ExceptionalExpenseProjectPage};
