import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {LIFE_PROJECTS, ProjectState} from "../../../store/projects/types";
import {getProjectsAction} from "../../../store/projects/actions";

const useNumberProjects = () => {
   const dispatch = useDispatch();
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   useEffect(() => {
      if (!projectState.hasFetchedProjects) {
         dispatch(getProjectsAction());
      }
   }, []);

   return projectState.projects.filter((value) => LIFE_PROJECTS.includes(value.projectType)).length;
};

export {useNumberProjects};
