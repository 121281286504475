import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGAlert } from "sg-alert";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo, SGAvenirStrokedMesRendezVous, SGAvenirStrokedTelechargement } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGEqualHeight, SGEqualHeightItem, SGSpace } from "sg-space";
import { SGSpinner } from "sg-spinner";
import { SGSticky } from "sg-sticky";
import { SGText } from "sg-typo";
import { AccountState } from "store/account/types";
import { updateAppointment } from "store/appointment/actions";
import { AppointmentState } from "store/appointment/types";
import { updateAndReloadPrefs, updatePrefs } from "store/dashboard/actions";
import { DashboardAge, PathType, User, hasFetchedDashboard } from "store/dashboard/types";
import { telechargerSyntheseRetraite } from "store/pdf/actions";
import { resetErreurPdf } from "store/pdf/pdfSlice";
import { PdfState } from "store/pdf/types";
import { resetParcours } from "store/simulateurAvantApres/slice";
import { State } from "store/store";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useDashboardLastPathDoneAndMessage } from "website/components/hooks/dashboard/useDashboardLastPathDoneAndMessage";
import { useDashboardProgression } from "website/components/hooks/dashboard/useDashboardProgression";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { PAGE_TYPE_GESTION } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useArticles } from "website/components/hooks/useArticles";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { AccesContenus } from "website/components/molecules/AccesContenus/AccesContenus";
import { AccompagnementTelephonique } from "website/components/molecules/AccompagnementTelephonique/AccompagnementTelephonique";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { InfoAssuModal } from "website/components/molecules/InfoAssuModal/InfoAssuModal";
import { LegalNotice } from "website/components/molecules/LegalNotice/LegalNotice";
import { OffreType, PromotionalBanner } from "website/components/molecules/PromotionalBanner/PromotionalBanner";
import { StopTutorialModal } from "website/components/molecules/StopTutorialModal/StopTutorialModal";
import { UnderstandSimulationModal } from "website/components/molecules/UnderstandSimulationModal/UnderstandSimulationModal";
import { AccesSimulateurEconomieImpot } from "website/components/organisms/Dashboard/AccesSimulateurEconomieImpot/AccesSimulateurEconomieImpot";
import { AccesSimulateurReforme } from "website/components/organisms/Dashboard/AccesSimulateurReforme/AccesSimulateurReforme";
import { CarouselNavigator } from "website/components/organisms/Dashboard/CarouselNavigator/CarouselNavigator";
import { DisclaimerDashboard } from "website/components/organisms/Dashboard/DisclaimerDashboard/DisclaimerDashboard";
import { EffortEpargne } from "website/components/organisms/Dashboard/EffortEpargne/EffortEpargne";
import { InfoCardType } from "website/components/organisms/Dashboard/InfoCard/InfoCard";
import { Pension } from "website/components/organisms/Dashboard/Pension/Pension";
import { PensionMobile } from "website/components/organisms/Dashboard/PensionMobile/PensionMobile";
import { Age } from "website/components/organisms/Dashboard/Personnalisation/Age/Age";
import { Patrimoine } from "website/components/organisms/Dashboard/Personnalisation/CartesDonnees/Patrimoine";
import { Progression } from "website/components/organisms/Dashboard/Progression/Progression";
import { ProgressionMob } from "website/components/organisms/Dashboard/Progression/ProgressionMob";
import { RevenuTotalRetraite } from "website/components/organisms/Dashboard/RevenuTotalRetraite/RevenuTotalRetraite";
import { RevenuTotalRetraiteMobile } from "website/components/organisms/Dashboard/RevenuTotalRetraiteMobile/RevenuTotalRetraiteMobile";
import { CTA, SideBarChatBot } from "website/components/organisms/Dashboard/SideBarChatBot/SideBarChatBot";
import { Tutoriel } from "website/components/organisms/Dashboard/Tutoriel/Tutoriel";
import {
    ARTICLES,
    MES_SERVICES,
    MON_PLAN,
    PARCOURS_EPARGNER,
    PARCOURS_REVENUS_COMPLEMENTAIRES,
    PARCOURS_RIS,
    SIMULATEUR_AVANT_APRES,
    SIMULATEUR_ECONOMIES_IMPOTS
} from "website/utils/privateRoutes";

export enum MessageType {
    A = "readMessageADate",
    B = "readMessageBDate",
    C1 = "readMessageC1Date",
    C2 = "readMessageC2Date",
    C3 = "readMessageC3Date",
    D = "readMessageDDate",
    E = "readMessageEDate",
    F = "readMessageFDate",
    Z = "readMessageZDate",
}

export const Dashboard: FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { trackPage, trackClick, trackDownload } = useTracking();

    const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
    const estSupervisionActive: boolean = useSelector<State, boolean>((state) => state.supervision.estActive);

    const intl = useIntl();

    const [ageDepartDashboard, setAgeDepartDashboard] = useState<DashboardAge[]>([]);
    const [savedObjectifRevenu, setSavedObjectifRevenu] = useState<number | undefined>();
    const [ctaToDisplay, setCtaToDisplay] = useState<CTA[]>([]);
    const [animateCTA, setAnimateCTA] = useState<boolean>(false);
    const [showLegalNoticesModal, setShowLegalNoticesModal] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showStopTutorialModal, setShowStopTutorialModal] = useState(false);
    const [showCTA, setShowCTA] = useState(false);
    const [showUnderstandSimulationModal, setShowUnderstandSimulationModal] = useState(false);
    const [toggleButtonText, setToggleButtonText] = useState(true);
    const [tablesModal, setTablesModal] = useState<string[][]>();
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentTime, setAppointmentTime] = useState("");
    const [clignoteAge, setClignoteAge] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [avantReforme, setAvantReforme] = useState<boolean>();
    // Afficher un disclamer si l'utilisateur n'a pas réalisé le parcours RIS ou qu'il a réalisé le parcours RIS avant la date : REACT_APP_DEPART_ANTICIPE_DEBUT
    const [showDisclamerAnalyserRIS, setShowDisclamerAnalyserRIS] = useState<boolean>(false);

    // Custom hooks
    const dashboardState = useDashboardState();
    const { getLienThematique, getLienArticle } = useArticles();
    const { agesTries, selectedRetirementAge } = useSelectedRetirementAge();
    const { pourcentage, parcoursDoneTab } = useDashboardProgression();
    const { lastPathDone, messageType } = useDashboardLastPathDoneAndMessage();
    const openSGAgenda = useSGAgenda();
    const appointmentState: AppointmentState = useSelector<State, AppointmentState>((state) => state.appointment);
    const [showTutoriel, setShowTutoriel] = useState(false);
    const [showDisclaimerDashboard, setShowDisclaimerDashboard] = useState<boolean>();

    const pdfState = useSelector<State, PdfState>((state) => state.pdf);

    // Booléen qui permet de savoir si le parcours SEI est déjà effectué
    const {
        parcoursSEIActif,
        disponibiliteAppeler,
        disponibiliteWebCallback,
        simulateurAvantApres,
        disponibiliteSGAgenda,
        disponibiliteParcoursRevenusComplementaires,
        disponibiliteOffrePER,
    } = useFeatures();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    useEffect(() => {
        setShowDisclamerAnalyserRIS(
                ((dashboardState.dashboard?.lastRISPathDate === undefined && hasFetchedDashboard(dashboardState)) ||
                    (dashboardState.dashboard?.lastRISPathDate !== undefined &&
                        new Date(dashboardState.dashboard?.lastRISPathDate) < new Date(window.env.REACT_APP_DEPART_ANTICIPE_DEBUT)))
        );
    }, [dashboardState.dashboard]);

    useEffect(() => {
        if (pdfState.erreur) {
            setShowErrorModal(true);
            dispatch(resetErreurPdf());
        }
    }, [pdfState.erreur]);

    const handleOnClickRdv = () => {
        if (disponibiliteSGAgenda) {
            openSGAgenda();
        } else {
            // SGAgenda désactivé, on affiche un message d'erreur
            setShowErrorModal(true);
        }
    };

    const handleOnClickTelechargerSynthese = () => {
        trackDownload("telechargement-synthese-retraite");
        dispatch(telechargerSyntheseRetraite());
    };

    const messageSEI = {
        text: "sei",
        redirectTo: () => {
            history.push(SIMULATEUR_ECONOMIES_IMPOTS);
        },
        trackKey: "simulateur-economies-impots",
        hideCard: !parcoursSEIActif,
    };

    const messagesChatBotList = {
        [MessageType.A]: [
            { text: "comprendreResultat", redirectTo: setShowUnderstandSimulationModal, trackKey: "comprendre-resultat" },
            { text: "fiabiliser", redirectTo: () => {
                history.push(PARCOURS_RIS);
                }, trackKey: "parcours-ris" },
            {
                text: "comprendreRetraite",
                redirectTo: () => {
                    history.push(getLienArticle("retraite-sans-jargon"));
                },
                trackKey: "article-retraite-sans-jargon",
            },
            messageSEI,
        ],
        [MessageType.B]: [
            { text: "comprendreResultatRis", redirectTo: setShowUnderstandSimulationModal, trackKey: "comprendre-resultat" },
            {
                text: "estimerRevenus",
                redirectTo: () => {
                    history.push(PARCOURS_REVENUS_COMPLEMENTAIRES);
                },
                trackKey: "parcours-patrimoine",
                hideCard: !disponibiliteParcoursRevenusComplementaires,
            },
            {
                text: "comprendreReleveRis",
                redirectTo: () => {
                    history.push(getLienArticle("comprendre-ris"));
                },
                trackKey: "article-comprendre-ris",
            },
            messageSEI,
        ],
        [MessageType.C1]: [
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(PARCOURS_EPARGNER);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "article.idees",
                redirectTo: () => {
                    history.push(getLienArticle("idees-preparer-retraite"));
                },
                trackKey: "article-idees-preparer-retraite",
            },
            messageSEI,
        ],
        [MessageType.C2]: [
            { text: "fiabiliser", redirectTo: () => {
                history.push(PARCOURS_RIS);
                }, trackKey: "parcours-ris" },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(PARCOURS_EPARGNER);
                },
                trackKey: "parcours-epargner",
            },
            messageSEI,
        ],
        [MessageType.C3]: [
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(PARCOURS_EPARGNER);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.D]: [
            {
                text: "estimerEpargnerPreparer",
                redirectTo: () => {
                    history.push(PARCOURS_EPARGNER);
                },
                trackKey: "parcours-epargner",
            },
            {
                text: "definirBudget",
                redirectTo: () => {
                    history.push(getLienArticle("estimer-budget-retraite"));
                },
                trackKey: "article-estimer-budget-retraite",
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.E]: [
            {
                text: "definirPlan",
                redirectTo: () => {
                    history.push(MON_PLAN);
                },
                trackKey: "definir-plan",
            },
            {
                text: "agendaRendezVous",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "strategies",
                redirectTo: () => {
                    history.push(getLienThematique("strategie-retraite"));
                },
                trackKey: "article-strategie-retraite",
            },
            messageSEI,
        ],
        [MessageType.F]: [
            {
                text: "agenda",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "home",
                redirectTo: () => {
                    history.push(ARTICLES);
                },
                trackKey: "articles-home",
            },
            {
                text: "services",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
        [MessageType.Z]: [
            {
                text: "article.idees",
                redirectTo: () => {
                    history.push(getLienArticle("idees-preparer-retraite"));
                },
                trackKey: "article-idees-preparer-retraite",
            },
            {
                text: "agenda",
                redirectTo: estSupervisionActive ? undefined : handleOnClickRdv,
                trackKey: "rdv",
                complementTracking: { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" },
            },
            {
                text: "accompagnerExperts",
                redirectTo: () => {
                    history.push(MES_SERVICES);
                },
                trackKey: "mes-services",
            },
            messageSEI,
        ],
    };
    const tablesModalList = {
        [MessageType.A]: [["statutPro", "carriere", "nbrEnfants", "revenusPro"]],
        [MessageType.B]: [["statutPro", "carriere"], ["projections"]],
    };

    const onHideAppointmentModal = () => {
        dispatch(updateAppointment({ ...appointmentState.appointment, appointmentJustCreated: false }));
    };

    useEffect(() => {
        trackPage("tableau-de-bord", "accueil", PAGE_TYPE_GESTION);
    }, []);

    useEffect(() => {
        if (dashboardState.showTutoriel) {
            setShowTutoriel(dashboardState.showTutoriel);
        }
    }, [dashboardState.showTutoriel]);

    useEffect(() => {
        if (messageType) {
            setCtaToDisplay(messagesChatBotList[messageType]);

            switch (messageType) {
                case MessageType.A:
                    setTablesModal(tablesModalList[messageType]);
                    break;
                case MessageType.B:
                    setTablesModal(tablesModalList[messageType]);
                    break;
                default:
            }
        }
    }, [messageType, parcoursSEIActif]);

    useEffect(() => {
        if (hasFetchedDashboard(dashboardState)) {
            // Affichage des préférences utilisateur (age de départ choisi et objectif de revenu saisi précedemment)
            if (
                dashboardState.dashboard.monthlyIncomeGoal !== undefined &&
                dashboardState.dashboard.monthlyIncomeGoal >= 0 &&
                (savedObjectifRevenu === undefined || dashboardState.dashboard.monthlyIncomeGoal !== savedObjectifRevenu)
            ) {
                setSavedObjectifRevenu(dashboardState.dashboard.monthlyIncomeGoal);
            }

            setShowLegalNoticesModal(dashboardState.dashboard.legalNoticesAcceptanceDate === undefined);
            setClignoteAge(dashboardState.dashboard.choosenAge === undefined);
        }
    }, [dashboardState]);

    useEffect(() => {
        if (agesTries) {
            // Construction des AgesDeparts à partir de dashboardInformations
            setAgeDepartDashboard(agesTries);
            setAvantReforme(agesTries[0]?.creationDate < "2023-06-14");
        }
    }, [agesTries]);

    useEffect(() => {
        if (appointmentState.appointment !== undefined) {
            if (appointmentState.appointment.date !== undefined) {
                setAppointmentDate(new Date(appointmentState.appointment.date).toLocaleDateString());
            }
            if (appointmentState.appointment.time !== undefined) {
                setAppointmentTime(appointmentState.appointment.time);
            }
            if (appointmentState.appointment.appointmentJustCreated !== undefined) {
                setShowAppointmentModal(appointmentState.appointment.appointmentJustCreated);
            }
        }
    }, [appointmentState]);

    useEffect(() => {
        if (!showLegalNoticesModal && messageType) {
            if (dashboardState.dashboard[messageType]) {
                setAnimateCTA(false);
            } else {
                saveReadMessageXToDb(messageType);
                setAnimateCTA(true);
            }
            setShowCTA(true);
        }
    }, [showLegalNoticesModal, messageType]);

    const changeSelectedAgeRetirement = (val: DashboardAge) => {
        // save choosen age
        const req: User = {
            choosenAge: val.type,
        };
        dispatch(updatePrefs(req));
    };

    const saveMonthlyIncomeGoalToDb = (val: number) => {
        const req: User = {
            monthlyIncomeGoal: val,
        };

        dispatch(updatePrefs(req));
    };

    const saveReadMessageXToDb = (type: MessageType) => {
        const req: User = {
            [type]: new Date(),
        };
        dispatch(updateAndReloadPrefs(req));
    };

    useEffect(() => {
        const intervalID = setInterval(() => {
            setToggleButtonText((toggle) => !toggle);
        }, 7000);

        return () => clearInterval(intervalID);
    }, []);

    const carouselAction = (cardType: InfoCardType) => {
        if (cardType === InfoCardType.monGuide) {
            setShowTutoriel(true);
        }
    };

    const showPromotionalBanner = () =>
        new Date() > new Date(window.env.REACT_APP_OFFRE_PER_DEBUT) && new Date() < new Date(window.env.REACT_APP_OFFRE_PER_FIN) && disponibiliteOffrePER;

    const handleOnClickSimulateurAvantApresLink = (idTracking: string) => {
        trackClick(`clic-sur-${idTracking}`);
        dispatch(resetParcours());
        history.push(SIMULATEUR_AVANT_APRES);
    };

    function getMobileDashboard() {
        return (
            <>
                <SGLayout background="light">
                    <SGBox padding={{left: "sm", right: "sm", top: "sm", bottom: "md"}}>
                        <SGSpace direction="vertical">
                            <div className="choix-age__container">
                                <Age agesDepartRetraite={ageDepartDashboard} setSelectedRetirementAge={changeSelectedAgeRetirement} selectedRetirementAge={selectedRetirementAge}/>
                            </div>
                            <DisclaimerDashboard key="disclaimerDashboard" setShowDisclaimerDashboard={setShowDisclaimerDashboard}/>
                            <RevenuTotalRetraiteMobile key="revenu-total-retraite" />
                            {showDisclamerAnalyserRIS && showDisclaimerDashboard === false && (
                                <SGAlert
                                    key="alert"
                                    description={
                                        <SGText size="m">
                                            {intl.formatMessage(
                                                { id: "dashboard.disclaimer.parcoursRIS.departAnticipe" },
                                                { b: (word) => word }
                                            )}
                                        </SGText>
                                    }
                                    icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                                    type="info"
                                    disableautomargin
                                />
                            )}
                            <PensionMobile key="pension" selectedRetirementAge={selectedRetirementAge} />
                            <Patrimoine key="patrimoine" selectedRetirementAge={selectedRetirementAge}/>
                            {selectedRetirementAge && <EffortEpargne key="effort-epargne" selectedRetirementAge={selectedRetirementAge} />}
                            {parcoursSEIActif && <AccesSimulateurEconomieImpot key="acces-sei" />}
                            { simulateurAvantApres && <AccesSimulateurReforme key="acces-reforme" />}
                            <ProgressionMob key="progression" percent={pourcentage} parcoursDoneTab={parcoursDoneTab}/>
                            {(disponibiliteAppeler || disponibiliteWebCallback) && (
                                <SGBox key="box-tel" margin={{ top: "md" }}>
                                    <AccompagnementTelephonique />
                                </SGBox>
                            )}
                            {showPromotionalBanner() && (
                                <PromotionalBanner key="banner-per" offreType={OffreType.PER} />
                            )}
                            {!showPromotionalBanner() && (
                                <PromotionalBanner key="banner-sapiendo" offreType={OffreType.SAPIENDO} />
                            )}
                            <SGBox key="box-navigateur" margin={{top: "sm"}} forcemargin>
                                <CarouselNavigator onCarouselAction={(cardType: InfoCardType) => carouselAction(cardType)} />
                            </SGBox>
                            <AccesContenus key="acces-contenu" />
                            <LegalNotice key="mentions-legales" />
                        </SGSpace>
                     </SGBox>
                </SGLayout>
                {savedObjectifRevenu !== undefined && (
                    <SGSticky>
                        <SGContent disableautomargin>
                            <SGBox alignchildren={["center"]}>
                                <BoutonSGAgenda
                                    type="primary"
                                    icon={<SGIcon component={<SGAvenirStrokedMesRendezVous />} currentcolor />}
                                    onClick={() => {
                                        trackClick("clic-sur-sticky-contacter-mon-conseiller", { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" });
                                        handleOnClickRdv();
                                    }}
                                >
                                    <FormattedMessage id={`dashboard.button.contacter.${toggleButtonText ? "conseiller" : "rendez-vous"}`} />
                                </BoutonSGAgenda>
                            </SGBox>
                        </SGContent>
                    </SGSticky>
                )}
                <StopTutorialModal visible={showStopTutorialModal} setVisible={setShowStopTutorialModal} setShowTutoriel={setShowTutoriel} />
                <Tutoriel
                    visible={showTutoriel}
                    setVisible={setShowTutoriel}
                    showStopTutorialModal={showStopTutorialModal}
                    setShowStopTutorialModal={setShowStopTutorialModal}
                />
            </>
        );
    }

    return (
        <>
            <SGMediaQuery minwidth={0} maxwidth="lg">
                {getMobileDashboard()}
            </SGMediaQuery>
            <SGMediaQuery minwidth="xl">
                <SGBox span={12}>
                    <SGEqualHeight>
                        <SGGridRow>
                            <SGGridCol span={4} textalign="left">
                                <SGLayout background="light" stretch>
                                    <SGBox margin={{right: "sm", left: "sm"}}>
                                        <SGEqualHeightItem>
                                            <SideBarChatBot
                                                ctaToDisplay={ctaToDisplay}
                                                animateCTA={animateCTA}
                                                lastPathDone={lastPathDone}
                                                pourcentage={pourcentage}
                                                user={accountState.account}
                                                showCTA={showCTA}
                                            />
                                            <SGButtonGroup align="right">
                                                <SGButton
                                                    type="link"
                                                    icon={<SGIcon component={pdfState.enChargement ? <SGSpinner size="md" /> : <SGAvenirStrokedTelechargement />} />}
                                                    onClick={handleOnClickTelechargerSynthese}
                                                    disabled={pdfState.enChargement || estSupervisionActive}
                                                >
                                                    Télécharger ma synthèse
                                                </SGButton>
                                            </SGButtonGroup>
                                        </SGEqualHeightItem>
                                    </SGBox>
                                </SGLayout>
                            </SGGridCol>
                            <SGGridCol span={8}>
                                <SGEqualHeightItem>
                                    <SGGridRow gutter={[0,16]}>
                                        <SGGridCol span={12}>
                                            <DisclaimerDashboard setShowDisclaimerDashboard={setShowDisclaimerDashboard}/>
                                        </SGGridCol>
                                        <SGGridCol span={6}>
                                            <RevenuTotalRetraite selectedRetirementAge={selectedRetirementAge} />
                                        </SGGridCol>
                                        <SGGridCol span={6}>
                                            <SGSpace direction="vertical" disableautomargin>
                                                <div className="choix-age__container">
                                                    <Age agesDepartRetraite={ageDepartDashboard} setSelectedRetirementAge={changeSelectedAgeRetirement} selectedRetirementAge={selectedRetirementAge}/>
                                                </div>
                                                <Progression key="progression" percent={pourcentage} parcoursDoneTab={parcoursDoneTab} />
                                                <Pension
                                                    key="pension"
                                                    selectedRetirementAge={selectedRetirementAge}
                                                    showDisclamerAnalyserRIS={showDisclamerAnalyserRIS}
                                                />
                                                <Patrimoine key="patrimoine" selectedRetirementAge={selectedRetirementAge}/>
                                            </SGSpace>
                                        </SGGridCol>
                                        {selectedRetirementAge && <SGGridCol span={12}><EffortEpargne selectedRetirementAge={selectedRetirementAge} /></SGGridCol>}
                                        {parcoursSEIActif && (
                                            <SGGridCol span={12}>
                                                <AccesSimulateurEconomieImpot />
                                            </SGGridCol>
                                        )}
                                        {simulateurAvantApres && (
                                            <SGGridCol span={12}>
                                                <AccesSimulateurReforme />
                                            </SGGridCol>
                                        )}
                                        {(disponibiliteAppeler || disponibiliteWebCallback) && (
                                            <SGGridCol span={12}>
                                                <AccompagnementTelephonique />
                                            </SGGridCol>
                                        )}
                                        {showPromotionalBanner() && (
                                            <SGGridCol span={12}>
                                                <PromotionalBanner offreType={OffreType.PER} />
                                            </SGGridCol>
                                        )}
                                        {!showPromotionalBanner() && (
                                            <SGGridCol span={12}>
                                                <PromotionalBanner offreType={OffreType.SAPIENDO} />
                                            </SGGridCol>
                                        )}
                                        <SGGridCol span={12}>
                                            <CarouselNavigator onCarouselAction={(cardType: InfoCardType) => carouselAction(cardType)} />
                                        </SGGridCol>
                                        <SGGridCol span={12}>
                                            <AccesContenus />
                                        </SGGridCol>
                                        <SGGridCol span={12}>
                                            <SGBox padding={{ bottom: "md" }}>
                                                <LegalNotice />
                                            </SGBox>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGEqualHeightItem>
                            </SGGridCol>
                        </SGGridRow>
                    </SGEqualHeight>
                </SGBox>
                {lastPathDone && [PathType.ONBOARDING, PathType.RIS].includes(lastPathDone) && tablesModal && (
                    <UnderstandSimulationModal
                        visible={showUnderstandSimulationModal}
                        setVisible={setShowUnderstandSimulationModal}
                        lastPathDone={lastPathDone}
                        tables={tablesModal}
                    />
                )}
                <StopTutorialModal visible={showStopTutorialModal} setVisible={setShowStopTutorialModal} setShowTutoriel={setShowTutoriel} />
                <Tutoriel
                    visible={showTutoriel}
                    setVisible={setShowTutoriel}
                    showStopTutorialModal={showStopTutorialModal}
                    setShowStopTutorialModal={setShowStopTutorialModal}
                />
                <InfoAssuModal
                    onHide={onHideAppointmentModal}
                    showInfoModal={showAppointmentModal}
                    text="popin.info.appointment.text"
                    tranform={{ date: appointmentDate, time: appointmentTime, linebreak: <br /> }}
                    trackKey="popin-confirmation-rdv"
                    title="modal.error.info.title"
                    buttonPrimary="modal.error.button"
                />
                {showErrorModal && <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} description="modal.error.message.indisponible" />}
            </SGMediaQuery>
        </>
    )
};
