import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {State} from "../../../../store/store";
import {CallToActionState, MeetingAppointmentState,} from "../../../../store/callToAction/types";
import {ProjectState,} from "../../../../store/projects/types";
import {Scenario, SIMULATION_ALERTS,} from "../../../../store/simulation/types";
import {buildBalanceGraph, buildIncomesExpensesGraph,} from "../../../utils/graphs/graph-builders";
import {getAlertsByAge} from "../../../utils/graphs/graphs-parsers.service";
import {ChartsAlert} from "../../../../store/charts/types";
import {AccountState} from "../../../../store/account/types";
import {FeedbackState} from "../../../../store/feedback/types";
import {getSimulationPdf, scheduleMeeting,} from "../../../../store/callToAction/actions";
import {getHasAlreadySentFeedback,} from "../../../../store/feedback/actions";
import {getProjectsAction} from "../../../../store/projects/actions";
import {getGlobalSimulation} from "../../../../store/simulation/actions";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {getSimulationRange} from "../../../../store/simulation/utils";
import {SGModal} from "sg-modal";
import {SGSpinner} from "sg-spinner";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {CoachAdvice} from "../CoachAdvice/CoachAdvice";
import {SGBlock} from "sg-typo";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledAnalyseEpargne} from "sg-icon-pack-base";
import {useSimulation} from "../../hooks/useSimulation";
import {ErrorState, ErrorType} from "../../../../store/error/types";
import {ErrorElement} from "../../atoms/ErrorElement/ErrorElement";
import {useCustomerMeetingStatePrefix} from "../../hooks/useCustomerMeetingStatePrefix ";
import {ModalContext} from "../../providers/ModalProvider";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface GoFurtherButtonProps {
   size: string;
}

const GoFurtherButton: FunctionComponent<GoFurtherButtonProps> = ({size}: GoFurtherButtonProps) => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const hasFetchedProjects = useSelector<State, boolean>((state) => state.projects.hasFetchedProjects);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);

   const simulation = useSimulation(false);
   const prefix = useCustomerMeetingStatePrefix(undefined);

   const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
   const callToActionState: CallToActionState = useSelector<State, CallToActionState>((state) => state.callToAction);
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);
   const feedbackState: FeedbackState = useSelector<State, FeedbackState>((state) => state.feedback);
   const errorState: ErrorState = useSelector<State, ErrorState>((state) => state.error);

   const [modalSimulationVisible, setModalSimulationVisible] = useState(false);
   const [displayError, setDisplayError] = useState(true);
   const [hasAskedForDownload, setHasAskedForDownload] = useState(false);

   const {setDisplayingFeedbackModal} = useContext(ModalContext);
   const { trackPopUp } = useTracking();

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });
   const {handleSubmit} = methods;

   const hasPdfDownloadError = (): boolean => errorState.hasError && errorState.type === ErrorType.TIME_OUT_ON_PDF_DOWNLOAD_ERROR;

   useEffect(() => {
      const shouldCallToAction = hasAskedForDownload && hasFetchedProjects && hasFetchedSimulation;
      if (shouldCallToAction) {
         const chartContainer: HTMLElement = document.createElement("div");
         const range = getSimulationRange(simulation);
         const hcAlerts: Array<ChartsAlert> = getAlertsByAge(simulation);
         const balanceSvg = buildBalanceGraph(
            chartContainer,
            800,
            [],
            hcAlerts,
            simulation.graphs,
            simulation.birthday,
            range,
            intl.formatMessage
         ).getSVG({
            chart: {
               width: 550,
               height: 300,
            },
         });
         const chartContainer2: HTMLElement = document.createElement("div");
         const incExpSvg = buildIncomesExpensesGraph(
            chartContainer2,
            projectState.projects,
            simulation.birthday,
            simulation.incomeExpenses,
            Scenario.CONSERVATIVE,
            range,
            intl.formatMessage
         ).getSVG({
            chart: {
               width: 550,
               height: 300,
            },
         });
         const alerts = hcAlerts
            .filter((x) => SIMULATION_ALERTS.indexOf(x.type) !== -1)
            .map((a: ChartsAlert) => {
               if (a.type.startsWith("ALERT")) {
                  return (
                     intl.formatMessage(
                        {id: "simulation.warningYear"},
                        {year: a.year.toLocaleString()}
                     ) + intl.formatMessage({id: a.alert})
                  );
               }

               return intl.formatMessage({id: a.alert});
            });

         if (callToActionState.meetingAppointmentState === MeetingAppointmentState.CAN_BE_SENT) {
            dispatch(scheduleMeeting(incExpSvg, balanceSvg, accountState.account.email, alerts));
         } else {
            dispatch(getSimulationPdf(incExpSvg, balanceSvg, accountState.account.email, alerts));
         }
      }
   }, [hasFetchedProjects, hasFetchedSimulation, simulation, hasAskedForDownload]);

   useEffect(() => {
      if (hasAskedForDownload && callToActionState.simulationPDF) {
         const tempLink = document.createElement("a");
         document.body.appendChild(tempLink);

         tempLink.href = window.URL.createObjectURL(
            new Blob([callToActionState.simulationPDF], {type: "application/pdf"})
         );
         tempLink.download = "simulation.pdf";
         tempLink.click();
         setHasAskedForDownload(false);
         setModalSimulationVisible(false);
         setDisplayingFeedbackModal(!feedbackState.hasSentFeedback);
      }
   }, [callToActionState.simulationPDF]);

   useEffect(() => {
      if (hasAskedForDownload && callToActionState.meetingAppointmentState === MeetingAppointmentState.ALREADY_SENT_FOR_THIS_USER) {
         setHasAskedForDownload(false);
      }
   }, [callToActionState.meetingAppointmentState]);

   useEffect(() => {
      if (hasPdfDownloadError()) {
         setHasAskedForDownload(false);
      }
   }, [errorState.hasError]);

   useEffect(() => {
      if (!modalSimulationVisible) {
         setDisplayError(false);
      }
   }, [modalSimulationVisible]);

   const downloadPdf = () => {
      setHasAskedForDownload(true);
      dispatch(getProjectsAction());
      dispatch(getGlobalSimulation());
      dispatch(getHasAlreadySentFeedback());
      setDisplayError(true);
   };

   return (
      <>
         <SGButtonIntl type="primary" intlId={`netWorth.distribution.${prefix}.action`} size={size} disabled={!methods.formState.isValid}
                       cypressName="go-further-show-modal" onClick={() => {
            trackPopUp(false, "coach", "telechargement-rapport");
            setModalSimulationVisible(true);
            setHasAskedForDownload(false);
         }}/>
         <SGModal visible={modalSimulationVisible} onCancel={() => setModalSimulationVisible(false)}>
            <SGMessage
               extra={<>{hasAskedForDownload &&
                  <SGSpinner size="sm" tip={intl.formatMessage({id: "go.further.modal.wait.for.simulation"})}/>
               }
                  <SGButtonIntl intlId={`go.further.modal.${prefix}.action`} type="primary" size="md" disabled={hasAskedForDownload}
                                cypressName="go-further-submit"
                                onClick={() => {
                                   downloadPdf();
                                   trackPopUp(true, "coach", "telechargement-rapport::clic-sur-telecharger-mon-etude", "inscription", "0");
                                }
                                }/></>}
               icon={<SGIcon component={<SGAvenirStyledAnalyseEpargne/>} size="xl"/>}
               title={<SGTitleIntl intlId={`go.further.modal.${prefix}.title`} cypressName="go-further-modal-title"/>}>
               <CoachAdvice text={intl.formatMessage({id: `go.further.modal.${prefix}.coach.text`})}/>
               <SGBlock>
                  <SGTextIntl intlId={`go.further.modal.${prefix}.text`}/>
               </SGBlock>
               {displayError && <ErrorElement cypressName="go-further-error" errorTextPrefix="go.further.modal.error"/>}
            </SGMessage>
         </SGModal>
      </>
   );
};

export {GoFurtherButton};
