import { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGInputNumber, SGInputQuantity } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Family } from "store/members/types";
import {
  nextStep,
  parametersUpdated,
  previousStep,
} from "store/simulateurEconomiesImpots/slice";
import { ParametresSEI } from "store/simulateurEconomiesImpots/types";
import { getTrackProfile } from "store/simulateurEconomiesImpots/utils";
import { callTaxEconomyCeiling } from "store/simulateurPlafondEconomieImpot/actions";
import { State } from "store/store";
import { SGButtonIntl } from "website/components/atoms/SGButtonIntl/SGButtonIntl";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { anneeN } from "website/utils/date/DateUtils";
import { ReactComponent as LogoEuro } from "../../../../../assets/images/logoEuro.svg";
import "./RevenusStep.scss";

const RevenusStep: FunctionComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { trackPage } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;

  const isDeclarationCommune: boolean = useSelector<State, boolean>(
    (state) =>
      state.simulateurEconomiesImpots.parameters.isJointDeclaration ?? false
  );
  const minValNbPart = isDeclarationCommune ? 2 : 1;

  const parametresSEI: ParametresSEI = useSelector<State, ParametresSEI>(
    (state) => state.simulateurEconomiesImpots.parameters
  );

  const family: Family = useSelector<State, Family>(
    (state) => state.family.family
  );
  const [revenu, setRevenu] = useState(parametresSEI.taxIncome);
  const [revenuConjoint, setRevenuConjoint] = useState(
    parametresSEI.partnerTaxIncome
  );
  const isSuperior = (shares: number) =>
    shares > minValNbPart ? shares : minValNbPart;
  const [nbParts, setNbParts] = useState(
    parametresSEI.sharesNumber
      ? isSuperior(parametresSEI.sharesNumber)
      : minValNbPart
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const increment = 0.25;
  const minRevenu = 1000;

  // Fonction qui permet d'arrondir la valeur au 0,25 le plus proche
  const onChangeNbPart = (val: number) => {
    const newVal = Math.round(val * 4) / 4;
    setNbParts(newVal);
  };

  const decrementNbPart = () => {
    if (nbParts > minValNbPart) {
      setNbParts(nbParts - increment);
    }
  };

  // Si supérieur à 2 enfants: 1 + (nbEnfants - 2) parts
  const getEnfantsParts = (nbEnfants: number) =>
    nbEnfants > 2 ? nbEnfants - 1 : nbEnfants * 0.5;

  useEffect(() => {
    trackPage(
      "parcours-sei",
      "mes-revenus",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "2",
      { form_field_1: getTrackProfile(parametresSEI.tnsStatus) }
    );
  }, []);

  useEffect(() => {
    setIsSubmitDisabled(
      !(
        revenu &&
        revenu >= minRevenu &&
        nbParts > 0 &&
        (!isDeclarationCommune ||
          (revenuConjoint && revenuConjoint >= minRevenu))
      )
    );
  }, [revenu, nbParts, revenuConjoint]);

  useEffect(() => {
    if (!parametresSEI.simulationDate && !parametresSEI.sharesNumber) {
      // Calcul du nombre de parts la première entrée dans le parcours SEI
      setNbParts(minValNbPart + getEnfantsParts(family.children.length));
    }
  }, [family]);

  const handleOnSubmit = () => {
    dispatch(
      parametersUpdated({
        sharesNumber: nbParts,
        taxIncome: revenu,
        partnerTaxIncome: isDeclarationCommune ? revenuConjoint : undefined,
        totalAvailableCeiling: parametresSEI.totalAvailableCeiling,
        donePayment: parametresSEI.donePayment,
      })
    );
    dispatch(
      callTaxEconomyCeiling({
        taxIncome: revenu,
        partnerTaxIncome: isDeclarationCommune ? revenuConjoint : undefined,
        isJointDeclaration: isDeclarationCommune,
        tnsStatus: parametresSEI.tnsStatus,
        isPartnerTns: isDeclarationCommune
          ? parametresSEI.isPartnerTns
          : undefined,
        contractType: parametresSEI.provision,
      })
    );
    dispatch(nextStep());
  };

  const getTooltipRevenu = () =>
    `simulateurEconomiesImpots.step.revenus.revenu.tooltip${
      parametresSEI.tnsStatus ? `.${parametresSEI.tnsStatus}` : ""
    }`;

  const getTooltipRevenuConjoint = () =>
    `simulateurEconomiesImpots.step.revenus.revenuConjoint.tooltip${
      parametresSEI.isPartnerTns ? ".TNS" : ""
    }`;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmit)} className="revenus-step">
          <SGGridRow>
            <SGGridCol span={12}>
              <SGTitle level={2} textalign="left">
                {intl.formatMessage({
                  id: "simulateurEconomiesImpots.step.revenus",
                })}
              </SGTitle>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGInputNumber
                label={
                  <SGText size="l">
                    {intl.formatMessage(
                      {
                        id: "simulateurEconomiesImpots.step.revenus.revenu.label",
                      },
                      {
                        tooltip: (
                          <InfoTooltipAssu
                            text={getTooltipRevenu()}
                            placement={isPhone ? "bottomLeft" : "right"}
                            trackKey="mes-revenus::clic-sur-bouton-information-revenus-imposables"
                          />
                        ),
                        insecable: (word: string) => (
                          <span className="espaces-insecables">{word}</span>
                        ),
                        anneeN,
                      }
                    )}
                  </SGText>
                }
                suffix={<LogoEuro />}
                size={isPhone ? "xl" : "m"}
                min={0}
                max={99_999_999}
                required={false}
                value={revenu}
                formatter={(value: any) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                onChange={(value: any) => {
                  setRevenu(value);
                }}
                status={
                  revenu !== undefined && revenu < minRevenu
                    ? "error"
                    : "default"
                }
                validate={intl.formatMessage({
                  id: "simulateurEconomiesImpots.error.min",
                })}
                inputMode="decimal"
              />
            </SGGridCol>
            {isDeclarationCommune && (
              <SGGridCol span={12}>
                <SGInputNumber
                  label={
                    <SGText size="l">
                      {intl.formatMessage(
                        {
                          id: "simulateurEconomiesImpots.step.revenus.revenuConjoint.label",
                        },
                        {
                          tooltip: (
                            <InfoTooltipAssu
                              text={getTooltipRevenuConjoint()}
                              placement={isPhone ? "bottomLeft" : "right"}
                              trackKey="mes-revenus::clic-sur-bouton-information-revenus-conjoint"
                            />
                          ),
                          insecable: (word: string) => (
                            <span className="espaces-insecables">{word}</span>
                          ),
                        }
                      )}
                    </SGText>
                  }
                  suffix={<LogoEuro />}
                  min={0}
                  max={99_999_999}
                  size={isPhone ? "xl" : "m"}
                  required={false}
                  value={revenuConjoint}
                  formatter={(value: any) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }
                  onChange={(value: any) => {
                    setRevenuConjoint(value);
                  }}
                  status={
                    revenuConjoint !== undefined && revenuConjoint < minRevenu
                      ? "error"
                      : "default"
                  }
                  validate={intl.formatMessage({
                    id: "simulateurEconomiesImpots.error.min",
                  })}
                />
              </SGGridCol>
            )}
            <SGGridCol>
            <div className="revenus-step__nbParts">
              <SGInputQuantity
                unitvalue=""
                label={
                  <SGText size="l">
                    {intl.formatMessage(
                      {
                        id: "simulateurEconomiesImpots.step.revenus.nbParts.label",
                      },
                      {
                        tooltip: (
                          <InfoTooltipAssu
                            text="simulateurEconomiesImpots.step.revenus.nbParts.tooltip"
                            placement={isPhone ? "top" : "right"}
                            className="revenus-step__tooltip"
                            transformations={{
                              ul: (word: string) => <ul>{word}</ul>,
                              li: (word: string) => <li>{word}</li>,
                            }}
                            trackKey="mes-revenus::clic-sur-bouton-information-nombre-de-parts"
                          />
                        ),
                        insecable: (word: string) => (
                          <span className="espaces-insecables">{word}</span>
                        ),
                      }
                    )}
                  </SGText>
                }
                value={nbParts}
                min={minValNbPart}
                onChange={onChangeNbPart}
                increment={increment}
                onDecrement={decrementNbPart}
                onIncrement={() => {
                  setNbParts(nbParts < minValNbPart ? minValNbPart : nbParts + increment);
                }}
                precision={1}
                step={0.25}
              />
              </div>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGBox margin={{ top: isPhone ? "lg" : "xxl" }}>
                <SGButtonGroup
                  layout={isPhone ? "column" : "row"}
                  align={isPhone ? "center" : "opposite"}
                >
                  <SGButton
                    disabled={isSubmitDisabled}
                    onClick={handleOnSubmit}
                    style={!isPhone ? { order: 1 } : {}}
                  >
                    {intl.formatMessage({ id: "tunnel.buttons.next" })}
                  </SGButton>
                  <SGButtonIntl
                    type="link"
                    cypressName="tunnel-previous"
                    onClick={() => dispatch(previousStep())}
                    intlId="tunnel.buttons.previous"
                  />
                </SGButtonGroup>
              </SGBox>
            </SGGridCol>
          </SGGridRow>
        </form>
      </FormProvider>
    </>
  );
};

export { RevenusStep };
