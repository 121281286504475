import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Family, Member} from "../../../../../store/members/types";
import {State} from "../../../../../store/store";
import {getMembersAction, updateFamilyAction,} from "../../../../../store/members/actions";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {toMembers} from "../../../../../store/members/utils";
import {triggerIncomesPageRedirect} from "../../../../../store/routing/actions";
import {useRedirect} from "../../../hooks/useRedirect";
import {useRetirementProject} from "../../../hooks/useRetirementProject";
import {useFamily} from "../../../hooks/useFamily";
import {EditorContext} from "../../../providers/EditorProvider";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {resetTriggerRedirectToIncomes} from "../../../../../store/members/membersSlice";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGSpace} from "sg-space";
import {MemberElement} from "../../../molecules/MemberElement/MemberElement";
import {ButtonAddNewElement} from "../../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";

const FamilyEditor: FunctionComponent = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   const retirementProject = useRetirementProject();
   useRedirect();

   const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);
   const family = useFamily();

   const hasLocalChanges: boolean = useSelector<State, boolean>((state) => state.family.hasLocalChanges);
   const shouldRedirectToIncomes: boolean = useSelector<State, boolean>((state) => state.family.shouldRedirectToIncomes);

   const [isCreatingMember, setIsCreatingMember] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);

   useEffect(() => {
      setEditing(isCreatingMember)
   }, [isCreatingMember]);

   useEffect(() => {
      setIsCreatingMember(false)
   }, [family]);

   const newMember: Member = {deletable: true, incomes: [], name: "", projects: [], status: ""};

   // On affiche un avertissement si l'utilisateur crée un PARTNER alors qu'il possède déjà un projet retraite
   // En effet, celui-ci devra recréer le projet retraite s'il veut que celui prenne en compte son PARTNER
   const displayNewPartnerWithRetirementProjectWarning = () => hasLocalChanges && retirementProject && family.partner && !family.partner?.id;

   const onCancelFamily = () => {
      dispatch(resetTriggerRedirectToIncomes());
      dispatch(getMembersAction());
   };

   const onSubmitFamily = () => {
      const familyToDispatch: Family = {me: family.me, partner: family.partner, children: family.children, relatives: family.relatives};

      dispatch(updateFamilyAction(familyToDispatch, false, true));
   };

   useEffect(() => {
      if (shouldRedirectToIncomes && !hasLocalChanges) {
         dispatch(resetTriggerRedirectToIncomes());
         dispatch(triggerIncomesPageRedirect());
      }
      setIsCreatingMember(false);
   }, [shouldRedirectToIncomes]);

   useEffect(
      () =>
         history.listen((_location) => {
            // nettoyage pour éviter un dirty state en cas de navigation sans sauvegarde
            // NB: J'ai essayé de conditionner l'action avec un if(hasLocalChanges) pour éviter les appels inutiles, mais c'est buggé
            dispatch(getMembersAction());
         }),
      [history]
   );

   return (
      <SpinnerWrapper displayComponent={hasFetchedFamily} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace disableautomargin direction="vertical">
            {displayNewPartnerWithRetirementProjectWarning() &&
               <SGAlertIntl cypressName="family-warning-new-partner-with-retirement" type="warning" title="common.warning">
                  <SGTextIntl intlId="family.warning.retirement-project"/>
               </SGAlertIntl>
            }
            <SGGridRow>
               {hasFetchedFamily && toMembers(family).map((member: Member) => (
                  <SGGridCol span={12} key={member.id} disableautomargin>
                     <MemberElement key={member.id} member={member} cypressName={`family-member-element-${member.status}`}/>
                  </SGGridCol>
               ))}
            </SGGridRow>
            {isCreatingMember && (
               <MemberElement member={newMember} isCreating cypressName="new-member" onCancel={() => setIsCreatingMember(false)}/>
            )}
            <ButtonAddNewElement cypressName="family-add-member" text="common.add-member" disabled={isEditing}
                                 onClick={() => setIsCreatingMember(true)}/>

            {hasLocalChanges && (
               <SGButtonGroup>
                  <SGButtonIntl type="primary" size="md" disabled={isEditing} cypressName="family-submit" onClick={onSubmitFamily} intlId="common.save"/>
                  <SGButtonIntl type="secondary" size="md" disabled={isEditing} cypressName="family-cancel" onClick={onCancelFamily}
                                intlId="common.cancel"/>
               </SGButtonGroup>
            )}
            <ErrorElement cypressName="family-api-errors" errorTextPrefix="profile.family.error" notificationFrame/>
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {FamilyEditor};
