import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {LIFE_PROJECTS, Project, ProjectState} from "../../../store/projects/types";
import {getProjectsAction} from "../../../store/projects/actions";

const useProjects = (forceFetch: boolean, sorted: boolean) => {
   const dispatch = useDispatch();
   const [projects, setProjects] = useState<Project[]>([]);
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   useEffect(() => {
      if (!projectState.hasFetchedProjects || forceFetch) {
         dispatch(getProjectsAction());
      }
   }, []);

   useEffect(() => {
      if (projectState.hasFetchedProjects) {
         setProjects(sorted ? projectState.projects.filter((project) => LIFE_PROJECTS.includes(project.projectType)) : projectState.projects);
      }
   }, [projectState]);

   return projects;
};

export {useProjects};
