import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AssetState, AssetWithValuation, INVESTMENT_ASSETS} from "../../../store/assets/types";
import {getAssetsWithoutLoans} from "../../../store/assets/actions";
import {getIncomesAction} from "../../../store/incomes/actions";
import {IncomeState} from "../../../store/incomes/types";
import {isPlannedSavingsBuybackIncome} from "../../../store/incomes/typeguards";

const useInvestmentAssets = (onlyAvailableForBuyback: boolean) => {
   const dispatch = useDispatch();
   const [investmentAssets, setInvestmentAssets] = useState<AssetWithValuation[]>([]);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
   const incomeState: IncomeState = useSelector<State, IncomeState>((state) => state.incomes);

   useEffect(() => {
      if (!assetState.hasFetched) {
         dispatch(getAssetsWithoutLoans());
      }
      if (!incomeState.hasFetched) {
         dispatch(getIncomesAction());
      }
   }, []);

   useEffect(() => {
      if (assetState.hasFetched && incomeState.hasFetched) {
         setInvestmentAssets(assetState.assets.filter((asset) => INVESTMENT_ASSETS.includes(asset.assetType) &&
            (!onlyAvailableForBuyback || !incomeState.incomes.some((income) => isPlannedSavingsBuybackIncome(income) && income.savingsAsset?.id === asset.id))));
      }
   }, [incomeState.incomes, assetState.assets]);

   return investmentAssets;
};

export {useInvestmentAssets};
