import React, {FunctionComponent} from "react";
import {LeaveProject} from "../components/organisms/Projects/LeaveProject/LeaveProject";
import {SGContent} from "sg-layout";

const TravelPage: FunctionComponent = () => (
   <SGContent>
     <LeaveProject type="travel"/>
   </SGContent>
);

export { TravelPage };
