import React, {ChangeEvent, FunctionComponent, useState,} from "react";
import {Member} from "../../../../store/members/types";
import {IncomeType} from "../../../../store/incomes/types";
import {SGRadio, SGRadioGroup} from "sg-radio";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {useIntl} from "react-intl";
import {Controller, useFormContext} from "react-hook-form";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {calculateAgeByBirthdate, MAX_AGE_TO_RETIRE, MIN_AGE_TO_RETIRE} from "../../../../store/members/utils";
import {SGCard} from "sg-card";
import {FOREST_GREEN, TEA_GREEN} from "../../../utils/graphs/colors";

interface IncomeEditProps {
   member: Member;
   income: { type: IncomeType, monthlyAmount?: number };
   cypressName: string;
}

const IncomeSalary: FunctionComponent<IncomeEditProps> = (props: IncomeEditProps) => {
   const {member, income, cypressName} = props;
   const intl = useIntl();
   const methods = useFormContext();
   const [incomeType, setIncomeType] = useState(income.type);

   const shouldDisplayType = (): boolean => {
      if (!member.birthday) {
         return true;
      }
      const age = calculateAgeByBirthdate(member.birthday);

      return age <= MAX_AGE_TO_RETIRE && age >= MIN_AGE_TO_RETIRE;
   };

   return (
      <SGCard data-cy="tunnel-income-situation" borderleft={`.3rem solid ${incomeType === IncomeType.WORK_INCOME ? FOREST_GREEN.hexCode : TEA_GREEN.hexCode}`}>
         {shouldDisplayType() && (
            <Controller control={methods.control} name={`${member.status.toLowerCase()}Type`} defaultValue={income.type} render={({onChange, value}) => (
               <SGRadioGroup legend={intl.formatMessage({id: `form.income.situation.${member.status}`})} value={value} disableautomargin
                             onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                onChange(event.target.value)
                                setIncomeType(event.target.value);
                             }}>
                  <SGRadio value={IncomeType.WORK_INCOME}>
                     <SGTextIntl intlId="form.income.status.WORK_INCOME"/>
                  </SGRadio>

                  <SGRadio value={IncomeType.RETIREMENT_INCOME}>
                     <SGTextIntl intlId="form.income.status.RETIREMENT_INCOME"/>
                  </SGRadio>
               </SGRadioGroup>
            )}/>
         )}
         <NumericInput name={`${member.status.toLowerCase()}Amount`} label={`form.income.name.${member.status}.${incomeType}`} key={income.monthlyAmount}
                       tooltipLabel="form.income.amount.work-income.tooltip" min={0} max={100_000} required
                       defaultValue={income.monthlyAmount} cypressName={cypressName} suffix="€" displayPeriodicChoice
                       placeholder={intl.formatMessage({id: "form.income.amount.work-income.placeholder"})}/>
      </SGCard>
   );
};

export {IncomeSalary};
