import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import {
    SGAvenirNavFlecheBtnLien,
    SGAvenirStyledAide,
    SGAvenirStyledArticle,
    SGAvenirStyledDocumentContrat,
    SGAvenirStyledPuzzle,
    SGAvenirStyledSolution,
} from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useArticles } from "website/components/hooks/useArticles";
import { ARTICLES, MES_SERVICES, MON_PLAN } from "website/utils/privateRoutes";
import "./InfoCard.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface InfoCardProps {
    id: InfoCardType;
    onCardAction?: () => void;
}

export enum InfoCardType {
    saviezVous = "saviez-vous",
    monPlan = "plan",
    mesServices = "services",
    mesArticles = "articles",
    monGuide = "tutoriel",
}

const listArticles = [
    "idees-preparer-retraite",
    "optimiser-retraite-obligatoire",
    "impact-enfant-retraite",
    "estimer-budget-retraite",
    "partir-retraite",
    "zoom-retraite-progressive",
    "comprendre-ris",
];

export const InfoCard: FunctionComponent<InfoCardProps> = (props: InfoCardProps) => {
    const { id, onCardAction } = props;
    const intl = useIntl();
    const history = useHistory();
    const { getLienArticle } = useArticles();
    const [article] = useState<string>(() => initArticle());
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const { trackClick } = useTracking();

    const trackMenu = (val: string) => {
        trackClick(`clic-sur-${val}`);
    };

    function initArticle() {
        if (id === InfoCardType.saviezVous) {
            return listArticles[Math.floor(Math.random() * listArticles.length)];
        }

        return "";
    }

    const handleTrackingAndRedirect = () => {
        switch (id) {
            case InfoCardType.saviezVous:
                trackMenu("comprendre-ma-retraite");
                history.push(getLienArticle(article));
                break;
            case InfoCardType.monPlan:
                trackMenu("mon-plan");
                history.push(MON_PLAN);
                break;
            case InfoCardType.mesServices:
                trackMenu("mes-services");
                history.push(MES_SERVICES);
                break;
            case InfoCardType.mesArticles:
                trackMenu("mes-articles");
                history.push(ARTICLES);
                break;
            case InfoCardType.monGuide:
                if (onCardAction) {
                    onCardAction();
                }
                break;
            default:
        }
    };

    function getSGIcon() {
        switch (id) {
            case InfoCardType.saviezVous:
                return <SGIcon component={<SGAvenirStyledSolution />} size="s" />;
            case InfoCardType.monPlan:
                return <SGIcon component={<SGAvenirStyledDocumentContrat />} size="s" />;
            case InfoCardType.mesServices:
                return <SGIcon component={<SGAvenirStyledPuzzle />} size="s" />;
            case InfoCardType.mesArticles:
                return <SGIcon component={<SGAvenirStyledArticle />} size="s" />;
            case InfoCardType.monGuide:
                return <SGIcon component={<SGAvenirStyledAide />} size="s" />;
            default:
        }
    }

    return (
        <SGCard
            className="info-card"
            actions={[
                <SGButton
                    type="tertiary"
                    icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                    iconposition="right"
                    onClick={handleTrackingAndRedirect}
                >
                    <FormattedMessage id={`dashboard.infos.label.${id}`} />
                </SGButton>,
            ]}
        >
            <SGSpace size="sm" direction="vertical" textalign={isPhone ? "left" : "center"}>
                <SGBox key="box" margin={{ bottom: "sm" }} forcemargin>
                    {getSGIcon()}
                </SGBox>
                <SGTitle key="title" level={1} visuallevel={4} caps>
                    {intl.formatMessage({ id: `dashboard.infos.title.${id}` })}
                </SGTitle>
                <SGText key="text" size="s">{intl.formatMessage({ id: `dashboard.infos.text.${id === InfoCardType.saviezVous ? article : id}` })}</SGText>
            </SGSpace>
        </SGCard>
    );
};
