import { FunctionComponent, useContext } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { UploadButton } from "website/components/atoms/UploadButton/UploadButton";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface ParcoursRISCarteImportProps {
    setUploadedFile: (file?: File) => void;
    errorMessage: string;
    setErrorMessage: (message: string) => void;
    onSubmit: () => void;
}

const ParcoursRISCarteImport: FunctionComponent<ParcoursRISCarteImportProps> = (props: ParcoursRISCarteImportProps) => {
    const intl = useIntl();
    const { trackClick } = useTracking();

    const { setUploadedFile, errorMessage, setErrorMessage, onSubmit } = props;

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
    });

    const { handleSubmit } = methods;

    const handleOnClickUpload = () => {
        trackClick("preparation::clic-sur-parcourir");
    };

    return (
        <SGCard>
            <SGSpace direction="vertical" size="xs" textalign="center">
                <SGText key="text-1" size="xxl" weight="600">
                    {intl.formatMessage({ id: "parcoursRIS.preparation.text.ris.oui" })}
                </SGText>
                <SGText key="text-2" size="l">{intl.formatMessage({ id: "parcoursRIS.preparation.text.pdf" })}</SGText>
                <SGBox key="box-1" alignchildren={["center"]}>
                    <UploadButton
                        setUploadedFile={setUploadedFile}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        maxSize={30000000}
                        errorMessageSize="parcoursRIS.importReleve.erreur.size"
                        onClick={handleOnClickUpload}
                    />
                </SGBox>
                <SGBox key="box-2" margin={{ bottom: "sm" }} forcemargin>
                    <BoutonSupervision onClick={handleSubmit(onSubmit)} ariaLabel={intl.formatMessage({ id: "parcoursRIS.preparation.commencerEstimation.ariaLabel" })}>
                        {intl.formatMessage({ id: "parcoursRIS.preparation.commencerEstimation" })}
                    </BoutonSupervision>
                </SGBox>
            </SGSpace>
        </SGCard>
    );
};

export { ParcoursRISCarteImport };
