import React, {FunctionComponent} from "react";
import {SGContent} from "sg-layout";
import {WeddingProject} from "../../components/organisms/Projects/WeddingProject/WeddingProject";

const WeddingProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <WeddingProject/>
   </SGContent>
);

export {WeddingProjectPage};
