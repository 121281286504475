import sapiendoImg from "assets/images/sapiendo-baseline.svg";
import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Acquis } from "store/parcoursRisInformationsComplementaires/types";
import { Image } from "website/components/atoms/Image/Image";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useArticles } from "website/components/hooks/useArticles";
import { InfoModalFiabiliteRIS } from "website/components/molecules/InfoModalFiabiliteRIS/InfoModalFiabiliteRIS";
import "./CarteReleve.scss";

type CarteReleveProps = {
  fiabilite: number;
  acquis: Acquis;
};

export const CarteReleve: FunctionComponent<CarteReleveProps> = (
  props: React.PropsWithChildren<CarteReleveProps>
) => {
  const { fiabilite, acquis } = props;
  const { getLienArticle } = useArticles();

  const [showInfoModalFiabiliteRIS, setShowInfoModalFiabiliteRIS] =
    useState<boolean>(false);

  const dateReleve = acquis.date_trimestre_restants
    .split("-")
    .reverse()
    .join("/");

  const intl = useIntl();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const getFiabilite = () => {
    if (fiabilite < 33) {
      return "faible";
    }

    if (fiabilite > 66) {
      return "eleve";
    }

    return "modere";
  };

  return (
    <SGSpace disableautomargin direction="vertical" size="sm" textalign="left">
      <SGBox key="box">
        <SGGridRow>
          <SGGridCol span={8} md={10}>
            <SGSpace direction="vertical" disableautomargin size="sm">
              <SGTitle key="title" textalign="left" level={3}>
                {intl.formatMessage({ id: "parcoursRIS.resultat.releve" })}
              </SGTitle>
              <SGTextIntl
                key="text-1"
                intlId="parcoursRIS.resultat.releveDate"
                size="xl"
                transformations={{ date: dateReleve }}
              />
              <SGTextIntl
                key="text-2"
                intlId="parcoursRIS.resultat.reliability.ris"
                size="m"
                transformations={{
                  b: (word: string) => <SGText weight="700">{word}</SGText>,
                  reliability: intl.formatMessage({
                    id: `parcoursRIS.resultat.reliability.${getFiabilite()}`,
                  }),
                  info: (
                    <SGButton
                      type="icon"
                      icon={
                        <SGIcon component={<SGAvenirStatusInfo />} size="s" />
                      }
                      onClick={() => setShowInfoModalFiabiliteRIS(true)}
                    />
                  ),
                  insecable: (word: string) => (
                    <SGText className="espaces-insecables">{word}</SGText>
                  ),
                }}
              />
            </SGSpace>
          </SGGridCol>
          <SGGridCol span={4} md={2} textalign="left">
            <SGBox>
              <>
                <SGBlock textalign="left" disableautomargin>
                  <SGText size="s">
                    {intl.formatMessage({
                      id: "parcoursRIS.resultat.analysed",
                    })}
                  </SGText>
                </SGBlock>
                <Image
                  src={sapiendoImg}
                  alt="logo-sapiendo"
                  className="carte-releve__img-sapiendo"
                />
              </>
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      </SGBox>
      <SGTextIntl
        key="text"
        intlId="parcoursRIS.resultat.erreur.pourcentage"
        size="m"
        transformations={{
          erreurFiabilite: 100 - fiabilite,
        }}
      />
      <SGBlock key="block" component="p">
        <SGText color="lighter">
          <FormattedMessage
            id={`parcoursRIS.resultat.reliability.text.${getFiabilite()}`}
          />
        </SGText>
        <InfoModalFiabiliteRIS
          visible={showInfoModalFiabiliteRIS}
          setVisible={setShowInfoModalFiabiliteRIS}
        />
        <SGBox inline={!isPhone} margin={isPhone ? {} : { left: "xs" }}>
          <SGLink
            href={`#${getLienArticle("comprendre-ris#verifier-document")}`}
          >
            <FormattedMessage id="parcoursRIS.resultat.reliability.article" />
          </SGLink>
        </SGBox>
      </SGBlock>
    </SGSpace>
  );
};
