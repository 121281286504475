import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { useArticles } from "website/components/hooks/useArticles";
import { CategorieVideo, useVideos } from "website/components/hooks/useVideos";
import { getIdTrackingOnglet, ONGLET_ACCUEIL } from "website/components/molecules/Article/ArticleModel";
import { DecouverteServicesAccompagnement } from "website/components/molecules/DecouverteServicesAccompagnement/DecouverteServicesAccompagnement";
import { ARTICLE } from "website/utils/privateRoutes";
import { CarrouselALaUne } from "./CarrouselALaUne";
import { CarteArticleHorizontal } from "./CarteArticleHorizontal";
import { CarteArticleVertical } from "./CarteArticleVertical";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface OngletAccueilProps {
    handleOnChangeOnglet: (thematique: string) => void;
}

const OngletAccueil: FunctionComponent<OngletAccueilProps> = (props: OngletAccueilProps) => {
    const { handleOnChangeOnglet } = props;

    const intl = useIntl();
    const history = useHistory();
    const { thematiques, articlesTries } = useArticles();
    const videos = useVideos(CategorieVideo.ARTICLES);
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick } = useTracking();

    const handleOnClickLireArticle = (path: string, trackKey: string) => {
        trackClick(trackKey, { form_field_1: path });
        history.push(`${ARTICLE}${path}`);
    };

    return (
        <SGGridRow>
            <SGGridCol span={12} md={8}>
                <SGSpace direction="vertical" disableautomargin size="xl">
                    <CarrouselALaUne
                        key="carousel"
                        articles={articlesTries.slice(0, 3)}
                        videos={videos.filter((video) => video.ALaUne)}
                        handleOnClickLireArticle={handleOnClickLireArticle}
                        trackKeyArticle={`${ONGLET_ACCUEIL}::a-la-une:clic-sur-lire-l-article`}
                        trackKeyVideo={`${ONGLET_ACCUEIL}::a-la-une:clic-sur-lire-la-video`}
                    />
                    {thematiques.map((thematique) => (
                        <SGSpace direction="vertical" size={isPhone ? "md" : "lg"} key={thematique}>
                            <SGTitle key="title" level={2}>{intl.formatMessage({ id: `articles.thematique.titre.${thematique}` })}</SGTitle>
                            <SGGridRow key="row" align="stretch" gutter={[24, 16]}>
                                {articlesTries
                                    .filter((article) => article.theme === thematique && article.favoriAccueil)
                                    .map((article) => (
                                        <SGGridCol span={12} md={6} key={article.id}>
                                            <SGMediaQuery minwidth="md">
                                                <CarteArticleVertical
                                                    media={article}
                                                    handleOnClickLireArticle={handleOnClickLireArticle}
                                                    trackKey={`${ONGLET_ACCUEIL}::${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                                />
                                            </SGMediaQuery>
                                            <SGMediaQuery minwidth={0} maxwidth="sm">
                                                <CarteArticleHorizontal
                                                    article={article}
                                                    handleOnClickLireArticle={handleOnClickLireArticle}
                                                    trackKey={`${ONGLET_ACCUEIL}::${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                                />
                                            </SGMediaQuery>
                                        </SGGridCol>
                                    ))}
                            </SGGridRow>
                            <SGButtonGroup key="buttons" align="right">
                                <SGButton
                                    type="secondary"
                                    onClick={() => {
                                        trackClick(`${ONGLET_ACCUEIL}::clic-sur-consulter-la-rubrique-${getIdTrackingOnglet(thematique)}`);
                                        handleOnChangeOnglet(thematique);
                                        window.scrollTo(0, 0);
                                    }}
                                    size={["md", "sm"]}
                                    ariaLabel={intl.formatMessage({ id: "articles.accueil.bouton.consulter.ariaLabel" })}
                                >
                                    {intl.formatMessage({ id: "articles.accueil.bouton.consulter" })}
                                </SGButton>
                            </SGButtonGroup>
                        </SGSpace>
                    ))}
                </SGSpace>
            </SGGridCol>
            <SGGridCol span={12} md={4}>
                <DecouverteServicesAccompagnement onglet={ONGLET_ACCUEIL} />
            </SGGridCol>
        </SGGridRow>
    );
};

export { OngletAccueil };
