import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGModal } from "sg-modal";
import { SGText, SGTitle } from "sg-typo";
import "./AssuModal.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface AssuModalProps {
   type: "accesTutoriel" | "stopTutoriel" | "exitRis";
   visible: boolean;
   closable: boolean;
   onClickLeftButton: () => void;
   onClickRightButton: () => void;
   onCancel?: () => void;
   isSubtitle?: boolean;
}

const AssuModal: FunctionComponent<AssuModalProps> = (props: AssuModalProps) => {
   const { type, visible, closable, onClickLeftButton, onClickRightButton, onCancel, isSubtitle } = props;
   const intl = useIntl();
   const { trackPopin } = useTracking();

   useEffect(() => {
      if (visible) {
         if (type === "accesTutoriel" || type === "stopTutoriel") {
            trackPopin(false, "tableau-de-bord", `popin-tutoriel-${type === "accesTutoriel" ? "intro" : "sortie"}`, "tutoriel",
               type === "accesTutoriel" ? "0" : undefined);
         }
      }
   }, [visible]);

   return (
      <SGModal className="assu-modal__modal" visible={visible} closable={closable} onCancel={onCancel}>
         <SGGridRow gutter={[0, 20]}>
            <SGGridCol>
               <SGGridRow gutter={[0, 8]}>
                  <SGGridCol span={12} textalign="left">
                     <SGTitle level={3} textalign="left">
                        {intl.formatMessage({ id: `assuModal.${type}.title` })}
                     </SGTitle>
                  </SGGridCol>
                  {isSubtitle && (
                     <SGGridCol span={12} textalign="left">
                        <SGText size="m">{intl.formatMessage({ id: `assuModal.${type}.subtitle`, defaultMessage: " " })}</SGText>
                     </SGGridCol>)}
               </SGGridRow>
            </SGGridCol>
            <SGGridCol span={12}>
               <SGGridRow align="middle">
                  <SGGridCol span={6} textalign="left">
                     <SGButton type="link" underline={false} size="sm" onClick={onClickLeftButton}>
                        {intl.formatMessage({ id: `assuModal.${type}.button.left` })}
                     </SGButton>
                  </SGGridCol>
                  <SGGridCol span={6} textalign="right">
                     <SGButton type="primary" size="sm" disableautomargin onClick={onClickRightButton}>
                        {intl.formatMessage({ id: `assuModal.${type}.button.right` })}
                     </SGButton>
                  </SGGridCol>
               </SGGridRow>
            </SGGridCol>
         </SGGridRow>
      </SGModal>
   );
};

export { AssuModal };
