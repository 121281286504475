import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormContext} from "react-hook-form";
import {useIntl} from "react-intl";
import {Income, IncomeType} from "../../../../store/incomes/types";
import {
   AssetType,
   AssetWithValuation,
   REAL_ESTATE_ASSETS,
   RealEstateAsset,
   RealEstateAssetTaxSystemSubType,
   RealEstateAssetTaxSystemType,
} from "../../../../store/assets/types";
import {deleteIncomeAction} from "../../../../store/incomes/actions";
import {deleteAsset, updateAsset, updateRealEstateAsset} from "../../../../store/assets/actions";
import {
   AssetEditFormData,
   getIncomeGraphCategory,
   isAssociatedToSCI,
   parseFormDataForAssetMemberLink,
   parseFormDataForLoanAsset,
   parseFormDataForRealEstateRentAsset,
} from "../../../../store/assets/utils";
import {IncomeEdit} from "../IncomeEdit/IncomeEdit";
import {AssetEdit} from "../AssetEdit/AssetEdit";
import {DeleteModal} from "../DeleteModal/DeleteModal";
import {useFamily} from "../../hooks/useFamily";
import {useLoans} from "../../hooks/useLoans";
import {EditorContext} from "../../providers/EditorProvider";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirStrokedCorbeille, SGAvenirStrokedModification} from "sg-icon-pack-base";
import {SGCard} from "sg-card";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";
import {isAssetWithValuationType} from "../../../../store/assets/typeguards";

interface IncomeElementProps {
   income: Income;
   asset?: AssetWithValuation;
   assets: AssetWithValuation[];
   cypressName: string;
}

const IncomeElement: FunctionComponent<IncomeElementProps> = (
   props: IncomeElementProps
) => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();
   const loans = useLoans([]);
   const {income, asset, assets, cypressName} = props;

   const {isEditing, setEditing} = useContext(EditorContext);
   const [isEditingIncome, setIsEditingIncome] = useState(false);
   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

   const methods = useFormContext();

   useEffect(() => {
      setIsEditingIncome(false);
      if (isDeleteModalVisible) {
         setDeleteModalVisible(false);
      }
   }, [income]);

   useEffect(() => {
      setEditing(isEditingIncome);
   }, [isEditingIncome]);

   const onSubmit = (data: AssetEditFormData) => {
      if (asset === undefined) {
         return;
      }

      let currentAsset: Partial<RealEstateAsset> = {
         ...asset,
         assetName: data.assetName,
         assetValuation: {
            ...asset.assetValuation,
            totalValuation: data.totalValuation
         },
         acquisitionDate: data.acquisitionDate,
         loans: [],
      };

      if (data.assetMemberLink) {
         currentAsset.assetMemberLinkSet = parseFormDataForAssetMemberLink(
            data,
            family
         );
      } else {
         currentAsset.assetMemberLinkSet = asset.assetMemberLinkSet;
      }

      if (data.loans) {
         currentAsset.loans = parseFormDataForLoanAsset(data, loans);
      }

      if (asset.assetType === AssetType.REAL_ESTATE_RENT) {
         currentAsset = parseFormDataForRealEstateRentAsset(currentAsset, data);
      }

      if (!!currentAsset.assetType && REAL_ESTATE_ASSETS.includes(currentAsset.assetType)) {
         dispatch(updateRealEstateAsset(currentAsset));
      } else if (isAssetWithValuationType(currentAsset)) {
         dispatch(updateAsset(currentAsset));
      }
   };

   const isRentalRealEstate = (): boolean =>
      Object.keys(RealEstateAssetTaxSystemType).includes(income.type) ||
      Object.keys(RealEstateAssetTaxSystemSubType).includes(income.type);

   const handleClickRemove = () => {
      if (isRentalRealEstate()) {
         const assetToDelete = assets.find((a) => a.id === income.id);
         if (assetToDelete) {
            dispatch(deleteAsset(assetToDelete)); // The income is a rental real estate
         }
      } else {
         dispatch(deleteIncomeAction(income.id));
      }
   };

   const renderIncomeElement = () => (
      <SGGridRow align="center">
         <SGGridCol span={6}>{income.name}</SGGridCol>
         <SGGridCol span={4}>
            <CustomNumberFormat thousandSeparator={" "} suffix={intl.formatMessage({id: "common.euro.per.month"})} displayType="text"
                                value={income.monthlyAmount}/>
         </SGGridCol>
         <SGGridCol span={2} textalign="right">
            <SGButton type="icon" data-cy={`${cypressName}-edit-button`}
                      disabled={isEditing || !(Object.values(IncomeType).includes(income.type) || isRentalRealEstate())}
                      onClick={() => setIsEditingIncome(true)}>
               <SGIcon component={<SGAvenirStrokedModification/>}/>
            </SGButton>
            <SGButton type="icon" data-cy={`${cypressName}-delete-button`}
                      disabled={isEditing || !(Object.values(IncomeType).includes(income.type) || isRentalRealEstate())}
                      onClick={() => setDeleteModalVisible(true)}>
               <SGIcon component={<SGAvenirStrokedCorbeille/>}/>
            </SGButton>
         </SGGridCol>
      </SGGridRow>
   );

   return (
      <SGCard align="center" data-cy={`${cypressName}-element`}
              borderleft={income ? `.3rem solid ${getIncomeGraphCategory(income?.type).color.hexCode}` : ""}>
         {isEditingIncome && !asset && (
            <IncomeEdit {...methods} income={income} onCancel={() => setIsEditingIncome(false)} cypressName={`${cypressName}-edit`}/>
         )}
         {isEditingIncome && asset && (
            <AssetEdit
               {...methods} asset={asset} onCancel={() => setIsEditingIncome(false)} onSubmit={onSubmit} isRealEstateIncome
               cypressName={`${cypressName}-asset-edit`} displayAssetShares isAssetMemberLinkDisabled={isAssociatedToSCI(assets, asset)}/>
         )}
         {!isEditingIncome && renderIncomeElement()}

         {/* Modal de confirmation de suppression */}
         {isDeleteModalVisible && (
            <DeleteModal hideModal={() => setDeleteModalVisible(false)} onSubmit={handleClickRemove} cypressName={cypressName}
                         textId="common.delete.modal.text"/>
         )}
      </SGCard>
   );
};

export {IncomeElement};
