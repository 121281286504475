import { ReactComponent as Arrow } from "assets/images/red_arrow_right.svg";
import { ReactComponent as LogoCross } from "assets/images/redcross.svg";
import sapiendoImg from "assets/images/sapiendo-baseline.svg";
import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGModal } from "sg-modal";
import { SGText, SGTitle } from "sg-typo";
import { PathType } from "store/dashboard/types";
import { useArticles } from "website/components/hooks/useArticles";
import { HypothesisModal } from "../HypothesisModal/HypothesisModal";
import "./UnderstandSimulationModal.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface UnderstandSimulationModalProps {
    visible: boolean;
    setVisible: (showParcoursRISModal: boolean) => void;
    lastPathDone?: PathType;
    tables: string[][];
}

export const UnderstandSimulationModal: FunctionComponent<UnderstandSimulationModalProps> = (props: UnderstandSimulationModalProps) => {
    const { visible, setVisible, lastPathDone, tables } = props;
    const intl = useIntl();
    const history = useHistory();
    const { getLienArticle } = useArticles();
    const [showHypothesisModal, setShowHypothesisModal] = useState(false);
    const { resetTracking } = useTracking();

    const handleOnClose = () => {
        setVisible(false);
        resetTracking();
    };

    const handleRedirectToArticleJargon = () => {
        history.push(getLienArticle("retraite-sans-jargon"));
    };

    return (
        <SGModal className="understand-simulation-modal__modal" visible={visible} centered onCancel={handleOnClose} closeIcon={<LogoCross />}>
            <SGGridRow>
                <SGGridCol span={12} align="start">
                    <SGGridRow gutter={[0, 15]}>
                        <SGGridCol span={10}>
                            <SGTitle caps level={2}>
                                {intl.formatMessage({ id: `understandPaths.modal.${lastPathDone}.title` })}
                            </SGTitle>
                        </SGGridCol>

                        <SGGridCol span={12}>
                            <div className="understand-simulation-modal__modal-content">
                                <SGGridRow gutter={[0, 15]}>
                                    <SGGridCol span={12}>
                                        <SGGridRow gutter={[1, 8]}>
                                            {lastPathDone !== PathType.ONBOARDING && (
                                                <SGGridCol span={12}>
                                                    <SGText className="understand-simulation-modal__text">
                                                        {intl.formatMessage(
                                                            { id: `understandPaths.modal.${lastPathDone}.subtitle` },
                                                            { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                                        )}
                                                    </SGText>
                                                </SGGridCol>
                                            )}
                                            <SGGridCol>
                                                <img src={sapiendoImg} alt="logo-sapiendo" style={{ width: "140px", height: "90px", marginTop: "-12px" }} />
                                            </SGGridCol>

                                            <SGGridCol>
                                                <SGText className="understand-simulation-modal__text">
                                                    {intl.formatMessage({ id: `understandPaths.modal.${lastPathDone}.paragraph` })}
                                                </SGText>
                                            </SGGridCol>
                                        </SGGridRow>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGText className="understand-simulation-modal__text">
                                            {intl.formatMessage(
                                                { id: `understandPaths.modal.${lastPathDone}.table.title` },
                                                { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        {tables?.map((table, index) => (
                                            <React.Fragment key={`table-${String(index)}`}>
                                                <div className="understand-simulation-modal__table-title">
                                                    <SGGridRow gutter={[7]}>
                                                        <SGGridCol span={5}>
                                                            <SGText weight="600">
                                                                {intl.formatMessage({ id: `understandPaths.modal.${lastPathDone}.table${index + 1}.header.left` })}
                                                            </SGText>
                                                        </SGGridCol>
                                                        <SGGridCol span={6} offset={1}>
                                                            <SGText weight="600">
                                                                {intl.formatMessage({ id: `understandPaths.modal.${lastPathDone}.table${index + 1}.header.right` })}
                                                            </SGText>
                                                        </SGGridCol>
                                                    </SGGridRow>
                                                </div>
                                                {table?.map((entry) => (
                                                    <React.Fragment key={entry}>
                                                        <SGGridRow align="middle" gutter={[5]}>
                                                            <SGGridCol span={6}>
                                                                <SGGridRow gutter={[5]}>
                                                                    <SGGridCol span={11}>
                                                                        {intl.formatMessage(
                                                                            { id: `understandPaths.modal.${lastPathDone}.table${index + 1}.${entry}.left` },
                                                                            {
                                                                                ul: (word: string) => <ul className="understand-simulation-modal__ul">{word}</ul>,
                                                                                li: (word: string) => <li>{word}</li>,
                                                                                linebreak: <br />,
                                                                            }
                                                                        )}
                                                                    </SGGridCol>
                                                                    <SGGridCol span={1} align="center">
                                                                        <div className="understand-simulation-modal__arrow-container">
                                                                            <SGIcon component={<Arrow />} />
                                                                        </div>
                                                                    </SGGridCol>
                                                                </SGGridRow>
                                                            </SGGridCol>

                                                            <SGGridCol span={6}>
                                                                {intl.formatMessage(
                                                                    { id: `understandPaths.modal.${lastPathDone}.table${index + 1}.${entry}.right` },
                                                                    {
                                                                        ul: (word: string) => <ul className="understand-simulation-modal__ul">{word}</ul>,
                                                                        li: (word: string) => <li>{word}</li>,
                                                                        linebreak: <br />,
                                                                    }
                                                                )}
                                                            </SGGridCol>
                                                        </SGGridRow>
                                                        <SGDivider className="understand-simulation-modal__divider" />
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </SGGridCol>
                                    <SGGridCol span={12} className="understand-simulation-modal__no-margin">
                                        <SGText className="understand-simulation-modal__text">
                                            {intl.formatMessage(
                                                { id: `understandPaths.modal.${lastPathDone}.paragraph2` },
                                                {
                                                    b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span>,
                                                    ul: (word: string) => <ul className="understand-simulation-modal__ul">{word}</ul>,
                                                    li: (word: string) => <li>{word}</li>,
                                                    linebreak: <br />,
                                                    p: (word: string) => <p>{word}</p>,
                                                }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGText className="understand-simulation-modal__text">
                                            {intl.formatMessage(
                                                { id: `understandPaths.modal.${lastPathDone}.paragraph3` },
                                                {
                                                    p: (word: string) => <p>{word}</p>,
                                                    hypothese: (word: string) => (
                                                        <span
                                                            style={{ textDecoration: "underline", cursor: "pointer", fontWeight: 400, color: "#0072b9" }}
                                                            onClick={() => setShowHypothesisModal(true)}
                                                        >
                                                            {word}
                                                        </span>
                                                    ),
                                                    link: (word: string) => (
                                                        <span
                                                            style={{ textDecoration: "underline", cursor: "pointer", fontWeight: 400, color: "#0072b9" }}
                                                            onClick={handleRedirectToArticleJargon}
                                                        >
                                                            {word}
                                                        </span>
                                                    ),
                                                }
                                            )}
                                        </SGText>
                                    </SGGridCol>
                                    {showHypothesisModal && <HypothesisModal setVisible={setShowHypothesisModal} visible={showHypothesisModal} closeSecondGroup />}
                                    <SGGridCol span={12}>
                                        <SGText style={{ fontSize: "12px", color: "#545454" }} className="understand-simulation-modal__text">
                                            {intl.formatMessage({ id: `understandPaths.modal.details` })}
                                        </SGText>
                                    </SGGridCol>
                                </SGGridRow>
                            </div>
                        </SGGridCol>
                    </SGGridRow>
                </SGGridCol>
            </SGGridRow>
        </SGModal>
    );
};
