import React, { FunctionComponent } from "react";
import "./FooterDefault.scss";
import { CookieBanner } from "../../../../../molecules/CookieBanner/CookieBanner";

const FooterDefault: FunctionComponent = () => (
  <div className="footer-default">
    <CookieBanner />
  </div>
);

export { FooterDefault };
