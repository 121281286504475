import React, { ComponentType } from "react";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface TuileServiceAccompagnementProps {
    titre: string;
    texte: string;
    lien: string;
    Icon: ComponentType;
    trackKey: string;
}

export const TuileServiceAccompagnement = ({ titre, texte, lien, Icon, trackKey }: TuileServiceAccompagnementProps) => {
    const history = useHistory();
    const { trackClick } = useTracking();

    const handleOnClick = () => {
        trackClick(trackKey);
        history.push(lien);
    };

    return (
        <>
            {/* @ts-ignore le temps que SGTile soit corrigé */}
            <SGTile clickable="full">
                <SGGridRow align="middle">
                    <SGGridCol flex="0 0 0%" textalign="center">
                        <SGIcon component={<Icon />} size="m" />
                    </SGGridCol>
                    <SGGridCol flex="1 0 0%">
                        <SGTitle level={3} visuallevel={5}>
                            {titre}
                        </SGTitle>
                        <SGBox>
                            <SGText size="s">{texte}</SGText>
                        </SGBox>
                    </SGGridCol>
                    <SGGridCol flex="0 0 0%">
                        <SGButton icon={<SGIcon component={<SGAvenirNavFleche />} />} type="icon" onClick={handleOnClick} />
                    </SGGridCol>
                </SGGridRow>
            </SGTile>
        </>
    );
};
