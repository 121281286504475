import {call, put, takeLeading} from "redux-saga/effects";
import {apiPost} from "../apiCaller";
import {onDefaultError, safe} from "../error/utils";
import {FETCH_SHARE_OF_SURVIVING_PARTNER, SurvivingPartnerSimulatorParameter, SurvivingPartnerSimulatorResult} from "./types";
import {PayloadAction} from "@reduxjs/toolkit";
import {survivngPartnerSimulatorResultFetched} from "./survivingPartnerSimulatorSlice";
import {AxiosResponse} from "axios";

export function* watcherSaga() {
   yield takeLeading(FETCH_SHARE_OF_SURVIVING_PARTNER, safe(onDefaultError, handleCallSurvivingPartnerSimulator));
}

function* handleCallSurvivingPartnerSimulator(action: PayloadAction<SurvivingPartnerSimulatorParameter>) {
   const payload: AxiosResponse<SurvivingPartnerSimulatorResult[]> = yield call(apiPost, `wealth/api/inheritance/spouse-share?spouse-status=${action.payload.familyStatus}`, {
      status: action.payload.heirFamilyStatus,
      bothParent: action.payload.bothParent,
      custodyType: action.payload.custodyType
   });
   yield put(survivngPartnerSimulatorResultFetched(payload.data));
}
