import React, {FunctionComponent, useEffect} from "react";
import {useDispatch} from "react-redux";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SpendingProfileEditor} from "../../Editors/SpendingProfileEditor/SpendingProfileEditor";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGSpace} from "sg-space";
import {nextStepAction, previousStepAction} from "../../../../../store/tunnel/actions";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const TunnelSpendingProfile: FunctionComponent = () => {
   const dispatch = useDispatch();
   const { trackClick, trackPage } = useTracking();

   useEffect(() => {
      trackPage("funnel", "capacite-epargne", "formulaire-ecran", "inscription", "4");
   }, []);

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="tunnel.expenses-profile.title" cypressName="tunnel-expenses-profile-title"/>
         <SGTextIntl intlId="tunnel.expenses-profile.subtitle"/>
         <SpendingProfileEditor onClick={() => dispatch(nextStepAction())}/>
         <SGButtonGroup align="center">
            <SGButtonIntl type="link" cypressName="tunnel-previous"
                          onClick={() => { dispatch(previousStepAction()); trackClick("capacite-epargne::clic-sur-retour"); }}
                          intlId="tunnel.buttons.previous"/>
         </SGButtonGroup>
      </SGSpace>
   );
};

export {TunnelSpendingProfile};
