import React, {FunctionComponent} from "react";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {NameAndValuationCollapsableFrames} from "../../../molecules/NameAndValuationCollapsableFrames/NameAndValuationCollapsableFrames";
import {useLoans} from "../../../hooks/useLoans";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {NetWorthDistribution} from "../../Advices/NetWorthDistribution/NetWorthDistribution";
import {useAssets} from "../../../hooks/useAssets";
import {Recommendations} from "../../Advices/Recommendations/Recommendations";
import {RecommendationCategory} from "../../../../../store/recommendations/types";
import {SGSpace} from "sg-space";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

const ProfileNetWorth: FunctionComponent = () => {
   const loans = useLoans([]);
   const { assets } = useAssets();

   const hasFetchedAssetsAndLoans = useSelector<State, boolean>((state) => state.assets.hasFetched && state.loans.hasFetched);

   return (
      <SpinnerWrapper displayComponent={hasFetchedAssetsAndLoans} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace direction="vertical">
            <SGGridRow>
               <SGGridCol span={12}>
                  <SGTitleIntl intlId="profile-net-worth-title" cypressName="profile-net-worth-graph-title"/>
                  <NetWorthDistribution/>
               </SGGridCol>
            </SGGridRow>
            <SGGridRow>
               <SGGridCol xs={12} xl={6}>
                  <NameAndValuationCollapsableFrames cypressName="profile-assets" title="profile.assets.title" assets={assets}/>
               </SGGridCol>
               <SGGridCol xs={12} xl={6}>
                  <NameAndValuationCollapsableFrames cypressName="profile-loans" title="profile.loans.title" loans={loans}/>
               </SGGridCol>
            </SGGridRow>
            <SGGridRow>
               <SGGridCol span={12}>
                  <SGTitleIntl intlId="advices.coach.suggestions" cypressName="recommendations-title"/>
                  <Recommendations categoryFilters={[RecommendationCategory.ASSETS, RecommendationCategory.LOAN, RecommendationCategory.SAVING]}/>
               </SGGridCol>
            </SGGridRow>
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {ProfileNetWorth};
