import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { ImportState } from "store/import/types";
import { updateFamilyAction } from "store/members/actions";
import {
    CustodyType,
    Family,
    FamilyStatus,
    MaritalStatus,
    MatrimonialRegime,
    Me,
    Member,
    RetirementCategory,
    RetirementCategorySimulation,
} from "store/members/types";
import { isCoupleMaritalStatus, isIsolatedParentStatus } from "store/members/utils";
import { savePersonalInformations } from "store/personalInformations/action";
import { PersonalInformationsState } from "store/personalInformations/types";
import { setShowStep2, updateParameters } from "store/simulateurAvantApres/slice";
import { CategorieActiveChoix, ParametresSimulateurAvantApres } from "store/simulateurAvantApres/types";
import { getDate, toStringDate } from "store/simulateurAvantApres/utils";
import { State } from "store/store";
import { saveWorkIncomesHistoryAction } from "store/workIncomesHistory/action";
import { WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { DetailTab } from "website/components/atoms/DetailTab/DetailTab";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { secteurProToProfession } from "website/components/hooks/useWorkIncomes";
import { ChildrenCounterAssu } from "website/components/molecules/ChildrenCounterAssu/ChildrenCounterAssu";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const MaSituationStep: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const family = useFamily();
    const { showDetailFonctionnaire, setShowDetailFonctionnaire } = useContext(DetailTabContext);
    const { trackClick } = useTracking();

    useAccount();

    const importData: ImportState = useSelector<State, ImportState>((state) => state.importData);
    const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const personalInformationsState: PersonalInformationsState = useSelector<State, PersonalInformationsState>((state) => state.personalInformations);
    const parametres = useSelector<State, ParametresSimulateurAvantApres>((state) => state.simulateurAvantApres.parametres);

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const isVisible = useMediaQuery({ maxwidth: "xl"});

    const [numberChild, setNumberChild] = useState(0);
    const [retirementType, setRetirementType] = useState<RetirementCategory>();
    const [carrierStartYear, setCarrierStartYear] = useState<number>();
    const [carrierStartMonth, setCarrierStartMonth] = useState<number>();
    const [categorieActive, setCategorieActive] = useState<CategorieActiveChoix>(CategorieActiveChoix.NON);
    const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>(MaritalStatus.SINGLE);

    const retirementTypeValues: RetirementCategory[] = Object.values(RetirementCategory);
    const optionCategorieActiveChoix: CategorieActiveChoix[] = Object.values(CategorieActiveChoix);
    const [options, setOptions] = useState([]);
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const { handleSubmit, formState } = methods;

    useEffect(() => {
        if (parametres.nbEnfants) {
            setNumberChild(parametres.nbEnfants);
        }
        if (parametres.dateDebutCarriere) {
            setCarrierStartYear(getDate(parametres.dateDebutCarriere).getFullYear());
            setCarrierStartMonth(getDate(parametres.dateDebutCarriere).getMonth() + 1);
        }
        if (parametres.statut) {
            const statut = secteurProToProfession(parametres.statut);
            setRetirementType(statut === "" ? family.me.retirementType : statut);
        }
        setCategorieActive(parametres.categorieActive ? CategorieActiveChoix.OUI : CategorieActiveChoix.NON);
    }, [parametres, family?.me?.retirementType]);

    // Permet de revalider la valeur de l'input suite à l'utilisation d'un Controller
    useEffect(() => {
        methods.setValue("childrenCounter", numberChild, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [numberChild]);

    useEffect(() => {
        methods.setValue("retirementCategories", retirementType, {
            shouldValidate: formState.submitCount > 0,
        });
        setShowDetailFonctionnaire(retirementType === RetirementCategory.FONCTIONNAIRE);
    }, [retirementType]);

    useEffect(() => {
        methods.setValue("carrierStartYear", carrierStartYear, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [carrierStartYear]);

    useEffect(() => {
        methods.setValue("carrierStartMonth", carrierStartMonth, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [carrierStartMonth]);

    useEffect(() => {
        methods.setValue("categorieActive", categorieActive, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [categorieActive, retirementType]);

    useEffect(() => {
        if (family?.me?.birthday) {
            const birthday = new Date(family.me.birthday);
            setMaritalStatus(family.me.maritalStatus);

            const minYear = birthday.getFullYear() + 16;
            const thisYear = new Date().getFullYear();
            const maxOffset = thisYear - minYear;

            const optionsSelect: any = [];
            for (let i = 0; i <= maxOffset; i += 1) {
                const year = thisYear - i;
                optionsSelect.push(
                    <SGSelectOption key={i} value={year.toString()}>
                        {year}
                    </SGSelectOption>
                );
            }
            setOptions(optionsSelect.reverse());
        }
    }, [family, importData]);

    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    const onSubmit = () => {
        trackClick("ma-situation::clic-sur-suivant");
        // Persistance du nombre d'enfants et du statut
        const newMe: Me = {
            birthday: family.me.birthday,
            name: "Moi même",
            status: FamilyStatus.ME,
            retirementType,
            maritalStatus,
        } as Me;

        const familyToDispatch: Family = {
            children: [],
            me: { ...newMe, matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS },
            relatives: [],
        };
        let ordinal = 0;
        for (let i = 0; i < numberChild; i += 1) {
            ordinal += 1;
            const custodyType =
                isIsolatedParentStatus(maritalStatus) || maritalStatus === MaritalStatus.WIDOWER ? CustodyType.FULL_CUSTODY_ME : CustodyType.FULL_CUSTODY;
            const child: Member = {
                name: `Mon enfant ${ordinal}`,
                status: FamilyStatus.CHILD,
                custodyType,
            } as Member;
            familyToDispatch.children.push(child);
        }
        if (isCoupleMaritalStatus(maritalStatus)) {
            const partner: any = { ...family.partner, matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS };
            familyToDispatch.partner = partner;
        }
        dispatch(updateFamilyAction(familyToDispatch, true, true));

        // Persistance de la date de debut de carriere
        // FIXME Décalage de 2h retirant 1 jour dans le toString => ajout de 12h en paliatif temporaire
        if (carrierStartYear && carrierStartMonth && retirementType) {
            const workIncomesHistoryToSave: WorkIncomesHistory = {
                ...workIncomesHistoryState.workIncomesHistory,
                carrierStartDate: new Date(carrierStartYear, carrierStartMonth - 1, 1, 12),
            };
            dispatch(saveWorkIncomesHistoryAction(workIncomesHistoryToSave));
        }

        // Persistance de la catégorie active
        const isCategorieActive = categorieActive === CategorieActiveChoix.OUI;
        if (personalInformationsState.personalInformations && personalInformationsState.personalInformations.categorieActive !== isCategorieActive) {
            dispatch(
                savePersonalInformations({
                    ...personalInformationsState.personalInformations,
                    categorieActive: isCategorieActive,
                })
            );
        }

        dispatch(setShowStep2(true));

        // Mise à jour des parametres du simulateur avant/apres
        if (retirementType && carrierStartYear && carrierStartMonth && categorieActive) {
            dispatch(
                updateParameters({
                    ...parametres,
                    nbEnfants: numberChild,
                    dateDebutCarriere: toStringDate(new Date(carrierStartYear, carrierStartMonth - 1), "-", true),
                    statut: RetirementCategorySimulation[retirementType],
                    categorieActive: isCategorieActive,
                })
            );
        }
    };

    return (
        <SGBox span={9}>
            <SGCard disableautomargin shadow={false}>
                <SGGridRow>
                    <SGGridCol span={12} md={7}>
                        <SGBox margin={{ top: "lg", bottom: "lg" }}>
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <SGGridRow gutter={[0, {xs: 8, sm: 16}]}>
                                        <SGGridCol textalign="left" span={12}>
                                            <SGSpace direction="vertical" disableautomargin size="sm">
                                                <SGTitle key="title" level={1} visuallevel={2}>
                                                    {intl.formatMessage({ id: "tunnel.situation.title" })}
                                                </SGTitle>
                                                <SGText key="text" size={isPhone ? "l" : undefined}>
                                                    {intl.formatMessage(
                                                        {
                                                            id: "tunnel.situation.dateOfBirth",
                                                        },
                                                        {
                                                            dateOfBirth: family?.me?.birthday ? toStringDate(new Date(family.me.birthday), "/") : "01/01/1970",
                                                            b: (word: string) => <strong style={{ fontFamily: "Source Sans Pro" }}>{word}</strong>,
                                                        }
                                                    )}
                                                </SGText>
                                            </SGSpace>
                                        </SGGridCol>
                                        <SGGridCol textalign="left" span={12}>
                                            <SGGridRow gutter={[0, 10]}>
                                                <SGGridCol span={12}>
                                                    <ChildrenCounterAssu
                                                        counter={numberChild}
                                                        setCounter={setNumberChild}
                                                        name="childrenCounter"
                                                    />
                                                </SGGridCol>
                                            </SGGridRow>
                                            <SGGridRow gutter={[0, 20]}>
                                                <SGGridCol span={12}>
                                                    <Controller
                                                        control={methods.control}
                                                        name="retirementCategories"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ onChange }) => (
                                                            <SGSelect
                                                                placeholder="Sélectionnez"
                                                                value={retirementType || ""}
                                                                size={!isVisible ? "xl" : "l"}
                                                                onChange={(value: RetirementCategory) => {
                                                                    onChange(value);
                                                                    setRetirementType(value);
                                                                    if (value !== RetirementCategory.FONCTIONNAIRE) {
                                                                        setCategorieActive(CategorieActiveChoix.NON);
                                                                    }
                                                                }}
                                                                label={
                                                                    <SGText>
                                                                        <SGTextIntl intlId="tunnel.situation.situationQuestion" size="l" />
                                                                    </SGText>
                                                                }
                                                                status={methods?.errors?.retirementCategories && "error"}
                                                                validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                            >
                                                                <SGSelectOption value="" style={{ display: "none" }}>
                                                                    Sélectionnez
                                                                </SGSelectOption>
                                                                {retirementTypeValues.map((retirementCategory: RetirementCategory) => (
                                                                    <SGSelectOption key={retirementCategory} value={retirementCategory}>
                                                                        {intl.formatMessage({
                                                                            id: `retirementType.${retirementCategory}`,
                                                                        })}
                                                                    </SGSelectOption>
                                                                ))}
                                                            </SGSelect>
                                                        )}
                                                    />
                                                </SGGridCol>
                                            </SGGridRow>
                                            {retirementType === RetirementCategory.FONCTIONNAIRE && (
                                                <>
                                                    <SGGridRow gutter={[0, 16]}>
                                                        <SGGridCol span={12}>
                                                            <Controller
                                                                control={methods.control}
                                                                name="categorieActive"
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                                render={({ onChange }) => (
                                                                    <SGSelect
                                                                        placeholder="Sélectionnez"
                                                                        value={categorieActive}
                                                                        size={!isVisible ? "xl" : "l"}
                                                                        onChange={(value: CategorieActiveChoix) => {
                                                                            onChange(value);
                                                                            setCategorieActive(value);
                                                                        }}
                                                                        label={
                                                                            <SGText>
                                                                                <SGTextIntl
                                                                                    intlId="simulateurAvantApres.situation.question.categorieActive"
                                                                                    size="l"
                                                                                />
                                                                                <InfoTooltipAssu
                                                                                    text="simulateurAvantApres.situation.question.categorieActive.tooltip"
                                                                                    placement={isPhone ? "bottomLeft" : "right"}
                                                                                    transformations={{
                                                                                        b: (word: string) => <SGText weight="bold">{word}</SGText>,
                                                                                        linebreak: <br />,
                                                                                    }}
                                                                                />
                                                                            </SGText>
                                                                        }
                                                                        status={methods?.errors?.categorieActive && "error"}
                                                                        validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                                    >
                                                                        {optionCategorieActiveChoix.map((value: CategorieActiveChoix) => (
                                                                            <SGSelectOption key={value} value={value}>
                                                                                {value}
                                                                            </SGSelectOption>
                                                                        ))}
                                                                    </SGSelect>
                                                                )}
                                                            />
                                                        </SGGridCol>
                                                    </SGGridRow>
                                                </>
                                            )}
                                            <SGGridRow>
                                                <SGGridCol span={12}>
                                                    <SGBox margin={{ top: "lg", bottom: "sm" }}>
                                                        <SGText size="l">
                                                            {intl.formatMessage({ id: "simulateurAvantApres.situation.question.carrierStart" })}
                                                        </SGText>
                                                    </SGBox>
                                                </SGGridCol>
                                                <SGGridCol span={6} sm={5}>
                                                    <Controller
                                                        control={methods.control}
                                                        name="carrierStartMonth"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ onChange }) => (
                                                            <SGSelect
                                                                placeholder="Sélectionnez"
                                                                label={
                                                                    <SGText>
                                                                        <FormattedMessage id="tunnel.workIncomesHistory.mois" />
                                                                    </SGText>
                                                                }
                                                                value={carrierStartMonth?.toString() || ""}
                                                                size={isPhone ? "xl" : "s"}
                                                                onChange={(value: number) => {
                                                                    onChange(value);
                                                                    setCarrierStartMonth(value);
                                                                }}
                                                                status={methods?.errors?.carrierStartMonth && "error"}
                                                                validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                                disableautomargin
                                                            >
                                                                <SGSelectOption value="" style={{ display: "none" }}>
                                                                    Sélectionnez
                                                                </SGSelectOption>
                                                                {months.map((month: string, index: number) => (
                                                                    <SGSelectOption key={month} value={(index + 1).toString()}>
                                                                        {month}
                                                                    </SGSelectOption>
                                                                ))}
                                                            </SGSelect>
                                                        )}
                                                    />
                                                </SGGridCol>
                                                <SGGridCol span={6} sm={5}>
                                                    <Controller
                                                        control={methods.control}
                                                        name="carrierStartYear"
                                                        rules={{
                                                            required: true,
                                                        }}
                                                        render={({ onChange }) => (
                                                            <SGSelect
                                                                defaultValue="Sélectionnez"
                                                                label={
                                                                    <SGText>
                                                                        <FormattedMessage id="tunnel.workIncomesHistory.année" />
                                                                    </SGText>
                                                                }
                                                                value={carrierStartYear?.toString() || ""}
                                                                size={isPhone ? "xl" : "s"}
                                                                disableautomargin
                                                                onChange={(value: number) => {
                                                                    onChange(value);
                                                                    setCarrierStartYear(value);
                                                                }}
                                                                status={methods?.errors?.carrierStartYear && "error"}
                                                                validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                            >
                                                                <SGSelectOption value="" style={{ display: "none" }}>
                                                                    Sélectionnez
                                                                </SGSelectOption>
                                                                {options}
                                                            </SGSelect>
                                                        )}
                                                    />
                                                </SGGridCol>
                                            </SGGridRow>
                                        </SGGridCol>
                                        <SGGridCol span={12}>
                                            <SGBox margin={{top: "md"}}>
                                            <SGButtonGroup align="center" disableautomargin>
                                                <SGButton size="sm" onClick={handleSubmit(onSubmit)}>
                                                    {intl.formatMessage({ id: "common.next" })}
                                                </SGButton>
                                            </SGButtonGroup>
                                            </SGBox>
                                        </SGGridCol>
                                    </SGGridRow>
                                </form>
                            </FormProvider>
                        </SGBox>
                    </SGGridCol>
                    <SGGridCol span={12} md={5} align="stretch">
                        <DetailTab
                            titleDetailTab="tunnel.detail.title"
                            textDetailTab="tunnel.detail.text.step1"
                            hideListStyle={isPhone}
                            transformations={{ fonctionnaire: (word: string) => (showDetailFonctionnaire ? word : "")}}
                        />
                    </SGGridCol>
                </SGGridRow>
            </SGCard>
        </SGBox>
    );
};
