import React, {FunctionComponent} from "react";
import {SGAvenirStrokedMasquerCompte, SGAvenirStrokedOeil} from "sg-icon-pack-base";
import {SGIcon} from "sg-icon";
import {SGButton} from "sg-button";

interface ShowPasswordProps {
   showPassword: boolean;
   setShowPassword: (showPassword: boolean) => void;
}

const ShowPassword: FunctionComponent<ShowPasswordProps> = (props: ShowPasswordProps) => {
   const {showPassword, setShowPassword} = props;

   return (
      <SGButton type="icon" icon={<SGIcon component={showPassword ? <SGAvenirStrokedOeil/> : <SGAvenirStrokedMasquerCompte/>}/>}
                onClick={() => setShowPassword(!showPassword)} tabIndex="-1" />
   );
};
export { ShowPassword };
