import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {NewProjectState} from "../../../store/newProjects/types";
import {getNewProjects} from "../../../store/newProjects/actions";

const useNewProject = (): NewProjectState => {
   const dispatch = useDispatch();
   const newProjectState: NewProjectState = useSelector<State, NewProjectState>((state) => state.newProjects);

   useEffect(() => {
      if (!newProjectState.hasFetched) {
         dispatch(getNewProjects());
      }
   }, []);

   return newProjectState;
};

export {useNewProject};
