import {ProjectType} from "../projects/types";
import {Member} from "../members/types";
import {CADET_BLUE, CHESTNUT, ElxColor, OLD_MAUVE, ORANGE_YELLOW, SG_ORANGE, SG_PINK, SG_PURPLE, SILVER, WHEAT} from "../../website/utils/graphs/colors";

export interface ExpenseState {
   expenses: Expense[];
   hasFetchedExpenses: boolean;
   spendingProfile: SpendingProfile;
   hasFetchedSpendingProfile: boolean;
}

export interface Expense {
   id: number;
   name: string;
   monthlyAmount: number;
   type?: ProjectType;
   member: Member | undefined;
}

export enum SpendingProfile {
   MEDIUM_SAVING = "MEDIUM_SAVING",
   HIGH_SAVING = "HIGH_SAVING",
   NO_SAVING = "NO_SAVING",
}

export type ExpenseCategoryKey =
   | "EXPENSES"
   | "TAX_EXPENSE"
   | "LOAN"
   | "RENT"
   | "CHILD_SUPPORT_EXPENSE"
   | "EXPENSE_PLANNED_SAVINGS"
   | "ASSET_EXPENSE"
   | "AVAILABLE_INCOME"
   | "EMPLOYEE_AT_HOME"
   | "DONATIONS"
   | "CHILDCARE";

export interface ExpenseGraphCategory {
   intlKey: ExpenseCategoryKey;
   color: ElxColor;
}

export const EMPLOYEE_AT_HOME_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "EMPLOYEE_AT_HOME",
   color: WHEAT
};
export const DONATIONS_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "DONATIONS",
   color: WHEAT
};
export const CHILDCARE_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "CHILDCARE",
   color: WHEAT
};
export const EXPENSES_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "EXPENSES",
   color: OLD_MAUVE
};
export const TAX_EXPENSE_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "TAX_EXPENSE",
   color: CADET_BLUE
};
export const LOAN_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "LOAN",
   color: SG_PURPLE
};
export const RENT_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "RENT",
   color: SILVER
};
export const CHILD_SUPPORT_EXPENSE_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "CHILD_SUPPORT_EXPENSE",
   color: CHESTNUT
};
export const EXPENSE_PLANNED_SAVINGS_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "EXPENSE_PLANNED_SAVINGS",
   color: SG_ORANGE
};
export const EXPENSE_ASSET_GRAPH_CATEGORY: ExpenseGraphCategory = {
   intlKey: "ASSET_EXPENSE",
   color: SG_PINK
};
export const AVAILABLE_INCOME_CATEGORY: ExpenseGraphCategory = {
   intlKey: "AVAILABLE_INCOME",
   color: ORANGE_YELLOW
};

// actions
export const FETCH_EXPENSES = "FETCH_EXPENSES";
export const EXPENSES_FETCHED = "EXPENSES_FETCHED";
export const UPDATE_SPENDING_PROFILE = "UPDATE_SPENDING_PROFILE";
export const SPENDING_PROFILE_UPDATED = "SPENDING_PROFILE_UPDATED";
export const FETCH_SPENDING_PROFILE = "FETCH_SPENDING_PROFILE";
export const SPENDING_PROFILE_FETCHED = "SPENDING_PROFILE_FETCHED";
export const UPDATE_EXPENSE_IN_STORE = "UPDATE_EXPENSE_IN_STORE";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const EXPENSE_DELETED = "EXPENSE_DELETED";
