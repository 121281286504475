/**
 * Les options du graphe lié au média
 */
export const getOptionsMedia = (
  opt: any,
  height: number,
  spacing: number,
  titleY: number,
  titleFontSize: string,
  subtitleY: number,
  subtitleFontSize: string,
  innerSize: string
) => ({
  ...opt,
  chart: {
    ...opt.chart,
    height,
    spacingTop: spacing,
    spacingBottom: spacing,
  },
  title: {
    ...opt.title,
    y: titleY,
    style: {
      ...opt.title.style,
      fontSize: titleFontSize,
    },
  },
  subtitle: {
    ...opt.subtitle,
    y: subtitleY,
    style: {
      ...opt.subtitle.style,
      fontSize: subtitleFontSize,
    },
  },
  series: [
    {
      ...opt.series[0],
      innerSize,
    },
  ],
});
