import React, {FunctionComponent, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {CustodyType, FamilyStatus, Member} from "../../../../store/members/types";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {DateInput, DatePattern} from "../../atoms/DateInput/DateInput";
import {addMemberInStore} from "../../../../store/members/membersSlice";
import {getAmountOfMembersWithSameStatus, getAvailableCustodyTypes} from "../../../../store/members/utils";
import {utcDate} from "../../../utils/date/DateUtils";
import {SGModal} from "sg-modal";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGButtonGroup} from "sg-button";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledPrendreContact} from "sg-icon-pack-base";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {useFamily} from "../../hooks/useFamily";

interface AddMemberWithModalProps {
   displayFamilyType: boolean;
   hideModal: () => void;
   cypressName: string;
   onAddNewMemberSubmit: (member: Member) => void;
}

const AddMemberModal: FunctionComponent<AddMemberWithModalProps> = (props: AddMemberWithModalProps) => {
   const {displayFamilyType, cypressName, hideModal, onAddNewMemberSubmit} = props;

   const family = useFamily();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });
   const {handleSubmit} = methods;
   const intl = useIntl();
   const dispatch = useDispatch();
   // On n'autorise pas l'utilisateur à créer un ME ou PARTNER sur ces dialogs
   const authorizedFamilyStatuses: FamilyStatus[] = Object.values(FamilyStatus).filter((fs) => fs !== FamilyStatus.ME && fs !== FamilyStatus.PARTNER);

   const [member, setMember] = useState<Partial<Member>>({});
   const [displayCustodyType, setDisplayCustodyType] = useState<boolean>(false);

   const onSelectFamilyStatus = (newFamilyStatus: string) => {
      const amountOfMembersWithSameStatus = getAmountOfMembersWithSameStatus(family, newFamilyStatus);
      const defaultName =
         amountOfMembersWithSameStatus === 0
            ? intl.formatMessage({id: `family.status.${newFamilyStatus}`})
            : `${intl.formatMessage({
               id: `family.status.${newFamilyStatus}`,
            })} ${amountOfMembersWithSameStatus + 1}`;

      methods.setValue("name", defaultName, {shouldValidate: true});

      setDisplayCustodyType(newFamilyStatus === FamilyStatus.CHILD);
   };

   const onSelectBirthday = (date: Date) => {
      setMember({...member, birthday: utcDate(date)});
   };

   const onSubmit = (data: Member) => {
      dispatch(addMemberInStore(data));
      hideModal();
      onAddNewMemberSubmit(data);
   };

   const displayNewMemberForm = () => (
      <FormInputs colLength={10}>
         {displayFamilyType && (
            <SelectInput label="inheritance.realized-donation.page.member" name="status" onValueChange={onSelectFamilyStatus} required
                         cypressName={`${cypressName}-edit-type`}>
               {authorizedFamilyStatuses.map(
                  (familyStatus: FamilyStatus) => (
                     <SGSelectOption key={familyStatus} data-cy={`${cypressName}-edit-status-${familyStatus}`} value={familyStatus}>
                        {intl.formatMessage({
                           id: `family.status.${familyStatus}`,
                        })}
                     </SGSelectOption>
                  )
               )}
            </SelectInput>
         )}
         <StringInput defaultValue="" placeholder="member.form.placeholder.name" name="name" minLength={1} maxLength={140}
                      cypressName={`${cypressName}-edit-name`} label="member.form.name"/>
         <DateInput cypressName={`${cypressName}-edit-birthday-container`} onDateChange={(value: Date) => {
            onSelectBirthday(value);
         }} pattern={DatePattern.DAYS} label="member.form.birthdate" name="birthday" placeholder="member.form.placeholder.birthdate" min="1935-01-01"
                    max={new Date()}/>
         {displayCustodyType && (
            <SelectInput label="member.form.custody.type" name="custodyType" required defaultValue={member.custodyType}
                         cypressName={`${cypressName}-edit-custody-type`}>
               {getAvailableCustodyTypes(family).map(
                  (custodyType: CustodyType) => (
                     <SGSelectOption key={custodyType} data-cy={`${cypressName}-edit-custody-type-${custodyType}`} value={custodyType}>
                        {intl.formatMessage({id: `custody.type.${custodyType}`})}
                     </SGSelectOption>
                  )
               )}
            </SelectInput>
         )}
      </FormInputs>
   );

   return (
      <SGModal visible onCancel={hideModal}>
         <SGMessage
            extra={<SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName={`${cypressName}-edit-submit`}
                             onClick={handleSubmit(onSubmit)} intlId="common.add"/>
               <SGButtonIntl type="secondary" size="sm" cypressName={`${cypressName}-edit-cancel`} onClick={hideModal} intlId="common.cancel"/>
            </SGButtonGroup>}
            icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
            title={<SGTitleIntl intlId="common.add-member" cypressName="add-member-modal-title"/>}>
            <FormProvider {...methods}>
               <form id="modal-add-new-member-form">
                  {displayNewMemberForm()}
               </form>
            </FormProvider>
         </SGMessage>
      </SGModal>
   );
};

export {AddMemberModal};
