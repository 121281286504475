import React, {FunctionComponent, useEffect} from "react";
import {ExpensesEditor} from "../components/organisms/Editors/ExpensesEditor/ExpensesEditor";
import {SpinnerSize, SpinnerWrapper} from "../components/atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SITUATION_INCOMES} from "../utils/privateRoutes";
import {SpendingProfileEditor} from "../components/organisms/Editors/SpendingProfileEditor/SpendingProfileEditor";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {getExpensesAction} from "../../store/expenses/actions";
import {useDispatch, useSelector} from "react-redux";
import {SGContent} from "sg-layout";
import {SGAlertIntl} from "../components/molecules/SGAlertIntl/SGAlertIntl";
import {SGSpace} from "sg-space";
import {State} from "../../store/store";

const ExpensesPage: FunctionComponent = () => {
   const dispatch = useDispatch();
   const displayComponent = useSelector<State, boolean>((state) => state.loans.hasFetched && state.incomes.hasFetched && state.expenses.hasFetchedExpenses);

   // On force le rechargement des dépenses dans le cas où les dépenses ou le profil de dépense auraient changés.
   useEffect(() => {
      dispatch(getExpensesAction());
   }, []);

   const goNext = () => {
      redirectToRelativePath(SITUATION_INCOMES);
   };

   return (
      <SGContent span={8}>
         <SGTitleIntl intlId="expenses.title" cypressName="expense-title"/>
         <SGAlertIntl type="info" cypressName="notification-frame-expenses" title="common.did.you.know">
            <SGSpace direction="vertical">
               <SGTextIntl intlId="expenses.page.info1"/>
               <SGTextIntl intlId="expenses.page.info2"/>
            </SGSpace>
         </SGAlertIntl>
         <SpinnerWrapper displayComponent={displayComponent} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
            <SGSpace direction="vertical">
               <SGTextIntl intlId="expenses.info"/>
               <SpendingProfileEditor/>
               <ExpensesEditor onValidate={goNext}/>
            </SGSpace>
         </SpinnerWrapper>
      </SGContent>
   );
};

export {ExpensesPage};
