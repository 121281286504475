import React, {FunctionComponent, useContext} from "react";
import {ModalContext} from "../../../providers/ModalProvider";
import {FeedbackModal} from "../../../molecules/FeedbackModal/FeedbackModal";

const ModalAggregator: FunctionComponent = () => {
   const {isDisplayingFeedbackModal, setDisplayingFeedbackModal} = useContext(ModalContext);

   return (<>
         {isDisplayingFeedbackModal && <FeedbackModal hideModal={() => setDisplayingFeedbackModal(false)}/>}
      </>

   );
}

export {ModalAggregator};
