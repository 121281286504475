import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FutureExpenses, IncomeLine, Project, ProjectFeasibility, ProjectState, RetirementProjectValues, WorkIncomeEvolutionLevel} from "./types";
import {removeElement, storeDeepEquals, updateElement} from "../utils";
import {RetirementCategory} from "../members/types";

const initialState: ProjectState = {
   projects: new Array<Project>(),
   hasFetchedProjects: false,
   hasFetchedFeasibilitiesError: false,
   hasFetchedFeasibilities: false,
   projectFeasibilities: [],
   additionalIncomeObjective: 0,
   changeLifeFutureExpenses: {
      common: 0,
      projects: 0,
      other: 0,
      total: 0
   },
   changeLifeMyFutureIncome: 0,
   changeLifePartnerFutureIncome: 0,
   givenDonationFees: undefined,
   retirementProject: {
      myRetirement: {
         memberId: 0,
         memberName: "",
         birthdate: new Date(),
         currentMonthlyIncome: 0,
         type: RetirementCategory.SALARIE_PRIVE_CADRE, // TODO : Verif saisie
         salaryEvolutionLevel: WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
         retirementYearToRetirementIncomeMap: {},
         retirementYearToIncomeBeforeRetirementMap: {},
         retirementIncomes: new Array<IncomeLine>(),
         memberRetirementDate: new Date()
      },
      partnerRetirement: {
         memberId: 0,
         memberName: "",
         birthdate: new Date(),
         currentMonthlyIncome: 0,
         type: RetirementCategory.SALARIE_PRIVE_CADRE, // TODO : Verif saisie
         salaryEvolutionLevel: WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
         retirementYearToRetirementIncomeMap: {},
         retirementYearToIncomeBeforeRetirementMap: {},
         retirementIncomes: new Array<IncomeLine>(),
         memberRetirementDate: new Date()
      },
      expenses: {
         common: 0,
         projects: 0,
         other: 0,
         total: 0
      },
      objective: 0,
      projectId: 0
   },
   hasFetchedMyRetirementSimulation: false,
   hasFetchedPartnerRetirementSimulation: false,
   isRetirementProjectAutomaticallyUpdated: false
};

export const projectsSlice = createSlice({
   name: "projects",
   initialState,
   reducers: {
      projectCreated: (currentState, action: PayloadAction<Project>) => ({
         ...currentState,
         projects: [...currentState.projects, action.payload]
      }),
      projectDeleted: (currentState, action: PayloadAction<Project>) => ({
         ...currentState,
         projects: removeElement(currentState.projects, String(action.payload.id))
      }),
      projectUpdated: (currentState, action: PayloadAction<Project>) => ({
         ...currentState,
         projects: updateElement(currentState.projects, action.payload)
      }),
      retirementProjectFetched: (currentState, action: PayloadAction<RetirementProjectValues>) => ({
         ...currentState,
         retirementProject: action.payload,
         hasFetchedMyRetirementSimulation: action.payload.myRetirement !== undefined,
         hasFetchedPartnerRetirementSimulation: action.payload.partnerRetirement !== undefined
      }),
      retirementProjectUpdated: (currentState, action: PayloadAction<RetirementProjectValues>) => ({
         ...currentState,
         retirementProject: action.payload,
         hasFetchedMyRetirementSimulation: action.payload.myRetirement !== undefined,
         hasFetchedPartnerRetirementSimulation: action.payload.partnerRetirement !== undefined
      }),
      retirementProjectReset: (currentState) => ({
         ...currentState,
         retirementProject: initialState.retirementProject,
         hasFetchedMyRetirementSimulation: false,
         hasFetchedPartnerRetirementSimulation: false
      }),
      feasibilityReset: (currentState) => ({
         ...currentState,
         projectFeasibilities: initialState.projectFeasibilities,
         hasFetchedFeasibilities: false,
         hasFetchedFeasibilitiesError: false
      }),
      allProjectsDeleted: (currentState) => ({
         ...currentState, projects: [], numberProjects: 0
      }),
      projectsFetched: (currentState, action: PayloadAction<Project[]>) => ({
         ...currentState,
         projects: storeDeepEquals(currentState.projects, action.payload) ? currentState.projects : action.payload,
         hasFetchedProjects: true
      }),
      projectFeasibilitiesFetched: (currentState, action: PayloadAction<ProjectFeasibility[]>) => ({
         ...currentState,
         projectFeasibilities: storeDeepEquals(currentState.projectFeasibilities, action.payload) ? currentState.projectFeasibilities : action.payload,
         hasFetchedFeasibilities: true
      }),
      feasibilityError: (currentState) => ({
         ...currentState, hasFetchedFeasibilitiesError: true
      }),
      resetAdditionalIncomeObjective: (currentState) => ({
         ...currentState, additionalIncomeObjective: undefined
      }),
      additionalIncomeObjectiveFetched: (currentState, action: PayloadAction<number>) => ({
         ...currentState, additionalIncomeObjective: action.payload
      }),
      resetGivenDonationsFees: (currentState) => ({
         ...currentState, givenDonationFees: undefined
      }),
      givenDonationsFeesFetched: (currentState, action: PayloadAction<number>) => ({
         ...currentState, givenDonationFees: action.payload
      }),
      resetChangeLifeFutureExpenses: (currentState) => ({
         ...currentState, changeLifeFutureExpenses: undefined
      }),
      changeLifeFutureExpensesFetched: (currentState, action: PayloadAction<FutureExpenses>) => ({
         ...currentState, changeLifeFutureExpenses: action.payload
      }),
      changeLifeMyFutureIncomesFetched: (currentState, action: PayloadAction<number>) => ({
         ...currentState, changeLifeMyFutureIncome: action.payload
      }),
      changeLifePartnerFutureIncomesFetched: (currentState, action: PayloadAction<number>) => ({
         ...currentState, changeLifePartnerFutureIncome: action.payload
      }),
      updateIsRetirementProjectAutomaticallyUpdated: (currentState, action: PayloadAction<boolean>) => ({
         ...currentState, isRetirementProjectAutomaticallyUpdated: action.payload
      })
   }
});

export const {
   projectCreated,
   projectDeleted,
   projectUpdated,
   retirementProjectFetched,
   retirementProjectUpdated,
   retirementProjectReset,
   feasibilityReset,
   allProjectsDeleted,
   projectsFetched,
   projectFeasibilitiesFetched,
   feasibilityError,
   resetAdditionalIncomeObjective,
   additionalIncomeObjectiveFetched,
   resetGivenDonationsFees,
   givenDonationsFeesFetched,
   resetChangeLifeFutureExpenses,
   changeLifeFutureExpensesFetched,
   changeLifeMyFutureIncomesFetched,
   changeLifePartnerFutureIncomesFetched,
   updateIsRetirementProjectAutomaticallyUpdated
} = projectsSlice.actions;

export default projectsSlice.reducer;
