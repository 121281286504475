import { browserName, browserVersion, deviceType, mobileModel, osName, osVersion } from "react-device-detect";
import { useDispatch } from "react-redux";
import { isWebView } from "store/import/utils";
import { saveTrackingEvent } from "store/trackingEvent/action";
import { TrackingEvent } from "store/trackingEvent/types";
import { usePrivateData } from "./usePrivateData";

export enum trackingEvent {
   CONNEXION_MCR = "connexion-mcr",
   ONBOARDING_MA_RETRAITE = "parcours-onboarding::ma-retraite",
   ONBOARDING_SAPIENDO_OK = "parcours-onboarding::api-sapiendo-succes",
   ONBOARDING_SAPIENDO_KO = "parcours-onboarding::api-sapiendo-echec",
   FLASH_SAPIENDO_OK = "simulateur-flash::api-sapiendo-succes",
   FLASH_SAPIENDO_KO = "simulateur-flash::api-sapiendo-echec",
   MENTIONS_LEGALES = "acceptation-mentions-legales",
   RIS_PREPARATION = "parcours-ris::preparation",
   RIS_IMPORT = "parcours-ris::import",
   RIS_ANALYSE_OK = "parcours-ris::analyse-succes",
   RIS_ANALYSE_KO = "parcours-ris::analyse-echec",
   SGAGENDA_DEBRANCHEMENT = "sgagenda::debranchement",
   SGAGENDA_RETOUR = "sgagenda::rdv-pris",
   PATRIMOINE_MAJ = "parcours-patrimoine::actualiser",
   EPARGNER = "parcours-epargner",
   HOME = "home::comprendre-ma-retraite",
   PLAN = "mon-plan",
   SERVICES_PUBLICS = "mes-services::publics",
   SERVICES_SAPIENDO = "mes-services::sapiendo",
}

const useTrackingEvent = () => {
   const dispatch = useDispatch();
   const { idStat } = usePrivateData();

   function saveEvent(type: trackingEvent, complement?: string): void {
      const req: Partial<TrackingEvent> = {
         trackingId: idStat,
         type,
         complement,
         hardware: deviceType === "mobile" ? `Mobile ${mobileModel}` : "Desktop",
         webBrowser: `${browserName} ${browserVersion}`,
         operatingSystem: `${osName} ${osVersion}`,
         fromWebview: isWebView(),
      };
      dispatch(saveTrackingEvent(req));
   }

   return saveEvent;
};

export { useTrackingEvent };
