import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Indicator, Recommendation, RecommendationCategory, RecommendationState, RecommendationType} from "./types";
import cardEmergencySaving from "../../assets/images/recommendations/1201269168_super.jpg";
import cardDonationSurvivingSpouse from "../../assets/images/recommendations/1368647315_medium.jpg";
import cardPrevoyance from "../../assets/images/recommendations/1391970122_medium.jpg";
import cardEmergencySavingTooHigh from "../../assets/images/recommendations/1150014787_medium.jpg";
import cardRetraite from "../../assets/images/recommendations/enjoy_your_retirement_low.jpeg";
import cardProjectAdjust from "../../assets/images/recommendations/1040303566_medium.jpg";
import cardImmoBetter from "../../assets/images/recommendations/card-immo-better.png";
import cardCreditRepayment from "../../assets/images/recommendations/1325134124_super.jpg";
import cardCreditNegotiation from "../../assets/images/recommendations/143918178.jpg";
import cardTaxExemption from "../../assets/images/recommendations/515306510_super.jpg";
import cardReleasedMoneyFromLoan from "../../assets/images/recommendations/1348830933_super.jpg";
import cardChildrenEducationSavings from "../../assets/images/recommendations/815069468_super.jpg";
import cardDepositBefore70 from "../../assets/images/recommendations/1182214550_medium.jpg";
import cardInheritanceAdvice from "../../assets/images/recommendations/638932793_super.jpg";
import cardDiversifyRealEstate from "../../assets/images/recommendations/595504906_super.jpg";
import cardLifeInsurance from "../../assets/images/recommendations/1211622412_medium.jpg";
import cardChildLifeInsurance from "../../assets/images/recommendations/707021738.jpg";
import cardPushConseil from "../../assets/images/recommendations/card_push_conseil.png";
import cardVersement from "../../assets/images/recommendations/card-versement.png";
import cardNoAdvice from "../../assets/images/recommendations/804445672_medium.jpg";
import cardTakeAction from "../../assets/images/recommendations/608711169_super.jpg";
import cardConstructionWork from "../../assets/images/recommendations/card-constructionWork.jpeg";
import {convertNumberToStringWithSeparators} from "../../website/utils/formatting/numberFormatter";
import {ProjectType} from 'store/projects/types';

const addRecommendationFields = (recommendation: Recommendation): Recommendation => {
   const startingOutputData = {
      projectName: "",
      projectId: 0,
      projectType: "",
      saveOnPer: false,
      createPer: false,
      createFcpi: false,
      realEstateDispoDefisc: false
   };

   const outputDataAsJson = JSON.parse(recommendation.outputData);

   const outputData = (outputDataAsJson && outputDataAsJson[0] && Object.entries(outputDataAsJson[0]).reduce((a, [key, value]) => // for each item get the key/value pairs
         ({
            ...a,
            [key]: (typeof value === "number" && key !== "projectId") ? convertNumberToStringWithSeparators(value) : value
         }),
      startingOutputData)) || startingOutputData;

   const recommendationData = {
      ...recommendation,
      modalTextTransformations: outputData,
      projectName: outputData && outputData.projectName,
      projectId: outputData && outputData.projectId
   };

   switch (recommendationData.recommendationType) {
      case RecommendationType.CONSTRUCTION_WORK: {
         recommendationData.title = "advices.title.construction.work";
         recommendationData.description = "advices.description.construction.work";
         recommendationData.button = "advices.button.simulate";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardConstructionWork;
         recommendationData.modalText = "advices.modal-text.construction.work";
         recommendationData.modalAppUrl = "construction-work";
         recommendationData.modalTextUrl = window.env.REACT_APP_WORK_CREDITS;
         break;
      }
      case RecommendationType.CREATE_EMERGENCY_SAVINGS: {
         recommendationData.title = "advices.title.emergency.saving";
         recommendationData.description = "advices.description.emergency.saving";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardEmergencySaving;
         recommendationData.modalText = "advices.modal-text.emergency.saving";
         recommendationData.modalTextUrl = window.env.REACT_APP_SAVING_ACCOUNT_URL;
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.SAVE_ON_EMERGENCY_SAVINGS: {
         recommendationData.title = "advices.title.emergency.saving";
         recommendationData.description = "advices.description.save.emergency.saving";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardEmergencySaving;
         recommendationData.modalText = "advices.modal-text.emergency.saving";
         recommendationData.modalTextUrl = window.env.REACT_APP_SAVING_ACCOUNT_URL;
         break;
      }
      case RecommendationType.EMERGENCY_SAVINGS_TOO_HIGH: {
         recommendationData.title = "advices.title.emergency.saving.high";
         recommendationData.description = "advices.description.emergency.saving.high";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardEmergencySavingTooHigh;
         recommendationData.modalText = "advices.modal-text.emergency.saving.high";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.SAVE_FOR_RETIREMENT: {
         recommendationData.title = "advices.title.save.retirement";
         recommendationData.description = "advices.description.save.retirement";
         recommendationData.button = "advices.button.simulate";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardRetraite;
         recommendationData.modalText = "advices.modal-text.save.retirement";
         recommendationData.modalAppUrl = "retirement-project";
         recommendationData.modalTextUrl = window.env.REACT_APP_RETIREMENT_URL;
         break;
      }
      case RecommendationType.PROJECT_ADJUST: {
         recommendationData.title = "advices.title.your.project";
         recommendationData.description = "advices.description.your.project";
         recommendationData.button = "advices.button.lookup";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardProjectAdjust;
         recommendationData.modalText = "advices.modal-text.your.project";
         recommendationData.modalAppUrl = `projects?currentProject=${recommendationData.projectId}`;
         break;
      }
      case RecommendationType.BUY_PRIMARY_REAL_ESTATE: {
         recommendationData.title = "advices.title.your.estate";
         recommendationData.description = "advices.description.your.estate";
         recommendationData.button = "advices.button.simulate";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardImmoBetter;
         recommendationData.modalText = "advices.modal-text.your.estate";
         recommendationData.modalAppUrl = "real-estate-project";
         recommendationData.modalTextUrl = window.env.REACT_APP_OWNER_URL;
         break;
      }
      case RecommendationType.LOAN_REPAYMENT: {
         recommendationData.title = "advices.title.loan.repayment";
         recommendationData.description = "advices.description.loan.repayment";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.LOAN;
         recommendationData.image = cardCreditRepayment;
         recommendationData.modalCallToAction = true;

         if (outputData.projectType === ProjectType.RETIREMENT_PROJECT) {
            recommendationData.modalText = "advices.modal-text.loan.repayment.retirement";
         } else if (outputData.projectType === ProjectType.CHANGE_LIFE_PROJECT) {
            recommendationData.modalText = "advices.modal-text.loan.repayment.change-life";
         } else if (outputData.projectType === ProjectType.GIVEN_DONATION) {
            recommendationData.modalText = "advices.modal-text.loan.repayment.given-donation";
         } else {
            recommendationData.modalText = "advices.modal-text.loan.repayment.none";
         }
         break;
      }
      case RecommendationType.CREDIT_NEGOTIATION: {
         recommendationData.title = "advices.title.credit.negotiation";
         recommendationData.description = "advices.description.credit.negotiation";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.LOAN;
         recommendationData.image = cardCreditNegotiation;
         recommendationData.modalText = "advices.modal-text.credit.negotiation";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.TAX_EXEMPTION: {
         recommendationData.title = "advices.title.tax.exemption";
         recommendationData.description = "advices.description.tax.exemption";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.TAXATION;
         recommendationData.image = cardTaxExemption;
         recommendationData.modalCallToAction = true;
         if (outputData.createFcpi) {
            recommendationData.modalText = "advices.modal-text.tax.exemption.createFcpi";
            recommendationData.modalTextUrl = window.env.REACT_APP_TAXATION_URL;
         } else if (outputData.createPer) {
            recommendationData.modalText = "advices.modal-text.tax.exemption.createPer";
            recommendationData.modalTextUrl = window.env.REACT_APP_PER_URL;
         } else if (outputData.saveOnPer) {
            recommendationData.modalText = "advices.modal-text.tax.exemption.saveOnPer";
         } else if (outputData.realEstateDispoDefisc) {
            recommendationData.modalText = "advices.modal-text.tax.exemption.realEstateDispoDefisc";
            recommendationData.modalTextUrl = window.env.REACT_APP_REAL_ESTATE_RENT_URL;
         }
         break;
      }
      case RecommendationType.USE_RELEASED_MONEY_FROM_LOAN: {
         recommendationData.title = "advices.title.use.released.money.from.loan";
         recommendationData.description = "advices.description.use.released.money.from.loan";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardReleasedMoneyFromLoan;
         recommendationData.modalText = "advices.modal-text.use.released.money.from.loan";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.CHILDREN_EDUCATION_SAVINGS: {
         recommendationData.title = "advices.title.children.education.savings";
         recommendationData.description = "advices.description.children.education.savings";
         recommendationData.button = "advices.button.simulate";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardChildrenEducationSavings;
         recommendationData.modalText = "advices.modal-text.children.education.savings";
         recommendationData.modalTextUrl = window.env.REACT_APP_SAVING_ACCOUNT_URL;
         recommendationData.modalCallToAction = true;
         break;
      }

      case RecommendationType.MAKE_A_DEPOSIT_ON_LIFE_INSURANCE_BEFORE_70: {
         recommendationData.title = "advices.title.life.insurance.before.seventy";
         recommendationData.description = "advices.description.life.insurance.before.seventy";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardDepositBefore70;
         recommendationData.modalText = "advices.modal-text.life.insurance.before.seventy";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.ESTATE_FEES_PROTECT_KIDS: {
         recommendationData.title = "advices.title.estate.fees.protect.kids";
         recommendationData.description = "advices.description.estate.fees.protect.kids";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SUCCESSION;
         recommendationData.image = cardInheritanceAdvice;
         recommendationData.modalText = "advices.modal-text.inheritance-advice";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.ESTATE_FEES_PROTECT_AVERAGE_KIDS_AND_PARTNER: {
         recommendationData.title = "advices.title.estate.fees.protect.average";
         recommendationData.description = "advices.description.estate.fees.protect.average";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SUCCESSION;
         recommendationData.image = cardInheritanceAdvice;
         recommendationData.modalText = "advices.modal-text.inheritance-advice";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.ESTATE_FEES_PROTECT_HIGH_KIDS_AND_PARTNER: {
         recommendationData.title = "advices.title.estate.fees.protect.high.kids";
         recommendationData.description = "advices.description.estate.fees.protect.high.kids";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SUCCESSION;
         recommendationData.image = cardInheritanceAdvice;
         recommendationData.modalText = "advices.modal-text.inheritance-advice";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.ESTATE_FEES_PROTECT_PARTNER: {
         recommendationData.title = "advices.title.estate.fees.protect.partner";
         recommendationData.description = "advices.description.estate.fees.protect.partner";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SUCCESSION;
         recommendationData.image = cardInheritanceAdvice;
         recommendationData.modalText = "advices.modal-text.inheritance-advice";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.DIVERSIFICATION_REAL_ESTATE_HIGH: {
         recommendationData.title = "advices.title.diversification.real.estate";
         recommendationData.description = "advices.description.diversification.real.estate";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.ASSETS;
         recommendationData.image = cardDiversifyRealEstate;
         recommendationData.modalText = "advices.modal-text.diversification.real.estate";
         recommendationData.modalTextUrl = window.env.REACT_APP_LIFE_INSURANCE_URL;
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.DIVERSIFICATION_REAL_ESTATE_TO_HIGH: {
         recommendationData.title = "advices.title.diversification.real.estate.too.high";
         recommendationData.description = "advices.description.diversification.real.estate.too.high";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.ASSETS;
         recommendationData.image = cardDiversifyRealEstate;
         recommendationData.modalText = "advices.modal-text.diversification.real.estate";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.OPEN_LIFE_INSURANCE: {
         recommendationData.title = "advices.title.open.life.insurance";
         recommendationData.description = "advices.description.open.life.insurance";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardLifeInsurance;
         recommendationData.modalText = "advices.modal-text.open.life.insurance";
         recommendationData.modalTextUrl = window.env.REACT_APP_LIFE_INSURANCE_URL;
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.CREATE_VALORIZATION_PROJECT: {
         recommendationData.title = "advices.title.create.valorization.project";
         recommendationData.description = "advices.description.create.valorization.project";
         recommendationData.button = "advices.button.simulate";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardPushConseil;
         recommendationData.modalText = "advices.modal-text.create.valorization.project";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.USE_CASH_FOR_PROJECTS: {
         recommendationData.title = "advices.title.use.cash.for.projects";
         recommendationData.description = "advices.description.use.cash.for.projects";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardVersement;
         recommendationData.modalText = "advices.modal-text.use.cash.for.projects";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.PREVOYANCE: {
         recommendationData.title = "advices.title.prevoyance";
         recommendationData.description = "advices.description.prevoyance";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.INSURANCE;
         recommendationData.image = cardPrevoyance;
         recommendationData.modalText = "advices.modal-text.prevoyance";
         recommendationData.modalTextUrl = window.env.REACT_APP_PREVOYANCE_URL;
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.NO_ADVICE: {
         recommendationData.title = "advices.title.no.advice";
         recommendationData.description = "advices.description.no.advice";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardNoAdvice;
         recommendationData.modalText = "advices.modal-text.no.advice";
         break;
      }
      case RecommendationType.TAKE_ACTION: {
         recommendationData.title = "advices.title.take.action";
         recommendationData.description = "advices.description.take.action";
         recommendationData.button = "advices.button.lookup";
         recommendationData.category = RecommendationCategory.PROJECT;
         recommendationData.image = cardTakeAction;
         recommendationData.modalText = "advices.modal-text.take.action";
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.OPEN_CHILD_LIFE_INSURANCE: {
         recommendationData.title = "advices.title.open.child.life.insurance";
         recommendationData.description = "advices.description.open.child.life.insurance";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SAVING;
         recommendationData.image = cardChildLifeInsurance;
         recommendationData.modalText = "advices.modal-text.open.child.life.insurance";
         recommendationData.modalTextUrl = window.env.REACT_APP_LIFE_INSURANCE_URL;
         recommendationData.modalCallToAction = true;
         break;
      }
      case RecommendationType.DONATION_SURVIVING_SPOUSE: {
         recommendationData.title = "advices.title.donation.surviving.spouse";
         recommendationData.description = "advices.description.donation.surviving.spouse";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.DONATION;
         recommendationData.image = cardDonationSurvivingSpouse;
         recommendationData.modalText = "advices.modal-text.donation.surviving.spouse";
         break;
      }
      case RecommendationType.CHILDREN_TRANSMISSION: {
         recommendationData.title = "advices.title.children.transmission";
         recommendationData.description = "advices.description.children.transmission";
         recommendationData.button = "advices.button.discover";
         recommendationData.category = RecommendationCategory.SUCCESSION;
         recommendationData.image = cardInheritanceAdvice;
         recommendationData.modalText = "advices.modal-text.children.transmission";
         break;
      }
      default: {
         console.warn("Recommendation de type non géré");
         break;
      }
   }

   return recommendationData;
};

export const initialState: RecommendationState = {
   recommendations: new Array<Recommendation>(),
   cannotFetchRecommendations: false,
   hasFetched: false,
   indicators: new Array<Indicator>()
};

export const recommendationsSlice = createSlice({
   name: "recommendations",
   initialState,
   reducers: {
      recommendationsFetched: (currentState, action: PayloadAction<{ recommendations: Recommendation[], indicators: Indicator[] }>) => ({
         ...currentState,
         hasFetched: true,
         recommendations: action.payload.recommendations
         .map((r: Recommendation) => addRecommendationFields(r))
         .sort((a: Recommendation, b: Recommendation) => a.priority - b.priority),
         indicators: action.payload.indicators
         .filter((i: Indicator) => ["RETIREMENT", "TRANSMISSION", "DIVERSIFICATION"].includes(i.indicatorType))
         .map((i: Indicator) => ({
            ...i, messageKey: i.values.filter((v) => v.key === "JUSTIFICATION")[0].value
         }))
         .sort((a: Indicator, b: Indicator) => {
            if (a.indicatorType === "RETIREMENT") {
               return -1;
            }
            if (a.indicatorType === "DIVERSIFICATION") {
               return 1;
            }

            return b.indicatorType === "DIVERSIFICATION" ? -1 : 1;
         })
      }),
      fetchRecommendationsError: (currentState) => ({
         ...currentState, cannotFetchRecommendations: true
      }),
      resetRecommendations: () => (
         initialState
      )

   }
});

export const {recommendationsFetched, fetchRecommendationsError, resetRecommendations} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
