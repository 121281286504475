import { FunctionComponent } from "react";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { DashboardAge } from "store/dashboard/types";
import { getAge, getDateAge } from "store/dashboard/utils";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";

interface CarteAgeContenuProps {
  age: DashboardAge;
}

export const CarteAgeContenu: FunctionComponent<CarteAgeContenuProps> = (
  props: React.PropsWithChildren<CarteAgeContenuProps>
) => {
  const { age } = props;

  const { montantTotal } = useMonPlanMontants(age);

  return (
    <SGSpace disableautomargin direction="vertical" size="xs">
      <SGBox>
        <>
          <SGText titlerender size="l">
            {getAge(age)}
          </SGText>
          <SGText size="xs" color="lighter">
            {getDateAge(age)}
          </SGText>
        </>
      </SGBox>
      <SGText>{age.typesDepartTitre}</SGText>
      <SGBox>
        <>
          <SGText weight="600" size="xs">
            Revenus à la retraite
          </SGText>
          <SGText titlerender size="l">
            {` ${montantTotal}${" "}€ `}
          </SGText>
          <SGText weight="400">net/mois</SGText>
        </>
      </SGBox>
    </SGSpace>
  );
};
