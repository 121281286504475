import debounce from "lodash.debounce";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGBox, SGSpace } from "sg-space";
import { SGText } from "sg-typo";
import { AssetIncomesState } from "store/assetIncomes/types";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { getMonthlyIncomes } from "store/savingPath/actions";
import {
    DataComparer,
    MonthlyIncomesRequest,
    Result,
    SavingPathState,
    convertAgeTypeToWealthProfile,
    hasFetchedSavingPathObjective
} from "store/savingPath/types";
import { State } from "store/store";
import { EpargnerCard } from "website/components/atoms/EpargnerCard/EpargnerCard";
import { EpargnerComparerHaut } from "website/components/molecules/EpargnerComparerHaut/EpargnerComparerHaut";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import "./ComparerTab.scss";

interface ComparerTabProps {
    objectif: number;
    montantEpargne: number;
    setMontantEpargne: (value: number) => void;
    data: DataComparer[];
    wealthProfiles: string[];
    currentKey: string;
    itemKey: string;
}

const ComparerTab: FunctionComponent<ComparerTabProps> = (props: ComparerTabProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { objectif, montantEpargne, setMontantEpargne, data, wealthProfiles, currentKey, itemKey } = props;

    const [lastMonthlyIncome, setLastMonthlyIncome] = useState(0);
    const [dataComparer, setDataComparer] = useState<DataComparer[]>(data);
    const [lastSavingAmount, setLastSavingAmount] = useState(montantEpargne);

    const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
    const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);
    const savingPathState = useSelector<State, SavingPathState>((state) => state.savingPath);

    const {assets} = assetState;
    const {assetIncomes} = assetIncomesState;

    const titleComparer = ["annee", "epargne", "complement", "revenus"];

    // Initialisation des données pour chaque âge
    useEffect(() => {
        if (itemKey !== currentKey) {
            return;
        }

        setLastMonthlyIncome(roundDown10(Math.max(...data.map((item) => item.age.lastMonthlyIncome))));

        const newData: DataComparer[] = data.map((age: DataComparer) => {
            const newAge = age;

            // Fetched dans ModuleEpargner
            const additionalIncome = computeAdditionalIncomes(assets, assetIncomes, newAge.age);
            if (additionalIncome) {
                newAge.montantPatrimoine = roundDown10(additionalIncome);
            }
            newAge.deltaObjectif = getDeltaObjectif(age.age.retirementMonthlyIncome, objectif, newAge.montantPatrimoine || 0);

            return newAge;
        });
        setDataComparer(newData);
    }, [data, assets, assetIncomes]);

    // Si l'objectif est modifié, on met à jour les données
    useEffect(() => {
        if (itemKey !== currentKey) {
            return;
        }
        if (hasFetchedSavingPathObjective(savingPathState)) {
            const newData: DataComparer[] = [];
            dataComparer.map((age: DataComparer) => {
                const newAge = age;
                const ageWealth = convertAgeTypeToWealthProfile(newAge.age.type);
                newAge.objectif = roundDown10(savingPathState.results?.find((result: Result) => result.age === ageWealth)?.result || 0);
                newAge.deltaObjectif = getDeltaObjectif(newAge.age.retirementMonthlyIncome, newAge.objectif, newAge.montantPatrimoine || 0);

                return newData.push(newAge);
            });
            setDataComparer(newData);
        }
    }, [savingPathState]);

    useEffect(() => {
        if (itemKey !== currentKey) {
            return;
        }
        if (lastSavingAmount !== montantEpargne) {
            debouncedCall(montantEpargne);
        } else if (currentKey === "Comparer") {
            callSavingPathMotor(montantEpargne);
        }

        setLastSavingAmount(montantEpargne);
    }, [montantEpargne, objectif, currentKey]);

    /**
     * Retourne le complément de revenu. Calculé par la différence entre l'objectif et les revenus (pension + patrimoine)
     *
     * @param retirementMonthlyIncome Revenu
     * @param objectifValue Objectif
     * @param montantPatrimoine Patrimoine
     */
    const getDeltaObjectif = (retirementMonthlyIncome: number, objectifValue: number, montantPatrimoine: number) => {
        const montantPension = roundDown10(retirementMonthlyIncome);
        const deltaObjectif = objectifValue - (montantPension + montantPatrimoine);
        if (deltaObjectif < 0) {
            return 0;
        }

        return deltaObjectif;
    };

    const debouncedCall = useCallback(
        debounce((montantEpargne: number) => callSavingPathMotor(montantEpargne), 400),
        [] // will be created only once initially
    );

    /**
     * Met à jour l'effort d'épargne
     */
    const callSavingPathMotor = (val: number) => {
        const req: MonthlyIncomesRequest = {
            profiles: wealthProfiles,
            monthlySavingEffort: val,
        };
        dispatch(getMonthlyIncomes(req));
    };

    return (
        <div className="comparer-tab__no-padding">
            <SGCard>
                <EpargnerComparerHaut
                    montantEpargne={montantEpargne}
                    setMontantEpargne={setMontantEpargne}
                />
                <SGSpace size="xs" disableautomargin>
                    {titleComparer.map((title, index) => (
                        <SGBox padding={index !== 2 ? {left: "sm"} : {}} key={`${title}`}>
                            <SGText size="xl">
                                {intl.formatMessage({
                                    id: `parcoursEpargner.comparer.title.${title}`,
                                })}
                            </SGText>
                        </SGBox>
                    ))}
                </SGSpace>
                <SGBox margin={{top: "md", bottom: "md"}}>
                    <SGDivider/>
                </SGBox>
                <SGGridRow gutter={[0,16]}>
                    {dataComparer?.map((age: DataComparer) => (
                        <React.Fragment key={`${age.age.type}`}>
                            <SGGridCol span={3} align="center">
                                <SGBox margin={{left: "sm"}}>
                                    <>
                                        <SGText weight="600" style={{ color: "#010035", fontSize: "32px" }}>
                                            {intl.formatMessage(
                                                {
                                                    id: `parcoursEpargner.comparer.age.${
                                                    age.age.retirementAgeMonth ? `month` : "notMonth"
                                                    }`,
                                                },
                                                {
                                                    retirementAgeMonth: age.age.retirementAgeMonth,
                                                    retirementAgeYear: age.age.retirementAgeYear,
                                                    month: (word: string) => <label className="comparer-tab__month">{word}</label>,
                                                }
                                            )}
                                        </SGText>
                                        <InfoTooltipAssu placement="leftTop">
                                            <SGText>{age.age.typesDepartTitre}</SGText>
                                        </InfoTooltipAssu>
                                    </>
                                </SGBox>
                            </SGGridCol>
                            <SGGridCol span={3}>
                                <EpargnerCard comparer salaryMonth={montantEpargne} selectedRetirementAge={age.age} color="blue" />
                            </SGGridCol>
                            <SGGridCol span={3}>
                                <EpargnerCard
                                    comparer
                                    environ
                                    salaryMonth={age.deltaObjectif}
                                    selectedRetirementAge={age.age}
                                    color="dark-green"
                                />
                            </SGGridCol>
                            <SGGridCol span={3}>
                                <EpargnerCard comparer salaryMonth={age.objectif} color="grey" />
                            </SGGridCol>
                        </React.Fragment>
                    ))}
                </SGGridRow>
            </SGCard>
        </div>
    );
};

export { ComparerTab };
