import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {AssetWithValuation, REAL_ESTATE_ASSETS, RealEstateAsset,} from "../../../../../store/assets/types";
import {AssetEditFormData, isAssociatedToSCI, parseFormDataForAsset} from "../../../../../store/assets/utils";
import {AssetElement} from "../../../molecules/AssetElement/AssetElement";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {State} from "../../../../../store/store";
import {AccountData} from "../../../../../store/account/types";
import {assetsToCategoryArray} from "../../../molecules/NameAndValuationCollapsableFrames/NameAndValuationCollapsableFrames";
import {getCategoryIcone} from "../../../molecules/NameAndValuationCollapsableFrames/FrameCategory";
import {isSCIAssetType} from "../../../../../store/assets/typeguards";
import {useAssets} from "../../../hooks/useAssets";
import {useLoans} from "../../../hooks/useLoans";
import {useFamily} from "../../../hooks/useFamily";
import {EditorContext} from "../../../providers/EditorProvider";
import {SGSpace} from "sg-space";
import {SGBlock} from "sg-typo";
import {SGCard} from "sg-card";
import {createAsset, createRealEstateAsset, createSCIAsset} from "../../../../../store/assets/actions";
import {SGImportAssetModal} from "../../../molecules/SgImportAssetModal/SGImportAssetModal";
import {AssetEdit} from "../../../molecules/AssetEdit/AssetEdit";
import {useRealEstateAssets} from "../../../hooks/useRealEstateAssets";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import { ButtonAddNewElementSupervision } from "website/components/atoms/ButtonAddNewElementSupervision/ButtonAddNewElementSupervision";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { setShowSGImportAssetModal } from "store/assets/assetsSlice";

interface AssetsEditorProps {
   cypressName: string;
   displayAssetShares: boolean;
}

const AssetsEditor: FunctionComponent<AssetsEditorProps> = ({displayAssetShares, cypressName}: AssetsEditorProps) => {
   const dispatch = useDispatch();
   const { assets } = useAssets();
   const family = useFamily();
   const loans = useLoans([]);
   const realEstateAssets = useRealEstateAssets();
   const displayComponent = useSelector<State, boolean>((state) => state.assets.hasFetched);

   const {isEditing, setEditing} = useContext(EditorContext);

   const [isCreatingAsset, setIsCreatingAsset] = useState(false);
   const [displayImportSgAsset, setDisplayImportSgAsset] = useState(false);

   const account: AccountData = useSelector<State, AccountData>((state) => state.account.account);
   const [nbAssets, setNbAssets] = useState<number>();

   const showSGImportAssetModal: boolean = useSelector<State, boolean>((state) => state.assets.showSGImportAssetModal);

   const { disponibiliteHorsSgPatrimoineSupervision } = useFeatures();
   const { trackClick } = useTracking();

   const methodsAsset = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   useEffect(() => {
      setEditing(isCreatingAsset);
   }, [isCreatingAsset]);

   useEffect(() => {
      // Permet de masquer le formulaire de création après l'enregistrement de l'asset
      if (nbAssets !== assets.length) {
         setIsCreatingAsset(false);
         setNbAssets(assets.length)
      }
   }, [assets]);

   useEffect(() => {
      setDisplayImportSgAsset(showSGImportAssetModal);
   }, [showSGImportAssetModal]);

   const handleCreateAsset = (data: AssetEditFormData) => {
      const currentAsset: Partial<RealEstateAsset> = parseFormDataForAsset(data, family, loans, realEstateAssets);
      if (currentAsset.assetType !== undefined && REAL_ESTATE_ASSETS.includes(currentAsset.assetType)) {
         dispatch(createRealEstateAsset(currentAsset));
      } else if (isSCIAssetType(currentAsset)) {
         dispatch(createSCIAsset(currentAsset));
      } else {
         dispatch(createAsset(currentAsset));
      }
   };

   const renderAssetsByType = () =>
      assetsToCategoryArray(assets).map((categoryAndValues) => (
         <SGSpace direction="vertical" key={categoryAndValues.category.intlKey + categoryAndValues.valuations + categoryAndValues}>
            <SGBlock key="block" textalign="left">
               {getCategoryIcone(categoryAndValues.category)}
               &nbsp;
               <SGTextIntl intlId={`assetCategory.${categoryAndValues.category.intlKey}`} strong/>
            </SGBlock>

            {categoryAndValues.valuations.map((asset: AssetWithValuation) => (
               (asset.externalId !== null || disponibiliteHorsSgPatrimoineSupervision) ?
               (<SGCard borderleft={`.3rem solid ${categoryAndValues.category.color.hexCode}`} key={asset.id} disableautomargin
                       className={isAssociatedToSCI(assets, asset) ? "asset__sub-element" : ""}>
                  <AssetElement asset={asset} associatedRealEstateAssets={realEstateAssets}
                                cypressName={`${cypressName}-${asset.assetType}`} displayAssetShares={displayAssetShares}
                                isAssetMemberLinkDisabled={isAssociatedToSCI(assets, asset)}/>
               </SGCard>) : (<></>)
            ))}
         </SGSpace>
      ));

   const renderAssetCreate = () => (
      <SGCard key="card" disableautomargin>
         <FormProvider {...methodsAsset}>
            <form>
               <AssetEdit onCancel={() => setIsCreatingAsset(false)} onSubmit={handleCreateAsset} isRealEstateIncome={false}
                          cypressName={`${cypressName}-new-edit`} isAssetMemberLinkDisabled={false} displayAssetShares={displayAssetShares}/>
            </form>
         </FormProvider>
      </SGCard>

   );

   return (
      <SpinnerWrapper displayComponent={displayComponent} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace disableautomargin direction="vertical">
            <div key="div">{renderAssetsByType()}</div>
            {isCreatingAsset && renderAssetCreate()}
            <ButtonAddNewElementSupervision key="button" text="form.asset.new" disabled={isEditing} onClick={() => {
               trackClick("agreger-mon-patrimoine::clic-sur-ajouter-un-compte-ou-un-bien-immobilier");
               if (window.env.REACT_APP_USE_SSO === "false" || !account.ssouser) {
                  setIsCreatingAsset(true);
               } else {
                  setDisplayImportSgAsset(true);
               }
            }}/>
            {/* Modal pour ajouter un asset venant de l'import SG */}
            {displayImportSgAsset && <SGImportAssetModal cypressName={`${cypressName}-import`} key="modal" hideModal={() => { setDisplayImportSgAsset(false); dispatch(setShowSGImportAssetModal(false)) }} onCreateOtherAsset={() => setIsCreatingAsset(true)} showSGImportAssetModal={showSGImportAssetModal}/>}
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {AssetsEditor};
