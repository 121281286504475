import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {Project, ProjectState, ProjectType} from "../../../store/projects/types";
import {getProjectsAction} from "../../../store/projects/actions";

const useRetirementProject = () => {
   const dispatch = useDispatch();
   const [retirementProject, setRetirementProject] = useState<Project>();
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   useEffect(() => {
      if (!projectState.hasFetchedProjects) {
         dispatch(getProjectsAction());
      }
   }, []);

   useEffect(() => {
      if (projectState.hasFetchedProjects) {
         setRetirementProject(projectState.projects.filter((project) => project.projectType === ProjectType.RETIREMENT_PROJECT).shift());
      }
   }, [projectState]);

   return retirementProject;
};

export {useRetirementProject};
