import React, {FunctionComponent, useEffect} from "react";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {ElyxirTabs} from "../components/molecules/ElyxirTabs/ElyxirTabs";
import {ProfileFamily} from "../components/organisms/Profile/ProfileFamily/ProfileFamily";
import {ProfileNetWorth} from "../components/organisms/Profile/ProfileNetWorth/ProfileNetWorth";
import {ProfileIncomeExpenses} from "../components/organisms/Profile/ProfileIncomeExpenses/ProfileIncomeExpenses";
import {ProfileTaxation} from "../components/organisms/Profile/ProfileTaxation/ProfileTaxation";
import {ProfileSuccession} from "../components/organisms/Profile/ProfileSuccession/ProfileSuccession";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {SGContent} from "sg-layout";
import {SITUATION_FAMILY, SITUATION_INCOMES, SITUATION_NET_WORTH, SITUATION_SUCCESSION, SITUATION_TAXATION} from "../utils/privateRoutes";
import {SGSpace} from "sg-space";
import {SGBlock} from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const ProfilePage: FunctionComponent = () => {
   const { trackPage } = useTracking();

   const tabsUrls = [
      SITUATION_FAMILY,
      SITUATION_NET_WORTH,
      SITUATION_INCOMES,
      SITUATION_TAXATION,
      SITUATION_SUCCESSION,
   ];

   useEffect(() => {
      trackPage("profil", "accueil", "gestion-ecran");
   }, []);

   return (
      <SGContent>
         <SGSpace direction="vertical" textalign="left">
            <SGBlock>
               <SGTitleIntl intlId="profile.title" cypressName="profile-title"/>
               <SGTextIntl intlId="profile.subtitle"/>
            </SGBlock>
            <ElyxirTabs centered
                        tabsCypressNames={["family", "net-worth", "income-expense", "taxation", "succession",]}
                        tabsNames={["profile.family.title", "profile.net.worth.title", "profile.income.expenses.title", "profile.taxation.title", "profile.succession.title"]}
                        tabsComponents={[<SGContent key="profile-family"><ProfileFamily/></SGContent>,
                           <SGContent key="profile-net-worth"><ProfileNetWorth/></SGContent>,
                           <SGContent key="profile-income-expenses"><ProfileIncomeExpenses/></SGContent>,
                           <SGContent key="profile-taxation"><ProfileTaxation/></SGContent>,
                           <SGContent key="profile-succession"><ProfileSuccession/></SGContent>]}
                        tabsUrls={tabsUrls}
            />
         </SGSpace>
      </SGContent>
   );
};

export {ProfilePage};
