import { ReactComponent as LinkImage } from "assets/images/link-image-black.svg";
import React, {
  FunctionComponent,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGLink } from "sg-link";
import { SGBlock, SGText } from "sg-typo";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import {
  PAGE_TYPE_FORMULAIRE,
  TRACKING_CLICK_HYPOTHESES,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { externalURL } from "website/utils/externalUrl";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import "./Faq.scss";

interface Faq {
  titleFaq?: string;
  bodyFaq: string[];
  openDefaultKeys?: string[];
  hideMainAccordion?: boolean;
  isMainAccordionOpen?: boolean;
  trackKeyParcours?: string;
  setShowHypothesisModal?: (value: boolean) => void;
  isModal?: boolean; // pour le tacking
}

export const Faq: FunctionComponent<Faq> = (
  props: React.PropsWithChildren<Faq>
) => {
  const intl = useIntl();
  const {
    titleFaq,
    bodyFaq,
    hideMainAccordion,
    isMainAccordionOpen,
    openDefaultKeys,
    trackKeyParcours,
    setShowHypothesisModal,
    isModal = false,
  } = props;

  const [titleClicked, setTitleClicked] = useState(
    isMainAccordionOpen || false
  );
  const [openFaq, setOpenFaq] = useState<string[]>(bodyFaq);

  const openSGAgenda = useSGAgenda();
  const openLink = useOpenLink();
  const { disponibiliteSGAgenda } = useFeatures();
  const { trackClick, trackPopin } = useTracking();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleOnClickRdv = () => {
    if (isModal) {
      // Pop-in Hypothesis
      trackPopin(
        true,
        "",
        "hypothese-de-simulation::clic-sur-prendre-rendez-vous",
        "",
        "",
        PAGE_TYPE_FORMULAIRE,
        { form_field_2: disponibiliteSGAgenda ? "active" : "non-active" }
      );
    } else {
      trackClick("faq::clic-sur-prendre-rendez-vous", {
        form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
      });
    }
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (openDefaultKeys) {
      setOpenFaq(openDefaultKeys);
    }
  }, [openDefaultKeys]);

  useEffect(() => {
    if (isMainAccordionOpen !== undefined) {
      setTitleClicked(isMainAccordionOpen);
    }
  }, [isMainAccordionOpen]);

  function onClickHypothesisModal() {
    if (trackKeyParcours) {
      trackClick(`${trackKeyParcours}::${TRACKING_CLICK_HYPOTHESES}`);
    }
    if (setShowHypothesisModal) {
      setShowHypothesisModal(true);
    }
  }

  const renderFaqContent = () => (
    <SGAccordion
      className="faq__accordion-particulier"
      activeKey={openFaq}
      accordion={false}
      onChange={(key: string[]) => setOpenFaq(key)}
      disableContentMargin
    >
      {bodyFaq.map((body) => (
        <SGAccordionPanel
          header={intl.formatMessage({ id: `faq.title.${body}` })}
          key={body}
        >
          <FormattedMessage
            id={`faq.text.${body}`}
            values={{
              p: (word: string) => <p>{word}</p>,
              ul: (word: string) => (
                <ul className="faq__content-list">{word}</ul>
              ),
              li: (word: string) => <li>{word}</li>,
              b: (word: string) => <strong>{word}</strong>,
              i: (word: string) => <em>{word}</em>,
              u: (word: string) => <SGText underline>{word}</SGText>,
              linebreak: <br />,
              agenda: (word: string) => (
                <BoutonSGAgenda type="link" onClick={handleOnClickRdv}>
                  {word}
                </BoutonSGAgenda>
              ),
              sga: (word: string) => {
                const link = window.env.REACT_APP_BDDF_URL;

                return (
                  <SGLink
                    key="lien-sga"
                    icon={<LinkImage />}
                    iconside="right"
                    href={link}
                    onClick={(event: SyntheticEvent) => openLink(link, event)}
                  >
                    {word}
                  </SGLink>
                );
              },
              pers: (word: string) => {
                const link =
                  window.env.REACT_APP_BDDF_URL +
                  intl.formatMessage({ id: "link.rgpd" });

                return (
                  <SGLink
                    key="lien-pers"
                    icon={<LinkImage />}
                    iconside="right"
                    href={link}
                    onClick={(event: SyntheticEvent) => openLink(link, event)}
                  >
                    {word}
                  </SGLink>
                );
              },
              hypothese: (word: string) => (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={onClickHypothesisModal}
                >
                  {word}
                </span>
              ),
              inforetraite: (word: string) => {
                const link = externalURL.EXTERNAL_URL_INFO_RETRAITE;

                return (
                  <SGLink
                    key="lien-inforetraite"
                    icon={<LinkImage />}
                    iconside="right"
                    href={link}
                    onClick={(event: SyntheticEvent) => openLink(link, event)}
                  >
                    {word}
                  </SGLink>
                );
              },
            }}
          />
        </SGAccordionPanel>
      ))}
    </SGAccordion>
  );

  return (
    <SGBlock>
      {!hideMainAccordion ? (
        <SGAccordion
          className={
            titleClicked
              ? "faq__accordion-globale"
              : "faq__accordion-globale-unclicked"
          }
          onChange={() => setTitleClicked(!titleClicked)}
          activeKey={titleClicked ? "mainPanel" : undefined}
          disableContentMargin
        >
          <SGAccordionPanel
            key="mainPanel"
            header={intl.formatMessage({
              id: titleFaq ?? "parcoursRIS.faq.foireAuxQuestions",
            })}
          >
            {renderFaqContent()}
          </SGAccordionPanel>
        </SGAccordion>
      ) : (
        renderFaqContent()
      )}
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </SGBlock>
  );
};
