import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {AssetState, RealEstateAsset, RealEstateAssetTaxSystemType} from "../../../store/assets/types";
import {getAssetsWithoutLoans} from "../../../store/assets/actions";
import {isRealEstateAssetType} from "../../../store/assets/typeguards";

const useTaxExemptedAssets = () => {
   const dispatch = useDispatch();
   const [taxExemptedAssets, setTaxExemptedAssets] = useState<RealEstateAsset[]>([]);
   const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);

   useEffect(() => {
      if (!assetState.hasFetched) {
         dispatch(getAssetsWithoutLoans());
      }
   }, []);

   useEffect(() => {
      if (assetState.hasFetched) {
         const taxExemptedAssetsToReturn: RealEstateAsset[] = [];
         assetState.assets.forEach((asset) => {
            if (isRealEstateAssetType(asset) && asset.taxSystemType === RealEstateAssetTaxSystemType.DEFISCALISATION_REAL_ESTATE_INCOME) {
               taxExemptedAssetsToReturn.push(asset);
            }
         });
         setTaxExemptedAssets(taxExemptedAssetsToReturn);
      }
   }, [assetState.assets]);

   return taxExemptedAssets;
};

export {useTaxExemptedAssets};
