import {Member, RetirementCategory} from "../members/types";
import {AssetType, RealEstateAssetTaxSystemSubType, RealEstateAssetTaxSystemType} from "../assets/types";

export interface ProjectState {
   projects: Project[];
   hasFetchedProjects: boolean;
   retirementProject: RetirementProjectValues;
   hasFetchedMyRetirementSimulation: boolean;
   hasFetchedPartnerRetirementSimulation: boolean;
   projectFeasibilities: ProjectFeasibility[];
   hasFetchedFeasibilities: boolean;
   hasFetchedFeasibilitiesError: boolean;
   additionalIncomeObjective: number | undefined;
   givenDonationFees: number | undefined;
   changeLifeMyFutureIncome: number;
   changeLifePartnerFutureIncome: number;
   changeLifeFutureExpenses: FutureExpenses | undefined;
   isRetirementProjectAutomaticallyUpdated: boolean;
}

export interface Project {
   id: number;
   clientId: number;
   projectName: string;
   projectType: ProjectType;
   creationDate: string;
   amount: number;
   priority: string;
   horizon: number;
   startDate: string;
   endDate: string;
   member: Member;
   memberAgeAtProjectStartDate: number;
   assetId: number;
}

export type FutureIncome = { memberId: number | undefined; futureIncome: number; };

export interface NewDefaultProject {
   projectName?: string;
   name?: string;
   projectType?: ProjectType;
   horizon: number;
   amount?: number;
   duration?: string;
   recurrentFees?: number;
   durationInMonths?: number;
   futureExpenses?: number | null;
   futureIncomes?: FutureIncome[];
   totalFutureIncome?: number | null;
   concernedMember?: Partial<Member>;
   memberId?: number | null;
   given?: boolean;
   assetsAllocations?: [];
   fees?: number;
   transactionFees?: number;
   yearlyRentAmount?: number;
   soldAssetIds?: (string | undefined)[];
   rentalInvestmentAssetTaxArrangement?: RealEstateAssetTaxSystemSubType;
   rentalInvestmentAssetTaxSystem?: RealEstateAssetTaxSystemType;
}

export const ProjectCategory = {
   REAL_ESTATE_OR_CONSTRUCTION: "REAL_ESTATE_OR_CONSTRUCTION",
   LIFE_PROJECT: "LIFE_PROJECT",
   OPTIMIZE_PROJECT: "OPTIMIZE_PROJECT",
   CHANGE_PROJECT: "CHANGE_PROJECT",
   OTHER: "OTHER"
};

export type ProjectCategory = typeof ProjectCategory[keyof typeof ProjectCategory];

export const ProjectType = {
   RETIREMENT_PROJECT: "RETIREMENT_PROJECT",
   REAL_ESTATE_PRIMARY: "REAL_ESTATE_PRIMARY",
   REAL_ESTATE_SECONDARY: "REAL_ESTATE_SECONDARY",
   REAL_ESTATE_INVESTMENT: "REAL_ESTATE_INVESTMENT",
   CAPITAL_GAIN: "CAPITAL_GAIN",
   ADDITIONAL_INCOME: "ADDITIONAL_INCOME",
   CONSTRUCTION_WORK: "CONSTRUCTION_WORK",
   EXCEPTIONAL_EXPENSE: "EXCEPTIONAL_EXPENSE",
   WEDDING: "WEDDING",
   CHILDREN_EDUCATION: "CHILDREN_EDUCATION",
   RECURRENT_EXPENSE: "RECURRENT_EXPENSE",
   EXPENSES: "EXPENSES",
   WORK_INCOME: "WORK_INCOME",
   RETIREMENT_INCOME: "RETIREMENT_INCOME",
   OTHER_INCOME: "OTHER_INCOME",
   CHANGE_LIFE_PROJECT: "CHANGE_LIFE_PROJECT",
   TRAVEL: "TRAVEL",
   SABBATICAL_LEAVE: "SABBATICAL_LEAVE",
   GIVEN_DONATION: "GIVEN_DONATION",
   RECEIVED_DONATION: "RECEIVED_DONATION",
   LOAN: "LOAN",
   RENT: "RENT",
   EXPENSE_PLANNED_SAVINGS: "EXPENSE_PLANNED_SAVINGS",
   TAX_EXPENSE: "TAX_EXPENSE",
   TAX_EXEMPTION_PROJECT: "TAX_EXEMPTION_PROJECT",
   ASSET_EXPENSE: "ASSET_EXPENSE",
   CHILD_SUPPORT_EXPENSE: "CHILD_SUPPORT_EXPENSE",
   CHILDCARE_DEDUCTIBLE_EXPENSE: "CHILDCARE_DEDUCTIBLE_EXPENSE",
   EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE: "EMPLOYEE_AT_HOME_DEDUCTIBLE_EXPENSE",
   DONATION_DEDUCTIBLE_EXPENSE: "DONATION_DEDUCTIBLE_EXPENSE"
};

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];

export const LIFE_PROJECTS: Array<AssetType> = [ProjectType.RETIREMENT_PROJECT, ProjectType.CAPITAL_GAIN, ProjectType.EXCEPTIONAL_EXPENSE, ProjectType.CONSTRUCTION_WORK, ProjectType.WEDDING, ProjectType.CHILDREN_EDUCATION, ProjectType.ADDITIONAL_INCOME, ProjectType.TAX_EXEMPTION_PROJECT,
   ProjectType.REAL_ESTATE_PRIMARY, ProjectType.REAL_ESTATE_SECONDARY, ProjectType.REAL_ESTATE_INVESTMENT, ProjectType.GIVEN_DONATION, ProjectType.CHANGE_LIFE_PROJECT, ProjectType.RECEIVED_DONATION];

export interface ProjectFeasibility {
   // User Info
   maxLoanAmount: number;
   maxInitialDepositAmount: number;
   maxMonthlyDepositAmount: number;

   // Project Info
   projectId: number;
   projectName: string;

   projectType: ProjectType;
   projectTotalObjective: number;
   projectObjective: number;
   projectDuration: number;
   projectHorizon: number;
   projectLoanAllowed: boolean;
   maxComputedProjectDeposit: number;

   // Plan recommandé par Elyxir
   plan: ProjectPlan;
}

export enum ProjectPlanStatus {
   OK = "OK",
   OPTIMIZE = "OPTIMIZE",
   INFO = "INFO",
   WARN = "WARN",
   KO = "KO",
}

export enum ProjectPlanAlert {
   BANKRUPTCY_BEFORE = "BANKRUPTCY_BEFORE",
   BANKRUPTCY_AFTER = "BANKRUPTCY_AFTER",
   BANKRUPTCY_REC = "BANKRUPTCY_REC",
   BANKRUPTCY_SPOT = "BANKRUPTCY_SPOT",
   USES_INVESTMENT = "USES_INVESTMENT",
   USES_SAVING = "USES_SAVING",
   USES_CASH = "USES_CASH",
}

export interface ProjectPlan {
   asset: ProjectPlanAsset;
   alert: ProjectPlanAlert;
   status: ProjectPlanStatus;

   recommendations: string[];

   loanAmount: number;
   soldAssetAmount: number;
   cashAmount: number;
   savingsAmount: number;
   investmentAmount: number;
   spotRealized: boolean;
   recurringRealized: boolean;
   bankruptcy: boolean;
   cashUsed: boolean;
   savingUsed: boolean;
   otherInvestmentUsed: boolean;
}

export interface ProjectPlanAsset {
   name: string;
   type: AssetType;
   initialDepositAmount: number;
   monthlyDepositAmount: number;
   computedProjectDepositAmount: number;
   grossAmount: number;
   netAmount: number;
   grossCapitalGain: number;
   netCapitalGain: number;
}

// TODO Améliorer le nommage de cette interface
export interface RetirementProjectValues {
   // initial input values
   myRetirement: RetirementSimulation;
   partnerRetirement: RetirementSimulation;

   // computed values from initial values
   expenses: FutureExpenses;
   objective: number;

   // ID of saved project
   projectId: number;
}

export interface PartialRetirementProjectValues {
   // initial input values
   myRetirement: Partial<RetirementSimulation>;
   partnerRetirement: Partial<RetirementSimulation>;

   // computed values from initial values
   expenses: FutureExpenses;
   objective: number;

   // ID of saved project
   projectId: number;
}

export const WorkIncomeEvolutionLevel = {
   NO_WORK_INCOME_RAISES: "NO_WORK_INCOME_RAISES",
   LOW_WORK_INCOME_RAISES: "LOW_WORK_INCOME_RAISES",
   HIGH_WORK_INCOME_RAISES: "HIGH_WORK_INCOME_RAISES"
};

export type WorkIncomeEvolutionLevel = typeof WorkIncomeEvolutionLevel[keyof typeof WorkIncomeEvolutionLevel];

export interface IncomeLine {
   name: string;
   income: number;
}

export interface RetirementSimulation {
   memberId: number;
   memberName: string;

   // initial values
   birthdate: Date | string;
   currentMonthlyIncome: number;
   type: RetirementCategory;
   salaryEvolutionLevel: WorkIncomeEvolutionLevel;

   // computed values from initial values
   retirementYearToRetirementIncomeMap: { [key: number]: number };
   retirementYearToIncomeBeforeRetirementMap: { [key: number]: number };

   // additional values after users'choices
   retirementIncomes: Array<IncomeLine>;
   memberRetirementDate: Date | string;
}

export const sumIncomeLines = (lines: Array<IncomeLine>) =>
   lines.reduce((sum, line) => sum + Number(line.income), 0);

export interface FutureExpenses {
   common: number;
   projects: number;
   other: number;
   total: number;
}

// actions
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const DELETE_ALL_PROJECTS = "DELETE_ALL_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const FETCH_RETIREMENT_PROJECT = "FETCH_RETIREMENT_PROJECT";
export const CREATE_RETIREMENT_PROJECT = "CREATE_RETIREMENT_PROJECT";
export const UPDATE_RETIREMENT_PROJECT = "UPDATE_RETIREMENT_PROJECT";
export const UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME = "UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME";
export const SIMULATE_RETIREMENT_PROJECT = "SIMULATE_RETIREMENT_PROJECT";
export const UPDATE_RETIREMENT_PROJECT_IN_STORE = "UPDATE_RETIREMENT_PROJECT_IN_STORE";
export const RESET_RETIREMENT_PROJECT_IN_STORE = "RESET_RETIREMENT_PROJECT_IN_STORE";
export const FETCH_ADDITIONAL_INCOME_OBJECTIVE = "FETCH_ADDITIONAL_INCOME_OBJECTIVE";
export const FETCH_GIVEN_DONATION_FEES = "FETCH_GIVEN_DONATION_FEES";
export const RESET_GIVEN_DONATION_FEES = "RESET_GIVEN_DONATION_FEES";
export const FETCH_CHANGE_LIFE_FUTURE_INCOMES = "FETCH_CHANGE_LIFE_FUTURE_INCOMES";
export const FETCH_CHANGE_LIFE_FUTURE_EXPENSES = "FETCH_CHANGE_LIFE_FUTURE_EXPENSES";
export const FETCH_FEASIBILITIES_AND_UPDATE_PROJECT = "FETCH_FEASIBILITIES_AND_UPDATE_PROJECT";
export const FETCH_FEASIBILITIES = "FETCH_FEASIBILITIES";
