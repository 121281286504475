import React, {FunctionComponent, useContext} from "react";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {LoansEditor} from "../components/organisms/Editors/LoansEditor/LoansEditor";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SITUATION_NET_WORTH} from "../utils/privateRoutes";
import {EditorContext, EditorContextProvider} from "../components/providers/EditorProvider";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {SGContent} from "sg-layout";
import {SGButtonGroup} from "sg-button";

const LoansPage: FunctionComponent = () => {
   const goNext = () => {
      redirectToRelativePath(SITUATION_NET_WORTH);
   };

   const LoansPageButton = () => {
      const {isEditing} = useContext(EditorContext);

      return (
         <SGButtonGroup align="center">
            <SGButtonIntl type="primary" size="md" disabled={isEditing} cypressName="loans-go-next" onClick={goNext} intlId="common.next"/>
         </SGButtonGroup>
      );
   };

   return (
      <SGContent span={8}>
         <SGTitleIntl intlId="profile.loans.page.title" cypressName="loan-page-title"/>
         <EditorContextProvider>
            <LoansEditor cypressName="profile-loans"/>
            <LoansPageButton/>
         </EditorContextProvider>
      </SGContent>
   );
};

export {LoansPage};
