import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGAlert } from "sg-alert";
import { SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGCheckBox, SGCheckBoxGroup } from "sg-checkbox";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGInputDate } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { resetAssetsIncomes } from "store/assetIncomes/actions";
import { callDashboard, setVientDeFaireReformeDA } from "store/dashboard/actions";
import { hasFetchedDashboard } from "store/dashboard/types";
import { updateFamilyAction } from "store/members/actions";
import {
    CustodyType,
    Family,
    FamilyStatus,
    MaritalStatus,
    MatrimonialRegime,
    Me,
    Member,
    RetirementCategory,
    RetirementCategorySimulation,
} from "store/members/types";
import { isIsolatedParentStatus } from "store/members/utils";
import { savePersonalInformations } from "store/personalInformations/action";
import { PersonalInformationsState } from "store/personalInformations/types";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { callRetirementSimulation, updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters, RetirementSimulationParametersState, SituationsHandicapEnum } from "store/simulationAssu/types";
import { State } from "store/store";
import { saveWorkIncomesHistoryAction } from "store/workIncomesHistory/action";
import { WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { DetailTab } from "website/components/atoms/DetailTab/DetailTab";
import { Loader } from "website/components/atoms/Loader/Loader";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { ChildrenCounterAssu } from "website/components/molecules/ChildrenCounterAssu/ChildrenCounterAssu";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import { getDate } from "website/utils/date/DateUtils";
import { DASHBOARD } from "website/utils/privateRoutes";

export const ReformeDepartsAnticipes: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const family = useFamily();
    const history = useHistory();
    const { showDetailFonctionnaire, setShowDetailFonctionnaire } = useContext(DetailTabContext);
    const { trackClick } = useTracking();

    const dashboardState = useDashboardState();
    const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const personalInformationsState: PersonalInformationsState = useSelector<State, PersonalInformationsState>((state) => state.personalInformations);
    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );
    const minCarrierStartDate = new Date(new Date(family.me.birthday).getFullYear() + 16);

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const isVisible = useMediaQuery({ maxwidth: "xl"});

    const [numberChild, setNumberChild] = useState<number>(0);
    const [retirementType, setRetirementType] = useState<RetirementCategory>();
    const [categorieActive, setCategorieActive] = useState<CategorieActiveChoix>(CategorieActiveChoix.NON);
    const [carrierStartDate, setCarrierStartDate] = useState<Date>();
    const [showLoader, setShowLoader] = useState(false);

    const retirementTypeValues: RetirementCategory[] = Object.values(RetirementCategory);
    const optionCategorieActiveChoix: CategorieActiveChoix[] = Object.values(CategorieActiveChoix);

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const { handleSubmit, formState } = methods;

    useEffect(() => {
        setNumberChild(
            family.children.filter(
                (child) =>
                    child.status === FamilyStatus.CHILD && (child.custodyType === CustodyType.FULL_CUSTODY || child.custodyType === CustodyType.FULL_CUSTODY_ME)
            ).length
        );
        if (family.me.retirementType) {
            setRetirementType(family.me.retirementType);
        }
        if (workIncomesHistoryState.workIncomesHistory.carrierStartDate) {
            // La valeur venant du back est au format string...
            setCarrierStartDate(new Date(workIncomesHistoryState.workIncomesHistory.carrierStartDate));
        }
    }, []);

    // Permet de revalider la valeur de l'input suite à l'utilisation d'un Controller
    useEffect(() => {
        methods.setValue("childrenCounter", numberChild, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [numberChild]);

    useEffect(() => {
        methods.setValue("retirementCategories", retirementType, {
            shouldValidate: formState.submitCount > 0,
        });
        setShowDetailFonctionnaire(retirementType === RetirementCategory.FONCTIONNAIRE);
    }, [retirementType]);

    useEffect(() => {
        methods.setValue("carrierStartDate", carrierStartDate, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [carrierStartDate]);

    useEffect(() => {
        methods.setValue("categorieActive", categorieActive, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [categorieActive, retirementType]);

    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    const onSubmit = () => {
        if (!carrierStartDate) {
            return;
        }
        trackClick("mise-a-jour-donnees-departs-anticipes::clic-sur-mettre-a-jour-mes-donnees");

        if (family.me.retirementType !== retirementType || family.children.length !== numberChild) {
            // Persistance du nombre d'enfants et du statut
            const newMe: Me = {
                ...family.me,
                status: FamilyStatus.ME,
                retirementType,
                matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS,
            };
            const familyToDispatch: Family = {
                children: [],
                me: newMe,
                relatives: [],
            };
            let ordinal = 0;
            for (let i = 0; i < numberChild; i += 1) {
                ordinal += 1;
                const custodyType =
                    isIsolatedParentStatus(newMe.maritalStatus) || newMe.maritalStatus === MaritalStatus.WIDOWER
                        ? CustodyType.FULL_CUSTODY_ME
                        : CustodyType.FULL_CUSTODY;
                const child: Member = {
                    name: `Mon enfant ${ordinal}`,
                    status: FamilyStatus.CHILD,
                    custodyType,
                } as Member;
                familyToDispatch.children.push(child);
            }
            dispatch(updateFamilyAction(familyToDispatch, true, true));
        }

        // Persistance de la date de debut de carriere
        // FIXME Décalage de 2h retirant 1 jour dans le toString => ajout de 12h en paliatif temporaire
        const startDate = new Date(carrierStartDate.getFullYear(), carrierStartDate.getMonth(), 1, 12);
        if (
            !workIncomesHistoryState.workIncomesHistory.carrierStartDate ||
            new Date(workIncomesHistoryState.workIncomesHistory.carrierStartDate).toDateString() !== startDate.toDateString()
        ) {
            const workIncomesHistoryToSave: WorkIncomesHistory = {
                ...workIncomesHistoryState.workIncomesHistory,
                carrierStartDate: startDate,
            };
            dispatch(saveWorkIncomesHistoryAction(workIncomesHistoryToSave));
        }

        // Persistance de la catégorie active
        const isCategorieActive = categorieActive === CategorieActiveChoix.OUI;
        if (personalInformationsState.personalInformations.categorieActive !== isCategorieActive) {
            dispatch(
                savePersonalInformations({
                    ...personalInformationsState.personalInformations,
                    categorieActive: isCategorieActive
                })
            );
        }

        // retirementType obligatoire, de toute façon
        const secteurPro = RetirementCategorySimulation[retirementType ?? RetirementCategory.SALARIE_PRIVE_CADRE];
        const simulateur45Request: Partial<RetirementSimulationParameters> = {
            ...simulationAssuState.retirementSimulationParameters,
            nbEnfant: numberChild,
            secteurPro,
            salaireBrutNet:
                secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE || secteurPro === RetirementCategorySimulation.FONCTIONNAIRE ? 1 : 2,
            dateDebCarriere: getDate(startDate),
        };
        dispatch(updateRetirementSimulation(simulateur45Request));
        setShowLoader(true);
    };

    useEffect(() => {
        if (
            showLoader &&
            simulationAssuState.retirementSimulationParameters.idSimulSimple === "" &&
            simulationAssuState.retirementSimulationParameters.simulationErrorCode === undefined
        ) {
            dispatch(callRetirementSimulation(simulationAssuState.retirementSimulationParameters as RetirementSimulationParameters));
        }
    }, [showLoader, simulationAssuState.retirementSimulationParameters]);

    useEffect(() => {
        if (showLoader && !simulationAssuState.retirementSimulationParameters.isParcoursDone) {
            if (simulationAssuState.retirementSimulationParameters.idSimulSimple && simulationAssuState.retirementSimulationParameters.idSimulSimple !== "") {
                // après récupération des données de sapiendo, on met à jour le booléen isParcoursDone pour permettre le débranchement sur le dashboard
                dispatch(updateRetirementSimulation({ ...simulationAssuState.retirementSimulationParameters, isParcoursDone: true }));
                dispatch(resetAssetsIncomes());
                dispatch(callDashboard());
            } else if (simulationAssuState.retirementSimulationParameters.simulationErrorCode !== undefined) {
                // Cas d'erreur. Annulation
                setShowLoader(false);
            }
        }
    }, [simulationAssuState.retirementSimulationParameters]);

    useEffect(() => {
        // Dashboard mis à jour APRES avoir reçu la nouvelle simulation
        if (hasFetchedDashboard(dashboardState) && simulationAssuState.retirementSimulationParameters.isParcoursDone) {
            dispatch(updateRetirementSimulation({ ...simulationAssuState.retirementSimulationParameters, isParcoursDone: false }));
            dispatch(setVientDeFaireReformeDA(true));
            history.push(DASHBOARD);
        }
    }, [dashboardState]);

    return (
        <SGCard disableautomargin shadow={false}>
            <SGGridRow>
                <SGGridCol span={12} md={7}>
                    <SGBox margin={{ top: "lg", bottom: "lg", left: "xs" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <SGGridRow gutter={[0, 32]}>
                                    <SGGridCol textalign="left" span={12}>
                                        <SGSpace direction="vertical" disableautomargin size="lg">
                                            <SGTitle key="title-1" level={1} visuallevel={3}>
                                                {intl.formatMessage({ id: "reformeDepartAnticipe.title" })}
                                            </SGTitle>
                                            <SGTitle key="title-2" level={2} visuallevel={5}>
                                                {intl.formatMessage({ id: "reformeDepartAnticipe.subtitle" })}
                                            </SGTitle>
                                        </SGSpace>
                                    </SGGridCol>
                                    <SGGridCol textalign="left" span={12}>
                                        <SGGridRow gutter={[0, 10]}>
                                            <SGGridCol span={12}>
                                                <ChildrenCounterAssu
                                                    counter={numberChild}
                                                    setCounter={setNumberChild}
                                                    name="childrenCounter"
                                                />
                                            </SGGridCol>
                                        </SGGridRow>
                                        <SGGridRow gutter={[0, 20]}>
                                            <SGGridCol span={12}>
                                                <Controller
                                                    control={methods.control}
                                                    name="retirementCategories"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ onChange }) => (
                                                        <SGSelect
                                                            placeholder="Sélectionnez"
                                                            value={retirementType || ""}
                                                            size={!isVisible ? "xl" : "m"}
                                                            onChange={(value: RetirementCategory) => {
                                                                onChange(value);
                                                                setRetirementType(value);
                                                                if (value !== RetirementCategory.FONCTIONNAIRE) {
                                                                    setCategorieActive(CategorieActiveChoix.NON);
                                                                }
                                                            }}
                                                            label={
                                                                <SGText>
                                                                    <SGTextIntl intlId="tunnel.situation.situationQuestion" size="l" />
                                                                </SGText>
                                                            }
                                                            status={methods?.errors?.retirementCategories && "error"}
                                                            validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                        >
                                                            <SGSelectOption value="" style={{ display: "none" }}>
                                                                Sélectionnez
                                                            </SGSelectOption>
                                                            {retirementTypeValues.map((retirementCategory: RetirementCategory) => (
                                                                <SGSelectOption key={retirementCategory} value={retirementCategory}>
                                                                    {intl.formatMessage({
                                                                        id: `retirementType.${retirementCategory}`,
                                                                    })}
                                                                </SGSelectOption>
                                                            ))}
                                                        </SGSelect>
                                                    )}
                                                />
                                            </SGGridCol>
                                        </SGGridRow>
                                        {retirementType === RetirementCategory.FONCTIONNAIRE && (
                                            <>
                                                <SGGridRow gutter={[0, 20]}>
                                                    <SGGridCol span={12}>
                                                        <Controller
                                                            control={methods.control}
                                                            name="categorieActive"
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({ onChange }) => (
                                                                <SGSelect
                                                                    placeholder="Sélectionnez"
                                                                    value={categorieActive || []}
                                                                    size={!isVisible ? "xl" : "m"}
                                                                    onChange={(value: CategorieActiveChoix) => {
                                                                        onChange(value);
                                                                        setCategorieActive(value);
                                                                    }}
                                                                    label={
                                                                        <SGText>
                                                                            <SGTextIntl
                                                                                intlId="simulateurAvantApres.situation.question.categorieActive"
                                                                                size="l"
                                                                            />
                                                                            <InfoTooltipAssu
                                                                                text="simulateurAvantApres.situation.question.categorieActive.tooltip"
                                                                                placement={isPhone ? "bottomLeft" : "right"}
                                                                                transformations={{
                                                                                    b: (word: string) => <SGText weight="bold">{word}</SGText>,
                                                                                    linebreak: <br />,
                                                                                }}
                                                                            />
                                                                        </SGText>
                                                                    }
                                                                    status={methods?.errors?.categorieActive && "error"}
                                                                    validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                                >
                                                                    {optionCategorieActiveChoix.map((value: CategorieActiveChoix) => (
                                                                        <SGSelectOption key={value} value={value}>
                                                                            {value}
                                                                        </SGSelectOption>
                                                                    ))}
                                                                </SGSelect>
                                                            )}
                                                        />
                                                    </SGGridCol>
                                                </SGGridRow>
                                            </>
                                        )}
                                        <SGGridRow>
                                            <SGGridCol span={12}>
                                                <Controller
                                                    control={methods.control}
                                                    name="carrierStartDate"
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ onChange }) => (
                                                        <SGInputDate
                                                            label={
                                                                <SGText size="l">
                                                                    {intl.formatMessage({ id: "reformeDepartAnticipe.label.carrierStart" })}
                                                                </SGText>
                                                            }
                                                            size={!isVisible ? "xl" : "m"}
                                                            dateFormat="MM/yyyy"
                                                            minDate={minCarrierStartDate}
                                                            onChange={(value: Date) => {
                                                                onChange(value);
                                                                setCarrierStartDate(value);
                                                            }}
                                                            placeholder="mm/aaaa"
                                                            showMonthYearPicker
                                                            selected={carrierStartDate}
                                                            status={methods?.errors?.carrierStartDate && "error"}
                                                            validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                                        />
                                                    )}
                                                />
                                            </SGGridCol>
                                        </SGGridRow>
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        {showLoader ? (
                                            <Loader title="loader.title" />
                                        ) : (
                                            <SGButtonGroup align="center" disableautomargin>
                                                <BoutonSupervision onClick={handleSubmit(onSubmit)}>
                                                    <FormattedMessage id="reformeDepartsAnticipes.bouton" />
                                                </BoutonSupervision>
                                            </SGButtonGroup>
                                        )}
                                    </SGGridCol>
                                </SGGridRow>
                            </form>
                        </FormProvider>
                    </SGBox>
                </SGGridCol>
                <SGGridCol span={12} md={5} align="stretch">
                    <DetailTab
                        titleDetailTab="tunnel.detail.title"
                        textDetailTab="reformeDepartAnticipe.detail.text"
                        hideListStyle={isPhone}
                        transformations={{ fonctionnaire: (word: string) => (showDetailFonctionnaire ? word : "") }}
                    />
                </SGGridCol>
            </SGGridRow>
        </SGCard>
    );
};
