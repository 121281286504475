import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {SimulationState} from "../../../store/simulation/types";
import {getGlobalSimulation} from "../../../store/simulation/actions";

const useSimulation = (forceFetch: boolean) => {
   const dispatch = useDispatch();
   const simulationState: SimulationState = useSelector<State, SimulationState>((state) => state.simulation);

   useEffect(() => {
      if (!simulationState.hasFetched || forceFetch) {
         dispatch(getGlobalSimulation());
      }
   }, []);

   return simulationState.simulation;
};

export {useSimulation};
