import {Loan} from "./types";

export const getEndDate = (loan: Loan): string => {
   if (loan.startDate && loan.durationInMonth) {
      const date = new Date(loan.startDate);
      date.setMonth(date.getMonth() + loan.durationInMonth);

      return String(date.getFullYear());
   }

   return "20XX";
};
