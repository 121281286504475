import {call, put, select, takeEvery, takeLeading} from "redux-saga/effects";
import {AxiosError, AxiosResponse} from "axios";
import {
   CREATE_PROJECT,
   CREATE_RETIREMENT_PROJECT,
   DELETE_ALL_PROJECTS,
   DELETE_PROJECT,
   FETCH_ADDITIONAL_INCOME_OBJECTIVE,
   FETCH_CHANGE_LIFE_FUTURE_EXPENSES,
   FETCH_CHANGE_LIFE_FUTURE_INCOMES,
   FETCH_FEASIBILITIES,
   FETCH_FEASIBILITIES_AND_UPDATE_PROJECT,
   FETCH_GIVEN_DONATION_FEES,
   FETCH_PROJECTS,
   FETCH_RETIREMENT_PROJECT,
   FutureExpenses,
   NewDefaultProject,
   PartialRetirementProjectValues,
   Project,
   ProjectFeasibility,
   ProjectType,
   RESET_GIVEN_DONATION_FEES,
   RESET_RETIREMENT_PROJECT_IN_STORE,
   RetirementProjectValues,
   SIMULATE_RETIREMENT_PROJECT,
   UPDATE_RETIREMENT_PROJECT,
   UPDATE_RETIREMENT_PROJECT_IN_STORE,
   UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME
} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiDelete, apiGet, apiPost, apiPut} from "../apiCaller";
import {getProjectsAction} from "./actions";
import {FamilyStatus, Member} from "../members/types";
import {getNewProjects} from "../newProjects/actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {getMembersAction} from "../members/actions";
import {
   additionalIncomeObjectiveFetched,
   allProjectsDeleted,
   changeLifeFutureExpensesFetched,
   changeLifeMyFutureIncomesFetched,
   changeLifePartnerFutureIncomesFetched,
   feasibilityError,
   feasibilityReset,
   givenDonationsFeesFetched,
   projectCreated,
   projectDeleted,
   projectFeasibilitiesFetched,
   projectsFetched,
   projectUpdated,
   resetAdditionalIncomeObjective,
   resetChangeLifeFutureExpenses,
   resetGivenDonationsFees,
   retirementProjectFetched,
   retirementProjectReset,
   retirementProjectUpdated,
   updateIsRetirementProjectAutomaticallyUpdated
} from "./projectsSlice";
import {redirectToRelativePath} from "../../website/utils/routes/routing";
import {PROJECTS} from "../../website/utils/privateRoutes";
import {getGlobalSimulation} from "../simulation/actions";
import {updateElement} from "../utils";

export function* watcherSaga() {
   yield takeLeading(FETCH_PROJECTS, safe(onDefaultError, handleGetProjects));
   yield takeLeading(CREATE_PROJECT, safe(onDefaultError, handleCreateProject));
   yield takeLeading(FETCH_RETIREMENT_PROJECT, safe(onDefaultError, handleGetRetirementProject));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT, safe(onDefaultError, handleUpdateRetirementProject));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME, safe(onDefaultError, handleUpdateRetirementProjectWithNewIncome));
   yield takeLeading(CREATE_RETIREMENT_PROJECT, safe(onDefaultError, handleCreateRetirementProject));
   yield takeLeading(SIMULATE_RETIREMENT_PROJECT, safe(onDefaultError, handleSimulateRetirementProject));
   yield takeLeading(RESET_RETIREMENT_PROJECT_IN_STORE, safe(onDefaultError, handleResetRetirementProjectInStore));
   yield takeLeading(UPDATE_RETIREMENT_PROJECT_IN_STORE, safe(onDefaultError, handleUpdateRetirementProjectInStore));
   yield takeLeading(DELETE_ALL_PROJECTS, safe(onDefaultError, handleDeleteAllProjects));
   yield takeLeading(DELETE_PROJECT, safe(onDefaultError, handleDeleteProject));
   yield takeLeading(FETCH_FEASIBILITIES, safe(onFetchFeasibilityError, handleGetProjectFeasibilities));
   yield takeLeading(FETCH_FEASIBILITIES_AND_UPDATE_PROJECT, safe(onFetchFeasibilityError, handleUpdateProjectAndGetFeasibilities));
   yield takeLeading(FETCH_ADDITIONAL_INCOME_OBJECTIVE, safe(onDefaultError, handleGetAdditionalIncomeObjective));
   yield takeLeading(FETCH_GIVEN_DONATION_FEES, safe(onDefaultError, handleGetGivenDonationFees));
   yield takeLeading(RESET_GIVEN_DONATION_FEES, safe(onDefaultError, handleResetGivenDonationFees));
   yield takeEvery(FETCH_CHANGE_LIFE_FUTURE_INCOMES, safe(onDefaultError, handleGetChangeLifeFutureIncomes));
   yield takeEvery(FETCH_CHANGE_LIFE_FUTURE_EXPENSES, safe(onDefaultError, handleGetChangeLifeFutureExpenses));
}

function* handleGetProjects() {
   const payload: AxiosResponse<Project[]> = yield call(apiGet, `wealth/api/projects`);
   yield put(projectsFetched(payload.data));
}

function* handleCreateProject(action: PayloadAction<NewDefaultProject>) {
   let projectApiUrl;
   if (action.payload.projectType === ProjectType.GIVEN_DONATION) {
      projectApiUrl = `wealth/api/projects/given-donation`;
   } else if (action.payload.projectType === ProjectType.CHANGE_LIFE_PROJECT) {
      projectApiUrl = `wealth/api/projects/change-life`;
   } else {
      projectApiUrl = `wealth/api/projects`;
   }

   const payload: AxiosResponse<Project> = yield call(apiPost, projectApiUrl, action.payload);
   redirectToRelativePath(`${PROJECTS}?currentProject=${payload.data.id}`);

   yield put(projectCreated(payload.data));

   if (action.payload.projectType === ProjectType.CHILDREN_EDUCATION || action.payload.projectType === ProjectType.GIVEN_DONATION) {
      yield put(getMembersAction());
   }

   yield put(getNewProjects());
}

function* handleCreateRetirementProject() {
   const retirementProject: RetirementProjectValues = yield select((state) => state.projects.retirementProject);
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiPost, `wealth/api/projects/retirement?persist=true`, retirementProject);
   yield put(getNewProjects());
   redirectToRelativePath(`${PROJECTS}?currentProject=${payload.data.projectId}`);
   yield put(getProjectsAction());
   yield put(getMembersAction());
}

function* handleUpdateRetirementProject(action: PayloadAction<RetirementProjectValues>) {
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiPut, `wealth/api/projects/retirement?persist=true`, action.payload);
   yield put(retirementProjectUpdated(payload.data));
}

function* handleSimulateRetirementProject(action: PayloadAction<Partial<PartialRetirementProjectValues>>) {
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiPost, `wealth/api/projects/retirement`, action.payload);
   yield put(retirementProjectUpdated(payload.data));
}

function* handleResetRetirementProjectInStore() {
   yield put(retirementProjectReset());
}

function* handleUpdateRetirementProjectInStore(action: PayloadAction<RetirementProjectValues>) {
   yield put(retirementProjectUpdated(action.payload));
}

function* handleGetRetirementProject(action: PayloadAction<{
   retirementProjectId: number,
   simulate: boolean,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined
}>) {
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiGet, `wealth/api/projects/retirement/${action.payload.retirementProjectId}`);
   if (action.payload.simulate) {
      // Dans le cas ou le call demande une simulation en plus pour récupérer les estimations de salaire à la retraite en fonction de l'âge de la retraite
      const retirementSimulation: AxiosResponse<RetirementProjectValues> = yield call(
         apiPost,
         `wealth/api/projects/retirement`,
         {
            ...payload.data,
            myRetirement: {
               birthdate: payload.data.myRetirement.birthdate,
               currentMonthlyIncome: action.payload.myCurrentIncome,
               salaryEvolutionLevel: payload.data.myRetirement.salaryEvolutionLevel,
               type: payload.data.myRetirement.type,
               retirementYearToRetirementIncomeMap: {},
               retirementYearToIncomeBeforeRetirementMap: {}
            },
            partnerRetirement: payload.data.partnerRetirement
               ? {
                  birthdate: payload.data.partnerRetirement.birthdate,
                  currentMonthlyIncome: action.payload.partnerCurrentIncome,
                  salaryEvolutionLevel:
                  payload.data.partnerRetirement.salaryEvolutionLevel,
                  type: payload.data.partnerRetirement.type,
                  retirementYearToRetirementIncomeMap: {},
                  retirementYearToIncomeBeforeRetirementMap: {}
               }
               : undefined,
            objective: undefined
         });

      payload.data.myRetirement.retirementYearToRetirementIncomeMap = retirementSimulation.data.myRetirement.retirementYearToRetirementIncomeMap;
      if (payload.data.partnerRetirement) {
         payload.data.partnerRetirement.retirementYearToRetirementIncomeMap = retirementSimulation.data.partnerRetirement.retirementYearToRetirementIncomeMap;
      }
   }
   yield put(retirementProjectFetched(payload.data));
}

function* handleUpdateRetirementProjectWithNewIncome(action: PayloadAction<{
   retirementProjectId: number,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined,
}>) {
   const payload: AxiosResponse<RetirementProjectValues> = yield call(apiGet, `wealth/api/projects/retirement/${action.payload.retirementProjectId}`);
   const retirementSimulation: AxiosResponse<RetirementProjectValues> = yield call(
      apiPost,
      `wealth/api/projects/retirement`,
      {
         myRetirement: {
            memberId: payload.data.myRetirement.memberId,
            birthdate: payload.data.myRetirement.birthdate,
            currentMonthlyIncome: action.payload.myCurrentIncome,
            salaryEvolutionLevel: payload.data.myRetirement.salaryEvolutionLevel,
            type: payload.data.myRetirement.type,
            retirementYearToRetirementIncomeMap: {},
            retirementYearToIncomeBeforeRetirementMap: {}
         },
         partnerRetirement: payload.data.partnerRetirement
            ? {
               memberId: payload.data.partnerRetirement.memberId,
               birthdate: payload.data.partnerRetirement.birthdate,
               currentMonthlyIncome: action.payload.partnerCurrentIncome,
               salaryEvolutionLevel: payload.data.partnerRetirement.salaryEvolutionLevel,
               type: payload.data.partnerRetirement.type,
               retirementYearToRetirementIncomeMap: {},
               retirementYearToIncomeBeforeRetirementMap: {}
            }
            : undefined
      });

   const updatedRetirementProject: AxiosResponse<RetirementProjectValues> = yield call(
      apiPost,
      `wealth/api/projects/retirement?persist=true`,
      {
         myRetirement: {
            memberId: payload.data.myRetirement.memberId,
            birthdate: payload.data.myRetirement.birthdate,
            memberRetirementDate: payload.data.myRetirement.memberRetirementDate,
            retirementIncomes: [{
               name: "Retirement Income",
               income: retirementSimulation.data.myRetirement.retirementYearToRetirementIncomeMap[new Date(payload.data.myRetirement.memberRetirementDate).getFullYear() - new Date(payload.data.myRetirement.birthdate).getFullYear()]
            }],
            currentMonthlyIncome: action.payload.myCurrentIncome,
            salaryEvolutionLevel: payload.data.myRetirement.salaryEvolutionLevel,
            type: payload.data.myRetirement.type,
            retirementYearToRetirementIncomeMap: {},
            retirementYearToIncomeBeforeRetirementMap: {}
         },
         partnerRetirement: payload.data.partnerRetirement
            ? {
               memberId: payload.data.partnerRetirement.memberId,
               birthdate: payload.data.partnerRetirement.birthdate,
               memberRetirementDate: payload.data.partnerRetirement.memberRetirementDate,
               retirementIncomes: [{
                  name: "Retirement Income",
                  income: retirementSimulation.data.partnerRetirement.retirementYearToRetirementIncomeMap[new Date(payload.data.partnerRetirement.memberRetirementDate).getFullYear() - new Date(payload.data.partnerRetirement.birthdate).getFullYear()]
               }],
               currentMonthlyIncome: action.payload.partnerCurrentIncome,
               salaryEvolutionLevel: payload.data.partnerRetirement.salaryEvolutionLevel,
               type: payload.data.partnerRetirement.type,
               retirementYearToRetirementIncomeMap: {},
               retirementYearToIncomeBeforeRetirementMap: {}
            }
            : undefined
      });

   yield put(getProjectsAction());
   yield put(retirementProjectFetched(updatedRetirementProject.data));
   yield put(updateIsRetirementProjectAutomaticallyUpdated(true));
}

function* handleDeleteAllProjects() {
   yield call(apiDelete, `wealth/api/projects/`);
   yield put(allProjectsDeleted());
   yield put(getProjectsAction());
   yield put(getNewProjects());
}

function* handleDeleteProject(action: PayloadAction<Project>) {
   yield call(apiDelete, `wealth/api/projects/${action.payload.id}`);
   yield put(projectDeleted(action.payload));
   yield put(getNewProjects());
}

function* handleGetProjectFeasibilities() {
   yield put(feasibilityReset());
   const payload: AxiosResponse<ProjectFeasibility[]> = yield call(apiPost, `wealth/api/projects/feasibility/`);
   yield put(projectFeasibilitiesFetched(payload.data));
   yield put(getGlobalSimulation());
}

function* handleUpdateProjectAndGetFeasibilities(action: PayloadAction<Project>) {
   yield put(feasibilityReset());
   const project : AxiosResponse<Project> = yield call(apiPut, `wealth/api/projects`, action.payload);
   yield put(projectUpdated(project.data))

   const payload: AxiosResponse<ProjectFeasibility[]> = yield call(apiPost, `wealth/api/projects/feasibility/`);
   yield put(projectFeasibilitiesFetched(payload.data));
   yield put(getGlobalSimulation());
}

function* handleGetAdditionalIncomeObjective(action: PayloadAction<{ amount: number, duration: number }>) {
   yield put(resetAdditionalIncomeObjective());
   const payload: AxiosResponse<{ totalObjective: number }> = yield call(
      apiGet,
      `wealth/api/simulation/objective/additional-income?duration=${action.payload.duration}&estimatedExpenses=${action.payload.amount}`
   );
   yield put(additionalIncomeObjectiveFetched(payload.data.totalObjective));
}

function* handleGetGivenDonationFees(action: PayloadAction<{ amount: number, horizon: number, concernedMember: Partial<Member> }>) {
   yield put(resetGivenDonationsFees());
   const payload: AxiosResponse<{ feesAmountPerHeir: number }> = yield call(
      apiPost,
      `wealth/api/projects/given-donation/compute-fees`,
      {
         amount: action.payload.amount,
         horizon: action.payload.horizon,
         concernedMember: action.payload.concernedMember,
         memberId: action.payload.concernedMember.id
            ? action.payload.concernedMember.id
            : null,
         given: true,
         fees: 0,
         name: `Transmission à ${action.payload.concernedMember.name}`,
         assetsAllocations: []
      }
   );
   yield put(givenDonationsFeesFetched(payload.data.feesAmountPerHeir));
}

function* handleResetGivenDonationFees() {
   yield put(resetGivenDonationsFees());
}

function* handleGetChangeLifeFutureIncomes(action: PayloadAction<{ currentIncome: number, horizon: number, concernedMember: Partial<Member> }>) {
   const payload: AxiosResponse<{ futureIncome: number }> = yield call(
      apiGet,
      `wealth/api/projects/change-life/estimate/situation?currentAmount=${action.payload.currentIncome}&horizon=${action.payload.horizon}&memberId=${action.payload.concernedMember.id}`
   );
   if (action.payload.concernedMember.status === FamilyStatus.ME) {
      yield put(changeLifeMyFutureIncomesFetched(payload.data.futureIncome));
   } else {
      yield put(changeLifePartnerFutureIncomesFetched(payload.data.futureIncome));
   }
}

function* handleGetChangeLifeFutureExpenses(action: PayloadAction<{
   currentIncomes: Array<{ memberId: number | undefined, expectedIncome: number }>,
   horizon: number
}>) {
   yield put(resetChangeLifeFutureExpenses());
   const payload: AxiosResponse<FutureExpenses> = yield call(apiPost, `wealth/api/projects/change-life/estimate/expenses`, action.payload);
   yield put(changeLifeFutureExpensesFetched(payload.data));
}

function* onFetchFeasibilityError(err: AxiosError) {
   if (err.response?.status !== 200) {
      yield put(feasibilityError());
   } else {
      onDefaultError(err);
   }
}
