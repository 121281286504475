import Slider from "rc-slider";
import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGSelectOption } from "sg-select";
import { SGBlock } from "sg-typo";
import { IncomeType } from "../../../../../store/incomes/types";
import { getIncome } from "../../../../../store/incomes/utils";
import { calculateAgeByBirthdate, getMembersOfCouple, MAX_AGE_FOR_CHANGE_LIFE } from "../../../../../store/members/utils";
import { createProjectAction, getChangeLifeFutureExpensesAction, getChangeLifeFutureIncomesAction } from "../../../../../store/projects/actions";
import { FutureExpenses, ProjectType } from "../../../../../store/projects/types";
import { State } from "../../../../../store/store";
import { convertNumberToStringWithSeparators } from "../../../../utils/formatting/numberFormatter";
import { NEW_PROJECTS } from "../../../../utils/privateRoutes";
import { redirectToRelativePath } from "../../../../utils/routes/routing";
import { ErrorElement } from "../../../atoms/ErrorElement/ErrorElement";
import { FormInputs } from "../../../atoms/FormInputs/FormInputs";
import { NumericInput } from "../../../atoms/NumericInput/NumericInput";
import { SelectInput } from "../../../atoms/SelectInput/SelectInput";
import { SGButtonIntl } from "../../../atoms/SGButtonIntl/SGButtonIntl";
import { SGTextIntl } from "../../../atoms/SGTextIntl/SGTextIntl";
import { SGTitleIntl } from "../../../atoms/SGTitleIntl/SGTitleIntl";
import { SpinnerSize, SpinnerWrapper } from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import { StringInput } from "../../../atoms/StringInput/StringInput";
import { useDebounce } from "../../../hooks/useDebounce";
import { useExpenses } from "../../../hooks/useExpenses";
import { useFamily } from "../../../hooks/useFamily";
import { useIncomes } from "../../../hooks/useIncomes";

const ChangeLifeSelectableMembers = {
   ME: "ME",
   PARTNER: "PARTNER",
   BOTH: "BOTH",
}

export type ChangeLifeSelectableMembers = typeof ChangeLifeSelectableMembers[keyof typeof ChangeLifeSelectableMembers];

interface ChangeLifeProjectFormData {
   duration: number,
   horizon: string,
   newExpense: number,
   myNewIncome: number,
   partnerNewIncome: number
}

const ChangeLifeProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const [maxHorizon, setMaxHorizon] = useState<number>(25);
   const [projectHorizon, setProjectHorizon] = useState<number>(1);
   const debouncedHorizon = useDebounce<number>(projectHorizon, 1000);
   const [selectedMembers, setSelectedMembers] = useState<ChangeLifeSelectableMembers | undefined>(undefined);
   const [myCurrentIncome, setMyCurrentIncome] = useState<number>(0);
   const [partnerCurrentIncome, setPartnerCurrentIncome] = useState<number>(0);
   const [currentExpenses, setCurrentExpenses] = useState<number>(0);

   const family = useFamily();
   const incomes = useIncomes();
   const expenses = useExpenses();

   const hasFetchedExpensesAndIncomes = useSelector<State, boolean>((state) => state.incomes.hasFetched && state.expenses.hasFetchedExpenses);
   const hasFetchedFamily = useSelector<State, boolean>((state) => state.family.hasFetched);

   const myFutureIncome: number = useSelector<State, number>((state) => state.projects.changeLifeMyFutureIncome);
   const partnerFutureIncome: number = useSelector<State, number>((state) => state.projects.changeLifePartnerFutureIncome);
   const futureExpenses: FutureExpenses | undefined = useSelector<State, FutureExpenses | undefined>((state) => state.projects.changeLifeFutureExpenses);

   useEffect(() => {
      if (hasFetchedFamily) {
         if (!family.partner) {
            setSelectedMembers(ChangeLifeSelectableMembers.ME);
         }

         getMembersOfCouple(family).forEach((m) => {
            if (m.birthday && maxHorizon > MAX_AGE_FOR_CHANGE_LIFE - calculateAgeByBirthdate(m.birthday)) {
               setMaxHorizon(MAX_AGE_FOR_CHANGE_LIFE + 1 - calculateAgeByBirthdate(m.birthday));
            }
         });
      }
   }, [family, hasFetchedFamily]);

   useEffect(() => {
      if (hasFetchedFamily && hasFetchedExpensesAndIncomes) {
         updateIncomes(debouncedHorizon);
      }
   }, [hasFetchedFamily, hasFetchedExpensesAndIncomes, selectedMembers, debouncedHorizon]);

   useEffect(() => {
      if (hasFetchedFamily && hasFetchedExpensesAndIncomes) {
         const myIncome = getIncome(incomes, IncomeType.WORK_INCOME, family.me);
         setMyCurrentIncome(myIncome !== undefined ? myIncome.monthlyAmount : 0);
         if (family.partner) {
            const partnerIncome = getIncome(incomes, IncomeType.WORK_INCOME, family.partner);
            setPartnerCurrentIncome(partnerIncome !== undefined ? partnerIncome.monthlyAmount : 0);
         }
         setCurrentExpenses(expenses.filter((e) => e.type === ProjectType.EXPENSES)[0]?.monthlyAmount);
      }
   }, [hasFetchedFamily, hasFetchedExpensesAndIncomes, family, incomes, expenses]);

   useEffect(() => {
      if (hasFetchedFamily && hasFetchedExpensesAndIncomes) {
         const incomes = [];
         if (myFutureIncome !== undefined) {
            incomes.push({memberId: family.me.id, expectedIncome: myFutureIncome});
            methods.setValue("myFutureIncome", myFutureIncome);
            methods.setValue("myNewIncome", myFutureIncome);

            if (partnerFutureIncome !== undefined && family.partner) {
               incomes.push({memberId: family.partner?.id, expectedIncome: partnerFutureIncome});
               methods.setValue("partnerFutureIncome", partnerFutureIncome);
               methods.setValue("partnerNewIncome", partnerFutureIncome);
            }
         }

         dispatch(getChangeLifeFutureExpensesAction(incomes, new Date().getFullYear() + Number(projectHorizon)));
      }
   }, [myFutureIncome, partnerFutureIncome, family])

   useEffect(() => {
      if (futureExpenses !== undefined) {
         methods.setValue("futureExpense", futureExpenses.common, {shouldValidate: true, shouldDirty: true});
         methods.setValue("newExpense", futureExpenses.common, {shouldValidate: true, shouldDirty: true});
         methods.setValue("otherExpense", futureExpenses.other, {shouldValidate: true, shouldDirty: true});
         methods.setValue("totalExpense", futureExpenses.total, {shouldValidate: true, shouldDirty: true});
      }
   }, [futureExpenses]);

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   const onHorizonChange = (horizon: number) => {
      setProjectHorizon(horizon);
   };

   useEffect(() => {
      if (hasFetchedFamily && hasFetchedExpensesAndIncomes && !!projectHorizon) {
         updateIncomes(projectHorizon);
      }
   }, [debouncedHorizon]);

   const onMembersChange = (newChangeLifeMembers: string) => {
      setSelectedMembers(newChangeLifeMembers);
   };

   const onExpenseChange = () => {
      methods.setValue(
         "totalExpense",
         Number(methods.getValues("newExpense")) + Number(methods.getValues("otherExpense")), {shouldValidate: true, shouldDirty: true});
   };

   /* Fonction qui appelle le back pour calculer les futurs revenus des membres */
   const updateIncomes = (horizon: number) => {
      if (methods.formState.isValid) {
         const myIncome = getIncome(incomes, IncomeType.WORK_INCOME, family.me);
         dispatch(getChangeLifeFutureIncomesAction(myIncome !== undefined ? myIncome.monthlyAmount : 0, new Date().getFullYear() + Number(horizon), family.me));
         if (family.partner) {
            const partnerIncome = getIncome(incomes, IncomeType.WORK_INCOME, family.partner);
            dispatch(getChangeLifeFutureIncomesAction(partnerIncome !== undefined ? partnerIncome.monthlyAmount : 0, new Date().getFullYear() + Number(horizon), family.partner));
         }
      }
   };

   const goToNewProject = () => {
      redirectToRelativePath(NEW_PROJECTS);
   };

   const onSubmit = (data: ChangeLifeProjectFormData) => {
      const incomes = [];
      if (selectedMembers === ChangeLifeSelectableMembers.ME || selectedMembers === ChangeLifeSelectableMembers.BOTH) {
         incomes.push({
            memberId: family.me.id,
            futureIncome: data.myNewIncome,
         });
      }
      if (selectedMembers === ChangeLifeSelectableMembers.PARTNER || selectedMembers === ChangeLifeSelectableMembers.BOTH) {
         incomes.push({
            memberId: family.partner?.id,
            futureIncome: data.partnerNewIncome,
         });
      }

      dispatch(
         createProjectAction({
            durationInMonths: data.duration,
            futureExpenses: data.newExpense,
            futureIncomes: incomes,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectType: ProjectType.CHANGE_LIFE_PROJECT,
            projectName: "Mon changement de vie",
            totalFutureIncome: null,
         })
      );
   };

   return (
      <SpinnerWrapper displayComponent={hasFetchedFamily && hasFetchedExpensesAndIncomes} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         {hasFetchedFamily && hasFetchedExpensesAndIncomes && (
            <>
               <SGTitleIntl intlId="project.change-life.title" cypressName="project-change-life-title"/>
               <SGTextIntl intlId="project.change-life.subtitle"/>
               <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                     <SGCard>
                        <FormInputs colLength={10}>
                           <SGTextIntl intlId="project.change-life.form.horizon"/>
                           <SGGridRow align="middle">
                              <SGGridCol span={9} xs={7}>
                                 {/* @ts-ignore */}
                                 <Slider min={1} max={maxHorizon} value={projectHorizon} onChange={(value) => {
                                    setProjectHorizon(value);
                                    methods.setValue("horizon", value, {
                                       shouldValidate: true,
                                    });
                                 }} onAfterChange={(value) =>
                                    onHorizonChange(Number(value))
                                 } railStyle={{height: 9}} handleStyle={{
                                    height: 20,
                                    width: 20,
                                    marginTop: -6,
                                 }} trackStyle={{height: 9}} step={1}/>
                              </SGGridCol>
                              <SGGridCol span={3} xs={5}>
                                 <NumericInput cypressName="change-life-project-horizon-input" suffix="common.years" name="horizon" min={1} max={maxHorizon}
                                               placeholder={intl.formatMessage({id: "project.change-life.form.amount.placeholder"})}
                                               required={false} defaultValue={projectHorizon} onValueChange={onHorizonChange}/>
                              </SGGridCol>
                           </SGGridRow>
                           <SGTextIntl intlId="project.change-life.form.horizon.info"/>

                           <StringInput label="project.change-life.form.name" cypressName="change-life-project-name-input"
                                        defaultValue="Mon changement de vie" name="name"/>
                           {family.partner && (
                              <SelectInput name="member" required onValueChange={onMembersChange}
                                           placeholder={intl.formatMessage({id: "project.change-life.form.member.select"})}
                                           label="project.change-life.form.member"
                                           cypressName="change-life-project-member-input">
                                 {Object.values(ChangeLifeSelectableMembers).map(
                                    (member: ChangeLifeSelectableMembers) => (
                                       <SGSelectOption key={member} data-cy={`change-life-project-member-input-select-${member}`} value={member}>
                                          {intl.formatMessage({id: `project.change-life.form.member.${member}`})}
                                       </SGSelectOption>
                                    )
                                 )}
                              </SelectInput>
                           )}
                        </FormInputs>
                     </SGCard>
                     {selectedMembers !== undefined && (
                        <SGCard data-cy="change-life-project-income-expense-frame">
                           <SGTextIntl intlId="project.change-life.projection.title"/>
                           <FormInputs colLength={10}>

                              <NumericInput cypressName="change-life-project-my-future-income" name="myFutureIncome"
                                            defaultValue={myFutureIncome} suffix="common.euro.per.month" disabled placeholder=""
                                            label="project.change-life.projection.my-future-income"/>
                              <SGTextIntl intlId="project.change-life.actual.incomes"
                                          transformations={{amount: convertNumberToStringWithSeparators(myCurrentIncome)}}/>
                              {family.partner && (<>
                                 <NumericInput cypressName="change-life-project-partner-future-income" name="partnerFutureIncome"
                                               defaultValue={partnerFutureIncome}
                                               suffix={intl.formatMessage({id: "common.euro.per.month"})} disabled placeholder=""
                                               label="project.change-life.projection.partner-future-income"/>
                                 <SGTextIntl intlId="project.change-life.actual.incomes"
                                             transformations={{amount: convertNumberToStringWithSeparators(partnerCurrentIncome)}}/>
                              </>)}
                              <NumericInput cypressName="change-life-project-future-expense" name="futureExpense" placeholder=""
                                            defaultValue={futureExpenses?.common}
                                            suffix={intl.formatMessage({id: "common.euro.per.month"})} disabled
                                            label="project.change-life.projection.expenses"/>
                              <SGTextIntl intlId="project.change-life.actual.expenses" transformations={{
                                 amount:
                                    convertNumberToStringWithSeparators(
                                       currentExpenses
                                    ),
                              }}/>
                              <SGBlock>
                                 <SGTextIntl intlId="project.change-life.goal.title"/>
                              </SGBlock>
                              <NumericInput cypressName="change-life-project-my-new-income" min={0} name="myNewIncome"
                                            defaultValue={myFutureIncome} max={100_000} label="project.change-life.goal.my-new-income"
                                            suffix={intl.formatMessage({id: "common.euro.per.month"})}
                                            placeholder={intl.formatMessage({id: "project.change-life.goal.my-new-income.placeholder"})}
                                            disabled={selectedMembers === ChangeLifeSelectableMembers.PARTNER}/>
                              {family.partner && (
                                 <NumericInput cypressName="change-life-project-partner-new-income" min={0} name="partnerNewIncome"
                                               label="project.change-life.goal.partner-new-income"
                                               defaultValue={partnerFutureIncome}
                                               placeholder={intl.formatMessage({id: "project.change-life.goal.partner-new-income.placeholder"})}
                                               suffix={intl.formatMessage({id: "common.euro.per.month"})} max={100_000}
                                               disabled={selectedMembers === ChangeLifeSelectableMembers.ME}/>
                              )}
                              <NumericInput cypressName="change-life-project-future-new-expense" onValueChange={() => onExpenseChange()} name="newExpense"
                                            placeholder={intl.formatMessage({id: "project.change-life.goal.new-expenses.placeholder"})}
                                            defaultValue={futureExpenses?.common} min={0} suffix={intl.formatMessage({id: "common.euro.per.month"})}
                                            label="project.change-life.goal.new-expenses"/>
                              <NumericInput cypressName="change-life-project-future-other-expense" disabled name="otherExpense" placeholder=""
                                            label="project.change-life.goal.other-expenses" tooltipLabel="project.change-life.goal.other-expenses.tooltip"
                                            defaultValue={futureExpenses?.other} suffix={intl.formatMessage({id: "common.euro.per.month"})}/>
                              <NumericInput cypressName="change-life-project-future-total-expense" disabled name="totalExpense" placeholder=""
                                            defaultValue={futureExpenses?.total} suffix={intl.formatMessage({id: "common.euro.per.month"})}
                                            label="project.change-life.goal.total-expenses"/>
                           </FormInputs>
                        </SGCard>
                     )}

                     <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

                     <SGButtonGroup align="center" layout="column">
                        <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="change-life-next"
                                      onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                        <SGButtonIntl type="link" cypressName="change-life-previous" onClick={goToNewProject} intlId="common.previous"/>
                     </SGButtonGroup>
                  </form>
               </FormProvider>
            </>
         )}
      </SpinnerWrapper>
   );
};

export { ChangeLifeProject };
