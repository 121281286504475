import moment from "moment";
import {
  MAX_AGE_TO_RETIRE,
  MIN_AGE_FOR_ME_OR_PARTNER,
  calculateAgeByBirthdate,
} from "../../../store/members/utils";

export function utcDate(localDate: Date): Date {
  return new Date(
    Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())
  );
}

export function toddMMyyyyStringWithSlash(date: Date): string {
  return new Intl.DateTimeFormat("fr-FR").format(new Date(date));
}

export function yyyyMmDdToDate(date: string): Date {
  const regExp = /(\d{4})(\d{2})(\d{2})/;
  const parts = regExp.exec(date);
  if (parts) {
    return new Date(
      parseInt(parts[1]),
      parseInt(parts[2]) - 1,
      parseInt(parts[3])
    );
  }

  return new Date();
}

export function isOlderThanMaxRetirementAge(birthDate: Date): boolean {
  if (!birthDate) {
    return false;
  }

  return calculateAgeByBirthdate(birthDate) >= MAX_AGE_TO_RETIRE;
}

export function getMaxDateForMyBirthdate(): string {
  return `${new Date().getFullYear() - MIN_AGE_FOR_ME_OR_PARTNER}-${String(
    new Date().getMonth() + 1
  ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`;
}

export function getMinDateForMyBirthdate(): string {
  return `${new Date().getFullYear() - MAX_AGE_TO_RETIRE}-${String(
    new Date().getMonth() + 1
  ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`;
}

/**
 * Transforme une date vers un string dd-mm-yyyy
 * @param date Date
 * @returns String
 */
export function getDate(date: Date): string {
  return `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;
}

/**
 * Transforme une date française en date SQL
 * @param dateString Date française en string
 */
export function dateFRToSQL(dateString: string): string {
  const dateSplit = dateString.split(/\//);

  return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
}

export const formatDateSimulation = (date: Date) => {
  const day = date.getDate() ? date.getDate() : 15;
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? `0${day}` : day}-${month}-${year}`;
};

/**
 * Formatage MM-DD-YY => DD/MM/YY
 * @param date date
 * @param separateur separateur
 * @returns une date
 */
export function formatDate(date: string, separateur: string) {
  const [mois, jour, annee] = date.split(separateur);

  return new Date(`${jour}/${mois}/${annee}`);
}

/**
 * Conversion d'une date de type string en moment.
 * @param date date
 * @param separateur separateur
 * @returns une date de type moment
 */
export function convertStringToMoment(date: string, separateur: string) {
  const [, mois, annee] = date.split(separateur);

  return moment([annee, mois, 1]);
}

/**
 * Calcule la durée en année et en mois entre 2 dates.
 * @param date1 date
 * @param date2 date
 * @param separateur separateur
 * @returns la durée en année et en mois entre 2 dates
 */
export function getDureeEntreDeuxDates(
  date1: string,
  date2: string,
  separateur: string
) {
  const newDate1: Date = new Date(date1);
  const newDate2: Date = new Date(date2);
  let dateDiff: number;
  if (newDate1 < newDate2) {
    dateDiff = convertStringToMoment(date2, separateur).diff(
      convertStringToMoment(date1, separateur)
    );
  } else {
    dateDiff = convertStringToMoment(date1, separateur).diff(
      convertStringToMoment(date2, separateur)
    );
  }
  const duration = moment.duration(dateDiff);
  const mois = duration.days() > 20 ? duration.months() + 1 : duration.months();

  return {
    annee: mois < 12 ? duration.years() : duration.years() + 1,
    mois: mois < 12 ? mois : 0,
  };
}

/**
 * Permet d'obtenir l'année N
 * @returns l'année en N
 */
export const anneeN: number = new Date().getFullYear();

/**
 * Permet d'obtenir l'année N-1
 *  @returns l'année en N-1
 */
export const anneePrecedente: number = new Date().getFullYear() - 1;
