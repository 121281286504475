import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {TaxState} from "../../../store/tax/types";
import {getTaxCreditsAction} from "../../../store/tax/actions";

const useTaxCredits = () => {
   const dispatch = useDispatch();
   const taxState: TaxState = useSelector<State, TaxState>((state) => state.tax);

   useEffect(() => {
      if (!taxState.hasFetchedTaxCredits) {
         dispatch(getTaxCreditsAction());
      }
   }, []);

   return taxState.taxCredits;
};

export {useTaxCredits};
