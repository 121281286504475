import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGAvenirStrokedDocumentContrat, SGAvenirStrokedDon, SGAvenirStrokedOperateurEnLigne, SGAvenirStrokedRevenusEpargne } from "sg-icon-pack-base";
import { SGSpace } from "sg-space";
import { SGTitle } from "sg-typo";
import { TuileServiceAccompagnement } from "website/components/molecules/TuileServiceAccompagnement/TuileServiceAccompagnement";
import { MES_SERVICES, MON_PLAN, PARCOURS_EPARGNER } from "website/utils/privateRoutes";

interface DecouverteServicesAccompagnementProps {
    onglet: string;
}
export const DecouverteServicesAccompagnement: FunctionComponent<DecouverteServicesAccompagnementProps> = (props: DecouverteServicesAccompagnementProps) => {
    const {onglet} = props
    const intl = useIntl();
    const enum Tab {
        sapiendo = "sapiendo",
        services = "services",
    }

    return (
        <>
            <SGSpace direction="vertical" size="xs">
                <SGTitle key="title" level={2} visuallevel={4}>
                    {intl.formatMessage({ id: "home.decouvrirServicesAccompagnement" })}
                </SGTitle>
                <TuileServiceAccompagnement
                    key="tuile-1"
                    titre={intl.formatMessage({ id: "home.serviceAccompagnement.monPlan.titre" })}
                    texte={intl.formatMessage({ id: "home.serviceAccompagnement.monPlan.texte" })}
                    lien={MON_PLAN}
                    Icon={SGAvenirStrokedDocumentContrat}
                    trackKey={`${onglet}::clic-sur-mon-plan`}
                />
                {/* Tuile Le meilleur des services publics */}
                <TuileServiceAccompagnement
                    key="tuile-2"
                    titre={intl.formatMessage({ id: "home.serviceAccompagnement.servicesPublics.titre" })}
                    texte={intl.formatMessage({ id: "home.serviceAccompagnement.servicesPublics.texte" })}
                    lien={`${MES_SERVICES}?tab=${Tab.services}`}
                    Icon={SGAvenirStrokedDon}
                    trackKey={`${onglet}::clic-sur-le-meilleur-service-public`}
                />
                {/* Tuile Sapiendo, l'expert retraite */}
                <TuileServiceAccompagnement
                    key="tuile-3"
                    titre={intl.formatMessage({ id: "home.serviceAccompagnement.sapiendo.titre" })}
                    texte={intl.formatMessage({ id: "home.serviceAccompagnement.sapiendo.texte" })}
                    lien={`${MES_SERVICES}?tab=${Tab.sapiendo}`}
                    Icon={SGAvenirStrokedOperateurEnLigne}
                    trackKey={`${onglet}::clic-sur-sapiendo`}
                />
                {/* Tuile Epargner pour ma retraite */}
                <TuileServiceAccompagnement
                    key="tuile-4"
                    titre={intl.formatMessage({ id: "home.serviceAccompagnement.epargnerRetraite.titre" })}
                    texte={intl.formatMessage({ id: "home.serviceAccompagnement.epargnerRetraite.texte" })}
                    lien={PARCOURS_EPARGNER}
                    Icon={SGAvenirStrokedRevenusEpargne}
                    trackKey={`${onglet}::clic-sur-epargner-pour-ma-retraite`}
                />
            </SGSpace>
        </>
    );
};
