import {put, takeLeading} from "redux-saga/effects";
import {onDefaultError, safe} from "../error/utils";
import {RESET_REDIRECT, TRIGGER_INCOMES_REDIRECT, TRIGGER_INFORMATION_FOR_RETIRED} from "./types";
import {resetRedirectStore, triggerIncomesRedirect, triggerInformationForRetiredRedirect} from "./routingSlice";

export function* watcherSaga() {
   yield takeLeading(RESET_REDIRECT, safe(onDefaultError, handleReset));
   yield takeLeading(TRIGGER_INFORMATION_FOR_RETIRED, safe(onDefaultError, handleInformationForRetiredRedirect));
   yield takeLeading(TRIGGER_INCOMES_REDIRECT, safe(onDefaultError, handleIncomesRedirect));
}

function* handleReset() {
   yield put(resetRedirectStore());
}

function* handleInformationForRetiredRedirect() {
   yield put(triggerInformationForRetiredRedirect());
}

function* handleIncomesRedirect() {
   yield put(triggerIncomesRedirect());
}
