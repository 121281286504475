import {RESET_REDIRECT, TRIGGER_INCOMES_REDIRECT, TRIGGER_INFORMATION_FOR_RETIRED} from "./types";

export const triggerIncomesPageRedirect = () => ({
   type: TRIGGER_INCOMES_REDIRECT
});

export const triggerInformationForRetiredAction = () => ({
   type: TRIGGER_INFORMATION_FOR_RETIRED
});

export const resetRedirectAction = () => ({
   type: RESET_REDIRECT
});
