import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {TaxState} from "../../../store/tax/types";
import {getMeTaxSystemAction} from "../../../store/tax/actions";

const useMyTaxSystem = () => {
   const dispatch = useDispatch();
   const taxState: TaxState = useSelector<State, TaxState>((state) => state.tax);

   useEffect(() => {
      if (!taxState.hasFetchedTaxSystems) {
         dispatch(getMeTaxSystemAction());
      }
   }, []);

   return taxState.myTaxSystem;
};

export {useMyTaxSystem};
