import React, {FunctionComponent,} from "react";
import {useFormContext} from "react-hook-form";
import {useIntl} from "react-intl";
import {AmortizationType, Loan,} from "../../../../store/loans/types";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {ErrorElement} from "../../atoms/ErrorElement/ErrorElement";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {DateInput, DatePattern} from "../../atoms/DateInput/DateInput";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";

interface LoanEditProps {
   editedLoan: Partial<Loan>;
   onCancel?: () => void;
   onSubmit: (formData: never) => void;
   hideButtons?: boolean;
   cypressName: string;
}

const LoanEdit: FunctionComponent<LoanEditProps> = (props: LoanEditProps) => {
   const {onCancel, onSubmit, editedLoan, hideButtons, cypressName} = props;
   const intl = useIntl();
   const amortisationTypes: AmortizationType[] = Object.values(AmortizationType);
   const methods = useFormContext();
   const {handleSubmit} = methods;

   return (
      <div key={editedLoan.id}>
         {!editedLoan.externalId && (
            <div>
               <SGTextIntl intlId="form.loan.fill-info-loan"/>
            </div>
         )}
         <FormInputs colLength={10}>
            <StringInput label="form.asset.asset-name" cypressName={`${cypressName}-name`} defaultValue={editedLoan.name || ''} name="name" minLength={6}
                         maxLength={60} disabled={!!editedLoan.externalId}/>

            <NumericInput cypressName={`${cypressName}-monthlyAmount`} defaultValue={editedLoan.monthlyAmount} name="monthlyAmount"
                          disabled={!!editedLoan.externalId} label="form.loan.monthly-payment" min={0} max={10_000} suffix="€"
                          placeholder={intl.formatMessage({id: "form.loan.monthly-payment.placeholder"})}/>

            <NumericInput cypressName={`${cypressName}-displayedAmount`} defaultValue={editedLoan.displayedAmount} name="displayedAmount"
                          disabled={!!editedLoan.externalId} min={0} max={10_000_000} label="form.loan.total-amount" suffix="€"
                          placeholder={intl.formatMessage({id: "form.loan.total-amount.placeholder"})}/>

            <DateInput cypressName={`${cypressName}-startDate`} defaultValue={editedLoan.startDate} name="startDate" pattern={DatePattern.MONTHS}
                       disabled={!!editedLoan.externalId}
                       label="form.loan.start-date" rangeMessage="form.loan.error.date.invalid-range" min="1990-01-01" max={new Date()}/>

            <NumericInput cypressName={`${cypressName}-duration`} defaultValue={editedLoan.durationInMonth} name="durationInMonth"
                          suffix="common.month.suffix" disabled={!!editedLoan.externalId} label="form.loan.duration"
                          min={25} max={300} placeholder={intl.formatMessage({id: "form.loan.duration.placeholder"})}/>

            <SelectInput disabled={!!editedLoan.externalId} name="amortizationType"
                         cypressName={`${cypressName}-amortizationType`} defaultValue={editedLoan.amortizationType || AmortizationType.AMORTIZATION}
                         label="form.loan.credit.type">
               {amortisationTypes.map(
                  (amortization: AmortizationType) => (
                     <SGSelectOption key={amortization} value={amortization}>
                        {intl.formatMessage({
                           id: `form.loan.amortizationType.${amortization}`,
                        })}
                     </SGSelectOption>
                  )
               )}
            </SelectInput>

         </FormInputs>

         {!hideButtons && (
            <SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName="loan-edit-submit"
                             onClick={handleSubmit(onSubmit)} intlId="common.save"/>
               <SGButtonIntl type="secondary" size="sm" cypressName="loan-edit-cancel" onClick={() => (onCancel ? onCancel() : {})} intlId="common.cancel"/>
            </SGButtonGroup>
         )}

         <ErrorElement cypressName={cypressName} errorTextPrefix="form.loan.error"/>
      </div>
   );
};

export {LoanEdit};
