import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedNousVousRappellons } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGText, SGTitle } from "sg-typo";
import { externalTel, transformTel } from "website/utils/externalTel";
import { ServiceSapiendoModel } from "../../organisms/MesServices/ServiceModel";
import "./InformationSapiendoCard.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface InformationSapiendoCardProps {
    selectedService: ServiceSapiendoModel;
    trackKey?: string;
}

export const InformationSapiendoCard: FunctionComponent<InformationSapiendoCardProps> = (props: React.PropsWithChildren<InformationSapiendoCardProps>) => {
    const { selectedService, trackKey } = props;

    const intl = useIntl();
    const { trackPopin } = useTracking();

    return (
        <SGCard className="information-sapiendo-card">
            <SGGridRow gutter={[0, 13]}>
                <SGGridCol span={12} sm={7} align="start">
                    <SGGridRow gutter={[0, 18]}>
                        <SGGridCol span={12}>
                            <SGMediaQuery minwidth="sm">
                                <SGText
                                    weight="900"
                                    style={{
                                        textTransform: "uppercase",
                                        fontFamily: "Montserrat",
                                        fontSize: "24px",
                                        lineHeight: "24px",
                                        color: "#010035",
                                    }}
                                >
                                    {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.title" }, { linebreak: <br /> })}
                                </SGText>
                            </SGMediaQuery>
                            <SGMediaQuery minwidth={0} maxwidth="xs">
                                <SGText
                                    weight="900"
                                    style={{
                                        textTransform: "uppercase",
                                        fontFamily: "Montserrat",
                                        fontSize: "24px",
                                        lineHeight: "24px",
                                        color: "#010035",
                                    }}
                                >
                                    {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.title.mobile" })}
                                </SGText>
                            </SGMediaQuery>
                        </SGGridCol>
                        <SGGridCol span={12}>
                            <SGText size="m" weight="bolder" style={{ color: "#545454" }}>
                                {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.subtitle" })}
                            </SGText>
                        </SGGridCol>
                        <SGMediaQuery minwidth="sm">
                            <SGGridCol>
                            <a href={`tel:${externalTel.EXTERNAL_TEL_SAPIENDO}`} onClick={() => trackPopin(true, "mes-services", `sapiendo-l-expert-retraite::popin-${trackKey}::clic-sur-appeler`)} className="expert-retraite__call-link">
                                <div className="information-sapiendo-card__appel-container">
                                    <SGText size="s" style={{ color: "#FFFFFF" }}>
                                        {intl.formatMessage(
                                            { id: "mesServices.sapiendo.encart.appel" },
                                            {
                                                number: (word: string) => (
                                                    <strong style={{ marginLeft: "10px", color: "#E2010B", fontSize: "20px" }}>{transformTel(externalTel[word])}</strong>
                                                ),
                                            }
                                        )}
                                    </SGText>
                                </div>
                            </a>
                            </SGGridCol>
                        </SGMediaQuery>
                        <SGMediaQuery minwidth={0} maxwidth="xs">
                            <SGGridCol span={12}>
                                <a href={`tel:${externalTel.EXTERNAL_TEL_SAPIENDO}`} onClick={() => trackPopin(true, "mes-services", `sapiendo-l-expert-retraite::popin-${trackKey}::clic-sur-appeler`)} className="expert-retraite__call-link">
                                    <SGButton
                                        style={{width: "100%"}}
                                        icon={<SGIcon currentcolor component={<SGAvenirStrokedNousVousRappellons />} />}
                                    >
                                        <SGText>
                                            {intl.formatMessage(
                                                { id: "mesServices.sapiendo.encart.appel" },
                                                { number: (word: string) => <strong>{transformTel(externalTel[word])}</strong> }
                                            )}
                                        </SGText>
                                    </SGButton>
                                </a>
                            </SGGridCol>
                        </SGMediaQuery>
                    </SGGridRow>
                </SGGridCol>
                <SGGridCol span={12} sm={5}>
                    <SGCard className="information-sapiendo-card__infos-price-card">
                        <SGGridRow justify="center" gutter={[0, 15]}>
                            <SGGridCol span={12}>
                                <SGGridRow>
                                    {selectedService.cost.from && (
                                        <SGGridCol span={12} textalign="center">
                                            <SGText size="l" style={{ color: "#545454" }}>
                                                {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.from" })}
                                            </SGText>
                                        </SGGridCol>
                                    )}
                                    <SGGridCol span={12} textalign="center">
                                        <SGText size="xxl" weight="bolder" delete>
                                            {intl.formatMessage({ id: `${selectedService.cost.oldPrice}€ TTC` })}
                                        </SGText>
                                    </SGGridCol>
                                    <SGGridCol span={12} textalign="center">
                                        <SGText size="xxl" weight="bolder" style={{ color: "#E2010B" }}>
                                            {intl.formatMessage({ id: `${selectedService.cost.newPrice}€ TTC` })}
                                        </SGText>
                                    </SGGridCol>
                                    {selectedService.cost.quote && (
                                        <SGGridCol span={12} textalign="center">
                                            <SGText weight="600" style={{ color: "#636C6E" }}>
                                                {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.devis" })}
                                            </SGText>
                                        </SGGridCol>
                                    )}
                                </SGGridRow>
                            </SGGridCol>
                            <SGGridCol span={12} textalign="center">
                                <SGText weight="600" style={{ color: "#000000" }}>
                                    {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.deduction" })}
                                </SGText>
                            </SGGridCol>
                        </SGGridRow>
                    </SGCard>
                </SGGridCol>
                <SGGridCol span={12} align="start">
                    <SGText size="m" style={{ color: "#545454" }}>
                        {intl.formatMessage({ id: "mesServices.sapiendo.modal.infos.mail" }, { b: (word: string) => <strong>{word}</strong> })}
                    </SGText>
                </SGGridCol>
            </SGGridRow>
        </SGCard>
    );
};
