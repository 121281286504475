import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStrokedAppeler, SGAvenirStrokedNousVousRappellons } from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGLink } from "sg-link";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGPhoneNumberBanalise } from 'sg-phone-number';
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { isWebView } from "store/import/utils";
import { State } from "store/store";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { externalTel } from "website/utils/externalTel";

export const AccompagnementTelephonique: FunctionComponent = () => {
  const intl = useIntl();
  const { trackClick } = useTracking();
  const codeSegmentMarcheNSM: string = useSelector<State, string>(
    (state) => state.account.account.codeSegmentMarcheNSM ?? ""
  );
  const potentielSousSegmentNSM: string = useSelector<State, string>(
    (state) => state.account.account.potentielSousSegmentNSM
  );
  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );
  const urlWebCallback = window.env.REACT_APP_WEBCALLBACK_URL;
  const { disponibiliteAppeler, disponibiliteWebCallback } = useFeatures();
  const [isWebCallbackAvailable, setIsWebCallbackAvailable] =
    useState<boolean>(false);

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  // Liste des sous segments autorisés
  const lstSousSegments = ["13", "14", "15", "16", "05"];

  useEffect(() => {
    // la segmentation
    setIsWebCallbackAvailable(codeSegmentMarcheNSM === "30" && lstSousSegments.includes(potentielSousSegmentNSM));
  }, [codeSegmentMarcheNSM]);

  const getBlock = (
    icon: any,
    type: "appeler" | "webCallback",
    lien: JSX.Element
  ) => (
    <SGLayout background={isPhone ? "light" : ""}>
      <SGSpace
        direction="vertical"
        disableautomargin
        textalign="center"
        size="sm"
        style={isPhone ? { padding: "1rem" } : {}}
      >
        <SGGridRow key="row" justify="center">
          <SGGridCol span={2} textalign="center" align="start">
            {icon}
          </SGGridCol>
          <SGGridCol span={10}>
            <SGText size="m">
              {intl.formatMessage(
                { id: `accompagnementTelephonique.${type}.titre` },
                {
                  p: (word: string) => (
                    <SGBlock disableautomargin component="p">
                        {word}
                    </SGBlock>
                  ),
                  strong: (word) => <SGText strong>{word}</SGText>
                }
              )}
            </SGText>
          </SGGridCol>
        </SGGridRow>
        {lien}
      </SGSpace>
    </SGLayout>
  );

  return (
    <>
      {isWebCallbackAvailable && (
        <SGLayout background={isPhone ? "" : "light"}>
          <SGContent disableautomargin>
            <SGBox textalign="center" margin={{ bottom: "md" }}>
              <SGTitle level={2} visuallevel={4}>
                {intl.formatMessage({ id: "accompagnementTelephonique.titre" })}
              </SGTitle>
            </SGBox>
            <SGGridRow justify="center" gutter={isPhone ? [0, 10] : []}>
              { disponibiliteAppeler && (
                <SGGridCol span={12} sm={disponibiliteWebCallback ? 5 : 8}>
                  {getBlock(
                    <SGIcon
                      component={<SGAvenirStrokedAppeler />}
                      currentcolor
                      disableautomargin
                  />,
                    "appeler",
                    <SGBox key="box" inline onClick={() => trackClick("clic-sur-appeler-3944")}>
                      <SGPhoneNumberBanalise
                        format="short"
                        number={externalTel.EXTERNAL_TEL_APPELER}
                      />
                    </SGBox>
                  )}
                </SGGridCol>
              )}
              {disponibiliteAppeler && disponibiliteWebCallback && (
                <SGMediaQuery minwidth="sm">
                  <SGGridCol align="center">
                    <SGBox margin={{ left: "sm", right: "sm" }}>
                      <SGDivider
                        align="center"
                        type="vertical"
                        width={12}
                        disableautomargin
                      />
                    </SGBox>
                  </SGGridCol>
                </SGMediaQuery>
              )}
              {disponibiliteWebCallback && (
                <SGGridCol span={12} sm={disponibiliteAppeler ? 5 : 8}>
                  {getBlock(
                    <SGIcon
                      component={<SGAvenirStrokedNousVousRappellons />}
                      currentcolor
                      disableautomargin
                    />,
                    "webCallback",
                    estSupervisionActive ? (
                      <SGButton type="link" disabled>
                        {intl.formatMessage({
                          id: "accompagnementTelephonique.webCallback.lien",
                        })}
                      </SGButton>
                    ) : (
                      <SGLink
                        href={urlWebCallback}
                        target={isWebView() ? "_self" : "_blank"}
                        onClick={() =>
                          trackClick("clic-sur-je-souhaite-etre-rappele")
                        }
                      >
                        {intl.formatMessage({
                          id: "accompagnementTelephonique.webCallback.lien",
                        })}
                      </SGLink>
                    )
                  )}
                </SGGridCol>
              )}
            </SGGridRow>
          </SGContent>
        </SGLayout>
      )}
    </>
  );
};
