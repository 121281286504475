import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGText } from "sg-typo";
import { useFeatures } from "website/components/hooks/useFeatures";
import { TutorialContext } from "website/components/providers/TutorialProvider";
import "./Tutoriel.scss";
import { setShowTutoriel } from "store/dashboard/actions";
import { useDispatch } from "react-redux";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGBox, SGSpace } from "sg-space";

enum TutorielStep {
    AGE = "AGE",
    RIS = "RIS",
    SEI = "SEI",
    SERVICES_HEADER = "SERVICES_HEADER",
    SERVICES_CAROUSEL = "SERVICES_CAROUSEL",
}
interface StepPlacement {
    top: string;
    right: string;
    elementIndex: number;
    pointeur: string;
}
type TutorielSteps = Record<TutorielStep, StepPlacement>;

interface TutorielProps {
    visible: boolean;
    setVisible: (showTutoriel: boolean) => void;
    showStopTutorialModal: boolean;
    setShowStopTutorialModal: (showStopTutorialModal: boolean) => void;
}

export const Tutoriel: FunctionComponent<TutorielProps> = (props: TutorielProps) => {
    const { visible, setVisible, showStopTutorialModal, setShowStopTutorialModal } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const [scrollListener] = useState(() => initScrollListener());
    const [scrollEnded, setScrollEnded] = useState(false);

    const [indexStep, setIndexStep] = useState<number>(0);
    const [lastIndex, setLastIndex] = useState<number>(1);
    const [topPlacement, setTopPlacement] = useState<number>(0);
    const [rightPlacement, setRightPlacement] = useState<number>(0);
    const [pointeurPlacement, setPointeurPlacement] = useState<string>("0");
    const [stepsToShow, setStepsToShow] = useState<TutorielStep[]>([]);
    const { parcoursSEIActif } = useFeatures();
    const { trackPopin, resetTracking } = useTracking();

    const steps: TutorielSteps = {
        [TutorielStep.AGE]: {
            top: ".choix-age__container",
            right: ".choix-age__container",
            elementIndex: 0,
            pointeur: isPhone ? "6%" : "3%",
        },
        [TutorielStep.RIS]: {
            top: isPhone ? ".pension-mobile__card" : ".pension__card",
            right: ".choix-age__container",
            elementIndex: 0,
            pointeur: isPhone ? "50%" : "20%",
        },
        [TutorielStep.SEI]: {
            top: "#SEITile",
            right: "#SEITile",
            elementIndex: 0,
            pointeur: "50%",
        },
        [TutorielStep.SERVICES_HEADER]: {
            top: isPhone ? ".stl_carousel .info-card" : ".headerAssu__title",
            right: isPhone ? ".stl_carousel__wrapper" : ".headerAssu__title",
            elementIndex: isPhone ? 0 : 3,
            pointeur: isPhone ? "50%" : "3%",
        },
        [TutorielStep.SERVICES_CAROUSEL]: {
            top: ".stl_carousel .info-card",
            right: ".stl_carousel__wrapper",
            elementIndex: 0,
            pointeur: "75%",
        },
    };

    const { setTutorialDisplayed } = useContext(TutorialContext);

    function initScrollListener() {
        return () => {
            // Pas de clearTimeout car impossible d'en stocker la valeur
            setTimeout(() => {
                setScrollEnded(true);
            }, 100);
        };
    }

    useEffect(() => {
        if (visible) {
            window.addEventListener("scroll", scrollListener);

            setTutorialDisplayed(true);
            document.body.classList.add("tutoriel_body");

            document.querySelector(steps[TutorielStep.AGE].top)?.scrollIntoView();
            // La modale n'est pas encore apparue...
            setTimeout(moveView, 200);

            trackPopin(false, "tableau-de-bord", `popin-tutoriel-etape-${indexStep}`, "tutoriel", indexStep.toLocaleString());
        } else {
            document.body.classList.remove("tutoriel_body");
            window.removeEventListener("scroll", scrollListener);
        }
    }, [visible]);

    useEffect(() => {
        // Mise à jour si on termine ou arrête le tutoriel
        if (!visible && !showStopTutorialModal) {
            setIndexStep(0);
            dispatch(setShowTutoriel(false));
        }
    }, [visible, showStopTutorialModal]);

    useEffect(() => {
        // Les étapes obligatoires
        const initShowSteps = [TutorielStep.AGE, TutorielStep.RIS, TutorielStep.SEI, TutorielStep.SERVICES_HEADER, TutorielStep.SERVICES_CAROUSEL].filter(
            (valueStep) => (parcoursSEIActif || valueStep !== TutorielStep.SEI) && (!isPhone || valueStep !== TutorielStep.SERVICES_HEADER)
        );
        setStepsToShow(initShowSteps);
        setLastIndex(initShowSteps.length - 1);
    }, [parcoursSEIActif, isPhone]);

    // Placement de la modale à la fin du scroll
    useEffect(() => {
        if (scrollEnded) {
            moveModal(stepsToShow[indexStep]);
            setScrollEnded(false);
        }
    }, [scrollEnded]);

    /**
     * Place la modale et sa flèche en fonction de l'étape.
     * @param realStep Etape réelle (pour gérer la reprise)
     */
    const moveModal = (realStep: TutorielStep) => {
        const tutorielPointeur = document.querySelector(".tutoriel__pointeur");
        const stepTopElement = document.querySelector(steps[realStep].top);
        const stepRightElement = document.querySelectorAll(steps[realStep].right)[steps[realStep].elementIndex];
        if (stepTopElement && stepRightElement && tutorielPointeur) {
            const pointeurRect = tutorielPointeur.getBoundingClientRect();
            const elementRect = stepTopElement.getBoundingClientRect();
            const rightRect = stepRightElement.getBoundingClientRect();
            const bodyRect = document.body.getBoundingClientRect();

            setTopPlacement(elementRect.top + elementRect.height + (pointeurRect.height / 2 || 16));
            setRightPlacement(bodyRect.width - rightRect.right);
            setPointeurPlacement(steps[realStep].pointeur);
        }
    };

    const continueLater = () => {
        trackPopin(true, "tableau-de-bord", "popin-tutoriel-sortie::clic-sur-continuer-plus-tard", "tutoriel");
        setTutorialDisplayed(false);
        document.body.classList.remove("tutoriel_body");
        setVisible(false);
    };

    const nextStep = () => {
        const indexNextStep = indexStep + 1;

        if (indexNextStep === stepsToShow.length) {
            document.body.classList.remove("tutoriel_body");
            setTutorialDisplayed(false);
            setVisible(false);
            window.scrollTo(0, 0);
            resetTracking();
        } else {
            const nextStepToShow = stepsToShow[indexNextStep];

            trackPopin(false, "tableau-de-bord", `popin-tutoriel-etape-${indexNextStep}`, "tutoriel", `${indexNextStep}`);
            if (nextStepToShow === TutorielStep.SERVICES_HEADER) {
                window.scrollTo(0, 0);
            } else {
                document.querySelector(steps[nextStepToShow].top)?.scrollIntoView({ behavior: "smooth" });
            }
            setIndexStep(indexNextStep);
        }
    };

    const moveView = () => {
        const realStep = stepsToShow[indexStep];
        if (realStep === TutorielStep.SERVICES_HEADER) {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
            document.querySelector(steps[realStep].top)?.scrollIntoView({ behavior: "smooth" });
        }
    };

    const stopTutorial = () => {
        if (indexStep !== lastIndex) {
            setVisible(false);
            setShowStopTutorialModal(true);
        }
    };

    return (
        // Condition visible ici car le carrousel se casse si on entre par la carte Mon Guide depuis la puce 4
        visible ? (
            <SGModal
                visible={visible}
                closable
                onCancel={stopTutorial}
                className={`tutoriel tutoriel__etape${indexStep}`}
                style={{ top: topPlacement, right: rightPlacement }}
            >
                <div className="tutoriel__pointeur" style={{ right: pointeurPlacement }}>
                    <div className="triangle" />
                </div>
                <SGSpace disableautomargin direction="vertical" size="lg">
                    <SGText key="texte" weight="600">
                        {intl.formatMessage(
                            { id: `tutoriel.etape.${stepsToShow[indexStep]}.texte` },
                            {
                                linebreak: <br />,
                                currentStep: indexStep + 1,
                                maxStep: stepsToShow.length,
                            }
                        )}
                    </SGText>
                    {indexStep !== lastIndex ? (
                        // Un SGButtonGroup opposite place les boutons en colonne en mobile
                        <SGSpace key="boutons" disableautomargin>
                            <SGButton key="quitter" type="link" underline={false} onClick={() => continueLater()}>
                                <FormattedMessage id="tutoriel.bouton.continuer" />
                            </SGButton>
                            <SGBox key="continuer" alignchildren={["right", "center"]}>
                                <SGButton
                                    type="tertiary"
                                    icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                                    iconposition="right"
                                    onClick={() => nextStep()}
                                />
                            </SGBox>
                        </SGSpace>
                    ) : (
                        <SGBox key="boutons" alignchildren={["right", "center"]}>
                            <SGButton type="tertiary" onClick={() => nextStep()}>
                                <FormattedMessage id="tutoriel.bouton.terminer" />
                            </SGButton>
                        </SGBox>
                    )}
                </SGSpace>
            </SGModal>
        ) : (
            <></>
        )
    );
};
