import React, {FunctionComponent, useEffect, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import Slider from "rc-slider";
import {useIntl} from "react-intl";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {ProjectType,} from "../../../../../store/projects/types";
import {createProjectAction,} from "../../../../../store/projects/actions";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {Asset, AssetType, RealEstateAssetTaxSystemSubType, RealEstateAssetTaxSystemType,} from "../../../../../store/assets/types";
import {MonthlyYearly, NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {RealEstateSimulator} from "../../../molecules/RealEstateSimulator/RealEstateSimulator";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {FamilyStatus} from "../../../../../store/members/types";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGSpace} from "sg-space";
import {SGCard} from "sg-card";
import {SelectInputButtons} from "../../../atoms/SelectInputButtons/SelectInputButtons";
import {SGGridCol, SGGridRow} from "sg-grid";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";
import {useAssets} from "../../../hooks/useAssets";
import {useMediaQuery} from "sg-media-query";
import {State} from "../../../../../store/store";

interface RealEstateProjectFormData {
   amount: number;
   horizon: string;
   periodicCostAmountPeriodicity: MonthlyYearly;
   rentPeriodicity: MonthlyYearly;
   realEstateSoldId?: string;
}

const RealEstateProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const [isSellingPrimaryRealEstates, setSellingPrimaryRealEstates] = useState<boolean>(false);
   const [isPriceKnown, setPriceKnown] = useState<boolean | null>(null);
   const [isMonthlyFeesKnown, setIsMonthlyFeesKnown] = useState<boolean | null>(null);
   const [primaryRealEstates, setPrimaryRealEstates] = useState<Asset[]>([]);
   const [selectedSoldAssetValuation, setSelectedSoldAssetValuation] = useState<number>();
   const [assetValuation, setAssetValuation] = useState<number>();
   const [periodicCostAmount, setPeriodicCostAmount] = useState<number>();
   const [transactionFees, setTransactionFees] = useState<number>();
   const [rent, setRent] = useState<number>();
   const [taxSystem, setTaxSystem] = useState<RealEstateAssetTaxSystemType>();
   const [taxSystemSubType, setTaxSystemSubType] = useState<RealEstateAssetTaxSystemSubType>();
   const [projectGoal, setProjectGoal] = useState<number>(0);
   const [projectHorizon, setProjectHorizon] = useState<number>(5);
   const [propertyTypeSelected, setPropertyTypeSelected] = useState<ProjectType | undefined>(undefined);

   const taxSystemValues: RealEstateAssetTaxSystemType[] = Object.values(RealEstateAssetTaxSystemType);
   const taxArrangementValues: RealEstateAssetTaxSystemSubType[] = Object.values(RealEstateAssetTaxSystemSubType);

   const { assets } = useAssets();
   const hasFetchedAssets = useSelector<State, boolean>((state) => state.assets.hasFetched);

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const areSelectButtonVertical = useMediaQuery({
      minwidth: 'xs',
      maxwidth: 'md',
   });

   useEffect(() => {
      if (hasFetchedAssets) {
         setPrimaryRealEstates(assets.filter((a) => a.assetType === AssetType.REAL_ESTATE_PRIMARY));

         const selectedPrimaryRealEstateAsset = assets.filter((a) => a.assetType === AssetType.REAL_ESTATE_PRIMARY).shift();

         const assetValuation = selectedPrimaryRealEstateAsset?.assetValuation?.totalValuation || 0;
         const ownershipPercentage = selectedPrimaryRealEstateAsset?.assetMemberLinkSet?.filter((aml) => aml?.familyMember?.status === FamilyStatus.ME || aml?.familyMember?.status === FamilyStatus.PARTNER)
            .map((x) => (x.percentage || 0) / 100)
            .reduce((a, b) => a + b, 0) || 0;

         setSelectedSoldAssetValuation(assetValuation * ownershipPercentage);
      }
   }, [assets, hasFetchedAssets]);

   useEffect(() => {
      setProjectGoal(Number(assetValuation) - Number((isSellingPrimaryRealEstates && selectedSoldAssetValuation) ? selectedSoldAssetValuation : 0) + Number(transactionFees));
   }, [isSellingPrimaryRealEstates, assetValuation, selectedSoldAssetValuation, transactionFees]);

   const goToNewProject = () => {
      redirectToRelativePath(NEW_PROJECTS);
   };

   const onHorizonChange = (horizon: number) => {
      setProjectHorizon(horizon);
   };

   const onAssetValuationChange = (valuation: number) => {
      setAssetValuation(valuation);
   };

   const onProjectTypeChange = (projectType: string) => {
      setPropertyTypeSelected(projectType);
      if (projectType !== ProjectType.REAL_ESTATE_PRIMARY) {
         setSellingPrimaryRealEstates(false);
      }
   };

   const onCostChange = (monthlyCost: number) => {
      setPeriodicCostAmount(monthlyCost);
   };

   const onTransactionFeesChange = (fees: number) => {
      setTransactionFees(fees);
   };

   const onRentChange = (rentAmount: number) => {
      setRent(rentAmount);
   };

   const calculatePrice = (estimatedPrice: number) => {
      setAssetValuation(estimatedPrice);
      setIsMonthlyFeesKnown(true);
      methods.setValue("totalValuation", estimatedPrice, {
         shouldValidate: true,
      });
   };

   const calculateFees = (monthlyFees: number) => {
      setPeriodicCostAmount(monthlyFees);
      methods.setValue("periodicCostAmount", monthlyFees, {shouldValidate: true});
   };

   const calculateTransactionFees = (fees: number) => {
      setTransactionFees(fees);
      methods.setValue("transactionFees", fees, {shouldValidate: true});
   };

   const onSelectTaxTypeChange = (newRealEstateAssetTaxSystemType: string) => {
      if (newRealEstateAssetTaxSystemType !== RealEstateAssetTaxSystemType.DEFISCALISATION_REAL_ESTATE_INCOME) {
         setTaxSystemSubType(undefined);
      }
      setTaxSystem(newRealEstateAssetTaxSystemType);
   };

   const onSelectTaxSubTypeChange = (newRealEstateAssetTaxSystemSubType: string) => {
      setTaxSystemSubType(newRealEstateAssetTaxSystemSubType);
   };

   const onSelectPrimaryRealEstateToSell = (assetToSellId: string) => {
      const selectedAssetWithValuation = assets.filter((a) => a.id === parseInt(assetToSellId)).shift();
      if (selectedAssetWithValuation !== undefined) {
         const assetValuation = selectedAssetWithValuation.assetValuation.totalValuation || 0;
         const ownershipPercentage = selectedAssetWithValuation?.assetMemberLinkSet?.filter((aml) => aml?.familyMember?.status === FamilyStatus.ME || aml?.familyMember?.status === FamilyStatus.PARTNER)
            .map((x) => (x.percentage || 0) / 100)
            .reduce((a, b) => a + b, 0);
         setSelectedSoldAssetValuation(assetValuation * ownershipPercentage);
      }
   };

   const onSubmit = (data: RealEstateProjectFormData) => {
      const computedRentValue = rent && data.rentPeriodicity === MonthlyYearly.MONTHLY ? rent * 12 : rent;

      dispatch(
         createProjectAction({
            projectType: propertyTypeSelected,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectName: intl.formatMessage({id: `projectType.${propertyTypeSelected}`}),
            amount: assetValuation,
            soldAssetIds: isSellingPrimaryRealEstates ? [data.realEstateSoldId] : undefined,
            recurrentFees: periodicCostAmount === undefined ? 0 : Math.floor(data.periodicCostAmountPeriodicity === MonthlyYearly.YEARLY ? Math.trunc(periodicCostAmount / 12) : periodicCostAmount),
            rentalInvestmentAssetTaxArrangement: taxSystemSubType,
            rentalInvestmentAssetTaxSystem: taxSystem,
            transactionFees,
            yearlyRentAmount: rent ? computedRentValue : undefined,
         })
      );
   };

   return (
      <>
         <SGTitleIntl intlId="project.real-estate.title" cypressName="real-estate-project-title"/>
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
               <SGCard data-cy="project-real-estate-frame-input">
                  <SGSpace direction="vertical" disableautomargin>
                     <SGTextIntl intlId="project.real-estate.horizon"/>
                     <SGGridRow align="middle">
                        <SGGridCol span={9} xs={8}>
                           {/* @ts-ignore */}
                           <Slider min={1} max={25} value={projectHorizon} onChange={(value) => {
                              setProjectHorizon(value);
                              methods.setValue("horizon", value, {shouldValidate: true});
                           }} railStyle={{height: 9}} handleStyle={{height: 20, width: 20, marginTop: -6}} trackStyle={{height: 9}} step={1}/>
                        </SGGridCol>
                        <SGGridCol span={3} xs={4}>
                           <NumericInput cypressName="real-estate-project-horizon-input" suffix="common.years" name="horizon" min={1} max={25}
                                         required={false} defaultValue={projectHorizon} onValueChange={onHorizonChange}
                                         placeholder={intl.formatMessage({id: "project.real-estate.horizon.placeholder"})}/>
                        </SGGridCol>
                     </SGGridRow>
                     <SGTextIntl intlId="project.real-estate.type"/>
                     <SelectInputButtons cypressName="real-estate-project-type" choice={propertyTypeSelected} direction="vertical"
                                         setChoice={onProjectTypeChange} size="md"
                                         labels={["projectType.REAL_ESTATE_PRIMARY", "projectType.REAL_ESTATE_SECONDARY", "projectType.REAL_ESTATE_INVESTMENT"]}
                                         choices={[ProjectType.REAL_ESTATE_PRIMARY, ProjectType.REAL_ESTATE_SECONDARY, ProjectType.REAL_ESTATE_INVESTMENT]}/>
                  </SGSpace>
               </SGCard>
               {propertyTypeSelected !== undefined && (
                  <SGCard data-cy="project-real-estate-asset-edit">
                     <FormInputs colLength={10}>
                        <RealEstateSimulator setPrice={calculatePrice} displayTransactionFees setTransactionFees={calculateTransactionFees} displayMonthlyFees
                                             setMonthlyFees={calculateFees} priceKnown={isPriceKnown} setPriceKnown={setPriceKnown}
                                             setMonthlyFeesKnown={setIsMonthlyFeesKnown} monthlyFeesKnown={isMonthlyFeesKnown}/>

                        {isPriceKnown && (
                           <NumericInput name="totalValuation" cypressName="project-real-estate-total-valuation" min={0} max={10_000_000}
                                         onValueChange={onAssetValuationChange} defaultValue={assetValuation} label="form.asset.total-valuation"
                                         suffix="€" maxMessage="form.asset.error.totalValuation" placeholder={intl.formatMessage({
                              id: "form.asset.total-valuation.placeholder",
                           })} minMessage="form.asset.error.totalValuation"/>
                        )}
                        {isPriceKnown && assetValuation !== undefined && (
                           <RealEstateSimulator priceMode={false} setMonthlyFees={calculateFees} priceKnown={isPriceKnown} displayTransactionFees
                                                setTransactionFees={calculateTransactionFees} setPriceKnown={setPriceKnown}
                                                monthlyFeesKnown={isMonthlyFeesKnown}
                                                setMonthlyFeesKnown={setIsMonthlyFeesKnown} givenPrice={assetValuation}/>
                        )}
                        {/* Frais de notaire */}
                        {isPriceKnown && methods.getValues("totalValuation") !== undefined && isMonthlyFeesKnown && (
                           <NumericInput defaultValue={transactionFees} placeholder={intl.formatMessage({id: "form.asset.transaction.fees.placeholder"})}
                                         name="transactionFees" cypressName="project-real-estate-transaction-fees" min={1000} max={150_000}
                                         onValueChange={onTransactionFeesChange} suffix="€" label="form.asset.transaction.fees"
                                         minMessage="form.asset.error.transaction.fees.min" maxMessage="form.asset.error.transaction.fees.max"/>
                        )}
                        {/* Charges annuelles */}
                        {isPriceKnown && methods.getValues("totalValuation") !== undefined && isMonthlyFeesKnown && (
                           <NumericInput defaultValue={periodicCostAmount} placeholder={intl.formatMessage({id: "form.asset.periodicCostAmount.placeholder"})}
                                         name="periodicCostAmount" cypressName="project-real-estate-monthly-cost-amount" min={100} max={1_000_000}
                                         onValueChange={onCostChange} suffix="€" label="form.asset.periodicCostAmount" displayPeriodicChoice
                                         minMessage="form.asset.error.periodicCostAmount.min" maxMessage="form.asset.error.periodicCostAmount.max"/>
                        )}

                        {/* Loyer */}
                        {isPriceKnown && methods.getValues("totalValuation") !== undefined && isMonthlyFeesKnown && propertyTypeSelected === ProjectType.REAL_ESTATE_INVESTMENT && (
                           <NumericInput defaultValue={rent} placeholder={intl.formatMessage({id: "form.asset.rent.placeholder"})} name="rent"
                                         cypressName="project-real-estate-rent" min={0} max={10_000} label="project.real-estate.amount.rent" suffix="€"
                                         displayPeriodicChoice onValueChange={onRentChange} minMessage="form.asset.error.rent.min"
                                         maxMessage="form.asset.error.rent.max"/>
                        )}
                        {/* Dispositif Fiscal */}
                        {isPriceKnown && methods.getValues("totalValuation") !== undefined && isMonthlyFeesKnown && propertyTypeSelected === ProjectType.REAL_ESTATE_INVESTMENT && (
                           <SelectInput name="rentalInvestmentAssetTaxSystem" required onValueChange={onSelectTaxTypeChange}
                                        cypressName="project-real-estate-rental-investment-tax-system" defaultValue={taxSystem} label="form.asset.tax-system"
                                        placeholder={intl.formatMessage({id: "form.asset.choose-a-tax-system-type"})}>
                              {taxSystemValues.map(
                                 (taxSystem: RealEstateAssetTaxSystemType) => (
                                    <SGSelectOption key={taxSystem} value={taxSystem}>
                                       {intl.formatMessage({id: `tax-system.${taxSystem}`})}
                                    </SGSelectOption>
                                 )
                              )}
                           </SelectInput>
                        )}
                        {isPriceKnown && methods.getValues("totalValuation") !== undefined && isMonthlyFeesKnown && taxSystem === RealEstateAssetTaxSystemType.DEFISCALISATION_REAL_ESTATE_INCOME && (
                           <SelectInput name="rentalInvestmentAssetTaxArrangement" onValueChange={onSelectTaxSubTypeChange} defaultValue={taxSystemSubType}
                                        required
                                        cypressName="project-real-estate-rental-investment-tax-arrangement" label="form.asset.tax-arrangement"
                                        placeholder={intl.formatMessage({id: "form.asset.choose-a-tax-arrangement-type"})}>
                              {taxArrangementValues.map((taxArrangement: RealEstateAssetTaxSystemSubType) =>
                                 <SGSelectOption key={taxArrangement} value={taxArrangement}>
                                    {intl.formatMessage({id: `tax-arrangement.${taxArrangement}`})}
                                 </SGSelectOption>
                              )}
                           </SelectInput>
                        )}
                     </FormInputs>
                  </SGCard>
               )}
               {methods.formState.isValid && propertyTypeSelected !== undefined && isPriceKnown && isMonthlyFeesKnown && (
                  <SGCard data-cy="project-real-estate-frame-goal">
                     {propertyTypeSelected === ProjectType.REAL_ESTATE_PRIMARY && primaryRealEstates.length > 0 && (
                        <>
                           <SelectInputButtons labels={["project.real-estate.sell.primary.yes", "project.real-estate.sell.primary.no"]}
                                               choices={["true", "false"]} size="md"
                                               direction={areSelectButtonVertical ? "vertical" : "horizontal"}
                                               choice={String(isSellingPrimaryRealEstates)} cypressName="sell-real-estate"
                                               setChoice={(choice: string) => setSellingPrimaryRealEstates(choice === "true")}/>
                           {isSellingPrimaryRealEstates && (
                              <SelectInput name="realEstateSoldId" cypressName="select-real-estate" required
                                           onValueChange={onSelectPrimaryRealEstateToSell} defaultValue={primaryRealEstates[0].id}>
                                 {primaryRealEstates.map(
                                    (asset: Asset) => (asset.id !== undefined &&
                                       <SGSelectOption key={asset.id} data-cy={`real-estate-sold${asset.assetName}`} value={asset.id}>
                                          {asset.assetName}
                                       </SGSelectOption>
                                    )
                                 )}
                              </SelectInput>
                           )}
                        </>
                     )}
                     <SGSpace>
                        <SGTextIntl intlId="project.real-estate.financing" size="l"/>
                     </SGSpace>
                     <SGSpace>
                        <SGTextIntl intlId="project.real-estate.amount"/>
                        <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" decimalScale={0} value={assetValuation}/>
                     </SGSpace>

                     <SGSpace disableautomargin>
                        <SGTextIntl intlId="project.real-estate.amount.fees"/>
                        <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" decimalScale={0} value={transactionFees}/>
                     </SGSpace>
                     {propertyTypeSelected === ProjectType.REAL_ESTATE_INVESTMENT && (
                        <SGSpace disableautomargin>
                           <SGTextIntl intlId="form.asset.periodicCostAmount"/>
                           <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" decimalScale={0} value={periodicCostAmount}/>
                        </SGSpace>
                     )}
                     {propertyTypeSelected === ProjectType.REAL_ESTATE_INVESTMENT && taxSystemSubType === undefined && (
                        <SGSpace disableautomargin>
                           <SGTextIntl intlId="project.real-estate.tax-arrangement"/>
                           <SGTextIntl intlId={`tax-system.${taxSystem}`} strong/>
                        </SGSpace>
                     )}
                     {propertyTypeSelected === ProjectType.REAL_ESTATE_INVESTMENT && taxSystemSubType !== undefined && (
                        <SGSpace disableautomargin>
                           <SGTextIntl intlId="project.real-estate.tax-arrangement"/>
                           <SGTextIntl intlId={`tax-arrangement.${taxSystemSubType}`} strong/>
                        </SGSpace>
                     )}
                     {isSellingPrimaryRealEstates && selectedSoldAssetValuation && (
                        <SGSpace disableautomargin>
                           <SGTextIntl intlId="project.real-estate.sell.primary.title"/>
                           <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" decimalScale={0} value={-selectedSoldAssetValuation}/>
                        </SGSpace>
                     )}

                     <SGSpace>
                        <SGTextIntl intlId={projectGoal < 0 ? "project.real-estate.goal.reached" : "project.real-estate.goal"} strong/>
                        {projectGoal > 0 && (
                           <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" decimalScale={0} value={projectGoal}/>
                        )}
                     </SGSpace>
                  </SGCard>
               )}

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md"
                                disabled={!methods.formState.isValid || propertyTypeSelected === undefined || !isPriceKnown || !isMonthlyFeesKnown || (isSellingPrimaryRealEstates && selectedSoldAssetValuation === undefined)}
                                cypressName="real-estate-project-next"
                                onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                  <SGButtonIntl type="link" cypressName="real-estate-project-previous" onClick={goToNewProject} intlId="common.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>
      </>
   );
};

export {RealEstateProject};
