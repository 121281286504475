import { ReactComponent as Calendar } from "assets/images/calendar-epargner.svg";
import { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText, SGTitle } from "sg-typo";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "../../ErrorModal/ErrorModal";
import "./ArticleContacterCard.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface ArticleContacterCardProps {
    articlePath: any;
}

/**
 * Encadré de contact d'un conseiller
 */
export const ArticleContacterCard: FunctionComponent<ArticleContacterCardProps> = (props: ArticleContacterCardProps) => {
    const { articlePath } = props;
    const intl = useIntl();
    const openSGAgenda = useSGAgenda();
    const {disponibiliteSGAgenda} = useFeatures();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const { trackClick } = useTracking();

    const handleOnClickRdv = () => {
        trackClick(`${articlePath}::clic-sur-prendre-rendez-vous`, {form_field_2: disponibiliteSGAgenda ? "active" : "non-active"});
        // FIXME : Déporter dans BoutonSGAgenda
        if (disponibiliteSGAgenda) {
            openSGAgenda();
        } else {
           // SGAgenda désactivé, on affiche un message d'erreur
           setShowErrorModal(true);
        }
    };

    return (
        <SGGridRow className="article-contacter-card__card-info-container">
            <SGGridCol span={12}>
                <SGGridRow justify="center" gutter={[0,18]} align="middle">
                    <SGGridCol span={12} textalign="center">
                        <SGTitle level={2} visuallevel={5}>
                            {intl.formatMessage({ id: "home.article.cadre.loin" })}
                        </SGTitle>
                    </SGGridCol>
                    <SGGridCol span={11}>
                        <SGText>{intl.formatMessage({ id: "home.article.cadre.texte" })}</SGText>
                    </SGGridCol>
                    <SGGridCol className="calendar-button">
                        <BoutonSGAgenda type="primary" icon={<Calendar/>} onClick={handleOnClickRdv}>
                            <FormattedMessage id="parcoursEpargner.button.contacter"/>
                        </BoutonSGAgenda>
                    </SGGridCol>
                </SGGridRow>
                {showErrorModal && (
                    <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} description="modal.error.message.indisponible" />
                )}
            </SGGridCol>
        </SGGridRow>
    );
};
