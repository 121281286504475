import React, {useState} from "react";
import {Controller, useFormContext, Validate} from "react-hook-form";
import {useIntl} from "react-intl";
import {SGTextIntl} from "../SGTextIntl/SGTextIntl";
import {SGPopover} from "sg-popover";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusInfo} from "sg-icon-pack-base";
import {SGButton} from "sg-button";
import {SGInput} from "sg-input";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {ShowPassword} from "../ShowPassword/ShowPassword";
import {noTagRegex} from "../../organisms/Utils/Signup/const";

interface StringInputProps {
   label: string;
   tooltipLabel?: string;
   tooltipTransformations?: Record<string, string | React.ReactNode>;
   defaultValue?: string;
   isPassword?: boolean;
   name: string;
   placeholder?: string;
   onValueChange?: (newValue: string) => void;
   required?: boolean;
   minLength?: number;
   maxLength?: number;
   pattern?: RegExp;
   validate?: Record<string, Validate> | Validate;
   disabled?: boolean;
   minLengthMessage?: string;
   maxLengthMessage?: string;
   patternMessage?: string;
   validateMessage?: string;
   cypressName: string;
}

const StringInput = ({
                        label,
                        tooltipLabel,
                        tooltipTransformations,
                        required,
                        isPassword,
                        minLength,
                        maxLength,
                        pattern,
                        validate,
                        onValueChange,
                        disabled,
                        name,
                        defaultValue,
                        cypressName,
                        placeholder,
                        minLengthMessage,
                        maxLengthMessage,
                        patternMessage,
                        validateMessage,
                     }: StringInputProps) => {
   const methods = useFormContext();
   const intl = useIntl();
   const {errors, control} = methods;

   const [showPassword, setShowPassword] = useState(false);

   const validationRules: RegisterOptions = {};
   validationRules.required = required === undefined ? true : required;
   validationRules.pattern = pattern || noTagRegex;
   validationRules.validate = validate;
   if (minLength) {
      validationRules.minLength = {value: minLength, message: ""};
   }
   if (maxLength) {
      validationRules.maxLength = {value: maxLength, message: ""};
   }

   const computeLabelNode = (): React.ReactNode => {
      if (tooltipLabel) {
         return <>  <SGTextIntl intlId={label}/>
            <SGPopover trigger="hover"
                       content={<SGTextIntl intlId={tooltipLabel} transformations={tooltipTransformations}/>}
                       placement="topLeft">
               <SGButton type="icon" icon={<SGIcon component={<SGAvenirStatusInfo/>}/>} tabIndex="-1"/>
            </SGPopover></>
      }

      return <SGTextIntl intlId={label}/>;
   }

   const computeErrorMessage = (): string => {
      if (errors && errors[name]) {
         switch (errors[name].type) {
            case "minLength":
               return intl.formatMessage({id: minLengthMessage || "common.validation.string.length.min"}, minLength ? {min: String(minLength)} : {});
            case "maxLength":
               return intl.formatMessage({id: maxLengthMessage || "common.validation.string.length.max"}, maxLength ? {max: String(maxLength)} : {});
            case "pattern":
               return intl.formatMessage({id: patternMessage || "common.validation.string.pattern"});
            case "validate":
               return intl.formatMessage({id: validateMessage || "common.validation.string.validate"});
            case "required":
            default:
               return intl.formatMessage({id: "common.required"});
         }
      }

      return "";
   }

   const computeDefaultInputType = (): string => {
      if (isPassword) {
         return showPassword ? "text" : "password";
      }

      return "text";
   }

   return (
      <Controller control={control} name={name} rules={validationRules} defaultValue={defaultValue || null} render={({onChange, value}) => (
         <SGInput name={name} label={computeLabelNode()} value={value} type={computeDefaultInputType()} inputmode="text"
                  placeholder={placeholder && intl.formatMessage({id: placeholder})} status={(errors && errors[name]) ? "error" : "default"}
                  disabled={disabled} defaultValue={defaultValue} data-cy={cypressName} validate={computeErrorMessage()}
                  suffix={isPassword ? <ShowPassword showPassword={showPassword} setShowPassword={setShowPassword}/> : <></>}
                  onChange={(value: string) => {
                     onChange(value);
                     if (onValueChange) {
                        onValueChange(value);
                     }
                  }}
         />)}
      />
   );
};

export {StringInput};
