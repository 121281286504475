import React, {FunctionComponent, useState} from "react";
import "./RecommendationElement.scss";
import {Recommendation} from "../../../../store/recommendations/types";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGCard, SGCardMeta} from "sg-card";
import {SGIcon} from "sg-icon";
import {SGAvenirNavFlecheBtnLien, SGAvenirStyledPrendreContact} from "sg-icon-pack-base";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTag} from "sg-tag";
import {useIntl} from "react-intl";
import {replaceSpecialCharsAndLowercase} from "../../../utils/formatting/replaceSpecialCharsAndLowercase";
import {SGMessage} from "sg-message";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGSpace} from "sg-space";
import {SGModal} from "sg-modal";
import {SGButtonGroup} from "sg-button";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {GoFurtherButton} from "../GoFurtherButton/GoFurtherButton";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface RecommendationProps {
   recommendation: Recommendation;
   cypressName: string;
}

const RecommendationElement: FunctionComponent<RecommendationProps> = (props: RecommendationProps) => {
   const {recommendation, cypressName} = props;
   const intl = useIntl();
   const [showModal, setShowModal] = useState<boolean>(false);
   const { trackClick } = useTracking();

   const onClick = () => {
      trackClick(`clic-sur-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: recommendation.category}))}-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: recommendation.title}))}`);
      setShowModal(true);
   };

   const onClickInternalUrl = (url: string) => {
      redirectToRelativePath(url);
   };

   const onClickExternalUrl = (url: string) => {
      window.open(url);
   };

   return (
      <>
         <SGCard onClick={() => onClick()} data-cy={cypressName} coversize="md" clickable bordered
                 cover={(<img alt="recommendation" src={recommendation.image}/>)}
                 actions={[
                    <SGButtonIntl iconPosition="right" key="arrow" type="tertiary" data-cy={cypressName} onClick={() => onClick()}
                                  icon={<SGIcon currentcolor component={<SGAvenirNavFlecheBtnLien/>}/>} intlId="project.action.plan.discover.more"/>
                 ]}>
            {recommendation.priority === 1 && (
               <div className="recommendation-element__black-triangle">
                  <span className="recommendation-element__star">✩</span>
               </div>
            )}
            <SGTag tagcolor="10">
               <SGTextIntl intlId={`advices.category.${recommendation.category}`}/>
            </SGTag>
            <SGCardMeta
               title={(<SGTextIntl size="xl" intlId={recommendation.title} strong/>)}
               description={<SGTextIntl intlId={recommendation.description} transformations={{__projectName__: recommendation.projectName}}/>}
            />
         </SGCard>
         <SGModal visible={showModal}
                  onCancel={() => setShowModal(false)}>
            <SGMessage
               icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
               title={<SGTitleIntl intlId={recommendation.title} cypressName={`recommendation-modal-title-${recommendation.recommendationType}`}/>}>
               <SGSpace direction="vertical" textalign="left">
                  <SGTextIntl intlId={recommendation.modalText}
                              transformations={{
                                 ...recommendation.modalTextTransformations,
                                 linebreak: <><br/><br/></>,
                                 strong: (word: string) => <strong>{word}</strong>,
                                 i: (word: string) => <i>{word}</i>,
                              }}/>
                  {recommendation.modalCallToAction &&
                     <SGButtonGroup align="center">
                        <GoFurtherButton size="md"/>
                     </SGButtonGroup>
                  }
                  <SGButtonGroup align="right" layout="column">
                     {recommendation.modalTextUrl &&
                        <SGButtonIntl iconPosition="right" key="arrow" type="tertiary" cypressName={`${cypressName}-modal-link`}
                                      onClick={() => onClickExternalUrl(recommendation.modalTextUrl)}
                                      icon={<SGIcon currentcolor component={<SGAvenirNavFlecheBtnLien/>}/>} intlId="project.action.plan.discover.more"/>
                     }
                     {recommendation.modalAppUrl &&
                        <SGButtonIntl iconPosition="right" key="arrow" type="tertiary" cypressName={`${cypressName}-modal-link`}
                                      onClick={() => onClickInternalUrl(recommendation.modalAppUrl)}
                                      icon={<SGIcon currentcolor component={<SGAvenirNavFlecheBtnLien/>}/>} intlId="advices.title.take.action"/>
                     }
                  </SGButtonGroup>
               </SGSpace>
            </SGMessage>
         </SGModal>
      </>
   );
};

export {RecommendationElement};
