export interface ProfileChoice {
   id?: number;
   retirementProfileType: RetirementProfileType;
}

export interface ProfileChoiceState {
   profileChoice: ProfileChoice;
}

export const SEND_RETIREMENT_CHOICE = "SEND_RETIREMENT_CHOICE";
export const RETIREMENT_PROFIL_CHOICE_FETCHED = "RETIREMENT_PROFIL_CHOICE_FETCHED";

export enum RetirementProfileType {
   READY = "READY",
   INTERESTED = "INTERESTED",
   DONT_UNDERSTAND = "DONT_UNDERSTAND",
   DONT_KNOW = "DONT_KNOW",
}

export enum RetirementProfileTrack {
   READY = "bloc-haut-gauche",
   INTERESTED = "bloc-haut-droit",
   DONT_UNDERSTAND = "bloc-bas-gauche",
   DONT_KNOW = "bloc-bas-droit",
}
