import {FETCH_CAN_MEETING_BE_SENT, FETCH_SIMULATION_PDF, SCHEDULE_MEETING} from "./types";

export const getSimulationPdf = (incExpSvg: string, balanceSvg: string, email: string, alerts: Array<string>) => ({
   type: FETCH_SIMULATION_PDF,
   payload: {
      incExpSvg,
      balanceSvg,
      email,
      alerts
   }
});

export const getCanScheduleMeeting = (cookieProvider: string) => ({
   type: FETCH_CAN_MEETING_BE_SENT,
   payload: cookieProvider
});

export const scheduleMeeting = (incExpSvg: string, balanceSvg: string, email: string, alerts: Array<string>) => ({
   type: SCHEDULE_MEETING,
   payload: {
      incExpSvg,
      balanceSvg,
      email,
      alerts
   }
});
