import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGText } from "sg-typo";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { DASHBOARD } from "website/utils/privateRoutes";
import "./ObjectifRevenus.scss";
import { SGBox } from "sg-space";

interface MonPatrimoineProps {
  monthlyIncomeGoal?: number;
}

const ObjectifRevenus: FunctionComponent<MonPatrimoineProps> = (
  props: MonPatrimoineProps
) => {
  const { monthlyIncomeGoal } = props;
  const intl = useIntl();
  const history = useHistory();
  const { trackClick } = useTracking();

  const redirect = () => {
    trackClick(
      `mon-objectif-de-revenu::clic-sur-${
        monthlyIncomeGoal
          ? "je-modifie-mon-objectif-de-revenus"
          : "j-estime-mon-objectif-de-revenus"
      }`
    );
    history.push(DASHBOARD);
  };

  return (
    <SGCard
      coversize="md"
      hoverable
      borderleft="7px solid #545454"
      title={
        <SGText className="objectif-revenus__title">
          {intl.formatMessage({ id: "monPlan.objectif.title" })}
        </SGText>
      }
      className="objectif-revenus__card"
    >
      <SGGridRow>
        <SGGridCol span={12}>
          <SGText>
            <SGTextIntl
              intlId={
                monthlyIncomeGoal
                  ? "monPlan.objectif.done"
                  : "monPlan.objectif.not.done"
              }
              size="m"
              transformations={{
                monthlyIncomeGoal: monthlyIncomeGoal?.toLocaleString("fr-FR"),
                b: (word: string) => <strong>{word}</strong>,
              }}
            />
          </SGText>
        </SGGridCol>
      </SGGridRow>
      <SGBox margin={{ top: "sm" }} textalign="right">
        <SGButton
          onClick={redirect}
          type="tertiary"
          icon={
            <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
          }
          iconposition="right"
        >
          {intl.formatMessage({
            id: monthlyIncomeGoal
              ? "monPlan.objectif.modify"
              : "monPlan.objectif.create",
          })}
        </SGButton>
      </SGBox>
    </SGCard>
  );
};

export { ObjectifRevenus };
