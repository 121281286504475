import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { SGGridRow } from "sg-grid";
import { SGModal } from "sg-modal";
import Spinner from "../../atoms/SpinnerAssu/SpinnerAssu";
import "./LoaderParcoursRis.scss";
import { SGText } from "sg-typo";
import { SGBox } from "sg-space";

type LoaderParcoursRisProps = {
  showLoader: boolean;
};

export const LoaderParcoursRis: FunctionComponent<LoaderParcoursRisProps> = (
  props: React.PropsWithChildren<LoaderParcoursRisProps>
) => {
  const { showLoader } = props;

  return (
    <SGModal
      className="loader-modal modal__assu"
      visible={showLoader}
      centered
      header={
        <SGText size="xxl">
          <FormattedMessage
            id="parcoursRis.loader.title"
          />
        </SGText>
      }
    >
      <SGBox alignchildren={["center"]}>
        <Spinner />
      </SGBox>
    </SGModal>
  );
}
