import React, {FunctionComponent, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {computeSurvivingPartnerShare} from "../../../../../store/survivingPartnerSimulator/actions";
import {State} from "../../../../../store/store";
import {PossessionType} from "../../../../../store/assets/types";
import {SGCard} from "sg-card";
import {SGSpace} from "sg-space";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGSelectOption} from "sg-select";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {SGButtonGroup} from "sg-button";
import {SGText} from "sg-typo";
import {getAssetCategory} from "../../../../../store/assets/utils";
import {SGContent} from "sg-layout";
import {CustodyType, FamilyStatus, HeirsType} from "../../../../../store/members/types";
import {CustomNumberFormat} from "../../../../utils/formatting/CustomNumberFormat";
import {updateAccountAction} from "../../../../../store/account/actions";
import {useAccount} from "../../../hooks/useAccount";
import {SurvivingPartnerSimulatorParameter, SurvivingPartnerSimulatorResult} from "store/survivingPartnerSimulator/types";
import {convertNumberToStringWithSeparators} from "../../../../utils/formatting/numberFormatter";

const SurvivingPartnerSimulator: FunctionComponent = () => {
   const intl = useIntl();
   const dispatch = useDispatch();
   const account = useAccount();
   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange"
   });
   const cypressName = "surviving-partner-simulator";

   const survivingPartnerSimulatorResults: SurvivingPartnerSimulatorResult[] = useSelector<State, SurvivingPartnerSimulatorResult[]>((state) => state.survivingPartnerSimulator.result);
   const hasFetchSurvivingPartnerSimulatorResults = useSelector<State, boolean>((state) => state.survivingPartnerSimulator.hasFetched);

   const heirsTypes: HeirsType[] = Object.values(HeirsType).filter((ht) => ht !== HeirsType.PARTNER);
   const familyStatuses: FamilyStatus[] = [FamilyStatus.ME, FamilyStatus.PARTNER,];

   const [targetType, setTargetType] = useState<HeirsType>();
   const [familyStatus, setFamilyStatus] = useState<FamilyStatus>();
   const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

   useEffect(() => {
      if (!account.visitedSuccessionSimulator) {
         dispatch(updateAccountAction({...account, visitedSuccessionSimulator: true}));
      }
   }, []);

   const onSelectFamilyType = (newFamilyType: number | string) => {
      const targetType = heirsTypes.filter((ht) => ht === newFamilyType)[0];
      setTargetType(targetType);
   };

   const onSelectSimulationTarget = (newSimulationTarget: string | number) => {
      const targetedStatus: FamilyStatus = newSimulationTarget === FamilyStatus.ME ? FamilyStatus.ME : FamilyStatus.PARTNER;
      setFamilyStatus(targetedStatus);
   };

   const onSubmit = () => {
      let custodyType: CustodyType | undefined;
      let heirFamilyStatus: string;
      let bothParent = false;

      switch (targetType) {
         case HeirsType.CHILDREN_FROM_PREVIOUS_UNION:
            heirFamilyStatus = FamilyStatus.CHILD;
            custodyType = CustodyType.NO_CUSTODY;
            break;
         case HeirsType.COMMON_CHILDREN:
            heirFamilyStatus = FamilyStatus.CHILD;
            custodyType = CustodyType.FULL_CUSTODY;
            break;
         case HeirsType.BOTH_PARENTS:
            heirFamilyStatus = FamilyStatus.PARENT;
            bothParent = true;
            break;
         default:
            heirFamilyStatus = `${targetType}`;
      }

      const heir: SurvivingPartnerSimulatorParameter = {
         familyStatus,
         heirFamilyStatus,
         custodyType,
         bothParent,
      };
      dispatch(computeSurvivingPartnerShare(heir));
      setFormSubmitted(true);
   };

   const getTotalValuationSpouseShare = () => {
      const getTotalPerAsset = (survivingPartnerSimulatorResult: SurvivingPartnerSimulatorResult): number => {
         const totalPerAsset: number | undefined = survivingPartnerSimulatorResult.valuationByPossessionTypeChoice[PossessionType.PLEINE_PROPRIETE];

         return !totalPerAsset ? 0 : +totalPerAsset;
      };

      return survivingPartnerSimulatorResults
         .map((value) => getTotalPerAsset(value))
         .reduce((previousValue, currentValue) => previousValue + currentValue);
   };

   const displayResult = () => (
      <SGCard bordered>
         <SGTextIntl intlId="inheritance.simulator.surviving-partner.page.spouse_share"
                     transformations={{
                        amount: <CustomNumberFormat displayType="text" value={convertNumberToStringWithSeparators(getTotalValuationSpouseShare())}/>
                     }}/>
         <SGContent>
            <SGSpace direction="vertical" textalign="center" size="sm">
               {[...survivingPartnerSimulatorResults].sort((shareChoice1, shareChoice2) => getAssetCategory(shareChoice1.asset.assetType).priority - getAssetCategory(shareChoice2.asset.assetType).priority).map((shareChoice) => (
                  <SGCard key={shareChoice.asset.id + shareChoice.familyStatus} disableautomargin
                          data-cy={`surviving-partner-simulator-${shareChoice.asset.assetName}`}
                          borderleft={`.3rem solid ${getAssetCategory(shareChoice.asset.assetType).color.hexCode}`}>
                     <SGSpace>
                        <SGText strong>{shareChoice.asset.assetName}</SGText>
                        <SGSpace direction="vertical" textalign="right">
                           <SGTextIntl intlId="inheritance.simulator.surviving-partner.page.result.PLEINE_PROPRIETE" transformations={{
                              amount: <CustomNumberFormat displayType="text"
                                                          value={convertNumberToStringWithSeparators(shareChoice.valuationByPossessionTypeChoice[PossessionType.PLEINE_PROPRIETE])}/>
                           }}/>
                           {displaySubResult(shareChoice)}
                        </SGSpace>
                     </SGSpace>
                  </SGCard>
               ))}
            </SGSpace>
         </SGContent>
      </SGCard>
   );

   const displaySubResult = (shareChoice: SurvivingPartnerSimulatorResult) => {
      const validKeys = Object.keys(shareChoice.valuationByPossessionTypeChoice).filter((key) => key === PossessionType.QUASI_USUFRUIT || key === PossessionType.USUFRUIT);
      if (!validKeys || validKeys.length === 0) {
         return "";
      }
      const otherPossessionType = validKeys[0];

      return (
         <SGTextIntl intlId="inheritance.simulator.surviving-partner.page.result.other.propertyType" transformations={{
            amount: <CustomNumberFormat displayType="text"
                                        value={convertNumberToStringWithSeparators(shareChoice.valuationByPossessionTypeChoice[otherPossessionType])}/>,
            propertyType: intl.formatMessage({id: `possessionType.${otherPossessionType}`})
         }}/>
      );
   };

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="inheritance.simulator.surviving-partner.page.title" cypressName="surviving-partner-simulator-title"/>
         <SGTextIntl intlId="inheritance.simulator.surviving-partner.page.paragraph"/>
         <FormProvider {...methods}>
            <SGSpace direction="vertical">
               <FormInputs colLength={6}>
                  <SelectInput onValueChange={onSelectFamilyType} cypressName={`${cypressName}-edit-heirs`} name="heirsType" required
                               placeholder={intl.formatMessage({id: "inheritance.simulator.surviving-partner.page.type.placeholder"})}
                               label="inheritance.simulator.surviving-partner.page.type.label">
                     {heirsTypes.map((heirsType: HeirsType) => (
                        <SGSelectOption key={heirsType} data-cy={`${cypressName}-edit-heirs-${heirsType}`} value={heirsType}>
                           {intl.formatMessage({
                              id: `inheritance.surviving-partner.heirsType.${heirsType}`,
                           })}
                        </SGSelectOption>
                     ))}
                  </SelectInput>
                  <SelectInput onValueChange={onSelectSimulationTarget} cypressName={`${cypressName}-edit-target`} required name="simulationTarget"
                               placeholder={intl.formatMessage({id: "inheritance.simulator.surviving-partner.page.target.placeholder"})}
                               label="inheritance.simulator.surviving-partner.page.target.label">
                     {familyStatuses.map(
                        (familyStatus: FamilyStatus) => (
                           <SGSelectOption key={familyStatus} data-cy={`${cypressName}-edit-target-${familyStatus}`} value={familyStatus}>
                              {intl.formatMessage({
                                 id: `family.status.${familyStatus}`,
                              })}
                           </SGSelectOption>
                        )
                     )}
                  </SelectInput>
               </FormInputs>
               <SGButtonGroup align="center">
                  <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName="rd-edit-submit" onClick={onSubmit}
                                intlId="inheritance.simulator.surviving-partner.page.compute.button"/>
               </SGButtonGroup>
            </SGSpace>
         </FormProvider>
         {formSubmitted && hasFetchSurvivingPartnerSimulatorResults && survivingPartnerSimulatorResults.length > 0 && (
            displayResult()
         )}
         {formSubmitted && hasFetchSurvivingPartnerSimulatorResults && survivingPartnerSimulatorResults.length === 0 && (
            <div data-cy={`${cypressName}-no-result`}>
               <SGTextIntl intlId="inheritance.simulator.surviving-partner.page.warning_no_share"/>
            </div>
         )}
      </SGSpace>
   );
};

export {SurvivingPartnerSimulator};
