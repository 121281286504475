import { FunctionComponent, useEffect, useState } from "react";
import { Controller, FieldValues, UseFormMethods } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGCheckBox, SGCheckBoxGroup } from "sg-checkbox";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import {
  PersonalInformationsState,
  hasFetchedPersonalInformations,
} from "store/personalInformations/types";
import { SituationsHandicapEnum } from "store/simulationAssu/types";
import { State } from "store/store";

const CONSENTI = "CONSENTI";

interface FormSituationsHandicapProps {
  situationsHandicap: SituationsHandicapEnum[];
  setSituationsHandicap: (valeur: SituationsHandicapEnum[]) => void;
  formMethods: UseFormMethods<FieldValues>;
}

const FormSituationsHandicap: FunctionComponent<FormSituationsHandicapProps> = (
  props: FormSituationsHandicapProps
) => {
  const { situationsHandicap, setSituationsHandicap, formMethods } = props;

  const [aConsentiSituationsHandicap, setAConsentiSituationsHandicap] =
    useState<boolean>();
  const [isSituationsHandicap, setIsSituationsHandicap] =
    useState<SituationsHandicapEnum>();

  const optionsIsSituationsHandicap: SituationsHandicapEnum[] = [
    SituationsHandicapEnum.OUI,
    SituationsHandicapEnum.NON,
    SituationsHandicapEnum.NE_PAS_REPONDRE,
  ];

  const optionsSituationsHandicap: SituationsHandicapEnum[] = Object.values(
    SituationsHandicapEnum
  ).filter((value) => !optionsIsSituationsHandicap.includes(value));

  const personalInformationsState: PersonalInformationsState = useSelector<
    State,
    PersonalInformationsState
  >((state) => state.personalInformations);

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  useEffect(() => {
    formMethods.setValue("isSituationsHandicap", isSituationsHandicap, {
      shouldValidate: formMethods.formState.submitCount > 0,
    });
  }, [isSituationsHandicap]);

  useEffect(() => {
    formMethods.setValue(
      "consentementSituationsHandicap",
      aConsentiSituationsHandicap,
      {
        shouldValidate: formMethods.formState.submitCount > 0,
      }
    );
    formMethods.setValue("situationsHandicap", situationsHandicap, {
      shouldValidate: formMethods.formState.submitCount > 0,
    });
  }, [aConsentiSituationsHandicap, situationsHandicap]);

  // Définit les champs de l'étape 2 après la récupération des données personalInformations
  useEffect(() => {
    if (hasFetchedPersonalInformations(personalInformationsState)) {
      setAConsentiSituationsHandicap(
        personalInformationsState.personalInformations
          .dateConsentementSituationsHandicap !== undefined
      );

      const situationsHandicapList =
        personalInformationsState.personalInformations.situationsHandicap ?? [];
      if (situationsHandicapList.includes(SituationsHandicapEnum.NON)) {
        setIsSituationsHandicap(SituationsHandicapEnum.NON);
      } else if (
        situationsHandicapList.includes(SituationsHandicapEnum.NE_PAS_REPONDRE)
      ) {
        setIsSituationsHandicap(SituationsHandicapEnum.NE_PAS_REPONDRE);
      } else if (situationsHandicapList.length !== 0) {
        setIsSituationsHandicap(SituationsHandicapEnum.OUI);
      }
      setSituationsHandicap(situationsHandicapList);
    }
  }, [personalInformationsState.hasFetched]);

  const handleOnChangeEstSituationHandicap = (
    value: SituationsHandicapEnum
  ) => {
    setIsSituationsHandicap(value);
    if (value === SituationsHandicapEnum.OUI) {
      setSituationsHandicap([]);
    } else {
      setSituationsHandicap([value]);
    }

    setAConsentiSituationsHandicap(false);
  };

  return (
    <SGSpace direction="vertical" disableautomargin>
      <SGBlock
        key="bloc-reponse"
        className="parcours-ris-complements__select--wrapper"
      >
        <Controller
          control={formMethods.control}
          name="isSituationsHandicap"
          rules={{
            required: true,
          }}
          render={({ onChange }) => (
            <SGSelect
              placeholder="Sélectionnez"
              value={isSituationsHandicap ?? []}
              size={isPhone ? "xl" : "l"}
              onChange={(value: SituationsHandicapEnum) => {
                onChange(value);
                handleOnChangeEstSituationHandicap(value);
              }}
              label={
                <SGText size="l">
                  <FormattedMessage
                    id="form.situationsHandicap.isSituationsHandicap"
                    values={{ linebreak: isPhone ? " " : <br /> }}
                  />
                </SGText>
              }
              status={formMethods.errors?.isSituationsHandicap && "error"}
              validate={
                <FormattedMessage id="form.situationsHandicap.isSituationsHandicap.required" />
              }
            >
              {optionsIsSituationsHandicap.map(
                (value: SituationsHandicapEnum) => (
                  <SGSelectOption key={value} value={value}>
                    <FormattedMessage
                      id={`form.situationsHandicap.isSituationsHandicap.option.${value}`}
                    />
                  </SGSelectOption>
                )
              )}
            </SGSelect>
          )}
        />
        {isSituationsHandicap === SituationsHandicapEnum.NE_PAS_REPONDRE && (
          <SGBox margin={{ top: "md" }}>
            <SGAlert
              icon={<SGIcon component={<SGAvenirStatusInfo />} size="s" />}
              message={
                <FormattedMessage id="form.situationsHandicap.isSituationsHandicap.option.NE_PAS_REPONDRE.alert" />
              }
              span={7}
              type="info"
              className="parcours-ris-complements__alert"
            />
          </SGBox>
        )}
      </SGBlock>
      {isSituationsHandicap === SituationsHandicapEnum.OUI && (
        <Controller
          key="consentement"
          control={formMethods.control}
          name="consentementSituationsHandicap"
          rules={{
            required: true,
          }}
          render={({ onChange }) => (
            <SGCheckBoxGroup
              layout="column"
              onChange={(value: string[]) => {
                onChange(value);
                setAConsentiSituationsHandicap((consentement) => !consentement);
              }}
              value={aConsentiSituationsHandicap ? [CONSENTI] : []}
              status={
                formMethods.errors?.consentementSituationsHandicap && "error"
              }
              validate={
                formMethods.errors?.consentementSituationsHandicap && (
                  <FormattedMessage id="form.situationsHandicap.consentementSituationHandicap.required" />
                )
              }
            >
              <SGCheckBox value={CONSENTI}>
                <FormattedMessage id="form.situationsHandicap.consentementSituationHandicap" />
              </SGCheckBox>
            </SGCheckBoxGroup>
          )}
        />
      )}
      {isSituationsHandicap === SituationsHandicapEnum.OUI &&
        aConsentiSituationsHandicap && (
          <SGBox key="box-choix" margin={{ top: "xs", left: "md" }} forcemargin>
            <SGGridRow>
              <SGGridCol span={10} sm={12}>
                <Controller
                  control={formMethods.control}
                  name="situationsHandicap"
                  rules={{
                    required: true,
                    validate: (values) => values.length > 0,
                  }}
                  render={({ onChange }) => (
                    <SGCheckBoxGroup
                      layout="column"
                      disableautomargin
                      onChange={(value: SituationsHandicapEnum[]) => {
                        onChange(value);
                        setSituationsHandicap(value);
                      }}
                      value={situationsHandicap}
                      status={formMethods.errors?.situationsHandicap && "error"}
                      validate={
                        formMethods.errors?.situationsHandicap && (
                          <FormattedMessage id="form.situationsHandicap.situationsHandicap.required" />
                        )
                      }
                    >
                      {optionsSituationsHandicap.map(
                        (value: SituationsHandicapEnum) => (
                          <SGCheckBox key={value} value={value}>
                            <FormattedMessage
                              id={`form.situationsHandicap.situationsHandicap.option.${value}`}
                            />
                          </SGCheckBox>
                        )
                      )}
                    </SGCheckBoxGroup>
                  )}
                />
              </SGGridCol>
            </SGGridRow>
          </SGBox>
        )}
    </SGSpace>
  );
};

export { FormSituationsHandicap };
