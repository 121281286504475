import React, {FunctionComponent, useEffect} from "react";
import {SGContent} from "sg-layout";
import {useFamily} from "../../components/hooks/useFamily";
import {SGAlertIntl} from "../../components/molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../../components/atoms/SGTextIntl/SGTextIntl";
import {useNumberProjects} from "../../components/hooks/useNumberProjects";
import {createRetirementProjectValuesAction, resetRetirementProjectValuesInStoreAction} from "../../../store/projects/actions";
import {RetirementSimulation} from "../../../store/projects/types";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {SpinnerSize, SpinnerWrapper} from "../../components/atoms/SpinnerWrapper/SpinnerWrapper";
import {RetirementProject} from "website/components/organisms/Projects/RetirementProject/RetirementProject";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../components/atoms/SGButtonIntl/SGButtonIntl";
import {redirectToRelativePath} from "../../utils/routes/routing";
import {NEW_PROJECTS} from "../../utils/privateRoutes";

const RetirementProjectPage: FunctionComponent = () => {
   const dispatch = useDispatch();
   const family = useFamily();
   const numberProjects = useNumberProjects();

   const myRetirement = useSelector<State, RetirementSimulation | undefined>((state) => state.projects.retirementProject && state.projects.retirementProject.myRetirement);
   const partnerRetirement = useSelector<State, RetirementSimulation | undefined>((state) => state.projects.retirementProject && state.projects.retirementProject.partnerRetirement);

   const hasFetchedMyRetirementSimulation = useSelector<State, boolean>((state) => state.projects.hasFetchedMyRetirementSimulation);
   const hasFetchedPartnerRetirementSimulation = useSelector<State, boolean>((state) => state.projects.hasFetchedPartnerRetirementSimulation);

   useEffect(() => {
      dispatch(resetRetirementProjectValuesInStoreAction());
   }, []);

   const isCreateRetirementProjectButtonDisabled = () => !hasFetchedMyRetirementSimulation
      || (family && family.partner && !hasFetchedPartnerRetirementSimulation)

   return (
      <SGContent span={10}>
         <SpinnerWrapper displayComponent={family !== undefined} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
            {numberProjects === 3 &&
               <SGAlertIntl type="warning" cypressName="retirement-error-element-frame" title="common.warning">
                  <SGTextIntl intlId="project.error.already-three"/>
               </SGAlertIntl>
            }

            {family && family.me &&
               <RetirementProject member={family.me} retirementSimulation={myRetirement}/>
            }
            {family && family.partner && hasFetchedMyRetirementSimulation &&
               <RetirementProject member={family.partner} retirementSimulation={partnerRetirement}/>
            }

            {numberProjects !== 3 && (
               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={isCreateRetirementProjectButtonDisabled()}
                                cypressName="retirement-project-next" intlId="new.project.create"
                                onClick={() => dispatch(createRetirementProjectValuesAction())}/>
                  <SGButtonIntl type="link" cypressName="retirement-project-previous" onClick={() => redirectToRelativePath(NEW_PROJECTS)}
                                intlId="common.previous"/>
               </SGButtonGroup>
            )}
         </SpinnerWrapper>
      </SGContent>
   );
};

export {RetirementProjectPage};
