import React, {FunctionComponent} from "react";
import {ProfileRealizedDonations} from "../components/organisms/Profile/ProfileSuccession/ProfileRealizedDonations/ProfileRealizedDonations";
import {SGContent} from "sg-layout";

const RealizedDonationsPage: FunctionComponent = () => (
   <SGContent span={8}>
      <ProfileRealizedDonations/>
   </SGContent>
);

export {RealizedDonationsPage};
