import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RetirementCategorySimulation } from "store/members/types";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import { nextStepAction } from "store/tunnel/actions";
import { updateWorkIncomesStore } from "store/workIncomes/action";
import { WorkIncome, WorkIncomePathType } from "store/workIncomes/types";
import { updateWorkIncomesHistoryStore } from "store/workIncomesHistory/action";
import { WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useAccount } from "website/components/hooks/useAccount";
import { WorkIncomes } from "../../WorkIncomes/WorkIncomes";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const SimulateurFlashWorkIncomes: FunctionComponent = () => {
    const dispatch = useDispatch();
    const account = useAccount();
    const { trackClick, trackPage } = useTracking();

    const [carrierStartMonth, setCarrierStartMonth] = useState<number>();
    const [carrierStartYear, setCarrierStartYear] = useState<number>();
    const [secteurPro, setSecteurPro] = useState<string>();
    const [montantSalaireAnnuel, setMontantSalaireAnnuel] = useState<number>();
    const [memberId, setMemberId] = useState<number>();
    const [showPopin, setShowPopin] = useState(false);
    const [hasSeenInfo, setHasSeenInfo] = useState(false);

    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );
    const workIncomesHistoryData: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);

    const onHideModal = () => {
        setShowPopin(false);
        setHasSeenInfo(true);
    };

    const onSubmit = () => {
        if (montantSalaireAnnuel === 0 || (montantSalaireAnnuel !== undefined && montantSalaireAnnuel >= 10000) || hasSeenInfo) {
            if (carrierStartMonth !== undefined && carrierStartYear !== undefined && secteurPro && montantSalaireAnnuel !== undefined) {
                const startMonth = carrierStartMonth < 10 ? `0${carrierStartMonth}` : carrierStartMonth;
                dispatch(
                    updateRetirementSimulation({
                        ...simulationAssuState.retirementSimulationParameters,
                        montantAnnuelSalaire: montantSalaireAnnuel,
                        salaireBrutNet:
                            secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE || secteurPro === RetirementCategorySimulation.FONCTIONNAIRE
                                ? 1
                                : 2,
                        dateDebCarriere: `01-${startMonth}-${carrierStartYear}`,
                        memberId,
                    })
                );

                const workIncomes: WorkIncome[] = [
                    {
                        userId: account.id,
                        originPath: WorkIncomePathType.ONBOARDING,
                        profession: parseInt(secteurPro),
                        income: montantSalaireAnnuel,
                    },
                ];

                dispatch(updateWorkIncomesStore(workIncomes));

                // FIXME Décalage de 2h retirant 1 jour dans le toString => ajout de 12h en paliatif temporaire
                const workIncomesHistoryToSave: WorkIncomesHistory = {
                    ...workIncomesHistoryData.workIncomesHistory,
                    carrierStartDate: new Date(carrierStartYear, carrierStartMonth - 1, 1, 12),
                    currentYearlyAmount: montantSalaireAnnuel,
                };

                dispatch(updateWorkIncomesHistoryStore(workIncomesHistoryToSave));
                trackClick('mes-revenus::clic-sur-suivant');
                dispatch(nextStepAction());
            }
        } else {
            setShowPopin(true);
        }
    };

    useEffect(() => {
        trackPage("simulateur-flash", "mes-revenus", PAGE_TYPE_FORMULAIRE, "simulation", "2");
    }, [])

    return (
        <WorkIncomes
            onSubmit={onSubmit}
            showPopin={showPopin}
            onHideModal={onHideModal}
            carrierStartMonth={carrierStartMonth}
            setCarrierStartMonth={setCarrierStartMonth}
            carrierStartYear={carrierStartYear}
            setCarrierStartYear={setCarrierStartYear}
            montantSalaireAnnuel={montantSalaireAnnuel}
            setMontantSalaireAnnuel={setMontantSalaireAnnuel}
            secteurPro={secteurPro}
            setSecteurPro={setSecteurPro}
            setMemberId={setMemberId}
        />
    );
};
