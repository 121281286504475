import {ReactNode} from "react";

export interface RecommendationState {
   recommendations: Recommendation[];
   hasFetched: boolean;
   cannotFetchRecommendations: boolean;
   indicators: Indicator[];
}

export interface Indicator {
   indicatorType: string;
   color: string;
   values: Array<IndicatorParameter>;
   messageKey: string;
}

export interface IndicatorParameter {
   key: string;
   value: string;
}

export enum IndicatorType {
   TAX_RATE = "TAX_RATE",
   DIVERSIFICATION = "DIVERSIFICATION",
   RETIREMENT = "RETIREMENT",
   TRANSMISSION = "TRANSMISSION",
}

export interface Recommendation {
   id: number;
   clientId: number;
   recommendationType: RecommendationType;
   outputData: string;
   priority: number;
   title: string;
   description: string;
   modalText: string;
   modalTextUrl: string;
   modalAppUrl: string;
   modalTextTransformations: Record<string, ReactNode>,
   modalCallToAction?: boolean;
   button: string;
   category: RecommendationCategory;
   projectName: string;
   projectId: number | undefined;
   image: string;
}

export enum RecommendationType {
   PROJECT_ADJUST = "PROJECT_ADJUST",
   NO_ADVICE = "NO_ADVICE",
   CREATE_EMERGENCY_SAVINGS = "CREATE_EMERGENCY_SAVINGS",
   SAVE_ON_EMERGENCY_SAVINGS = "SAVE_ON_EMERGENCY_SAVINGS",
   SAVE_FOR_RETIREMENT = "SAVE_FOR_RETIREMENT",
   BUY_PRIMARY_REAL_ESTATE = "BUY_PRIMARY_REAL_ESTATE",
   CREDIT_NEGOTIATION = "CREDIT_NEGOTIATION",
   LOAN_REPAYMENT = "LOAN_REPAYMENT",
   TAX_EXEMPTION = "TAX_EXEMPTION",
   USE_RELEASED_MONEY_FROM_LOAN = "USE_RELEASED_MONEY_FROM_LOAN",
   EMERGENCY_SAVINGS_TOO_HIGH = "EMERGENCY_SAVINGS_TOO_HIGH",
   CHILDREN_EDUCATION_SAVINGS = "CHILDREN_EDUCATION_SAVINGS",
   TAKE_ACTION = "TAKE_ACTION",
   USE_CASH_FOR_PROJECTS = "USE_CASH_FOR_PROJECTS",
   CREATE_VALORIZATION_PROJECT = "CREATE_VALORIZATION_PROJECT",
   OPEN_LIFE_INSURANCE = "OPEN_LIFE_INSURANCE",
   DIVERSIFICATION_REAL_ESTATE_TO_HIGH = "DIVERSIFICATION_REAL_ESTATE_TO_HIGH",
   DIVERSIFICATION_REAL_ESTATE_HIGH = "DIVERSIFICATION_REAL_ESTATE_HIGH",
   ESTATE_FEES_PROTECT_PARTNER = "ESTATE_FEES_PROTECT_PARTNER",
   ESTATE_FEES_PROTECT_HIGH_KIDS_AND_PARTNER = "ESTATE_FEES_PROTECT_HIGH_KIDS_AND_PARTNER",
   ESTATE_FEES_PROTECT_AVERAGE_KIDS_AND_PARTNER = "ESTATE_FEES_PROTECT_AVERAGE_KIDS_AND_PARTNER",
   ESTATE_FEES_PROTECT_KIDS = "ESTATE_FEES_PROTECT_KIDS",
   MAKE_A_DEPOSIT_ON_LIFE_INSURANCE_BEFORE_70 = "MAKE_A_DEPOSIT_ON_LIFE_INSURANCE_BEFORE_70",
   PREVOYANCE = "PREVOYANCE",
   OPEN_CHILD_LIFE_INSURANCE = "OPEN_CHILD_LIFE_INSURANCE",
   DONATION_SURVIVING_SPOUSE = "DONATION_SURVIVING_SPOUSE",
   CHILDREN_TRANSMISSION = "CHILDREN_TRANSMISSION",
   CONSTRUCTION_WORK = "CONSTRUCTION_WORK"
}

export enum RecommendationCategory {
   SAVING = "SAVING",
   PROJECT = "PROJECT",
   LOAN = "LOAN",
   TAXATION = "TAXATION",
   ASSETS = "ASSETS",
   INSURANCE = "INSURANCE",
   DONATION = "DONATION",
   SUCCESSION = "SUCCESSION",
}

// Actions
export const FETCH_RECOMMENDATIONS = "FETCH_RECOMMENDATIONS";
