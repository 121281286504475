import "./CookieBanner.scss";
import React, {FunctionComponent, useState} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";

const CookieBanner: FunctionComponent = () => {
   const rememberMeKey = "acceptCookies";
   const [showCookieBanner, setShowCookieBanner] = useState<boolean>(
      localStorage.getItem(rememberMeKey) === null
   );

   const acceptCookies = () => {
      setShowCookieBanner(false);
      localStorage.setItem(rememberMeKey, "true");
   };

   return (
      <>
         {showCookieBanner && (
            <div className="cookie-banner">
               <div className="cookie-banner-bottom" data-cy="cookie-banner">
                  <SGTextIntl intlId="cookies-banner.main-text"/>
                  <SGButtonIntl type="link" onClick={acceptCookies} cypressName="cookie-banner-accept" intlId="cookies-banner.button"/>
               </div>
            </div>
         )}
      </>
   );
};

export { CookieBanner };
