import { SousThematique, Thematique } from "../molecules/Article/ArticleModel";

const agentsNonTitulairesEtat = "articles/images/cover/agentsNonTitulairesEtat.png";
const anticiper = "articles/images/cover/anticiper.svg";
const comprendreIndemnites = "articles/images/cover/comprendreIndemnites.jpg";
const comprendreRIS = "articles/images/cover/comprendreRIS.svg";
const cumulEmploiRetraite = "articles/images/cover/cumulEmploiRetraite.svg";
const enseignantsRetraite = "articles/images/cover/enseignants-retraite-carrousel.jpg";
const estimerBudgetRetraite = "articles/images/cover/estimerBudgetRetraite.svg";
const expertComptableAnticiperDepartRetraite = "articles/images/cover/expertComptableAnticiperDepartRetraite.jpg";
const femmeArtisan = "articles/images/cover/femmeArtisan.svg";
const femmeExploitantAgricole = "articles/images/cover/femmeExploitantAgricole.svg";
const femmeFoyer = "articles/images/cover/femmeFoyer.svg";
const fonctionnairesPensionRetraite = "articles/images/cover/fonctionnairesPensionRetraite.jpg"
const fonctionnaireRetraite = "articles/images/cover/fonctionnaireRetraite.png"
const idee = "articles/images/cover/idee.svg";
const impactEnfantRetraite = "articles/images/cover/impactEnfantRetraite.svg";
const impactPensionRetraite = "articles/images/cover/impactPensionRetraite.svg";
const liquiderDroitsRetraite = "articles/images/cover/liquiderDroitsRetraite.jpg";
const optimiserRetraiteObligatoire = "articles/images/cover/optimiserRetraiteObligatoire.svg";
const organiser = "articles/images/cover/organiser.svg";
const medecinLiberalCalculRetraite = "articles/images/cover/medecinLiberalCalculRetraite.jpg";
const partirRetraite = "articles/images/cover/partirRetraite.svg";
const partirRetraiteTauxPlein = "articles/images/cover/partir-retraite-taux-plein-carrousel.jpg";
const pensionReversion = "articles/images/cover/pensionReversion.svg";
const periodesInvalidite = "articles/images/cover/periodesInvalidite.svg";
const preparer = "articles/images/cover/preparer.svg";
const quiContacterRetraite = "articles/images/cover/quiContacterRetraite.jpg";
const rachatTrimestres = "articles/images/cover/rachatTrimestres-carrousel.svg";
const retraiteConjointCollaborateur = "articles/images/cover/retraiteConjointCollaborateur.svg";
const retraiteSansJargon = "articles/images/cover/retraiteSansJargon.svg";
const travailEtranger = "articles/images/cover/travailEtranger.svg";
const trimestreRetraite = "articles/images/cover/trimestreRetraite-carrousel.svg";
const serviceMilitaire = "articles/images/cover/serviceMilitaire.svg";
const salarieCommercantArtisanAgricole = "articles/images/cover/salarieCommercantArtisanAgricole.jpg";
const strategiePreparerRetraite = "articles/images/cover/strategiePreparerRetraite.svg";
const zoomRetraiteProgressive = "articles/images/cover/zoomRetraiteProgressive.svg";
const medecinLiberalRachatTrimestres = "articles/images/cover/VignetteMedecinLiberalRachatTrimestres.png";
const microEntrepreneurs = "articles/images/cover/microEntrepreneurs.png"
const arretMaladieSalarieImpact = "articles/images/cover/arretMaladieSalarieImpact.png";
const risDetecterErreurs = "articles/images/cover/risDetecterErreurs.png";
const conditionsRetraiteAnticipee = "articles/images/cover/conditionsRetraiteAnticipee.png";
const materniteImpactRetraite = "articles/images/cover/materniteImpactRetraite.png";
const artisansCommercants = "articles/images/cover/artisansCommercants.png";
const impositionPensionRetraite = "articles/images/cover/impositionPensionRetraite.jpg";
const cipavCalculRetraite = "articles/images/cover/cipavCalculRetraite.jpg";
const artisansCommercantsClefs = "articles/images/cover/artisansCommercantsClefs.jpg";
const professionLiberalRetraiteCIPAV = "articles/images/cover/professionLiberalRetraiteCIPAV.jpg";
const medecinLiberalAgeRetraite = "articles/images/cover/medecinLiberalAgeRetraite.jpg";
const enfantRetraiteProfessionLiberale = "articles/images/cover/enfantRetraiteProfessionLiberale.jpg";
const tempsPartielEnfants = "articles/images/cover/tempsPartielEnfants.jpg";

export enum TypeMedia {
   ARTICLE = "ARTICLE",
   VIDEO = "VIDEO",
   PODCAST = "PODCAST",
}
export interface Media {
   id: string;
   type: TypeMedia;
   image: string;
   time: number;
   date: Date;
   path: string;
   theme: Thematique;
}

export interface Article extends Media {
   sousThematique?: SousThematique;
   /**
    * Articles à mettre en avant par thématique sur l'accueil
    */
   favoriAccueil?: boolean;
   /**
    * Articles à mettre en avant dans le carrousel à la une d'une page thématique
    */
   favoriThematique?: boolean;
   /**
    * Articles à mettre en avant dans le carrousel du Dashboard
    * Le number permet de les ordonner
    */
   favoriDashboard?: number;
}

export function getIdTrackingType(typeMedia: TypeMedia) {
   switch (typeMedia) {
      case TypeMedia.ARTICLE:
         return "lire-l-article";
      case TypeMedia.PODCAST:
         return "ecouter-le-podcast";
      case TypeMedia.VIDEO:
         return "lire-la-video";
      default:
         return "";
   }
}

/**
 * Liste des articles triés par ordre décroissant de date dans un hook (pas ici).
 * Les 3 premiers sont affichés sur le carrousel à la une de l'accueil
 */
export const articles: Article[] = [
   {
      id: "expertComptableAnticiperDepartRetraite",
      type: TypeMedia.ARTICLE,
      image: expertComptableAnticiperDepartRetraite,
      time: 5,
      path: "expert-comptable-anticiper-depart-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 3),
   },
   {
      id: "tempsPartielEnfants",
      type: TypeMedia.ARTICLE,
      image: tempsPartielEnfants,
      time: 5,
      path: "temps-partiel-enfants",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.enfants,
      date: new Date(2024, 3),
      favoriThematique: true,
   },
   {
      id: "liquiderDroitsRetraite",
      type: TypeMedia.ARTICLE,
      image: liquiderDroitsRetraite,
      time: 5,
      path: "liquider-droits-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.depart,
      date: new Date(2024, 3),
      favoriThematique: true,
   },
   {
      id: "enseignantsRetraite",
      type: TypeMedia.ARTICLE,
      image: enseignantsRetraite,
      time: 6,
      path: "enseignants-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.fonctionnaires,
      date: new Date(2024, 3),
   },
   {
      id: "medecinLiberalAgeRetraite",
      type: TypeMedia.ARTICLE,
      image: medecinLiberalAgeRetraite,
      time: 5,
      path: "medecin-liberal-age-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 3),
   },
   {
      id: "fonctionnairesPensionRetraite",
      type: TypeMedia.ARTICLE,
      image: fonctionnairesPensionRetraite,
      time: 5,
      path: "fonctionnaires-pension-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.fonctionnaires,
      date: new Date(2024, 3),
      favoriAccueil: true,
      favoriThematique: true
   },
   {
      id: "salarieCommercantArtisanAgricole",
      type: TypeMedia.ARTICLE,
      image: salarieCommercantArtisanAgricole,
      time: 4,
      path: "salarie-commercant-artisan-agricole",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 3),
   },
   {
      id: "professionLiberalRetraiteCIPAV",
      type: TypeMedia.ARTICLE,
      image: professionLiberalRetraiteCIPAV,
      time: 5,
      path: "profession-liberal-retraite-CIPAV",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 3),
   },
   {
      id: "artisansCommercantsClefs",
      type: TypeMedia.ARTICLE,
      image: artisansCommercantsClefs,
      time: 4,
      path: "artisans-commercants-clefs",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.travailleurIndependant,
      date: new Date(2024, 3),
      favoriAccueil: true,
      favoriThematique: true,
   },
   {
      id: "cipavCalculRetraite",
      type: TypeMedia.ARTICLE,
      image: cipavCalculRetraite,
      time: 6,
      path: "CIPAV-calcul-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 3),
   },
   {
      id: "impositionPensionRetraite",
      type: TypeMedia.ARTICLE,
      image: impositionPensionRetraite,
      time: 4,
      path: "imposition-pension-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 3),
   },
   {
      id: "quiContacterRetraite",
      type: TypeMedia.ARTICLE,
      image: quiContacterRetraite,
      time: 8,
      path: "qui-contacter-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 3),
   },
   {
      id: "enfantRetraiteProfessionLiberale",
      type: TypeMedia.ARTICLE,
      image: enfantRetraiteProfessionLiberale,
      time: 3,
      path: "enfant-retraite-profession-liberale",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.enfants,
      date: new Date(2024, 3),
   },
   {
      id: "microEntrepreneurs",
      type: TypeMedia.ARTICLE,
      image: microEntrepreneurs,
      time: 6,
      path: "micro-entrepreneurs",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.travailleurIndependant,
      date: new Date(2024, 3),
   },
   {
      id: "partirRetraiteTauxPlein",
      type: TypeMedia.ARTICLE,
      image: partirRetraiteTauxPlein,
      time: 5,
      path: "partir-retraite-taux-plein",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 3),
   },
   {
      id: "fonctionnaireRetraite",
      type: TypeMedia.ARTICLE,
      image: fonctionnaireRetraite,
      time: 6,
      path: "fonctionnaire-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.fonctionnaires,
      date: new Date(2024, 3),
   },
   {
      id: "artisansCommercants",
      type: TypeMedia.ARTICLE,
      image: artisansCommercants,
      time: 6,
      path: "artisans-commercants",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.travailleurIndependant,
      date: new Date(2024, 3),
   },
   {
      id: "materniteImpactRetraite",
      type: TypeMedia.ARTICLE,
      image: materniteImpactRetraite,
      time: 4,
      path: "impact-maternite-retraite",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.enfants,
      date: new Date(2024, 3),
   },
   {
      id: "conditionsRetraiteAnticipee",
      type: TypeMedia.ARTICLE,
      image: conditionsRetraiteAnticipee,
      time: 5,
      path: "conditions-retraite-anticipee",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 3),
      favoriAccueil: true
   },
   {
      id: "risDetecterErreurs",
      type: TypeMedia.ARTICLE,
      image: risDetecterErreurs,
      time: 5,
      path: "ris-detecter-erreurs",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.ris,
      date: new Date(2024, 2),
   },
   {
      id: "arretMaladieSalarieImpact",
      type: TypeMedia.ARTICLE,
      image: arretMaladieSalarieImpact,
      time: 4,
      path: "arret-maladie-salarie-impact",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 1),
   },
   {
      id: "comprendreIndemnites",
      type: TypeMedia.ARTICLE,
      image: comprendreIndemnites,
      time: 5,
      path: "comprendre-indemnites",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2024, 1),
   },
   {
      id: "medecinLiberalCalculRetraite",
      type: TypeMedia.ARTICLE,
      image: medecinLiberalCalculRetraite,
      time: 6,
      path: "medecin-liberal-calcul-retraite",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 0),
   },
   {
      id: "agentsNonTitulairesEtat",
      type: TypeMedia.ARTICLE,
      image: agentsNonTitulairesEtat,
      time: 5,
      path: "agents-non-titulaires-etat",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 0),
      favoriThematique: true,
   },
   {
      id: "medecinLiberalRachatTrimestres",
      type: TypeMedia.ARTICLE,
      image: medecinLiberalRachatTrimestres,
      time: 5,
      path: "medecin-liberal-rachat-trimestres",
      theme: Thematique.tnsEtFonctionnaires,
      sousThematique: SousThematique.professionLiberale,
      date: new Date(2024, 0),
      favoriThematique: true
   },
   {
      id: "strategiePreparerRetraite",
      type: TypeMedia.PODCAST,
      image: strategiePreparerRetraite,
      time: 6,
      path: "strategie-preparer-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.conseil,
      date: new Date(2023, 10),
      favoriAccueil: true,
      favoriDashboard: 1,
   },
   {
      id: "retraiteConjointCollaborateur",
      type: TypeMedia.ARTICLE,
      image: retraiteConjointCollaborateur,
      time: 4,
      path: "retraite-conjoint-collaborateur",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 5),
   },
   {
      id: "femmeArtisan",
      type: TypeMedia.ARTICLE,
      image: femmeArtisan,
      time: 8,
      path: "femme-artisan",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 5),
      favoriAccueil: true,
      favoriThematique: true,
   },
   {
      id: "femmeExploitantAgricole",
      type: TypeMedia.ARTICLE,
      image: femmeExploitantAgricole,
      time: 4,
      path: "femme-exploitant-agricole",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 5),
      favoriThematique: true,
   },
   /** Desactivation temporaire
   {
      id: "femmeFoyer",
      image: femmeFoyer,
      time: 4,
      path: "femme-foyer",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.enfants,
      date: new Date(2023, 5),
   },
   {
      id: "trimestreRetraite",
      image: trimestreRetraite,
      time: 7,
      path: "trimestre-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.astuces,
      date: new Date(2023, 4),
   },
   */
   {
      id: "rachatTrimestres",
      type: TypeMedia.ARTICLE,
      image: rachatTrimestres,
      time: 5,
      path: "rachat-trimestres",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.astuces,
      date: new Date(2023, 4),
   },
   {
      id: "zoomRetraiteProgressive",
      type: TypeMedia.ARTICLE,
      image: zoomRetraiteProgressive,
      time: 2,
      path: "zoom-retraite-progressive",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 8),
   },
   {
      id: "estimerBudgetRetraite",
      type: TypeMedia.ARTICLE,
      image: estimerBudgetRetraite,
      time: 2,
      path: "estimer-budget-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2022, 4),
   },
   {
      id: "pensionReversion",
      type: TypeMedia.ARTICLE,
      image: pensionReversion,
      time: 2,
      path: "pension-reversion",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.depart,
      date: new Date(2023, 8),
   },
   {
      id: "optimiserRetraiteObligatoire",
      type: TypeMedia.ARTICLE,
      image: optimiserRetraiteObligatoire,
      time: 1,
      path: "optimiser-retraite-obligatoire",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.astuces,
      date: new Date(2022, 3),
   },
   {
      id: "periodesInvalidite",
      type: TypeMedia.ARTICLE,
      image: periodesInvalidite,
      time: 1,
      path: "periodes-invalidite",
      theme: Thematique.momentsVie,
      date: new Date(2022, 3),
      favoriAccueil: true,
      favoriThematique: true,
      favoriDashboard: 4,
   },
   {
      id: "cumulEmploiRetraite",
      type: TypeMedia.ARTICLE,
      image: cumulEmploiRetraite,
      time: 3,
      path: "cumul-emploi-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 8),
   },
   {
      id: "jargon",
      type: TypeMedia.ARTICLE,
      image: retraiteSansJargon,
      time: 3,
      path: "retraite-sans-jargon",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.comprendre,
      date: new Date(2023, 8),
   },
   {
      id: "impactEnfantRetraite",
      type: TypeMedia.ARTICLE,
      image: impactEnfantRetraite,
      time: 3,
      path: "impact-enfant-retraite",
      theme: Thematique.femmeRetraite,
      sousThematique: SousThematique.enfants,
      date: new Date(2022, 2),
      favoriAccueil: true,
      favoriDashboard: 3,
   },
   {
      id: "comprendreRIS",
      type: TypeMedia.ARTICLE,
      image: comprendreRIS,
      time: 1,
      path: "comprendre-ris",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.ris,
      date: new Date(2022, 2),
      favoriThematique: true,
      favoriDashboard: 2,
   },
   {
      id: "partirRetraite",
      type: TypeMedia.ARTICLE,
      image: partirRetraite,
      time: 10,
      path: "partir-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.age,
      date: new Date(2023, 8),
   },
   {
      id: "impactPensionRetraite",
      type: TypeMedia.ARTICLE,
      image: impactPensionRetraite,
      time: 10,
      path: "impact-pension-retraite",
      theme: Thematique.momentsVie,
      date: new Date(2022, 1),
   },
   {
      id: "travailEtranger",
      type: TypeMedia.ARTICLE,
      image: travailEtranger,
      time: 3,
      path: "travail-etranger",
      theme: Thematique.momentsVie,
      date: new Date(2022, 1),
      favoriAccueil: true,
      favoriThematique: true,
   },
   {
      id: "serviceMilitaire",
      type: TypeMedia.ARTICLE,
      image: serviceMilitaire,
      time: 1,
      path: "service-militaire",
      theme: Thematique.momentsVie,
      date: new Date(2022, 1),
      favoriThematique: true,
   },
   {
      id: "ideesPreparerRetraite",
      type: TypeMedia.ARTICLE,
      image: idee,
      time: 2,
      path: "idees-preparer-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.conseil,
      date: new Date(2022, 0),
      favoriThematique: true,
   },
   {
      id: "anticiperDepartRetraite",
      type: TypeMedia.ARTICLE,
      image: anticiper,
      time: 2,
      path: "anticiper-depart-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.age,
      date: new Date(2022, 0),
      favoriThematique: true,
   },
   {
      id: "preparerDepartRetraite",
      type: TypeMedia.ARTICLE,
      image: preparer,
      time: 3,
      path: "preparer-depart-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.age,
      date: new Date(2022, 0),
   },
   {
      id: "organiserDepartRetraite",
      type: TypeMedia.ARTICLE,
      image: organiser,
      time: 3,
      path: "organiser-depart-retraite",
      theme: Thematique.fonctionnementRetraite,
      sousThematique: SousThematique.age,
      date: new Date(2022, 0),
   },
];
