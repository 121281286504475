import React, {FunctionComponent, useContext} from "react";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {AssetsEditor} from "../components/organisms/Editors/AssetsEditor/AssetsEditor";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SITUATION_NET_WORTH} from "../utils/privateRoutes";
import {EditorContext, EditorContextProvider} from "../components/providers/EditorProvider";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {SGContent} from "sg-layout";
import {SGButtonGroup} from "sg-button";

const AssetsPage: FunctionComponent = () => {
   const AssetsPageButton = () => {
      const {isEditing} = useContext(EditorContext);

      return (
         <SGButtonGroup>
            <SGButtonIntl type="primary" size="sm" disabled={isEditing} cypressName="assets-go-next"
                          onClick={() => redirectToRelativePath(SITUATION_NET_WORTH)} intlId="common.next"/>
         </SGButtonGroup>
      );
   };

   return (
      <SGContent span={8}>
         <SGTitleIntl intlId="profile.assets.title" cypressName="assets-page-title"/>
         <EditorContextProvider>
            <AssetsEditor displayAssetShares cypressName="profile-assets"/>
            <AssetsPageButton/>
         </EditorContextProvider>
      </SGContent>
   );
};

export {AssetsPage};
