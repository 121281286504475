import {createSlice} from '@reduxjs/toolkit';
import {RoutingState} from "./types";
import {EDIT_INCOMES} from "../../website/utils/privateRoutes";
import {INFORMATION_FOR_RETIRED} from "../../website/utils/publicRoutes";

const initialState: RoutingState = {
   shouldRedirect: false,
   redirectUrl: ""
};

export const routingSlice = createSlice({
   name: "recommendations",
   initialState,
   reducers: {
      triggerIncomesRedirect: () => ({
         shouldRedirect: true,
         redirectUrl: EDIT_INCOMES
      }),
      triggerInformationForRetiredRedirect: () => ({
         shouldRedirect: true,
         redirectUrl: INFORMATION_FOR_RETIRED
      }),
      resetRedirectStore: () => initialState
   }
});

export const {triggerIncomesRedirect, triggerInformationForRetiredRedirect, resetRedirectStore} = routingSlice.actions;

export default routingSlice.reducer;
