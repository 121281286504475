import React, {FunctionComponent, useEffect} from "react";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {State} from "../../../../store/store";
import {ProjectState, sumIncomeLines} from "../../../../store/projects/types";
import {IncomeType,} from "../../../../store/incomes/types";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {roundToTen} from "../../../utils/formatting/numberFormatter";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {SGSpace} from "sg-space";
import {useIncomes} from "../../hooks/useIncomes";

const ProjectKORetirementSummary: FunctionComponent = () => {
   const intl = useIntl();

   const methods = useForm({criteriaMode: "all", mode: "onChange",});

   const incomes = useIncomes();
   const hasFetchedIncomes = useSelector<State, boolean>((state) => state.incomes.hasFetched);
   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   useEffect(() => {
      if (projectState.hasFetchedMyRetirementSimulation) {
         methods.setValue("my-incomes", sumIncomeLines(projectState.retirementProject.myRetirement.retirementIncomes));
      }
      if (projectState.hasFetchedPartnerRetirementSimulation) {
         methods.setValue("partner-incomes", sumIncomeLines(projectState.retirementProject.partnerRetirement.retirementIncomes));
      }

      if (projectState.retirementProject.expenses !== undefined) {
         methods.setValue("common-expenses", projectState.retirementProject.expenses.common);
         methods.setValue("other-expenses", projectState.retirementProject.expenses.other);
         methods.setValue("total-expenses", projectState.retirementProject.expenses.total);
      }
   }, [projectState]);

   const getOtherIncomes = (): number => {
      if (incomes) {
         return incomes
            .filter((i) => i.type !== IncomeType.RETIREMENT_INCOME && i.type !== IncomeType.WORK_INCOME)
            .map((i) => i.monthlyAmount)
            .reduce((a, b) => a + b, 0);
      }

      return 0;
   };

   const getTotalIncomes = (): number => {
      let totalIncomes = 0;
      if (projectState.retirementProject.myRetirement) {
         totalIncomes += sumIncomeLines(
            projectState.retirementProject.myRetirement.retirementIncomes
         );
      }
      if (projectState.retirementProject.partnerRetirement) {
         totalIncomes += sumIncomeLines(
            projectState.retirementProject.partnerRetirement.retirementIncomes
         );
      }

      return totalIncomes + getOtherIncomes();
   };

   return (
      <SpinnerWrapper displayComponent={hasFetchedIncomes} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace direction="vertical" textalign="left">
            <SGTitleIntl intlId="project.configuration.retirement.summary.title" cypressName="project-ko-retirement-situation-title"/>
            <FormProvider {...methods}>
               <SGGridRow align="top">
                  <SGGridCol xs={12} xl={6}>
                     <SGTextIntl intlId="project.configuration.retirement.incomes" strong/>
                     <FormInputs colLength={10}>
                        {projectState.retirementProject.myRetirement && (
                           <NumericInput name="my-incomes" disabled cypressName="my-retirement-income"
                                         suffix="common.euro.per.month" placeholder=""
                                         label="project.configuration.retirement.incomes.departure"
                                         labelTransformations={{
                                            age: String(new Date(projectState.retirementProject.myRetirement.memberRetirementDate).getFullYear() - new Date(projectState.retirementProject.myRetirement.birthdate).getFullYear()),
                                            name: intl.formatMessage({id: "family.status.ME"}),
                                         }}
                                         defaultValue={sumIncomeLines(projectState.retirementProject.myRetirement.retirementIncomes)}/>
                        )}
                        {projectState.retirementProject.partnerRetirement && (
                           <NumericInput name="partner-incomes" disabled cypressName="partner-retirement-income"
                                         suffix="common.euro.per.month" placeholder=""
                                         label="project.configuration.retirement.incomes.departure"
                                         labelTransformations={{
                                            age: String(new Date(projectState.retirementProject.partnerRetirement.memberRetirementDate).getFullYear() - new Date(projectState.retirementProject.partnerRetirement.birthdate).getFullYear()),
                                            name: intl.formatMessage({id: "family.status.PARTNER"}),
                                         }}
                                         defaultValue={sumIncomeLines(projectState.retirementProject.partnerRetirement.retirementIncomes)}/>
                        )}
                        <NumericInput name="other-incomes" disabled defaultValue={getOtherIncomes()} cypressName="other-income" placeholder=""
                                      suffix="common.euro.per.month" label="project.configuration.retirement.other.incomes"/>
                        <NumericInput name="total-incomes" disabled defaultValue={getTotalIncomes()} cypressName="total-income" placeholder=""
                                      suffix="common.euro.per.month" label="project.configuration.retirement.incomes.total"/>

                     </FormInputs>
                  </SGGridCol>
                  <SGGridCol xs={12} xl={6}>
                     <SGTextIntl intlId="project.configuration.retirement.expenses" strong/>
                     <FormInputs colLength={10}>
                        <NumericInput name="common-expenses" disabled defaultValue={projectState.retirementProject.expenses.common} placeholder=""
                                      suffix="common.euro.per.month" cypressName="common-expenses" label="project.configuration.retirement.common.expenses"/>
                        <NumericInput name="other-expenses" disabled defaultValue={roundToTen(projectState.retirementProject.expenses.other)} placeholder=""
                                      suffix="common.euro.per.month" cypressName="other-expenses" label="project.configuration.retirement.other.expenses"/>
                        <NumericInput name="total-expenses" disabled defaultValue={roundToTen(projectState.retirementProject.expenses.total)} placeholder=""
                                      suffix="common.euro.per.month" cypressName="total-expenses" label="project.configuration.retirement.expenses.total"/>
                     </FormInputs>
                  </SGGridCol>
               </SGGridRow>
            </FormProvider>
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {ProjectKORetirementSummary};
