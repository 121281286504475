import {call, put, takeLeading} from "redux-saga/effects";
import {
   AssetType,
   AssetWithValuation,
   CREATE_ASSET,
   CREATE_REAL_ESTATE_ASSET,
   CREATE_REAL_ESTATE_ASSET_AND_LOAN,
   CREATE_SCI_ASSET,
   DELETE_ASSET,
   FETCH_ASSETS_WITHOUT_LOANS,
   RealEstateAsset,
   SCIAsset,
   UPDATE_ASSET,
   UPDATE_REAL_ESTATE_ASSET,
   UPDATE_SCI_ASSET,
   UPDATE_ASSETS_FOR_RETIREMENT,
   UPDATE_REAL_ESTATE_ASSET_AND_LOAN
} from "./types";
import {onDefaultError, safe} from "../error/utils";
import {apiDelete, apiGet, apiPost, apiPut} from "../apiCaller";
import {getIncomesAction} from "../incomes/actions";
import {getExpensesAction} from "../expenses/actions";
import {getAssetsWithoutLoans} from "./actions";
import {getLoansAction} from "../loans/actions";
import {Loan, LoanData} from "../loans/types";
import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {assetCreated, assetDeleted, assetsFetched, assetForRetirementUpdated,assetUpdated} from "./assetsSlice";

export function* watcherSaga() {
   yield takeLeading(FETCH_ASSETS_WITHOUT_LOANS, safe(onDefaultError, handleGetAssetsNoLoans));
   yield takeLeading(CREATE_ASSET, safe(onDefaultError, handleCreateAsset));
   yield takeLeading(CREATE_REAL_ESTATE_ASSET, safe(onDefaultError, handleCreateRealEstateAsset));
   yield takeLeading(CREATE_REAL_ESTATE_ASSET_AND_LOAN, safe(onDefaultError, handleCreateRealEstateAssetAndLoan));
   yield takeLeading(CREATE_SCI_ASSET, safe(onDefaultError, handleCreateSCIAsset));
   yield takeLeading(UPDATE_ASSET, safe(onDefaultError, handleUpdateAsset));
   yield takeLeading(UPDATE_REAL_ESTATE_ASSET, safe(onDefaultError, handleUpdateRealEstateAsset));
   yield takeLeading(UPDATE_REAL_ESTATE_ASSET_AND_LOAN, safe(onDefaultError, handleUpdateRealEstateAssetAndLoan));
   yield takeLeading(UPDATE_SCI_ASSET, safe(onDefaultError, handleUpdateSCIAsset));
   yield takeLeading(DELETE_ASSET, safe(onDefaultError, handleDeleteAsset));
   yield takeLeading(UPDATE_ASSETS_FOR_RETIREMENT, safe(onDefaultError, handleUpdateAssetsForRetirement));
}

function* handleGetAssetsNoLoans() {
   const payload: AxiosResponse<AssetWithValuation[]> = yield call(apiGet, `wealth/api/assets?noLoans=true`);
   yield put(assetsFetched(payload.data));
}

function* handleCreateAsset(action: PayloadAction<Partial<AssetWithValuation>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/`, action.payload);
   yield put(assetCreated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
}

function* handleUpdateAsset(action: PayloadAction<AssetWithValuation>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/`, action.payload);
   yield put(assetUpdated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
}

function* handleUpdateAssetsForRetirement(action: PayloadAction<AssetWithValuation[]>) {
   const currentAssets = action.payload;
   const unused = currentAssets.filter((asset) => asset.percentageUsedForRetirement === 0)
      .map((a) => a.id?.toString().concat("=").concat(a.percentageUsedForRetirement.toString()));
   const used = currentAssets.filter((asset) => asset.percentageUsedForRetirement > 0)
      .map((a) => a.id?.toString().concat("=").concat(a.percentageUsedForRetirement.toString()));
   let params = "";
   if (unused.length > 0) {
      params = params.concat(unused.join("&"));
      if (used.length > 0) {
         params = params.concat("&");
      }
   }
   if (used.length > 0) {
      params = params.concat(used.join("&"));
   }
   if (params.length > 0) {
      yield call(apiPut, `/wealth/api/assets/percentageUsedForRetirement?${params}`, null);
   }
   // PFR: Ajout des assets sans mise è jour
   yield put(assetForRetirementUpdated(currentAssets));
}

function* handleDeleteAsset(action: PayloadAction<AssetWithValuation>) {
   yield call(apiDelete, `wealth/api/assets/${action.payload.id}`);
   yield put(assetDeleted(action.payload));
   yield put(getIncomesAction());
   yield put(getExpensesAction());
   yield put(getAssetsWithoutLoans());
   yield put(getLoansAction());
}

function* handleCreateRealEstateAssetAndLoan(action: PayloadAction<{ asset: Partial<RealEstateAsset>, loan: Partial<Loan> }>) {
   const loanData: Partial<LoanData> = {
      displayedAmount: action.payload.loan.displayedAmount,
      durationInMonth: action.payload.loan.durationInMonth,
      monthlyPayments: action.payload.loan.monthlyAmount,
      loanAmount: action.payload.loan.displayedAmount,
      loanType: action.payload.loan.loanType,
      amortizationType: action.payload.loan.amortizationType
   };
   const resultLoan: AxiosResponse<LoanData> = yield call(apiPost, `wealth/api/compute-loan-parameters`, loanData);
   if (resultLoan.status === 200) {
      const loan: Partial<Loan> = {
         ...action.payload.loan,
         displayedAmount: resultLoan.data.displayedAmount,
         totalAmount: resultLoan.data.loanAmount,
         monthlyAmount: resultLoan.data.monthlyPayments,
         interestRate: resultLoan.data.annualRate,
         durationInMonth: resultLoan.data.durationInMonth
      };
      const loanPayload: AxiosResponse<Loan> = yield call(loan.id ? apiPut : apiPost, `wealth/api/loans?createIfNotExist=true`, loan);
      if (loanPayload.status === 200 || loanPayload.status === 201) {
         const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/real-estates/`, {
            ...action.payload.asset,
            loans: [loanPayload.data.id]
         });
         yield put(assetCreated(payload.data));
         if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
            yield put(getIncomesAction());
            yield put(getExpensesAction());
         }
         yield put(getLoansAction());
      }
   }
}

function* handleCreateRealEstateAsset(action: PayloadAction<Partial<RealEstateAsset>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/real-estates/`, action.payload);
   yield put(assetCreated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
   yield put(getLoansAction());
}

function* handleUpdateRealEstateAssetAndLoan(action: PayloadAction<{ asset: Partial<RealEstateAsset>, loan: Partial<Loan> }>) {
   const loanData: Partial<LoanData> = {
      displayedAmount: action.payload.loan.displayedAmount,
      durationInMonth: action.payload.loan.durationInMonth,
      monthlyPayments: action.payload.loan.monthlyAmount,
      loanAmount: action.payload.loan.displayedAmount,
      loanType: action.payload.loan.loanType,
      amortizationType: action.payload.loan.amortizationType
   };
   const resultLoan: AxiosResponse<LoanData> = yield call(apiPost, `wealth/api/compute-loan-parameters`, loanData);
   if (resultLoan.status === 200) {
      const loan: Partial<Loan> = {
         ...action.payload.loan,
         displayedAmount: resultLoan.data.displayedAmount,
         totalAmount: resultLoan.data.loanAmount,
         monthlyAmount: resultLoan.data.monthlyPayments,
         interestRate: resultLoan.data.annualRate,
         durationInMonth: resultLoan.data.durationInMonth
      };
      const loanPayload: AxiosResponse<Loan> = yield call(loan.id ? apiPut : apiPost, `wealth/api/loans?createIfNotExist=true`, loan);
      if (loanPayload.status === 200 || loanPayload.status === 201) {
         const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/real-estates/`, {
            ...action.payload.asset,
            loans: [loanPayload.data.id]
         });
         yield put(assetUpdated(payload.data));
         if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
            yield put(getIncomesAction());
            yield put(getExpensesAction());
         }
         yield put(getLoansAction());
      }
   }
}

function* handleUpdateRealEstateAsset(action: PayloadAction<Partial<RealEstateAsset>>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/real-estates/`, action.payload);
   yield put(assetUpdated(payload.data));
   if (payload.data.assetType === AssetType.REAL_ESTATE_RENT) {
      yield put(getIncomesAction());
      yield put(getExpensesAction());
   }
   yield put(getLoansAction());
}

function* handleCreateSCIAsset(action: PayloadAction<SCIAsset>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPost, `wealth/api/assets/sci/`, action.payload);
   yield put(getAssetsWithoutLoans());
   yield put(getIncomesAction());
   yield put(getLoansAction());
   yield put(assetCreated(payload.data));
}

function* handleUpdateSCIAsset(action: PayloadAction<SCIAsset>) {
   const payload: AxiosResponse<AssetWithValuation> = yield call(apiPut, `wealth/api/assets/sci/`, action.payload);
   yield put(getAssetsWithoutLoans());
   yield put(getIncomesAction());
   yield put(getLoansAction());
   yield put(assetUpdated(payload.data));
}
