export interface TunnelState {
  currentStep: number;
  isTunnelReforme: boolean;
}

export const RESET_TUNNEL = "RESET_TUNNEL";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const GO_TO_STEP = "GO_TO_STEP";
export const SET_IS_TUNNEL_REFORME = "SET_IS_TUNNEL_REFORME";
