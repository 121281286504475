import React, {FunctionComponent, useContext} from "react";
import {TaxCreditsEditor} from "../components/organisms/Editors/TaxCreditsEditor/TaxCreditsEditor";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SITUATION_TAXATION} from "../utils/privateRoutes";
import {EditorContext, EditorContextProvider} from "../components/providers/EditorProvider";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {SGContent} from "sg-layout";
import {SGAlertIntl} from "../components/molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {SGButtonGroup} from "sg-button";

const TaxCreditsPage: FunctionComponent = () => {
   const goNext = () => {
      redirectToRelativePath(SITUATION_TAXATION);
   };

   const TaxCreditsPageButton = () => {
      const {isEditing} = useContext(EditorContext);

      return (
         <SGButtonGroup>
            <SGButtonIntl type="primary" size="sm" disabled={isEditing} cypressName="tax-credits-go-next" onClick={goNext} intlId="common.next"/>
         </SGButtonGroup>
      );
   };

   return (
      <SGContent span={8}>
         <SGTitleIntl cypressName="tax-credits-page-title" intlId="tax.credit.page.title"/>
         <SGAlertIntl cypressName="notification-frame-tax-credits" type="info" title="common.did.you.know">
            <SGTextIntl intlId="tax.credit.page.info"/>
         </SGAlertIntl>
         <EditorContextProvider>
            <TaxCreditsEditor cypressName="tax-credits"/>
            <TaxCreditsPageButton/>
         </EditorContextProvider>
      </SGContent>
   );
};

export { TaxCreditsPage };
