import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Highcharts, {Options} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Scenario,} from "../../../../store/simulation/types";
import {computeIncomeExpenseChartOptions} from "../../../utils/graphs/graph-builders";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {getSimulationRange} from "../../../../store/simulation/utils";
import {useSimulation} from "../../hooks/useSimulation";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";
import {useProjects} from "../../hooks/useProjects";

const GraphIncomesExpenses: FunctionComponent = () => {
   const intl = useIntl();
   const birthDay = useRef<number>(18);
   const [chartOptionsIncomesExpenses, setChartOptionsIncomesExpenses] = useState<Highcharts.Options>();

   const simulation = useSimulation(false);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);
   const projects = useProjects(false, false);

   useEffect(() => {
      if (hasFetchedSimulation) {
         birthDay.current = simulation.birthday;
         if (simulation.incomeExpenses) {
            const options: Options = computeIncomeExpenseChartOptions(
               projects,
               birthDay.current,
               simulation.incomeExpenses,
               Scenario.CONSERVATIVE,
               getSimulationRange(simulation),
               intl.formatMessage,
            );
            setChartOptionsIncomesExpenses(options);
         }
      }
   }, [simulation, hasFetchedSimulation, projects]);

   return (
      <SpinnerWrapper displayComponent={chartOptionsIncomesExpenses !== undefined} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
         {chartOptionsIncomesExpenses !== undefined &&
            <HighchartsReact highcharts={Highcharts} options={chartOptionsIncomesExpenses}/>
         }
      </SpinnerWrapper>
   );
};

export {GraphIncomesExpenses};
