import {
   CREATE_TAX_CREDIT,
   DELETE_TAX_CREDIT,
   FETCH_MY_TAX_SYSTEM,
   FETCH_PARTNER_TAX_SYSTEM,
   FETCH_TAX_CREDITS,
   FETCH_TAX_SITUATION,
   TaxCredit,
   TaxSystem,
   UPDATE_MY_TAX_SYSTEM,
   UPDATE_PARTNER_TAX_SYSTEM,
   UPDATE_TAX_CREDIT
} from "./types";

export const getTaxSituationAction = () => ({
   type: FETCH_TAX_SITUATION
});

export const getMeTaxSystemAction = () => ({
   type: FETCH_MY_TAX_SYSTEM
});

export const getPartnerTaxSystemAction = () => ({
   type: FETCH_PARTNER_TAX_SYSTEM
});

export const updateMyTaxSystemAction = (taxSystem: TaxSystem) => ({
   type: UPDATE_MY_TAX_SYSTEM,
   payload: taxSystem
});

export const updatePartnerTaxSystemAction = (taxSystem: TaxSystem) => ({
   type: UPDATE_PARTNER_TAX_SYSTEM,
   payload: taxSystem
});

export const getTaxCreditsAction = () => ({
   type: FETCH_TAX_CREDITS
});

export const createTaxCreditAction = (taxCredit: Omit<TaxCredit, "id">) => ({
   type: CREATE_TAX_CREDIT,
   payload: taxCredit
});

export const updateTaxCreditAction = (taxCredit: TaxCredit) => ({
   type: UPDATE_TAX_CREDIT,
   payload: taxCredit
});

export const deleteTaxCreditAction = (taxCredit: TaxCredit) => ({
   type: DELETE_TAX_CREDIT,
   payload: taxCredit
});
