import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { services } from "website/components/hooks/servicesList";
import { ServiceCard } from "website/components/molecules/ServiceCard/ServiceCard";
import { ServiceContenu } from "website/components/molecules/ServiceContenu/ServiceContenu";
import { ServiceContenuModal } from "website/components/molecules/ServiceContenuModal/ServiceContenuModal";
import { ServiceCardModel, ServiceModel } from "../ServiceModel";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const MesServicesPublics: FunctionComponent = () => {
    const intl = useIntl();
    const { trackClick } = useTracking();

    const [serviceId, setServiceId] = useState<string>(services[0].id);
    const [selectedService, setSelectedService] = useState<ServiceModel>();
    const [showModalService, setShowModalService] = useState<boolean>(false);

    const handleOnClickService = (service: ServiceCardModel) => {
        trackClick(`les-principaux-services-publics::clic-sur-voir-plus-${service.trackKey}`);
        setServiceId(service.id);
        setShowModalService(true);
    };

    useEffect(() => {
        fetch(`services/${serviceId}.json`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((myJson: ServiceModel) => {
                setSelectedService({ ...myJson, id: serviceId });
            });
    }, [serviceId]);

    return (
        <>
            <SGBox margin={{ top: "sm", bottom: "md" }}>
                <SGSpace direction="vertical">
                    <SGTitle key="title" level={2} visuallevel={3}>
                        {intl.formatMessage({ id: "mesServices.services.header.subtitle" })}
                    </SGTitle>
                    <SGText key="text-1">{intl.formatMessage({ id: "mesServices.services.header.text" })}</SGText>
                    <SGText key="text-2">{intl.formatMessage({ id: "mesServices.services.header.intro" })}</SGText>
                </SGSpace>
            </SGBox>
            <SGGridRow >
                <SGGridCol span={12} sm={4} >
                    {services.map((service) =>
                        <ServiceCard
                            key={service.id}
                            title={intl.formatMessage({ id: `mesServices.services.card.${service.id}.title` })}
                            subtitle={intl.formatMessage({ id: `mesServices.services.card.${service.id}.subtitle` })}
                            onClickShowMore={() => handleOnClickService(service)}
                            logo={service.logo}
                            selected={serviceId === service.id}
                        />
                    )}
                </SGGridCol>
                <SGMediaQuery minwidth="sm">
                    <SGGridCol style={{"background-color": "#ffffff", "box-shadow": "0px 2px 4px #bababa"}} span={8}>
                            {selectedService && (
                                <ServiceContenu key={serviceId} service={selectedService} logo={services.find((svc) => svc.id === serviceId)?.logo ?? "undefined"} />
                            )}
                    </SGGridCol>
                </SGMediaQuery>
                <SGMediaQuery minwidth={0} maxwidth="xs">
                    {showModalService && selectedService && (
                        <ServiceContenuModal
                            service={selectedService}
                            logo={services.find((svc) => svc.id === serviceId)?.logo ?? "undefined"}
                            showModalService={showModalService}
                            setShowModalService={setShowModalService}
                        />
                    )}
                </SGMediaQuery>
            </SGGridRow>
        </>
    );
};
export { MesServicesPublics };
