import React from "react";
import {useIntl} from "react-intl";
import {RealEstateAsset, SCIAsset,} from "../../../../../store/assets/types";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {AssetAssociationForm} from "../AssetAssociationForm/AssetAssociationForm";
import {Loan} from "../../../../../store/loans/types";
import {SGSelectOption} from "sg-select";

interface SCIEditFormProps {
   currentAsset: SCIAsset;
   cypressName: string;
   associatedAssets: RealEstateAsset[];
   nonAssociatedAssets: RealEstateAsset[];
   associatedLoans: Loan[];
   nonAssociatedLoans: Loan[];
   onRemoveAssociatedAsset: (assetIndex: number) => void;
   onRemoveAssociatedLoan: (assetIndex: number) => void;
   onAssetSelectionChange: (newAssetId: string) => void;
   onLoanSelectionChange: (newLoanId: string) => void;
}

const SCIEditForm = ({
                        currentAsset, cypressName, associatedAssets, nonAssociatedAssets,
                        associatedLoans, nonAssociatedLoans, onRemoveAssociatedAsset, onRemoveAssociatedLoan,
                        onAssetSelectionChange, onLoanSelectionChange
                     }: SCIEditFormProps) => {
   const intl = useIntl();

   return (
      <>
         <SelectInput defaultValue={currentAsset.isTaxTransparent !== undefined ? String(currentAsset.isTaxTransparent) : "true"} name="isTaxTransparent"
                      required
                      cypressName={`${cypressName}-select-sci-tax-type`} label="form.asset.sci.tax-transparency.label">
            <SGSelectOption key={0} value="true">{intl.formatMessage({id: `form.asset.sci.tax-transparency.true`})}</SGSelectOption>
            <SGSelectOption key={1} value="false">{intl.formatMessage({id: `form.asset.sci.tax-transparency.false`})}</SGSelectOption>
         </SelectInput>
         <AssetAssociationForm assetType="REAL-ESTATE" associatedAssets={associatedAssets} cypressName={cypressName} nonAssociatedAssets={nonAssociatedAssets}
                               onAssetSelectionChange={onAssetSelectionChange}
                               onRemoveAssociatedAsset={onRemoveAssociatedAsset}/>
         <AssetAssociationForm assetType="LOAN" associatedAssets={associatedLoans} cypressName={cypressName}
                               nonAssociatedAssets={nonAssociatedLoans} onAssetSelectionChange={onLoanSelectionChange}
                               onRemoveAssociatedAsset={onRemoveAssociatedLoan}/>
      </>
   );
};

export {SCIEditForm};
