/* eslint-disable camelcase */
import { ReactComponent as LinkImage } from "assets/images/link-image.svg";
import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLink } from "sg-link";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { PAGE_CATEGORY_3_RIS } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { BlueCard } from "website/components/molecules/BlueCard/BlueCard";
import { externalURL } from "website/utils/externalUrl";
import "./ParcoursRISPreparationModal.scss";

interface ParcoursRISPreparationModalProps {
  setVisible: (visible: boolean) => void;
  visible: boolean;
}

const ParcoursRISPreparationModal: FunctionComponent<
  ParcoursRISPreparationModalProps
> = (props: ParcoursRISPreparationModalProps) => {
  const { setVisible, visible } = props;
  const intl = useIntl();
  const openLink = useOpenLink();
  const { trackPopin, resetTracking } = useTracking();

  const [showCard, setShowCard] = useState<boolean>(true);

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  useEffect(() => {
    if (visible) {
      trackPopin(
        false,
        PAGE_CATEGORY_3_RIS,
        "aide-ris",
        "simulation",
        "1",
        undefined
      );
    }
  }, [visible]);

  const handleOnClose = () => {
    trackPopin(
      true,
      PAGE_CATEGORY_3_RIS,
      "aide-ris::clic-sur-fermer",
      "simulation",
      "1",
      undefined
    );
    setVisible(false);
    resetTracking();
  };

  const handleOnClickConfirm = () => {
    trackPopin(
      true,
      PAGE_CATEGORY_3_RIS,
      "aide-ris::clic-sur-merci-j-ai-compris",
      "simulation",
      "1",
      undefined
    );
    setVisible(false);
    resetTracking();
  };

  const transformations = {
    ul: (word: string) => <SGBlock component="ul">{word}</SGBlock>,
    li: (word: string) => <li>{word}</li>,
    b: (word: string) => (
      <SGText strong weight="bold">
        {word}
      </SGText>
    ),
  };

  return (
    <SGModal
      className="parcours-ris-preparation-modal"
      visible={visible}
      onCancel={handleOnClose}
      centered
      closable={!isPhone}
    >
      <SGScroll>
        <SGSpace direction="vertical" size="xl" textalign="left">
          <SGTitle key="title" level={2} visuallevel={3}>
            {intl.formatMessage(
              { id: "parcoursRIS.preparation.modal.title" },
              { linebreak: <br /> }
            )}
          </SGTitle>
          <SGBox
            key="box1"
            alignchildren={["left", "top"]}
            margin={{ left: "sm" }}
            forcemargin
          >
            <BlueCard
              text="parcoursRIS.preparation.modal"
              title="blue.card.parcoursRIS.preparation.modal.commencer"
              showCard={showCard}
              setShowCard={setShowCard}
            />
          </SGBox>
          <SGSpace key="space" direction="vertical">
            <SGTitle key="title1" level={3} visuallevel={5}>
              {intl.formatMessage({
                id: "parcoursRIS.preparation.modal.etape1.title",
              })}
            </SGTitle>
            <SGBox key="box1" margin={{ left: "sm" }} forcemargin>
              <SGTextIntl
                intlId="parcoursRIS.preparation.modal.etape1.message"
                transformations={{
                  ...transformations,
                  link: (word: string) => {
                    const link = externalURL.EXTERNAL_URL_INFO_RETRAITE;

                    return (
                      <SGLink
                        className="parcours-ris-preparation-modal__link"
                        key="lien"
                        href={link}
                        onClick={(event: SyntheticEvent) =>
                          openLink(link, event)
                        }
                      >
                        {word}

                        <LinkImage className="parcours-ris-preparation-modal__link-image" />
                      </SGLink>
                    );
                  },
                }}
              />
            </SGBox>
            <SGTitle key="title2" level={3} visuallevel={5}>
              {intl.formatMessage({
                id: "parcoursRIS.preparation.modal.etape2.title",
              })}
            </SGTitle>
            <SGBox key="box2" margin={{ left: "sm" }} forcemargin>
              <SGTextIntl
                intlId="parcoursRIS.preparation.modal.etape2.message"
                transformations={{
                  ...transformations,
                  ul: (word: string) => (
                    <SGBlock component="ul" disableautomargin>
                      {word}
                    </SGBlock>
                  ),
                  subtitle: (word: string) => (
                    <SGBox margin={{ left: "md" }} textalign="left">
                      <>{word}</>
                    </SGBox>
                  ),
                }}
              />
            </SGBox>
            <SGTitle key="title3" level={3} visuallevel={5}>
              {intl.formatMessage({
                id: "parcoursRIS.preparation.modal.etape3.title",
              })}
            </SGTitle>
            <SGBox key="box3" margin={{ left: "sm" }} forcemargin>
              <SGTextIntl
                intlId="parcoursRIS.preparation.modal.etape3.message"
                transformations={transformations}
              />
            </SGBox>
          </SGSpace>
        </SGSpace>
      </SGScroll>

      <SGGridRow>
        <SGGridCol span={12} textalign={isPhone ? "center" : "right"}>
          <SGButton
            onClick={handleOnClickConfirm}
            ariaLabel={intl.formatMessage({
              id: "parcoursRIS.preparation.compris.ariaLabel",
            })}
          >
            <SGTextIntl intlId="parcoursRIS.preparation.compris" />
          </SGButton>
        </SGGridCol>
      </SGGridRow>
    </SGModal>
  );
};

export { ParcoursRISPreparationModal };
