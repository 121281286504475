import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SurvivingPartnerSimulatorResult, SurvivingPartnerSimulatorState} from "./types";

const initialState: SurvivingPartnerSimulatorState = {
   result: [],
   hasFetched: false
};

export const survivngPartnerSimulatorSlice = createSlice({
   name: "survivngPartnerSimulator",
   initialState,
   reducers: {
      survivngPartnerSimulatorResultFetched: (currentState, action: PayloadAction<SurvivingPartnerSimulatorResult[]>) => ({
         result: action.payload,
         hasFetched: true
      })
   }
});

export const {survivngPartnerSimulatorResultFetched} = survivngPartnerSimulatorSlice.actions;

export default survivngPartnerSimulatorSlice.reducer;
