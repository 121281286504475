import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CoachState} from "./types";
import {computeIsNewCoachAdvices} from "./utils";

const initialState: CoachState = {advices: []};

export const coachSlice = createSlice({
   name: "coach",
   initialState,
   reducers: {
      coachAdvicesComputed: (currentState, action: PayloadAction<CoachState>) => computeIsNewCoachAdvices(currentState, action.payload)
   }
});

export const {coachAdvicesComputed} = coachSlice.actions;

export default coachSlice.reducer;
