import React, { FunctionComponent, SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLink } from "sg-link";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { ReactComponent as Star } from "../../../../assets/images/star.svg";
import "./HowToSaveCard.scss";

export type Card = {
    type: string;
    image: unknown;
    name: string;
    link?: string
};

interface HowToSaveProps {
    card: Card;
}

// Composant card du parcours epargner
export const HowToSaveCard: FunctionComponent<HowToSaveProps> = (props: React.PropsWithChildren<HowToSaveProps>) => {
    const { card } = props;
    const intl = useIntl();
    const { trackingPageName, trackClick } = useTracking();
    const openLink = useOpenLink();

    const link = window.env.REACT_APP_BDDF_URL + intl.formatMessage({ id: `link.${card.type}` });

    const handleOnClickCardLink = (event: SyntheticEvent) => {
        trackClick(`${trackingPageName}::clic-sur-${card.name}`);
        openLink(link, event);
    };

    return (
        <SGCard
            className="how-to-save-card"
            hoverable
            shadow
            actions={[
                <>
                    {(card.link) && (
                        <SGGridRow className="how-to-save-card__title-container">
                            <SGGridCol span={12} textalign="center">
                                <SGLink href={card.link}>
                                    {intl.formatMessage({
                                        id: `parcoursEpargner.cards.${card.type}.link`,
                                    })}
                                </SGLink>
                            </SGGridCol>
                        </SGGridRow>
                    )}
                    <SGGridRow className="how-to-save-card__title-container">
                        <SGGridCol span={12} textalign="center">
                            <SGLink href={link} onClick={handleOnClickCardLink}>
                                {intl.formatMessage({
                                    id: "project.action.plan.discover.more",
                                })}
                            </SGLink>
                        </SGGridCol>
                    </SGGridRow>
                </>
            ]}
        >
            <SGGridRow className="how-to-save-card__cover-container">
                <SGGridCol span={12} textalign="center">
                    <Star className="how-to-save-card__cover-icone" />
                    {card.image}
                </SGGridCol>
            </SGGridRow>
            <SGGridRow justify="center">
                <SGGridCol span={12} align="center">
                    <SGGridRow align="middle" className="how-to-save-card__title-container">
                        <SGGridCol span={12}>
                            <SGTitle
                                level={3}
                                style={{
                                    fontFamily: "Source Sans Pro",
                                    lineHeight: "30px",
                                }}
                                visuallevel="3"
                                textalign="center"
                            >
                                {intl.formatMessage({ id: `parcoursEpargner.cards.${card.type}.title` })}
                            </SGTitle>
                        </SGGridCol>
                    </SGGridRow>
                </SGGridCol>
                <SGGridCol span={12} textalign="center">
                    <SGText
                        size="s"
                        style={{
                            lineHeight: "18px",
                        }}
                    >
                        {intl.formatMessage({ id: `parcoursEpargner.cards.${card.type}.text` })}
                    </SGText>
                </SGGridCol>
            </SGGridRow>
        </SGCard>
    );
};
