import React, {FunctionComponent} from "react";
import {RealEstateProject} from "../../components/organisms/Projects/RealEstateProject/RealEstateProject";
import {SGContent} from "sg-layout";

const RealEstateProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <RealEstateProject/>
   </SGContent>
);

export {RealEstateProjectPage};
