import { ReactComponent as Arrow } from "assets/images/arrow-right-solid.svg";
import debounce from "lodash.debounce";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { SGBlock, SGText } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { updateAndReloadPrefs, updatePrefs } from "store/dashboard/actions";
import {
  DashboardAge,
  DashboardAgeType,
  User,
  hasFetchedDashboard,
} from "store/dashboard/types";
import { getMonthlyIncome, getSavingEffort } from "store/savingPath/actions";
import {
  DataComparer,
  MonthlyIncomeRequest,
  SavingEffortRequest,
  SavingPathState,
  convertAgeTypeToWealthProfile,
  hasFetchedSavingPathMonthlyEffort,
  hasFetchedSavingPathObjective,
} from "store/savingPath/types";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import {
  PAGE_TYPE_GESTION,
  TRACKING_CLICK_HYPOTHESES,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  HypothesisAnchor,
  HypothesisModal,
} from "website/components/molecules/HypothesisModal/HypothesisModal";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { DASHBOARD } from "website/utils/privateRoutes";
import { ComparerTab } from "../ComparerTab/ComparerTab";
import { TabEpargner } from "../TabEpargner/TabEpargner";
import "./ModuleEpargner.scss";

export interface TabModel {
  tabName: ReactNode;
  key: string;
  ageRetraite?: DashboardAge;
}

export const COMPARE_TAB = "Comparer";

interface ModuleEpargnerProps {
  montantEpargne: number; // Initialisé à 0 pour SGAgenda
  setMontantEpargne: (montantEpargne: number) => void;
  showLoader: boolean;
  setShowLoader: (showLoader: boolean) => void;
  currentTab: DashboardAgeType | typeof COMPARE_TAB | undefined;
  setCurrentTab: (currentTab: DashboardAgeType | typeof COMPARE_TAB) => void;
}

const ModuleEpargner: FunctionComponent<ModuleEpargnerProps> = (
  props: ModuleEpargnerProps
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    montantEpargne,
    setMontantEpargne,
    showLoader,
    setShowLoader,
    currentTab,
    setCurrentTab,
  } = props;

  const dashboardState = useDashboardState();
  const savingPathState = useSelector<State, SavingPathState>(
    (state) => state.savingPath
  );
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);
  const { agesTries, selectedRetirementAge } = useSelectedRetirementAge();
  const { trackClick, trackPage } = useTracking();

  const [dataComparer, setDataComparer] = useState<DataComparer[]>([]);
  const [wealthProfiles, setWealthProfiles] = useState<string[]>([]);
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);
  const [objectif, setObjectif] = useState(0);
  const [saveMotorResult, setSaveMotorResult] = useState(true);
  const compareTab: TabModel = {
    tabName: intl.formatMessage({
      id: "parcoursEpargner.moduleEpargner.tabtitle.compare",
    }),
    key: intl.formatMessage({
      id: "parcoursEpargner.moduleEpargner.tabtitle.compare",
    }),
  };
  const [tabs, setTabs] = useState<TabModel[]>([compareTab]);
  const [lastProfile, setLastProfile] = useState<string>();
  const [lastSavingAmount, setLastSavingAmount] = useState(0);
  const [agesDepartDashboard, setAgesDepartDashboard] = useState<
    DashboardAge[]
  >([]);
  const [lastSavedIncomeGoal, setLastSavedIncomeGoal] = useState(0);

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const saveChoosenAgeToDb = (val: DashboardAgeType | typeof COMPARE_TAB) => {
    setCurrentTab(val);
    if (COMPARE_TAB !== val) {
      trackClick(`age-cle::clic-sur-selection-ages-${val.replace(/_/g, "-")}`);
      const req: User = {
        choosenAge: val,
      };
      dispatch(updatePrefs(req));
    } else {
      trackClick("age-cle::clic-sur-comparer");
    }
  };

  const saveMonthlyIncomeGoalToDb = (val: number) => {
    const roundedVal = roundDown10(val);
    setObjectif(roundedVal);

    if (lastSavedIncomeGoal !== roundedVal) {
      const req: User = {
        monthlyIncomeGoal: roundedVal,
      };
      dispatch(updateAndReloadPrefs(req));
      setLastSavedIncomeGoal(roundedVal);
    }
  };

  function getTabName(ageDepart: DashboardAge) {
    return intl.formatMessage(
      { id: "parcoursEpargner.moduleEpargner.tabtitle" },
      {
        ageYear: ageDepart.retirementAgeYear,
        label: (content: string) => (
          <label className="ModuleEpargner__tabs--label">{content}</label>
        ),
        ageMonth:
          ageDepart.retirementAgeMonth !== 0
            ? ` et ${ageDepart.retirementAgeMonth} mois`
            : "",
        linebreak: <br />,
        retirementMonth: intl
          .formatMessage({
            id: `parcoursEpargner.moduleEpargner.retirementMonth.${ageDepart.retirementMonth.toString()}`,
          })
          .toString(),
        retirementYear: ageDepart.retirementYear,
      }
    );
  }

  const debouncedCall = useCallback(
    debounce(
      (val: number, rev: boolean, wp: string) =>
        callSavingPathMotor(val, rev, wp),
      400
    ),
    [] // will be created only once initially
  );

  /**
   * Appelle le moteur dans un sens ou dans l'autre
   *
   * @param val Valeur à envoyer
   * @param rev Si oui, on envoie l'effort d'épargne
   * @param wp Profil Wealth
   */
  const callSavingPathMotor = (val: number, rev: boolean, wp: string) => {
    if (wp !== "COMPARER") {
      if (rev) {
        const req: MonthlyIncomeRequest = {
          profile: wp,
          monthlySavingEffort: val,
        };
        dispatch(getMonthlyIncome(req));
      } else {
        const req: SavingEffortRequest = {
          profile: wp,
          monthlyIncomeObjective: val,
        };
        dispatch(getSavingEffort(req));
      }
    }
  };

  function onClickHypothesisModal() {
    trackClick(
      `${
        currentTab === "Comparer" ? "comparaison" : "age-cle"
      }::${TRACKING_CLICK_HYPOTHESES}`
    );
    setShowHypothesisModal(true);
  }

  useEffect(() => {
    if (!assetState.hasFetched) {
      dispatch(getAssetsWithoutLoans());
    }
    if (!hasFetchedAssets(assetIncomesState)) {
      dispatch(getAssetsIncomes());
    }
  }, []);

  useEffect(() => {
    if (currentTab) {
      if (currentTab === "Comparer") {
        trackPage("epargner", "comparaison", PAGE_TYPE_GESTION);
      } else {
        trackPage("epargner", "age-cle", PAGE_TYPE_GESTION);
      }
    }
  }, [currentTab]);

  // Récupération des âges dans le dashboard
  useEffect(() => {
    // Si on a déjà récupéré les âges, on s'arrête là
    if (agesDepartDashboard && agesDepartDashboard.length > 0) {
      return;
    }
    if (
      hasFetchedDashboard(dashboardState) &&
      agesTries &&
      selectedRetirementAge &&
      assetState &&
      hasFetchedAssets(assetIncomesState)
    ) {
      // Construction des AgesDeparts à partir de dashboardInformations
      setAgesDepartDashboard(agesTries);

      // Récupération de l'objectif
      let newObjectif = dashboardState.dashboard.monthlyIncomeGoal || 0;
      const additionalIncome = roundDown10(
        computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge)
      );
      const minIncome = roundDown10(
        selectedRetirementAge.retirementMonthlyIncome + additionalIncome
      );

      if (newObjectif < minIncome) {
        newObjectif = minIncome;
      }

      if (newObjectif !== dashboardState.dashboard.monthlyIncomeGoal) {
        saveMonthlyIncomeGoalToDb(newObjectif);
      } else {
        // Car initialisé à 0
        setObjectif(newObjectif);
      }

      // Get choosen age
      if (dashboardState.dashboard.choosenAge !== undefined) {
        setCurrentTab(dashboardState.dashboard.choosenAge);
      } else {
        setCurrentTab(agesTries[0].type);
      }
    }
  }, [
    dashboardState,
    agesTries,
    selectedRetirementAge,
    assetState,
    assetIncomesState,
  ]);

  // Initialisation des données à partir des âges récupérés
  useEffect(() => {
    if (agesDepartDashboard === undefined || agesDepartDashboard.length === 0) {
      return;
    }
    if (hasFetchedDashboard(dashboardState) && agesDepartDashboard) {
      // Init data compare tab
      const data: DataComparer[] = agesDepartDashboard.map(
        (age: DashboardAge) => ({ age, montantEpargne })
      );
      setDataComparer(data);
      const wp: string[] = agesDepartDashboard.map((age: DashboardAge) =>
        convertAgeTypeToWealthProfile(age.type)
      );
      setWealthProfiles(wp);

      // Maj des tabs à partir des ages
      const updatedTabs: TabModel[] = agesDepartDashboard.map((item) => ({
        tabName: getTabName(item) || "",
        key: item.type,
        ageRetraite: item,
      }));
      updatedTabs.push(compareTab);
      setTabs(updatedTabs);

      // save first Saving Path Date
      if (dashboardState.dashboard.firstSavingPathDate === undefined) {
        const req: User = {
          firstSavingPathDate: new Date(),
        };
        dispatch(updatePrefs(req));
      }
    }
  }, [agesDepartDashboard]);

  // Changement d'onglet ou de montantEpargne, hors COMPARER
  useEffect(() => {
    if (!currentTab || COMPARE_TAB === currentTab) {
      return;
    }
    const wealthProfile = convertAgeTypeToWealthProfile(currentTab);
    // init TabEpargner : on calcule le premier effort d'épargner à afficher
    if (!lastProfile) {
      // On initialise l'effort d'épargne si l'objectif de revenu est défini
      // sinon on initialise les revenus de retraite sans effort d'épargne
      if (objectif !== 0) {
        setSaveMotorResult(false);
        callSavingPathMotor(objectif, false, wealthProfile);
      } else {
        callSavingPathMotor(0, true, wealthProfile);
      }
      // Le montant d'épargne a changé : on calcul l'objectif de revenu correspondant
    } else if (lastSavingAmount !== montantEpargne) {
      if (lastSavedIncomeGoal === objectif) {
        setShowLoader(true);
      }

      debouncedCall(montantEpargne, true, wealthProfile);
      // On change d'onglet TabEpargner : on calcul l'objectif de revenu correspondant
    } else if (lastProfile !== wealthProfile) {
      setShowLoader(true);
      debouncedCall(montantEpargne, true, wealthProfile);
    }
    setLastSavingAmount(montantEpargne);
    setLastProfile(wealthProfile);
  }, [montantEpargne, currentTab]);

  // Récupération de l'objectif ou du revenu via le moteur
  useEffect(() => {
    if (!currentTab) {
      return;
    }
    if (saveMotorResult && hasFetchedSavingPathObjective(savingPathState)) {
      saveMonthlyIncomeGoalToDb(savingPathState.objective);
      setShowLoader(false);
    } else if (
      !saveMotorResult &&
      hasFetchedSavingPathMonthlyEffort(savingPathState)
    ) {
      setSaveMotorResult(true);
      setMontantEpargne(savingPathState.monthlyEffort);
    }
  }, [savingPathState]);

  const goToDashboard = () => {
    trackClick(
      `${
        currentTab === COMPARE_TAB ? "comparaison" : "age-cle"
      }::clic-sur-retour-au-tableau-de-bord`
    );
    history.push(DASHBOARD);
  };

  return (
    <SGGridCol span={12}>
      <div className="ModuleEpargner">
        <SGTabs
          activeKey={currentTab}
          onChange={(activeKey: DashboardAgeType | "Comparer") =>
            saveChoosenAgeToDb(activeKey)
          }
        >
          {tabs.map((item) => (
            <SGTabPane
              ariaId={item.key}
              className
              tab={<SGText>{item.tabName}</SGText>}
              key={item.key}
              disabled={
                !possedeDroitsActionUtilisateur(supervisionState) &&
                item.key !== COMPARE_TAB &&
                item.ageRetraite !== selectedRetirementAge
              }
            >
              <SGBlock>
                {item.ageRetraite && (
                  <TabEpargner
                    selectedRetirementAge={item.ageRetraite}
                    objectif={objectif}
                    montantEpargne={montantEpargne}
                    setMontantEpargne={setMontantEpargne}
                    showLoader={showLoader}
                  />
                )}
                {item.key === COMPARE_TAB && (
                  <ComparerTab
                    objectif={objectif}
                    montantEpargne={montantEpargne}
                    setMontantEpargne={setMontantEpargne}
                    data={dataComparer}
                    wealthProfiles={wealthProfiles}
                    currentKey={currentTab || ""}
                    itemKey={item.key}
                  />
                )}
              </SGBlock>
            </SGTabPane>
          ))}
        </SGTabs>
        {showHypothesisModal && (
          <HypothesisModal
            setVisible={setShowHypothesisModal}
            visible={showHypothesisModal}
            anchor={HypothesisAnchor.EPARGNER}
            closeFirstGroup
          />
        )}
        <SGGridRow justify="start">
          <SGGridCol span={12}>
            <SGBox margin={{top: "md", bottom: "md"}}>
              <>
                <SGText size="s" color="lighter">
                  <FormattedMessage
                    id={`parcoursEpargner.moduleEpargner.${
                      currentTab === COMPARE_TAB ? "mention.comparer" : "mention"
                    }`}
                  />
                </SGText>
                <SGButton type="link" onClick={() => onClickHypothesisModal()}>
                  <FormattedMessage id="parcoursEpargner.moduleEpargner.mention.link" />
                </SGButton>
              </>
            </SGBox>
          </SGGridCol>
          <SGGridCol>
            <SGButton
              type="tertiary"
              icon={
                <SGIcon
                  rotate="left"
                  component={<Arrow className="tab-epargner__arrow" />}
                />
              }
              iconposition="left"
              onClick={() => goToDashboard()}
            >
              <SGText className="tab-epargner__link">
                {intl.formatMessage({
                  id: "parcoursEpargner.tabEpargner.link.retourBilanRetraite",
                })}
              </SGText>
            </SGButton>
          </SGGridCol>
        </SGGridRow>
      </div>
    </SGGridCol>
  );
};

export { ModuleEpargner };
