import React, {FunctionComponent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {useIntl} from "react-intl";
import {Family, FamilyStatus, Me, Partner, RetirementCategory,} from "../../../../../store/members/types";
import {State} from "../../../../../store/store";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {simulateRetirementProjectAction, updateRetirementProjectValuesInStoreAction} from "../../../../../store/projects/actions";
import {IncomeType,} from "../../../../../store/incomes/types";
import {AGE_FOR_RETIREMENT_WITH_FULL_PENSION, calculateAgeByBirthdate, MAX_AGE_TO_RETIRE,} from "../../../../../store/members/utils";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {DateInput, DatePattern} from "../../../atoms/DateInput/DateInput";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {convertNumberToStringWithSeparators, roundToHundreds,} from "../../../../utils/formatting/numberFormatter";
import {useFamily} from "../../../hooks/useFamily";
import {useIncomes} from "../../../hooks/useIncomes";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGCard} from "sg-card";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {getMaxDateForMyBirthdate} from "../../../../utils/date/DateUtils";
import {GraphRetirementSimulation} from "../../../molecules/GraphRetirementSimulation/GraphRetirementSimulation";
import {SelectInputButtons} from "website/components/atoms/SelectInputButtons/SelectInputButtons";
import {updateFamilyAction} from "../../../../../store/members/actions";
import {createIncomeAction, updateIncomeAction} from "../../../../../store/incomes/actions";
import {useMediaQuery} from "sg-media-query";
import {
   IncomeLine,
   PartialRetirementProjectValues,
   RetirementProjectValues,
   RetirementSimulation,
   WorkIncomeEvolutionLevel
} from "../../../../../store/projects/types";
import {SGSpace} from "sg-space";

interface RetirementProjectProps {
   member: Me | Partner;
   retirementSimulation?: RetirementSimulation;
}

const RetirementProject: FunctionComponent<RetirementProjectProps> = ({member, retirementSimulation}: RetirementProjectProps) => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();
   const incomes = useIncomes();
   const hasFetchedIncomes = useSelector<State, boolean>((state) => state.incomes.hasFetched);
   const hasFetchedMyRetirementSimulation = useSelector<State, boolean>((state) => state.projects.hasFetchedMyRetirementSimulation);
   const hasFetchedPartnerRetirementSimulation = useSelector<State, boolean>((state) => state.projects.hasFetchedPartnerRetirementSimulation);
   const areGrowthButtonVertical = useMediaQuery({
      minwidth: 'xs',
      maxwidth: 'md',
   });

   const retirementProjectValues = useSelector<State, RetirementProjectValues>((state) => state.projects.retirementProject);

   const retirementCategories: RetirementCategory[] = Object.values(RetirementCategory);

   const [isTooOldForRetirementProject, setTooOldForRetirementProject] = useState<boolean>(false);
   const [isBirthdateUndefined, setBirthdateUndefined] = useState<boolean>(false);
   const [selectedWorkIncomeEvolution, setSelectedWorkIncomeEvolution] = useState<WorkIncomeEvolutionLevel>(WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES);
   const [selectedRetirementAge, setSelectedRetirementAge] = useState<number>(AGE_FOR_RETIREMENT_WITH_FULL_PENSION);

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   useEffect(() => {
      if (hasFetchedIncomes) {
         const currentIncome = incomes.filter((i) => i.type === IncomeType.WORK_INCOME && i.memberId === member.id)[0];
         methods.setValue("currentIncome", currentIncome?.monthlyAmount);
      }
   }, [incomes, hasFetchedIncomes])

   useEffect(() => {
      setSelectedWorkIncomeEvolution(retirementSimulation?.salaryEvolutionLevel ? retirementSimulation?.salaryEvolutionLevel : WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES);
      setTooOldForRetirementProject(calculateAgeByBirthdate(methods.getValues("birthday")) > MAX_AGE_TO_RETIRE - 1);
   }, []);

   useEffect(() => {
      const hasFetchedRetirementSimulation = (member.status === FamilyStatus.ME && hasFetchedMyRetirementSimulation) || (member.status === FamilyStatus.PARTNER && hasFetchedPartnerRetirementSimulation)
      if (hasFetchedRetirementSimulation && retirementSimulation?.retirementYearToIncomeBeforeRetirementMap !== undefined) {
         const keys = Object.keys(retirementSimulation.retirementYearToIncomeBeforeRetirementMap).map((x) => parseInt(x));
         const defaultAge = Math.min(...keys);

         if (defaultAge < AGE_FOR_RETIREMENT_WITH_FULL_PENSION) {
            setSelectedRetirementAge(AGE_FOR_RETIREMENT_WITH_FULL_PENSION);
         } else {
            setSelectedRetirementAge(defaultAge);
         }
      }
   }, [retirementProjectValues, hasFetchedMyRetirementSimulation, hasFetchedPartnerRetirementSimulation]);

   const onSelectBirthday = (date: Date) => {
      setTooOldForRetirementProject(calculateAgeByBirthdate(date) > MAX_AGE_TO_RETIRE - 1);
      setBirthdateUndefined(date === undefined);
   };

   const simulateRetirement = () => {
      setSelectedRetirementAge(AGE_FOR_RETIREMENT_WITH_FULL_PENSION);

      // Mise à jour de la date de naissance et du type de retraite
      if (member.status === FamilyStatus.ME) {
         const familyToDispatch: Family = {
            me: {...member, birthday: methods.getValues("birthday"), retirementType: methods.getValues("retirementType")},
            partner: family.partner,
            children: family.children,
            relatives: family.relatives
         };
         dispatch(updateFamilyAction(familyToDispatch, false, false));
      } else if (member.status === FamilyStatus.PARTNER) {
         const familyToDispatch: Family = {
            me: family.me,
            partner: {...member, birthday: methods.getValues("birthday"), retirementType: methods.getValues("retirementType")},
            children: family.children,
            relatives: family.relatives
         };
         dispatch(updateFamilyAction(familyToDispatch, false, false));
      }

      // Mise à jour du salaire
      const currentIncome = incomes.filter((i) => i.type === IncomeType.WORK_INCOME && i.memberId === member.id)[0];
      if (currentIncome === undefined) {
         dispatch(
            createIncomeAction({
               type: IncomeType.WORK_INCOME,
               monthlyAmount: methods.getValues("currentIncome"),
               memberId: member.id,
               name: intl.formatMessage({id: `form.income.name.${member.status}.WORK_INCOME`}),
            })
         );
      } else if (currentIncome.monthlyAmount !== methods.getValues("currentIncome")) {
         dispatch(
            updateIncomeAction({
               ...currentIncome,
               monthlyAmount: methods.getValues("currentIncome")
            })
         );
      }

      const retirementProjectToSubmit: Partial<PartialRetirementProjectValues> = {
         myRetirement: retirementProjectValues?.myRetirement,
         partnerRetirement: hasFetchedPartnerRetirementSimulation ? retirementProjectValues?.partnerRetirement : undefined,
      };
      if (member.status === FamilyStatus.ME) {
         retirementProjectToSubmit.myRetirement = {
            birthdate: methods.getValues("birthday"),
            currentMonthlyIncome: methods.getValues("currentIncome"),
            salaryEvolutionLevel:
               selectedWorkIncomeEvolution ||
               WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
            type: methods.getValues("retirementType"),
         };
      } else {
         retirementProjectToSubmit.partnerRetirement = {
            birthdate: methods.getValues("birthday"),
            currentMonthlyIncome: methods.getValues("currentIncome"),
            salaryEvolutionLevel:
               selectedWorkIncomeEvolution ||
               WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES,
            type: methods.getValues("retirementType"),
         };
      }

      dispatch(simulateRetirementProjectAction(retirementProjectToSubmit));
   };

   const onSelectRetirementAge = (retirementAge: number) => {
      // On récupère l'année de naissance settée au 1er janvier pour stocker des projets retraite au 1er janvier
      const birthYear: Date = new Date(methods.getValues("birthday"));
      birthYear.setMonth(0);
      birthYear.setDate(1);

      setSelectedRetirementAge(retirementAge);

      // Lancement de la simulation
      const newIncomeLine: IncomeLine = {
         name: retirementSimulation?.retirementIncomes[0].name || "",
         income: retirementSimulation?.retirementYearToRetirementIncomeMap[retirementAge] || 0
      };

      const retirementProjectValuesToDispatch = {...retirementProjectValues};

      if (member?.status === FamilyStatus.ME) {
         retirementProjectValuesToDispatch.myRetirement = {
            ...retirementProjectValuesToDispatch.myRetirement,
            memberRetirementDate: new Date(birthYear.setFullYear(birthYear.getFullYear() + Number(retirementAge))),
            retirementIncomes: [newIncomeLine]
         }
      } else if (member?.status === FamilyStatus.PARTNER && retirementProjectValues.partnerRetirement !== undefined) {
         retirementProjectValuesToDispatch.partnerRetirement = {
            ...retirementProjectValuesToDispatch.partnerRetirement,
            memberRetirementDate: new Date(birthYear.setFullYear(birthYear.getFullYear() + Number(retirementAge))),
            retirementIncomes: [newIncomeLine]
         }
      }

      retirementProjectValuesToDispatch.expenses = {
         ...retirementProjectValuesToDispatch.expenses,
         common: roundToHundreds(retirementProjectValuesToDispatch.expenses.common),
         total: retirementProjectValuesToDispatch.expenses.projects + retirementProjectValuesToDispatch.expenses.other + roundToHundreds(retirementProjectValuesToDispatch.expenses.common),
      };

      dispatch(updateRetirementProjectValuesInStoreAction(retirementProjectValuesToDispatch));
   };

   return (
      <>
         <SpinnerWrapper spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout displayComponent={hasFetchedIncomes}>
            {hasFetchedIncomes &&
               <FormProvider {...methods}>
                  <form>
                     <SGCard data-cy="project-retirement-frame-input">
                        <SGTitleIntl intlId={`project.retirement.title.${member.status.toLowerCase()}`} cypressName="retirement-project-title"/>
                        <FormInputs colLength={10}>
                           <NumericInput suffix="€" defaultValue={0} cypressName="retirement-project-income" required
                                         name="currentIncome" label="project.retirement.form.monthly-income"
                                         placeholder={intl.formatMessage({id: "project.retirement.form.monthly-income.placeholder"})}
                           />
                           <DateInput cypressName="retirement-project-birthdate" onDateChange={(value: Date) => {
                              onSelectBirthday(value);
                           }} required name="birthday" min="1935-01-01" pattern={DatePattern.DAYS} max={getMaxDateForMyBirthdate()}
                                      placeholder="project.retirement.form.birthdate.placeholder"
                                      defaultValue={member.birthday}
                                      label="project.retirement.form.birthdate"
                           />

                           {isTooOldForRetirementProject && (
                              <SGAlertIntl type="warning" cypressName="retirement-project-too-old-warning" title="common.warning">
                                 <SGTextIntl intlId="project.retirement.error.too.old"/>
                              </SGAlertIntl>
                           )}

                           <SelectInput cypressName="retirement-project-retirement-type" name="retirementType"
                                        defaultValue={member.retirementType || RetirementCategory.SALARIE_PRIVE_CADRE}
                                        label="project.retirement.form.tax-system">
                              {retirementCategories.map((retirementCategory: RetirementCategory) => (
                                    <SGSelectOption key={retirementCategory} data-cy={`retirement-project-retirement-type${retirementCategory}`}
                                                    value={retirementCategory}>
                                       {intl.formatMessage({id: `retirement.category.${retirementCategory}`})}
                                    </SGSelectOption>
                                 )
                              )}
                           </SelectInput>
                           <SGSpace direction="vertical">
                              <SGTextIntl intlId="project.retirement.estimate-salary-growth.title"/>

                              <SelectInputButtons size="sm" cypressName="retirement-project-growth"
                                                  direction={areGrowthButtonVertical ? "vertical" : "horizontal"}
                                                  choice={selectedWorkIncomeEvolution} setChoice={setSelectedWorkIncomeEvolution}
                                                  labels={["project.retirement.estimate-salary-growth.weak", "project.retirement.estimate-salary-growth.medium", "project.retirement.estimate-salary-growth.high"]}
                                                  choices={[WorkIncomeEvolutionLevel.NO_WORK_INCOME_RAISES, WorkIncomeEvolutionLevel.LOW_WORK_INCOME_RAISES, WorkIncomeEvolutionLevel.HIGH_WORK_INCOME_RAISES]}/>

                              <SGButtonGroup align="center">
                                 <SGButtonIntl type="primary" size="md" cypressName="retirement-project-simulate" onClick={simulateRetirement}
                                               disabled={!methods.formState.isValid || isTooOldForRetirementProject || isBirthdateUndefined || selectedWorkIncomeEvolution === undefined}
                                               intlId={`project.retirement.simulate.${member.status.toLowerCase()}`}/>
                              </SGButtonGroup>
                           </SGSpace>
                        </FormInputs>
                        {((member.status === FamilyStatus.ME && hasFetchedMyRetirementSimulation) || (member.status === FamilyStatus.PARTNER && hasFetchedPartnerRetirementSimulation)) && retirementSimulation !== undefined && retirementSimulation.retirementYearToRetirementIncomeMap[selectedRetirementAge] !== undefined && (<>
                              <GraphRetirementSimulation data={retirementSimulation.retirementYearToRetirementIncomeMap}
                                                         selectedAge={selectedRetirementAge}
                                                         onClick={(selectedAge: number) => onSelectRetirementAge(selectedAge)}/>
                              <SGCard dark textalign="center">
                                 <SGTextIntl intlId="project.retirement.amount" transformations={{
                                    age: selectedRetirementAge,
                                    retirementAmount: convertNumberToStringWithSeparators(retirementSimulation.retirementYearToRetirementIncomeMap[selectedRetirementAge]),
                                    lastIncomeAmount: convertNumberToStringWithSeparators(retirementSimulation.retirementYearToIncomeBeforeRetirementMap[selectedRetirementAge])
                                 }}/>
                              </SGCard>

                           </>
                        )}
                     </SGCard>
                  </form>
               </FormProvider>}
         </SpinnerWrapper>
      </>
   );
};

export {RetirementProject};
