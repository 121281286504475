import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLeading } from "redux-saga/effects";
import { onDefaultError, safe } from "../error/utils";
import { goToStep, nextStep, previousStep, resetTunnel, setIsTunnelReforme } from "./tunnelSlice";
import { GO_TO_STEP, NEXT_STEP, PREVIOUS_STEP, RESET_TUNNEL, SET_IS_TUNNEL_REFORME } from "./types";

export function* watcherSaga() {
   yield takeLeading(RESET_TUNNEL, safe(onDefaultError, handleReset));
   yield takeLeading(NEXT_STEP, safe(onDefaultError, handleNextStep));
   yield takeLeading(PREVIOUS_STEP, safe(onDefaultError, handlePreviousStep));
   yield takeLeading(GO_TO_STEP, safe(onDefaultError, handleGoToStep));
   yield takeLeading(SET_IS_TUNNEL_REFORME, safe(onDefaultError, handleSetIsTunnelReforme));
}

function* handleReset() {
   yield put(resetTunnel());
}

function* handleNextStep() {
   yield put(nextStep());
}

function* handlePreviousStep() {
   yield put(previousStep());
}

function* handleGoToStep(action: PayloadAction<number>) {
   yield put(goToStep(action.payload));
}

function* handleSetIsTunnelReforme(action: PayloadAction<boolean>) {
   yield put(setIsTunnelReforme(action.payload));
}
