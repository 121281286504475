import React, {FunctionComponent} from "react";
import {DefaultProject} from "../../components/organisms/Projects/DefaultProject/DefaultProject";
import {ProjectType} from "../../../store/projects/types";
import {SGContent} from "sg-layout";

const CapitalGainProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <DefaultProject
         projectType={ProjectType.CAPITAL_GAIN}
         projectTitle="project.capital.gain.title"
         projectSubtitle="project.capital.gain.subtitle"
         projectAmountLabel="project.capital.gain.form.amount"
         projectAmountPlaceholder="project.capital.gain.form.amount.placeholder"
         projectHorizonLabel="project.capital.gain.form.horizon"
         newProjectTitle="new.project.title.capital.gain"
         cypressName="capital-gain"
      />
   </SGContent>
);

export {CapitalGainProjectPage};
