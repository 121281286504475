import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface InfoAssuModalProps {
    text: string;
    tranform?: Record<string, string | React.ReactNode>;
    onHide?: () => void;
    showInfoModal: boolean;
    trackKey?: string;
    trackPageCategory3?: string;
    trackKeyOnHide?: string;
    trackKeyOnClick?: string;
    trackKeyButtonSecondaryOnHide?: string;
    trackKeyFormField1?: string;
    onClick?: () => void;
    title: string;
    buttonPrimary?: string;
    buttonSecondary?: string;
    titleLevel?: number;
    isBoutonSupervision?: boolean;
}

export const InfoAssuModal: FunctionComponent<InfoAssuModalProps> = (props: InfoAssuModalProps) => {
    const {
        onHide,
        text,
        tranform,
        showInfoModal,
        trackKey,
        onClick,
        title,
        buttonPrimary,
        trackPageCategory3 = "",
        trackKeyOnHide,
        trackKeyOnClick,
        trackKeyButtonSecondaryOnHide,
        buttonSecondary,
        titleLevel = 1,
        trackKeyFormField1,
        isBoutonSupervision = false,
    } = props;

    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const { trackPopin, resetTracking } = useTracking();

    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (showInfoModal) {
            setShowModal(showInfoModal);
            if (trackKey) {
                trackPopin(false, trackPageCategory3, trackKey, "", "", "", trackKeyFormField1 ? { form_field_1: trackKeyFormField1 } : undefined);
            }
        }
    }, [showInfoModal]);

    const hide = () => {
        setShowModal(false);
        if (trackKey) {
            resetTracking();
        }
        if (onHide) {
            onHide();
        }
    };

    const click = () => {
        if (onClick) {
            onClick();
        }
        if (onHide) {
            hide();
        }
    };

    return (
        <SGModal
            visible={showModal}
            centered
            closable
            onCancel={() => {
                if (trackKeyOnHide) {
                    trackPopin(true, trackPageCategory3, trackKeyOnHide, "", "", "", trackKeyFormField1 ? { form_field_1: trackKeyFormField1 } : undefined);
                }
                hide();
            }}
            header={
                <SGBox margin={{ top: "md" }}>
                    <SGTitle level={titleLevel} textalign="left">
                        {intl.formatMessage({ id: title })}
                    </SGTitle>
                </SGBox>
            }
        >
            <SGSpace direction="vertical" textalign="left" size="xl">
                <SGText key="text" size={isPhone ? "m" : "xl"} weight="600">
                    {intl.formatMessage({ id: text }, tranform)}
                </SGText>
                {buttonPrimary && (
                    <SGButtonGroup key="buttons" align={buttonSecondary ? "opposite" : "center"}>
                        {isBoutonSupervision ?
                            (<BoutonSupervision
                                onClick={() => {
                                    if (trackKeyOnClick) {
                                        trackPopin(
                                            true,
                                            trackPageCategory3,
                                            trackKeyOnClick,
                                            "",
                                            "",
                                            "",
                                            trackKeyFormField1 ? { form_field_1: trackKeyFormField1 } : undefined
                                        );
                                    }
                                    click();
                                }}
                            >
                                {intl.formatMessage({ id: buttonPrimary })}
                            </BoutonSupervision>
                        ) : (
                            <SGButton
                                onClick={() => {
                                    if (trackKeyOnClick) {
                                        trackPopin(
                                            true,
                                            trackPageCategory3,
                                            trackKeyOnClick,
                                            "",
                                            "",
                                            "",
                                            trackKeyFormField1 ? { form_field_1: trackKeyFormField1 } : undefined
                                        );
                                    }
                                    click();
                                }}
                            >
                                {intl.formatMessage({ id: buttonPrimary })}
                            </SGButton>)
                        }
                        {buttonSecondary && (
                            <SGButton
                                onClick={() => {
                                    if (trackKeyButtonSecondaryOnHide) {
                                        trackPopin(
                                            true,
                                            trackPageCategory3,
                                            trackKeyButtonSecondaryOnHide,
                                            "",
                                            "",
                                            "",
                                            trackKeyFormField1 ? { form_field_1: trackKeyFormField1 } : undefined
                                        );
                                    }
                                    hide();
                                }}
                                type="secondary"
                            >
                                {intl.formatMessage({ id: buttonSecondary })}
                            </SGButton>
                        )}
                    </SGButtonGroup>
                )}
            </SGSpace>
        </SGModal>
    );
};
