import React, { FunctionComponent } from "react";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText } from "sg-typo";
import { isMobile } from "store/import/utils";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import infoAssuImg from "../../../../../assets/images/infoAssu.png";
import "./DetailTab.scss";

export interface DetailTabProps {
    lines: DetailTabLine[];
    hideDivider?: boolean;
    disableautomarginDivider? : boolean
}

export interface DetailTabLine {
    id: string;
    value: number;
}

export const DetailTab: FunctionComponent<DetailTabProps> = (props: DetailTabProps) => {
    const { lines, hideDivider, disableautomarginDivider = false } = props;
    let totalValue = 0;

    lines.forEach((line) => {
        // On filtre l'objectif et le revenu car ils représentent les totaux
        if (line.id !== "objectif" && line.id !== "revenu") {
            totalValue += line.value;
        }
    });

    function getPercent(value: number) {
        const percent = (value * 100) / totalValue;

        if (percent < 0) {
            return 0;
        }
        if (percent > 100) {
            return 100;
        }

        return percent;
    }

    if (!isMobile()) {
        return (
            <>
                <SGGridRow>
                    {!hideDivider && <SGDivider />}
                    <SGGridCol span={12}>
                        <div className="DetailTab__bar">
                            {lines?.map(
                                (line: DetailTabLine) =>
                                    // On filtre l'objectif et le revenu car ils représentent les totaux
                                    line.id !== "objectif" &&
                                    line.id !== "revenu" && (
                                        <div
                                            key={line.id}
                                            style={{ width: `${getPercent(line.value)}%` }}
                                            className={`DetailTab__bar--part DetailTab__legend--${line.id}`}
                                        />
                                    )
                            )}
                        </div>
                    </SGGridCol>
                </SGGridRow>
                {lines?.map((line: DetailTabLine) => (
                    <div className={`DetailTab--line DetailTab--${line.id}`}>
                    <SGGridRow key={line.id} span={12} justify="space-between" >
                        {line.id === "objectif" && (
                            <SGGridCol span={12}>
                                <SGDivider />
                            </SGGridCol>
                        )}
                        <SGGridCol>
                            <div className={`DetailTab__legend DetailTab__legend--${line.id}`} />
                            <SGText size="s">
                                <SGTextIntl intlId={`parcoursEpargner.DetailTab.label.${line.id}`} />
                            </SGText>
                        </SGGridCol>
                        <SGGridCol textalign="right">
                            <SGText size="s">{line.value.toLocaleString("fr-FR")} € / mois</SGText>
                        </SGGridCol>
                    </SGGridRow>
                </ div>
                ))}
            </>
        );
    }

    return (
        <SGGridRow style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <SGGridCol>
                <SGGridRow>
                    {!hideDivider && <SGDivider disableautomargin={disableautomarginDivider} />}
                    <SGGridCol span={12}>
                        <div className="DetailTab__bar">
                            {lines?.map(
                                (line: DetailTabLine) =>
                                    // On filtre l'objectif et le revenu car ils représentent les totaux
                                    line.id !== "objectif" &&
                                    line.id !== "revenu" && (
                                        <div
                                            key={line.id}
                                            style={{ width: `${getPercent(line.value)}%` }}
                                            className={`DetailTab__bar--part DetailTab__legend-mob--${line.id}`}
                                        />
                                    )
                            )}
                        </div>
                    </SGGridCol>
                </SGGridRow>
                {lines?.map((line: DetailTabLine) => (
                    line.id !== "objectif" ?
                        <div className={`DetailTab--line DetailTab--${line.id}`}>
                            <SGGridRow key={line.id} span={12}>
                                <SGGridCol span={1}>
                                    <div className={`DetailTab__legend-mob DetailTab__legend-mob--${line.id}`} />
                                </SGGridCol>
                                <SGGridCol span={7}>
                                    <SGText size="s">
                                        <SGTextIntl intlId={`parcoursEpargner.DetailTab.label.${line.id}`} />
                                    </SGText>
                                </SGGridCol>
                                <SGGridCol textalign="right" span={4}>
                                    <SGText size="s">{line.value.toLocaleString("fr-FR")} € / mois</SGText>
                                </SGGridCol>
                            </SGGridRow>
                        </div>
                        : <></>
                ))}
            </SGGridCol>
        </SGGridRow>
    );
};
