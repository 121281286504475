import React, {FunctionComponent, useEffect} from "react";
import {Recommendations} from "../components/organisms/Advices/Recommendations/Recommendations";
import {SGContent} from "sg-layout";
import {GoFurther} from "../components/molecules/GoFurther/GoFurther";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const AdvicesPage: FunctionComponent = () => {
   const { trackPage } = useTracking();

   useEffect(() => {
      trackPage("coach", "accueil", "gestion-ecran");
   }, []);

   return (
      <SGContent>
         <SGTitleIntl intlId="advices.coach.suggestions" cypressName="recommendations-title"/>
         <SGTextIntl intlId="advices.coach.suggestions.explain"/>
         <Recommendations/>
         <GoFurther cypressParentName="net-worth-distribution"/>
      </SGContent>
   );
}

export {AdvicesPage};
