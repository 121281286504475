import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { PARCOURS_EPARGNER } from "website/utils/privateRoutes";
import "./MonEpargneAConstituer.scss";

interface MonEpargneAConstituerProps {
  isParcoursEpargnerDone?: boolean;
  effortEpargne?: number;
}
const MonEpargneAConstituer: FunctionComponent<MonEpargneAConstituerProps> = (
  props: MonEpargneAConstituerProps
) => {
  const history = useHistory();
  const { isParcoursEpargnerDone, effortEpargne } = props;
  const intl = useIntl();
  const { trackClick } = useTracking();

  const goToParcoursEpargner = () => {
    trackClick(
      `mon-epargne-a-constituer::clic-sur-${
        isParcoursEpargnerDone
          ? "je-modifie-mon-epargne"
          : "je-definis-mon-epargne"
      }`
    );
    history.push(PARCOURS_EPARGNER);
  };

  const redirectToMaCheckList = () => {
    trackClick("mon-epargne-a-constituer::clic-sur-ma-checklist");
    history.push(`/ma-checklist/patrimoine`);
  };

  return (
    <SGCard
      coversize="md"
      hoverable
      borderleft="7px solid #090F70"
      title={
        <SGText
          style={{
            color: "#090F70",
          }}
          size="xl"
          weight="600"
        >
          {intl.formatMessage({ id: "monPlan.monEpargne.title" })}
        </SGText>
      }
      className="mon-epargne-a-constituer__card"
    >
      <>
        <SGGridRow>
          <SGGridCol>
            <SGText>
              <SGTextIntl
                intlId={`monPlan.monEpargne.${
                  isParcoursEpargnerDone ? "done" : "not.done"
                }`}
                size="m"
                transformations={{
                  effortEpargne: effortEpargne?.toLocaleString("fr-FR"),
                  b: (word: string) => <strong>{word}</strong>,
                }}
              />
            </SGText>
          </SGGridCol>
        </SGGridRow>
        <SGMediaQuery minwidth={0} maxwidth="sm">
          <SGBox margin={{top: "sm"}} textalign="right">
            <SGButton
              onClick={redirectToMaCheckList}
              type="tertiary"
              icon={
                <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
              }
              iconposition="right"
            >
              {intl.formatMessage({ id: "monPlan.checklist" })}
            </SGButton>
          </SGBox>
        </SGMediaQuery>
        <SGBox margin={{top: "sm"}} textalign="right">
          <SGButton
            onClick={goToParcoursEpargner}
            type="tertiary"
            icon={
              <SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />
            }
            iconposition="right"
          >
            {intl.formatMessage({
              id: `monPlan.monEpargne.${
                isParcoursEpargnerDone ? "modifier" : "renseigner"
              }`,
            })}
          </SGButton>
        </SGBox>
      </>
    </SGCard>
  );
};
export { MonEpargneAConstituer };
