import React, { FunctionComponent, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SGPriceCommercial, SGText } from "sg-typo";

interface SalaryByMonthProps {
    salaryMonth: number | undefined;
    color?: string;
    hideSalary?: boolean;
}

export const SalaryByMonth: FunctionComponent<SalaryByMonthProps> = (props: React.PropsWithChildren<SalaryByMonthProps>) => {
    const {
        salaryMonth,
        color,
        hideSalary,
    } = props;

    const couleurStyle = useMemo(() => {
        if (color === "purple") {
            return "#9f2d62";
        }

        return "#208382";
    }, [color]);

    const getSalaryMonth = () => {
        if (hideSalary) {
            return "--";
        }

        return salaryMonth?.toLocaleString("fr-FR") || "--";
    };

    return (
        <>
            <SGPriceCommercial value={getSalaryMonth()} size="xl" style={{color: couleurStyle}} />
            <SGText size="l" weight="600" style={{fontFamily: "Montserrat", color: couleurStyle}}>
                <FormattedMessage id="dashboard.moisnet" />
            </SGText>
        </>
    );
};
