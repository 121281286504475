import React, {FunctionComponent} from "react";
import {ChildrenEducationProject} from "../../components/organisms/Projects/ChildrenEducationProject/ChildrenEducationProject";
import {SGContent} from "sg-layout";

const ChildrenEducationProjectPage: FunctionComponent = () => (
   <SGContent span={8}>
      <ChildrenEducationProject/>
   </SGContent>
);

export {ChildrenEducationProjectPage};
