import React, {FunctionComponent, useContext, useEffect} from "react";
import {useDispatch} from "react-redux";
import {nextStepAction, previousStepAction} from "../../../../../store/tunnel/actions";
import {AssetsEditor} from "../../Editors/AssetsEditor/AssetsEditor";
import {LoansEditor} from "../../Editors/LoansEditor/LoansEditor";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {EditorContext, EditorContextProvider} from "../../../providers/EditorProvider";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGSpace} from "sg-space";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const TunnelAssets: FunctionComponent = () => {
   const dispatch = useDispatch();
   const { trackPage } = useTracking();

   useEffect(() => {
      trackPage("funnel", "mon-patrimoine-residence-principale", "formulaire-ecran", "inscription", "2.2");
   }, []);

   const TunnelAssetButtons = () => {
      const {isEditing} = useContext(EditorContext);

      return (
         <SGButtonGroup align="center" layout="column">
            <SGButtonIntl type="primary" size="md" disabled={isEditing} cypressName="tunnel-next"
                          onClick={() => { dispatch(nextStepAction()) }} trackingName="mon-patrimoine-financier-immobilier::clic-sur-confirmer"
                          intlId="tunnel.buttons.next"/>
            <SGButtonIntl type="link" cypressName="tunnel-previous"
                          onClick={() => { dispatch(previousStepAction()) }} trackingName="mon-patrimoine-financier-immobilier::clic-sur-retour"
                          intlId="tunnel.buttons.previous"/>
         </SGButtonGroup>

      );
   };

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="tunnel.step.netWorth" cypressName="tunnel-asset-title"/>
         <SGTextIntl intlId="tunnel.step.netWorth.label"/>
         <EditorContextProvider>
            <SGSpace direction="vertical">
               <AssetsEditor displayAssetShares cypressName="tunnel-assets"/>
               <SGTitleIntl intlId="profile.loans.page.title" cypressName="tunnel-loans-editor-title"/>
               <LoansEditor cypressName="tunnel-loans"/>
               <TunnelAssetButtons/>
            </SGSpace>
         </EditorContextProvider>
      </SGSpace>
   );
};

export {TunnelAssets};
