import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import {LoanEdit} from "../LoanEdit/LoanEdit";
import {Loan} from "../../../../store/loans/types";
import {deleteLoanAction, updateLoanAction} from "../../../../store/loans/actions";
import {getEndDate} from "../../../../store/loans/utils";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {DeleteModal} from "../DeleteModal/DeleteModal";
import {useSCIAssets} from "../../hooks/useSCIAssets";
import {isAssociatedToSCI} from "../../../../store/assets/utils";
import {EditorContext} from "../../providers/EditorProvider";
import {SGGridCol, SGGridRow} from "sg-grid";
import {LOAN} from "../../../../store/assets/types";
import {SGCard} from "sg-card";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirStrokedCorbeille, SGAvenirStrokedModification} from "sg-icon-pack-base";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";

interface LoanElementProps {
   loan: Loan;
   cypressName: string;
}

const LoanElement: FunctionComponent<LoanElementProps> = (
   props: LoanElementProps
) => {
   const {loan, cypressName} = props;

   const [isEditingLoan, setIsEditingLoan] = useState(false);
   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);

   const dispatch = useDispatch();
   const sciAssets = useSCIAssets();

   const methods = useForm({
      criteriaMode: "all",
      mode: "onChange",
   });

   useEffect(() => {
      setIsEditingLoan(false);
      if (isDeleteModalVisible) {
         setDeleteModalVisible(false);
      }
   }, [loan]);

   useEffect(() => {
      setEditing(isEditingLoan);
   }, [isEditingLoan]);

   const isImportedLoan = (): boolean => loan.externalId !== null;

   const handleClickRemove = () => {
      if (loan.id != null) {
         dispatch(deleteLoanAction(loan));
      }
   };

   const onSubmit = (data: Loan) => {
      dispatch(updateLoanAction({
         ...loan,
         name: data.name,
         startDate: data.startDate,
         displayedAmount: data.displayedAmount,
         monthlyAmount: data.monthlyAmount,
         durationInMonth: data.durationInMonth,
         amortizationType: data.amortizationType,
      }));
   };

   const renderLoanElement = () => (
      <SGGridRow justify="center" align="center">
         <SGGridCol span={4}>{loan.name}</SGGridCol>
         {!isAssociatedToSCI(sciAssets, loan) &&
            <>
               <SGGridCol span={2}>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" decimalScale={0} displayType="text" value={Math.trunc(loan.remainingPrincipal)}/>
               </SGGridCol>
               <SGGridCol span={4}>
                  <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={loan.monthlyAmount}/>
                  <SGTextIntl intlId="form.loan.by-month-until"/>
                  <span>{getEndDate(loan)}</span>
               </SGGridCol>
            </>
         }
         {isAssociatedToSCI(sciAssets, loan) && <SGGridCol span={4}><SGTextIntl intlId="assetType.SCI"/></SGGridCol>}
         <SGGridCol span={2} textalign="right">
            <SGButton type="icon" data-cy={`${cypressName}-edit-button`} disabled={isEditing || isImportedLoan()}
                      onClick={() => setIsEditingLoan(true)}><SGIcon component={<SGAvenirStrokedModification/>}/></SGButton>
            <SGButton type="icon" data-cy={`${cypressName}-delete-button`} disabled={isEditing} onClick={() => setDeleteModalVisible(true)}><SGIcon
               component={<SGAvenirStrokedCorbeille/>}/></SGButton>
         </SGGridCol>
      </SGGridRow>
   );

   const renderLoanEdit = () => (
      <FormProvider {...methods}>
         <form>
            <LoanEdit editedLoan={loan} onCancel={() => setIsEditingLoan(false)} onSubmit={onSubmit} cypressName={`${cypressName}-edit`}/>
         </form>
      </FormProvider>
   );

   return (
      <SGCard borderleft={`.3rem solid ${LOAN.color.hexCode}`} align="center" data-cy={`${cypressName}-element`}>
         {isEditingLoan && renderLoanEdit()}
         {!isEditingLoan && renderLoanElement()}

         {/* Modal de confirmation de suppression */}
         {isDeleteModalVisible && (
            <DeleteModal hideModal={() => setDeleteModalVisible(false)} cypressName={cypressName} textId="common.delete.modal.text"
                         onSubmit={handleClickRemove}/>
         )}
      </SGCard>
   );
};

export {LoanElement};
