import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { AssetState, AssetWithValuation } from "../../../store/assets/types";
import { getAssetsWithoutLoans } from "../../../store/assets/actions";
import {
  getAssociatedSciName,
  isAssociatedToSCI,
} from "../../../store/assets/utils";
import { useFeatures } from "./useFeatures";

const useAssets = () => {
  const dispatch = useDispatch();
  const [assets, setAssets] = useState<AssetWithValuation[]>([]);
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const { disponibiliteParcoursRevenusComplementaires } = useFeatures();

  useEffect(() => {
    if (!assetState.hasFetched) {
      dispatch(getAssetsWithoutLoans());
    }
  }, []);

  useEffect(() => {
    if (assetState.hasFetched) {
      const arrayForSort = [...assetState.assets];
      setAssets(
        arrayForSort.sort(
          (asset1: AssetWithValuation, asset2: AssetWithValuation) => {
            const asset1SortLabel = isAssociatedToSCI(assetState.assets, asset1)
              ? `${getAssociatedSciName(assetState.assets, asset1)} - ${
                  asset1.assetName
                }`
              : asset1.assetName;
            const asset2SortLabel = isAssociatedToSCI(assetState.assets, asset2)
              ? `${getAssociatedSciName(assetState.assets, asset2)} - ${
                  asset2.assetName
                }`
              : asset2.assetName;

            return asset1SortLabel > asset2SortLabel ? 1 : -1;
          }
        )
      );
    }
  }, [assetState]);

  const showDisclaimerPatrimoine = useMemo(() => {
    if (
      assetState.hasFetched &&
      (assetState.hasFetchedAssetsImported ||
        disponibiliteParcoursRevenusComplementaires === false)
    ) {
      return (
        disponibiliteParcoursRevenusComplementaires &&
        assetState.assetsImported.filter(
          (a) =>
            a.externalId !== null &&
            !assets.some(
              (existingAsset) => existingAsset.externalId === a.externalId
            )
        ).length > 0
      );
    }

    return undefined;
  }, [assetState, assets, disponibiliteParcoursRevenusComplementaires]);

  return { assets, showDisclaimerPatrimoine };
};

export { useAssets };
