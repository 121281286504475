import { FunctionComponent } from "react";
import { SGMediaQuery } from "sg-media-query";
import { BanniereModeSupervision } from "website/components/molecules/BanniereModeSupervision/BanniereModeSupervision";
import "./Header.scss";
import { HeaderAssu } from "./templates/HeaderAssu/HeaderAssu";
import { HeaderAssuXS } from "./templates/HeaderAssu/HeaderAssuXS";
import { HeaderBddf } from "./templates/HeaderBddf/HeaderBddf";
import { HeaderDefault } from "./templates/HeaderDefault/HeaderDefault";
import { HeaderXSBddf } from "./templates/HeaderXSBDDF/HeaderXSBDDF";
import { HeaderXSDefault } from "./templates/HeaderXSDefault/HeaderXSDefault";

const Header: FunctionComponent = () => (
  <>
    <div className="header__supervision">
      <BanniereModeSupervision />
    </div>
    <SGMediaQuery minwidth="xs" maxwidth="sm">
      {window.env.REACT_APP_INSTANCE === "default" && <HeaderXSDefault />}
      {window.env.REACT_APP_INSTANCE === "bddf" && <HeaderXSBddf />}
    </SGMediaQuery>
    <SGMediaQuery minwidth="md" maxwidth="xxl">
      {window.env.REACT_APP_INSTANCE === "default" && <HeaderDefault />}
      {window.env.REACT_APP_INSTANCE === "bddf" && <HeaderBddf />}
    </SGMediaQuery>
    {window.env.REACT_APP_INSTANCE === "assu" && (
      <>
        <HeaderAssu />
        <HeaderAssuXS />
      </>
    )}
  </>
);

export { Header };
