import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche, SGAvenirStyledSuiviVirement } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { resetParcours } from "store/simulateurAvantApres/slice";
import { State } from "store/store";
import { SupervisionState, possedeDroitsActionUtilisateur } from "store/supervision/types";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SIMULATEUR_AVANT_APRES } from "website/utils/privateRoutes";

export const AccesSimulateurReforme: FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick } = useTracking();

    const handleOnClick = () => {
        trackClick("clic-sur-la-reforme-et-moi");
        dispatch(resetParcours());
        history.push(SIMULATEUR_AVANT_APRES);
    };

    const supervisionState: SupervisionState = useSelector<State,SupervisionState>((state) => state.supervision);

    return (
        <>
            {/* @ts-ignore le temps que SGTile soit corrigé */}
            <SGTile clickable={possedeDroitsActionUtilisateur(supervisionState) ? "full" : false} disableautomargin={!isPhone}>
                <SGGridRow align="middle">
                    <SGGridCol flex="0 0 0%" textalign="center">
                        <SGIcon component={<SGAvenirStyledSuiviVirement />} size="s" />
                    </SGGridCol>
                    <SGGridCol flex="1 0 0%">
                        <SGTitle level={2} visuallevel={4}>
                            La Réforme & moi
                        </SGTitle>
                        <SGBox>
                            <SGText size="s">Visualiser vos dates prévisionnelles de départ à la retraite</SGText>
                        </SGBox>
                    </SGGridCol>
                    <SGGridCol flex="0 0 0%">
                        <BoutonSupervision icon={<SGIcon component={<SGAvenirNavFleche />} />} type="icon" onClick={handleOnClick} />
                    </SGGridCol>
                </SGGridRow>
            </SGTile>
        </>
    );
}
