import React, {FunctionComponent} from "react";
import {SpendingProfile} from "../../../../../store/expenses/types";
import {SpendingProfileButton} from "../../../molecules/SpendingProfileButton/SpendingProfileButton";
import {useSpendingProfile} from "../../../hooks/useSpendingProfile";
import {SGGridCol, SGGridRow} from "sg-grid";

export interface SpendingProfileEditorProps {
   onClick?: () => void;
}

const SpendingProfileEditor: FunctionComponent<SpendingProfileEditorProps> = (props: SpendingProfileEditorProps) => {
   const {onClick} = props;
   const selectedSpendingProfile = useSpendingProfile();

   return (
      <SGGridRow justify="center" autolayout align="stretch">
         <SGGridCol xs={6} md={4}>
            <SpendingProfileButton spendingProfile={SpendingProfile.NO_SAVING} onClick={onClick}
                                   isSelected={selectedSpendingProfile === SpendingProfile.NO_SAVING}/>
         </SGGridCol>
         <SGGridCol xs={6} md={4}>
            <SpendingProfileButton spendingProfile={SpendingProfile.MEDIUM_SAVING} onClick={onClick}
                                   isSelected={selectedSpendingProfile === SpendingProfile.MEDIUM_SAVING}/>
         </SGGridCol>
         <SGGridCol xs={6} md={4}>
            <SpendingProfileButton spendingProfile={SpendingProfile.HIGH_SAVING} onClick={onClick}
                                   isSelected={selectedSpendingProfile === SpendingProfile.HIGH_SAVING}/>
         </SGGridCol>
      </SGGridRow>
   );
};

export {SpendingProfileEditor};
