import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import Highcharts, {AxisLabelsFormatterContextObject} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {HighChartsPoint} from "../../../../store/simulation/types";

interface GraphRetirementSimulationProps {
   data: { [key: number]: number };
   selectedAge: number;
   onClick: (selectedAge: number, selectedAmount: number) => void;
}

const GraphRetirementSimulation: FunctionComponent<GraphRetirementSimulationProps> =
   (props: GraphRetirementSimulationProps) => {
      const {data, selectedAge, onClick} = props;
      const intl = useIntl();

      const formattedData = Object.keys(data)
         .map((x) => parseInt(x))
         .map((key) => [key, data[key]]);

      const chartOptions = {
         plotOptions: {
            series: {
               animation: false,
            },
         },
         colors: ["#f6c056"],
         chart: {
            zoomType: "x",
            backgroundColor: "transparent",
            height: "350px",
            events: {
               load(value: { target: Highcharts.Chart }) {
                  const dataIndexSelectedOnLoad = value.target.series[0].data.findIndex(
                     (element: { x: number }) => element.x === selectedAge
                  );
                  value.target.series[0].data[dataIndexSelectedOnLoad].select();
               },
            },
         },
         credits: {enabled: false},
         title: {text: ""},
         legend: {enabled: false},
         xAxis: {
            gridLineWidth: 0,
            tickInterval: formattedData.length > 4 ? 2 : 1,
            title: {
               text: intl.formatMessage({id: "retirement.graph.help"}),
               style: {
                  color: "#333",
                  fontSize: "14px",
                  fontWeight: "bold",
               },
            },
            labels: {
               formatter(point: { value: string }): string {
                  return point.value + intl.formatMessage({id: "common.years.age"});
               },
               style: {
                  color: "#333",
                  fontSize: "12px",
               },
            },
         },
         yAxis: {
            gridLineDashStyle: "Dot",
            title: {
               text: "",
            },
            labels: {
               align: "left",
               x: 1,
               y: -1,
               formatter(formatter: AxisLabelsFormatterContextObject<number>): string {
                  const label = formatter.axis.defaultLabelFormatter.call(formatter);

                  return `${label}€`;
               },
               style: {
                  color: "#333",
                  fontSize: "12px",
               },
            },
         },
         tooltip: {
            enabled: true,
            shared: true,

            borderWidth: 0,
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            formatter(): string {
               return intl.formatMessage(
                  {id: "retirement.graph.tooltip"},
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // eslint-disable-next-line react/no-this-in-sfc
                  {age: this.x, amount: this.y}
               );
            },
            style: {
               textShadow: false,
               fontSize: "12px",
               color: "#ffffff",
            },
         },
         series: [
            {
               name: intl.formatMessage({
                  id: "retirement.graph.retirement-amount",
               }),
               data: formattedData,
               type: "column",
               pointWidth: 50,
               fillColor: "#ffbfbf",
               lineColor: "#ffbfbf",
               point: {
                  events: {
                     click(event: { point: HighChartsPoint }) {
                        onClick(event.point.x, event.point.y);
                     },
                  },
               },
               allowPointSelect: true,
            },
         ],
         exporting: {
            enabled: false,
         },
      };

      return (
         <div data-cy="graph-retirement-simulation">
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
         </div>
      );
   };

export {GraphRetirementSimulation};
