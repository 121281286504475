import {put, select, takeLeading} from "redux-saga/effects";
import {COMPUTE_COACH_ADVICES} from "./types";
import {computeCoachAdvices} from "./utils";
import {coachAdvicesComputed} from "./coachSlice";
import {PayloadAction} from "@reduxjs/toolkit";
import {State} from "../store";
import {onDefaultError, safe} from "../error/utils";

export function* watcherSaga() {
   yield takeLeading(COMPUTE_COACH_ADVICES, safe(onDefaultError, handleComputeCoachAdvices));
}

function* handleComputeCoachAdvices(action: PayloadAction<string>) {
   const store: State = yield select((state: State) => state);

   yield put(coachAdvicesComputed(computeCoachAdvices(store, action.payload)));
}
