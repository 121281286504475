import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { SGContent } from "sg-layout";
import { logout } from "store/login/actions";
import { SGButtonIntl } from "../components/atoms/SGButtonIntl/SGButtonIntl";
import { SGTextIntl } from "../components/atoms/SGTextIntl/SGTextIntl";
import { SGTitleIntl } from "../components/atoms/SGTitleIntl/SGTitleIntl";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const InformationForRetiredPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { trackClick } = useTracking();

    const handleLogout = () => {
        trackClick("ma-retraite::clic-sur-retour-aux-comptes");
        dispatch(logout());
    };

    return (
        <SGContent span={8}>
           <SGTitleIntl intlId="information-for-retired.title" cypressName="information-for-retired-title"/>
           <SGTextIntl intlId="information-for-retired.subtitle"/>
           <br/>
           <br/>
           <SGButtonIntl type="link" onClick={handleLogout} intlId="app.logout"/>
        </SGContent>
    );
};

export { InformationForRetiredPage };
