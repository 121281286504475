import { downloadData } from "../../website/utils/import/sg.import-service";
import { getCookie } from "../../website/utils/cookies/cookies";

// autorisation de faire des tests (ABtest)
export const OPTIN_COOKIE_TEST = "4";

const isMobileEnvironment = () =>
  !!getCookie("isWebView") || window.navigator.userAgent.includes("Appli");

const getEnvironment = () =>
  isMobileEnvironment()
    ? window.env.REACT_APP_BDDF_ENV_MOBILE
    : window.env.REACT_APP_BDDF_ENV;

const getBankPromiseForLoansAndBankAdvisor = (env: string) =>
  downloadData(env, ["listeRestitutionPretsNET.json"]);

const isMobile = () => window.innerWidth < 576;

const isWebView = () => !!isMobileEnvironment();

const alertWebView = (message: string) => {
  if (isWebView() && window.env.REACT_APP_ENV === "hml") {
    alert(message); // eslint-disable-line no-alert
  } else {
    // eslint-disable-next-line no-console
    console.debug(message);
  }
};

const getBankPromise = (env: string) =>
  downloadData(env, [
    "liste-prestations-authsec.json",
    "listeRestitutionPretsNET.json",
    "get-contacts.xml",
  ]);

const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const getURLParams = () => {
  const params = new URLSearchParams(window.location.search);

  // pour supprimer le paramètre t (timestamp) de l'url
  const urlParams = Array.from(params)
    .filter(([key]) => key !== "t")
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return (urlParams !== "" ? `?${urlParams}` : "");
}

/**
 * Recherche un consentement dans le cookie TC_PRIVACY_CENTER.
 * Le cookie encode les consentements en URL séparés par des virgules.
 */
const optinCookie = (consentement: string) => {
  const consentements: string[] = decodeURIComponent(
    getCookie("TC_PRIVACY_CENTER") || ""
  ).split(",");

  return consentements.find((aut: string) => aut === consentement) !== undefined;
};

export {
  isIOS,
  getEnvironment,
  isMobileEnvironment,
  alertWebView,
  getBankPromise,
  isMobile,
  isWebView,
  getBankPromiseForLoansAndBankAdvisor,
  getURLParams,
  optinCookie,
};
