import React, {FunctionComponent} from "react";
import {useDispatch} from "react-redux";
import {deleteMemberInStore, resetAllInStore} from "../../../../store/members/membersSlice";
import {Me, Member} from "../../../../store/members/types";
import {SGModal} from "sg-modal";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {SGAvenirStatusError} from "sg-icon-pack-base";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGBlock, SGText} from "sg-typo";
import {SGGridCol, SGGridRow} from "sg-grid";
import {Project} from "../../../../store/projects/types";
import {filterOutStopWorkIncomes} from "../../../../store/incomes/utils";
import {Income} from "../../../../store/incomes/types";

interface MemberDeleteModalProps {
   isVisible: boolean;
   setVisible: (isVisible: boolean) => void;
   member: Member;
}

const MemberDeleteModal: FunctionComponent<MemberDeleteModalProps> = (props: MemberDeleteModalProps) => {
   const {member, isVisible, setVisible} = props;

   const dispatch = useDispatch();

   return (
      <SGModal visible={isVisible} onCancel={() => setVisible(false)}>
         <SGMessage
            extra={<SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" cypressName="member-delete-confirm" onClick={() => {
                  setVisible(false);
                  dispatch(deleteMemberInStore(member));
               }} intlId="common.delete"/>
               <SGButtonIntl type="secondary" size="sm" cypressName="member-delete-cancel" onClick={() => {
                  setVisible(false)
                  dispatch(resetAllInStore())
               }} intlId="common.cancel"/>
            </SGButtonGroup>}
            icon={<SGIcon component={<SGAvenirStatusError/>} size="xl"/>}
            title={<SGTitleIntl intlId="member.delete.modal.title" cypressName="member-delete-confirme-title"/>}>
            <SGTextIntl intlId="member.delete.modal.text" transformations={{name: member.name}}/>
            {((member.projects && member.projects.length > 0) ||
               (member.incomes && member.incomes.length > 0)) && (
               <>
                  <SGBlock>
                     <SGTextIntl intlId="member.delete.modal.text.projects"/>
                  </SGBlock>
                  <SGGridRow justify="start">
                     <SGGridCol span={2}>
                        <SGTextIntl intlId="member.delete.modal.project.type" strong/>
                     </SGGridCol>
                     <SGGridCol span={10}>
                        <SGTextIntl intlId="member.delete.modal.project.name" strong/>
                     </SGGridCol>
                  </SGGridRow>
                  {member.projects.map((project: Project) => (
                     <SGGridRow justify="start" key={project.id}>
                        <SGGridCol span={2}>
                           <SGTextIntl intlId="member.delete.modal.project"/>
                        </SGGridCol>
                        <SGGridCol span={10}>
                           <SGText>
                              {project.projectName}
                           </SGText>
                        </SGGridCol>
                     </SGGridRow>
                  ))}
                  {filterOutStopWorkIncomes(member.incomes).map((income: Income) => (
                     <SGGridRow justify="start" key={income.id}>
                        <SGGridCol span={2}>
                           <SGTextIntl intlId="member.delete.modal.income"/>
                        </SGGridCol>
                        <SGGridCol span={10}>
                           <SGText>{income.name}</SGText>
                        </SGGridCol>
                     </SGGridRow>
                  ))}
               </>
            )}
         </SGMessage>

      </SGModal>
   );
};

export {MemberDeleteModal};
