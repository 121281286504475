import React, {FunctionComponent, useEffect, useState} from "react";
import {Redirect, RouteProps} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TunnelStep} from "../TunnelStep/TunnelStep";
import {State} from "../../../../../store/store";
import {updateAccountAction} from "../../../../../store/account/actions";
import {AccountData} from "../../../../../store/account/types";
import {HIDE_PROJECTS_INFO_MODAL} from "../../../../pages/SimulationsPage";
import {SGContent} from "sg-layout";

export interface TunnelProps extends RouteProps {
   steps: FunctionComponent[];
}

const Tunnel: FunctionComponent<TunnelProps> = (props: TunnelProps) => {
   const {steps} = props;
   const dispatch = useDispatch();
   const account: AccountData = useSelector<State, AccountData>((state) => state.account.account);

   const [redirectToCoach, setRedirectToCoach] = useState<boolean>(false);
   const [disclaimerReadUpdated, setDisclaimerReadUpdated] = useState<boolean>(false);
   const [tunnelCompletedUpdated, setTunnelCompletedUpdated] = useState<boolean>(false);
   const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);

   useEffect(() => {
      if (currentStep >= steps.length) {
         if (!tunnelCompletedUpdated) {
            setTunnelCompletedUpdated(true);
            dispatch(updateAccountAction({...account, tunnelCompleted: true}));
            localStorage.removeItem(HIDE_PROJECTS_INFO_MODAL);
         }
      }
      if (currentStep > 0 && !account.disclaimerRead && !disclaimerReadUpdated) {
         setDisclaimerReadUpdated(true);
         dispatch(updateAccountAction({...account, disclaimerRead: true}));
      }
   }, [dispatch, currentStep, steps, account, disclaimerReadUpdated, tunnelCompletedUpdated]);

   useEffect(() => {
      if (account.tunnelCompleted && tunnelCompletedUpdated) {
         setRedirectToCoach(true);
      }
   }, [account.tunnelCompleted, tunnelCompletedUpdated]);

   if (redirectToCoach) {
      // @ts-ignore
      return <Redirect to="/advices"/>;
   }

   return (
      <div>
         {steps.map((step: FunctionComponent, index: number) => (
            <SGContent key={step.name}>
               <TunnelStep stepIndex={index} component={step}/>
            </SGContent>
         ))}
      </div>
   );
};

export {Tunnel};
