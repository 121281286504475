import { ReactComponent as CheckProgression } from "assets/images/check-progression.svg";
import { ReactComponent as CheckVideProgression } from "assets/images/check-vide-progression.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGText } from "sg-typo";
import { useDashboardProgression } from "website/components/hooks/dashboard/useDashboardProgression";
import "./ProgressionPopHover.scss";

const ProgressionPopHoverMob: FunctionComponent = () => {
    const intl = useIntl();
    const { parcoursDoneTab } = useDashboardProgression();

    return (
        <SGGridRow
            span={12}
            style={{
                backgroundColor: "#fff",
                zIndex: 1000,
            }}
        >
            {parcoursDoneTab?.map((parcours, val) => (
                <SGGridCol span={12} align="start">
                    <SGGridRow>
                        <SGGridCol span={1} className="progression-pop-hover__align-check">
                            {parcours.date !== undefined ? <CheckProgression /> : <CheckVideProgression />}
                        </SGGridCol>
                        <SGGridCol>
                            <SGText weight="400" style={{ color: parcours.date !== undefined && "#0072B9" }}>
                                {intl.formatMessage({ id: `progression.popHover.check.${parcours.nom}` })}
                            </SGText>
                        </SGGridCol>
                    </SGGridRow>
                </SGGridCol>
            )
            )}
        </SGGridRow>
    );
};
export { ProgressionPopHoverMob };
