import React, {FunctionComponent} from "react";
import {SGContent} from "sg-layout";
import {TaxSimulator} from "website/components/organisms/Simulators/TaxSimulator/TaxSimulator";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {redirectToRelativePath} from "../utils/routes/routing";
import {SIMULATIONS} from "../utils/privateRoutes";

const TaxSimulatorPage: FunctionComponent = () => (
   <SGContent>
      <TaxSimulator/>
      <SGButtonGroup align="center" layout="column">
         <SGButtonIntl type="link" cypressName="additional-income-previous" onClick={() => redirectToRelativePath(SIMULATIONS)}
                       intlId="common.previous"/>
      </SGButtonGroup>
   </SGContent>
);

export {TaxSimulatorPage};
