import { AssetIncomes } from "store/assetIncomes/types";
import { DashboardAge } from "store/dashboard/types";
import { convertAgeTypeToWealthProfile } from "store/savingPath/types";
import { MonthlyYearly } from "../../website/components/atoms/NumericInput/NumericInput";
import { utcDate } from "../../website/utils/date/DateUtils";
import {
   AVAILABLE_INCOME_CATEGORY,
   CHILD_SUPPORT_EXPENSE_GRAPH_CATEGORY,
   EXPENSES_GRAPH_CATEGORY,
   EXPENSE_ASSET_GRAPH_CATEGORY,
   EXPENSE_PLANNED_SAVINGS_GRAPH_CATEGORY,
   ExpenseGraphCategory,
   LOAN_GRAPH_CATEGORY,
   RENT_GRAPH_CATEGORY,
   TAX_EXPENSE_GRAPH_CATEGORY,
} from "../expenses/types";
import {
   CHILD_SUPPORT_INCOME_GRAPH_CATEGORY,
   IncomeGraphCategory,
   IncomeType,
   OTHER_INCOME_GRAPH_CATEGORY,
   PLANNED_SAVINGS_BUYBACK_GRAPH_CATEGORY,
   REAL_ESTATE_INCOME_GRAPH_CATEGORY,
   RETIREMENT_INCOME_GRAPH_CATEGORY,
   WORK_INCOME_GRAPH_CATEGORY,
} from "../incomes/types";
import { Loan } from "../loans/types";
import { Family, FamilyStatus, Member } from "../members/types";
import { toMembers } from "../members/utils";
import { ProjectType } from "../projects/types";
import { isSCIAssetType } from "./typeguards";
import {
   Asset,
   AssetCategory,
   AssetMemberLink,
   AssetType,
   AssetWithValuation,
   BANK_ACCOUNT,
   INVESTMENT_ACCOUNT,
   OTHER,
   PossessionType,
   REAL_ESTATE,
   RealEstateAsset,
   RealEstateAssetTaxSystemSubType,
   RealEstateAssetTaxSystemType,
   SAVINGS_ACCOUNT,
   TERM_ACCOUNT,
} from "./types";

export const parseFormDataForAssetMemberLink = (formData: AssetEditFormData, family: Family) => {
   const result: AssetMemberLink[] = [];
   const members = toMembers(family);
   formData.assetMemberLink.forEach((assetMemberLink: AssetMemberLinkFormData) => {
      const newAssetMemberLink: AssetMemberLink = {
         percentage: assetMemberLink.percentage,
         possessionType: assetMemberLink.possessionType,
         familyMember: members.filter((member) => member.id === parseInt(assetMemberLink.familyMemberId)).shift(),
      };
      result.push(newAssetMemberLink);
   });

   return result;
};

export const parseFormDataForLoanAsset = (formData: AssetEditFormData, loans: Loan[]) => {
   const result: number[] = [];
   formData.loans.forEach((loanId: { id: string }) => {
      const associatedLoan = loans.filter((loan) => loan.id === parseInt(loanId.id))[0];
      if (associatedLoan) {
         result.push(associatedLoan.id);
      }
   });

   return result;
};

export const parseFormDataForSCI = (formData: AssetEditFormData, realEstateAssets: RealEstateAsset[]) =>
   realEstateAssets.filter((asset: RealEstateAsset) =>
      formData.associatedRealEstateAssets?.map((associatedAsset: RealEstateAsset) => associatedAsset.id && Number(associatedAsset.id))?.includes(asset.id)
   );

export const parseFormDataForRealEstateRentAsset = (currentAsset: Partial<RealEstateAsset>, formData: AssetEditFormData) => ({
   ...currentAsset,
   taxSystemSubType: formData.rentalInvestmentAssetTaxArrangement,
   taxSystemType: formData.rentalInvestmentAssetTaxSystem,
   yearlyRentAmount: formData.periodicRentAmountPeriodicity === MonthlyYearly.MONTHLY ? formData.periodicRentAmount * 12 : formData.periodicRentAmount,
   yearlyCostAmount: formData.periodicCostAmountPeriodicity === MonthlyYearly.MONTHLY ? formData.periodicCostAmount * 12 : formData.periodicCostAmount,
});

export const defaultAssetMemberLinkSet = (me: Member): Array<AssetMemberLink> => [
   {
      familyMember: me,
      percentage: 100,
      possessionType: PossessionType.PLEINE_PROPRIETE,
   },
];

export const isRealEstateAsset = (asset: Partial<AssetWithValuation>) =>
   asset.assetType === AssetType.REAL_ESTATE_RENT || asset.assetType === AssetType.REAL_ESTATE_PRIMARY || asset.assetType === AssetType.REAL_ESTATE_SECONDARY;

export interface AssetEditFormData {
   assetName: string;
   totalValuation: number;
   assetType: AssetType;
   acquisitionDate: Date;
   assetMemberLink: AssetMemberLinkFormData[];
   loans: { id: string }[];
   associatedRealEstateAssets: RealEstateAsset[];
   isTaxTransparent: boolean;
   rentalInvestmentAssetTaxSystem: RealEstateAssetTaxSystemType;
   rentalInvestmentAssetTaxArrangement: RealEstateAssetTaxSystemSubType;
   periodicRentAmountPeriodicity: MonthlyYearly;
   periodicRentAmount: number;
   periodicCostAmountPeriodicity?: MonthlyYearly;
   periodicCostAmount: number;
}

interface AssetMemberLinkFormData {
   familyMemberId: string;
   possessionType: PossessionType;
   percentage: number;
}

export const parseFormDataForAsset = (formData: AssetEditFormData, family: Family, loans: Loan[], realEstateAssets?: RealEstateAsset[]) => {
   let currentAsset: Partial<RealEstateAsset> = {
      assetName: formData.assetName,
      assetValuation: { totalValuation: formData.totalValuation },
      assetType: formData.assetType,
      acquisitionDate: formData.acquisitionDate ? formData.acquisitionDate : utcDate(new Date()),
      assetMemberLinkSet: [],
      loans: [],
   };
   if (formData.assetMemberLink) {
      currentAsset.assetMemberLinkSet = parseFormDataForAssetMemberLink(formData, family);
   } else {
      currentAsset.assetMemberLinkSet = defaultAssetMemberLinkSet(family.me);
   }

   if (formData.loans) {
      currentAsset.loans = parseFormDataForLoanAsset(formData, loans);
   }

   if (isSCIAssetType(currentAsset)) {
      if (formData.associatedRealEstateAssets && realEstateAssets) {
         currentAsset.associatedRealEstateAssets = parseFormDataForSCI(formData, realEstateAssets);
      }
      currentAsset.isTaxTransparent = formData.isTaxTransparent;
   }

   if (formData.assetType === AssetType.REAL_ESTATE_RENT) {
      currentAsset = parseFormDataForRealEstateRentAsset(currentAsset, formData);
   }

   return currentAsset;
};

export const getAssetCategory = (assetType: AssetType): AssetCategory => {
   switch (assetType) {
      case AssetType.CASH_ACCOUNT:
         return BANK_ACCOUNT;
      case AssetType.CSL:
      case AssetType.LIVRET_A:
      case AssetType.LDDS:
         return SAVINGS_ACCOUNT;
      case AssetType.PEL:
      case AssetType.COMPTE_A_TERME:
         return TERM_ACCOUNT;
      case AssetType.LIFE_INSURANCE:
      case AssetType.PEA:
      case AssetType.PEE:
      case AssetType.PER:
      case AssetType.CTO:
         return INVESTMENT_ACCOUNT;
      case AssetType.REAL_ESTATE_PRIMARY:
      case AssetType.REAL_ESTATE_RENT:
      case AssetType.REAL_ESTATE_SECONDARY:
      case AssetType.SCI:
         return REAL_ESTATE;
      default:
         return OTHER;
   }
};

export const getIncomeGraphCategory = (incomeType: IncomeType): IncomeGraphCategory => {
   switch (incomeType) {
      case IncomeType.WORK_INCOME:
         return WORK_INCOME_GRAPH_CATEGORY;
      case IncomeType.RETIREMENT_INCOME:
         return RETIREMENT_INCOME_GRAPH_CATEGORY;
      case IncomeType.PLANNED_SAVINGS_BUYBACK:
         return PLANNED_SAVINGS_BUYBACK_GRAPH_CATEGORY;
      case IncomeType.CHILD_SUPPORT_INCOME:
         return CHILD_SUPPORT_INCOME_GRAPH_CATEGORY;
      case IncomeType.REAL_ESTATE_INCOME:
      case IncomeType.REAL_COSTS_REAL_ESTATE_INCOME:
         return REAL_ESTATE_INCOME_GRAPH_CATEGORY;
      case IncomeType.OTHER_INCOME:
      default:
         return OTHER_INCOME_GRAPH_CATEGORY;
   }
};

export const getExpenseGraphCategory = (expenseType?: ProjectType): ExpenseGraphCategory => {
   switch (expenseType) {
      case ProjectType.EXPENSES:
         return EXPENSES_GRAPH_CATEGORY;
      case ProjectType.TAX_EXPENSE:
         return TAX_EXPENSE_GRAPH_CATEGORY;
      case ProjectType.LOAN:
         return LOAN_GRAPH_CATEGORY;
      case ProjectType.RENT:
         return RENT_GRAPH_CATEGORY;
      case ProjectType.CHILD_SUPPORT_EXPENSE:
         return CHILD_SUPPORT_EXPENSE_GRAPH_CATEGORY;
      case ProjectType.ASSET_EXPENSE:
         return EXPENSE_ASSET_GRAPH_CATEGORY;
      case undefined:
         return AVAILABLE_INCOME_CATEGORY;
      case ProjectType.EXPENSE_PLANNED_SAVINGS:
      default:
         return EXPENSE_PLANNED_SAVINGS_GRAPH_CATEGORY;
   }
};

export const isCoOwned = (asset: Asset): boolean =>
   !!(
      asset.assetMemberLinkSet.some((value) => value.familyMember && value.familyMember.status === FamilyStatus.ME) &&
      asset.assetMemberLinkSet.find(
         (value) => value.familyMember && value.familyMember.status === FamilyStatus.ME && value.percentage && value.percentage < 100
      )
   );

export const isAssociatedToSCI = (assets: AssetWithValuation[], asset: AssetWithValuation | Loan): boolean => {
   if (!isSCIAssetType(asset)) {
      let isAssociatedToSCI = false;
      assets.forEach((sciAsset) => {
         if (isSCIAssetType(sciAsset)) {
            if (
               sciAsset.loans?.some((loanId) => loanId === asset.id) ||
               sciAsset.associatedRealEstateAssets?.some((currentAsset) => currentAsset.id === asset.id)
            ) {
               isAssociatedToSCI = true;
            }
         }
      });

      return isAssociatedToSCI;
   }

   return false;
};

export const getAssociatedSciName = (assets: AssetWithValuation[], asset: AssetWithValuation | Loan): string | undefined => {
   if (!isSCIAssetType(asset)) {
      let sciAssetName;
      assets.forEach((sciAsset) => {
         if (isSCIAssetType(sciAsset)) {
            if (
               sciAsset.loans?.some(
                  (loanId) => loanId === asset.id || sciAsset.associatedRealEstateAssets?.some((currentAsset) => currentAsset.id === asset.id)
               )
            ) {
               sciAssetName = sciAsset.assetName;
            }
         }
      });

      return sciAssetName;
   }

   return undefined;
};

export const computeValuationPerAssetType = (asset: AssetWithValuation, loans: Loan[]): number => {
   if (isSCIAssetType(asset)) {
      let amount = asset.assetValuation.totalValuation || 0;
      amount += asset.associatedRealEstateAssets
         ?.map((realEstate: RealEstateAsset) => realEstate.assetValuation?.totalValuation || 0)
         .reduce((a, b) => a + b, 0);

      amount -= asset.loans
         ?.map((loanId: number) => Math.trunc(loans.filter((loan) => loan.id === loanId)[0]?.remainingPrincipal || 0))
         .reduce((a, b) => a + b, 0);

      return amount;
   }

   return asset.assetValuation.totalValuation ? asset.assetValuation.totalValuation : 0;
};

export const getCleanName = (assetName: string): string =>
   assetName
      .normalize("NFD")
      .toLowerCase()
      .replace(/[^a-z ]+/g, "")
      .replace(/[ ]+/g, "-");

/**
 * Détermine les revenus du patrimoine pour l'âge considéré
 *
 * @param assets Liste des assets
 * @param assetsIncomes Liste des revenus des assets par ages
 * @param age Age à considérer
 * @return La somme des revenus du patrimoine ou 0
 */
export const computeAdditionalIncomes = (assets: AssetWithValuation[], assetsIncomes: AssetIncomes, age: DashboardAge): number => {
   const assetsIncome = assetsIncomes && assetsIncomes[convertAgeTypeToWealthProfile(age.type)];
   let retirementAdditionalIncome = 0;
   if (assetsIncome && assets) {
      // On ne considère que les assets
      assets.forEach((asset) => {
         if (asset?.id && asset.percentageUsedForRetirement > 0) {
            retirementAdditionalIncome += asset.percentageUsedForRetirement
               ? (assetsIncome[asset.id] * asset.percentageUsedForRetirement) / 100
               : assetsIncome[asset.id];
         }
      });
   }

   return retirementAdditionalIncome;
};

/**
 * Détermine le total du patrimoine alloué à la retraite
 * @param assets Liste des assets
 * @returns le total du patrimoine alloué à la retraite
 */
export const getMontantTotalAlloue = (assets: AssetWithValuation[]): number =>
   assets.filter((asset) => asset?.id && asset.percentageUsedForRetirement > 0).reduce((accumulateur, valeurCourante) => accumulateur + (valeurCourante.assetValuation.totalValuation ?? 0) * (valeurCourante.percentageUsedForRetirement / 100), 0);
