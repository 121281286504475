import React, {FunctionComponent, ReactElement} from "react";
import {useDispatch} from "react-redux";
import {useTotalIncomes} from "../../hooks/useTotalIncomes";
import {useTotalExpenses} from "../../hooks/useTotalExpenses";
import {SpendingProfile} from "../../../../store/expenses/types";
import {updateExpenseInStoreAction, updateSpendingProfileAction} from "../../../../store/expenses/actions";
import {ProjectType} from "../../../../store/projects/types";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {getTotalIncomeSpendingPercentage} from "../../../../store/expenses/utils";
import {SGCard} from "sg-card";
import {SGIcon} from "sg-icon";
import {SGAvenirStyledPortefeuille, SGAvenirStyledPortefeuilleNonExistant, SGAvenirStyledPortefeuillePlus} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";
import {useIntl} from "react-intl";
import {replaceSpecialCharsAndLowercase} from "../../../utils/formatting/replaceSpecialCharsAndLowercase";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export interface SpendingProfileButtonProps {
   spendingProfile: SpendingProfile;
   onClick?: () => void;
   isSelected?: boolean;
}

const SpendingProfileButton: FunctionComponent<SpendingProfileButtonProps> = (props: SpendingProfileButtonProps) => {
   const {spendingProfile, isSelected, onClick} = props;
   const dispatch = useDispatch();
   const totalIncomes = useTotalIncomes();
   const allExpensesButOthers = useTotalExpenses([ProjectType.EXPENSES]);
   const intl = useIntl();
   const { trackClick } = useTracking();

   const handleClick = () => {
      dispatch(
         updateExpenseInStoreAction({
            type: ProjectType.EXPENSES,
            monthlyAmount: Math.max(
               0,
               Math.round(totalIncomes * (1 - getTotalIncomeSpendingPercentage(spendingProfile)) - (allExpensesButOthers || 0))
            ),
            id: -1,
            name: "Dépenses",
            member: undefined
         })
      );
      dispatch(updateSpendingProfileAction(spendingProfile));
      trackClick(`capacite-epargne::clic-sur-${replaceSpecialCharsAndLowercase(intl.formatMessage({id: `tunnel.expenses-profile.button.${spendingProfile}.title`}))}`);
      if (onClick) {
         onClick();
      }
   };

   const getSpendingProfileIcon = (): ReactElement => {
      switch (spendingProfile) {
         case SpendingProfile.NO_SAVING:
            return <SGAvenirStyledPortefeuilleNonExistant/>
         case SpendingProfile.HIGH_SAVING:
            return <SGAvenirStyledPortefeuillePlus/>
         case SpendingProfile.MEDIUM_SAVING:
         default:
            return <SGAvenirStyledPortefeuille/>
      }
   };

   return (
      <SGCard bordered dark={isSelected} clickable onClick={handleClick} data-cy={`expense-profile-${spendingProfile}`} textalign="center">
         <SGSpace direction="vertical" disableautomargin>
            <SGIcon size="xxl" component={getSpendingProfileIcon()}/>
            <SGTextIntl intlId={`tunnel.expenses-profile.button.${spendingProfile}.title`} strong/>
            <SGTextIntl intlId={`tunnel.expenses-profile.button.${spendingProfile}.desc`}
                        transformations={{amount: Math.round(totalIncomes * getTotalIncomeSpendingPercentage(spendingProfile))}}
            />
         </SGSpace>
      </SGCard>
   );
};

export {SpendingProfileButton};
