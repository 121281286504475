import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheVideo } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { isIOS } from "store/import/utils";
import { TypeMedia } from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { TagTemps } from "../TagTemps/TagTemps";
import "./VideoPlayer.scss";

const { Player, ControlBar } = require("video-react");

export interface PlayerRef {
  play: () => void;
  pause: () => void;
  actions: any;
}

interface VideoPlayerProps {
  getPlayer?: (player: PlayerRef) => void;
  onClickPlayer?: () => void;
  cover: string;
  time?: number;
  urlVideo: string;
  type: TypeMedia;
}

// Composant card du parcours epargner
export const VideoPlayer: FunctionComponent<VideoPlayerProps> = (
  props: React.PropsWithChildren<VideoPlayerProps>
) => {
  const { getPlayer, onClickPlayer, cover, time, urlVideo, type } = props;

  const [player, setPlayer] = useState<PlayerRef>();
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showTag, setShowTag] = useState(true);

  const { trackingPageName, trackClick } = useTracking();

  return (
    <SGBox>
      <SGBox
        disableautomargin
        className="video-player"
        alignchildren={["center", "center"]}
      >
        <>
          <Player
            ref={(p: PlayerRef) => {
              setPlayer(p);
              if (getPlayer) {
                getPlayer(p);
              }
            }}
            playsInline
            poster={cover}
            src={urlVideo}
            // Appelé avec player.play()
            onPlay={() => {
              setShowPlayButton(false);
              setShowTag(false);
              trackClick(`${trackingPageName}::clic-sur-voir-la-video`);
              if (onClickPlayer) {
                onClickPlayer();
              }
            }}
          >
            <ControlBar disableCompletely={isIOS()} />
          </Player>
          {time && showTag && <TagTemps temps={time} />}
          {showPlayButton && (
            <SGButton
              type="primarylight"
              size="md"
              icon={
                <SGIcon component={<SGAvenirNavFlecheVideo />} currentcolor />
              }
              onClick={() => player?.play()}
              disableautomargin
            >
              <FormattedMessage
                id={`parcoursEpargner.commentPasserAction.videoSection.button.voir.${type}`}
              />
            </SGButton>
          )}
        </>
      </SGBox>
    </SGBox>
  );
};
