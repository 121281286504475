import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard, SGCardMeta } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { Video } from "website/components/hooks/useVideos";
import { VideoPlayer } from "website/components/molecules/VideoPlayer/VideoPlayer";
import { PauseVideoContext } from "website/components/providers/PauseVideoProvider";
import "./CarrouselVideoCarteMobile.scss";

export interface PlayerRef {
    play: () => void;
    pause: () => void;
    actions: any;
}
interface CarrouselVideoCarteMobileProps {
    video: Video;
    getPlayer: (player: PlayerRef) => void;
}

// Composant card du parcours epargner
export const CarrouselVideoCarteMobile: FunctionComponent<CarrouselVideoCarteMobileProps> = (
    props: React.PropsWithChildren<CarrouselVideoCarteMobileProps>
) => {
    const { video, getPlayer } = props;
    const intl = useIntl();

    const [player, setPlayer] = useState<PlayerRef>();
    const { setPauseVideo } = useContext(PauseVideoContext);

    useEffect(() => {
        if (player) {
            getPlayer(player);
        }
    }, [player]);

    return (
        <SGCard
            disableautomargin
            shadow
            coversize="md"
            className="carrousel-video-carte-mobile__card"
            actions={[
                video.onClick ? (
                    <SGButton
                        // A revoir si liens autres qu'interne
                        onClick={video.onClick}
                        // FIXME Utiliser plutôt tertiary, mais ne supporte pas le disabled pour la couleur
                        type="primarylink"
                        icon={<SGIcon component={<SGAvenirNavFlecheBtnLien />} currentcolor />}
                        iconposition="right"
                        disabled={video.disableOnClick}
                    >
                        {intl.formatMessage({ id: `video.${video.id}.link.${video.linkLabel}` })}
                    </SGButton>
                ) : null,
            ]}
            coverposition="centerTop"
            cover={
                <>
                    <SGBox className="carrousel-video-carte-mobile__video-container">
                        <VideoPlayer
                            type={video.type}
                            cover={video.cover}
                            time={video.time}
                            urlVideo={video.video}
                            getPlayer={setPlayer}
                            onClickPlayer={() => setPauseVideo(true)}
                        />
                    </SGBox>
                </>
            }
        >
            <SGCardMeta
                title={
                    <SGTitle level={3} visuallevel={4}>
                        {intl.formatMessage({ id: `video.${video.id}.title` })}
                    </SGTitle>
                }
                description={
                    <SGText>
                        {intl.formatMessage({ id: `video.${video.id}.text` },{bloc: (word: string) => <SGBlock>{word}</SGBlock>,})}
                  </SGText>
                }
            />
        </SGCard>
    );
};
