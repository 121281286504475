import React, { FunctionComponent } from "react";
import "./FooterBddf.scss";
import { useSelector } from "react-redux";
import { State } from "../../../../../../../store/store";
import { AccountState } from "../../../../../../../store/account/types";
import { SGContent } from "sg-layout";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import {
    SGAvenirStrokedAide,
    SGAvenirStrokedLocalisation,
    SGAvenirStrokedReseauxSociauxFacebook,
    SGAvenirStrokedReseauxSociauxInstagram,
    SGAvenirStrokedReseauxSociauxTwitter,
} from "sg-icon-pack-base";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGLink } from "sg-link";
import { SGSpace } from "sg-space";
import { SGLogo } from "sg-logo";

const FooterBddf: FunctionComponent = () => {
    const accountState = useSelector<State, AccountState>((state) => state.account);

    const intl = useIntl();

    const sites = [
        { id: "banque", link: "https://www.privatebanking.societegenerale.com/fr/" },
        { id: "professionnels", link: "https://professionnels.sg.fr/" },
        { id: "entreprises", link: "https://entreprises.sg.fr/" },
        { id: "associations", link: "https://associations.sg.fr/" },
        { id: "economie", link: "https://economiepublique.sg.fr/" },
        { id: "groupe", link: "https://www.societegenerale.com/fr" },
    ];

    const liensLigne1 = [
        { id: "securite", link: "https://particuliers.sg.fr/securite" },
        { id: "engagements", link: "https://particuliers.sg.fr/engagements" },
        { id: "cookies", link: "https://particuliers.sg.fr/charte-cookies-societe-generale" },
        { id: "donnees", link: "https://particuliers.sg.fr/engagements/gestion-donnees-personnelles-rgpd" },
        { id: "tarifs", link: "https://particuliers.sg.fr/documentation-tarifs" },
    ];

    const liensLigne2 = [
        { id: "informations", link: "https://particuliers.sg.fr/informations-mentions-legales" },
        { id: "accessibilite", link: "https://particuliers.sg.fr/accessibilitenumerique" },
    ];

    return (
        <>
            {accountState.account.id !== -1 && (
                <>
                    <div className="footerBddfBlack">
                        <SGContent disableautomargin>
                            <SGGridRow align="middle">
                                <SGGridCol textalign="left" span={6} align="center">
                                    <SGButton
                                        type="tertiarylight"
                                        icon={<SGIcon size="l" currentcolor component={<SGAvenirStrokedAide />} />}
                                        onClick={() => {
                                            window.location.href = "https://particuliers.sg.fr/icd/pch/faq";
                                        }}
                                    >
                                        {intl.formatMessage({ id: "header.title.help.bddf" })}
                                    </SGButton>

                                    <SGButton
                                        type="tertiarylight"
                                        icon={<SGIcon size="l" currentcolor component={<SGAvenirStrokedLocalisation />} />}
                                        onClick={() => {
                                            window.location.href = "https://agences.sg.fr/banque-assurance/";
                                        }}
                                    >
                                        {intl.formatMessage({ id: "header.title.agences" })}
                                    </SGButton>
                                </SGGridCol>
                                <SGGridCol span={3} align="center" className="footerBddfBlack__select-container">
                                    <SGSelect
                                        placeholder={intl.formatMessage({ id: "footer.site.id.autres" })}
                                        className="footerBddfBlack__select"
                                        value={intl.formatMessage({ id: "footer.site.id.autres" })}
                                    >
                                        {sites.map((site) => (
                                            <SGSelectOption value={site.id} key={site.id}>
                                                <SGLink underline={false} href={site.link}>
                                                    {intl.formatMessage({ id: `footer.site.id.${site.id}` })}
                                                </SGLink>
                                            </SGSelectOption>
                                        ))}
                                    </SGSelect>
                                </SGGridCol>
                                <SGGridCol textalign="right" span={3}>
                                    <SGButton
                                        type="tertiarylight"
                                        icon={<SGIcon size="l" currentcolor component={<SGAvenirStrokedReseauxSociauxFacebook />} />}
                                        onClick={() => {
                                            window.location.href = "https://www.facebook.com/societegenerale.france/";
                                        }}
                                    />
                                    <SGButton
                                        type="tertiarylight"
                                        icon={<SGIcon size="l" currentcolor component={<SGAvenirStrokedReseauxSociauxTwitter />} />}
                                        onClick={() => {
                                            window.location.href = "https://twitter.com/sg_etvous";
                                        }}
                                    />
                                    <SGButton
                                        type="tertiarylight"
                                        icon={<SGIcon size="l" currentcolor component={<SGAvenirStrokedReseauxSociauxInstagram />} />}
                                        onClick={() => {
                                            window.location.href = "https://www.instagram.com/societegenerale/?hl=fr";
                                        }}
                                    />
                                </SGGridCol>
                            </SGGridRow>
                        </SGContent>
                    </div>
                    <div className="footerBddfWhite">
                        <SGContent>
                            <SGGridRow align="middle" justify="center">
                                <SGGridCol textalign="center">
                                    <SGLogo width={90} />
                                </SGGridCol>
                            </SGGridRow>
                            <SGSpace direction="horizontal" size="sm" textalign="center" align="center">
                                {liensLigne1.map((lien) => (
                                    <>
                                        <SGLink underline={false} href={lien.link} key={lien.id} disableautomargin>
                                            {intl.formatMessage({ id: `footer.title.${lien.id}` })}
                                        </SGLink>
                                    </>
                                ))}
                            </SGSpace>
                            <SGSpace direction="horizontal" size="sm" textalign="center" align="center">
                                {liensLigne2.map((lien) => (
                                    <>
                                        <SGLink underline={false} href={lien.link} key={lien.id} disableautomargin>
                                            {intl.formatMessage({ id: `footer.title.${lien.id}` })}
                                        </SGLink>
                                    </>
                                ))}
                            </SGSpace>
                        </SGContent>
                    </div>
                </>
            )}
        </>
    );
};

export { FooterBddf };
