import React, {FunctionComponent, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SavingCapacityState,} from "../../../../../store/savingCapacity/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGProgress} from "sg-progress";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";
import {useSavingCapacity} from "../../../hooks/useSavingCapacity";
import {CustomNumberFormat} from "../../../../utils/formatting/CustomNumberFormat";
import {SGSpace} from "sg-space";

const SavingCapacity: FunctionComponent = () => {
   const history = useHistory();
   const savingCapacity = useSavingCapacity()
   const hasFetchedSavingCapacity = useSelector<State, boolean>((state) => state.savingCapacity.hasFetched);
   const [availableMonthlySavingCapacityProgressBarPercentage, setAvailableMonthlySavingCapacityProgressBarPercentage] = useState<number | null>(null);
   // const [availableBorrowingCapacityProgessbarPercentage, setavailableBorrowingCapacityProgessbarPercentage] = useState<number>();

   const savingCapacityState: SavingCapacityState = useSelector<State, SavingCapacityState>((state) => state.savingCapacity);

   useEffect(() => {
      if (hasFetchedSavingCapacity) {
         setAvailableMonthlySavingCapacityProgressBarPercentage(Math.round((100 * savingCapacity.availableMonthlySavingCapacity) / savingCapacity.monthlySavingCapacity) || 0);
         // setavailableBorrowingCapacityProgessbarPercentage(100 * savingCapacity.availableBorrowingCapacity / savingCapacity.borrowingCapacity);
      }
   }, [hasFetchedSavingCapacity, savingCapacity]);

   const createProject = () => {
      history.push("/new-project");
   };

   const modifyProject = () => {
      history.push("/projects");
   };

   return (
      <>
         {savingCapacityState.cannotFetchSavingCapacity && (
            <SGAlertIntl type="warning" cypressName="advices-cannot-fetch-saving-capacity" title="common.warning">
               <SGTextIntl intlId="advices.coach.saving-capacity.error"/>
            </SGAlertIntl>
         )}
         {!savingCapacityState.cannotFetchSavingCapacity && hasFetchedSavingCapacity && (
            <SpinnerWrapper spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false} displayComponent={hasFetchedSavingCapacity}>
               <SGSpace direction="vertical" textalign="left">
                  <SGTitleIntl intlId="advices.coach.saving-capacity" cypressName="saving-capacity-title"/>
                  {savingCapacity.availableMonthlySavingCapacity >= 50 && (
                     <SGTextIntl intlId="advices.coach.saving-capacity.advice" transformations={{
                        amount: <CustomNumberFormat displayType="text" value={savingCapacity.availableMonthlySavingCapacity}/>
                     }}/>
                  )}
                  <SGSpace align="center" direction="vertical">
                     {availableMonthlySavingCapacityProgressBarPercentage !== null && (
                        <SGProgress percent={availableMonthlySavingCapacityProgressBarPercentage} type="line" showInfo context="monetique" status="normal"
                                    extra={<SGTextIntl intlId="advices.coach.saving-capacity.amount"
                                                       transformations={{
                                                          amount: <CustomNumberFormat displayType="text"
                                                                                      value={savingCapacity?.availableMonthlySavingCapacity}/>
                                                       }}/>}/>
                     )}
                     {savingCapacity.availableMonthlySavingCapacity >= 50 && (<>
                        {savingCapacity.numberOfProjects < 3 &&
                           <SGButtonIntl type="secondary" size="md" cypressName="saving-capacity-create-project" onClick={createProject}
                                         intlId="advices.coach.saving-capacity.button"/>
                        }
                        {savingCapacity.numberOfProjects === 3 &&
                           <SGButtonIntl type="secondary" size="md" cypressName="saving-capacity-modify-project" onClick={modifyProject}
                                         intlId="advices.coach.saving-capacity.modify"/>
                        }
                     </>)}
                  </SGSpace>
               </SGSpace>
            </SpinnerWrapper>
         )}
      </>
   );
};

export {SavingCapacity};
