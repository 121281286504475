import React, {FunctionComponent} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGTag} from "sg-tag";
import {SGBlock, SGText} from "sg-typo";
import {SGCard} from "sg-card";
import {ElxColor, SG_BLACK} from "../../../utils/graphs/colors";
import {SGAvenirStrokedAutresDepenses, SGAvenirStrokedBillet, SGAvenirStrokedHabitation} from "sg-icon-pack-base";
import {SGIcon} from "sg-icon";
import {SGGridCol, SGGridRow} from "sg-grid";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";
import {convertNumberToStringWithSeparators} from "../../../utils/formatting/numberFormatter";
import {useIntl} from "react-intl";

interface NameAndValuationFrameProps {
   title: string;
   subTitle?: string;
   amount: number;
   amountSubTitle?: string;
   isPerMonth: boolean;
   isPerYear?: boolean;
   isNet: boolean;
   isCoOwned?: boolean;
   isBeingBoughtBack?: boolean;
   icon?: "home" | "cash" | "expense";
   onClick?: () => void;
   isAssociatedToSCI?: boolean;
   frameColor: ElxColor;
   cypressName: string;
   loanMonthlyAmount?: number;
   loanEndDate?: string;
}

const NameAndValuationFrame: FunctionComponent<NameAndValuationFrameProps> = (props: NameAndValuationFrameProps) => {
   const {
      title, subTitle, amount, amountSubTitle, isPerMonth, isPerYear, isNet, isCoOwned, isBeingBoughtBack,
      icon, onClick, isAssociatedToSCI, frameColor, cypressName, loanMonthlyAmount, loanEndDate
   } = props;

   const handleClick = () => {
      if (onClick) {
         onClick();
      }
   };

   const intl = useIntl();

   const getSubTitle = (): string | JSX.Element => {
      if (subTitle !== undefined) {
         return subTitle
      }
      if (loanMonthlyAmount && loanEndDate) {
         return (<SGText data-cy={`${cypressName}-subTitle`}>
            <CustomNumberFormat displayType="text" suffix=" €" value={convertNumberToStringWithSeparators(loanMonthlyAmount)}/>
            {intl.formatMessage({id: "form.loan.by-month-until"})}{loanEndDate}
         </SGText>)
      }

      return ""
   }

   return (
      <>
         {amount !== 0 && (
            <SGCard className={isAssociatedToSCI ? "asset__sub-element" : ""} data-cy={`${cypressName}-element`} disableautomargin
                    onClick={handleClick} borderleft={`.3rem solid ${frameColor.hexCode}`} clickable>
               <SGGridRow align="center">
                  <SGGridCol span={(isCoOwned || isBeingBoughtBack) ? 4 : 8}>
                     <SGText data-cy={`${cypressName}-title`} strong>
                        {icon === "home" && <SGIcon component={<SGAvenirStrokedHabitation/>}/>}
                        {icon === "cash" && <SGIcon component={<SGAvenirStrokedBillet/>}/>}
                        {icon === "expense" && <SGIcon component={<SGAvenirStrokedAutresDepenses/>}/>}
                        {icon && <>&nbsp;</>}
                        {title}
                     </SGText>
                     <SGBlock component="p" disableautomargin>
                        <SGText data-cy={`${cypressName}-subTitle`}>
                           {getSubTitle()}
                        </SGText>
                     </SGBlock>
                  </SGGridCol>
                  <SGGridCol span={(isCoOwned || isBeingBoughtBack) ? 4 : 0} align="center">
                     {isCoOwned &&
                        <SGTag tagcolor={SG_BLACK.hexCode}>
                           <SGTextIntl intlId="profile.assets.co-owned" data-cy={`${cypressName}-coOwned`} size="xs"/>
                        </SGTag>
                     }
                     {isBeingBoughtBack &&
                        <SGTag tagcolor={SG_BLACK.hexCode}>
                           <SGTextIntl intlId="profile.assets.boughtback" data-cy={`${cypressName}-boughtback`} size="xs"/>
                        </SGTag>
                     }
                  </SGGridCol>
                  <SGGridCol textalign="right" span={4} align="center">
                     {!isAssociatedToSCI &&
                        <SGText strong>
                           <CustomNumberFormat thousandSeparator={" "} suffix=" €" displayType="text" value={amount} data-cy={`${cypressName}-amount`}/>
                        </SGText>
                     }
                     {isPerMonth && (
                        <SGTextIntl intlId="profile.income.per.month" strong/>
                     )}
                     {isNet && (
                        <SGTextIntl intlId="profile.income.net.per.month" strong/>
                     )}
                     {isPerYear && (
                        <SGTextIntl intlId="profile.income.per.year" strong/>
                     )}
                     <SGBlock component="p" disableautomargin>
                        <SGText data-cy={`${cypressName}-amount-subTitle`}>
                           {amountSubTitle}
                        </SGText>
                     </SGBlock>
                  </SGGridCol>
               </SGGridRow>
            </SGCard>
         )}
      </>
   );
}

export {NameAndValuationFrame};
