import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche, SGAvenirStyledFinancement } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText, SGTitle } from "sg-typo";
import { SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useSimulateurEconomiesImpotsState } from "website/components/hooks/useSimulateurEconomiesImpotsState";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";

export const AccesSimulateurEconomieImpot: FunctionComponent = () => {
    const history = useHistory();
    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick } = useTracking();

    const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState = useSimulateurEconomiesImpotsState();

    const handleOnClickSEILink = () => {
        // Tracking [Clic] Clic sur le lien PER estimation d'économie d'impôts - MODIFICATION
        trackClick("clic-sur-per-mon-economie-d-impot");
        history.push(SIMULATEUR_ECONOMIES_IMPOTS);
    };

    return (
        <>
            {/* @ts-ignore le temps que SGTile soit corrigé */}
            <SGTile clickable="full" disableautomargin={!isPhone} id="SEITile">
                <SGGridRow align="middle">
                    <SGGridCol flex="0 0 0%">
                        <SGIcon component={<SGAvenirStyledFinancement />} size="s" />
                    </SGGridCol>
                    <SGGridCol flex="1 0 0%">
                        <SGTitle level={2} visuallevel={4}>
                            {intl.formatMessage({
                                id: "dashboard.effortEpargne.sei.title",
                            })}
                        </SGTitle>
                        <SGBox>
                            <SGText size="s">
                                {intl.formatMessage(
                                    {
                                        id:
                                            simulateurEconomiesImpotsState.savedParameters.payment === undefined
                                                ? "dashboard.effortEpargne.sei.new"
                                                : "dashboard.effortEpargne.sei.modify",
                                    },
                                    {
                                        economieImpot: simulateurEconomiesImpotsState.taxEconomy.taxSaving.toLocaleString("fr-FR"),
                                        b: (word: string) => <SGText weight="700">{word}</SGText>,
                                    }
                                )}
                            </SGText>
                        </SGBox>
                    </SGGridCol>
                    <SGGridCol flex="0 0 0%">
                        <SGButton icon={<SGIcon component={<SGAvenirNavFleche />} />} type="icon" onClick={handleOnClickSEILink} />
                    </SGGridCol>
                </SGGridRow>
            </SGTile>
        </>
    );
};
