import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useTaxCredits} from "../../hooks/useTaxCredits";
import {StringInput} from "../../atoms/StringInput/StringInput";
import {SelectInput} from "../../atoms/SelectInput/SelectInput";
import {TaxCredit, TaxCreditType} from "../../../../store/tax/types";
import {createTaxCreditAction, deleteTaxCreditAction, updateTaxCreditAction} from "../../../../store/tax/actions";
import {getMembersOfCouple, hasChildrenUnder6, toMembers} from "../../../../store/members/utils";
import {NumericInput} from "../../atoms/NumericInput/NumericInput";
import {DeleteModal} from "../DeleteModal/DeleteModal";
import {ErrorElement} from "../../atoms/ErrorElement/ErrorElement";
import {useFamily} from "../../hooks/useFamily";
import {EditorContext} from "../../providers/EditorProvider";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGButton, SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../atoms/FormInputs/FormInputs";
import {SGCard} from "sg-card";
import {SGIcon} from "sg-icon";
import {SGAvenirStrokedCorbeille, SGAvenirStrokedModification} from "sg-icon-pack-base";
import {WHEAT} from "../../../utils/graphs/colors";
import {SGText} from "sg-typo";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";

interface TaxCreditElementProps {
   taxCredit: TaxCredit | Omit<TaxCredit, "id">;
   onCancel?: () => void;
   isCreating?: boolean;
   cypressName: string;
}

const TaxCreditElement: FunctionComponent<TaxCreditElementProps> = (props: TaxCreditElementProps) => {
   const {taxCredit, onCancel, isCreating, cypressName} = props;

   const intl = useIntl();
   const dispatch = useDispatch();

   const taxCredits = useTaxCredits();
   const family = useFamily();
   const couple = getMembersOfCouple(family);

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });
   const {handleSubmit} = methods;

   const [isEditingTaxCredit, setEditingTaxCredit] = useState(false);
   const [isSubmitting, setSubmitting] = useState(false);
   const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);

   useEffect(() => {
      if (isSubmitting) {
         setEditingTaxCredit(false);
         setSubmitting(false);
      }
   }, [taxCredits]);

   useEffect(() => {
      setEditing(isEditingTaxCredit);
   }, [isEditingTaxCredit]);

   // Permet de mettre le composant en édition à l'ajout d'un nouvel élément
   useEffect(() => {
      setEditingTaxCredit(!!isCreating);
   }, []);

   // Pas de crédits d'impot "Garde d'enfant" si pas d'enfants de moins de 6 ans
   const taxCreditsType: TaxCreditType[] = hasChildrenUnder6(family.children)
      ? Object.values(TaxCreditType)
      : Object.values(TaxCreditType).filter((creditType) => creditType !== TaxCreditType.CHILDCARE_DEDUCTIBLE_EXPENSE);

   const onSelectTaxCreditType = (newTaxCreditType: string) => {
      const amountOfTaxCreditsOfSameType = taxCredits.filter((tc) => tc.taxCreditType === newTaxCreditType).length;
      const defaultName = amountOfTaxCreditsOfSameType < 1 ? `${intl.formatMessage({id: `tax.credit.type.name.${newTaxCreditType}`})}` :
         `${intl.formatMessage({id: `tax.credit.type.name.${newTaxCreditType}`})} ${amountOfTaxCreditsOfSameType + 1}`;
      methods.setValue("name", defaultName, {shouldValidate: true});
   };

   function onSubmit(data: TaxCredit & { beneficiaryId: string }) {
      const beneficiary = toMembers(family).find((m) => m.id === Number(data.beneficiaryId));
      if (beneficiary) {
         if ("id" in taxCredit) {
            dispatch(updateTaxCreditAction({
               id: taxCredit?.id,
               taxCreditType: taxCredit.taxCreditType,
               name: data.name,
               amount: Number(data.amount),
               beneficiary
            }));
         } else {
            dispatch(createTaxCreditAction({
               taxCreditType: data.taxCreditType,
               name: data.name,
               amount: Number(data.amount),
               beneficiary
            }));
         }
      }
      setSubmitting(true);
   }

   const renderTaxCreditElement = () => (
      <SGGridRow align="center">
         <SGGridCol xs={12} xl={6}>
            <SGText>
               {taxCredit.name}
            </SGText>
         </SGGridCol>
         <SGGridCol xs={12} xl={4}>
            <SGText>
               <CustomNumberFormat thousandSeparator={" "} suffix={intl.formatMessage({id: "common.euro.per.year"})} displayType="text"
                                   value={taxCredit?.amount}/>
            </SGText>
         </SGGridCol>
         <SGGridCol xs={12} xl={2} textalign="right">
            <SGButton type="icon" data-cy={`${cypressName}-edit-button`} disabled={isEditing} onClick={() => setEditingTaxCredit(true)}>
               <SGIcon component={<SGAvenirStrokedModification/>}/>
            </SGButton>
            <SGButton type="icon" data-cy={`${cypressName}-delete-button`} disabled={isEditing} onClick={() => setDeleteModalVisible(true)}>
               <SGIcon component={<SGAvenirStrokedCorbeille/>}/>
            </SGButton>
         </SGGridCol>
      </SGGridRow>
   );

   const renderTaxCreditEdit = () => (
      <FormProvider {...methods}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <FormInputs colLength={10}>
               <SelectInput disabled={!isCreating} onValueChange={onSelectTaxCreditType} cypressName={`${cypressName}-edit-type`} name="taxCreditType"
                            defaultValue={taxCredit.taxCreditType} label="tax.credit.form.type">
                  {taxCreditsType.map(
                     (taxCreditType: TaxCreditType) => (
                        <SGSelectOption key={taxCreditType} data-cy={`${cypressName}-edit-status-${taxCreditType}`} value={taxCreditType}>
                           {intl.formatMessage({id: `tax.credit.type.${taxCreditType}`})}
                        </SGSelectOption>
                     )
                  )}
               </SelectInput>

               <SelectInput cypressName={`${cypressName}-edit-beneficiary`} name="beneficiaryId" defaultValue={taxCredit.beneficiary.id}
                            label="tax.credit.form.beneficiary">
                  {couple.map((member) => (
                     <SGSelectOption key={member.id} data-cy={`${cypressName}-edit-beneficiary-${member.status}`} value={member.id}>
                        {intl.formatMessage({id: `family.status.${member.status}`})}
                     </SGSelectOption>
                  ))}
               </SelectInput>

               <StringInput label="tax.credit.form.name" cypressName={`${cypressName}-edit-name`} name="name" minLength={2} maxLength={140}
                            defaultValue={taxCredit.name}/>

               <NumericInput cypressName={`${cypressName}-edit-amount`} min={50} name="amount" label="tax.credit.form.amount"
                             suffix="common.euro.per.year" defaultValue={taxCredit?.amount}
                             placeholder={intl.formatMessage({id: "tax.credit.form.amount.placeholder"})}/>

            </FormInputs>
            <ErrorElement cypressName={`${cypressName}-api-errors`} errorTextPrefix="profile.tax.error"/>

            <SGButtonGroup>
               <SGButtonIntl type="primary" size="sm" disabled={!methods.formState.isValid} cypressName={`${cypressName}-edit-submit`}
                             onClick={handleSubmit(onSubmit)} intlId={isCreating ? "common.add" : "common.update"}/>
               <SGButtonIntl type="secondary" size="sm" cypressName={`${cypressName}-edit-cancel`} intlId="common.cancel"
                             onClick={() => (onCancel ? onCancel() : setEditingTaxCredit(false))}/>
            </SGButtonGroup>
         </form>
      </FormProvider>
   );

   return (
      <SGCard align="center" borderleft={`.3rem solid ${WHEAT.hexCode}`}>
         {isEditingTaxCredit && renderTaxCreditEdit()}
         {!isEditingTaxCredit && renderTaxCreditElement()}

         {isDeleteModalVisible && "id" in taxCredit && (
            <DeleteModal hideModal={() => setDeleteModalVisible(false)} cypressName="tax-credit" textId="tax.credit.delete.modal.text"
                         onSubmit={() => dispatch(deleteTaxCreditAction(taxCredit))}/>
         )}
      </SGCard>
   );
}

export {TaxCreditElement};
