import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import { AssetIncomesState, hasFetchedAssets } from "store/assetIncomes/types";
import { getAssetsWithoutLoans } from "store/assets/actions";
import { AssetState } from "store/assets/types";
import { computeAdditionalIncomes } from "store/assets/utils";
import { DashboardAge } from "store/dashboard/types";
import { State } from "store/store";
import { useMonPlanMontants } from "website/components/hooks/monPlan/useMonPlanMontants";
import { GraphRevenuTotalRetraite } from "website/components/molecules/GraphRevenuTotalRetraite/GraphRevenuTotalRetraite";
import { roundDown10 } from "website/utils/formatting/numberFormatter";

interface RevenuTotalRetraiteProps {
    selectedRetirementAge?: DashboardAge;
}

const RevenuTotalRetraite: FunctionComponent<RevenuTotalRetraiteProps> = (props: RevenuTotalRetraiteProps) => {
    const { selectedRetirementAge } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [montantPension, setMontantPension] = useState<number>();
    const [montantRevenusComplementaires, setMontantRevenusComplementaires] = useState<number>();
    const [montantTotal, setMontantTotal] = useState<number>();
    const { montantRevenusAFinancer } = useMonPlanMontants(selectedRetirementAge);
    const assetState: AssetState = useSelector<State, AssetState>((state) => state.assets);
    const assetIncomesState: AssetIncomesState = useSelector<State, AssetIncomesState>((state) => state.assetIncomes);

    const {assets} = assetState;
    const {assetIncomes} = assetIncomesState;

    useEffect(() => {
        if (!assetState) {
            dispatch(getAssetsWithoutLoans());
        }
        if (!hasFetchedAssets(assetIncomesState)) {
            dispatch(getAssetsIncomes());
        }
    }, []);

    useEffect(() => {
        if (selectedRetirementAge) {
            let total = 0;
            if (selectedRetirementAge.retirementMonthlyIncome) {
                const retirementMonthlyIncome = roundDown10(selectedRetirementAge.retirementMonthlyIncome);
                setMontantPension(retirementMonthlyIncome);
                total += retirementMonthlyIncome;
            }

            let filteredAssets;
            if (assetState) {
                filteredAssets = assets.filter((asset) => asset.percentageUsedForRetirement > 0);
            }
            if (assetIncomesState && filteredAssets && filteredAssets.length > 0) {
                const retirementAdditionalIncome = roundDown10(computeAdditionalIncomes(assets, assetIncomes, selectedRetirementAge));
                setMontantRevenusComplementaires(roundDown10(retirementAdditionalIncome));
                total += roundDown10(retirementAdditionalIncome);
            }
            if (montantRevenusAFinancer) {
                total += roundDown10(montantRevenusAFinancer);
            }
            setMontantTotal(total);
        }
    }, [selectedRetirementAge, assetState, assetIncomesState, montantRevenusAFinancer]);

    return (
        <>
            <SGBox margin={{top: "sm", bottom: "md"}} span={4}>
                <SGTitle level={1} visuallevel={3} caps>
                    {intl.formatMessage({ id: "dashboard.revenuTotalRetraite.title" })}
                </SGTitle>
            </SGBox>
            <GraphRevenuTotalRetraite
                montantPension={montantPension}
                montantRevenusComplementaires={montantRevenusComplementaires}
                montantTotal={montantTotal}
                montantRevenusAFinancer={montantRevenusAFinancer}
                assetsLength={assets ? assets.filter((asset) => asset.percentageUsedForRetirement > 0).length : 0}
            />
        </>
    );
};

export { RevenuTotalRetraite };
