import React, {FunctionComponent} from "react";
import {LeaveProject} from "../components/organisms/Projects/LeaveProject/LeaveProject";
import {SGContent} from "sg-layout";

const SabbaticalLeavePage: FunctionComponent = () => (
   <SGContent>
      <LeaveProject type="sabbatical-leave"/>
   </SGContent>
);

export { SabbaticalLeavePage };
