import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RoutingState} from "../../../store/routing/types";
import {State} from "../../../store/store";
import {redirectToRelativePath} from "../../utils/routes/routing";
import {resetRedirectAction} from "../../../store/routing/actions";

const useRedirect = () => {
   const dispatch = useDispatch();

   const routingState: RoutingState = useSelector<State, RoutingState>(
      (state) => state.routing
   );

   useEffect(() => {
      if (routingState.shouldRedirect) {
         redirectToRelativePath(routingState.redirectUrl);
         dispatch(resetRedirectAction());
      }
   }, [routingState.shouldRedirect]);
};

export {useRedirect};
