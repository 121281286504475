import { Fragment, FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGCard, SGCardMeta } from "sg-card";
import { SGCarousel } from "sg-carousel";
import { SGMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Article, Media, TypeMedia } from "website/components/hooks/articles";
import { Video } from "website/components/hooks/useVideos";
import "./CarrouselALaUne.scss";
import { CarteArticleVertical } from "./CarteArticleVertical";

interface CarrouselALaUneProps {
    articles: Article[];
    videos?: Video[];
    handleOnClickLireArticle: (path: string, trackKey : string) => void;
    trackKeyArticle: string
    trackKeyVideo: string
}
export const CarrouselALaUne: FunctionComponent<CarrouselALaUneProps> = (props: CarrouselALaUneProps) => {
    const { articles, videos = [], handleOnClickLireArticle, trackKeyArticle, trackKeyVideo } = props;
    const intl = useIntl();

    // On recalcule uniquement si les dépendances changent
    const medias: Media[] = useMemo(() => ([...videos , ...articles]), [articles, videos])

    return (
        <SGSpace direction="vertical" disableautomargin size="xs" className="carrousel-a-la-une">
            <SGTitle key="title" level={2}>{intl.formatMessage({ id: "articles.titre.ALaUne" })}</SGTitle>
            <SGCarousel
                key="carousel"
                slidesToScroll={1}
                settings={{
                    infinite: true,
                    slidesToShow: 1
                }}
            >
                {medias.map((media) => (
                    <Fragment key={`carrousel-${media.id}`}>
                        <SGMediaQuery minwidth="xl">
                            <SGBox span={8}>
                                <SGCard
                                    actions={[
                                        <SGButton
                                            size={['md', 'sm']}
                                            onClick={() => handleOnClickLireArticle(media.path,media.type !== TypeMedia.VIDEO ? trackKeyArticle : trackKeyVideo)}
                                            ariaLabel={intl.formatMessage({ id: `articles.bouton.lire.${media.type}.ariaLabel` })}
                                        >
                                            {intl.formatMessage({ id: `articles.bouton.lire.${media.type}` })}
                                        </SGButton>,
                                    ]}
                                    cover={
                                        <img
                                            alt={`${intl.formatMessage({ id: "articles.couverture.alt" })}${intl.formatMessage({
                                                id: `home.article.title.${media.id}`,
                                            })}`}
                                            src={media.image}
                                        />
                                    }
                                    coverposition="leftCenter"
                                    coversize="full"
                                >
                                    <SGCardMeta
                                        description={
                                            media.type !== TypeMedia.VIDEO &&
                                            <SGBox span={6} align="left">
                                                <SGText color="lightest" ellipsis>
                                                    {intl.formatMessage({ id: `home.article.description.${media.id}` })}
                                                </SGText>
                                            </SGBox>
                                        }
                                        title={
                                            <SGBox margin={{ top: "xxl" }}>
                                                <SGTitle colorlight level={4}>
                                                    {intl.formatMessage({ id: `${media.type !== TypeMedia.VIDEO ? "home.article.title" : "video"}.${media.id}${media.type !== TypeMedia.VIDEO ? "" : ".title"}` })}
                                                </SGTitle>
                                            </SGBox>
                                        }
                                    />
                                </SGCard>
                            </SGBox>
                        </SGMediaQuery>

                        <SGMediaQuery minwidth={0} maxwidth="lg">
                            <CarteArticleVertical media={media} handleOnClickLireArticle={handleOnClickLireArticle} trackKey={media.type !== TypeMedia.VIDEO ? trackKeyArticle : trackKeyVideo} />
                        </SGMediaQuery>
                    </Fragment>
                ))}
            </SGCarousel>
        </SGSpace>
    );
};
