/**
 * Ce state permet d'effectuer des redirections dans l'application.
 * Pour cela, configurez le lien de redirection dans 'redirectUrl' et shouldRedirect = true dans le reducer de routing
 * Ensuite, créez un hook sur le modèle de useIncomeExpenseTunnelRedirect et placer ce hook dans les composants où vous souhaitez écouter le state pour redirection
 */

export interface RoutingState {
   shouldRedirect: boolean;
   redirectUrl: string;
}

// actions
export const RESET_REDIRECT = "RESET_REDIRECT";
export const TRIGGER_INCOMES_REDIRECT = "TRIGGER_INCOMES_REDIRECT";
export const TRIGGER_INFORMATION_FOR_RETIRED = "TRIGGER_INFORMATION_FOR_RETIRED";
