import {call, put, takeLeading} from "redux-saga/effects";
import {FETCH_RECOMMENDATIONS, Indicator, Recommendation} from "./types";
import {safe} from "../error/utils";
import {apiGet} from "../apiCaller";
import {AxiosError, AxiosResponse} from "axios";
import {fetchRecommendationsError, recommendationsFetched, resetRecommendations} from "./recommendationsSlice";

export function* watcherSaga() {
   yield takeLeading(FETCH_RECOMMENDATIONS, safe(onRecommendationsError, handleGetRecommendations));
}

function* handleGetRecommendations() {
   yield put(resetRecommendations());
   const payload: AxiosResponse<{ recommendations: Recommendation[], indicators: Indicator[] }> = yield call(apiGet, `wealth/api/recommendations?includeIndicators=${window.env.REACT_APP_DISPLAY_INDICATORS}`);
   yield put(recommendationsFetched(payload.data));
}

function* onRecommendationsError(err: AxiosError) {
   yield put(fetchRecommendationsError());
}
