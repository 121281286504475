import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Highcharts, {Options} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {computeBalanceNetWorthChartOptions} from "../../../utils/graphs/graph-builders";
import {HighChartsAlert,} from "../../../../store/simulation/types";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {getSimulationRange} from "../../../../store/simulation/utils";
import {useProjects} from "../../hooks/useProjects";
import {useSimulation} from "../../hooks/useSimulation";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";

interface GraphSimulationProps {
   alerts: HighChartsAlert[];
}

const GraphSimulation: FunctionComponent<GraphSimulationProps> = (props: GraphSimulationProps) => {
   const {alerts} = props;
   const intl = useIntl();
   const [chartOptionsNetWorth, setChartOptionsNetWorth] = useState<Highcharts.Options>();
   const range = useRef<number[]>([]);

   const projects = useProjects(false, false);
   const simulation = useSimulation(false);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);

   useEffect(() => {
      if (hasFetchedSimulation) {
         range.current = getSimulationRange(simulation);
         const options: Options = computeBalanceNetWorthChartOptions(
            800,
            projects,
            alerts,
            simulation.graphs,
            simulation.birthday,
            range.current,
            intl.formatMessage
         );

         setChartOptionsNetWorth(options);
      }
   }, [simulation, projects, hasFetchedSimulation]);

   return (
      <div data-cy="graph-simulation">
         <SpinnerWrapper displayComponent={chartOptionsNetWorth !== undefined} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
            <HighchartsReact highcharts={Highcharts} options={chartOptionsNetWorth}/>
         </SpinnerWrapper>
      </div>
   );
};

export {GraphSimulation};
