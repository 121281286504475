import {ProjectFeasibility, ProjectPlanAlert, ProjectPlanStatus, ProjectType} from "./types";

export const getMeteoPictureAsString = (projectFeasibility: ProjectFeasibility): string => {
   if (!projectFeasibility) {
      return "";
   }

   switch (projectFeasibility.plan?.status) {
      case ProjectPlanStatus.OK:
      case ProjectPlanStatus.OPTIMIZE:
         return "Sun";
      case ProjectPlanStatus.INFO:
      case ProjectPlanStatus.WARN:
         return "SunWithCloud2";
      case ProjectPlanStatus.KO:
         if (projectFeasibility.projectType === ProjectType.RETIREMENT_PROJECT) {
            return "SunWithCloud1";
         }

         return "Thunder";
      default:
         return "Thunder";
   }
};

export const getMeteoMessageIds = (projectFeasibility: ProjectFeasibility, hasRetirementProject: boolean): string[] => {
   const messageId = getMeteoMessageId(projectFeasibility, hasRetirementProject);

   return [`project.feasibility.plan.${messageId}.result`, `project.feasibility.plan.${messageId}.explanation`];
};

export const getMeteoMessageId = (projectFeasibility: ProjectFeasibility, hasRetirementProject: boolean): string => {
   if (projectFeasibility == null) {
      return "";
   }

   const {status, alert} = projectFeasibility.plan;

   if (projectFeasibility.projectType === ProjectType.RETIREMENT_PROJECT) {
      switch (status) {
         case ProjectPlanStatus.OK:
            return "RETIREMENT.OK";
         case ProjectPlanStatus.OPTIMIZE:
            return "RETIREMENT.OPTIMIZE";
         case ProjectPlanStatus.INFO:
            return "RETIREMENT.INFO";
         case ProjectPlanStatus.WARN:
            return "RETIREMENT.WARN";
         case ProjectPlanStatus.KO:
            if (alert === ProjectPlanAlert.BANKRUPTCY_REC) {
               return "RETIREMENT.BEFORE_THRESHOLD";
            }

            return "RETIREMENT.KO";
         default:
            return "NOT_AVAILABLE";
      }
   }

   if (!!status && status === ProjectPlanStatus.KO) {
      if (alert === ProjectPlanAlert.BANKRUPTCY_BEFORE) {
         return "KO.BEFORE";
      }

      return "KO.KO";
   }

   switch (status) {
      case ProjectPlanStatus.OK:
      case ProjectPlanStatus.OPTIMIZE:
         return "OK";
      case ProjectPlanStatus.INFO:
         switch (alert) {
            case ProjectPlanAlert.USES_INVESTMENT:
               return "INFO.INV";
            case ProjectPlanAlert.USES_SAVING:
               return "INFO.SAVINGS";
            default:
               return "INFO.DEFAULT";
         }
      case ProjectPlanStatus.WARN:
         switch (alert) {
            case ProjectPlanAlert.USES_INVESTMENT:
               return "WARN.INVEST";
            case ProjectPlanAlert.BANKRUPTCY_AFTER:
               return hasRetirementProject ? "WARN.BANK_AFTER" : "WARN.BANK_AFTER_WITH_NO_RETIREMENT";
            default:
               return "WARN.DEFAULT";
         }
      default:
         return "NOT_AVAILABLE";
   }
};
