import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {IncomeEdit} from "../../../molecules/IncomeEdit/IncomeEdit";
import {IncomeElement} from "../../../molecules/IncomeElement/IncomeElement";
import {Income} from "../../../../../store/incomes/types";
import {useIncomes} from "../../../hooks/useIncomes";
import {useAssets} from "../../../hooks/useAssets";
import {EditorContext} from "../../../providers/EditorProvider";
import {ButtonAddNewElement} from "../../../atoms/ButtonAddNewElement/ButtonAddNewElement";
import {SGCard} from "sg-card";
import {SGSpace} from "sg-space";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";

export interface IncomesEditorProps {
   cypressName: string;
}

const IncomesEditor: FunctionComponent<IncomesEditorProps> = (props: IncomesEditorProps) => {
   const {cypressName} = props;

   const incomes = useIncomes();
   const { assets } = useAssets();

   const displayComponent = useSelector<State, boolean>((state) => state.incomes.hasFetched);

   const [isCreatingIncome, setIsCreatingIncome] = useState(false);

   const {isEditing, setEditing} = useContext(EditorContext);

   const incomeMethods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   useEffect(() => {
      setEditing(isCreatingIncome);
   }, [isCreatingIncome]);

   useEffect(() => {
      setIsCreatingIncome(false)
   }, [incomes]);

   const getAssetByIncome = (income: Income) =>
      assets
         .filter((asset) => asset.id === income.assetId || asset.id === income.id)
         .shift();

   return (
      <SpinnerWrapper displayComponent={displayComponent} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout>
         <SGSpace direction="vertical">
            <FormProvider {...incomeMethods}>
               <form>
                  {isCreatingIncome &&
                     <SGCard disableautomargin>
                        <IncomeEdit onCancel={() => setIsCreatingIncome(false)} cypressName={`${cypressName}-new-edit`}/>
                     </SGCard>
                  }
                  {incomes && incomes.map((income) => (
                     <IncomeElement income={income} key={income.id} asset={getAssetByIncome(income)} assets={assets}
                                    cypressName={`${cypressName}-${income.name}`}/>
                  ))}
               </form>
            </FormProvider>
            <ButtonAddNewElement cypressName="incomes-add" text="form.income.new" disabled={isEditing} onClick={() => setIsCreatingIncome(true)}/>
         </SGSpace>
      </SpinnerWrapper>
   );
};

export {IncomesEditor};
