export const convertNumberToStringWithSeparators = (x: number): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const roundToTen = (toRound: number) => roundTo(toRound, 1);

export const roundToHundreds = (toRound: number) => roundTo(toRound, 2);

export const roundToThousands = (toRound: number) => roundTo(toRound, 3);

const roundTo = (toRound: number, numZeroAfterInt: number) => {
   const powerOfTen = 10 ** numZeroAfterInt;
   const intVal = Math.round(toRound / powerOfTen);

   return intVal * powerOfTen;
};

// Rounding Down To The Nearest Tenth
export const roundDown10 = (number: number) => roundDown(number, 10);

// Rounding Down To The Nearest Hundred
export const roundDown100 = (number: number) => roundDown(number, 100);

const roundDown = (numToRound: number, roundedNumber: number) => {
   if (numToRound) {
      return Math.floor(numToRound / roundedNumber) * roundedNumber;
   }

   return 0;
}
export const getStringValue = (value: number | undefined) => {
  if (value !== undefined && value >= 0) {
      return value?.toLocaleString("fr-FR");
  }

  return "--";
};
