import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TunnelState } from "./types";

const initialState: TunnelState = {
   currentStep: 0,
   isTunnelReforme: false,
};

export const tunnelSlice = createSlice({
   name: "tunnel",
   initialState,
   reducers: {
      resetTunnel: () => initialState,
      setIsTunnelReforme: (currentState, action: PayloadAction<boolean>) => ({
         ...currentState,
         isTunnelReforme: action.payload,
      }),
      nextStep: (currentState) => ({
         ...currentState,
         currentStep: currentState.currentStep + 1
      }),
      previousStep: (currentState) => ({
         ...currentState,
         currentStep: currentState.currentStep - 1
      }),
      goToStep: (currentState, action: PayloadAction<number>) => ({
         ...currentState,
         currentStep: action.payload
      })
   }
});

export const {resetTunnel, nextStep, previousStep, goToStep, setIsTunnelReforme} = tunnelSlice.actions;

export default tunnelSlice.reducer;
