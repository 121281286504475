import React, {FunctionComponent} from "react";
import {Recommendation, RecommendationCategory, RecommendationState,} from "../../../../../store/recommendations/types";
import {RecommendationElement} from "../../../molecules/RecommendationElement/RecommendationElement";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGCarousel} from "sg-carousel";
import {useSelector} from "react-redux";
import {State} from "../../../../../store/store";
import {useRecommendations} from "../../../hooks/useRecommendations";

interface RecommendationsProps {
   categoryFilters?: RecommendationCategory[];
}

const Recommendations: FunctionComponent<RecommendationsProps> = ({categoryFilters}: RecommendationsProps) => {
   const recommendationState: RecommendationState = useSelector<State, RecommendationState>((state) => state.recommendations);

   const hasFetchedRecommendations = useSelector<State, boolean>((state) => state.recommendations.hasFetched || state.recommendations.cannotFetchRecommendations);
   const recommendations = useRecommendations(true);

   return (
      <>
         {recommendationState.cannotFetchRecommendations && (
            <SGAlertIntl cypressName="recommendations-cannot-fetch-error" type="warning" title="common.warning">
               <SGTextIntl intlId="advices.coach.recommendations.error"/>
            </SGAlertIntl>
         )}
         {!recommendationState.cannotFetchRecommendations && (
            <SpinnerWrapper spinnerSize={SpinnerSize.LG} displayComponent={hasFetchedRecommendations} fatalErrorOnTimeout={false}>
               <SGCarousel settings={{infinite: false, slidesToShow: 1, slidesToScroll: 1}}>
                  {recommendations && recommendations.filter((recommendation: Recommendation) => categoryFilters === undefined || categoryFilters.includes(recommendation.category)).map((recommendation: Recommendation) => (
                     <RecommendationElement key={recommendation.id} recommendation={recommendation}
                                            cypressName={`recommendations-element-${recommendation.recommendationType}`}/>
                  ))}
               </SGCarousel>
            </SpinnerWrapper>
         )}
      </>
   );
};

export {Recommendations};
