import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { NumericInput } from "../NumericInput/NumericInput";

interface RoundedSalaryProps {
  salary: number | undefined;
  disabled?: boolean;
  setSalary?: (val: number) => void;
  maxValueInput?: number;
  setHasChangedValue?: (val: boolean) => void;
  disableautomargin? : boolean;
}

/**
 * Ce composant permet l'afficher d'un champ de saisie avec un arrondi du montant
 */
const RoundedSalary: FunctionComponent<RoundedSalaryProps> = (
  props: React.PropsWithChildren<RoundedSalaryProps>
) => {
  const {
    salary,
    disabled = false,
    setSalary,
    maxValueInput,
    setHasChangedValue,
    disableautomargin = false,
  } = props;
  // Variable interne pour éviter les effets de bord
  const [salaryInput, setSalaryInput] = useState(salary);
  const [cursorOut, setCursorOut] = useState(true);

  const methods = useForm();

  // Initialisation de la variable interne, si besoin
  useEffect(() => {
    if (salary !== undefined && salaryInput === undefined) {
      setSalaryInput(salary);
    }
  }, [salary]);

  // Arrondit le montant lorsque l'on sort du champ de saisie
  useEffect(() => {
    if (cursorOut && salaryInput && setSalary) {
      if (maxValueInput && salaryInput > maxValueInput) {
        setSalaryInput(maxValueInput);
        setSalary(maxValueInput);
      } else {
        setSalaryInput(roundDown10(salaryInput));
        setSalary(roundDown10(salaryInput));
      }
      if (setHasChangedValue !== undefined) {
        setHasChangedValue(true);
      }
    }
  }, [cursorOut]);

  const onSalaryChangeInput = (value?: number) => {
    if (setSalary !== undefined && typeof value === "number") {
      setSalaryInput(value);
      setSalary(
        maxValueInput && value > maxValueInput
          ? maxValueInput
          : roundDown10(value)
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <NumericInput
        suffix="€"
        defaultValue={salaryInput}
        disabled={disabled}
        name="salaryMonthDS"
        cypressName="salary-month-ds"
        min={0}
        max={maxValueInput}
        placeholder="--"
        onValueChange={(value) => {
          onSalaryChangeInput(value);
        }}
        setCursorOut={setCursorOut}
        overrideDefaultValue
        disableautomargin={disableautomargin}
      />
    </FormProvider>
  );
};

export { RoundedSalary };
