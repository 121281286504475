import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Highcharts, {Options} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Scenario,} from "../../../../store/simulation/types";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {getSimulationRange} from "../../../../store/simulation/utils";
import {useSimulation} from "../../hooks/useSimulation";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGButton} from "sg-button";
import {SGIcon} from "sg-icon";
import {SGAvenirNavFleche} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";
import {computeDetailedIncomeExpenseChartOptions} from "../../../utils/graphs/graph-builders";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";

const GraphDetailedIncomesExpenses: FunctionComponent = () => {
   const intl = useIntl();
   const birthDay = useRef<number>(18);
   const [chartDisplayMode, setChartDisplayMode] = useState<number>(5);
   const [chartOffset, setChartOffset] = useState<number>(0);
   const [maxChartOffset, setMaxChartOffset] = useState<number>(0);
   const [chartOptionsIncomesExpenses, setChartOptionsIncomesExpenses] = useState<Highcharts.Options>();

   const simulation = useSimulation(false);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);

   useEffect(() => {
      if (hasFetchedSimulation) {
         let range: number[] = getSimulationRange(simulation);
         const newMaxChartOffset = 1 + range[range.length - 1] - range[0] - chartDisplayMode;
         setMaxChartOffset(newMaxChartOffset);
         if (chartOffset > newMaxChartOffset) {
            setChartOffset(newMaxChartOffset);
         }
         range = range.slice(chartOffset, Number(chartDisplayMode) + chartOffset);

         birthDay.current = simulation.birthday;
         if (simulation.incomeExpenses) {
            const options: Options = computeDetailedIncomeExpenseChartOptions(
               [],
               birthDay.current,
               simulation.incomeExpenses,
               Scenario.CONSERVATIVE,
               range,
               intl.formatMessage,
               chartDisplayMode === 5
            );
            setChartOptionsIncomesExpenses(options);
         }
      }
   }, [simulation, hasFetchedSimulation, chartDisplayMode, chartOffset]);

   return (
      <SpinnerWrapper displayComponent={chartOptionsIncomesExpenses !== undefined} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
         <SGSpace align="center" size="sm">
            <SGButtonIntl intlId="graph.simulation.five" type={chartDisplayMode === 5 ? "primary" : "secondary"} onClick={() => setChartDisplayMode(5)}
                          size="sm"/>
            <SGButtonIntl intlId="graph.simulation.ten" type={chartDisplayMode === 10 ? "primary" : "secondary"} onClick={() => setChartDisplayMode(10)}
                          size="sm"/>
            <SGButtonIntl intlId="graph.simulation.fifteen" type={chartDisplayMode === 15 ? "primary" : "secondary"} onClick={() => setChartDisplayMode(15)}
                          size="sm"/>
         </SGSpace>
         <SGSpace align="center">
            <SGButton
               icon={<SGIcon component={<SGAvenirNavFleche/>} currentcolor rotate="left"/>}
               onClick={() => setChartOffset(chartOffset - 1)} disabled={chartOffset === 0}
               type="icon"
            />
            <SGButton
               icon={<SGIcon component={<SGAvenirNavFleche/>} currentcolor/>}
               onClick={() => setChartOffset(chartOffset + 1)} disabled={chartOffset === maxChartOffset}
               type="icon"
            />
         </SGSpace>
         {chartOptionsIncomesExpenses !== undefined &&
            <HighchartsReact highcharts={Highcharts} options={chartOptionsIncomesExpenses}/>
         }
      </SpinnerWrapper>
   );
};

export {GraphDetailedIncomesExpenses};
