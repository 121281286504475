import {
   CREATE_PROJECT,
   CREATE_RETIREMENT_PROJECT,
   DELETE_PROJECT,
   FETCH_ADDITIONAL_INCOME_OBJECTIVE,
   FETCH_CHANGE_LIFE_FUTURE_EXPENSES,
   FETCH_CHANGE_LIFE_FUTURE_INCOMES,
   FETCH_FEASIBILITIES,
   FETCH_FEASIBILITIES_AND_UPDATE_PROJECT,
   FETCH_GIVEN_DONATION_FEES,
   FETCH_PROJECTS,
   FETCH_RETIREMENT_PROJECT,
   NewDefaultProject,
   PartialRetirementProjectValues,
   Project,
   RESET_GIVEN_DONATION_FEES,
   RESET_RETIREMENT_PROJECT_IN_STORE,
   RetirementProjectValues,
   SIMULATE_RETIREMENT_PROJECT,
   UPDATE_RETIREMENT_PROJECT,
   UPDATE_RETIREMENT_PROJECT_IN_STORE,
   UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME
} from "./types";
import {Member} from "../members/types";

export const getProjectsAction = () => ({
   type: FETCH_PROJECTS
});

export const createProjectAction = (project: NewDefaultProject) => ({
   type: CREATE_PROJECT,
   payload: project
});

export const getRetirementProjectAction = (
   retirementProjectId: number,
   simulate: boolean,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined
) => ({
   type: FETCH_RETIREMENT_PROJECT,
   payload: {
      retirementProjectId,
      simulate,
      myCurrentIncome,
      partnerCurrentIncome
   }
});

export const updateRetirementProjectWithNewIncome = (
   retirementProjectId: number,
   myCurrentIncome: number | undefined,
   partnerCurrentIncome: number | undefined
) => ({
   type: UPDATE_RETIREMENT_PROJECT_WITH_NEW_INCOME,
   payload: {
      retirementProjectId,
      myCurrentIncome,
      partnerCurrentIncome
   }
});

export const deleteProjectAction = (project: Project) => ({
   type: DELETE_PROJECT,
   payload: project
});

export const getFeasibilitiesActions = () => ({
   type: FETCH_FEASIBILITIES
});

export const updateProjectAndFetchFeasibilitiesAction = (project: Project) => ({
   type: FETCH_FEASIBILITIES_AND_UPDATE_PROJECT,
   payload: project
});

export const updateRetirementProjectValuesAction = (retirementProject: RetirementProjectValues) => ({
   type: UPDATE_RETIREMENT_PROJECT,
   payload: retirementProject
});

export const updateRetirementProjectValuesInStoreAction = (retirementProject: RetirementProjectValues) => ({
   type: UPDATE_RETIREMENT_PROJECT_IN_STORE,
   payload: retirementProject
});

export const resetRetirementProjectValuesInStoreAction = () => ({
   type: RESET_RETIREMENT_PROJECT_IN_STORE
});

export const createRetirementProjectValuesAction = () => ({
   type: CREATE_RETIREMENT_PROJECT
});

export const simulateRetirementProjectAction = (retirementProject: Partial<PartialRetirementProjectValues>) => ({
   type: SIMULATE_RETIREMENT_PROJECT,
   payload: retirementProject
});

export const getAdditionalIncomeObjectiveAction = (amount: number, duration: number) => ({
   type: FETCH_ADDITIONAL_INCOME_OBJECTIVE,
   payload: {amount, duration}
});

export const getGivenDonationFeesAction = (amount: number, horizon: number, concernedMember: Partial<Member>) => ({
   type: FETCH_GIVEN_DONATION_FEES,
   payload: {amount, horizon, concernedMember}
});

export const resetGivenDonationFeesAction = () => ({
   type: RESET_GIVEN_DONATION_FEES
});

export const getChangeLifeFutureIncomesAction = (currentIncome: number, horizon: number, concernedMember: Partial<Member>) => ({
   type: FETCH_CHANGE_LIFE_FUTURE_INCOMES,
   payload: {currentIncome, horizon, concernedMember}
});

export const getChangeLifeFutureExpensesAction = (
   currentIncomes: Array<{ memberId: number | undefined, expectedIncome: number }>,
   horizon: number
) => ({
   type: FETCH_CHANGE_LIFE_FUTURE_EXPENSES,
   payload: {incomes: currentIncomes, horizon}
});
