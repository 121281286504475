import React, {FunctionComponent} from "react";
import {GoFurther} from "../GoFurther/GoFurther";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {ProjectFeasibility, ProjectPlanAlert} from "../../../../store/projects/types";
import {SGCarousel} from "sg-carousel";
import {SGSpace} from "sg-space";
import {useNumberProjects} from "../../hooks/useNumberProjects";
import {useMediaQuery} from "sg-media-query";
import {SGCard} from "sg-card";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGButtonGroup} from "sg-button";
import {redirectToRelativePath} from "../../../utils/routes/routing";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {PROJECTS, SITUATION} from "../../../utils/privateRoutes";

interface ProjectKOAdvicesProps {
   projectFeasibility: ProjectFeasibility
}

const ProjectKOAdvices: FunctionComponent<ProjectKOAdvicesProps> = (props: ProjectKOAdvicesProps) => {
   const {projectFeasibility} = props

   const numberProjects = useNumberProjects();
   const areButtonsBigger = useMediaQuery({
      minwidth: 'md'
   });

   const isBankruptcyBefore = (): boolean => projectFeasibility.plan.alert === ProjectPlanAlert.BANKRUPTCY_BEFORE;

   return (
      <SGSpace direction="vertical">
         <SGTitleIntl intlId="project.configuration.our.suggestions" cypressName="project-ko-advices-title"/>
         <SGCarousel slidesToShow={1} infinite={false}>
            <GoFurther customTextPrefix="project.advice" cypressParentName="project-ko-advices"
                       buttonSize={areButtonsBigger ? undefined : "sm"}/>
            {!isBankruptcyBefore() && (
               <SGCard data-cy="advice-lower-project-frame" bordered>
                  <SGSpace direction="vertical" textalign="center">
                     <SGTitleIntl intlId="project.advice.lower.project.title" cypressName="advice-lower-project-frame-title"/>
                     <SGTextIntl intlId="project.advice.lower.project.text"/>
                  </SGSpace>
               </SGCard>
            )}
            <SGCard data-cy="advice-see-profile-frame" bordered>
               <SGSpace direction="vertical" textalign="center">
                  <SGTitleIntl intlId="project.advice.see.profile.title" cypressName="advice-see-profile-frame-title"/>
                  <SGTextIntl intlId="project.advice.see.profile.text"/>
                  <SGButtonGroup align="center">
                     <SGButtonIntl type="primary" size={areButtonsBigger ? "md" : "sm"} cypressName="project.advice.see.profile.button"
                                   onClick={() => redirectToRelativePath(SITUATION)} intlId="project.advice.see.profile.button"/>
                  </SGButtonGroup>
               </SGSpace>
            </SGCard>
            {numberProjects > 1 && (
               <SGCard data-cy="advice-correct-other-projects-frame" bordered>
                  <SGSpace direction="vertical" textalign="center">
                     <SGTitleIntl intlId="project.advice.other.projects.title" cypressName="advice-correct-other-projects-frame-title"/>
                     <SGTextIntl intlId={isBankruptcyBefore() ? "project.advice.correct.other.projects.text" : "project.advice.other.projects.text"}/>
                     <SGButtonGroup align="center">
                        <SGButtonIntl type="primary" size={areButtonsBigger ? "md" : "sm"} cypressName="project.advice.see.profile.button"
                                      onClick={() => redirectToRelativePath(PROJECTS)}
                                      intlId="project.advice.other.projects.button"/>
                     </SGButtonGroup>
                  </SGSpace>
               </SGCard>
            )}
         </SGCarousel>
      </SGSpace>
   );
};

export {ProjectKOAdvices};
