import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedMesRendezVous,
  SGAvenirStyledEpargne,
} from "sg-icon-pack-base";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { Image } from "website/components/atoms/Image/Image";
import { RoundedSalary } from "website/components/atoms/RoundedSalary/RoundedSalary";
import { useMontantsMax } from "website/components/hooks/dashboard/useMontantsMax";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import "./EpargnerComparerHaut.scss";

interface EpargnerComparerHautProps {
  montantEpargne: number;
  setMontantEpargne: (value: number) => void;
}

const EpargnerComparerHaut: FunctionComponent<EpargnerComparerHautProps> = (
  props: EpargnerComparerHautProps
) => {
  const intl = useIntl();
  const { trackClick } = useTracking();

  const { montantEpargne, setMontantEpargne } = props;
  const { maxEpargneInput } = useMontantsMax();
  const openSGAgenda = useSGAgenda();
  const { disponibiliteSGAgenda } = useFeatures();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const handleOnClickRdv = () => {
    trackClick("comparaison::clic-sur-prendre-rendez-vous", {
      form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
    });
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  return (
    <div className="epargner-comparer-haut__container">
      <div className="epargner-comparer-haut__image-card" />
      <div className="comparer-tab__relative">
        <Image
              src="images/parcoursEpargner/imageComparerVideo.svg"
              alt="imageComparerVideo"
              className="epargner-comparer-haut__image-conseiller"
            />
      </div>
      <div className="epargner-comparer-haut__z-index">
        <SGBox padding={{top: "sm", left: "sm"}}>
          <SGGridRow>
            <SGGridCol span={12}>
              <SGText
                weight="700"
                style={{
                  fontSize: "20px",
                  fontFamily: "Montserrat",
                  color: "#010035",
                }}
              >
                {intl.formatMessage(
                  { id: `parcoursEpargner.comparer.subtitle.present` },
                  {
                    montantEpargne: montantEpargne?.toLocaleString("fr-FR"),
                    blue: (word: string) => (
                      <label className="tab-epargner__montant-epargne">
                        {word}
                      </label>
                    ),
                  }
                )}
              </SGText>
            </SGGridCol>
            <SGGridCol span={12}>
              <SGBox margin={{top: "sm"}}>
                <SGGridRow>
                  <SGGridCol span={1}>
                    <SGIcon
                      size="s"
                      currentcolor
                      component={<SGAvenirStyledEpargne />}
                    />
                  </SGGridCol>
                  <SGGridCol span={4}>
                    <RoundedSalary
                      salary={montantEpargne}
                      setSalary={setMontantEpargne}
                      maxValueInput={maxEpargneInput}
                      disabled={!possedeDroitsActionUtilisateur(supervisionState)}
                      disableautomargin
                    />
                  </SGGridCol>
                </SGGridRow>
              </SGBox>
            </SGGridCol>
            <SGGridCol offset={6} align="start">
              <SGText weight="600" size="s" style={{ color: "#FFFFFF" }}>
                {intl.formatMessage(
                  {
                    id: "parcoursEpargner.comparer.videoSection.text",
                  },
                  {
                    linebreak: <br />,
                  }
                )}
              </SGText>
            </SGGridCol>
            <SGGridCol span={2}>
              <BoutonSGAgenda
                type="primary"
                icon={
                  <SGIcon
                    component={<SGAvenirStrokedMesRendezVous />}
                    currentcolor
                  />
                }
                onClick={handleOnClickRdv}
                data-cy="disclaimer-submit"
                disableautomargin
              >
                {intl.formatMessage({
                  id: "parcoursEpargner.button.contacter",
                })}
              </BoutonSGAgenda>
            </SGGridCol>
          </SGGridRow>
        </SGBox>
      </div>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </div>
  );
};

export { EpargnerComparerHaut };
