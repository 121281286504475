import { FunctionComponent, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Image } from "website/components/atoms/Image/Image";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { DASHBOARD } from "website/utils/privateRoutes";

const ForbiddenPage: FunctionComponent = () => {
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const history = useHistory();
    const { trackErrorPage, trackClick } = useTracking();

    useEffect(() => {
        trackErrorPage("erreur-acces-interdit", "acces-interdit");
    }, []);

    const handleOnClick = () => {
        trackClick("erreur-acces-interdit::clic-sur-actualiser");
        history.push(DASHBOARD);
    };

    return (
        <SGContent>
            <SGBox margin={{ bottom: "xl" }}>
                <SGGridRow align="middle">
                    <SGGridCol textalign="center" span={12} md={0}>
                        <Image src="images/erreurs/403-mob.svg" alt="Erreur 403" />
                    </SGGridCol>
                    <SGGridCol xs={12} md={6} textalign={isPhone ? "center" : "left"}>
                        <SGTitle level={1}>
                            <SGText color="info">
                                <FormattedMessage id="forbidden.title" />
                            </SGText>
                        </SGTitle>
                        <SGText>
                            <FormattedMessage id="forbidden.subtitle" />
                        </SGText>
                        <SGBox textalign={isPhone ? "center" : "left"} margin={{ top: "xl" }}>
                            <SGButton onClick={handleOnClick}>
                                <FormattedMessage id="forbidden.button" />
                            </SGButton>
                        </SGBox>
                    </SGGridCol>
                    <SGMediaQuery minwidth="md">
                        <SGGridCol textalign="right" span={6}>
                            <Image src="images/erreurs/403.svg" alt="Erreur 403" />
                        </SGGridCol>
                    </SGMediaQuery>
                </SGGridRow>
            </SGBox>
        </SGContent>
    );
};

export { ForbiddenPage };
