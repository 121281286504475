import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";

interface BoutonSupervisionProps {
  type?: string;
  size?: string;
  disableautomargin?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  disabled?: boolean;
  icon?: ReactNode;
  iconposition?: string;
  cypressName?: string;
  dataCy?: string;
  style?: any;
}

/**
 * Bouton pouvant être désactivé si l'utilisateur est en mode supervision de type consultation.
 */
export const BoutonSupervision: FunctionComponent<BoutonSupervisionProps> = (
  props: React.PropsWithChildren<BoutonSupervisionProps>
) => {
  const {
    type,
    size,
    disableautomargin,
    onClick,
    disabled,
    ariaLabel,
    icon,
    iconposition,
    cypressName,
    dataCy,
    style,
    children,
  } = props;

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  return (
    <SGButton
      type={type}
      size={size}
      disableautomargin={disableautomargin}
      onClick={onClick}
      disabled={!possedeDroitsActionUtilisateur(supervisionState) || disabled}
      ariaLabel={ariaLabel}
      icon={icon}
      iconposition={iconposition}
      cypressName={cypressName}
      data-cy={dataCy}
      style={style}
    >
      {children}
    </SGButton>
  );
};
