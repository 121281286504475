import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { sendRetirementChoice } from "store/retirementChoice/action";
import { ProfileChoice, RetirementProfileTrack, RetirementProfileType } from "store/retirementChoice/types";
import { State } from "store/store";
import {
    SupervisionState,
    possedeDroitsActionUtilisateur
} from "store/supervision/types";
import { nextStepAction, setIsTunnelReformeAction } from "store/tunnel/actions";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useFeatures } from "website/components/hooks/useFeatures";
import "./TunnelWelcome.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const TunnelWelcome: FunctionComponent = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { simulateurAvantApres } = useFeatures();
    const { trackClick } = useTracking();

    const supervisionState: SupervisionState = useSelector<
        State,
        SupervisionState
    >((state) => state.supervision);

    const retirementChoices = Object.keys(RetirementProfileType);
    const retirementChoicesColor = ["red", "brown", "blue", "orange"];
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const isTab = useMediaQuery({ minwidth: "sm", maxwidth: "lg" });

    const possedeDroitsAction = possedeDroitsActionUtilisateur(supervisionState);

    const handleRetirementChoice = (choice: RetirementProfileType) => {
        const profileChoice: ProfileChoice = {
            retirementProfileType: choice,
        };
        const trackingChoice = RetirementProfileTrack[choice];
        trackClick(`ma-retraite::clic-sur-${trackingChoice}`);
        dispatch(setIsTunnelReformeAction(false));
        dispatch(nextStepAction());
        dispatch(sendRetirementChoice(profileChoice));
    };

    const handleClicSimulerReforme = () => {
        trackClick(`ma-retraite::clic-sur-simuler-impact-de-la-reforme`);
        dispatch(setIsTunnelReformeAction(true));
        dispatch(nextStepAction());
    };

    return (
        <SGGridRow justify="center" gutter={[0, 32]}>
            <SGGridCol span={11} sm={12} textalign={isPhone ? "center" : "left"}>
                <SGTitle level={isTab ? 1 : 2} textalign="center">
                    {intl.formatMessage({ id: "tunnel.welcome.title" })}
                </SGTitle>
            </SGGridCol>
            <SGGridCol span={12} md={9} textalign={isPhone ? "center" : "left"}>
                <SGGridRow gutter={[10, 10]} align="stretch">
                    {retirementChoices.map((choice, index) => (
                        <SGGridCol key={choice} span={6}>
                            <div className={`tunnel-welcome__button ${!possedeDroitsAction ? "disabled" : ""} tunnel-welcome__button-${retirementChoicesColor[index]}`}>
                                <SGCard textalign="center" hoverable={possedeDroitsAction} clickable={possedeDroitsAction}>
                                    <BoutonSupervision
                                        type="tertiary"
                                        onClick={() => {
                                            handleRetirementChoice((RetirementProfileType as any)[choice]);
                                        }}
                                        style={{ height: "100%" }}
                                    >
                                        <SGBox margin={isPhone ? {} : { top: "lg", bottom: "lg" }} style={{ height: "100%" }}>
                                            <>
                                                <SGTitle level={3} colorlight>
                                                    {intl.formatMessage({ id: `retirementChoiceType.title.${choice}` })}
                                                </SGTitle>
                                                <SGText size="m" weight="400" color="lightest" style={{ fontFamily: "Montserrat" }}>
                                                    {intl.formatMessage({ id: `retirementChoiceType.subtitle.${choice}` }, { linebreak: <br /> })}
                                                </SGText>
                                            </>
                                        </SGBox>
                                    </BoutonSupervision>
                                </SGCard>
                            </div>
                        </SGGridCol>
                    ))}
                </SGGridRow>
            </SGGridCol>
            {simulateurAvantApres && (
                <SGGridCol span={12}>
                    <SGBox padding={{ top: "lg" }}>
                        <SGGridRow justify="center">
                            <SGGridCol span={12} md={8} textalign="center">
                                <FormattedMessage id="tunnel.reforme.description" />
                            </SGGridCol>
                            <SGGridCol span={12} textalign="center">
                                <BoutonSupervision onClick={handleClicSimulerReforme}>
                                    <FormattedMessage id="tunnel.reforme.button" />
                                </BoutonSupervision>
                            </SGGridCol>
                        </SGGridRow>
                    </SGBox>
                </SGGridCol>
            )}
        </SGGridRow>
    );
};
