export interface CoachState {
   advices: CoachAdvice[];
}

export interface CoachAdvice {
   intlId: string;
   type: CoachAdviceType;
   ctaIntlId?: string;
   transformations?: Record<string, string | React.ReactNode>;
   redirection?: string;
   isNew?: boolean;
}

// L'Enum est trié par ordre de priorité d'apparition
export enum CoachAdviceType {
   TUTORIAL = "TUTORIAL",
   SITUATION_WARNING = "SITUATION_WARNING",
   SIMULATION_WARNING = "SIMULATION_WARNING",
   FAQ = "FAQ",
   OTHER = "OTHER",
}

export const COMPUTE_COACH_ADVICES = "COMPUTE_COACH_ADVICES";
