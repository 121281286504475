import {SpendingProfile} from "./types";

export const getTotalIncomeSpendingPercentage = (spendingProfile: SpendingProfile) => {
   switch (spendingProfile) {
      case SpendingProfile.HIGH_SAVING:
         return 0.2;
      case SpendingProfile.NO_SAVING:
         return 0;
      case SpendingProfile.MEDIUM_SAVING:
      default:
         return 0.1;
   }
};
