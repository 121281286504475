import { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { DashboardState, hasFetchedDashboard } from "store/dashboard/types";
import { State } from "store/store";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { useAssets } from "website/components/hooks/useAssets";
import { DisclaimerAgircArrco } from "../DisclaimerAgircArrco/DisclaimerAgircArrco";
import { DisclaimerPatrimoine } from "../DisclaimerPatrimoine/DisclaimerPatrimoine";
import { SGBox } from "sg-space";

interface DisclaimerDashboardProps {
  setShowDisclaimerDashboard: (showDisclaimerDashboard: boolean) => void;
}

const DisclaimerDashboard: FunctionComponent<DisclaimerDashboardProps> = (
  props: DisclaimerDashboardProps
) => {
  const { setShowDisclaimerDashboard } = props;

  const { showDisclaimerPatrimoine } = useAssets();
  const { ageSansMalusPresent } = useSelectedRetirementAge();

  const dashboardState: DashboardState = useSelector<State, DashboardState>(
    (state) => state.dashboard
  );
  const hasFetched =
    showDisclaimerPatrimoine !== undefined &&
    hasFetchedDashboard(dashboardState);

  useEffect(() => {
    if (hasFetched) {
      setShowDisclaimerDashboard(
        (showDisclaimerPatrimoine === true &&
          dashboardState.dashboard.firstPatrimonyPathDate !== undefined) ||
          ageSansMalusPresent === true
      );
    }
  }, [
    showDisclaimerPatrimoine,
    ageSansMalusPresent,
    dashboardState.dashboard?.firstPatrimonyPathDate,
  ]);

  if (hasFetched) {
    // Permet d'éviter d'une popin afficher alors on n'a pas fetch
    if (
      showDisclaimerPatrimoine &&
      dashboardState.dashboard.firstPatrimonyPathDate !== undefined
    ) {
      return (
        <SGBox padding={{ top: "sm" }}>
          <DisclaimerPatrimoine idTracking="clic-sur-disclaimer-actualiser-mes-actifs" />
        </SGBox>
      );
    }
    if (ageSansMalusPresent) {
      return (
        <SGBox padding={{ top: "sm" }}>
          <DisclaimerAgircArrco />
        </SGBox>
      );
    }
  }

  return <></>;
};

export { DisclaimerDashboard };
