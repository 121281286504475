import React, {ChangeEvent, FunctionComponent, useEffect, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {getTaxSituationAction, updateMyTaxSystemAction, updatePartnerTaxSystemAction,} from "../../../../../store/tax/actions";
import {Tax, TaxSystem,} from "../../../../../store/tax/types";
import {SpinnerSize, SpinnerWrapper} from "../../../atoms/SpinnerWrapper/SpinnerWrapper";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {SGGridCol} from "sg-grid";
import {SGAccordion, SGAccordionPanel} from "sg-accordion";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SGCard} from "sg-card";
import {SGSpace} from "sg-space";
import {SGDivider} from "sg-divider";
import {SGBlock} from "sg-typo";
import {SGPopover} from "sg-popover";
import {SGButton} from "sg-button";
import {SGAvenirStatusInfo} from "sg-icon-pack-base";
import {SGIcon} from "sg-icon";
import {SGRadio, SGRadioGroup} from "sg-radio";
import {SGNotification} from "sg-notification";
import {CustomNumberFormat} from "website/utils/formatting/CustomNumberFormat";
import {useMyTaxSystem} from "../../../hooks/useMyTaxSystem";
import {usePartnerTaxSystem} from "../../../hooks/usePartnerTaxSystem";
import {useTaxSituation} from "../../../hooks/useTaxSituation";
import {updateAccountAction} from "../../../../../store/account/actions";
import {useAccount} from "../../../hooks/useAccount";
import {State} from "../../../../../store/store";

const TaxSimulator: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const [myTaxSystem, setMyTaxSystem] = useState<TaxSystem>(TaxSystem.FLAT_TAX);
   const [partnerTaxSystem, setPartnerTaxSystem] = useState<TaxSystem>(TaxSystem.FLAT_TAX);
   const [displayTaxSystemUpdated, setDisplayTaxSystemUpdated] = useState<boolean>(false);

   const taxSituation = useTaxSituation(true);
   const myStoreTaxSystem = useMyTaxSystem();
   const partnerStoreTaxSystem = usePartnerTaxSystem();
   const account = useAccount()
   const hasFetchedTaxSituation = useSelector<State, boolean>((state) => state.tax.hasFetchedTaxSituation);
   const hasFetchedTaxSystems = useSelector<State, boolean>((state) => state.tax.hasFetchedTaxSystems);

   useEffect(() => {
      setDisplayTaxSystemUpdated(false);
      dispatch(getTaxSituationAction());
      if (!account.visitedTaxSimulator) {
         dispatch(updateAccountAction({...account, visitedTaxSimulator: true}));
      }
   }, []);

   useEffect(() => {
      setDisplayTaxSystemUpdated(hasFetchedTaxSystems && myStoreTaxSystem !== myTaxSystem);
      setMyTaxSystem(myStoreTaxSystem);
   }, [myStoreTaxSystem, hasFetchedTaxSystems]);

   useEffect(() => {
      setDisplayTaxSystemUpdated(hasFetchedTaxSystems && partnerStoreTaxSystem !== partnerTaxSystem);
      setPartnerTaxSystem(partnerStoreTaxSystem);
   }, [partnerStoreTaxSystem, hasFetchedTaxSystems]);

   return (
      <SpinnerWrapper spinnerSize={SpinnerSize.LG} displayComponent={hasFetchedTaxSituation} fatalErrorOnTimeout>
         <SGCard dark>
            <SGTitleIntl intlId="profile.calculated.taxation.title" cypressName="profile-calculated-tax-title"/>

            <SGAccordion disableContentMargin accordion={false} expandIconPosition="right">
               {/* Impôts sur le revenu */}
               <SGAccordionPanel header={<>
                  <SGTextIntl intlId="profile.tax.amount" size="m"/>
                  <CustomNumberFormat displayType="text" suffix="€" thousandSeparator=" "
                                      value={taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.incomeTaxAmount, 0)}/>

               </>
               }>
                  {/* Légende dans le cas du couple */}
                  {taxSituation.coupleIncomeTaxList[1] !== undefined &&
                     <SGSpace size="xl" disableautomargin>
                        <SGGridCol textalign="right">
                           <SGTextIntl intlId="family.status.ME"/>
                           <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                           <SGTextIntl intlId="family.status.PARTNER"/>
                        </SGGridCol>
                     </SGSpace>}
                  {/* Total impôts sur le revenu */}
                  <SGSpace size="xl" disableautomargin>
                     <SGTextIntl intlId="profile.tax.amount"/>

                     <SGGridCol textalign="right">
                        <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                            value={taxSituation.coupleIncomeTaxList[0].incomeTaxAmount}/>
                        {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                           <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                           <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" " width={200} size={200}
                                               value={taxSituation.coupleIncomeTaxList[1].incomeTaxAmount}/>
                        </>}
                     </SGGridCol>
                  </SGSpace>
                  {/* Réductions fiscales */}
                  {taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.incomeTaxAmount, 0) !== taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.grossTaxAmount, 0) && (
                     <>
                        <SGSpace size="xl" disableautomargin>
                           <SGTextIntl intlId="profile.tax.gross.amount"/>
                           <SGGridCol textalign="right">
                              <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                                  value={taxSituation.coupleIncomeTaxList[0].grossTaxAmount}/>
                              {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                                 <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                                 <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                                     value={taxSituation.coupleIncomeTaxList[1].grossTaxAmount}/>
                              </>}
                           </SGGridCol>
                        </SGSpace>
                        <SGSpace size="xl" disableautomargin>
                           <SGTextIntl intlId="profile.tax.deduction"/>
                           <SGGridCol textalign="right">
                              <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                                  value={-taxSituation?.coupleIncomeTaxList[0].taxDeduction -
                                                     taxSituation?.coupleIncomeTaxList[0].donationAmountDeduction -
                                                     taxSituation?.coupleIncomeTaxList[0].employeeAtHomeAmountDeduction -
                                                     taxSituation?.coupleIncomeTaxList[0].childCareAmountDeduction}/>
                              {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                                 <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                                 <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                                     value={-taxSituation?.coupleIncomeTaxList[1].taxDeduction -
                                                        taxSituation?.coupleIncomeTaxList[1].donationAmountDeduction -
                                                        taxSituation?.coupleIncomeTaxList[1].employeeAtHomeAmountDeduction -
                                                        taxSituation?.coupleIncomeTaxList[1].childCareAmountDeduction}/>
                              </>}
                           </SGGridCol>
                        </SGSpace>
                     </>
                  )}

                  <SGDivider/>

                  {/* TMI */}
                  {taxSituation.coupleIncomeTaxList[0] !== undefined &&
                     <SGSpace size="xl">
                        <SGTextIntl intlId="profile.tax.tmi"/>
                        <SGGridCol textalign="right">
                           <CustomNumberFormat displayType="text" suffix=" %" value={taxSituation.coupleIncomeTaxList[0].marginalTaxRate}/>
                           {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                              <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                              <CustomNumberFormat displayType="text" suffix=" %" value={taxSituation.coupleIncomeTaxList[1].marginalTaxRate}/>
                           </>}
                        </SGGridCol>
                     </SGSpace>}

                  {/* Taux réel */}
                  {taxSituation.coupleIncomeTaxList[0] !== undefined &&
                     <SGSpace size="xl" disableautomargin>
                        <SGTextIntl intlId="profile.tax.rate"/>
                        <SGGridCol textalign="right">
                           <CustomNumberFormat displayType="text" suffix=" %"
                                               value={taxSituation.coupleIncomeTaxList[0].percentageOfTheGlobalIncome}/>
                           {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                              <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                              <CustomNumberFormat displayType="text" suffix=" %"
                                                  value={taxSituation.coupleIncomeTaxList[1].percentageOfTheGlobalIncome}/>
                           </>}
                        </SGGridCol>
                     </SGSpace>}

                  {/* Nombre de parts */}
                  {taxSituation.coupleIncomeTaxList[0] !== undefined &&
                     <SGSpace size="xl" disableautomargin>
                        <SGTextIntl intlId="profile.tax.number.parts"/>
                        <SGGridCol textalign="right">
                           <CustomNumberFormat decimalScale={1} fixedDecimalScale decimalSeparator="," displayType="text"
                                               value={taxSituation.coupleIncomeTaxList[0].numberOfParts}/>
                           {taxSituation.coupleIncomeTaxList[1] !== undefined && <>
                              <SGDivider type="vertical" className="profile-taxation__slider-vertical"/>
                              <CustomNumberFormat decimalScale={1} fixedDecimalScale decimalSeparator="," displayType="text"
                                                  value={taxSituation.coupleIncomeTaxList[1].numberOfParts}/>
                           </>}
                        </SGGridCol>
                     </SGSpace>}

                  {/* Prélèvements sociaux sur revenus locatifs */}
                  {taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.socialTaxOnRentalIncomes, 0) !== 0 &&
                     <SGSpace size="xl" disableautomargin>
                        <SGBlock>
                           <SGTextIntl intlId="profile.tax.social-rental"/>
                           <SGPopover trigger="hover" content={<SGTextIntl intlId="profile.tax.social-rental.tooltip"/>} placement="right">
                              <SGButton type="icon" icon={<SGIcon component={<SGAvenirStatusInfo/>}/>} tabIndex="-1"/>
                           </SGPopover>
                        </SGBlock>
                        <SGGridCol textalign="right">
                           <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                               value={taxSituation.coupleIncomeTaxList.reduce((sum: number, cur: Tax) => sum + cur.socialTaxOnRentalIncomes, 0)}/>
                        </SGGridCol>
                     </SGSpace>}
               </SGAccordionPanel>

               {/* IFI */}
               <SGAccordionPanel
                  header={<>
                     <SGTextIntl intlId="profile.tax.ifi" size="m"/>
                     <CustomNumberFormat displayType="text" suffix="€" thousandSeparator=" "
                                         value={taxSituation.ifiTaxAmount}/>
                  </>
                  }>
                  {(taxSituation.ifiTaxAmount === 0 ||
                     taxSituation.ifiTaxAmount === undefined) && (
                     <SGTextIntl intlId="profile.tax.no.ifi"/>
                  )}
                  {taxSituation.ifiTaxAmount !== 0 &&
                     <SGSpace size="xl" disableautomargin>
                        <SGTextIntl intlId="profile.tax.ifi"/>
                        <SGGridCol textalign="right">
                           <CustomNumberFormat displayType="text" suffix=" €" thousandSeparator=" "
                                               value={taxSituation.ifiTaxAmount}/>
                        </SGGridCol>
                     </SGSpace>}
               </SGAccordionPanel>
            </SGAccordion>
         </SGCard>
         <SGCard dark>
            <SGTitleIntl intlId="profile.tax.types" cypressName="profile-tax-types-title"/>
            <SGTextIntl intlId="profile.tax.system.description"/>
            <SGSpace direction="vertical">
               <SGRadioGroup legend={<SGTextIntl intlId="family.status.ME"/>} disableautomargin
                             value={myTaxSystem} buttonstyle="tile" layout="column"
                             onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                dispatch(updateMyTaxSystemAction(event.target.value))
                             }}
                             extra={{label: <SGTextIntl intlId="profile.tax.system.info" size="xs"/>}}>
                  <SGRadio value={TaxSystem.FLAT_TAX}>
                     <SGTextIntl intlId="profile.tax.system.FLAT_TAX"/>
                  </SGRadio>
                  <SGRadio value={TaxSystem.INCOME_TAX}>
                     <SGTextIntl intlId="profile.tax.system.INCOME_TAX"/>
                  </SGRadio>
               </SGRadioGroup>
               {taxSituation.coupleIncomeTaxList?.length !== 1 && (
                  <SGRadioGroup legend={intl.formatMessage({id: "family.status.PARTNER"})} value={partnerTaxSystem}
                                buttonstyle="tile" disableautomargin layout="column"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   dispatch(updatePartnerTaxSystemAction(event.target.value))
                                }}>
                     <SGRadio value={TaxSystem.FLAT_TAX}>
                        <SGTextIntl intlId="profile.tax.system.FLAT_TAX"/>
                     </SGRadio>
                     <SGRadio value={TaxSystem.INCOME_TAX}>
                        <SGTextIntl intlId="profile.tax.system.INCOME_TAX"/>
                     </SGRadio>
                  </SGRadioGroup>
               )}
            </SGSpace>
         </SGCard>
         <SGNotification show={displayTaxSystemUpdated} message={intl.formatMessage({id: "profile.tax.system.updated"})}
                         type="success" onClose={() => setDisplayTaxSystemUpdated(false)} placement="bottom"
         />
      </SpinnerWrapper>
   );
};

export {TaxSimulator};
