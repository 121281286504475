import React, {FunctionComponent, useEffect, useState,} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import Slider from "rc-slider";
import {useIntl} from "react-intl";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {createProjectAction} from "../../../../../store/projects/actions";
import {ProjectType} from "../../../../../store/projects/types";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {calculateAgeByBirthdate, getMembersOfCouple, MAX_AGE_FOR_CHANGE_LIFE} from "../../../../../store/members/utils";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {useFamily} from "../../../hooks/useFamily";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGCard} from "sg-card";
import {SGGridCol, SGGridRow} from "sg-grid";
import {SGContent} from "sg-layout";
import {Member} from "../../../../../store/members/types";

interface TravelProjectFormData {
   duration: number,
   horizon: string,
   amount: number
}

interface SabbaticalLeaveProjectFormData {
   duration: number,
   horizon: string,
   memberId: string
}

interface LeaveProjectProps {
   type: "travel" | "sabbatical-leave";
}

const LeaveProject: FunctionComponent<LeaveProjectProps> = ({type}: LeaveProjectProps) => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const [maxHorizon, setMaxHorizon] = useState<number>(20);
   const [maxHorizonPlusDuration, setMaxHorizonPlusDuration] = useState<number>(25);

   const family = useFamily();

   useEffect(() => {
      if (family) {
         getMembersOfCouple(family).forEach((m) => {
            if (m.birthday && maxHorizonPlusDuration > MAX_AGE_FOR_CHANGE_LIFE - calculateAgeByBirthdate(m.birthday)) {
               setMaxHorizonPlusDuration(MAX_AGE_FOR_CHANGE_LIFE + 1 - calculateAgeByBirthdate(m.birthday));
               setMaxHorizon(MAX_AGE_FOR_CHANGE_LIFE + 1 - calculateAgeByBirthdate(m.birthday));
            }
         });
      }
   }, [family]);

   const [projectHorizon, setProjectHorizon] = useState<number>(1);
   const [projectDuration, setProjectDuration] = useState<number>(12);

   const onHorizonChange = (horizon: number) => {
      setProjectHorizon(horizon);
   };
   const onDurationChange = (months: string) => {
      setProjectDuration(parseInt(months));
   };

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   const isLeaveTooFar = (): boolean => Number(projectHorizon) + Number(projectDuration / 12) > maxHorizonPlusDuration;

   const onSubmitTravelProject = (data: TravelProjectFormData) => {
      dispatch(
         createProjectAction({
            durationInMonths: data.duration,
            futureExpenses: data.amount,
            futureIncomes: [],
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectName: intl.formatMessage(
               {id: "project.travel.project-title"},
               {duration: data.duration}
            ),
            totalFutureIncome: 0,
            projectType: ProjectType.CHANGE_LIFE_PROJECT,
         })
      );
   };

   const onSubmitSabbaticalLeaveProject = (data: SabbaticalLeaveProjectFormData) => {
      const concernedMember: Partial<Member> | undefined = getMembersOfCouple(family).filter((member) => member.id === parseInt(data.memberId))[0];

      dispatch(
         createProjectAction({
            durationInMonths: data.duration,
            futureExpenses: null,
            futureIncomes: [{memberId: concernedMember.id, futureIncome: 0}],
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectType: ProjectType.CHANGE_LIFE_PROJECT,
            projectName: intl.formatMessage(
               {id: "project.sabbatical-leave.project-title"},
               {duration: data.duration}
            ),
            totalFutureIncome: null,
         })
      );
   };

   const availableMonthsNumbersBeforeLeave = [12, 24];

   return (
      <>
         <SGTitleIntl intlId={`project.${type}.title`} cypressName={`project-${type}-title`}/>
         <SGTextIntl intlId={`project.${type}.subtitle`}/>
         <FormProvider {...methods}>
            <form>
               <SGCard data-cy={`${type}-project-input-frame`}>
                  <FormInputs colLength={10}>
                     <SGTextIntl intlId={`project.${type}.form.horizon`}/>
                     <SGGridRow align="middle">
                        <SGGridCol span={9} xs={7}>
                           {/* @ts-ignore */}
                           <Slider min={0} max={maxHorizon} value={projectHorizon} onChange={(value) => {
                              setProjectHorizon(value);
                              methods.setValue("horizon", value, {
                                 shouldValidate: true,
                              });
                           }} railStyle={{height: 9}} handleStyle={{height: 20, width: 20, marginTop: -6}} trackStyle={{height: 9}} step={1}/>
                        </SGGridCol>
                        <SGGridCol span={3} xs={5}>
                           <NumericInput cypressName={`${type}-project-horizon-input`} suffix={intl.formatMessage({id: "common.years"})} name="horizon"
                                         required={false} defaultValue={projectHorizon} onValueChange={onHorizonChange} min={0} max={maxHorizon}
                                         placeholder={intl.formatMessage({id: "project.leave.horizon.placeholder"})}/>
                        </SGGridCol>
                     </SGGridRow>

                     <SGTextIntl intlId={`project.${type}.form.horizon.info`}/>
                     {type === "travel" &&
                        <NumericInput cypressName="travel-project-amount-input" suffix={intl.formatMessage({id: "common.euro.per.month"})} name="amount"
                                      required min={0} max={15_000} label="project.travel.form.amount"
                                      placeholder={intl.formatMessage({id: "project.travel.form.amount.placeholder"})}/>
                     }
                     <SelectInput name="duration" required onValueChange={onDurationChange} defaultValue={projectDuration}
                                  cypressName={`${type}-project-duration-input`} label={`project.${type}.form.duration`}>
                        {availableMonthsNumbersBeforeLeave.map(
                           (months: number) =>
                              <SGSelectOption key={months} data-cy={`${type}-project-duration-input-select-${months}`} value={months}>
                                 {`${months} ${intl.formatMessage({id: "common.month"})}`}
                              </SGSelectOption>
                        )}
                     </SelectInput>

                     {type === "sabbatical-leave" &&
                        <SelectInput name="memberId" required placeholder={intl.formatMessage({id: "common.choose.member"})}
                                     cypressName="sabbatical-leave-project-member-input"
                                     label="project.sabbatical-leave.form.member">
                           {getMembersOfCouple(family).map(
                              (member) => (
                                 <SGSelectOption key={member.id} value={member.id}>
                                    {member.name}
                                 </SGSelectOption>
                              )
                           )}
                        </SelectInput>
                     }

                  </FormInputs>
                  {isLeaveTooFar() && (
                     <SGTextIntl intlId={`project.${type}.form.duration.error`} color="error" cypressName={`${type}-project-error`}/>
                  )}
               </SGCard>

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid || isLeaveTooFar()} cypressName={`${type}-next`}
                                onClick={type === "sabbatical-leave" ? methods.handleSubmit(onSubmitSabbaticalLeaveProject) : methods.handleSubmit(onSubmitTravelProject)}
                                intlId="new.project.create"/>
                  <SGButtonIntl type="link" cypressName={`${type}-previous`} onClick={() => redirectToRelativePath(NEW_PROJECTS)} intlId="common.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>
      </>
   );
};

export {LeaveProject};
