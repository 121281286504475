import React, {FunctionComponent} from "react";
import {FamilyEditor} from "../../Editors/FamilyEditor/FamilyEditor";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {EditorContextProvider} from "../../../providers/EditorProvider";
import {SGSpace} from "sg-space";

const ProfileFamily: FunctionComponent = () => (
   <SGSpace direction="vertical">
      <SGTitleIntl intlId="profile.family.situation.title" cypressName="profile-family-title"/>
      <EditorContextProvider>
         <FamilyEditor/>
      </EditorContextProvider>
   </SGSpace>
);

export {ProfileFamily};
