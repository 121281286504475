import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {getSavingCapacityAction} from "../../../store/savingCapacity/actions";
import {SavingCapacityState} from "../../../store/savingCapacity/types";

const useSavingCapacity = () => {
   const dispatch = useDispatch();
   const savingCapacityState: SavingCapacityState = useSelector<State, SavingCapacityState>((state) => state.savingCapacity);

   useEffect(() => {
      if (!savingCapacityState.hasFetched) {
         dispatch(getSavingCapacityAction());
      }
   }, []);

   return savingCapacityState.savingCapacity;
};

export {useSavingCapacity};
