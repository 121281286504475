import { AxiosResponse } from "axios";
import { call, put, takeLeading } from "redux-saga/effects";
import { apiPost } from "store/apiCaller";
import { onDefaultError, safe } from "store/error/utils";
import { ProfileChoice, RETIREMENT_PROFIL_CHOICE_FETCHED } from "store/retirementChoice/types";
import { SEND_RETIREMENT_CHOICE } from "./types";

export default function* watcherSaga() {
   yield takeLeading(SEND_RETIREMENT_CHOICE, safe(onDefaultError, handleSendRetirementChoice));
}

function* handleSendRetirementChoice(action: any) {
   const payload: AxiosResponse<ProfileChoice> = yield call(apiPost, `wealth/api/retirement/profile-choice`, action.payload);
   yield put({ type: RETIREMENT_PROFIL_CHOICE_FETCHED, payload });
}
