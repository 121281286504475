import React, {FunctionComponent, useEffect, useState} from "react";
import {SGContent} from "sg-layout";
import {SGTitleIntl} from "../components/atoms/SGTitleIntl/SGTitleIntl";
import {SGGridCol, SGGridRow} from "sg-grid";
import {LIFE_PROJECTS, Project, ProjectFeasibility, ProjectPlanStatus, ProjectState, ProjectType} from "../../store/projects/types";
import {SGCard} from "sg-card";
import {SGButtonIntl} from "../components/atoms/SGButtonIntl/SGButtonIntl";
import {SGIcon} from "sg-icon";
import {SGAvenirNavPlus} from "sg-icon-pack-base";
import {useNumberProjects} from "../components/hooks/useNumberProjects";
import {NEW_PROJECTS} from "../utils/privateRoutes";
import {useHistory} from "react-router-dom";
import {useProjects} from "../components/hooks/useProjects";
import {SGCarousel} from "sg-carousel";
import {useProjectsFeasibilities} from "../components/hooks/useProjectsFeasibilities";
import {ProjectConfiguration} from "../components/molecules/ProjectConfiguration/ProjectConfiguration";
import {SpinnerSize, SpinnerWrapper} from "../components/atoms/SpinnerWrapper/SpinnerWrapper";
import {SGAlertIntl} from "../components/molecules/SGAlertIntl/SGAlertIntl";
import {SGTextIntl} from "../components/atoms/SGTextIntl/SGTextIntl";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/store";
import {ProjectMeteo} from "website/components/molecules/ProjectMeteo/ProjectMeteo";
import {ProjectActionPlan} from "../components/molecules/ProjectActionPlan/ProjectActionPlan";
import {ProjectKOAdvices} from "../components/molecules/ProjectKOAdvices/ProjectKOAdvices";
import {ProjectKORetirementSummary} from "../components/molecules/ProjectKORetirementSummary/ProjectKORetirementSummary";
import {useUrlParams} from "../components/hooks/useUrlParams";
import {updateIsRetirementProjectAutomaticallyUpdated} from "../../store/projects/projectsSlice";
import {SGSpace} from "sg-space";
import {GoFurther} from "../components/molecules/GoFurther/GoFurther";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const ProjectsPage: FunctionComponent = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const [currentProjectIdFromUrl] = useUrlParams("currentProject");
   const numberProjects = useNumberProjects();
   const projects = useProjects(false, true);
   const projectFeasibilities = useProjectsFeasibilities(true);
   const hasFetchedProjects = useSelector<State, boolean>((state) => state.projects.hasFetchedProjects);
   const hasFetchedFeasibilities = useSelector<State, boolean>((state) => state.projects.hasFetchedFeasibilities);
   const { trackClick, trackPage } = useTracking();

   const projectState: ProjectState = useSelector<State, ProjectState>((state) => state.projects);

   const [projectsSorted, setProjectsSorted] = useState<Project[]>(projects);
   const [currentFeasibilityDisplayed, setCurrentFeasibilityDisplayed] = useState<ProjectFeasibility>(projectFeasibilities[0]);
   const [currentProjectDisplayed, setCurrentProjectDisplayed] = useState<Project>(projects[0]);

   useEffect(() => {
      // ici hasFetchedProjects et projects sont modifiés par la même action on passe donc par l'objet parent pour récuperer les deux modifications en une fois
      if (projectState.hasFetchedProjects) {
         const projects = projectState.projects.filter((project) => LIFE_PROJECTS.includes(project.projectType));
         if (!currentProjectDisplayed || !projects.some((p) => p.id === currentProjectDisplayed.id) || currentProjectIdFromUrl) {
            if (currentProjectIdFromUrl) {
               setProjectsSorted(projects.sort((p) => (p.id === Number(currentProjectIdFromUrl)) ? -1 : 1));
               setCurrentProjectDisplayed(projects.sort((p) => (p.id === Number(currentProjectIdFromUrl)) ? -1 : 1)[0])
            } else {
               setProjectsSorted(projects);
               setCurrentProjectDisplayed(projects[0]);
            }
         }
      }
   }, [projectState, currentProjectIdFromUrl]);

   useEffect(() => {
      if (projectState.isRetirementProjectAutomaticallyUpdated) {
         dispatch(updateIsRetirementProjectAutomaticallyUpdated(false));
      }
   }, [projectState]);

   useEffect(() => {
      if (hasFetchedFeasibilities && hasFetchedProjects && !!currentProjectDisplayed) {
         setCurrentFeasibilityDisplayed(projectFeasibilities.filter((f) => f.projectId === currentProjectDisplayed.id)[0]);
      }
   }, [currentProjectDisplayed, hasFetchedFeasibilities, hasFetchedProjects, projectFeasibilities]);

   useEffect(() => {
      if (hasFetchedProjects && numberProjects === 0 && !currentProjectIdFromUrl) {
         history.push(NEW_PROJECTS);
      }
   }, [numberProjects, hasFetchedProjects]);

   useEffect(() => {
      trackPage("projets", "accueil", "gestion-ecran");
   }, []);

   const goToAddProjectPage = () => {
      history.push(NEW_PROJECTS);
   };

   return (
      <SGContent>
         <SGTitleIntl intlId="projects.title" cypressName="projects-title"/>
         {numberProjects < 3 && hasFetchedProjects &&
            <SGCard clickable onClick={() => {
               goToAddProjectPage();
               trackClick("clic-sur-ajouter-un-projet");
            }}
                    bordered dark textalign="center">
               <SGButtonIntl type="tertiary" cypressName="add-new-project" icon={<SGIcon currentcolor component={<SGAvenirNavPlus/>}/>}
                             intlId="projects.add-project"/>
            </SGCard>
         }
         {numberProjects > 0 && hasFetchedProjects &&
            <SpinnerWrapper displayComponent={hasFetchedProjects} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
               <SGCarousel infinite afterChange={(index: number) => setCurrentProjectDisplayed(projectsSorted[index])}>
                  {projectsSorted && projectsSorted.map((project: Project) => (
                     <SGGridRow align="center" key={project.id}>
                        <SGGridCol span={10} xs={12}>
                           <SGCard data-cy="project-configuration-frame" bordered>
                              <SGSpace direction="vertical">
                                 <ProjectConfiguration project={project}/>
                                 {!!currentFeasibilityDisplayed && !!currentProjectDisplayed &&
                                    <SpinnerWrapper displayComponent={!!currentFeasibilityDisplayed && !!currentProjectDisplayed} spinnerSize={SpinnerSize.LG}
                                                    fatalErrorOnTimeout={false}>
                                       <ProjectMeteo projectFeasibility={currentFeasibilityDisplayed}/>
                                    </SpinnerWrapper>}
                              </SGSpace>
                           </SGCard>
                        </SGGridCol>
                     </SGGridRow>
                  ))}
               </SGCarousel>
               {projectState.hasFetchedFeasibilitiesError && (
                  <SGAlertIntl type="warning" cypressName="notification-frame-project-calculation-error" title="common.warning">
                     <SGTextIntl intlId="project.configuration.calculation.error"/>
                  </SGAlertIntl>
               )}
               {!projectState.hasFetchedFeasibilitiesError &&
                  <SpinnerWrapper displayComponent={!!currentFeasibilityDisplayed && !!currentProjectDisplayed} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
                     {!!currentFeasibilityDisplayed && !!currentProjectDisplayed &&
                        <>
                           {currentProjectDisplayed.projectType === ProjectType.RETIREMENT_PROJECT && currentFeasibilityDisplayed.plan.status !== ProjectPlanStatus.KO &&
                              <ProjectKORetirementSummary/>
                           }
                           {currentFeasibilityDisplayed.plan.status !== ProjectPlanStatus.KO && currentProjectDisplayed.projectType !== ProjectType.RECEIVED_DONATION &&
                              <>
                                 <ProjectActionPlan projectFeasibility={currentFeasibilityDisplayed}/>
                                 <GoFurther cypressParentName="project-feasibility-ok"/>
                              </>
                           }
                           {currentFeasibilityDisplayed.plan.status === ProjectPlanStatus.KO &&
                              <ProjectKOAdvices projectFeasibility={currentFeasibilityDisplayed}/>
                           }
                        </>}
                  </SpinnerWrapper>
               }
            </SpinnerWrapper>
         }
      </SGContent>
   );
}

export {ProjectsPage};
