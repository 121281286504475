import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMembersAction } from "store/members/actions";
import { updateFamilyStore } from "store/members/membersSlice";
import {
    CustodyType,
    Family,
    FamilyStatus,
    MaritalStatus,
    MatrimonialRegime,
    Me,
    Member,
    RetirementCategory,
    RetirementCategorySimulation,
} from "store/members/types";
import { isCoupleMaritalStatus, isIsolatedParentStatus } from "store/members/utils";
import { savePersonalInformations } from "store/personalInformations/action";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { updateRetirementSimulation } from "store/simulationAssu/actions";
import { RetirementSimulationParameters } from "store/simulationAssu/types";
import { nextStepAction } from "store/tunnel/actions";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { formatDateSimulation } from "website/utils/date/DateUtils";
import { FamilySituation } from "../../FamilySituation/FamilySituation";

const SimulateurFlashFamilySituation: FunctionComponent = () => {
    const dispatch = useDispatch();

    const family = useFamily();

    const [numberChild, setNumberChild] = useState(0);
    const [retirementType, setRetirementType] = useState<RetirementCategory>();
    const [categorieActive, setCategorieActive] = useState<CategorieActiveChoix>(CategorieActiveChoix.NON);
    const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>(MaritalStatus.SINGLE);
    const [dateOfBirth, setDateOfBirth] = useState<Date>();
    const { personalInformationsState } = usePersonalInformations();
    const { trackClick, trackPage } = useTracking();

    useEffect(() => {
        dispatch(getMembersAction());
        trackPage("simulateur-flash", "ma-situation", PAGE_TYPE_FORMULAIRE, "simulation", "1");
    }, []);

    useEffect(() => {
        if (personalInformationsState.hasFetched) {
            setCategorieActive(personalInformationsState.personalInformations.categorieActive ? CategorieActiveChoix.OUI : CategorieActiveChoix.NON);
        }
    }, [personalInformationsState.hasFetched]);

    const onSubmit = () => {
        const newMe: Me = {
            ...family.me,
            retirementType,
            maritalStatus,
        };
        const familyToDispatch: Family = {
            children: [],
            me: { ...newMe, matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS },
            relatives: [],
        };
        let ordinal = 0;
        for (let i = 0; i < numberChild; i += 1) {
            ordinal += 1;
            const custodyType =
                isIsolatedParentStatus(maritalStatus) || maritalStatus === MaritalStatus.WIDOWER ? CustodyType.FULL_CUSTODY_ME : CustodyType.FULL_CUSTODY;
            const child: Member = {
                name: `Mon enfant ${ordinal}`,
                status: FamilyStatus.CHILD,
                custodyType,
            } as Member;
            familyToDispatch.children.push(child);
        }
        if (isCoupleMaritalStatus(maritalStatus)) {
            const partner: any = { ...family.partner, matrimonialRegime: MatrimonialRegime.COMMUNAUTE_LEGALE_REDUITE_AUX_ACQUETS };
            familyToDispatch.partner = partner;
        }

        const retirementSimulationParametersToDispatch: Partial<RetirementSimulationParameters> = {
            nbEnfant: numberChild,
            dateNaissance: dateOfBirth ? formatDateSimulation(dateOfBirth) : "01-01-1970",
            secteurPro: retirementType && RetirementCategorySimulation[retirementType],
        };

        dispatch(updateRetirementSimulation(retirementSimulationParametersToDispatch));

        dispatch(
            savePersonalInformations({
                ...personalInformationsState.personalInformations,
                categorieActive: categorieActive === CategorieActiveChoix.OUI,
            })
        );
        dispatch(updateFamilyStore(familyToDispatch));
        trackClick("ma-situation::clic-sur-suivant");
        dispatch(nextStepAction());
    };

    return (
        <FamilySituation
            onSubmit={onSubmit}
            numberChild={numberChild}
            setNumberChild={setNumberChild}
            retirementType={retirementType}
            setRetirementType={setRetirementType}
            setDateOfBirth={setDateOfBirth}
            setMaritalStatus={setMaritalStatus}
            categorieActive={categorieActive}
            setCategorieActive={setCategorieActive}
        />
    );
};

export { SimulateurFlashFamilySituation };
