import React, {FunctionComponent, ReactNode, useState} from "react";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGModal} from "sg-modal";
import {SGMessage} from "sg-message";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGIcon} from "sg-icon";
import {SGTitleIntl} from "../../atoms/SGTitleIntl/SGTitleIntl";
import {SGAvenirStyledPrendreContact} from "sg-icon-pack-base";
import {SGSpace} from "sg-space";

interface InfoModalProps {
   titleId: string;
   embeddedContent?: ReactNode | undefined;
   textId?: string;
   cypressName: string;
   transformations?: Record<string, string | React.ReactNode>;
   onHide?: () => void;
}

const InfoModal: FunctionComponent<InfoModalProps> = (props: InfoModalProps) => {
   const {titleId, textId, cypressName, embeddedContent, transformations, onHide} = props;

   const [showModal, setShowModal] = useState<boolean>(true);

   const hide = () => {
      setShowModal(false);
      if (onHide) {
         onHide();
      }
   };

   return (
      <>
         {showModal && (
            <SGModal visible onCancel={hide}>
               <SGMessage
                  extra={<SGButtonIntl type="primary" size="sm" cypressName={`${cypressName}-ok`} onClick={hide} intlId="common.ok"/>}
                  icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
                  title={<SGTitleIntl intlId={titleId} cypressName="info-modal-title"/>}>
                  <SGSpace direction="vertical" textalign="left">
                     {textId && (
                        <SGTextIntl intlId={textId} transformations={transformations} cypressName={`${cypressName}-text`}/>
                     )}
                     {!!embeddedContent && embeddedContent}
                  </SGSpace>
               </SGMessage>
            </SGModal>
         )}
      </>
   );
};

export {InfoModal};
