import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../store/store";
import {getRealizedDonationsAction} from "../../../store/realizedDonations/actions";
import {RealizedDonationState} from "../../../store/realizedDonations/types";

const useRealizedDonations = () => {
   const dispatch = useDispatch();
   const realizedDonationState: RealizedDonationState = useSelector<State, RealizedDonationState>((state) => state.realizedDonations);

   useEffect(() => {
      if (!realizedDonationState.hasFetched) {
         dispatch(getRealizedDonationsAction());
      }
   }, []);

   return realizedDonationState.realizedDonations;
};

export {useRealizedDonations};
