import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGPaginationWrapper } from "sg-pagination";
import { SGBox } from "sg-space";
import { Article } from "website/components/hooks/articles";
import { useArticles } from "website/components/hooks/useArticles";
import { getIdTrackingFiltre, getIdTrackingOnglet, SousThematique, Thematique } from "website/components/molecules/Article/ArticleModel";
import { DecouverteServicesAccompagnement } from "website/components/molecules/DecouverteServicesAccompagnement/DecouverteServicesAccompagnement";
import { ARTICLE } from "website/utils/privateRoutes";
import { CarrouselALaUne } from "./CarrouselALaUne";
import { CarteArticleHorizontal } from "./CarteArticleHorizontal";
import { CarteArticleThematiqueHorizontale } from "./CarteArticleThematiqueHorizontale";
import { FiltreThematique, FiltresDesktop } from "./FiltresDesktop";
import { FiltresMobile } from "./FiltresMobile";
import { useTracking } from "website/components/hooks/tracking/useTracking";

interface OngletThematiqueProps {
    thematique: Thematique;
    updateMapPage: (onglet: string, page: number) => void;
}

export function getDateString(date: Date) {
    const dateString: string = date.toLocaleDateString("fr-FR", { year: "numeric", month: "long" });

    return dateString.slice(0, 1).toUpperCase() + dateString.slice(1);
}

const OngletThematique: FunctionComponent<OngletThematiqueProps> = (props: OngletThematiqueProps) => {
    const { thematique, updateMapPage } = props;

    const history = useHistory();
    const [filtres, setFiltres] = useState<FiltreThematique[]>([]);
    // Un seul filtre à la fois. Donc pas de gestion sur FiltreThematique
    const [filtreCourant, setFiltreCourant] = useState<SousThematique>(SousThematique.tous);

    const { articlesTries, sousThematiques } = useArticles();
    const [articles, setArticles] = useState<Article[]>([]);
    const [pageCourante, setPageCourante] = useState<number>(1);
    const nombreArticlesParPage = 6;
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
    const { trackClick } = useTracking();

    const handleOnClickLireArticle = (path: string, trackKey: string) => {
        trackClick(trackKey, { form_field_1: path });
        history.push(`${ARTICLE}${path}`);
    };

    const handleOnClickFiltre = (choix: SousThematique, isTracking = true) => {
        // Déselection
        const nouveauChoix = filtreCourant === choix ? SousThematique.tous : choix;
        // Ne pas tracker quand on clique sur le filtre "Tous" alors qu'on est déjà sur le filtre "Tous"
        if (isTracking && (filtreCourant !== SousThematique.tous || choix !== SousThematique.tous)) {
            trackClick(`${getIdTrackingOnglet(thematique)}::clic-sur-${filtreCourant === choix ? "desactiver" : "activer"}-filtre`, {
                form_field_2: getIdTrackingFiltre(choix),
            });
        }
        setFiltreCourant(nouveauChoix);

        setArticles(
            articlesTries.filter((article) => article.theme === thematique && (nouveauChoix === SousThematique.tous || article.sousThematique === nouveauChoix))
        );
        setPageCourante(1);
    };

    useEffect(() => {
        const articlesThematique = articlesTries.filter((article) => article.theme === thematique);
        setArticles(articlesThematique);

        if (sousThematiques && sousThematiques[thematique].length > 0) {
            setFiltres([
                { titre: SousThematique.tous, compteur: articlesThematique.length },
                ...sousThematiques[thematique].map((sousThematique: SousThematique) => ({
                    titre: sousThematique,
                    compteur: articlesThematique.filter((article: Article) => article.sousThematique === sousThematique).length,
                })),
            ]);
        }
    }, [articlesTries]);

    useEffect(() => {
        updateMapPage(thematique, pageCourante);
    }, [pageCourante]);

    return (
        <SGGridRow id="onglet-thematique__container">
            <SGMediaQuery minwidth="sm">
                <SGGridCol offset={2} span={8} textalign="center">
                    <FiltresDesktop filtres={filtres} onChangeFiltre={handleOnClickFiltre} filtreCourant={filtreCourant} />
                </SGGridCol>
            </SGMediaQuery>
            <SGMediaQuery minwidth={0} maxwidth="sm">
                <SGGridCol span={12} textalign="right">
                    <FiltresMobile filtres={filtres} onChangeFiltre={handleOnClickFiltre} filtreCourant={filtreCourant} thematique={thematique} />
                </SGGridCol>
            </SGMediaQuery>
            <SGGridCol span={12} md={8}>
                {filtreCourant === SousThematique.tous && (
                    <CarrouselALaUne
                        articles={articles.filter((article) => article.favoriThematique)}
                        handleOnClickLireArticle={handleOnClickLireArticle}
                        trackKeyArticle={`${getIdTrackingOnglet(thematique)}::a-la-une:clic-sur-lire-l-article`}
                        trackKeyVideo={`${getIdTrackingOnglet(thematique)}::a-la-une:clic-sur-lire-la-video`}
                    />
                )}
                <SGBox margin={{ bottom: "xl", top: "xl" }}>
                    <SGGridRow align="stretch" gutter={[0, isPhone ? 16 : 32]}>
                        {articles.slice((pageCourante - 1) * nombreArticlesParPage, pageCourante * nombreArticlesParPage).map((article) => (
                            <>
                                <SGMediaQuery minwidth="md" key={`carte-${article.id}`}>
                                    <SGGridCol span={12}>
                                        <CarteArticleThematiqueHorizontale
                                            article={article}
                                            handleOnClickLireArticle={handleOnClickLireArticle}
                                            trackKey={`${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                        />
                                    </SGGridCol>
                                    <SGGridCol span={12}>
                                        <SGDivider disableautomargin />
                                    </SGGridCol>
                                </SGMediaQuery>
                                <SGMediaQuery minwidth={0} maxwidth="sm" key={`carte-mob-${article.id}`}>
                                    <SGGridCol span={12}>
                                        <CarteArticleHorizontal
                                            article={article}
                                            handleOnClickLireArticle={handleOnClickLireArticle}
                                            afficherDate
                                            trackKey={`${getIdTrackingOnglet(thematique)}::clic-sur-lire-l-article`}
                                        />
                                    </SGGridCol>
                                </SGMediaQuery>
                            </>
                        ))}
                    </SGGridRow>
                </SGBox>
                {articles.length > nombreArticlesParPage && (
                    <SGPaginationWrapper
                        align="center"
                        defaultPageSize={nombreArticlesParPage}
                        current={pageCourante}
                        onChange={(page: number) => {
                            setPageCourante(page);
                            document.querySelector("#onglet-thematique__container")?.scrollIntoView({ behavior: "smooth" });
                        }}
                        total={articles.length}
                    />
                )}
            </SGGridCol>
            <SGGridCol span={12} md={4}>
                <DecouverteServicesAccompagnement onglet={getIdTrackingOnglet(thematique)} />
            </SGGridCol>
        </SGGridRow>
    );
};

export { OngletThematique };
