import React, {ChangeEvent, FunctionComponent, useEffect, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {FamilyStatus, Member,} from "../../../../../store/members/types";
import {State} from "../../../../../store/store";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {AddMemberModal} from "../../../molecules/AddMemberModal/AddMemberModal";
import {createProjectAction, getGivenDonationFeesAction, resetGivenDonationFeesAction,} from "../../../../../store/projects/actions";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {convertNumberToStringWithSeparators} from "../../../../utils/formatting/numberFormatter";
import {ProjectType} from "../../../../../store/projects/types";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {useFamily} from "../../../hooks/useFamily";
import {SGCheckBox} from "sg-checkbox";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGIcon} from "sg-icon";
import {SGAvenirNavPlus} from "sg-icon-pack-base";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SGBlock} from "sg-typo";
import {toMembers} from "../../../../../store/members/utils";
import {SGCard} from "sg-card";
import {SGSpace} from "sg-space";

interface GivenDonationProjectForm {
   horizon: number;
   amount: number;
}

const GivenDonationProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const family = useFamily();

   const transactionFees: number | undefined = useSelector<State, number | undefined>((state) => state.projects.givenDonationFees);

   const [concernedMember, setConcernedMember] = useState<Member | undefined>(undefined)
   const [payFees, setPayFees] = useState(false);

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange",
   });

   useEffect(() => {
      dispatch(resetGivenDonationFeesAction());
   }, []);

   useEffect(() => {
      if (family) {
         setAvailableMembersForDonation(toMembers(family).filter((m) => m.status !== FamilyStatus.ME && m.status !== FamilyStatus.PARTNER));
      }
   }, [family]);

   const availableYearsNumbersBeforeDonation = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];

   const [isAddMemberModalVisible, setAddMemberModalVisible] = useState(false);
   const [availableMembersForDonation, setAvailableMembersForDonation] = useState<Member[]>([]);

   const onSelectMember = (newMemberIndex: string) => {
      setConcernedMember(availableMembersForDonation[+newMemberIndex]);
   };

   const onAddNewMember = (member: Member) => {
      setAvailableMembersForDonation([...availableMembersForDonation, {...member},]);
      methods.setValue("member", availableMembersForDonation.length, {shouldValidate: true});
      setConcernedMember(member);
   };

   const goToNewProject = () => {
      redirectToRelativePath(NEW_PROJECTS);
   };

   const evaluateFees = () => {
      if (concernedMember) {
         dispatch(
            getGivenDonationFeesAction(
               methods.getValues("amount"),
               methods.getValues("horizon"),
               {...concernedMember, id: concernedMember.id === -1 ? undefined : concernedMember.id}
            )
         );
      }
   };

   const onSubmit = (data: GivenDonationProjectForm) => {
      if (concernedMember) {
         dispatch(
            createProjectAction({
               concernedMember,
               memberId: concernedMember.id ? concernedMember.id : null,
               projectType: ProjectType.GIVEN_DONATION,
               horizon: data.horizon,
               name: `Transmission à ${concernedMember?.name?.toLowerCase()}`,
               amount: data.amount,
               given: true,
               assetsAllocations: [],
               fees: payFees ? transactionFees : 0,
            })
         );
      }
   };

   return (
      <>
         <SGTitleIntl intlId="project.given-donation.title" cypressName="given-donation-project-title"/>
         <SGTextIntl intlId="project.given-donation.subtitle"/>
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
               <SGCard data-cy="project-given-donation-frame-input">
                  <FormInputs colLength={10}>
                     <SelectInput name="horizon" cypressName="given-donation-select-horizon" defaultValue={5} label="project.given-donation.form.horizon">
                        {availableYearsNumbersBeforeDonation.map(
                           (horizon: number) => (
                              <SGSelectOption key={horizon} value={horizon}>
                                 {horizon === 0
                                    ? intl.formatMessage({id: "common.now"})
                                    : horizon +
                                    intl.formatMessage({id: "common.years"})}
                              </SGSelectOption>
                           )
                        )}
                     </SelectInput>

                     <SGBlock>
                        <SelectInput name="member" cypressName="given-donation-select-member" required onValueChange={onSelectMember}
                                     label="project.given-donation.form.member" placeholder={intl.formatMessage({id: "common.choose.member"})}>
                           {availableMembersForDonation.map((member: Member, index: number) => (
                                 <SGSelectOption key={member.id} value={index}>
                                    {member.name}
                                 </SGSelectOption>
                              )
                           )}
                        </SelectInput>
                        <SGSpace disableautomargin direction="vertical">
                           <SGTextIntl intlId="member.not-in-list"/>
                           <SGButtonIntl type="tertiary" data-cy="given-donation-project-add-member" onClick={() => setAddMemberModalVisible(true)}
                                         icon={<SGIcon currentcolor component={<SGAvenirNavPlus/>}/>} intlId="common.add-member"/>
                        </SGSpace>
                     </SGBlock>

                     <NumericInput name="amount" suffix="€" cypressName="given-donation-amount" min={1} max={1_000_000} defaultValue={0}
                                   label="project.given-donation.form.amount"
                                   placeholder={intl.formatMessage({id: "project.given-donation.form.amount.placeholder"})}/>
                  </FormInputs>
               </SGCard>

               <SGButtonGroup align="center">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="project-given-donation-estimate-fees"
                                onClick={evaluateFees} intlId="project.given-donation.fees.evaluate"/>
               </SGButtonGroup>

               {transactionFees !== undefined && (
                  <SGAlertIntl cypressName="fees-alert" type="info" title="common.did.you.know">
                     <SGTextIntl intlId="project.given-donation.fees.estimate" transformations={{
                        estimate: convertNumberToStringWithSeparators(transactionFees)
                     }}/>
                     <p>
                        <SGTextIntl intlId="project.given-donation.fees.explanation"/>
                     </p>
                     <p>
                        <SGTextIntl intlId="project.given-donation.fees.more-information"/>
                        <SGButtonIntl type="link" onClick={() => {
                           (window.location.href = "https://www.service-public.fr/particuliers/vosdroits/F1404")
                        }} intlId="project.given-donation.fees.more-information.link"/>
                     </p>
                  </SGAlertIntl>
               )}

               {transactionFees !== undefined && transactionFees > 0 && (
                  <SGCheckBox name="payFees" data-cy="projects-given-donation-checkbox-fees"
                              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                 setPayFees(event.target.checked)
                              }>
                     <SGTextIntl intlId="project.given-donation.fees.pay"/>
                  </SGCheckBox>
               )}

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>

               <SGButtonGroup align="center" layout="column">
                  <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid || transactionFees === undefined}
                                cypressName="given-donation-next"
                                onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                  <SGButtonIntl type="link" cypressName="given-donation-previous" onClick={goToNewProject} intlId="common.previous"/>
               </SGButtonGroup>
            </form>
         </FormProvider>

         {isAddMemberModalVisible && (
            <AddMemberModal hideModal={() => setAddMemberModalVisible(false)} cypressName="given-donation-modal"
                            onAddNewMemberSubmit={onAddNewMember} displayFamilyType/>
         )}
      </>
   );
};

export {GivenDonationProject};
