import React, { FunctionComponent, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { SGBox } from "sg-space";
import { SGTabPane, SGTabs } from "sg-tabs";
import { Acquis } from "store/parcoursRisInformationsComplementaires/types";
import { RetirementAge } from "store/parcoursRisProjection/types";
import { CarteEstimation } from "../CarteEstimation/CarteEstimation";
import { CarteTrimestres } from "../CarteTrimestres/CarteTrimestres";
import { useTracking } from "website/components/hooks/tracking/useTracking";

type TabEstimationTrimestresProps = {
    dates: RetirementAge[];
    acquis: Acquis;
};

export const TabEstimationTrimestres: FunctionComponent<TabEstimationTrimestresProps> = (props: React.PropsWithChildren<TabEstimationTrimestresProps>) => {
    const { dates, acquis } = props;

    const intl = useIntl();
    const ONGLET_TRIMESTRES = "trimestres";
    const ONGLET_ESTIMATION = "estimation";
    const [ongletCourant, setOngletCourant] = useState<string>(ONGLET_TRIMESTRES);
    const { trackTab } = useTracking();

    const handleOnChangeOnglet = (onglet: string) => {
        setOngletCourant(onglet);
    };

    function getIdTrackingOnglet(onglet: string) {
        if (onglet === ONGLET_TRIMESTRES) {
            return "trimestres";
        }

        return "pension-estimee";
    }

    return (
        <SGTabs
            activeKey={ongletCourant}
            onChange={(onglet: string) => {
                trackTab(`resultats::clic-sur-onglet-${getIdTrackingOnglet(onglet)}`);
                handleOnChangeOnglet(onglet);
            }}
        >
            <SGTabPane ariaId={ONGLET_TRIMESTRES} tab={intl.formatMessage({ id: `parcoursRIS.resultat.tab.${ONGLET_TRIMESTRES}` })} key={ONGLET_TRIMESTRES}>
                <SGBox margin={{ top: "lg" }}>
                    <CarteTrimestres acquis={acquis} dates={dates} />
                </SGBox>
            </SGTabPane>
            <SGTabPane ariaId={ONGLET_ESTIMATION} tab={intl.formatMessage({ id: `parcoursRIS.resultat.tab.${ONGLET_ESTIMATION}` })} key={ONGLET_ESTIMATION}>
                <SGBox margin={{ top: "lg" }}>
                    <CarteEstimation dates={dates} />
                </SGBox>
            </SGTabPane>
        </SGTabs>
    );
};
