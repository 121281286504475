import { ReactComponent as Calendar } from "assets/images/calendar-epargner.svg";
import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SGCarousel } from "sg-carousel";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirStrokedMesRendezVous,
  SGAvenirStyledHabitation,
} from "sg-icon-pack-base";
import { SGContent } from "sg-layout";
import { SGBox } from "sg-space";
import { SGSticky } from "sg-sticky";
import { SGText, SGTitle } from "sg-typo";
import { DashboardAgeType } from "store/dashboard/types";
import { isMobile } from "store/import/utils";
import { BoutonSGAgenda } from "website/components/atoms/BoutonSGAgenda/BoutonSGAgenda";
import { StickyBanner } from "website/components/atoms/Sticky/StickyBanner/StickyBanner";
import { useFeatures } from "website/components/hooks/useFeatures";
import { useSGAgenda } from "website/components/hooks/useSGAgenda";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { CategorieVideo } from "website/components/hooks/useVideos";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { CarrouselVideo } from "website/components/organisms/ParcoursEpargner/CarrouselVideo/CarrouselVideo";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";
import { ReactComponent as Tree } from "../../../../assets/images/arbre.svg";
import { ReactComponent as Epargne } from "../../../../assets/images/epargne_banque_prive.svg";
import { ReactComponent as Famille } from "../../../../assets/images/famille.svg";
import { ReactComponent as Immeuble } from "../../../../assets/images/immeuble.svg";
import { BreadCrumbAssu } from "../../molecules/BreadCrumbAssu/BreadCrumbAssu";
import {
  Card,
  HowToSaveCard,
} from "../../molecules/HowToSaveCard/HowToSaveCard";
import {
  COMPARE_TAB,
  ModuleEpargner,
} from "../../organisms/ParcoursEpargner/ModuleEpargner/ModuleEpargner";
import { ModuleEpargnerMob } from "../../organisms/ParcoursEpargner/ModuleEpargner/ModuleEpargnerMob";
import "./ParcoursEpargner.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

export const ParcoursEpargner: FunctionComponent = () => {
  const intl = useIntl();
  const saveEvent = useTrackingEvent();
  const [montantEpargne, setMontantEpargne] = useState(0);
  const openSGAgenda = useSGAgenda();
  const { parcoursSEIActif, disponibiliteSGAgenda } = useFeatures();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<
    DashboardAgeType | typeof COMPARE_TAB
  >();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { trackClick } = useTracking();

  const cards: Card[] = [
    {
      type: "per",
      image: <Tree />,
      name: "plan-d-epargne-retraite",
      link: parcoursSEIActif ? `#${SIMULATEUR_ECONOMIES_IMPOTS}` : undefined,
    },
    { type: "assuranceVie", image: <Famille />, name: "assurance-vie" },
    {
      type: "pea",
      image: <Epargne />,
      name: "plan-d-epargne-en-actions-et-compte-titres",
    },
    { type: "immobilier", image: <Immeuble />, name: "immobilier" },
    {
      type: "pel",
      image: <SGIcon size="xxl" component={<SGAvenirStyledHabitation />} />,
      name: "plan-d-epargne-logement",
    },
  ];

  const [cardsList, setCardsList] = useState<Card[]>(cards);

  useEffect(() => {
    saveEvent(trackingEvent.EPARGNER);
  }, []);

  const handleOnClickRdv = () => {
    trackClick("age-cle::clic-sur-prendre-rendez-vous", {
      form_field_2: disponibiliteSGAgenda ? "active" : "non-active",
    });
    // FIXME : Déporter dans BoutonSGAgenda
    if (disponibiliteSGAgenda) {
      openSGAgenda();
    } else {
      // SGAgenda désactivé, on affiche un message d'erreur
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    setCardsList(cards);
  }, [parcoursSEIActif]);

  if (!isMobile()) {
    return (
      <div className="parcours-epargner">
        <div className="containerAssu">
          <SGGridRow>
            <SGGridCol>
              <BreadCrumbAssu
                step="parcoursEpargner.header"
                idTracking={
                  currentTab === COMPARE_TAB ? "comparaison" : "age-cle"
                }
              />
            </SGGridCol>
          </SGGridRow>
          <SGGridRow>
            <SGGridCol span={12} align="start">
              <SGTitle level={1} visuallevel={2} caps>
                {intl.formatMessage(
                  { id: "parcoursEpargner.title" },
                  { linebreak: <br /> }
                )}
              </SGTitle>
            </SGGridCol>
          </SGGridRow>
        </div>
        <div className="parcours-epargner__module-epargner-container">
          <div className="containerAssu">
            <SGGridRow span={12}>
              <ModuleEpargner
                montantEpargne={montantEpargne}
                setMontantEpargne={setMontantEpargne}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            </SGGridRow>
          </div>
        </div>
        <div className="containerAssu">
          <SGBox margin={{ bottom: "sm" }}>
            <SGGridRow gutter={[0, 16]}>
              <SGGridCol span={12}>
                <SGBox margin={{ top: "lg" }}>
                  <SGTitle
                    level={2}
                    textalign="center"
                  >
                    {intl.formatMessage({
                      id: "parcoursEpargner.commentEpargner.title",
                    })}
                  </SGTitle>
                </SGBox>
              </SGGridCol>
              <SGGridCol span={12} textalign="center">
                <SGText className="parcours-epargner__subtitle-comment">
                  {intl.formatMessage({
                    id: "parcoursEpargner.commentEpargner.subtitle",
                  })}
                </SGText>
              </SGGridCol>
              <SGGridCol span={12}>
                <SGCarousel
                  settings={{
                    infinite: true,
                    slidesToShow: 4,
                  }}
                >
                  {cardsList.map((card) => (
                    <HowToSaveCard key={card.type} card={card} />
                  ))}
                </SGCarousel>
              </SGGridCol>
              <SGGridCol span={12}>
                <CarrouselVideo categorie={CategorieVideo.EPARGNER} />
                <StickyBanner
                  onClick={handleOnClickRdv}
                  intlId="parcoursEpargner.button.contacter"
                  icon={<Calendar />}
                  disabled={showLoader}
                />
              </SGGridCol>
            </SGGridRow>
          </SGBox>
        </div>
        {showErrorModal && (
          <ErrorModal
            visible={showErrorModal}
            setVisible={setShowErrorModal}
            description="modal.error.message.indisponible"
          />
        )}
      </div>
    );
  }

  return (
    <div className="parcours-epargner">
      <div className="containerAssu">
        <SGGridRow style={{ marginTop: "20px" }}>
          <SGGridCol span={12} align="start">
            <SGTitle level={1} visuallevel={3} caps>
              {intl.formatMessage(
                { id: "parcoursEpargner.title" },
                { linebreak: <> </> }
              )}
            </SGTitle>
          </SGGridCol>
        </SGGridRow>
      </div>
      <div className="parcours-epargner__module-epargner-container">
        <div className="containerAssu">
          <SGGridRow>
            <ModuleEpargnerMob
              montantEpargne={montantEpargne}
              setMontantEpargne={setMontantEpargne}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
            />
          </SGGridRow>
        </div>
      </div>
      <div className="containerAssu">
        <SGGridRow gutter={[0, 17]}>
          <SGGridCol span={12}>
            <SGBox margin={{ top: "lg" }}>
              <SGTitle level={2} textalign="center">
                {intl.formatMessage({
                  id: "parcoursEpargner.commentEpargner.title",
                })}
              </SGTitle>
            </SGBox>
          </SGGridCol>
          <SGGridCol span={12} textalign="left">
            <SGText className="parcours-epargner__subtitle-comment">
              {intl.formatMessage({
                id: "parcoursEpargner.commentEpargner.subtitle",
              })}
            </SGText>
          </SGGridCol>
        </SGGridRow>
        <SGGridRow gutter={[0, 15]}>
          <SGGridCol span={12}>
            <SGCarousel
              settings={{
                infinite: true,
                slidesToShow: 1,
              }}
            >
              {cardsList.map((card) => (
                <HowToSaveCard key={card.type} card={card} />
              ))}
            </SGCarousel>
          </SGGridCol>
        </SGGridRow>
      </div>
      <div className="containerAssu">
        <CarrouselVideo categorie={CategorieVideo.EPARGNER} />
      </div>

      <SGBox>
        {
          // FIXME : retirer le @ts-ignore quand le bug sera corrigé https://sgithub.fr.world.socgen/AppliDigitalClient/sg_npm_sgc_components-plus/issues/2783
        }
        {/* @ts-ignore */}
        <SGSticky>
          <SGContent disableautomargin>
            <SGBox alignchildren={["center"]}>
              <BoutonSGAgenda
                type="primary"
                icon={
                  <SGIcon
                    component={<SGAvenirStrokedMesRendezVous />}
                    currentcolor
                  />
                }
                onClick={handleOnClickRdv}
                disabled={showLoader}
              >
                {intl.formatMessage({
                  id: "parcoursEpargner.button.contacter",
                })}
              </BoutonSGAgenda>
            </SGBox>
          </SGContent>
        </SGSticky>
      </SGBox>
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          description="modal.error.message.indisponible"
        />
      )}
    </div>
  );
};
