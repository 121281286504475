import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTile } from "sg-tile";
import { SGText } from "sg-typo";
import { State } from "store/store";
import {
  SupervisionState,
  possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { RoundedSalary } from "website/components/atoms/RoundedSalary/RoundedSalary";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import {
  EFFORT_EPARGNE_TAB_EPARGNE,
  EFFORT_EPARGNE_TAB_OBJECTIF,
} from "../EffortEpargne";

interface EffortEpargneTabProps {
  currentTab: "objectif" | "epargne";
  editableValue: number | undefined;
  setEditableValue: (value: number) => void;
  maxValueInput: number;
  otherValue: number | undefined;
  revenuAFinancer: number;
  setHasChangedValue?: (val: boolean) => void;
}

const EffortEpargneTab: FunctionComponent<EffortEpargneTabProps> = (
  props: EffortEpargneTabProps
) => {
  const {
    currentTab,
    editableValue,
    setEditableValue,
    maxValueInput,
    otherValue,
    revenuAFinancer,
    setHasChangedValue,
  } = props;
  const { selectedRetirementAge } = useSelectedRetirementAge();
  const intl = useIntl();

  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  return (
    <SGGridRow justify="center">
      <SGGridCol span={12}>
        <SGBox
          margin={{
            top: isPhone ? "lg" : "sm",
            bottom: "sm"
          }}
        >
        <SGGridRow align="middle" justify="center">
          <SGGridCol span={5}>
            <RoundedSalary
              salary={editableValue}
              setSalary={setEditableValue}
              maxValueInput={maxValueInput}
              disabled={!possedeDroitsActionUtilisateur(supervisionState)}
              setHasChangedValue={setHasChangedValue}
              disableautomargin
            />
          </SGGridCol>
          <SGGridCol flex="initial">
            <SGText size="xl" style={{color: "#090f70"}}>
              <FormattedMessage id="dashboard.moisnet" />
            </SGText>
          </SGGridCol>
        </SGGridRow>
        </SGBox>
      </SGGridCol>
      {selectedRetirementAge?.lastMonthlyIncome && (
        <SGGridCol span={12} textalign="center">
          <SGBox margin={isPhone ? { top: "sm" } : {}}>
            <SGText color="lighter" size="s">
              {intl.formatMessage(
                {
                  id: "dashboard.effortEpargne.objectif.revenuEstime",
                },
                {
                  revenu: roundDown10(
                    selectedRetirementAge.lastMonthlyIncome
                  ).toLocaleString("fr-FR"),
                  b: (word: string) => <SGText weight="700">{word}</SGText>,
                }
              )}
            </SGText>
          </SGBox>
        </SGGridCol>
      )}
      <SGGridCol span={12}>
        <SGBox margin={{ top: "md" }}>
          <SGTile bordered disableautomargin background="Sec_Sg3">
            {currentTab === EFFORT_EPARGNE_TAB_OBJECTIF && (
              <SGText>
                {intl.formatMessage(
                  {
                    id:
                      otherValue && revenuAFinancer
                        ? "dashboard.effortEpargne.objectif.epargner.pour.generer"
                        : "dashboard.effortEpargne.objectif.non.saisi",
                  },
                  {
                    montantAEpargner: otherValue?.toLocaleString("fr-FR"),
                    montantGenere: revenuAFinancer?.toLocaleString("fr-FR"),
                    b: (word: string) => <SGText weight="600">{word}</SGText>,
                  }
                )}
              </SGText>
            )}
            {currentTab === EFFORT_EPARGNE_TAB_EPARGNE && (
              <SGText>
                {intl.formatMessage(
                  {
                    id:
                      editableValue && otherValue
                        ? "dashboard.effortEpargne.epargne.pour.atteindre.objectif"
                        : "dashboard.effortEpargne.epargne.non.saisie",
                  },
                  {
                    montantAEpargner: editableValue?.toLocaleString("fr-FR"),
                    objectifRevenu: otherValue?.toLocaleString("fr-FR"),
                    b: (word: string) => <SGText weight="600">{word}</SGText>,
                  }
                )}
              </SGText>
            )}
          </SGTile>
        </SGBox>
      </SGGridCol>
    </SGGridRow>
  );
};

export { EffortEpargneTab };
