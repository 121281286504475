import React, {ChangeEvent, FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {Feedback} from "../../../../store/feedback/types";
import {sendFeedback} from "../../../../store/feedback/actions";
import {SGTextIntl} from "../../atoms/SGTextIntl/SGTextIntl";
import {SGModal} from "sg-modal";
import {SGButtonIntl} from "../../atoms/SGButtonIntl/SGButtonIntl";
import {SGMessage} from "sg-message";
import {SGIcon} from "sg-icon";
import {SGAvenirStrokedFavoris, SGAvenirStyledPrendreContact} from "sg-icon-pack-base";
import {SGTextArea} from "sg-input";
import {SGSpace} from "sg-space";
import {SGButton} from "sg-button";

interface FeedbackModalProps {
   hideModal: () => void;
}

const FeedbackModal: FunctionComponent<FeedbackModalProps> = ({hideModal}: FeedbackModalProps) => {
   const dispatch = useDispatch();
   const intl = useIntl();

   const [rating, setRating] = useState(0);
   const [comment, setComment] = useState("");

   const onSubmitFeedback = () => {
      const feedback: Feedback = {
         rating,
         comment,
      };
      dispatch(sendFeedback(feedback));
      hideModal();
   };

   return (
      <SGModal visible onCancel={hideModal}>
         <SGMessage
            extra={<SGButtonIntl type="primary" size="md" disabled={comment === "" || rating === 0} cypressName="survey-submit"
                                 onClick={onSubmitFeedback} intlId="go.further.modal.survey.button.action"/>}
            icon={<SGIcon component={<SGAvenirStyledPrendreContact/>} size="xl"/>}
            title={<SGTextIntl intlId="go.further.modal.survey.title"/>}>
            <SGTextIntl intlId="go.further.modal.survey.rating"/>
            <SGSpace>
               <SGButton
                  icon={<SGIcon fill={rating >= 1} component={<SGAvenirStrokedFavoris/>}/>}
                  onClick={() => setRating(1)}
                  type="icon"
               />
               <SGButton
                  icon={<SGIcon fill={rating >= 2} component={<SGAvenirStrokedFavoris/>}/>}
                  onClick={() => setRating(2)}
                  type="icon"
               />
               <SGButton
                  icon={<SGIcon fill={rating >= 3} component={<SGAvenirStrokedFavoris/>}/>}
                  onClick={() => setRating(3)}
                  type="icon"
               />
               <SGButton
                  icon={<SGIcon fill={rating >= 4} component={<SGAvenirStrokedFavoris/>}/>}
                  onClick={() => setRating(4)}
                  type="icon"
               />
               <SGButton
                  icon={<SGIcon fill={rating >= 5} component={<SGAvenirStrokedFavoris/>}/>}
                  onClick={() => setRating(5)}
                  type="icon"
               />
            </SGSpace>
            <SGTextArea value={comment} name="comment" label={intl.formatMessage({id: "go.further.modal.survey.comment"})} maxLength={5000}
                        onChange={(value: ChangeEvent<HTMLInputElement>) => setComment(value.target.value)}/>
         </SGMessage>
      </SGModal>
   );
};

export {FeedbackModal};
