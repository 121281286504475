import React, {FunctionComponent} from "react";
import {FooterBddf} from "./templates/FooterBddf/FooterBddf";
import {FooterDefault} from "./templates/FooterDefault/FooterDefault";
import { FooterAssu } from "./templates/FooterAssu/FooterAssu";
import {SGMediaQuery} from "sg-media-query";
import {FooterXS} from "./templates/FooterXS/FooterXS";

const Footer: FunctionComponent = () => (
   <>
      <SGMediaQuery minwidth="md" maxwidth="xxl">
         {window.env.REACT_APP_INSTANCE === "default" && (
            <FooterDefault/>
         )}
         {window.env.REACT_APP_INSTANCE === "bddf" && (
            <FooterBddf/>
         )}
         {window.env.REACT_APP_INSTANCE === "assu" && (
            <FooterAssu />
         )}
      </SGMediaQuery>
      <SGMediaQuery minwidth="xs" maxwidth="sm">
         {window.env.REACT_APP_INSTANCE === "default" && (
            <FooterXS/>
         )}
         {window.env.REACT_APP_INSTANCE === "bddf" && (
            <FooterXS/>
         )}
         {window.env.REACT_APP_INSTANCE === "assu" && (
            <FooterAssu />
         )}
      </SGMediaQuery>
   </>
);

export {Footer};
