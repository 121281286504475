import React, {FunctionComponent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {FormProvider, useForm} from "react-hook-form";
import {SGTitleIntl} from "../../../atoms/SGTitleIntl/SGTitleIntl";
import {SelectInput} from "../../../atoms/SelectInput/SelectInput";
import {SGTextIntl} from "../../../atoms/SGTextIntl/SGTextIntl";
import {NumericInput} from "../../../atoms/NumericInput/NumericInput";
import {ProjectType} from "../../../../../store/projects/types";
import {createProjectAction} from "../../../../../store/projects/actions";
import {redirectToRelativePath} from "../../../../utils/routes/routing";
import {ErrorElement} from "../../../atoms/ErrorElement/ErrorElement";
import {NEW_PROJECTS} from "../../../../utils/privateRoutes";
import {SGButtonGroup} from "sg-button";
import {SGButtonIntl} from "../../../atoms/SGButtonIntl/SGButtonIntl";
import {SGCard} from "sg-card";
import {SGSelectOption} from "sg-select";
import {FormInputs} from "../../../atoms/FormInputs/FormInputs";
import {SelectInputButtons} from "../../../atoms/SelectInputButtons/SelectInputButtons";
import {SGSpace} from "sg-space";
import {SGAlertIntl} from "../../../molecules/SGAlertIntl/SGAlertIntl";
import {useNumberProjects} from "../../../hooks/useNumberProjects";
import {useMediaQuery} from "sg-media-query";
import {StringInput} from "../../../atoms/StringInput/StringInput";

interface WeddingProjectFormData {
   horizon: string;
   numberOfGuests: number;
   extrasBudget: number;
   amount: number;
   name: string;
}

const Size = {
   S: "S",
   M: "M",
   L: "L"
};

type Size = typeof Size[keyof typeof Size];

const WeddingProject: FunctionComponent = () => {
   const dispatch = useDispatch();
   const intl = useIntl();
   const numberProjects = useNumberProjects();

   const methods = useForm({
      criteriaMode: "firstError",
      mode: "onChange"
   });
   const watchFields = methods.watch(["numberOfGuests" as const, "extrasBudget" as const]);
   const HALL_COST = 500;
   const STANDARD_MEAL_COST = 50;
   const BRIDE_DRESS_COST = 700;
   const GROOM_SUIT_COST = 350;
   const availableOptions = [0, 1, 2, 3, 4, 5];

   const [selectedWeddingHall, setSelectedWeddingHall] = useState<string | undefined>(Size.M);
   const [selectedStandardMeal, setSelectedStandardMeal] = useState<string | undefined>(Size.M);
   const [selectedBrideDress, setSelectedBrideDress] = useState<string | undefined>(Size.M);
   const [selectedGroomSuit, setSelectedGroomSuit] = useState<string | undefined>(Size.M);

   const convertLabelToFactor = (label: string | undefined) => {
      if (label === "S") { return 1; }
      if (label === "M") { return 3; }
      if (label === "L") { return 5; }

      return 1;
   }

   const areGrowthButtonVertical = useMediaQuery({
      minwidth: 'xs',
      maxwidth: 'md',
   });

   useEffect(() => {
      methods.setValue("amount", convertLabelToFactor(selectedWeddingHall) * HALL_COST +
         convertLabelToFactor(selectedStandardMeal) * STANDARD_MEAL_COST * methods.getValues("numberOfGuests") +
         convertLabelToFactor(selectedBrideDress) * BRIDE_DRESS_COST +
         convertLabelToFactor(selectedGroomSuit) * GROOM_SUIT_COST +
         methods.getValues("extrasBudget"), {shouldValidate: true});
   }, [selectedGroomSuit, selectedWeddingHall, selectedBrideDress, selectedStandardMeal, watchFields]);

   const onSubmit = (data: WeddingProjectFormData) => {
      dispatch(
         createProjectAction({
            projectType: ProjectType.WEDDING,
            horizon: new Date().getFullYear() + parseInt(data.horizon),
            projectName: data.name,
            amount: data.amount
         })
      );
   };

   return (
      <>
         <SGTitleIntl intlId="project.wedding.title" cypressName="wedding-project-title"/>
         <SGTextIntl intlId="project.wedding.subtitle"/>
         {numberProjects === 3 && (
            <SGAlertIntl cypressName="wedding-error-element-frame" type="warning" title="common.warning">
               <SGTextIntl intlId="project.error.already-three"/>
            </SGAlertIntl>
         )}
         <FormProvider {...methods}>
            <form>
               <SGCard data-cy="project-wedding-frame-input">
                  <FormInputs colLength={10}>
                     <StringInput label="project.form.name" name="name" cypressName="project-wedding-name"
                                  defaultValue={intl.formatMessage({id: "project.wedding.name"})}/>
                     <SelectInput name="horizon" cypressName="wedding-select-horizon" defaultValue={5} label="project.wedding.form.horizon">
                        {availableOptions.map((horizon: number) => (
                           <SGSelectOption key={horizon} value={horizon}>
                              {horizon === 0 ? intl.formatMessage({id: "common.now"}) : horizon + intl.formatMessage({id: "common.years"})}
                           </SGSelectOption>
                        ))}
                     </SelectInput>
                  </FormInputs>
               </SGCard>
               <SGCard data-cy="project-wedding-frame-choose-budget">
                  <FormInputs colLength={10}>
                     <SGSpace direction="vertical">

                        {/* Salle de mariage */}
                        <SGTextIntl intlId="project.wedding.wedding-hall.label"/>
                        <SelectInputButtons cypressName="project-wedding-weddingHall"
                                            labels={["project.wedding.wedding-hall.s", "project.wedding.wedding-hall.m", "project.wedding.wedding-hall.l"]}
                                            choices={[Size.S, Size.M, Size.L]} choice={selectedWeddingHall} size="sm" direction={areGrowthButtonVertical ? "vertical" : "horizontal"}
                                            setChoice={(val: string) => {
                                               setSelectedWeddingHall(val);
                                            }}
                        />

                        {/* Standing repas */}
                        <SGTextIntl intlId="project.wedding.standard-meal.label"/>
                        <SelectInputButtons cypressName="project-wedding-standardMeal"
                                            labels={["project.wedding.standard-meal.s", "project.wedding.standard-meal.m", "project.wedding.standard-meal.l"]}
                                            choices={[Size.S, Size.M, Size.L]} choice={selectedStandardMeal} size="sm" direction={areGrowthButtonVertical ? "vertical" : "horizontal"}
                                            setChoice={(val: string) => {
                                               setSelectedStandardMeal(val);
                                            }}
                        />

                        {/* Nombre d'invités */}
                        <NumericInput cypressName="project-wedding-numberOfGuests" defaultValue={0}
                                      name="numberOfGuests" min={10} max={1000}
                                      suffix="project.wedding.guests.suffix" label="project.wedding.number-of-guests.label"
                                      placeholder={intl.formatMessage({id: "project.wedding.number-of-guests.placeholder"})}/>

                        {/* Robe mariée */}
                        <SGTextIntl intlId="project.wedding.bride-dress.label"/>
                        <SelectInputButtons cypressName="project-wedding-brideDress"
                                            labels={["project.wedding.bride-dress.s", "project.wedding.bride-dress.m", "project.wedding.bride-dress.l"]}
                                            choices={[Size.S, Size.M, Size.L]} choice={selectedBrideDress} size="sm" direction={areGrowthButtonVertical ? "vertical" : "horizontal"}
                                            setChoice={(val: string) => {
                                               setSelectedBrideDress(val);
                                            }}
                        />

                        {/* Costume marié */}
                        <SGTextIntl intlId="project.wedding.groom-suit.label"/>
                        <SelectInputButtons cypressName="project-wedding-groomSuit"
                                            labels={["project.wedding.groom-suit.s", "project.wedding.groom-suit.m", "project.wedding.groom-suit.l"]}
                                            choices={[Size.S, Size.M, Size.L]} choice={selectedGroomSuit} size="sm" direction={areGrowthButtonVertical ? "vertical" : "horizontal"}
                                            setChoice={(val: string) => {
                                               setSelectedGroomSuit(val);
                                            }}
                        />

                        {/* budget extras */}
                        <NumericInput cypressName="project-wedding-extrasBudget" defaultValue={0}
                                      name="extrasBudget" min={0} max={10000}
                                      suffix="€" label="project.wedding.extras-budget.label"
                                      placeholder={intl.formatMessage({id: "project.wedding.extras-budget.placeholder"})}/>

                        <NumericInput disabled name="amount" suffix="€" cypressName="wedding-amount" min={5_000} max={300_000}
                                      label="project.wedding.form.amount"
                                      placeholder={intl.formatMessage({id: "project.wedding.form.amount.placeholder"})}/>
                     </SGSpace>
                  </FormInputs>
               </SGCard>

               <ErrorElement cypressName="project-api-error" errorTextPrefix="project.error.api"/>
               {numberProjects !== 3 && (
                  <SGButtonGroup align="center" layout="column">
                     <SGButtonIntl type="primary" size="md" disabled={!methods.formState.isValid} cypressName="wedding-next"
                                   onClick={methods.handleSubmit(onSubmit)} intlId="new.project.create"/>
                     <SGButtonIntl type="link" cypressName="wedding-previous" onClick={() => redirectToRelativePath(NEW_PROJECTS)} intlId="common.previous"/>
                  </SGButtonGroup>
               )}
            </form>
         </FormProvider>

      </>
   );
};

export {WeddingProject};
