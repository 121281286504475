import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Highcharts, {Options} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {computeDetailedBalanceNetWorthChartOptions} from "../../../utils/graphs/graph-builders";
import {SpinnerSize, SpinnerWrapper} from "../../atoms/SpinnerWrapper/SpinnerWrapper";
import {getSimulationRange} from "../../../../store/simulation/utils";
import {useSimulation} from "../../hooks/useSimulation";
import {useSelector} from "react-redux";
import {State} from "../../../../store/store";

const GraphDetailedSimulation: FunctionComponent = () => {
   const intl = useIntl();
   const [chartOptionsNetWorth, setChartOptionsNetWorth] = useState<Highcharts.Options>();
   const range = useRef<number[]>([]);

   const simulation = useSimulation(false);
   const hasFetchedSimulation = useSelector<State, boolean>((state) => state.simulation.hasFetched);

   useEffect(() => {
      if (hasFetchedSimulation) {
         range.current = getSimulationRange(simulation);
         const options: Options = computeDetailedBalanceNetWorthChartOptions(
            800,
            simulation.graphs,
            simulation.birthday,
            range.current,
            intl.formatMessage
         );

         setChartOptionsNetWorth(options);
      }
   }, [simulation, hasFetchedSimulation]);

   return (
      <div data-cy="graph-detailed-simulation">
         <SpinnerWrapper displayComponent={chartOptionsNetWorth !== undefined} spinnerSize={SpinnerSize.LG} fatalErrorOnTimeout={false}>
            <HighchartsReact highcharts={Highcharts} options={chartOptionsNetWorth}/>
         </SpinnerWrapper>
      </div>
   );
};

export {GraphDetailedSimulation};
